import { Block } from '@material-ui/icons'

const LeftToggleSection = ({
    bttnColor,
    bttnColor2,
    A1DIGI,
    A2DIGI,
    B1DIGI,
    B2DIGI,
    E1DIGI,
    E2DIGI,
    M1DIGI,
    M2DIGI,
    ServoA1,
    ServoA2,
    ServoB1,
    ServoB2,
    ServoE1,
    ServoE2,
    Servo360A1,
    Servo360A2,
    Servo360B1,
    Servo360B2,
    Servo360E1,
    Servo360E2,
    Servo270A1,
    Servo270A2,
    Servo270B1,
    Servo270B2,
    Servo270E1,
    Servo270E2,
    a1Digi,
    a2Digi,
    b1Digi,
    b2Digi,
    e1Digi,
    e2Digi,
    m1Digi,
    m2Digi,
    a1Servo,
    a1Servo270,
    a2Servo270,
    a1Servo360,
    a2Servo360,
    a2Servo,
    b1Servo,
    b2Servo,
    b1Servo270,
    b2Servo270,
    b1Servo360,
    b2Servo360,
    e1Servo,
    e2Servo,
    e1Servo270,
    e2Servo270,
    e1Servo360,
    e2Servo360,
    m1Servo,
    s1Servo,
    s1Servo360,
    toggleA1,
    toggleA2,
    toggleB1,
    toggleB2,
    toggleE1,
    toggleE2,
    toggleM1,
    toggleM2,
    handleS1Servo360Toggle,
    handleS1ServoToggle,
    isSmileOne,
    isSmileTwo,
    isPCv1,
    M1Servo,
    s1,
    s2,
    s2Servo,
    handleS2ServoToggle,
    s2Servo360,
    s1Servo270,
    s2Servo270,
    handleS2Servo360Toggle,
    handleS1Servo270Toggle,
    handleS2Servo270Toggle,
}) => {
    return (
        <div className="digital-ButtonDivInput">
            {/* port A */}
            <div
                className="digital-flow-left-upper"
                style={{ height: '235pt', backgroundSize: '260pt 300pt' }}
            >
                <div className="digital-flow-left-upper-grp">
                    <label className={'input upper-label-input'}>
                        <span className={(A1DIGI || false) + '-span textsp'}>
                            A1
                        </span>
                        <div
                            className={
                                'switch-button-' +
                                ((A1DIGI &&
                                    !a1Servo &&
                                    !a1Servo360 &&
                                    !a1Servo270 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id={'s1'}
                            style={{ color: bttnColor[0] }}
                        >
                            <input
                                active={a1Digi}
                                // disabled={
                                //   !A1DIGI ||
                                //   a1Servo ||
                                //   JSON.parse(sessionStorage.getItem("AUltra")) ||
                                //   false
                                // }
                                className="switch-button-checkbox"
                                type="checkbox"
                                onChange={toggleA1}
                                checked={a1Digi}
                            ></input>
                            <label className="switch-button-label" for="">
                                <span
                                    className="switch-button-label-span"
                                    id="in1"
                                    style={{
                                        color: bttnColor2[0],
                                    }}
                                >
                                    Digital
                                </span>
                            </label>
                        </div>
                    </label>
                    <br />
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-button-servo-' +
                                ((a1Servo &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-button-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('A1')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('a1-I/O')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('AUltra'))
                                }
                                checked={a1Servo}
                                onChange={ServoA1}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 270 */}
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-buttons-servo270-' +
                                ((a1Servo270 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('A1')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('a1-I/O')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('AUltra'))
                                }
                                checked={a1Servo270}
                                onChange={Servo270A1}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 360 */}
                    <label
                        className={'upper-label-input-servo360'}
                        style={{ display: 'Block' }}
                    >
                        <div
                            className={
                                'switch-buttons-servo-' +
                                ((a1Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('A1')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('a1-I/O')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('AUltra'))
                                }
                                checked={a1Servo360}
                                onChange={Servo360A1}
                            ></input>
                        </div>
                    </label>
                    <br />
                    <label className={'input upper-label-input'}>
                        <span className={(A2DIGI || false) + '-span textsp'}>
                            A2
                        </span>
                        <div
                            className={
                                'switch-button-' +
                                ((A2DIGI &&
                                    !a2Servo &&
                                    !a2Servo270 &&
                                    !a2Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id={'s2'}
                            style={{ color: bttnColor[1] }}
                        >
                            <input
                                active={a2Digi}
                                // disabled={
                                //   !A2DIGI ||
                                //   a2Servo ||
                                //   JSON.parse(sessionStorage.getItem("AUltra")) ||
                                //   false
                                // }
                                className="switch-button-checkbox"
                                type="checkbox"
                                onChange={toggleA2}
                                checked={a2Digi}
                            ></input>
                            <label className="switch-button-label" for="">
                                <span
                                    className="switch-button-label-span"
                                    id="in2"
                                    style={{
                                        color: bttnColor2[1],
                                    }}
                                >
                                    Digital
                                </span>
                            </label>
                        </div>
                    </label>
                    <br />
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-button-servo-' +
                                ((a2Servo &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-button-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('A2')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('a2-I/O')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('AUltra'))
                                }
                                checked={a2Servo}
                                onChange={ServoA2}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 270 */}
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-buttons-servo270-' +
                                ((a2Servo270 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('A2')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('a2-I/O')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('AUltra'))
                                }
                                checked={a2Servo270}
                                onChange={Servo270A2}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 360 */}
                    <label
                        className={'upper-label-input-servo360'}
                        style={{ display: 'Block' }}
                    >
                        <div
                            className={
                                'switch-buttons-servo-' +
                                ((a2Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('AUltra')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('A2')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('a2-I/O')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('AUltra'))
                                }
                                checked={a2Servo360}
                                onChange={Servo360A2}
                            ></input>
                        </div>
                    </label>
                </div>
            </div>
            {/* PORT B */}
            <div
                className="digital-flow-left-upper"
                style={{ height: '235pt', backgroundSize: '260pt 300pt' }}
            >
                <div className="digital-flow-left-upper-grp">
                    <label className={'input upper-label-input'}>
                        <span className={(B1DIGI || false) + '-span textsp'}>
                            B1
                        </span>
                        <div
                            className={
                                'switch-button-' +
                                ((B1DIGI &&
                                    !b1Servo &&
                                    !b1Servo270 &&
                                    !b1Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id={'s3'}
                            style={{ color: bttnColor[2] }}
                        >
                            <input
                                active={b1Digi}
                                className="switch-button-checkbox"
                                type="checkbox"
                                onChange={toggleB1}
                                checked={b1Digi}
                            ></input>
                            <label className="switch-button-label" for="">
                                <span
                                    className="switch-button-label-span"
                                    id="in3"
                                    style={{
                                        color: bttnColor2[2],
                                    }}
                                >
                                    Digital
                                </span>
                            </label>
                        </div>
                    </label>
                    <br />
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-button-servo-' +
                                ((b1Servo &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-button-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('B1')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('b1-I/O')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('BMP3'))
                                }
                                checked={b1Servo}
                                onChange={ServoB1}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 270 */}
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-buttons-servo270-' +
                                ((b1Servo270 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('B1')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('b1-I/O')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('BMP3'))
                                }
                                checked={b1Servo270}
                                onChange={Servo270B1}
                            ></input>
                        </div>
                    </label>

                    {/* Servo 360 */}
                    <label
                        className={'upper-label-input-servo360'}
                        style={{ display: 'Block' }}
                    >
                        <div
                            className={
                                'switch-buttons-servo-' +
                                ((b1Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('B1')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('b1-I/O')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('BMP3'))
                                }
                                checked={b1Servo360}
                                onChange={Servo360B1}
                            ></input>
                        </div>
                    </label>
                    <br />
                    <label className={'input upper-label-input'}>
                        <span className={(B2DIGI || false) + '-span textsp'}>
                            B2
                        </span>
                        <div
                            className={
                                'switch-button-' +
                                ((B2DIGI &&
                                    !b2Servo &&
                                    !b2Servo270 &&
                                    !b2Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id={'s4'}
                            style={{ color: bttnColor[3] }}
                        >
                            <input
                                active={b2Digi}
                                // disabled={
                                //   !B2DIGI ||
                                //   b2Servo ||
                                //   JSON.parse(sessionStorage.getItem("BRGB")) ||
                                //   JSON.parse(sessionStorage.getItem("BMP3")) ||
                                //   false
                                // }
                                className="switch-button-checkbox"
                                type="checkbox"
                                onChange={toggleB2}
                                checked={b2Digi}
                            ></input>
                            <label className="switch-button-label" for="">
                                <span
                                    className="switch-button-label-span"
                                    id="in4"
                                    style={{
                                        color: bttnColor2[3],
                                    }}
                                >
                                    Digital
                                </span>
                            </label>
                        </div>
                    </label>
                    <br />
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-button-servo-' +
                                ((b2Servo &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-button-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('B2')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('b2-I/O')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('BMP3'))
                                }
                                checked={b2Servo}
                                onChange={ServoB2}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 270 */}
                    <label
                        className={
                            'input upper-label-input upper-label-input-servo'
                        }
                    >
                        <div
                            className={
                                'switch-buttons-servo270-' +
                                ((b2Servo270 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('B2')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('b2-I/O')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('BMP3'))
                                }
                                checked={b2Servo270}
                                onChange={Servo270B2}
                            ></input>
                        </div>
                    </label>
                    {/* Servo 360 */}
                    <label
                        className={'upper-label-input-servo360'}
                        style={{ display: 'Block' }}
                    >
                        <div
                            className={
                                'switch-buttons-servo-' +
                                ((b2Servo360 &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) &&
                                    !JSON.parse(
                                        sessionStorage.getItem('BMP3')
                                    )) ||
                                    false)
                            }
                            id="s2"
                        >
                            <input
                                className="switch-buttons-checkbox-servo"
                                type="checkbox"
                                disabled={
                                    !JSON.parse(sessionStorage.getItem('B2')) ||
                                    !JSON.parse(
                                        sessionStorage.getItem('b2-I/O')
                                    ) ||
                                    JSON.parse(
                                        sessionStorage.getItem('BRGB')
                                    ) ||
                                    JSON.parse(sessionStorage.getItem('BMP3'))
                                }
                                checked={b2Servo360}
                                onChange={Servo360B2}
                            ></input>
                        </div>
                    </label>
                </div>
            </div>
            {/* PORT E */}
            {isPCv1 ? (
                <>
                    <div
                        className="digital-flow-left-upper"
                        style={{
                            height: '235pt',
                            backgroundSize: '260pt 300pt',
                        }}
                    >
                        <div className="digital-flow-left-upper-grp">
                            <label className={'input upper-label-input'}>
                                <span
                                    className={
                                        (E1DIGI || false) + '-span textsp'
                                    }
                                >
                                    E1
                                </span>
                                <div
                                    className={
                                        'switch-button-' +
                                        ((E1DIGI &&
                                            !e1Servo &&
                                            !e1Servo270 &&
                                            !e1Servo360) ||
                                            false)
                                    }
                                    id={'s11'}
                                    style={{ color: '#717171' }}
                                >
                                    <input
                                        active={e1Digi}
                                        className="switch-button-checkbox"
                                        type="checkbox"
                                        onChange={toggleE1}
                                        checked={e1Digi}
                                    ></input>
                                    <label
                                        className="switch-button-label"
                                        for=""
                                    >
                                        <span
                                            className="switch-button-label-span"
                                            id="in11"
                                            style={{
                                                color: '#fcfcfc',
                                            }}
                                        >
                                            Digital
                                        </span>
                                    </label>
                                </div>
                            </label>
                            <br />
                            <label
                                className={
                                    'input upper-label-input upper-label-input-servo'
                                }
                            >
                                <div
                                    className={'switch-button-servo-' + e1Servo}
                                    id="s11"
                                >
                                    <input
                                        className="switch-button-checkbox-servo"
                                        type="checkbox"
                                        disabled={
                                            !JSON.parse(
                                                sessionStorage.getItem('E1')
                                            ) ||
                                            !JSON.parse(
                                                sessionStorage.getItem('e1-I/O')
                                            )
                                        }
                                        checked={e1Servo}
                                        onChange={ServoE1}
                                    ></input>
                                </div>
                            </label>
                            {/* Servo 270 */}
                            <label
                                className={
                                    'input upper-label-input upper-label-input-servo'
                                }
                            >
                                <div
                                    className={
                                        'switch-buttons-servo270-' +
                                        (e1Servo270 || false)
                                    }
                                    id="s11"
                                >
                                    <input
                                        className="switch-buttons-checkbox-servo"
                                        type="checkbox"
                                        disabled={
                                            !JSON.parse(
                                                sessionStorage.getItem('E1')
                                            ) ||
                                            !JSON.parse(
                                                sessionStorage.getItem('e1-I/O')
                                            )
                                        }
                                        checked={e1Servo270}
                                        onChange={Servo270E1}
                                    ></input>
                                </div>
                            </label>
                            {/* Servo 360 */}
                            <label
                                className={'upper-label-input-servo360'}
                                style={{ display: 'Block' }}
                            >
                                <div
                                    className={
                                        'switch-buttons-servo-' +
                                        (e1Servo360 || false)
                                    }
                                    id="s11"
                                >
                                    <input
                                        className="switch-buttons-checkbox-servo"
                                        type="checkbox"
                                        disabled={
                                            !JSON.parse(
                                                sessionStorage.getItem('E1')
                                            ) ||
                                            !JSON.parse(
                                                sessionStorage.getItem('e1-I/O')
                                            )
                                        }
                                        checked={e1Servo360}
                                        onChange={Servo360E1}
                                    ></input>
                                </div>
                            </label>
                            <br />
                            <label className={'input upper-label-input'}>
                                <span
                                    className={
                                        (E2DIGI || false) + '-span textsp'
                                    }
                                >
                                    E2
                                </span>
                                <div
                                    className={
                                        'switch-button-' +
                                        ((E2DIGI &&
                                            !e2Servo &&
                                            !e2Servo360 &&
                                            !e2Servo360) ||
                                            false)
                                    }
                                    id={'s12'}
                                    style={{ color: '#717171' }}
                                >
                                    <input
                                        active={e2Digi}
                                        className="switch-button-checkbox"
                                        type="checkbox"
                                        onChange={toggleE2}
                                        checked={e2Digi}
                                    ></input>
                                    <label
                                        className="switch-button-label"
                                        for=""
                                    >
                                        <span
                                            className="switch-button-label-span"
                                            id="in12"
                                            style={{
                                                color: '#fcfcfc',
                                            }}
                                        >
                                            Digital
                                        </span>
                                    </label>
                                </div>
                            </label>
                            <br />
                            <label
                                className={
                                    'input upper-label-input upper-label-input-servo'
                                }
                            >
                                <div
                                    className={
                                        'switch-button-servo-' +
                                        (e2Servo || false)
                                    }
                                    id="s12"
                                >
                                    <input
                                        className="switch-button-checkbox-servo"
                                        type="checkbox"
                                        disabled={
                                            !JSON.parse(
                                                sessionStorage.getItem('E2')
                                            ) ||
                                            !JSON.parse(
                                                sessionStorage.getItem('e2-I/O')
                                            )
                                        }
                                        checked={e2Servo}
                                        onChange={ServoE2}
                                    ></input>
                                </div>
                            </label>
                            {/* Servo 270 */}
                            <label
                                className={
                                    'input upper-label-input upper-label-input-servo'
                                }
                            >
                                <div
                                    className={
                                        'switch-buttons-servo270-' +
                                        (e2Servo270 || false)
                                    }
                                    id="s12"
                                >
                                    <input
                                        className="switch-buttons-checkbox-servo"
                                        type="checkbox"
                                        disabled={
                                            !JSON.parse(
                                                sessionStorage.getItem('E2')
                                            ) ||
                                            !JSON.parse(
                                                sessionStorage.getItem('e2-I/O')
                                            )
                                        }
                                        checked={e2Servo270}
                                        onChange={Servo270E2}
                                    ></input>
                                </div>
                            </label>
                            {/* Servo 360 */}
                            <label
                                className={'upper-label-input-servo360'}
                                style={{ display: 'Block' }}
                            >
                                <div
                                    className={
                                        'switch-buttons-servo-' +
                                        (e2Servo360 || false)
                                    }
                                    id="s12"
                                >
                                    <input
                                        className="switch-buttons-checkbox-servo"
                                        type="checkbox"
                                        disabled={
                                            !JSON.parse(
                                                sessionStorage.getItem('E2')
                                            ) ||
                                            !JSON.parse(
                                                sessionStorage.getItem('e2-I/O')
                                            )
                                        }
                                        checked={e2Servo360}
                                        onChange={Servo360E2}
                                    ></input>
                                </div>
                            </label>
                        </div>
                    </div>
                </>
            ) : (
                <div className="Digital-flow-left-upper">
                    <div className="Digital-flow-left-upper-grp">
                        <label className={'input upper-label-input'}>
                            <span
                                className={(E1DIGI || false) + '-span textsp'}
                            >
                                E1
                            </span>
                            <div
                                className={'switch-button-' + (E1DIGI || false)}
                                id="s11"
                                style={{ color: '#717171' }}
                            >
                                <input
                                    active={e1Digi}
                                    // disabled={!F1DIGI || false}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    onChange={toggleE1}
                                    checked={e1Digi}
                                ></input>
                                <label className=" switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in11"
                                        style={{
                                            color: '#fcfcfc',
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>
                    <div className="Digital-flow-left-upper-grp">
                        <label className={'input upper-label-input'}>
                            <span
                                className={(E2DIGI || false) + '-span textsp'}
                            >
                                E2
                            </span>
                            <div
                                className={'switch-button-' + (E2DIGI || false)}
                                id="s12"
                                style={{ color: '#717171' }}
                            >
                                <input
                                    active={e2Digi}
                                    // disabled={!F2DIGI || false}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    onChange={toggleE2}
                                    checked={e2Digi}
                                ></input>

                                <label className=" switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in12"
                                        style={{
                                            color: '#fcfcfc',
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
            )}

            {/* PORT M1 & M2 */}
            <div
                className={
                    isPCv1
                        ? 'Digital-flow-left-upper'
                        : 'digital-flow-left-upper'
                }
                style={{
                    position: 'relative',
                    transform: 'translateY(-1%)',
                    zIndex: '1000',
                }}
            >
                {/* M1 */}
                <div
                    className="digital-flow-left-upper-grp"
                    style={{ top: '20%' }}
                >
                    <label className={'input upper-label-input'}>
                        <span
                            className={
                                (M1DIGI || isSmileOne || false) + '-span textsp'
                            }
                        >
                            L1
                        </span>
                        <div
                            className={
                                'switch-button-' +
                                (((M1DIGI || isSmileOne) && !m1Servo) || false)
                            }
                            id="s13"
                            style={{ color: bttnColor[12] }}
                        >
                            <input
                                active={m1Digi}
                                // disabled={true}
                                checked={m1Digi}
                                className="switch-button-checkbox"
                                type="checkbox"
                                onChange={toggleM1}
                            ></input>
                            <label className=" switch-button-label" for="">
                                <span
                                    className="switch-button-label-span"
                                    id="in13"
                                    style={{
                                        color: bttnColor2[12],
                                    }}
                                >
                                    Digital
                                </span>
                            </label>
                        </div>
                    </label>
                    <br />

                    {!isPCv1 && (
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-button-serialServo-' +
                                    (m1Servo &&
                                        JSON.parse(
                                            sessionStorage.getItem('M1')
                                        ))
                                }
                                style={{
                                    transform: 'translateY(-30%)',
                                }}
                                id="s2"
                            >
                                <input
                                    className="switch-button-checkbox-serialServo"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('M1')
                                        )
                                    }
                                    type="checkbox"
                                    checked={m1Servo}
                                    onChange={M1Servo}
                                ></input>
                            </div>
                        </label>
                    )}
                </div>
                {/* M2 */}
                <div
                    className="digital-flow-left-upper-grp"
                    style={{ top: '16%' }}
                >
                    <label className={'input upper-label-input'}>
                        <span
                            className={
                                (M2DIGI || isSmileTwo || false) + '-span textsp'
                            }
                        >
                            L2
                        </span>
                        <div
                            className={
                                'switch-button-' +
                                (M2DIGI || isSmileTwo || false)
                            }
                            id="s14"
                            style={{ color: bttnColor[13] }}
                        >
                            <input
                                active={m2Digi}
                                // disabled={true}
                                checked={m2Digi}
                                className="switch-button-checkbox"
                                type="checkbox"
                                onChange={toggleM2}
                            ></input>
                            <label className=" switch-button-label" for="">
                                <span
                                    className="switch-button-label-span"
                                    id="in14"
                                    style={{
                                        color: bttnColor2[13],
                                    }}
                                >
                                    Digital
                                </span>
                            </label>
                        </div>
                    </label>
                    <br />
                    {/* <label
                                    className={
                                        'input upper-label-input upper-label-input-servo'
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-mp3-' +
                                            (m3Mp3 &&
                                                JSON.parse(
                                                    sessionStorage.getItem('M2')
                                                ))
                                        }
                                        style={{
                                            transform: 'translateY(-30%)',
                                        }}
                                        id="s2"
                                    >
                                        <input
                                            className="switch-button-checkbox-mp3"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('M2')
                                                )
                                            }
                                            type="checkbox"
                                            checked={m3Mp3}
                                            onChange={Mp3M3}
                                        ></input>
                                    </div>
                                </label> */}
                </div>
            </div>

            {/* PORT S1 & S2 */}
            {isPCv1 && (
                <div
                    className={`digital-flow-left-upper input-btn-grp `}
                    style={{
                        position: 'relative',
                        transform: 'translateY(-4%)',
                        zIndex: '1000',
                    }}
                >
                    <label
                        className={
                            s1
                                ? 'single-input-grp-enabled'
                                : 'single-input-grp-disabled'
                        }
                    >
                        S1
                        <input
                            className={
                                s1Servo ? 'input-btn-on' : 'input-btn-off'
                            }
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                            }}
                            type="button"
                            value="Servo(180)"
                            onClick={handleS1ServoToggle}
                        ></input>
                        <input
                            className={
                                s1Servo270 ? 'input-btn-on' : 'input-btn-off'
                            }
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                            }}
                            type="button"
                            value="Servo(270)"
                            onClick={handleS1Servo270Toggle}
                        ></input>
                    </label>
                    <input
                        className={
                            s1Servo360 ? 'input-btn-on' : 'input-btn-off'
                        }
                        style={{
                            paddingLeft: '0',
                            paddingRight: '0',
                        }}
                        type="button"
                        value="Servo(360)"
                        onClick={handleS1Servo360Toggle}
                    ></input>
                    <label
                        className={
                            s2
                                ? 'single-input-grp-enabled'
                                : 'single-input-grp-disabled'
                        }
                    >
                        S2
                        <input
                            className={
                                s2Servo ? 'input-btn-on' : 'input-btn-off'
                            }
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                            }}
                            type="button"
                            value="Servo(180)"
                            onClick={handleS2ServoToggle}
                        ></input>
                        <input
                            className={
                                s2Servo270 ? 'input-btn-on' : 'input-btn-off'
                            }
                            style={{
                                paddingLeft: '0',
                                paddingRight: '0',
                            }}
                            type="button"
                            value="Servo(270)"
                            onClick={handleS2Servo270Toggle}
                        ></input>
                    </label>
                    <input
                        className={
                            s2Servo360 ? 'input-btn-on' : 'input-btn-off'
                        }
                        style={{
                            display: 'block',
                            paddingLeft: '0',
                            paddingRight: '0',
                        }}
                        type="button"
                        value="Servo(360)"
                        onClick={handleS2Servo360Toggle}
                    ></input>
                </div>
            )}

            {/* don't remove this div  [PLAYC-936]*/}
            <div
                className="Digital-flow-left-upper"
                style={{ visibility: 'hidden', height: '1vh' }}
            >
                {/* <div className="Digital-flow-left-upper-grp">
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span
                                            className={
                                                (M1DIGI ||
                                                    isSmileOne ||
                                                    false) + '-span textsp'
                                            }
                                        >
                                            L1
                                        </span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                (((M1DIGI || isSmileOne) &&
                                                    !m1Servo) ||
                                                    false)
                                            }
                                            id="s13"
                                            style={{ color: bttnColor[12] }}
                                        >
                                            <input
                                                active={m1Digi}
                                                // disabled={true}
                                                checked={m1Digi}
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleM1}
                                            ></input>
                                            <label
                                                className=" switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in13"
                                                    style={{
                                                        color: bttnColor2[12],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                </div>
                                <div className="Digital-flow-left-upper-grp">
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span
                                            className={
                                                (M2DIGI ||
                                                    isSmileTwo ||
                                                    false) + '-span textsp'
                                            }
                                        >
                                            L2
                                        </span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                (M2DIGI || isSmileTwo || false)
                                            }
                                            id="s14"
                                            style={{ color: bttnColor[13] }}
                                        >
                                            <input
                                                active={m2Digi}
                                                // disabled={true}
                                                checked={m2Digi}
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleM2}
                                            ></input>
                                            <label
                                                className=" switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in14"
                                                    style={{
                                                        color: bttnColor2[13],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                </div> */}
            </div>

            {/* <div style={{ marginTop: '4rem' }}>.</div> */}
        </div>
    )
}
export default LeftToggleSection
