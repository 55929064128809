import React, { useLayoutEffect, useState, useEffect, useRef } from 'react'
import Bottom from './Bottom'
import { Nav } from 'react-bootstrap'
import { useLocalStorage } from '../LocalStorage/LocalStorage'
import pcImg from '../../Assets/internalAccessories/PC_image@3x.png'
import PCv1 from '../../Assets/Bisoft_UI/Main/PNG/PC_v1_image@3x.png'
import selectImg from '../../Assets/img/select bar@2x.png'
import secondaryImg from '../../Assets/Bisoft_UI/Buttons/Inactive with shadow/save - secondary@2x.png'
import strokeImg from '../../Assets/Bisoft_UI/Buttons/Inactive with shadow/help@2x.png'
import connectionImg from '../../Assets/usb - off@2x.png'
import { Link, useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { webSerialConnect } from '../../redux/actions'

import eyeInactiveImg from '../../Assets/internalAccessories/eye - inactive.484d85f3.svg'
import teethImg from '../../Assets/internalAccessories/teeth - inactive.ff84b1d3.svg'
import inImg from '../../Assets/internalAccessories/4 in 1 - inactive.ea3e994f.svg'
import internalmicImg from '../../Assets/internalAccessories/internal mic - inactive.d43d2f36.svg'
import buzzerImg from '../../Assets/internalAccessories/buzzer - inactive.872b79d8.svg'
import touchpadsImg from '../../Assets/internalAccessories/touch pads - inactive.748c6933.svg'

import PcinternalEYEActive from '../../Assets/internalAccessories/eye - active.svg'
import PcinternalEYEInActive from '../../Assets/internalAccessories/eye - inactive.svg'
import PcinternalTeethInActive from '../../Assets/internalAccessories/teeth - inactive.svg'
import PcinternalTeethActive from '../../Assets/internalAccessories/teeth - active.svg'

import Pcinternal4in1Active from '../../Assets/internalAccessories/4 in 1 - active.svg'
import Pcinternal4in1InActive from '../../Assets/internalAccessories/4 in 1 - inactive.svg'
import PcinternalMicInActive from '../../Assets/internalAccessories/internal mic - inactive.svg'
import PcinternalMicActive from '../../Assets/internalAccessories/internal mic - active.svg'
import PcinternalBuzzerInActive from '../../Assets/internalAccessories/buzzer - inactive.svg'
import PcinternalBuzzerActive from '../../Assets/internalAccessories/buzzer - active.svg'
import PcinternalTouchpadsInActive from '../../Assets/internalAccessories/touch pads - inactive.svg'
import PcinternalTouchpadsActive from '../../Assets/internalAccessories/touch pads - active.svg'

import './pcimage.css'
import './Navbar.css'
import './style.css'
import renderPrgImage from '../../source/programImg'

import selectBgimg from '../../Assets/img/selectbgimg.png'
import closeBtn from '../../Assets/img/close.png'
import ProgramHeader from '../ReusableComponents/Header/ProgramHeader'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import cacheAssets from '../../utils/cacheAssets'

const isPCv1 = sessionStorage.getItem('deviceVersion')?.startsWith('1')
    ? true
    : false

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}
let i = []
let a1color = 'black',
    a2color = 'black'
let bttnColor = []
let bttType = [
    'A1',
    'A2',
    'B1',
    'B2',
    'C1',
    'C2',
    'D1',
    'D2',
    'E1',
    'E2',
    'F1',
    'F2',
    'M1',
    'M2',
    'M3',
    'M4',
    'S1',
    'S2',
]
for (let i = 0; i < bttType.length; i++) {
    bttnColor[i] = 'black'
    if (sessionStorage.getItem(bttType[i]) == 'true') bttnColor[i] = 'white'
}

if (JSON.parse(sessionStorage.getItem('A1'))) a1color = 'white'
if (JSON.parse(sessionStorage.getItem('A2'))) a2color = 'white'

for (let j = 0; j < 8; j++) i[j] = 1
let pa1 = false
const Port = (props) => {
    const history = useHistory()
    const [isAnimating, setIsAnimating] = useState(false)

    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')
    for (let i = 0; i < 16; i++) {
        bttnColor[i] = 'black'
        if (JSON.parse(sessionStorage.getItem(bttType[i])))
            bttnColor[i] = 'white'
    }

    useLayoutEffect(() => {
        return () => {
            console.log(
                'GSK',
                JSON.parse(sessionStorage.getItem('A1')),
                typeof JSON.parse(sessionStorage.getItem('A1'))
            )
            // if(JSON.parse(sessionStorage.getItem("A1")))
            //   a1color="white"
            // else
            //   a1color="black"
            // if(JSON.parse(sessionStorage.getItem("A2")))
            //   a2color="white"
            // else
            //   a2color="black"
            for (let i = 0; i < bttType.length; i++) {
                if (JSON.parse(sessionStorage.getItem(bttType[i])))
                    bttnColor[i] = 'white'
                else bttnColor[i] = 'black'
            }

            //  myFunction1();
        }
    })

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const next = () => {
        history.push('/flow/input-output')
    }
    const backBtnAction = () => {
        sessionStorage.setItem('slideDirection', true)
        saveDataToLocalStorage()

        if (isPCv1) history.push('/v1/selectScreen/InternalAccessories')
        else history.push('/flow/InternalAccessories')
    }
    const [a1, setA1] = useLocalStorage('A1', false)
    const [a2, setA2] = useLocalStorage('A2', false)
    const [a, setA] = useLocalStorage('A', false)
    const [b1, setB1] = useLocalStorage('B1', false)
    const [b2, setB2] = useLocalStorage('B2', false)
    const [b, setB] = useLocalStorage('B', false)
    const [c1, setC1] = useLocalStorage('C1', false)
    const [c2, setC2] = useLocalStorage('C2', false)
    const [c, setC] = useLocalStorage('C', false)
    const [d1, setD1] = useLocalStorage('D1', false)
    const [d2, setD2] = useLocalStorage('D2', false)
    const [d, setD] = useLocalStorage('D', false)
    const [e1, setE1] = useLocalStorage('E1', false)
    const [e2, setE2] = useLocalStorage('E2', false)
    const [e, setE] = useLocalStorage('E', false)
    const [f1, setF1] = useLocalStorage('F1', false)
    const [f2, setF2] = useLocalStorage('F2', false)
    const [f, setF] = useLocalStorage('F', false)
    const [m1, setM1] = useLocalStorage('M1', false)
    const [m2, setM2] = useLocalStorage('M2', false)
    const [m, setM] = useLocalStorage('M', false)
    const [m3, setM3] = useLocalStorage('M3', false)
    const [m4, setM4] = useLocalStorage('M4', false)
    const [s1, setS1] = useLocalStorage('S1', false)
    const [s2, setS2] = useLocalStorage('S2', false)
    const [n, setN] = useLocalStorage('N', false)
    const [aUltra, setAUltra] = useLocalStorage(
        'AUltra',
        JSON.parse(sessionStorage.getItem('AUltra'))
    )
    const [bRGB, setBRGB] = useLocalStorage(
        'BRGB',
        JSON.parse(sessionStorage.getItem('BRGB'))
    )
    const [bMP3, setBMP3] = useLocalStorage(
        'BMP3',
        JSON.parse(sessionStorage.getItem('BMP3'))
    )
    const [dOLED, setDOLED] = useLocalStorage(
        'DOLED',
        JSON.parse(sessionStorage.getItem('DOLED'))
    )

    const [cUltra, setCUltra] = useLocalStorage(
        'CUltra',
        JSON.parse(sessionStorage.getItem('CUltra'))
    )
    const [a1Servo, setA1Servo] = useLocalStorage(
        'A1Servo',
        JSON.parse(sessionStorage.getItem('A1Servo'))
    )
    const [a1Servo360, setA1Servo360] = useLocalStorage(
        'A1Servo360',
        JSON.parse(sessionStorage.getItem('A1Servo360'))
    )
    const [a2Servo, setA2Servo] = useLocalStorage(
        'A2Servo',
        JSON.parse(sessionStorage.getItem('A2Servo'))
    )
    const [a2Servo360, setA2Servo360] = useLocalStorage(
        'A2Servo360',
        JSON.parse(sessionStorage.getItem('A2Servo360'))
    )
    const [b1Servo, setB1Servo] = useLocalStorage(
        'B1Servo',
        JSON.parse(sessionStorage.getItem('B1Servo'))
    )
    const [b1Servo360, setB1Servo360] = useLocalStorage(
        'B1Servo360',
        JSON.parse(sessionStorage.getItem('B1Servo360'))
    )
    const [b2Servo, setB2Servo] = useLocalStorage(
        'B2Servo',
        JSON.parse(sessionStorage.getItem('B2Servo'))
    )
    const [b2Servo360, setB2Servo360] = useLocalStorage(
        'B2Servo360',
        JSON.parse(sessionStorage.getItem('B2Servo360'))
    )
    const [c1Servo, setC1Servo] = useLocalStorage(
        'C1Servo',
        JSON.parse(sessionStorage.getItem('C1Servo'))
    )
    const [c1Servo360, setC1Servo360] = useLocalStorage(
        'C1Servo360',
        JSON.parse(sessionStorage.getItem('C1Servo360'))
    )
    const [c2Servo, setC2Servo] = useLocalStorage(
        'C2Servo',
        JSON.parse(sessionStorage.getItem('C2Servo'))
    )
    const [c2Servo360, setC2Servo360] = useLocalStorage(
        'C2Servo360',
        JSON.parse(sessionStorage.getItem('C2Servo360'))
    )
    const [d1Servo, setD1Servo] = useLocalStorage(
        'D1Servo',
        JSON.parse(sessionStorage.getItem('D1Servo'))
    )
    const [d1Servo360, setD1Servo360] = useLocalStorage(
        'D1Servo360',
        JSON.parse(sessionStorage.getItem('D1Servo360'))
    )
    const [d2Servo, setD2Servo] = useLocalStorage(
        'D2Servo',
        JSON.parse(sessionStorage.getItem('D2Servo'))
    )
    const [d2Servo360, setD2Servo360] = useLocalStorage(
        'D2Servo360',
        JSON.parse(sessionStorage.getItem('D2Servo360'))
    )

    const [m3Mp3, setM3Mp3] = useLocalStorage(
        'M3Mp3',
        JSON.parse(sessionStorage.getItem('M3Mp3'))
    )

    const isDistanceSensors = JSON.parse(
        sessionStorage.getItem('isDistanceSensors')
    )
    const isGestureSensor = JSON.parse(
        sessionStorage.getItem('isGestureSensor')
    )
    const isLightSensor = JSON.parse(sessionStorage.getItem('isLightSensor'))
    const isColorSensor = JSON.parse(sessionStorage.getItem('isColorSensor'))
    const isTemperature = JSON.parse(sessionStorage.getItem('isTemperature'))
    const isMic = JSON.parse(sessionStorage.getItem('isMic'))
    const isTouchZero = JSON.parse(sessionStorage.getItem('isTouchZero'))
    const isTouchOne = JSON.parse(sessionStorage.getItem('isTouchOne'))
    const isTouchTwo = JSON.parse(sessionStorage.getItem('isTouchTwo'))
    const isTouchThree = JSON.parse(sessionStorage.getItem('isTouchThree'))
    const isInternalEye = JSON.parse(sessionStorage.getItem('isInternalEye'))

    const isTouchZeroOutput = JSON.parse(
        sessionStorage.getItem('isTouchZeroOutput')
    )
    const isTouchOneOutput = JSON.parse(
        sessionStorage.getItem('isTouchOneOutput')
    )
    const isTouchTwoOutput = JSON.parse(
        sessionStorage.getItem('isTouchTwoOutput')
    )
    const isTouchThreeOutput = JSON.parse(
        sessionStorage.getItem('isTouchThreeOutput')
    )
    const isEyeLeft = JSON.parse(sessionStorage.getItem('isEyeLeft'))
    const isEyeRight = JSON.parse(sessionStorage.getItem('isEyeRight'))
    const isbuzzer = JSON.parse(sessionStorage.getItem('isBuzzer'))
    const isSimeleOne = JSON.parse(sessionStorage.getItem('isSmileOne'))
    const isSimeleTwo = JSON.parse(sessionStorage.getItem('isSmileTwo'))
    const isSimeleThree = JSON.parse(sessionStorage.getItem('isSmileThree'))
    const isSimeleFour = JSON.parse(sessionStorage.getItem('isSmileFour'))

    const onA1ValueChange = async () => {
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchZero')) ||
            false

        if (!isPCv1 && isTouchOccupied) return
        setA1(!a1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (a1 === false && a2 === true) {
            setA(true)
        } else {
            setA(false)
        }
    }

    const onA2ValueChange = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchZero')) ||
            false

        if (!isPCv1 && isTouchOccupied) return
        setA2(!a2)
        if (a1 === true && a2 === false) {
            setA(true)
        } else {
            setA(false)
        }
    }

    const onB1ValueChange = () => {
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchOne')) ||
            false

        if (!isPCv1 && isTouchOccupied) return

        setB1(!b1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (b1 === false && b2 === true) {
            setB(true)
        } else {
            setB(false)
        }
    }
    const onB2ValueChange = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchOne')) ||
            false

        if (!isPCv1 && isTouchOccupied) return
        setB2(!b2)
        if (b1 === true && b2 === false) {
            setB(true)
        } else {
            setB(false)
        }
    }

    const onC1ValueChange = () => {
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
            false

        if (!isPCv1 && isTouchOccupied) return

        setC1(!c1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (c1 === false && c2 === true) {
            setC(true)
        } else {
            setC(false)
        }
    }
    const onC2ValueChange = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
            false

        if (!isPCv1 && isTouchOccupied) return
        setC2(!c2)
        if (c1 === true && c2 === false) {
            setC(true)
        } else {
            setC(false)
        }
    }

    const onD1ValueChange = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        setD1(!d1)
        if (d1 === false && d2 === true) {
            setD(true)
        } else {
            setD(false)
        }
    }
    const onD2ValueChange = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        setD2(!d2)
        if (d1 === true && d2 === false) {
            setD(true)
        } else {
            setD(false)
        }
    }

    const onE1ValueChange = () => {
        if (!isPCv1) return
        setE1(!e1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (e1 === false && e2 === true) {
            setE(true)
        } else {
            setE(false)
        }
    }
    const onE2ValueChange = () => {
        if (!isPCv1) return
        setE2(!e2)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (e1 === true && e2 === false) {
            setE(true)
        } else {
            setE(false)
        }
    }

    const onF1ValueChange = () => {
        setF1(!f1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (f1 === false && f2 === true) {
            setF(true)
        } else {
            setF(false)
        }
    }
    const onF2ValueChange = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        setF2(!f2)
        if (f1 === true && f2 === false) {
            setF(true)
        } else {
            setF(false)
        }
    }

    const onL1ValueChange = () => {
        const isSmileOne =
            JSON.parse(sessionStorage.getItem('isSmileOne')) == true

        if (isSmileOne) return

        setM1(!m1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (m1 === false && m2 === true) setM(true)
        else setM(false)
    }
    const onL2ValueChange = () => {
        const isSmileTwo =
            JSON.parse(sessionStorage.getItem('isSmileTwo')) == true

        if (isSmileTwo) return

        setM2(!m2)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (m1 === true && m2 === false) setM(true)
        else setM(false)
    }

    const onL3ValueChange = () => {
        const isSmileThree =
            JSON.parse(sessionStorage.getItem('isSmileThree')) == true

        if (isSmileThree) return

        setM3(!m3)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (m3 === true && m4 === false) setN(true)
        else setN(false)
    }
    const onL4ValueChange = () => {
        const isSmileFour =
            JSON.parse(sessionStorage.getItem('isSmileFour')) == true

        if (isSmileFour) return

        setM4(!m4)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (m3 === true && m4 === false) setN(true)
        else setN(false)
    }

    const onS1ValueChange = () => {
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchZero')) ||
            false

        if (isTouchOccupied) return
        setS1(!s1)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (s1 === false) {
            setS1(true)
        } else {
            setS1(false)
        }
    }

    const onS2ValueChange = () => {
        const isTouchOccupied =
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) ||
            JSON.parse(sessionStorage.getItem('isTouchOne')) ||
            false

        if (isTouchOccupied) return
        setS2(!s2)
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (s2 === false) {
            setS2(true)
        } else {
            setS2(false)
        }
    }

    function findIndex(array, string) {
        var index = []
        for (var i = 0; i < array.length; i++) {
            if (
                array[i].indexOf(string) > -1 &&
                array[i].indexOf('rgb1') == -1 &&
                array[i].indexOf('rgb2') == -1 &&
                array[i].indexOf(`${string}-I/O`) == -1
            ) {
                index.push(i)
            }
        }
        return index
    }
    function findIndex_new(array, string) {
        var index = []
        for (var i = 0; i < array.length; i++) {
            if (
                array[i].indexOf(string) > -1 &&
                array[i].indexOf('countRGB') == -1
            ) {
                index.push(i)
            }
        }
        return index
    }
    const myFunction1 = async () => {
        if (
            !isPCv1 &&
            (JSON.parse(sessionStorage.getItem('isTouchZero')) ||
                JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) == true)
        ) {
            setToastMsg('Disable Touch Pad 0 To Enable A1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('A1'))
        if (await JSON.parse(sessionStorage.getItem('A1'))) {
            document.getElementById('foo1').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo1').style.cssText = 'color: black; '
            //  a1color="black"
            setAUltra(false)
            setA1Servo(false)
            setA1Servo360(false)
        }
        let a = findIndex(Object.keys(sessionStorage), 'a1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction2 = async () => {
        if (
            !isPCv1 &&
            (JSON.parse(sessionStorage.getItem('isTouchZero')) ||
                JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) == true)
        ) {
            setToastMsg('Disable Touch Pad 0 To Enable A2')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('A2'))
        if (await JSON.parse(sessionStorage.getItem('A2'))) {
            document.getElementById('foo2').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo2').style.cssText = 'color: black; '
            setAUltra(false)
            setA2Servo(false)
            setA2Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'a2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction3 = async () => {
        if (
            !isPCv1 &&
            (JSON.parse(sessionStorage.getItem('isTouchOne')) ||
                JSON.parse(sessionStorage.getItem('isTouchOneOutput')) == true)
        ) {
            setToastMsg('Disable Touch Pad 1 To Enable B1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('B1'))
        if (await JSON.parse(sessionStorage.getItem('B1'))) {
            document.getElementById('foo3').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo3').style.cssText = 'color: black; '
            // setAUltra(false)
            setBRGB(false)
            setBMP3(false)
            setB1Servo(false)
            setB1Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'b1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        let b = [
            ...findIndex_new(Object.keys(sessionStorage), 'mp3'),
            ...findIndex_new(Object.keys(sessionStorage), 'rgb'),
            ...findIndex_new(Object.keys(sessionStorage), 'RGB'),
        ]
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction4 = async () => {
        if (
            !isPCv1 &&
            (JSON.parse(sessionStorage.getItem('isTouchOne')) ||
                JSON.parse(sessionStorage.getItem('isTouchOneOutput')) == true)
        ) {
            setToastMsg('Disable Touch Pad 1 To Enable B2')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('B2'))
        if (await JSON.parse(sessionStorage.getItem('B2'))) {
            document.getElementById('foo4').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo4').style.cssText = 'color: black; '
            setBRGB(false)
            setB2Servo(false)
            setB2Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'b2')
        let b = [
            ...findIndex_new(Object.keys(sessionStorage), 'mp3'),
            ...findIndex_new(Object.keys(sessionStorage), 'rgb'),
            ...findIndex_new(Object.keys(sessionStorage), 'RGB'),
        ]

        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction5 = async () => {
        if (
            !isPCv1 &&
            (JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
                JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) == true)
        ) {
            setToastMsg('Disable Touch Pad 2 To Enable C1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('C1'))
        if (await JSON.parse(sessionStorage.getItem('C1'))) {
            document.getElementById('foo5').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo5').style.cssText = 'color: black; '
            setCUltra(false)
            setC1Servo(false)
            setC1Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'c1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction6 = async () => {
        if (
            !isPCv1 &&
            (JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
                JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) == true)
        ) {
            setToastMsg('Disable Touch Pad 2 To Enable C1')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('C2'))
        if (await JSON.parse(sessionStorage.getItem('C2'))) {
            document.getElementById('foo6').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo6').style.cssText = 'color: black; '
            setCUltra(false)
            setC2Servo(false)
            setC2Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'c2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction7 = async () => {
        // if (JSON.parse(sessionStorage.getItem('isDistanceSensors')) == true) {
        //     setToastMsg('Disable DistanceSensors To Enable D1')
        //     setToastActive(true)
        //     disableToast()
        // } else if (
        //     JSON.parse(sessionStorage.getItem('isGestureSensor')) == true
        // ) {
        //     setToastMsg('Disable GestureSensor To Enable D1')
        //     setToastActive(true)
        //     disableToast()
        // } else if (
        //     JSON.parse(sessionStorage.getItem('isLightSensor')) == true
        // ) {
        //     setToastMsg('Disable LightSensor To Enable D1')
        //     setToastActive(true)
        //     disableToast()
        // } else if (
        //     JSON.parse(sessionStorage.getItem('isColorSensor')) == true
        // ) {
        //     setToastMsg('Disable ColorSensor To Enable D1')
        //     setToastActive(true)
        //     disableToast()
        // }

        await JSON.parse(sessionStorage.getItem('D1'))
        if (await JSON.parse(sessionStorage.getItem('D1'))) {
            document.getElementById('foo7').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo7').style.cssText = 'color: black; '
            setD1Servo(false)
            setDOLED(false)
            setD1Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'd1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        let b = findIndex_new(Object.keys(sessionStorage), 'oled')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], '')
        }
        b = findIndex_new(Object.keys(sessionStorage), 'oledChk')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction8 = async () => {
        await JSON.parse(sessionStorage.getItem('D2'))
        if (await JSON.parse(sessionStorage.getItem('D2'))) {
            document.getElementById('foo8').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo8').style.cssText = 'color: black; '
            setD2Servo(false)
            setDOLED(false)
            setD2Servo360(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'd2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        let b = findIndex_new(Object.keys(sessionStorage), 'oled')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], '')
        }
        b = findIndex_new(Object.keys(sessionStorage), 'oledChk')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction9 = async () => {
        if (!isPCv1 && !e1) {
            console.log('e1enabel')
            setToastMsg('E1 is Disabled')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('E1'))
        if (await JSON.parse(sessionStorage.getItem('E1'))) {
            document.getElementById('foo9').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo9').style.cssText = 'color: black; '
        }

        let a = findIndex(Object.keys(sessionStorage), 'e1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction10 = async () => {
        if (!isPCv1 && !e2) {
            console.log('e1el')
            setToastMsg('E2 is Disabled')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('E2'))
        if (await JSON.parse(sessionStorage.getItem('E2'))) {
            document.getElementById('foo10').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo10').style.cssText = 'color: black; '
        }

        let a = findIndex(Object.keys(sessionStorage), 'e2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction11 = async () => {
        await JSON.parse(sessionStorage.getItem('F1'))
        if (await JSON.parse(sessionStorage.getItem('F1'))) {
            document.getElementById('foo11').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo11').style.cssText = 'color: black; '
        }

        let a = findIndex(Object.keys(sessionStorage), 'f1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction12 = async () => {
        await JSON.parse(sessionStorage.getItem('F2'))
        if (await JSON.parse(sessionStorage.getItem('F2'))) {
            document.getElementById('foo12').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo12').style.cssText = 'color: black; '
        }

        let a = findIndex(Object.keys(sessionStorage), 'f2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction13 = async () => {
        if (JSON.parse(sessionStorage.getItem('isSmileOne')) == true) {
            setToastMsg('Disable Smile 1 To Enable M1')
            setToastActive(true)
            disableToast()
        }
        {
            await JSON.parse(sessionStorage.getItem('M1'))
            if (await JSON.parse(sessionStorage.getItem('M1'))) {
                document.getElementById('foo13').style.cssText =
                    'color: white; '
            } else {
                document.getElementById('foo13').style.cssText =
                    'color: black; '
                sessionStorage.setItem('M1Servo', false)
            }

            let a = findIndex(Object.keys(sessionStorage), 'm1')
            console.log('gsk', a)
            for (let i in a) {
                sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
            }
        }
    }
    const myFunction14 = async () => {
        if (JSON.parse(sessionStorage.getItem('isSmileTwo')) == true) {
            setToastMsg('Disable Smile 2 To Enable M2')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('M2'))
        if (await JSON.parse(sessionStorage.getItem('M2'))) {
            document.getElementById('foo14').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo14').style.cssText = 'color: black; '
        }

        let a = findIndex(Object.keys(sessionStorage), 'm2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction15 = async () => {
        if (JSON.parse(sessionStorage.getItem('isSmileThree')) == true) {
            setToastMsg('Disable Smile 3 To Enable M3')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('M3'))
        if (await JSON.parse(sessionStorage.getItem('M3'))) {
            document.getElementById('foo15').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo15').style.cssText = 'color: black; '
        }

        let a = findIndex(Object.keys(sessionStorage), 'm3')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction16 = async () => {
        if (JSON.parse(sessionStorage.getItem('isSmileFour')) == true) {
            setToastMsg('Disable Smile 4 To Enable M4')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('M4'))
        if (await JSON.parse(sessionStorage.getItem('M4'))) {
            document.getElementById('foo16').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo16').style.cssText = 'color: black; '
            setM3Mp3(false)
        }

        let a = findIndex(Object.keys(sessionStorage), 'm4')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }

    const myFunction17 = async () => {
        if (
            JSON.parse(sessionStorage.getItem('isTouchZero')) ||
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) == true
        ) {
            setToastMsg('Disable Touch Pad 0 To Enable S1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('S1'))
        if (await JSON.parse(sessionStorage.getItem('S1'))) {
            document.getElementById('foo17').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo17').style.cssText = 'color: black; '
            if (sessionStorage.getItem('S1Servo') !== null)
                sessionStorage.setItem('S1Servo', false)
        }

        let a = findIndex(Object.keys(sessionStorage), 's1')
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }

    const myFunction18 = async () => {
        if (
            JSON.parse(sessionStorage.getItem('isTouchOne')) ||
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) == true
        ) {
            setToastMsg('Disable Touch Pad 0 To Enable S2')
            setToastActive(true)
            disableToast()
        }
        await JSON.parse(sessionStorage.getItem('S2'))
        if (await JSON.parse(sessionStorage.getItem('S2'))) {
            document.getElementById('foo18').style.cssText = 'color: white; '
        } else {
            document.getElementById('foo18').style.cssText = 'color: black; '
            if (sessionStorage.getItem('S2Servo') !== null)
                sessionStorage.setItem('S2Servo', false)
        }

        let a = findIndex(Object.keys(sessionStorage), 's2')
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    const [ishelpButton, sethelpButton] = useState(false)
    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    useEffect(() => {
        let assetsToBeCached = [selectBgimg]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <>
            <div className="HeaderContainer">
                <ProgramHeader
                    data={{
                        options: [
                            'Select Ports',
                            'Input/Output',
                            'Digital/Analog',
                            'Flowchart',
                        ],
                        selected: 0,
                    }}
                    showSave={true}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
            </div>

            {/* Toast msg */}
            <div
                className={`toast-msg
        ${isToastActive ? 'toast-msg-active' : 'toast-msg-inactive'}`}
                ref={toastRef}
            >
                {toastMsg}
            </div>

            <div className="MainContainer">
                {!isPCv1 && (
                    <div
                        className="CenterImg"
                        style={{ left: '5rem', top: '-2vh' }}
                    >
                        <img
                            src={pcImg}
                            style={{
                                width: '85%',
                                zIndex: '110',
                                top: '7%',
                                position: 'relative',
                            }}
                        />
                        <div className="deviceContainer">
                            {isEyeLeft ? (
                                <img
                                    src={PcinternalEYEActive}
                                    className="imgStyleEyeL"
                                />
                            ) : (
                                <img
                                    src={PcinternalEYEInActive}
                                    className="imgStyleEyeL"
                                />
                            )}
                            {isEyeRight ? (
                                <img
                                    src={PcinternalEYEActive}
                                    className="imgStyleEyeR"
                                />
                            ) : (
                                <img
                                    src={PcinternalEYEInActive}
                                    className="imgStyleEyeR"
                                />
                            )}
                            {isSimeleOne ? (
                                <img
                                    src={PcinternalTeethActive}
                                    className="imgStyleTeeth1"
                                />
                            ) : (
                                <img
                                    src={PcinternalTeethInActive}
                                    className="imgStyleTeeth1"
                                />
                            )}

                            {isSimeleTwo ? (
                                <img
                                    src={PcinternalTeethActive}
                                    className="imgStyleTeeth2"
                                />
                            ) : (
                                <img
                                    src={PcinternalTeethInActive}
                                    className="imgStyleTeeth2"
                                />
                            )}

                            {isSimeleThree ? (
                                <img
                                    src={PcinternalTeethActive}
                                    className="imgStyleTeeth3"
                                />
                            ) : (
                                <img
                                    src={PcinternalTeethInActive}
                                    className="imgStyleTeeth3"
                                />
                            )}

                            {isSimeleFour ? (
                                <img
                                    src={PcinternalTeethActive}
                                    className="imgStyleTeeth4"
                                />
                            ) : (
                                <img
                                    src={PcinternalTeethInActive}
                                    className="imgStyleTeeth4"
                                />
                            )}

                            {isDistanceSensors ||
                            isColorSensor ||
                            isGestureSensor ||
                            isLightSensor ? (
                                <img
                                    src={Pcinternal4in1Active}
                                    className="imgStyle4in1"
                                />
                            ) : (
                                <img
                                    src={Pcinternal4in1InActive}
                                    className="imgStyle4in1"
                                />
                            )}

                            {isMic ? (
                                <img
                                    src={PcinternalMicActive}
                                    className="imgStyleMic"
                                />
                            ) : (
                                <img
                                    src={PcinternalMicInActive}
                                    className="imgStyleMic"
                                />
                            )}
                            {isbuzzer ? (
                                <img
                                    src={PcinternalBuzzerActive}
                                    className="imgStyleBuzzer"
                                />
                            ) : (
                                <img
                                    src={PcinternalBuzzerInActive}
                                    className="imgStyleBuzzer"
                                />
                            )}

                            {isTouchZero ? (
                                <img
                                    src={PcinternalTouchpadsActive}
                                    className="imgStyleTouchpads1"
                                />
                            ) : (
                                <img
                                    src={PcinternalTouchpadsInActive}
                                    className="imgStyleTouchpads1"
                                />
                            )}
                            {isTouchOne ? (
                                <img
                                    src={PcinternalTouchpadsActive}
                                    className="imgStyleTouchpads2"
                                />
                            ) : (
                                <img
                                    src={PcinternalTouchpadsInActive}
                                    className="imgStyleTouchpads2"
                                />
                            )}

                            {isTouchTwo ? (
                                <img
                                    src={PcinternalTouchpadsActive}
                                    className="imgStyleTouchpads3"
                                />
                            ) : (
                                <img
                                    src={PcinternalTouchpadsInActive}
                                    className="imgStyleTouchpads3"
                                />
                            )}
                        </div>
                    </div>
                )}

                {isPCv1 && (
                    <div
                        className="CenterImg"
                        style={{ left: '5rem', top: '-4vh' }}
                    >
                        <img
                            src={PCv1}
                            style={{
                                width: '87%',
                                zIndex: '110',
                                top: '7%',
                                position: 'relative',
                            }}
                        />
                        <div className="deviceContainer">
                            <div>
                                {[26.8, 32.8, 38.8, 45, 50.5, 57].map(
                                    (value, index) => {
                                        return (
                                            <>
                                                <img
                                                    src={
                                                        isInternalEye
                                                            ? renderPrgImage(
                                                                  'PcinternalEYEActive_v1'
                                                              )
                                                            : renderPrgImage(
                                                                  'PcinternalEYEInActive_v1'
                                                              )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        width: '3.25%',
                                                        left: `${value}%`,
                                                        top: '38.5%',
                                                        zIndex: 1000,
                                                    }}
                                                    draggable="false"
                                                />
                                                <img
                                                    src={
                                                        isInternalEye
                                                            ? renderPrgImage(
                                                                  'PcinternalEYEActive_v1'
                                                              )
                                                            : renderPrgImage(
                                                                  'PcinternalEYEInActive_v1'
                                                              )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        width: '3.25%',
                                                        left: `${value}%`,
                                                        top: '42.5%',
                                                        zIndex: 1000,
                                                    }}
                                                    draggable="false"
                                                />
                                            </>
                                        )
                                    }
                                )}
                            </div>

                            <img
                                src={
                                    isSimeleOne
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                                style={{
                                    position: 'absolute',
                                    width: '1.7%',
                                    height: '10%',
                                    left: '37%',
                                    top: '55.8%',
                                    zIndex: 1000,
                                }}
                            />
                            <img
                                src={
                                    isSimeleTwo
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                                style={{
                                    position: 'absolute',
                                    width: '1.7%',
                                    height: '10%',
                                    left: '39.5%',
                                    top: '58%',
                                    zIndex: 1000,
                                }}
                            />

                            <img
                                src={
                                    isSimeleThree
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                                style={{
                                    position: 'absolute',
                                    width: '1.7%',
                                    height: '10%',
                                    left: '45.8%',
                                    top: '58%',
                                    zIndex: 1000,
                                }}
                            />

                            <img
                                src={
                                    isSimeleFour
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                                style={{
                                    position: 'absolute',
                                    width: '1.7%',
                                    height: '10%',
                                    left: '48%',
                                    top: '56%',
                                    zIndex: 1000,
                                }}
                            />

                            <img
                                src={
                                    isDistanceSensors ||
                                    isColorSensor ||
                                    isGestureSensor ||
                                    isLightSensor
                                        ? renderPrgImage(
                                              'Pcinternal4in1Active_v1'
                                          )
                                        : renderPrgImage(
                                              'Pcinternal4in1InActive_v1'
                                          )
                                }
                                style={{
                                    position: 'absolute',
                                    width: '3%',
                                    left: '42%',
                                    top: '59.5%',
                                    zIndex: 1000,
                                }}
                            />

                            <img
                                src={
                                    isMic
                                        ? PcinternalMicActive
                                        : PcinternalMicInActive
                                }
                                style={{
                                    position: 'absolute',
                                    width: '8%',
                                    left: '39.5%',
                                    top: '-17%',
                                    zIndex: 1000,
                                }}
                            />
                            <img
                                src={
                                    isbuzzer
                                        ? PcinternalBuzzerActive
                                        : PcinternalBuzzerInActive
                                }
                                style={{
                                    position: 'absolute',
                                    width: '80%',
                                    left: '3%',
                                    top: '-3%',
                                    zIndex: 1000,
                                }}
                            />

                            <img
                                style={{
                                    position: 'absolute',
                                    bottom: '6%',
                                    left: '10%',
                                    width: '16%',
                                    height: '15%',
                                    borderRadius: '15% 26% 1% 13%',
                                    background:
                                        isTouchZero || isTouchZeroOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                    zIndex: 1000,
                                }}
                                draggable="false"
                            />

                            <img
                                style={{
                                    position: 'absolute',
                                    bottom: '6%',
                                    left: '27%',
                                    width: '16%',
                                    height: '15%',
                                    borderRadius: '15% 26% 1% 13%',
                                    background:
                                        isTouchOne || isTouchOneOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                    zIndex: 1000,
                                }}
                                draggable="false"
                            />

                            <img
                                style={{
                                    position: 'absolute',
                                    bottom: '6%',
                                    left: '44%',
                                    width: '16%',
                                    height: '15%',
                                    borderRadius: '15% 26% 1% 13%',
                                    background:
                                        isTouchTwo || isTouchTwoOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                    zIndex: 1000,
                                }}
                                draggable="false"
                            />

                            <img
                                style={{
                                    position: 'absolute',
                                    bottom: '6%',
                                    left: '61%',
                                    width: '16%',
                                    height: '15%',
                                    borderRadius: '15% 26% 1% 13%',
                                    background:
                                        isTouchThree || isTouchThreeOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                    zIndex: 1000,
                                }}
                                draggable="false"
                            />
                        </div>
                    </div>
                )}
                <div
                    className={`page2-content ${
                        isAnimating ? 'slide-out2' : ''
                    }`}
                    style={{ height: '100vh', width: '100vw' }}
                >
                    <div className="ports-Container">
                        <div className="ButtonDiv">
                            {/* A PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            'InputLabel-' +
                                            (!isPCv1 &&
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchZero'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchZeroOutput'
                                                        )
                                                    )))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchZero'
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchZeroOutput'
                                                        )
                                                    )
                                            )}`}
                                            type="checkbox"
                                            checked={a1}
                                            onClick={() => myFunction1()}
                                            onChange={() => onA1ValueChange()}
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isTouchZero")) ||
                                            //   JSON.parse(sessionStorage.getItem("isTouchZeroOutput"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo1"
                                            style={{ color: bttnColor[0] }}
                                        >
                                            A1
                                        </span>
                                    </span>
                                    <span
                                        className={
                                            'InputLabel ' +
                                            'InputLabel-' +
                                            (!isPCv1 &&
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchZero'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchZeroOutput'
                                                        )
                                                    )))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchZero'
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchZeroOutput'
                                                        )
                                                    )
                                            )}`}
                                            type="checkbox"
                                            checked={a2}
                                            onClick={() => myFunction2()}
                                            onChange={() => onA2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo2"
                                            style={{ color: bttnColor[1] }}
                                        >
                                            A2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* B PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            (!isPCv1 &&
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchOne'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchOneOutput'
                                                        )
                                                    )))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchOne'
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchOneOutput'
                                                        )
                                                    )
                                            )}`}
                                            type="checkbox"
                                            checked={b1}
                                            onClick={() => myFunction3()}
                                            onChange={() => onB1ValueChange()}
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isTouchOne")) ||
                                            //   JSON.parse(sessionStorage.getItem("isTouchOneOutput"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo3"
                                            style={{ color: bttnColor[2] }}
                                        >
                                            B1
                                        </span>
                                    </span>
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            (!isPCv1 &&
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchOne'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchOneOutput'
                                                        )
                                                    )))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchOne'
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchOneOutput'
                                                        )
                                                    )
                                            )}`}
                                            type="checkbox"
                                            checked={b2}
                                            onClick={() => myFunction4()}
                                            onChange={() => onB2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo4"
                                            style={{ color: bttnColor[3] }}
                                        >
                                            B2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* E PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            isPCv1
                                                ? 'InputLabel'
                                                : 'InputLabel-true'
                                        }
                                    >
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={e1}
                                            onClick={() => myFunction9()}
                                            onChange={() => onE1ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo9"
                                            style={{ color: bttnColor[8] }}
                                        >
                                            E1
                                        </span>
                                    </span>
                                    <span
                                        className={
                                            isPCv1
                                                ? 'InputLabel'
                                                : 'InputLabel-true'
                                        }
                                    >
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={e2}
                                            onClick={() => myFunction10()}
                                            onChange={() => onE2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo10"
                                            style={{ color: bttnColor[9] }}
                                        >
                                            E2
                                        </span>
                                    </span>
                                </div>
                            </div>

                            {/* M PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel' +
                                            ' InputLabel-' +
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileOne'
                                                )
                                            )
                                        }
                                    >
                                        {/* testing-123 */}
                                        <input
                                            // className="InputCheckBox"
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileOne'
                                                )
                                            )}`}
                                            type="checkbox"
                                            checked={m1}
                                            onClick={() => myFunction13()}
                                            onChange={() => onL1ValueChange()}
                                            // disabled={JSON.parse(sessionStorage.getItem("isSmileOne"))}
                                        />
                                        <span
                                            className="A1"
                                            id="foo13"
                                            style={{ color: bttnColor[12] }}
                                        >
                                            L1
                                        </span>
                                    </span>
                                    <span
                                        className={
                                            'InputLabel' +
                                            ' InputLabel-' +
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileTwo'
                                                )
                                            )
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileTwo'
                                                )
                                            )}`}
                                            type="checkbox"
                                            checked={m2}
                                            onClick={() => myFunction14()}
                                            onChange={() => onL2ValueChange()}
                                            // disabled={JSON.parse(sessionStorage.getItem("isSmileTwo"))}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo14"
                                            style={{ color: bttnColor[13] }}
                                        >
                                            L2
                                        </span>
                                    </span>
                                </div>
                            </div>

                            {/* S PORT */}
                            {isPCv1 && (
                                <div
                                    className="tlb"
                                    style={{
                                        marginBottom: '100px',
                                    }}
                                >
                                    <div className="tlbIn ">
                                        <span
                                            className={
                                                'InputLabel ' +
                                                'InputLabel-' +
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchZero'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchZeroOutput'
                                                        )
                                                    ))
                                            }
                                        >
                                            {/* testing-123 */}
                                            <input
                                                // className="InputCheckBox"
                                                className={`InputCheckBox  disabled_-${JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchZero'
                                                    ) ||
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'isTouchZeroOutput'
                                                            )
                                                        )
                                                )}`}
                                                type="checkbox"
                                                checked={s1}
                                                onClick={() => myFunction17()}
                                                onChange={() =>
                                                    onS1ValueChange()
                                                }
                                                // disabled={JSON.parse(sessionStorage.getItem("isSmileOne"))}
                                            />
                                            <span
                                                className="A1"
                                                id="foo17"
                                                style={{ color: bttnColor[16] }}
                                            >
                                                S1
                                            </span>
                                        </span>
                                        <span
                                            className={
                                                'InputLabel ' +
                                                'InputLabel-' +
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchOne'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchOneOutput'
                                                        )
                                                    ))
                                            }
                                        >
                                            <input
                                                className={`InputCheckBox  disabled_-${JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchOne'
                                                    ) ||
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'isTouchOneOutput'
                                                            )
                                                        )
                                                )}`}
                                                type="checkbox"
                                                checked={s2}
                                                onClick={() => myFunction18()}
                                                onChange={() =>
                                                    onS2ValueChange()
                                                }
                                                // disabled={JSON.parse(sessionStorage.getItem("isSmileTwo"))}
                                            />
                                            <span
                                                disabled="disabled"
                                                className="A1"
                                                id="foo18"
                                                style={{ color: bttnColor[17] }}
                                            >
                                                S2
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="ButtonRightDiv">
                            {/* C PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            (!isPCv1 &&
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwo'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchTwoOutput'
                                                        )
                                                    )))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwo'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwoOutput'
                                                    )
                                                )
                                            }`}
                                            type="checkbox"
                                            checked={c1}
                                            onClick={() => myFunction5()}
                                            onChange={() => onC1ValueChange()}
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isTouchTwo")) ||
                                            //   JSON.parse(sessionStorage.getItem("isTouchTwoOutput"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo5"
                                            style={{ color: bttnColor[4] }}
                                        >
                                            C1
                                        </span>
                                    </span>
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            (!isPCv1 &&
                                                (JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwo'
                                                    )
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchTwoOutput'
                                                        )
                                                    )))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwo'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwoOutput'
                                                    )
                                                )
                                            }`}
                                            type="checkbox"
                                            checked={c2}
                                            onClick={() => myFunction6()}
                                            onChange={() => onC2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo6"
                                            style={{ color: bttnColor[5] }}
                                        >
                                            C2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* D PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span>
                                        <input
                                            className={`InputCheckBox`}
                                            type="checkbox"
                                            checked={d1}
                                            onClick={() => myFunction7()}
                                            onChange={() => onD1ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo7"
                                            style={{ color: bttnColor[6] }}
                                        >
                                            D1
                                        </span>
                                    </span>
                                    <span className="InputLabel">
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={d2}
                                            onClick={() => myFunction8()}
                                            onChange={() => onD2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo8"
                                            style={{ color: bttnColor[7] }}
                                        >
                                            D2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* F PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span className="InputLabel">
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={f1}
                                            onClick={() => myFunction11()}
                                            onChange={() => onF1ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo11"
                                            style={{ color: bttnColor[10] }}
                                        >
                                            F1
                                        </span>
                                    </span>
                                    <span className="InputLabel">
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={f2}
                                            onClick={() => myFunction12()}
                                            onChange={() => onF2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo12"
                                            style={{ color: bttnColor[11] }}
                                        >
                                            F2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* M3 PORT */}

                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileThree'
                                                )
                                            )
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileThree'
                                                )
                                            )}`}
                                            type="checkbox"
                                            checked={m3}
                                            onClick={() => myFunction15()}
                                            onChange={() => onL3ValueChange()}
                                            // disabled={JSON.parse(
                                            //   sessionStorage.getItem("isSmileThree")
                                            // )}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo15"
                                            style={{ color: bttnColor[14] }}
                                        >
                                            L3
                                        </span>
                                    </span>
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileFour'
                                                )
                                            )
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isSmileFour'
                                                )
                                            )}`}
                                            type="checkbox"
                                            checked={m4}
                                            onClick={() => myFunction16()}
                                            onChange={() => onL4ValueChange()}
                                            // disabled={JSON.parse(sessionStorage.getItem("isSmileFour"))}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo16"
                                            style={{ color: bttnColor[15] }}
                                        >
                                            L4
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="SelectScreenBottom">
                <div className="bottom-child">
                    {/* <Link to="/programSelection"> */}
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    {/* </Link> */}
                    <div
                        className="decription"
                        style={{
                            fontFamily: 'Halcyon_SemiBold',
                            zIndex: '999',
                        }}
                    >
                        <p>Select the port you want to use</p>
                    </div>

                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('nextBtn')}
                        onClick={next}
                    />
                </div>
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '1000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        {/* First screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img src={selectBgimg} style={{ width: '100%' }} />

                            <div
                                style={{
                                    height: '6%',
                                    width: '5%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '41.5%',
                                    left: '41.2%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '2px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                    }}
                                >
                                    All the accessible ports are available as a
                                    scrollable. To enable a port, click on the
                                    port name buttons
                                </p>
                            </div>
                        </div>
                        {/* second screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img src={selectBgimg} style={{ width: '100%' }} />
                            <div
                                style={{
                                    height: '6%',
                                    width: '5%',
                                    // zIndex: "2000",
                                    position: 'absolute',
                                    top: '40%',
                                    left: '57.7%',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                    background: '#fff',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '2px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular ',
                                        borderRadius: '12px',
                                    }}
                                >
                                    You can enable all the ports or the ones you
                                    need. Note: Only those ports enable here
                                    will be available in the next set of screens
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </>
    )
}

// export default Port;

const mapStateToProps = (state) => {
    console.log('mapStateToProps', state)

    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialConnect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Port)
