import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import Bottom from '../Bottom'
import { Nav } from 'react-bootstrap'
import '../button.scss'

import useLocalStorage from '../../LocalStorage/LocalStorage'
import pcImg from '../../../Assets/internalAccessories/PC_image@3x.png'
import inputImg from '../../../Assets/img/assemble bar@2x.png'
import secondaryImg from '../../../Assets/img/save - secondary.png'
import strokeImg from '../../../Assets/img/button 52x52 - stroke.png'
import connectionImg from '../../../Assets/usb - off@2x.png'
import { useHistory } from 'react-router-dom'
import renderPrgImage from '../../../source/programImg'

import { connect } from 'react-redux'
import { webSerialAction } from '../../../redux/actions'

import '../pcimage.css'
import '../Navbar.css'
import '../style.css'

import inputInactiveURAT from '../../../Assets/img/inputInactiveURAT.png'
import inputActiveURAT from '../../../Assets/img/inputURATimg.png'
import inputActiveSPimg from '../../../Assets/img/inputSPIimg.png'
import inputActive2Cimg from '../../../Assets/img/input2Cimg.png'
import closeBtn from '../../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderImage from '../../../source/importImg'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import { PORTDATA } from '../../Humanoid/defaultData'
import selectStyle from '../../Humanoid/HumanoidSelectFeatures.module.css' //'./HumanoidSelectFeatures.module.css'
import { generateDeviceDataKey } from '../../ReusableComponents/ByteTransfer/utils'
let connectedDevice = sessionStorage.connectedDevice
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

let bttnColor = []
let bttnColor2 = []
let bttType = [
    'a1-I/O',
    'a2-I/O',
    'b1-I/O',
    'b2-I/O',
    'c1-I/O',
    'c2-I/O',
    'd1-I/O',
    'd2-I/O',
]
for (let i = 0; i < 16; i++) {
    bttnColor[i] = '#717171'
    bttnColor2[i] = '#fcfcfc'
    if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
        bttnColor[i] = '#fcfcfc'
        bttnColor2[i] = '#717171'
    }
}
function InputOutput(props) {
    const history = useHistory()
    const [ishelpButton, sethelpButton] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const deviceVersionNo = sessionStorage.getItem('deviceVersion')
    const connectedDeviceName = sessionStorage.getItem('connectedDevice')
    const humanoidPortData = JSON.parse(
        sessionStorage.getItem('humanoidPortData')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode

        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const backBtnAction = () => {
        history.push('/flow/selectports')
    }

    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')
    const next = () => {
        saveDataToLocalStorage()
        history.push('/flow/digital-analog')
    }

    useLayoutEffect(() => {
        return () => {
            for (let i = 0; i < 16; i++) {
                if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
                    bttnColor[i] = '#fcfcfc'
                    bttnColor2[i] = '#717171'
                } else {
                    bttnColor[i] = '#717171'
                    bttnColor2[i] = '#fcfcfc'
                }
            }
        }
    })

    const A1 = JSON.parse(sessionStorage.getItem('A1'))
    const A2 = JSON.parse(sessionStorage.getItem('A2'))
    const B1 = JSON.parse(sessionStorage.getItem('B1'))
    const B2 = JSON.parse(sessionStorage.getItem('B2'))
    const C1 = JSON.parse(sessionStorage.getItem('C1'))
    const C2 = JSON.parse(sessionStorage.getItem('C2'))
    const D1 = JSON.parse(sessionStorage.getItem('D1'))
    const D2 = JSON.parse(sessionStorage.getItem('D2'))

    const [a1Checked, setA1Checked] = useLocalStorage('a1-I/O', false)
    const [a2Checked, setA2Checked] = useLocalStorage('a2-I/O', false)
    const [b1Checked, setB1Checked] = useLocalStorage('b1-I/O', false)
    const [b2Checked, setB2Checked] = useLocalStorage('b2-I/O', false)
    const [c1Checked, setC1Checked] = useLocalStorage('c1-I/O', false)
    const [c2Checked, setC2Checked] = useLocalStorage('c2-I/O', false)
    const [d1Checked, setD1Checked] = useLocalStorage('d1-I/O', false)
    const [d2Checked, setD2Checked] = useLocalStorage('d2-I/O', false)

    const [aUltra, setAUltra] = useLocalStorage('AUltra', false)
    const [cUltra, setCUltra] = useLocalStorage('CUltra', false)
    const [dUltra, setDUltra] = useLocalStorage('DUltra', false)
    // useEffect(() => {
    //     if (dUltra) {
    //         setD1Checked(true)
    //         setD2Checked(false)
    //     } else {
    //         setD1Checked(true)
    //         setD2Checked(true)
    //     }
    // }, [dUltra])

    //if change input to output that time in condition Panel all state goes to default position
    const defaultValeConditionPanel = (portNo) => {
        let humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )
        const newHumanoidProgramData = { ...humanoidProgramData }
        Object.keys(newHumanoidProgramData).map((key) => {
            let nodeState = newHumanoidProgramData[key].state

            if (
                newHumanoidProgramData[key].type === 'if' &&
                nodeState.port == portNo
            ) {
                nodeState.condition = 'gt'
                nodeState.port = 'battery'
                nodeState.value = 0
                nodeState.value1 = 0
                nodeState.value2 = 0
            }
        })
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newHumanoidProgramData)
        )
    }

    const defaultValeActionPanel = (portNo) => {
        const PORTDATAKey = generateDeviceDataKey(
            connectedDevice,
            deviceVersionNo
        )
        let humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )
        const newHumanoidProgramData = { ...humanoidProgramData }
        Object.keys(newHumanoidProgramData).map((key) => {
            let nodeState = newHumanoidProgramData[key].state

            if (newHumanoidProgramData[key].type === 'output') {
                // nodeState[portNo] = HUMANOIDPORTDATA[portNo]
                nodeState[portNo].value = PORTDATA[PORTDATAKey][portNo].value
                nodeState[portNo].isActionEnabled = false
            }
        })
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newHumanoidProgramData)
        )
    }

    const a1CheckedState = async () => {
        if (!A1 || aUltra) {
            setToastMsg('Enable A1')
            setToastActive(true)
            disableToast()
        }

        if (aUltra) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!A1 || aUltra) return
        setA1Checked(!a1Checked)
        sessionStorage.setItem('A1Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('a1-I/O')))) {
            document.getElementById('in1').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s1').style.cssText = 'color: #717171;'
            if (humanoidPortData[31].type !== 'input') {
                defaultValeActionPanel(31)
            }
            humanoidPortData[31].type = 'input'
        } else {
            document.getElementById('in1').style.cssText = 'color: #717171; '
            document.getElementById('s1').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[31].type !== 'output') {
                defaultValeConditionPanel(31)
            }
            humanoidPortData[31].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const a2CheckedState = async () => {
        if (!A2 || aUltra) {
            setToastMsg('Enable A2 To Enable A2 Input or Output ')
            setToastActive(true)
            disableToast()
        }
        if (aUltra) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!A2 || aUltra) return
        setA2Checked(!a2Checked)
        await JSON.parse(sessionStorage.getItem('a2-I/O'))
        sessionStorage.setItem('A2Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('a2-I/O')))) {
            document.getElementById('in2').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s2').style.cssText = 'color: #717171;'
            if (humanoidPortData[32].type !== 'input') {
                defaultValeActionPanel(32)
            }
            humanoidPortData[32].type = 'input'
        } else {
            document.getElementById('in2').style.cssText = 'color: #717171; '
            document.getElementById('s2').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[32].type !== 'output') {
                defaultValeConditionPanel(32)
            }
            humanoidPortData[32].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const b1CheckedState = async () => {
        if (!B1) {
            setToastMsg('Enable B1 To Enable B1 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }

        setB1Checked(!b1Checked)
        await JSON.parse(sessionStorage.getItem('b1-I/O'))
        sessionStorage.setItem('B1Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('b1-I/O')))) {
            document.getElementById('in3').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s3').style.cssText = 'color: #717171;'
            if (humanoidPortData[33].type !== 'input') {
                defaultValeActionPanel(33)
            }
            humanoidPortData[33].type = 'input'
        } else {
            document.getElementById('in3').style.cssText = 'color: #717171; '
            document.getElementById('s3').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[33].type !== 'output') {
                defaultValeConditionPanel(33)
            }
            humanoidPortData[33].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const b2CheckedState = async () => {
        if (!B2) {
            setToastMsg('Enable B2 To Enable B2 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }

        setB2Checked(!b2Checked)
        await JSON.parse(sessionStorage.getItem('b2-I/O'))
        sessionStorage.setItem('B2Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('b2-I/O')))) {
            document.getElementById('in4').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s4').style.cssText = 'color: #717171;'
            if (humanoidPortData[34].type !== 'input') {
                defaultValeActionPanel(34)
            }
            humanoidPortData[34].type = 'input'
        } else {
            document.getElementById('in4').style.cssText = 'color: #717171; '
            document.getElementById('s4').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[34].type !== 'output') {
                defaultValeConditionPanel(34)
            }
            humanoidPortData[34].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const c1CheckedState = async () => {
        if (!C1 || cUltra) {
            setToastMsg('Enable C1 To Enable C1 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }
        if (cUltra) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (!C1 || cUltra) return
        setC1Checked(!c1Checked)
        await JSON.parse(sessionStorage.getItem('c1-I/O'))
        sessionStorage.setItem('C1Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('c1-I/O')))) {
            document.getElementById('in5').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s5').style.cssText = 'color: #717171;'
            if (humanoidPortData[35].type !== 'input') {
                defaultValeActionPanel(35)
            }
            humanoidPortData[35].type = 'input'
        } else {
            document.getElementById('in5').style.cssText = 'color: #717171; '
            document.getElementById('s5').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[35].type !== 'output') {
                defaultValeConditionPanel(35)
            }
            humanoidPortData[35].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const c2CheckedState = async () => {
        if (!C2 || cUltra) {
            setToastMsg('Enable C2 To Enable C2 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }
        if (cUltra) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (!C2 || cUltra) return
        setC2Checked(!c2Checked)
        await JSON.parse(sessionStorage.getItem('c2-I/O'))
        sessionStorage.setItem('C2Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('c2-I/O')))) {
            document.getElementById('in6').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s6').style.cssText = 'color: #717171;'
            if (humanoidPortData[36].type !== 'input') {
                defaultValeActionPanel(36)
            }
            humanoidPortData[36].type = 'input'
        } else {
            document.getElementById('in6').style.cssText = 'color: #717171; '
            document.getElementById('s6').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[36].type !== 'output') {
                defaultValeConditionPanel(36)
            }
            humanoidPortData[36].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const d1CheckedState = async () => {
        if (!D1 || dUltra) {
            setToastMsg('Enable D1')
            setToastActive(true)
            disableToast()
        }

        if (dUltra) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        // if (D1) {
        //     setToastMsg(' Only output can be enabled ')
        //     setToastActive(true)
        //     disableToast()
        //     return
        // }
        if (!D1 || dUltra) return
        setD1Checked(!d1Checked)
        sessionStorage.setItem('D1Servo', false)
        sessionStorage.setItem('D1Servo', false)

        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )

        if (!(await JSON.parse(sessionStorage.getItem('d1-I/O')))) {
            document.getElementById('in7').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s7').style.cssText = 'color: #717171;'
            if (humanoidPortData[37].type !== 'input') {
                defaultValeActionPanel(37)
            }
            humanoidPortData[37].type = 'input'
        } else {
            document.getElementById('in7').style.cssText = 'color: #717171; '
            document.getElementById('s7').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[37].type !== 'output') {
                defaultValeConditionPanel(37)
            }
            humanoidPortData[37].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const d2CheckedState = async () => {
        if (!D2 || dUltra) {
            setToastMsg('Enabled D2 to Enabled D2 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (dUltra) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        // if (D2) {
        //     setToastMsg(' Only output can be enabled ')
        //     setToastActive(true)
        //     disableToast()
        //     return
        // }
        if (!D2 || dUltra) return
        setD2Checked(!d2Checked)
        sessionStorage.setItem('D2Servo', false)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('d2-I/O')))) {
            document.getElementById('in8').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s8').style.cssText = 'color: #717171;'
            if (humanoidPortData[38].type !== 'input') {
                defaultValeActionPanel(38)
            }
            humanoidPortData[38].type = 'input'
        } else {
            document.getElementById('in8').style.cssText = 'color: #717171; '
            document.getElementById('s8').style.cssText = '  color: #fcfcfc;'
            if (humanoidPortData[38].type !== 'output') {
                defaultValeConditionPanel(38)
            }
            humanoidPortData[38].type = 'output'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const UltraA = () => {
        if (!A1 || !A2) {
            console.log('hiii ')
            setToastMsg(' Enable A1 and A2')
            setToastActive(true)
            disableToast()
            return
        }

        setAUltra(!aUltra)
        // setA1Checked(true)
        // setA2Checked(false)
        setA1Checked(false)
        // setA2Checked(true)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!aUltra) {
            humanoidPortData[31].type = 'input'
            humanoidPortData[32].type = 'output'
            humanoidPortData[31].device = 'ultrasonic'
            humanoidPortData[32].device = 'ultrasonic'
            setA2Checked(true)
        } else {
            humanoidPortData[31].device = null
            humanoidPortData[32].device = null
            humanoidPortData[32].type = 'input'
            setA2Checked(false)
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const UltraC = () => {
        if (!C1 || !C2) {
            setToastMsg(' Enable C1 and C2')
            setToastActive(true)
            disableToast()
            return
        }

        setCUltra(!cUltra)
        setC1Checked(false)

        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!cUltra) {
            humanoidPortData[35].type = 'input'
            humanoidPortData[36].type = 'output'
            humanoidPortData[35].device = 'ultrasonic'
            humanoidPortData[36].device = 'ultrasonic'
            setC2Checked(true)
        } else {
            humanoidPortData[35].device = null
            humanoidPortData[36].type = 'input'
            humanoidPortData[36].device = null
            setC2Checked(false)
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const UltraD = () => {
        if (!D1 || !D2) {
            setToastMsg(' Enable D1 and D2')
            setToastActive(true)
            disableToast()
            return
        }

        setDUltra(!dUltra)
        setD1Checked(false)
        setD2Checked(true)
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!dUltra) {
            humanoidPortData[37].type = 'input'
            humanoidPortData[38].type = 'output'
            humanoidPortData[37].device = 'ultrasonic'
            humanoidPortData[38].device = 'ultrasonic'
        } else {
            humanoidPortData[37].device = null
            humanoidPortData[38].device = null
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    return (
        <>
            <ProgramHeader
                data={{
                    options: [
                        'Select Ports',
                        'Input/Output',
                        'Digital/Analog',
                        'Flowchart',
                    ],
                    selected: 1,
                }}
                showSave={true}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />

            <div className="MainContainerInput">
                {/* <div className="CenterImgs">
                    <img
                        src={renderImage('Zing')}
                        style={{
                            position: 'absolute',
                            left: '80%',
                            top: '-6vh',
                            transform: 'translate(-50%,-50%)',
                        }}
                    />
                    <img
                        // className={selectStyle['select-buzzer']}
                        style={{
                            position: 'absolute',
                            left: '80%',
                            top: '-29vh',
                            transform: 'translate(-50%,-50%)',
                            width: '80%',
                        }}
                        src={
                            humanoidPortData[2].isPortSelected
                                ? renderImage('activeBuzzer')
                                : renderImage('inactiveBuzzer')
                        }
                    />
                </div> */}
                <div
                    className={selectStyle['select-humanoid']}
                    style={{
                        backgroundImage: `url(${renderImage('humanoid')})`,
                        top: '26%',
                        left: '15%',
                        backgroundSize: '100% 80%',
                    }}
                >
                    {/* <img className={selectStyle["select-buzzer"]} src={isBuzzer.img} /> */}
                    <img
                        className={selectStyle['select-leftEye']}
                        src={
                            humanoidPortData[0].isPortSelected
                                ? renderImage('activeEye')
                                : renderImage('inactiveEye')
                        }
                        style={{ top: '15.8%', left: '46%' }}
                    />
                    <img
                        className={selectStyle['select-rightEye']}
                        src={
                            humanoidPortData[1].isPortSelected
                                ? renderImage('activeEye')
                                : renderImage('inactiveEye')
                        }
                        style={{ top: '15.8%', left: '54.1%' }}
                    />
                    <img
                        className={selectStyle['select-buzzer']}
                        src={
                            humanoidPortData[2].isPortSelected
                                ? renderImage('activeBuzzer')
                                : renderImage('inactiveBuzzer')
                        }
                        style={{ top: '17.3%', width: '100%' }}
                    />
                </div>

                <div
                    className={`page2-content ${
                        isAnimating ? 'slide-out2' : ''
                    }`}
                    style={{ width: '100vw' }}
                >
                    <div className="Inputs-ports-Container">
                        <div
                            className="ButtonDivInput"
                            style={{ marginTop: '100px' }}
                        >
                            <div className="Inputs-flow-left-upper-ultrasonic">
                                <div className="Inputs-flow-left-upper-grp-ultrasonic">
                                    <label
                                        className={
                                            A1 &&
                                            !aUltra + 'input upper-label-input'
                                        }
                                    >
                                        <span
                                            className={
                                                ((A1 && !aUltra) || false) +
                                                '-span textsp'
                                            }
                                        >
                                            A1
                                        </span>

                                        <div
                                            className={
                                                'switch-button-' +
                                                ((A1 && !aUltra) || false)
                                            }
                                            id="s1"
                                            style={{
                                                color: bttnColor[0],
                                            }}
                                        >
                                            {/* <div> */}
                                            <input
                                                checked={a1Checked}
                                                onChange={a1CheckedState}
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                            ></input>
                                            {/* </div> */}

                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in1"
                                                    style={{
                                                        color: bttnColor2[0],
                                                    }}
                                                >
                                                    Input
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={
                                            A2 &&
                                            !aUltra + 'input upper-label-input'
                                        }
                                    >
                                        <span
                                            className={
                                                ((A2 && !aUltra) || false) +
                                                '-span textsp'
                                            }
                                        >
                                            A2
                                        </span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((A2 && !aUltra) || false)
                                            }
                                            id="s2"
                                            style={{ color: bttnColor[1] }}
                                        >
                                            <input
                                                disabled={
                                                    ['1'].includes(
                                                        deviceVersionNo[0]
                                                    ) &&
                                                    ['Humanoid'].includes(
                                                        connectedDeviceName
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                checked={a2Checked}
                                                onChange={a2CheckedState}
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in2"
                                                    style={{
                                                        color: bttnColor2[1],
                                                    }}
                                                >
                                                    Input
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={
                                            aUltra +
                                            'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                            aUltra +
                                            ' ultrasonic-disabled-' +
                                            (!A1 || !A2)
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-button-ultrasonic-' +
                                                (aUltra || false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-button-checkbox-ultrasonic"
                                                type="checkbox"
                                                // disabled={!A1 || !A2}
                                                checked={aUltra}
                                                onChange={UltraA}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in2"
                                                >
                                                    Ultra Sonic
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="Inputs-flow-left-upper">
                                <div className="Inputs-flow-left-upper-grp">
                                    <label
                                        className={
                                            B1 && +'input upper-label-input'
                                        }
                                    >
                                        <span
                                            className={
                                                (B1 || false) + '-span textsp'
                                            }
                                        >
                                            B1
                                        </span>

                                        <div
                                            className={
                                                'switch-button-' + (B1 || false)
                                            }
                                            id="s3"
                                            style={{ color: bttnColor[2] }}
                                        >
                                            <input
                                                // disabled={true}
                                                checked={b1Checked}
                                                onChange={b1CheckedState}
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span "
                                                    id="in3"
                                                    style={{
                                                        color: bttnColor2[2],
                                                    }}
                                                >
                                                    Input
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={
                                            B2 && +'input upper-label-input'
                                        }
                                    >
                                        <span
                                            className={
                                                (B2 || false) + '-span textsp'
                                            }
                                        >
                                            B2
                                        </span>

                                        <div
                                            className={
                                                'switch-button-' + (B2 || false)
                                            }
                                            id="s4"
                                            style={{ color: bttnColor[3] }}
                                        >
                                            <input
                                                disabled={false}
                                                // disabled={
                                                //     ['1', '2'].includes(
                                                //         zingVersion[0]
                                                //     )
                                                //         ? false
                                                //         : true
                                                // }
                                                checked={b2Checked}
                                                onChange={b2CheckedState}
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in4"
                                                    style={{
                                                        color: bttnColor2[3],
                                                    }}
                                                >
                                                    Input
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            className="ButtonRightDivInput"
                            style={{ marginTop: '100px' }}
                        >
                            {deviceVersionNo[0] == '0' &&
                            ['Humanoid'].includes(connectedDeviceName) ? (
                                <div>
                                    {' '}
                                    <div className="Inputs-flow-left-upper">
                                        <div className="Inputs-flow-left-upper-grp">
                                            <label
                                                className={
                                                    C1 &&
                                                    +'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        (C1 || false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    C1
                                                </span>

                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        (C1 || false)
                                                    }
                                                    id="s5"
                                                    style={{
                                                        color: bttnColor[4],
                                                    }}
                                                >
                                                    <input
                                                        checked={c1Checked}
                                                        onChange={
                                                            c1CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in5"
                                                            style={{
                                                                color: bttnColor2[4],
                                                            }}
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    C2 &&
                                                    +'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        (C2 || false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    C2
                                                </span>

                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        (C2 || false)
                                                    }
                                                    id="s6"
                                                    style={{
                                                        color: bttnColor[5],
                                                    }}
                                                >
                                                    <input
                                                        checked={c2Checked}
                                                        onChange={
                                                            c2CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in6"
                                                            style={{
                                                                color: bttnColor2[5],
                                                            }}
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="Inputs-flow-left-upper-ultrasonic">
                                        <div className="Inputs-flow-left-upper-grp-ultrasonic">
                                            <label
                                                className={
                                                    D1 &&
                                                    !dUltra +
                                                        'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        ((D1 && !dUltra) ||
                                                            false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    D1
                                                </span>
                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        ((D1 && !dUltra) ||
                                                            false)
                                                    }
                                                    id="s7"
                                                    style={
                                                        !d1Checked
                                                            ? {
                                                                  color: '#717171',
                                                              }
                                                            : {
                                                                  color: '#fcfcfc',
                                                              }
                                                    }
                                                >
                                                    <input
                                                        checked={d1Checked}
                                                        onChange={
                                                            d1CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in7"
                                                            style={
                                                                d1Checked
                                                                    ? {
                                                                          color: '#717171',
                                                                      }
                                                                    : {
                                                                          color: '#fcfcfc',
                                                                      }
                                                            }
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    D2 &&
                                                    !dUltra +
                                                        'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        ((D2 && !dUltra) ||
                                                            false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    D2
                                                </span>

                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        ((D2 && !dUltra) ||
                                                            false)
                                                    }
                                                    id="s8"
                                                    style={
                                                        !d2Checked
                                                            ? {
                                                                  color: '#717171',
                                                              }
                                                            : {
                                                                  color: '#fcfcfc',
                                                              }
                                                    }
                                                >
                                                    <input
                                                        // disabled={true}
                                                        checked={d2Checked}
                                                        onChange={
                                                            d2CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in8"
                                                            style={
                                                                d2Checked
                                                                    ? {
                                                                          color: '#717171',
                                                                      }
                                                                    : {
                                                                          color: '#fcfcfc',
                                                                      }
                                                            }
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    dUltra +
                                                    'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                                    dUltra +
                                                    ' ultrasonic-disabled-' +
                                                    (!D1 || !D2)
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-button-ultrasonic-' +
                                                        (dUltra || false)
                                                    }
                                                    id="s8"
                                                >
                                                    <input
                                                        className="switch-button-checkbox-oled"
                                                        type="checkbox"
                                                        // disabled={!D1 || !D2}
                                                        checked={dUltra}
                                                        onChange={UltraD}
                                                    ></input>
                                                    <label className="switch-button-label">
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in8"
                                                        >
                                                            Ultra Sonic
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {' '}
                                    <div className="Inputs-flow-left-upper-ultrasonic">
                                        <div className="Inputs-flow-left-upper-grp-ultrasonic">
                                            <label
                                                className={
                                                    C1 &&
                                                    !cUltra +
                                                        'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        ((C1 && !cUltra) ||
                                                            false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    C1
                                                </span>
                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        ((C1 && !cUltra) ||
                                                            false)
                                                    }
                                                    id="s5"
                                                    style={
                                                        !c1Checked
                                                            ? {
                                                                  color: '#717171',
                                                              }
                                                            : {
                                                                  color: '#fcfcfc',
                                                              }
                                                    }
                                                >
                                                    <input
                                                        checked={c1Checked}
                                                        onChange={
                                                            c1CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in5"
                                                            style={
                                                                c1Checked
                                                                    ? {
                                                                          color: '#717171',
                                                                      }
                                                                    : {
                                                                          color: '#fcfcfc',
                                                                      }
                                                            }
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    C2 &&
                                                    !cUltra +
                                                        'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        ((C2 && !cUltra) ||
                                                            false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    C2
                                                </span>

                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        ((C2 && !cUltra) ||
                                                            false)
                                                    }
                                                    id="s6"
                                                    style={
                                                        !c2Checked
                                                            ? {
                                                                  color: '#717171',
                                                              }
                                                            : {
                                                                  color: '#fcfcfc',
                                                              }
                                                    }
                                                >
                                                    <input
                                                        disabled={
                                                            ['1'].includes(
                                                                deviceVersionNo[0]
                                                            ) &&
                                                            [
                                                                'Humanoid',
                                                            ].includes(
                                                                connectedDeviceName
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        checked={c2Checked}
                                                        onChange={
                                                            c2CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in6"
                                                            style={
                                                                c2Checked
                                                                    ? {
                                                                          color: '#717171',
                                                                      }
                                                                    : {
                                                                          color: '#fcfcfc',
                                                                      }
                                                            }
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    cUltra +
                                                    'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                                    cUltra +
                                                    ' ultrasonic-disabled-' +
                                                    (!C1 || !C2)
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-button-ultrasonic-' +
                                                        (cUltra || false)
                                                    }
                                                    id="s6"
                                                >
                                                    <input
                                                        className="switch-button-checkbox-oled"
                                                        type="checkbox"
                                                        // disabled={!D1 || !D2}
                                                        checked={cUltra}
                                                        onChange={UltraC}
                                                    ></input>
                                                    <label className="switch-button-label">
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in6"
                                                        >
                                                            Ultra Sonic
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="Inputs-flow-left-upper">
                                        <div className="Inputs-flow-left-upper-grp">
                                            <label
                                                className={
                                                    D1 &&
                                                    +'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        (D1 || false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    D1
                                                </span>

                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        (D1 || false)
                                                    }
                                                    id="s7"
                                                    style={
                                                        !d1Checked
                                                            ? {
                                                                  color: '#717171',
                                                              }
                                                            : {
                                                                  color: '#fcfcfc',
                                                              }
                                                    }
                                                >
                                                    <input
                                                        checked={d1Checked}
                                                        onChange={
                                                            d1CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in7"
                                                            style={
                                                                d1Checked
                                                                    ? {
                                                                          color: '#717171',
                                                                      }
                                                                    : {
                                                                          color: '#fcfcfc',
                                                                      }
                                                            }
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    D2 &&
                                                    +'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        (D2 || false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    D2
                                                </span>

                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        (D2 || false)
                                                    }
                                                    id="s8"
                                                    style={
                                                        !d1Checked
                                                            ? {
                                                                  color: '#717171',
                                                              }
                                                            : {
                                                                  color: '#fcfcfc',
                                                              }
                                                    }
                                                >
                                                    <input
                                                        checked={d2Checked}
                                                        onChange={
                                                            d2CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in8"
                                                            style={
                                                                d1Checked
                                                                    ? {
                                                                          color: '#717171',
                                                                      }
                                                                    : {
                                                                          color: '#fcfcfc',
                                                                      }
                                                            }
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="SelectScreenBottom">
                <div className="bottom-child">
                    {/* <Link to="/programSelection"> */}
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    {/* </Link> */}

                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('nextBtn')}
                        onClick={next}
                    />
                </div>
            </div>
            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '1000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>

                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        {/* First screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('inputH')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    // height: "90px",
                                    width: '3%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '47%',
                                    left: '28.9%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    The enabled ports from the previous screen,
                                    can be set as input or Output mode
                                </p>
                            </div>
                        </div>
                        {/* second screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('inputH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "160px",
                                    width: '3%',
                                    // zIndex: "2000",
                                    position: 'absolute',
                                    top: '47%',
                                    left: '41.4%',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                    background: '#fff',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular ',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    When enabled as input mode, you can connect
                                    input accessories, whereas when enabled as
                                    Output mode, you can connect output
                                    accessories
                                </p>
                            </div>
                        </div>
                        {/* third screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('inputH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "160px",
                                    width: '3%',
                                    // zIndex: "2000",
                                    position: 'absolute',
                                    top: '49.2%',
                                    left: '54%',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                    background: '#fff',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '6px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular ',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Special Accessories like- Ultrasonic sensor,
                                    MP3, OLED display, etc can be enabled here
                                    separately at the available ports
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}

            <div
                className={`toast-msgs 
        ${isToastActive ? 'toast-msgs-active' : 'toast-msgs-inactive'}`}
                ref={toastRef}
            >
                {toastMsg}
            </div>
        </>
    )
}

// export default InputOutput;
const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialAction: (data) => {
            dispatch(webSerialAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputOutput)
