export const DBConfig = {
    name: 'ProgrammingPage',
    version: 1,
    objectStoresMeta: [
        {
            store: 'ProgramData',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [
                {
                    imageURL: 'imageURL',
                    keypath: 'imageURL',
                    options: { unique: false },
                },
            ],
        },
        {
            store: 'SavedFaces',
            storeConfig: { keyPath: 'id' },
            storeSchema: [
                {
                    imageURL: 'imageURL',
                    imageTensors: 'imageTensors',
                    keypath: 'imageURL',
                    options: { unique: false },
                },
            ],
        },
    ],
}
