import React from 'react'
import renderPrgImage from '../../../source/programImg'
import style from './GraphPlotter.module.css'
import GraphHeader from '../../ReusableComponents/Header/GraphHeader'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import { useHistory } from 'react-router'
import { useState, useRef } from 'react'
import closeBtn from '../../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import { graphPlotStorage } from '../../../redux/actions'
import selectStyle from '../../HumanoidInternalA/select.module.css'
import stylee from './Graph.module.css'
import { connect } from 'react-redux'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

function GraphPlotter(props) {
    let history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [isToastActive, setToastActive] = useState(false)
    const [toastMsg, setToastMsg] = useState('')
    const toastRef = useRef()

    const meterHandleClick = () => {
        history.push('/meterGraph')
    }
    const [ishelpButton, sethelpButton] = useState(false)
    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }
    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    let GraphPlotStorage = JSON.parse(
        sessionStorage.getItem('graphPlotStorage')
    )

    let internalAccessories = GraphPlotStorage.internalaccessories || {}
    let fourInOneSensors =
        GraphPlotStorage.internalaccessories.Four_in_one_sensor || {}
    let portsData = internalAccessories.portsData || []

    let areAllAccessoriesFalse = true
    for (const value of Object.values(internalAccessories)) {
        if (value === true) {
            areAllAccessoriesFalse = false
            break
        }
    }
    for (const value of Object.values(fourInOneSensors)) {
        if (value === true) {
            areAllAccessoriesFalse = false
            break
        }
    }

    let areAnyPortsActive = false
    for (const port of portsData) {
        for (const p of port.ports) {
            if (p.isActive === true) {
                areAnyPortsActive = true
                break
            }
        }
        if (areAnyPortsActive) {
            break
        }
    }

    const linehandleClick = () => {
        sessionStorage.removeItem('slideDirection')
        if (
            !areAnyPortsActive &&
            areAllAccessoriesFalse &&
            props.indexData.webSerial.isConnected
        ) {
            // Show popup message here
            setToastMsg('Select at least one sensor or port')
            setToastActive(true)
            disableToast()
        } else if (!props.indexData.webSerial.isConnected) {
            // Show toast message for Web Serial not connected
            setToastMsg('Please Connect the PeeCee')
            setToastActive(true)
            disableToast()
        } else {
            history.push('/lineGraph')
        }
    }
    const barhandleClick = () => {
        sessionStorage.removeItem('slideDirection')
        if (
            !areAnyPortsActive &&
            areAllAccessoriesFalse &&
            props.indexData.webSerial.isConnected
        ) {
            // Show popup message here
            setToastMsg('Select at least one sensor or port')
            setToastActive(true)
            disableToast()
        } else if (!props.indexData.webSerial.isConnected) {
            // Show toast message for Web Serial not connected
            setToastMsg('Please Connect the PeeCee')
            setToastActive(true)
            disableToast()
        } else {
            history.push('/barGraph')
        }
    }

    console.log('SERIALS', props.indexData.webSerial.isConnected)

    return (
        <>
            <div className={style.Main_Container}>
                <div style={{ height: '15%' }}>
                    <ProgramHeader
                        data={{
                            options: [
                                'Select Sensors',
                                'Select Ports',
                                'Set Time',
                                'Set Graph',
                                'Plot',
                            ],
                            selected: 3,
                        }}
                        showSave={false}
                        showHelp={true}
                        handleHelp={handleClickhelpbtn}
                    />
                </div>
                <div
                    id={style.Body}
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : 'slide-left'
                    } `}
                >
                    <div id={style.lineGraph}>
                        <img
                            className={style.lineImg}
                            src={renderPrgImage('LineGraph')}
                            onClick={linehandleClick}
                        ></img>
                        <div className={style.lineText}>
                            <p>Line Graph</p>
                        </div>
                    </div>
                    <div id={style.barGraph}>
                        {' '}
                        <img
                            className={style.barImg}
                            src={renderPrgImage('BarGraph')}
                            onClick={barhandleClick}
                        ></img>
                        <div className={style.barText}>
                            <p>Bar Graph</p>
                        </div>
                    </div>
                    {/* <div id={style.meterGraph}>
                        {' '}
                        <img
                            className={style.meterImg}
                            src={renderPrgImage('MeterGraph')}
                            onClick={meterHandleClick}
                        ></img>
                        <div className={style.meterText}>
                            <p>Meter</p>
                        </div>
                    </div> */}
                    <div id={style.subempty}></div>
                </div>
                <div style={{ width: '20%', position: 'relative' }}>
                    <img
                        style={{
                            position: 'absolute',
                            left: '35%',
                            top: '47%',
                            width: '60px',
                            height: '60px',
                            transform: 'translate(-50%,-50%)',
                            cursor: 'pointer',
                        }}
                        src={renderPrgImage('backBtn')}
                        onClick={() => {
                            setSlideDirection(true)
                            sessionStorage.setItem('slideDirection', true)
                            history.push('/graphTime')
                        }}
                    />

                    <div
                        className={`${
                            isToastActive
                                ? selectStyle['toast-active']
                                : selectStyle['toast-inactive']
                        } ${selectStyle['toast']}`}
                        ref={toastRef}
                        style={{
                            top: '47%',
                            left: '252%',
                            padding: '14px',
                            minWidth: '357px',
                        }}
                    >
                        {toastMsg}
                    </div>
                </div>
                {/* <div style={{ width: '20%', position: 'relative' }}>
                    <img
                        style={{
                            width: '10vh',
                            position: 'absolute',
                            right: '-380%',
                            transform: 'translateY(-150%) ',
                        }}
                        src={renderPrgImage('nextBtn')}
                        onClick={() => {
                            history.push('/lineGraph')
                        }}
                    />
                </div> */}

                {ishelpButton == true ? (
                    <div className={stylee.helpScreen}>
                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '15%',
                                right: '18%',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                        <Slider
                            {...settings}
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '46%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                            }}
                        >
                            {/* First screen */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={renderPrgImage('lineGraphH')}
                                    style={{ width: '100%' }}
                                />

                                <div
                                    style={{
                                        // height: '6%',
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '52.5%',
                                        left: '41.2%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '7px',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        This displays the Sensor values in a
                                        form a linear lines, whose y-axis shows
                                        the sensor values & x-axis - the time.
                                    </p>
                                </div>
                            </div>
                            {/* second screen */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={renderPrgImage('barGraphH')}
                                    style={{ width: '100%' }}
                                />
                                <div
                                    style={{
                                        // height: '6%',
                                        width: '5%',
                                        // zIndex: "2000",
                                        position: 'absolute',
                                        top: '52.5%',
                                        left: '61.3%',
                                        fontSize: '22px',
                                        borderRadius: '7px',
                                        transform: 'translate(-50%,-50%)',
                                        background: '#fff',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular ',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        This screen displays the sensor values
                                        in form of a vertical bar, with the
                                        sensor value varying vertically.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                ) : null}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        indexData: state,
    }
}

export default connect(mapStateToProps)(GraphPlotter)
