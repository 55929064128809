import { useState } from 'react'
import './VerticalSlider.css'

function VerticalSlider(props) {
    const {
        servoKiy,
        min,
        max,
        value,
        handelSliderChange,
        onMouseUpHandel,
        direction,
    } = props

    return (
        <div
            style={
                direction === 'up-down'
                    ? {
                          position: 'relative',
                          marginLeft: '15px',
                          height: '200px',
                      }
                    : { position: 'relative', marginLeft: '15px' }
            }
        >
            <input
                type="range"
                orient="vertical"
                min={min}
                max={max}
                value={value}
                onChange={(e) => {
                    handelSliderChange(servoKiy, e.target.value)
                }}
                onMouseUp={() => {
                    setTimeout(() => {
                        onMouseUpHandel()
                        // if (props.disable) return
                        // props.leftEyeData()
                    }, 200)
                }}
                style={
                    direction === 'up-down'
                        ? {
                              direction: 'initial',
                              position: 'absolute',
                              top: '20px',
                          }
                        : {}
                }
            />
            <div className="slider__track" />
        </div>
    )
}

export default VerticalSlider
