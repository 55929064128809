import React, { useState, useEffect, useLayoutEffect } from 'react'
import style from './Bar.module.css'
import ProgramHeader from '../../../ReusableComponents/Header/ProgramHeader'
import { useHistory } from 'react-router'
import Graph from '../Graph'
import renderPrgImage from '../../../../source/programImg'
import { GraphPlotStorage } from '../GraphPlotStorage'
import { connect } from 'react-redux'
import stylee from '../Graph.module.css'
import {
    CartesianGrid,
    XAxis,
    YAxis,
    Brush,
    Tooltip,
    BarChart,
    Bar,
    ResponsiveContainer,
} from 'recharts'
import {
    sendBytes,
    readBytesPa,
    HUMANOID_PA_SEND_BYTES,
    humanoidPABytesToSensorData,
} from '../../../ReusableComponents/ByteTransfer'

import { ContactsOutlined } from '@material-ui/icons'
function unicodeToChar(data) {
    let str = ''

    data.forEach((element) => {
        str = str.concat(String.fromCharCode(element))
    })

    console.log(str)

    return str
}
function BarGraph(props) {
    let history = useHistory()
    const [isMic, setMic] = useState(false)
    const [datas, setDatas] = useState([
        {
            name: '',
            pv: '',
            uv: 0,
            mic: 0,
            temp: 0,
            batt: 0,
            touch0: 0,
            touch2: 0,
            touch3: 0,
            acceX: 0,
            acceY: 0,
            acceZ: 0,
            red: 0,
            green: 0,
            blue: 0,
            distance: 0,
            light: 0,
            gesture: 0,
            A1: 0,
            A2: 0,
            B1: 0,
            B2: 0,
            C1: 0,
            C2: 0,
            D1: 0,
            D2: 0,
            E1: 0,
            E2: 0,
            F1: 0,
            F2: 0,
        },
    ])
    const [isPauseBtn, setPauseBtn] = useState(false)
    const [isArrowBtn, setArrowBtn] = useState(false)
    const [isTemperature, setTemperature] = useState(false)
    const [isBattery, setBattery] = useState(false)
    const [isTouchZero, setTouchZero] = useState(false)
    const [isTouchOne, setTouchOne] = useState('')
    const [isTouchTwo, setTouchTwo] = useState(false)
    const [isTouchThree, setTouchThree] = useState(false)
    const [isAccelerometerX, setAccelerometerX] = useState(false)
    const [isAccelerometerY, setAccelerometerY] = useState(false)
    const [isAccelerometerZ, setAccelerometerZ] = useState(false)
    const [isColorSensorRed, setColorSensorRed] = useState(false)
    const [isColorSensorGreen, setColorSensorGreen] = useState(false)
    const [isColorSensorBlue, setColorSensorBlue] = useState(false)
    const [isDistanceSensors, setDistanceSensors] = useState(false)
    const [isGestureSensor, setGestureSensor] = useState(false)
    const [isLightSensor, setLightSensor] = useState(false)
    const [isConnectedPort, setConnectedPort] = useState('')
    const [A1, setA1] = useState('')
    const [A2, setA2] = useState('')
    const [B1, setB1] = useState('')
    const [B2, setB2] = useState('')
    const [C1, setC1] = useState('')
    const [C2, setC2] = useState('')
    const [D1, setD1] = useState('')
    const [D2, setD2] = useState('')
    const [E1, setE1] = useState('')
    const [E2, setE2] = useState('')
    const [F1, setF1] = useState('')
    const [F2, setF2] = useState('')
    useEffect(() => {
        console.log(props)
    }, [])
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const onClickBack = async () => {
        const intervalId = sessionStorage.getItem('readSetIntervalId')
        if (intervalId !== null) {
            if (deviceVersion?.startsWith('1')) {
                props.worker.postMessage({
                    type: 'write',
                    value: 'M8',
                })
            } else {
                await sendBytes(
                    ['M'.charCodeAt(0), '8'.charCodeAt(0)],
                    props.indexData.webSerial.port
                )
            }
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            return
        }
        history.push('/graphPlotter')
        time = 0
    }

    const handleEventsClick = async (e) => {
        switch (e.target.alt) {
            case 'mic': {
                if (isMic) {
                    console.log('false')
                    GraphPlotStorage.isMic = false
                    props.graphPlotStorage({ isMic: false })
                    setMic(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.isMic = true
                    props.graphPlotStorage({ isMic: true })
                    setMic(true)
                }
                break
            }

            case 'temperature': {
                if (isTemperature) {
                    GraphPlotStorage.isTemperature = false
                    props.graphPlotStorage({ isTemperature: false })
                    setTemperature(false)
                } else {
                    GraphPlotStorage.isTemperature = true

                    props.graphPlotStorage({ isTemperature: true })

                    setTemperature(true)
                }
                break
            }

            case 'battery': {
                if (isBattery) {
                    GraphPlotStorage.isBattery = false
                    props.graphPlotStorage({ isBattery: false })
                    setBattery(false)
                } else {
                    GraphPlotStorage.isBattery = true

                    props.graphPlotStorage({ isBattery: true })

                    setBattery(true)
                }
                break
            }

            case 'touch0': {
                if (isTouchZero) {
                    GraphPlotStorage.isTouchZero = false
                    props.graphPlotStorage({ isTouchZero: false })
                    setTouchZero(false)
                } else {
                    GraphPlotStorage.isTouchZero = true

                    props.graphPlotStorage({
                        isTouchZero: true,
                        A1: false,
                        A2: false,
                    })
                    setA1(false)
                    setA2(false)
                    setTouchZero(true)
                }
                break
            }

            case 'touch1': {
                if (isTouchOne) {
                    GraphPlotStorage.isTouchOne = false
                    props.graphPlotStorage({ isTouchOne: false })
                    setTouchOne(false)
                } else {
                    GraphPlotStorage.isTouchOne = true

                    props.graphPlotStorage({
                        isTouchOne: true,
                        B1: false,
                        B2: false,
                    })
                    setB1(false)
                    setB2(false)
                    setTouchOne(true)
                }
                break
            }

            case 'touch2': {
                if (isTouchTwo) {
                    GraphPlotStorage.isTouchTwo = false
                    props.graphPlotStorage({ isTouchTwo: false })
                    setTouchTwo(false)
                } else {
                    GraphPlotStorage.isTouchTwo = true

                    props.graphPlotStorage({
                        isTouchTwo: true,
                        C1: false,
                        C2: false,
                    })
                    setC1(false)
                    setC2(false)

                    setTouchTwo(true)
                }
                break
            }

            case 'touch3': {
                if (isTouchThree) {
                    GraphPlotStorage.isTouchThree = false
                    props.graphPlotStorage({ isTouchThree: false })
                    setTouchThree(false)
                } else {
                    GraphPlotStorage.isTouchThree = true

                    props.graphPlotStorage({
                        isTouchThree: true,
                        D1: false,
                        D2: false,
                    })
                    setD1(false)
                    setD2(false)

                    setTouchThree(true)
                }
                break
            }

            case 'accelerometerX': {
                if (isAccelerometerX) {
                    GraphPlotStorage.isAccelerometerX = false
                    props.graphPlotStorage({ isAccelerometerX: false })
                    setAccelerometerX(false)
                    sessionStorage.setItem('isAccelerometerX', false)
                } else {
                    GraphPlotStorage.isAccelerometerX = true
                    props.graphPlotStorage({ isAccelerometerX: true })
                    setAccelerometerX(true)
                    sessionStorage.setItem('isAccelerometerX', true)
                }
                break
            }

            case 'accelerometerY': {
                if (isAccelerometerY) {
                    GraphPlotStorage.isAccelerometerY = false
                    props.graphPlotStorage({ isAccelerometerY: false })
                    setAccelerometerY(false)
                    sessionStorage.setItem('isAccelerometerY', false)
                } else {
                    GraphPlotStorage.isAccelerometerY = true
                    props.graphPlotStorage({ isAccelerometerY: true })
                    setAccelerometerY(true)
                    sessionStorage.setItem('isAccelerometerY', true)
                }
                break
            }
            case 'accelerometerZ': {
                if (isAccelerometerZ) {
                    GraphPlotStorage.isAccelerometerZ = false
                    props.graphPlotStorage({ isAccelerometerZ: false })
                    setAccelerometerZ(false)
                    sessionStorage.setItem('isAccelerometerZ', false)
                } else {
                    GraphPlotStorage.isAccelerometerZ = true
                    props.graphPlotStorage({ isAccelerometerZ: true })
                    setAccelerometerZ(true)
                    sessionStorage.setItem('isAccelerometerZ', true)
                }
                break
            }

            case 'A1': {
                if (A1) {
                    console.log('false')
                    GraphPlotStorage.A1 = false
                    props.graphPlotStorage({ A1: false })
                    setA1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.A1 = true
                    props.graphPlotStorage({ A1: true, isTouchZero: false })
                    setTouchZero(false)
                    setA1(true)
                }
                break
            }

            case 'A2': {
                if (A2) {
                    console.log('false')
                    GraphPlotStorage.A2 = false
                    setA2(false)
                    props.graphPlotStorage({ A2: false })
                } else {
                    console.log('true')
                    GraphPlotStorage.A2 = true
                    props.graphPlotStorage({ A2: true, isTouchZero: false })
                    setTouchZero(false)
                    setA2(true)
                }
                break
            }

            case 'B1': {
                if (B1) {
                    console.log('false')
                    GraphPlotStorage.B1 = false
                    props.graphPlotStorage({ B1: false })
                    setB1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.B1 = true
                    props.graphPlotStorage({ B1: true, isTouchOne: false })
                    setTouchOne(false)
                    setB1(true)
                }
                break
            }

            case 'B2': {
                if (B2) {
                    console.log('false')
                    GraphPlotStorage.B2 = false
                    props.graphPlotStorage({ B2: false })
                    setB2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.B2 = true
                    props.graphPlotStorage({ B2: true, isTouchOne: false })
                    setTouchOne(false)
                    setB2(true)
                }
                break
            }

            case 'C1': {
                if (C1) {
                    console.log('false')
                    GraphPlotStorage.C1 = false
                    props.graphPlotStorage({ C1: false })
                    setC1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.C1 = true
                    props.graphPlotStorage({ C1: true, isTouchTwo: false })
                    setTouchTwo(false)
                    setC1(true)
                }
                break
            }

            case 'C2': {
                if (C2) {
                    console.log('false')
                    GraphPlotStorage.C2 = false
                    props.graphPlotStorage({ C2: false })
                    setC2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.C2 = true
                    props.graphPlotStorage({ C2: true, isTouchTwo: false })
                    setTouchTwo(false)
                    setC2(true)
                }
                break
            }

            case 'D1': {
                if (D1) {
                    console.log('false')
                    GraphPlotStorage.D1 = false
                    props.graphPlotStorage({ D1: false })
                    setD1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.D1 = true
                    props.graphPlotStorage({ D1: true, isTouchThree: false })
                    setTouchThree(false)
                    setD1(true)
                }
                break
            }

            case 'D2': {
                if (D2) {
                    console.log('false')
                    GraphPlotStorage.D2 = false
                    props.graphPlotStorage({ D2: false })
                    setD2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.D2 = true
                    props.graphPlotStorage({ D2: true, isTouchThree: false })
                    setTouchThree(false)
                    setD2(true)
                }
                break
            }

            case 'E1': {
                if (E1) {
                    console.log('false')
                    GraphPlotStorage.E1 = false
                    props.graphPlotStorage({ E1: false })
                    setE1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.E1 = true
                    props.graphPlotStorage({ E1: true })
                    setE1(true)
                }
                break
            }

            case 'E2': {
                if (E2) {
                    console.log('false')
                    GraphPlotStorage.E2 = false
                    props.graphPlotStorage({ E2: false })
                    setE2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.E2 = true
                    props.graphPlotStorage({ E2: true })
                    setE2(true)
                }
                break
            }

            case 'F1': {
                if (F1) {
                    console.log('false')
                    GraphPlotStorage.F1 = false
                    props.graphPlotStorage({ F1: false })
                    setF1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.F1 = true
                    props.graphPlotStorage({ F1: true })
                    setF1(true)
                }
                break
            }

            case 'F2': {
                if (F2) {
                    console.log('false')
                    GraphPlotStorage.F2 = false
                    props.graphPlotStorage({ F2: false })
                    setF2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.F2 = true
                    props.graphPlotStorage({ F2: true })
                    setF2(true)
                }
                break
            }
        }
    }

    const handleFounInOneSensor = (e) => {
        switch (e.target.alt) {
            case 'lightsensor': {
                if (isLightSensor) {
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    props.graphPlotStorage({ isLightSensor: false })

                    setLightSensor(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: true,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = true
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(true)
                }
                break
            }

            case 'distancesensor': {
                if (isDistanceSensors) {
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    props.graphPlotStorage({ isDistanceSensors: false })

                    setDistanceSensors(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: false,
                        isDistanceSensors: true,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = true
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                    setDistanceSensors(true)
                    setGestureSensor(false)
                    setLightSensor(false)
                }
                break
            }

            case 'gesturesensor': {
                if (isGestureSensor) {
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    props.graphPlotStorage({ isGestureSensor: false })

                    setGestureSensor(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: true,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                    setDistanceSensors(false)
                    setGestureSensor(true)
                    setLightSensor(false)
                }
                break
            }

            case 'colorsensorRed': {
                if (isColorSensorRed) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    props.graphPlotStorage({ isColorSensorRed: false })

                    setColorSensorRed(false)
                } else {
                    props.graphPlotStorage({
                        isColorSensorRed: true,
                        isColorSensorGreen: false,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    setColorSensorRed(true)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                }
                break
            }

            case 'colorsensorGreen': {
                if (isColorSensorGreen) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    props.graphPlotStorage({ isColorSensorGreen: false })
                    setColorSensorGreen(false)
                } else {
                    props.graphPlotStorage({
                        isColorSensorGreen: true,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    setColorSensorRed(false)
                    setColorSensorBlue(false)
                    setColorSensorGreen(true)
                }
                break
            }

            case 'colorsensorBlue': {
                if (isColorSensorBlue) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    props.graphPlotStorage({ isColorSensorBlue: false })

                    setColorSensorBlue(false)
                } else {
                    props.graphPlotStorage({
                        isColorSensorBlue: true,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(true)
                }
                break
            }
        }
    }

    const handleClick = () => {
        if (isArrowBtn == false) {
            console.log('false')
            // sessionStorage.setItem('ArrowBtn', false)
            setArrowBtn(true)
        } else {
            console.log('true')
            // sessionStorage.setItem('ArrowBtn', true)
            setArrowBtn(false)
        }
    }

    const pauseHandleClick = () => {
        if (isConnectedPort.opened) {
            console.log('WORKING')
            deviceVersion?.startsWith('1')
                ? handleReadByte_v1(isConnectedPort)
                : handleReadByte(isConnectedPort)

            setPauseBtn(!isPauseBtn)
        } else {
            console.log('falseArrow')
            setPauseBtn(false)
        }
    }

    const stopHandleClick = () => {
        if (isPauseBtn == true) {
            setPauseBtn(false)
            deviceVersion?.startsWith('1')
                ? handleReadByte_v1(isConnectedPort)
                : handleReadByte(isConnectedPort)
        }

        console.log('stop')
    }

    let configNumber_ = 1
    let interfaceNumber_ = 1
    let endpointOut_ = 2
    let endpointIn_ = 2

    const MSBLSBToDecimal = (data) => {
        if (data.length !== 2) return
        const [MSBInDecimal, LSBInDecimal] = data
        const MSBInBinary = parseInt(MSBInDecimal).toString(2).padStart(8, 0)
        const LSBInBinary = parseInt(LSBInDecimal).toString(2).padStart(8, 0)
        const dataInBinary = MSBInBinary + LSBInBinary
        const dataInDecimal = parseInt(dataInBinary, 2)

        return dataInDecimal
    }
    const playComputerPABytesToSensorData = (receivedPABytes, selectedPort) => {
        if (selectedPort == 2)
            // A1
            return MSBLSBToDecimal([receivedPABytes[1], receivedPABytes[0]])
        else if (selectedPort == 3)
            // A2
            return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
        else if (selectedPort == 4)
            // B1
            return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
        else if (selectedPort == 5)
            // B2
            return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
        else if (selectedPort == 6)
            // C1
            return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
        else if (selectedPort == 7)
            // C2
            return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
        else if (selectedPort == 8)
            // D1
            return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
        else if (selectedPort == 9)
            // D2
            return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
        else if (selectedPort == 10)
            // E1
            return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
        else if (selectedPort == 11)
            // E2
            return MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]])
        else if (selectedPort == 12)
            // F1
            return MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]])
        else if (selectedPort == 13)
            // F2
            return MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]])
        else if (selectedPort == 14) {
            // Mic
            return MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]])
        } else if (selectedPort == 15)
            // Temperature
            return MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]])
        else if (selectedPort == 16)
            // Touch Pad 0
            return MSBLSBToDecimal([receivedPABytes[29], receivedPABytes[28]])
        else if (selectedPort == 17)
            // Touch Pad 1
            return MSBLSBToDecimal([receivedPABytes[31], receivedPABytes[30]])
        else if (selectedPort == 18)
            // Touch Pad 2
            return MSBLSBToDecimal([receivedPABytes[33], receivedPABytes[32]])
        else if (selectedPort == 19)
            // Touch Pad 3
            return MSBLSBToDecimal([receivedPABytes[35], receivedPABytes[34]])
        else if (selectedPort == '20L')
            // Light
            return receivedPABytes[36]
        else if (selectedPort == '20D')
            // Distance
            return receivedPABytes[37]
        else if (selectedPort == '20G') {
            // Gesture
            if (receivedPABytes[38] === '1') return 'Down'
            else if (receivedPABytes[38] === '2') return 'Up'
            else if (receivedPABytes[38] === '3') return 'Right'
            else if (receivedPABytes[38] === '4') return 'Left'
            else return receivedPABytes[38]
        } else if (selectedPort == '20C')
            // Color [R, G, B]
            return [
                receivedPABytes[36],
                receivedPABytes[37],
                receivedPABytes[38],
            ]
        else if (selectedPort == '21X')
            // Accelerometer X
            return MSBLSBToDecimal([receivedPABytes[40], receivedPABytes[39]])
        else if (selectedPort == '21Y')
            // Accelerometer Y
            return MSBLSBToDecimal([receivedPABytes[42], receivedPABytes[41]])
        else if (selectedPort == '21Z')
            // Accelerometer Z
            return MSBLSBToDecimal([receivedPABytes[44], receivedPABytes[43]])
        else if (selectedPort == 22)
            // Battery
            return receivedPABytes[45]

        return
    }

    const continousPABytesReadToggle_v1 = async (
        setPABytes_V1,
        expectedResponseArraySize,
        port,
        callBackFunc,
        overRide
    ) => {
        var int8array
        let combinedResponse = []
        const bytes = new Uint8Array(setPABytes_V1)

        await port.transferOut(endpointOut_, bytes)

        const reading = async () => {
            await port.transferIn(endpointIn_, 64).then((result) => {
                int8array = new Uint8Array(result.data.buffer)
                let uniIntToArray = Array.from(int8array)
                combinedResponse = combinedResponse.concat(uniIntToArray)
                result = Array.from(
                    unicodeToChar(combinedResponse).trim().split(' ')
                )
                if (result.length === 48 && !(result.length >= 49)) {
                    combinedResponse = []
                    callBackFunc(result)
                    return true
                } else {
                    if (result.length > 48) {
                        combinedResponse = []
                    } else {
                        reading()
                    }
                }
            })
        }

        reading()

        // await port.transferIn(endpointIn_, 64).then((result) => {
        //   int8view2 = new Uint8Array(result.data.buffer);
        //   mergedArray = new Uint8Array(int8view1.length + int8view2.length);
        //   mergedArray.set(int8view1);
        //   mergedArray.set(int8view2, int8view1.length);
        //   combinedResponse = unicodeToChar(mergedArray);
        //   final.push(combinedResponse.trim().split(" "));
        // });

        // callBackFunc(final[0]);
    }

    const setPABytes_V1 = () => {
        let sendBytes = Array(25).fill('0'.charCodeAt())

        sendBytes[0] = 'P'.charCodeAt()
        sendBytes[1] = 'A'.charCodeAt()
        sendBytes[23] = 'E'.charCodeAt()
        sendBytes[24] = 'R'.charCodeAt()

        let internalAccessories = JSON.parse(
            sessionStorage.getItem('graphPlotStorage')
        ).internalaccessories

        //Suppose if the user Select PORT A
        if (internalAccessories.A1 === true) {
            sendBytes[2] = 'A'.charCodeAt()
        }

        if (internalAccessories.A2 === true) {
            sendBytes[3] = 'A'.charCodeAt()
        }

        if (internalAccessories.B1 === true) {
            sendBytes[4] = 'A'.charCodeAt()
        }

        if (internalAccessories.B2 === true) {
            sendBytes[5] = 'A'.charCodeAt()
        }

        if (internalAccessories.C1 === true) {
            sendBytes[6] = 'A'.charCodeAt()
        }

        if (internalAccessories.C2 === true) {
            sendBytes[7] = 'A'.charCodeAt()
        }

        if (internalAccessories.D1 === true) {
            sendBytes[8] = 'A'.charCodeAt()
        }

        if (internalAccessories.D2 === true) {
            sendBytes[9] = 'A'.charCodeAt()
        }

        if (internalAccessories.E1 === true) {
            sendBytes[10] = 'A'.charCodeAt()
        }

        if (internalAccessories.E2 === true) {
            sendBytes[11] = 'A'.charCodeAt()
        }

        if (internalAccessories.F1 === true) {
            sendBytes[12] = 'A'.charCodeAt()
        }

        if (internalAccessories.F2 === true) {
            sendBytes[13] = 'A'.charCodeAt()
        }

        if (internalAccessories.isMic === true) {
            sendBytes[14] = 'M'.charCodeAt()
        }
        if (internalAccessories.isTemperature === true) {
            sendBytes[15] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchZero === true) {
            sendBytes[16] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchOne === true) {
            sendBytes[17] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchTwo === true) {
            sendBytes[18] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchThree === true) {
            sendBytes[19] = 'T'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isDistanceSensors === true) {
            sendBytes[20] = 'D'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isGestureSensor === true) {
            sendBytes[20] = 'G'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isColorSensor === true) {
            sendBytes[20] = 'C'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isLightSensor === true) {
            sendBytes[20] = 'L'.charCodeAt()
        }
        if (internalAccessories.isAccelerometer === true) {
            sendBytes[21] = 'A'.charCodeAt()
        }
        if (internalAccessories.isBattery === true) {
            sendBytes[22] = 'B'.charCodeAt()
        }
        var bytes = unicodeToChar(sendBytes)
        console.log(bytes)
        return sendBytes
    }
    const setPABytes_v0 = () => {
        let bytesData = Array(11).fill('O'.charCodeAt())

        bytesData.unshift('A'.charCodeAt())
        bytesData.unshift('P'.charCodeAt())

        let sessionData = JSON.parse(sessionStorage.getItem('graphPlotStorage'))
        console.log('session data', sessionData)
        let portdata = JSON.parse(sessionStorage.getItem('assembly'))

        Object.keys(portdata.PortConnections).map((port) => {
            if (portdata.PortConnections[port] != null) {
                if (port === 'A' || port === 'A1' || port === 'A2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[2] = 'U'.charCodeAt()
                        // bytesData[3] = "I".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'I'.charCodeAt()
                            bytesData[3] = 'I'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'I'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'A'.charCodeAt()
                            bytesData[3] = 'A'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'A'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[2] = 'A'.charCodeAt()
                        bytesData[3] = 'A'.charCodeAt()
                    }
                } else if (port === 'B' || port === 'B1' || port === 'B2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[4] = 'A'.charCodeAt()
                        bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'A'.charCodeAt()
                            bytesData[5] = 'A'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'A'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'I'.charCodeAt()
                            bytesData[5] = 'I'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'I'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'I'.charCodeAt()
                    }
                } else if (port === 'C' || port === 'C1' || port === 'C2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[6] = 'U'.charCodeAt()
                        // bytesData[7] = "A".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'I'.charCodeAt()
                            bytesData[7] = 'I'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'I'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'A'.charCodeAt()
                            bytesData[7] = 'A'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'A'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'metal_detector' ||
                        Type == 'extender'
                    ) {
                        bytesData[6] = 'A'.charCodeAt()
                        bytesData[7] = 'A'.charCodeAt()
                    }
                } else if (port === 'F' || port === 'F1' || port === 'F2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'F1') bytesData[8] = 'I'.charCodeAt()
                        if (port == 'F2') bytesData[9] = 'I'.charCodeAt()
                    }
                }
            }
        })

        if (sessionData.internalAccessories.portsData[0].ports[0].isActive) {
            if (
                sessionData.internalAccessories.portsData[0].ports[0]
                    .isDigital === true
            ) {
                bytesData[2] = 'A'.charCodeAt()
            } else {
                bytesData[2] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[0].ports[1].isActive) {
            if (
                sessionData.internalAccessories.portsData[0].ports[1]
                    .isDigital === true
            ) {
                bytesData[3] = 'A'.charCodeAt()
            } else {
                bytesData[3] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[1].ports[0].isActive) {
            if (
                sessionData.internalAccessories.portsData[1].ports[0]
                    .isDigital === true
            ) {
                bytesData[4] = 'A'.charCodeAt()
            } else {
                bytesData[4] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[1].ports[1].isActive) {
            if (
                sessionData.internalAccessories.portsData[1].ports[1]
                    .isDigital === true
            ) {
                bytesData[5] = 'A'.charCodeAt()
            } else {
                bytesData[5] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[2].ports[0].isActive) {
            if (
                sessionData.internalAccessories.portsData[2].ports[0]
                    .isDigital === true
            ) {
                bytesData[6] = 'A'.charCodeAt()
            } else {
                bytesData[6] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[2].ports[1].isActive) {
            if (
                sessionData.internalAccessories.portsData[2].ports[1]
                    .isDigital === true
            ) {
                bytesData[7] = 'A'.charCodeAt()
            } else {
                bytesData[7] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[3].ports[0].isActive) {
            if (
                sessionData.internalAccessories.portsData[3].ports[0]
                    .isDigital === true
            ) {
                bytesData[8] = 'A'.charCodeAt()
            } else {
                bytesData[8] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[3].ports[1].isActive) {
            if (
                sessionData.internalAccessories.portsData[3].ports[1]
                    .isDigital === true
            ) {
                bytesData[9] = 'A'.charCodeAt()
            } else {
                bytesData[9] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[4].ports[0].isActive) {
            if (
                sessionData.internalAccessories.portsData[4].ports[0]
                    .isDigital === true
            ) {
                bytesData[10] = 'A'.charCodeAt()
            } else {
                bytesData[10] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[4].ports[1].isActive) {
            if (
                sessionData.internalAccessories.portsData[4].ports[1]
                    .isDigital === true
            ) {
                bytesData[11] = 'A'.charCodeAt()
            } else {
                bytesData[11] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[5].ports[0].isActive) {
            if (
                sessionData.internalAccessories.portsData[5].ports[0]
                    .isDigital === true
            ) {
                bytesData[12] = 'A'.charCodeAt()
            } else {
                bytesData[12] = 'I'.charCodeAt()
            }
        }
        if (sessionData.internalAccessories.portsData[5].ports[1].isActive) {
            if (
                sessionData.internalAccessories.portsData[5].ports[1]
                    .isDigital === true
            ) {
                bytesData[13] = 'A'.charCodeAt()
            } else {
                bytesData[13] = 'I'.charCodeAt()
            }
        }

        if (sessionData.internalaccessories.isMic) {
            bytesData[11] = 'M'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTemperature) {
            bytesData[12] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchZero) {
            bytesData[2] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchOne) {
            bytesData[4] = 'T'.charCodeAt()
        }
        if (sessionData.internalaccessories.isTouchTwo) {
            bytesData[6] = 'T'.charCodeAt()
        }

        if (
            sessionData.internalaccessories.Four_in_one_sensor.isDistanceSensors
        ) {
            bytesData[10] = 'D'.charCodeAt()
        }
        if (
            sessionData.internalaccessories.Four_in_one_sensor.isGestureSensor
        ) {
            bytesData[10] = 'G'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isColorSensor) {
            bytesData[10] = 'C'.charCodeAt()
        }
        if (sessionData.internalaccessories.Four_in_one_sensor.isLightSensor) {
            bytesData[10] = 'L'.charCodeAt()
        }
        var bytes = unicodeToChar(bytesData)
        console.log('gskbytes', bytes)
        return bytesData
    }
    const setPABytesHumanoid = () => {
        HUMANOID_PA_SEND_BYTES[10] = 'B'.charCodeAt(0)
        HUMANOID_PA_SEND_BYTES[11] = 'A'.charCodeAt(0)
        HUMANOID_PA_SEND_BYTES[12] = 'G'.charCodeAt(0)

        let bytesData = HUMANOID_PA_SEND_BYTES

        console.log(bytesData)
        let portdata = JSON.parse(sessionStorage.getItem('assembly'))
        let sessionData = JSON.parse(sessionStorage.getItem('graphPlotStorage'))
        console.log('sessionData', sessionData)
        let internalAccessories = sessionData.internalaccessories
        console.log('internalAccessories', internalAccessories)
        Object.keys(portdata.PortConnections).map((port) => {
            if (portdata.PortConnections[port] != null) {
                if (port === 'A' || port === 'A1' || port === 'A2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[2] = 'U'.charCodeAt()
                        // bytesData[3] = "I".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'metal_detector' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'I'.charCodeAt()
                            bytesData[3] = 'I'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'I'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'A') {
                            bytesData[2] = 'A'.charCodeAt()
                            bytesData[3] = 'A'.charCodeAt()
                        }
                        if (port == 'A1') bytesData[2] = 'A'.charCodeAt()
                        if (port == 'A2') bytesData[3] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[2] = 'A'.charCodeAt()
                        bytesData[3] = 'A'.charCodeAt()
                    }
                } else if (port === 'B' || port === 'B1' || port === 'B2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[4] = 'A'.charCodeAt()
                        bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'A'.charCodeAt()
                            bytesData[5] = 'A'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'A'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'A'.charCodeAt()
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch' ||
                        Type == 'metal_detector'
                    ) {
                        if (port == 'B') {
                            bytesData[4] = 'I'.charCodeAt()
                            bytesData[5] = 'I'.charCodeAt()
                        }
                        if (port == 'B1') bytesData[4] = 'I'.charCodeAt()
                        if (port == 'B2') bytesData[5] = 'I'.charCodeAt()
                    }
                } else if (port === 'C' || port === 'C1' || port === 'C2') {
                    let Type = portdata.PortConnections[port].type

                    if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'I'.charCodeAt()
                            bytesData[7] = 'I'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'I'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'C') {
                            bytesData[6] = 'A'.charCodeAt()
                            bytesData[7] = 'A'.charCodeAt()
                        }
                        if (port == 'C1') bytesData[6] = 'A'.charCodeAt()
                        if (port == 'C2') bytesData[7] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[6] = 'A'.charCodeAt()
                        bytesData[7] = 'A'.charCodeAt()
                    }
                } else if (port === 'D' || port === 'D1' || port === 'D2') {
                    let Type = portdata.PortConnections[port].type

                    if (Type == 'ultrasonic_sensor') {
                        bytesData[8] = 'U'.charCodeAt()
                        // bytesData[7] = "A".charCodeAt();
                    } else if (
                        Type == 'tact_switch' ||
                        Type == 'touch_sensor' ||
                        Type == 'metal_detector' ||
                        Type == 'dual_switch' ||
                        Type == 'dip_switch'
                    ) {
                        if (port == 'D') {
                            bytesData[8] = 'I'.charCodeAt()
                            bytesData[9] = 'I'.charCodeAt()
                        }
                        if (port == 'D1') bytesData[8] = 'I'.charCodeAt()
                        if (port == 'C2') bytesData[9] = 'I'.charCodeAt()
                    } else if (
                        Type == 'distance_sensor' ||
                        Type == 'temperature_sensor' ||
                        Type == 'gas' ||
                        Type == 'light_sensor' ||
                        Type == 'linear_pot' ||
                        Type == 'pot' ||
                        Type == 'rain_sensor' ||
                        Type == 'humidity'
                    ) {
                        if (port == 'D') {
                            bytesData[8] = 'A'.charCodeAt()
                            bytesData[9] = 'A'.charCodeAt()
                        }
                        if (port == 'D1') bytesData[8] = 'A'.charCodeAt()
                        if (port == 'D2') bytesData[9] = 'A'.charCodeAt()
                    } else if (
                        Type == 'rotatory' ||
                        Type == 'joystick' ||
                        Type == 'extender'
                    ) {
                        bytesData[8] = 'A'.charCodeAt()
                        bytesData[9] = 'A'.charCodeAt()
                    }
                }
            }
        })
        if (internalAccessories.portsData[0].ports[0].isDigital === true) {
            bytesData[2] = 'A'.charCodeAt()
        } else {
            bytesData[2] = 'I'.charCodeAt()
        }
        if (internalAccessories.portsData[0].ports[1].isDigital === true) {
            bytesData[3] = 'A'.charCodeAt()
        } else {
            bytesData[3] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[1].ports[0].isDigital === true) {
            bytesData[4] = 'A'.charCodeAt()
        } else {
            bytesData[4] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[1].ports[1].isDigital === true) {
            bytesData[5] = 'A'.charCodeAt()
        } else {
            bytesData[5] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[2].ports[0].isDigital === true) {
            bytesData[6] = 'A'.charCodeAt()
        } else {
            bytesData[6] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[2].ports[1].isDigital === true) {
            bytesData[7] = 'A'.charCodeAt()
        } else {
            bytesData[7] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[3].ports[0].isDigital === true) {
            bytesData[8] = 'A'.charCodeAt()
        } else {
            bytesData[8] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[3].ports[1].isDigital === true) {
            bytesData[9] = 'A'.charCodeAt()
        } else {
            bytesData[9] = 'I'.charCodeAt()
        }
        bytesData[10] = 'B'.charCodeAt(0)
        bytesData[11] = 'A'.charCodeAt(0)
        bytesData[12] = 'G'.charCodeAt(0)

        var bytes = unicodeToChar(bytesData)
        console.log('gskbytes', bytes)
        return bytesData
    }
    const recievedBytes_v = (bytesRecieved) => {
        console.log(bytesRecieved, 'recievedData')
        console.log('sensirA1', A1, 'isMic', isMic)
        let sensorData
        if (connectedDevice == 'Ace')
            deviceVersion?.startsWith('1')
                ? (sensorData = {
                      A1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          2
                      ),
                      A2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          3
                      ),
                      B1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          4
                      ),
                      B2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          5
                      ),
                      C1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          6
                      ),
                      C2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          7
                      ),
                      D1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          8
                      ),
                      D2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          9
                      ),
                      E1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          10
                      ),
                      E2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          11
                      ),
                      F1: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          12
                      ),
                      F2: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          13
                      ),
                      isMic: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          14
                      ),
                      isTemperature: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          15
                      ),

                      isTouchZero: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          16
                      ),
                      isTouchOne: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          17
                      ),

                      isTouchTwo: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          18
                      ),
                      isTouchThree: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          19
                      ),
                      isAccelerometerX: Number(
                          playComputerPABytesToSensorData(
                              bytesRecieved.slice(2),
                              '21X'
                          )
                      ),

                      isAccelerometerY: Number(
                          playComputerPABytesToSensorData(
                              bytesRecieved.slice(2),
                              '21Y'
                          )
                      ),

                      isAccelerometerZ: Number(
                          playComputerPABytesToSensorData(
                              bytesRecieved.slice(2),
                              '21Z'
                          )
                      ),

                      isColorSensorRed: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20C'
                      )[0],

                      isColorSensorGreen: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20C'
                      )[1],

                      isColorSensorBlue: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20C'
                      )[2],

                      isGestureSensor: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20G'
                      ),
                      isDistanceSensors: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20D'
                      ),
                      isLightSensor: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          '20L'
                      ),

                      isBattery: playComputerPABytesToSensorData(
                          bytesRecieved.slice(2),
                          22
                      ),
                  })
                : (sensorData = {
                      isTouchZero: MSBLSBToDecimal([
                          bytesRecieved[3],
                          bytesRecieved[2],
                      ]),
                      isTouchOne: MSBLSBToDecimal([
                          bytesRecieved[7],
                          bytesRecieved[6],
                      ]),
                      isTouchTwo: MSBLSBToDecimal([
                          bytesRecieved[11],
                          bytesRecieved[10],
                      ]),
                      A1: MSBLSBToDecimal([bytesRecieved[3], bytesRecieved[2]]),
                      A2: MSBLSBToDecimal([bytesRecieved[5], bytesRecieved[4]]),
                      B1: MSBLSBToDecimal([bytesRecieved[7], bytesRecieved[6]]),
                      B2: MSBLSBToDecimal([bytesRecieved[9], bytesRecieved[8]]),
                      C1: MSBLSBToDecimal([
                          bytesRecieved[11],
                          bytesRecieved[10],
                      ]),
                      C2: MSBLSBToDecimal([
                          bytesRecieved[13],
                          bytesRecieved[12],
                      ]),
                      F1: bytesRecieved[14],
                      F2: bytesRecieved[15],
                      isColorSensorRed: bytesRecieved[16],
                      isColorSensorGreen: bytesRecieved[17],
                      isColorSensorBlue: bytesRecieved[18],
                      isLightSensor: MSBLSBToDecimal([
                          bytesRecieved[17],
                          bytesRecieved[16],
                      ]),
                      isDistanceSensors: bytesRecieved[17],
                      isGestureSensor: bytesRecieved[18],
                      isMic: MSBLSBToDecimal([
                          bytesRecieved[20],
                          bytesRecieved[19],
                      ]),
                      isTemperature: MSBLSBToDecimal([
                          bytesRecieved[22],
                          bytesRecieved[21],
                      ]),
                  })
        else
            sensorData = {
                isBattery: humanoidPABytesToSensorData(bytesRecieved, 21),
                isAccelerometerX: humanoidPABytesToSensorData(
                    bytesRecieved,
                    23
                ),
                isAccelerometerY: humanoidPABytesToSensorData(
                    bytesRecieved,
                    24
                ),
                isAccelerometerZ: humanoidPABytesToSensorData(
                    bytesRecieved,
                    25
                ),
                isGyroX: humanoidPABytesToSensorData(bytesRecieved, 26),
                isGyroY: humanoidPABytesToSensorData(bytesRecieved, 27),
                isGyroZ: humanoidPABytesToSensorData(bytesRecieved, 28),
                A1: humanoidPABytesToSensorData(bytesRecieved, 31),
                A2: humanoidPABytesToSensorData(bytesRecieved, 32),
                B1: humanoidPABytesToSensorData(bytesRecieved, 33),
                B2: humanoidPABytesToSensorData(bytesRecieved, 34),
                C1: humanoidPABytesToSensorData(bytesRecieved, 35),
                C2: humanoidPABytesToSensorData(bytesRecieved, 36),
                D1: humanoidPABytesToSensorData(bytesRecieved, 37),
                D2: humanoidPABytesToSensorData(bytesRecieved, 38),
            }
        recievedBytes_v1(sensorData)
    }

    let time = 0
    const recievedBytes_v1 = async (sensorData) => {
        console.log('RECIVEDDATA', sensorData)
        let startTime = window.performance.now()
        let value = sensorData
        // let value = recievedBytes_v
        let endTime = window.performance.now()
        time += endTime - startTime
        console.log('Value-Y', value, 'Time-X', time)
        if (connectedDevice == 'Ace')
            deviceVersion?.startsWith('1')
                ? setDatas([
                      { uv: sensorData.isTouchOne },
                      { mic: sensorData.isMic },
                      { temp: sensorData.isTemperature },
                      { batt: sensorData.isTemperature },
                      { touch0: sensorData.isTouchZero },
                      { touch2: sensorData.isTouchTwo },
                      { touch3: sensorData.isTouchThree },
                      { acceX: sensorData.isAccelerometerX },
                      { acceY: sensorData.isAccelerometerY },
                      { acceZ: sensorData.isAccelerometerZ },
                      { red: sensorData.isColorSensorRed },
                      { green: sensorData.isColorSensorGreen },
                      { blue: sensorData.isColorSensorBlue },
                      { distance: sensorData.isDistanceSensors },
                      { light: sensorData.isLightSensor },
                      { gesture: sensorData.isGestureSensor },
                      { A1: sensorData.A1 },
                      { A2: sensorData.A2 },
                      { B1: sensorData.B1 },
                      { B2: sensorData.B2 },
                      { C1: sensorData.C1 },
                      { C2: sensorData.C2 },
                      { D1: sensorData.D1 },
                      { D2: sensorData.D2 },
                      { E1: sensorData.E1 },
                      { E2: sensorData.E2 },
                      { F1: sensorData.F1 },
                      { F2: sensorData.F2 },
                      { pv: time },
                  ])
                : setDatas([
                      { mic: sensorData.isMic },
                      { temp: sensorData.isTemperature },
                      { touch0: sensorData.isTouchZero },
                      { touch1: sensorData.isTouchOne },
                      { touch2: sensorData.isTouchTwo },
                      { red: sensorData.isColorSensorRed },
                      { green: sensorData.isColorSensorGreen },
                      { blue: sensorData.isColorSensorBlue },
                      { distance: sensorData.isDistanceSensors },
                      { light: sensorData.isLightSensor },
                      { gesture: sensorData.isGestureSensor },
                      { A1: sensorData.A1 },
                      { A2: sensorData.A2 },
                      { B1: sensorData.B1 },
                      { B2: sensorData.B2 },
                      { C1: sensorData.C1 },
                      { C2: sensorData.C2 },
                      { F1: sensorData.F1 },
                      { F2: sensorData.F2 },
                      { pv: time },
                  ])
        else
            setDatas([
                { batt: sensorData.isBattery },
                { acceX: sensorData.isAccelerometerX },
                { acceY: sensorData.isAccelerometerY },
                { acceZ: sensorData.isAccelerometerZ },
                { gyroX: sensorData.isGyroX },
                { gyroY: sensorData.isGyroY },
                { gyroZ: sensorData.isGyroZ },
                { A1: sensorData.A1 },
                { A2: sensorData.A2 },
                { B1: sensorData.B1 },
                { B2: sensorData.B2 },
                { C1: sensorData.C1 },
                { C2: sensorData.C2 },
                { D1: sensorData.D1 },
                { D2: sensorData.D2 },
                { pv: time },
            ])

        // setDatas([...datas])

        console.log(datas)
    }

    // const handleReadByte_v01 = async (port) => {
    //     const time = props.indexData.graphPlotStorage.time
    //     const intervalId = sessionStorage.getItem('readSetIntervalId')

    //     if (intervalId !== null) {
    //         setTimeout(() => {
    //             sendBytes(['M'.charCodeAt(0), '8'.charCodeAt(0)], port)
    //         }, 500)

    //         clearTimeout(intervalId)
    //         sessionStorage.removeItem('readSetIntervalId')
    //         return
    //     }

    //     while (true) {
    //         await sendBytes(setPABytes_v01(), port)
    //         let read = await readBytesPa(port, false)
    //         recievedBytes_v(read)

    //         await new Promise((resolve) => {
    //             let newIntervalId = setTimeout(
    //                 resolve,
    //                 time.ms +
    //                     time.s * 1000 +
    //                     time.m * 60 * 1000 +
    //                     time.h * 60 * 60 * 1000
    //             )
    //             sessionStorage.setItem('readSetIntervalId', newIntervalId)
    //         })

    //         if (isPauseBtn) break
    //     }
    // }
    props.worker.onmessage = (e) => {
        if (e.data.type == 'PAread') {
            console.log('gsk read is ', e.data.value)
            recievedBytes_v(e.data.value)
        }
    }
    const handleReadByte_v1 = async (port) => {
        const intervalId = sessionStorage.getItem('readSetIntervalId')
        const time = props.indexData.graphPlotStorage.time
        const M8 = new Uint8Array(['M'.charCodeAt(0), '8'.charCodeAt(0)])
        if (intervalId !== null) {
            // await props.indexData.webSerial.port.transferOut(2, M8)
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            return
        }
        let counter =
            time.ms +
            time.s * 1000 +
            time.m * 60 * 1000 +
            time.h * 60 * 60 * 1000
        const newIntervalId = setInterval(async () => {
            props.worker.postMessage({
                type: 'writeArray',
                value: setPABytes_V1(),
            })
        }, counter)
        sessionStorage.setItem('readSetIntervalId', newIntervalId)
    }

    const handleReadByte = async (port) => {
        const time = props.indexData.graphPlotStorage.time
        const intervalId = sessionStorage.getItem('readSetIntervalId')

        if (intervalId !== null) {
            setTimeout(() => {
                sendBytes(['M'.charCodeAt(0), '8'.charCodeAt(0)], port)
            }, 500)

            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            return
        }
        let counter =
            time.ms +
            time.s * 1000 +
            time.m * 60 * 1000 +
            time.h * 60 * 60 * 1000

        const newIntervalId = setInterval(async () => {
            let read
            if (connectedDevice == 'Ace') {
                await sendBytes(setPABytes_v0(), port)
                read = await readBytesPa(port, false, 26)
            } else {
                await sendBytes(setPABytesHumanoid(), port)
                read = await readBytesPa(port, false, 36)
            }

            recievedBytes_v(read)
        }, counter)
        sessionStorage.setItem('readSetIntervalId', newIntervalId)
    }
    console.log(
        'gsk bargraph',
        props.indexData.graphPlotStorage.internalaccessories
    )

    return (
        <>
            <div className={style.Main_Container}>
                <div id={style.Navbar}>
                    <ProgramHeader
                        data={{
                            options: [
                                'Select Sensors',
                                'Select Ports',
                                'Set Time',
                                'Set Graph',
                                'Plot',
                            ],
                            selected: 4,
                        }}
                        showSave={false}
                        showHelp={true}
                        // handleHelp={helpBtn}
                    />
                </div>
                <div id={style.Body}>
                    {' '}
                    <div className={stylee.Main_Container}>
                        {/* sidebar */}
                        {isArrowBtn === false ? (
                            <>
                                {' '}
                                <div
                                    id={stylee.sidebar}
                                    style={{
                                        backgroundImage: `url("${renderPrgImage(
                                            'smallleftComponentBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',
                                        position: 'relative',
                                        borderRadius: '8px',
                                    }}
                                ></div>
                                {/* sidebar arrow button */}
                                <div
                                    id={stylee.arrowBtn}
                                    style={{
                                        backgroundImage: `url("${renderPrgImage(
                                            'CloseGraphSideBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '40.5% 13%',
                                        position: 'relative',
                                        transform: 'translate(0,43%)',
                                    }}
                                    onClick={handleClick}
                                ></div>
                            </>
                        ) : (
                            <div
                                id={stylee.arrowBtn}
                                style={{
                                    backgroundImage: `url("${renderPrgImage(
                                        'CloseGraphSideBar'
                                    )}")`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '40.5% 13%',
                                    position: 'relative',
                                    transform: 'translate(-300%, 43%)',
                                }}
                                onClick={handleClick}
                            ></div>
                        )}
                        {/* graph */}
                        <div
                            id={stylee.body}
                            style={
                                isArrowBtn === false
                                    ? { transform: 'translate(-9%, 11%)' }
                                    : { transform: 'translate(-16%, 11%)' }
                            }
                        >
                            <ResponsiveContainer width="100%">
                                <BarChart
                                    width={1000}
                                    height={250}
                                    data={datas}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    {/* {barField.map((data) => (
                                        <Bar dataKey="mic" fill="#EC9EA4" />
                                    ))} */}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isTouchOne ===
                                    true ? (
                                        <></>
                                    ) : null}
                                    <Bar dataKey="touch1" fill="#EDDD8A" />

                                    {/* {props.indexData.graphPlotStorage
                                        .internalAccessories.isMic === true ? (
                                        <Bar dataKey="mic" fill="#EC9EA4" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isTemperature ===
                                    true ? (
                                        <Bar dataKey="temp" fill="#69D2C8" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isBattery ===
                                    true ? (
                                        <Bar dataKey="batt" fill="#98D0ED" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isTouchZero ===
                                    true ? (
                                        <Bar dataKey="touch0" fill="#E3EDE7" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isTouchTwo ===
                                    true ? (
                                        <Bar dataKey="touch2" fill="#FA8D5A" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isTouchThree ===
                                    true ? (
                                        <Bar dataKey="touch3" fill="#541743" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isAccelerometerX === true ? (
                                        <Bar dataKey="acceX" fill="#E3EDE7" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isAccelerometerY === true ? (
                                        <Bar dataKey="acceY" fill="#FA8D5A" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isAccelerometerZ === true ? (
                                        <Bar dataKey="acceZ" fill="#541743" />
                                    ) : null}

                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isColorSensorRed === true ? (
                                        <Bar dataKey="red" fill="red" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isColorSensorGreen === true ? (
                                        <Bar dataKey="green" fill="green" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isColorSensorBlue === true ? (
                                        <Bar dataKey="blue" fill="blue" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories
                                        .isDistanceSensors === true ? (
                                        <Bar
                                            dataKey="distance"
                                            fill="#F17D1C"
                                        />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isLightSensor ===
                                    true ? (
                                        <Bar dataKey="light" fill="#758B70" />
                                    ) : null}
                                    {props.indexData.graphPlotStorage
                                        .internalAccessories.isGestureSensor ===
                                    true ? (
                                        <Bar dataKey="gesture" fill="#606060" />
                                    ) : null}
                                    // {A1 === true ? (
                                    //     <Bar dataKey="A1" fill="#EC9EA4" />
                                    // ) : null}
                                    {A2 === true ? (
                                        <Bar dataKey="A2" fill="#EC9EA4" />
                                    ) : null}
                                    {B1 === true ? (
                                        <Bar dataKey="B1" fill="#EC9EA4" />
                                    ) : null}
                                    {B2 === true ? (
                                        <Bar dataKey="B2" fill="#EC9EA4" />
                                    ) : null}
                                    {C1 === true ? (
                                        <Bar dataKey="C1" fill="#EC9EA4" />
                                    ) : null}
                                    {C2 === true ? (
                                        <Bar dataKey="C2" fill="#EC9EA4" />
                                    ) : null}
                                    {D1 === true ? (
                                        <Bar dataKey="D1" fill="#FAFAFA" />
                                    ) : null}
                                    {D2 === true ? (
                                        <Bar dataKey="D2" fill="#FAFAFA" />
                                    ) : null}
                                    {E1 === true ? (
                                        <Bar dataKey="E1" fill="#EC9EA4" />
                                    ) : null}

                                    {E2 === true ? (
                                        <Bar dataKey="E2" fill="#EC9EA4" />
                                    ) : null}
                                    {F1 === true ? (
                                        <Bar dataKey="F1" fill="#EC9EA4" />
                                    ) : null}

                                    {F2 === true ? (
                                        <Bar dataKey="F2" fill="blue" />
                                    ) : null} */}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        {/* bottom */}

                        <div
                            id={stylee.bottom}
                            style={{
                                transform: 'translateX(-5%) translateY(35%)',
                            }}
                        >
                            <div className={stylee.sub_Bottom}>
                                {isPauseBtn === false ? (
                                    <img
                                        src={renderPrgImage('play')}
                                        onClick={pauseHandleClick}
                                    ></img>
                                ) : (
                                    <img
                                        src={renderPrgImage('pause')}
                                        onClick={pauseHandleClick}
                                    ></img>
                                )}

                                <img
                                    style={{
                                        width: '10vh',
                                        transform:
                                            'translateX(70%) translateY(70%)',
                                    }}
                                    src={renderPrgImage('backBtn')}
                                    onClick={onClickBack}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        graphPlotStorage: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        assemblyComponent: (data) => {
            dispatch({ type: 'ASSEMBLY_SELECTION', payload: data })
        },
        // webSerialConnect: (data) => {
        //     dispatch(webSerialConnect(data))
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BarGraph)
