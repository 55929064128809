var Colors
export default Colors = {
    green: '#009499',
    blue: '#2979B8',
    blueshade: '#5aa1cf',
    pink: '#ED1459',
    teal: '#6FC7AC',
    cyan: '#0FF',
    purple: '#A64C9D',
    violet: '#65539A',
    yellow: '#F4B639',
    grey: '#D2D2D2',
    white: '#FFF',
    disabledgrey: '#878888',
    bordergrey: '#AAA',
    black: '#3E3F3D',
    flash_yellow: '#FCD928',
    insert: '#3FD0C1',
    // 'delete': '#E24D46',

    grey: '#adb9cc',
    orange: '#ff8000',
    if: '#d65858',
    wait: '#8c5ab8',
    repeat: '#53a65f',
    delete: '#3FD0C1',
}
