var INITIALSTATE = {
    showModal: false,
    modalType: null,
}

const popUpModalReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'SHOW_MODAL': {
            const data = {
                ...state,
                showModal: true,
                modalType: action.payload.modalType,
            }
            return data
        }

        case 'HIDE_MODAL': {
            return INITIALSTATE
        }

        default:
            return state
    }
}

export default popUpModalReducer
