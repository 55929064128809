export var internalaccessoriesObj = {
    Four_in_one_sensor: {
        isDistanceSensors: false,
        isGestureSensor: false,
        isLightSensor: false,
        isColorSensor: false,
    },
    isFour_in_one_sensor: false,

    isMic: false,
    isTemperature: false,
    isBattery: false,
    isAccelerometer: false,

    isSwitch1: false,
    isSwitch2: false,

    // input
    isTouchZero: false,
    isTouchOne: false,
    isTouchTwo: false,
    isTouchThree: false,

    // output
    isTouchZeroOutput: false,
    isTouchOneOutput: false,
    isTouchTwoOutput: false,
    isTouchThreeOutput: false,

    isLeftEye: false,
    isRightEye: false,
    isbuzzer: false,

    isSmileOne: false,
    isSmileTwo: false,
    isSmileThree: false,
    isSmileFour: false,
}
console.log('Internal Accessories', internalaccessoriesObj)
