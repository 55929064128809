import React, { useState, useEffect } from 'react'
import PanelModal from '../../../../../ReusableComponents/UI/PanelModal'
import style from './StartPanel.module.css'

const initialStartButtonState = [
    { name: 'Bicounter1', id: 'Bic1', isEnabled: false },
    { name: 'Bicounter2', id: 'Bic2', isEnabled: false },
    { name: 'BT TX', id: 'BtTx', isEnabled: false },
    { name: 'Biflag1', id: 'Bif1', isEnabled: false },
    { name: 'Biflag2', id: 'Bif2', isEnabled: false },
    { name: 'BT RX', id: 'BtRx', isEnabled: false },
    { name: 'USB TX', id: 'UsbTx', isEnabled: false },
    { name: 'USB RX', id: 'UsbRx', isEnabled: false },
    { name: 'BT Remote', id: 'BtRte', isEnabled: false },
]

function StartPanel({ handleClose }) {
    const [startButtonState, setStartButtonState] = useState(
        initialStartButtonState
    )

    useEffect(() => {
        const isPrevStatePresent =
            sessionStorage.getItem('startButtonState') || false
        if (isPrevStatePresent)
            setStartButtonState(JSON.parse(isPrevStatePresent))
    }, [])

    useEffect(() => {
        sessionStorage.setItem(
            'startButtonState',
            JSON.stringify(startButtonState)
        )
    }, [startButtonState])

    const handleClick = (params) => {
        setStartButtonState((initialState) => {
            return initialState.map((item) => {
                if (item.id === params.target.id) {
                    item.isEnabled = !item.isEnabled
                }
                return item
            })
        })
    }

    return (
        <PanelModal handleClose={handleClose}>
            <div className={style.buttonGrp}>
                {startButtonState.map((item) => {
                    return (
                        <button
                            id={item.id}
                            key={item.id}
                            className={item.isEnabled && style.enabled}
                            onClick={handleClick}
                        >
                            {item.name}
                        </button>
                    )
                })}
            </div>
        </PanelModal>
    )
}

export default StartPanel
