import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { webSerialAction } from '../../../../../redux/actions/index'
import renderImage from '../../../../../source/importImg'
import RemSlider from '../../../../ReusableComponents/RemSlider/RemSlider'
import Slider from '../../../../ReusableComponents/Slider/Slider'
import MainHeader from '../../../../ReusableComponents/Header/MainHeader'

import './RemoteSection.css'
import { sendBytes } from '../../../../ReusableComponents/ByteTransfer'

let initalRender = true
let count = 0

let value = false
const RstyleDevicePC = {
    width: '20vw',
    height: '16.5vw',

    backgroundImage: `url(${renderImage('PCimg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '76% 96%',
    backgroundPosition: '50% 50%',
    zIndex: 110,
    top: '25%',
    left: '15%',

    position: 'relative',
}

function Remote(props) {
    let history = useHistory()

    const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]

    const [isBuzzer, setBuzzer] = useState(0)
    const [isSmile1, setSmile1] = useState(false)
    const [isSmile2, setSmile2] = useState(false)
    const [isSmile3, setSmile3] = useState(false)
    const [isSmile4, setSmile4] = useState(false)

    const [isTalkback, setTalkback] = useState(false)
    const [isDisco, setDisco] = useState(false)
    const [isGesture, setGesture] = useState(false)

    const [isLeftGreenSlider, setLeftGreenSlider] = useState(0)
    const [isLeftBlueSlider, setLeftBlueSlider] = useState(0)
    const [isLeftRedSlider, setLeftRedSlider] = useState(0)

    const [isRightGreenSlider, setRightGreenSlider] = useState(0)
    const [isRightBlueSlider, setRightBlueSlider] = useState(0)
    const [isRightRedSlider, setRightRedSlider] = useState(0)

    const [isL_Red, setL_Red] = useState(0)
    const [isL_Green, setL_Green] = useState(0)
    const [isL_Blue, setL_Blue] = useState(0)

    const [isR_Red, setR_Red] = useState(0)
    const [isR_Green, setR_Green] = useState(0)
    const [isR_Blue, setR_Blue] = useState(0)

    const [isFreq, setFreq] = useState(0)

    const [valueBlue, setvalueBlue] = useState(0)
    const [cur, setCur] = useState(0)
    const [pre, setPre] = useState(0)
    const [buttonDisable, setButtonDisable] = useState(false)

    const [disable, setDisable] = useState(false)
    const [disableDisco, setDisableDisco] = useState(false)

    let data

    //talkback
    let data1 = ['R'.charCodeAt(), 't'.charCodeAt(), isTalkback ? 49 : 48]
    //gesture
    let data2 = ['R'.charCodeAt(), 'g'.charCodeAt(), isGesture ? 49 : 48]
    //disco
    let data3 = ['R'.charCodeAt(), 'd'.charCodeAt(), isDisco ? 49 : 48]

    useEffect(() => {
        if (
            props.webSerial.isConnected &&
            count === 0
        ) {
            sendPlayBytes()
            count = count + 1
        }
        if (props.webSerial.isConnected && count > 0) {
            if (!JSON.parse(sessionStorage.getItem('isPlay'))) {
                sendPlayBytes()
            }
        }
        if (!props.webSerial.isConnected) {
            count = 0
        }
    })

    useEffect(() => {
        if (initalRender) {
            initalRender = false
        } else {
            leftEyeData()
        }
    }, [isSmile1, isSmile2, isSmile3, isSmile4])

    const gobackUrl = () => {
        sessionStorage.setItem('slideDirection', true)
        let data
        if (isDisco) {
            setDisco(false)
            setDisable(false)
            data = [82, 100, 48]
        } else if (isGesture) {
            setGesture(false)
            setDisable(false)
            data = [82, 103, 48]
        } else if (isTalkback) {
            setTalkback(false)
            setDisable(false)
            data = [82, 116, 48]
        } else {
            data = [82, 76, 0, 0, 0, 82, 0, 0, 0, 66, 0, 48, 48, 48, 48]
        }

        writePort(data)
        history.goBack()
    }

    const sendPlayBytes = async() => {
        sessionStorage.setItem('isPlay', true)
        const PLAY = [
            'P'.charCodeAt(),
            'L'.charCodeAt(),
            'A'.charCodeAt(),
            'Y'.charCodeAt(),
        ]
        // const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
   
        
        const playBytes = setTimeout(() => {
            props.worker.postMessage({
                type: 'write',
                value: 'PLAY',
            })
            clearTimeout(playBytes)
        }, 200)
        const clearPin = setTimeout(() => {
            // writePort(M8)
            clearTimeout(clearPin)
        }, 300)
    }

    const handleTalkback = (e) => {
        if (buttonDisable) {
            return
        }
        if (isTalkback) {
            setTalkback(false)
            setDisable(false)
        } else {
            setButtonDisable(true)
            setTimeout(() => {
                setButtonDisable(false)
            }, 1100)

            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)

            setGesture(false)
            setDisco(false)
            setTalkback(true)
        }
        talkClick()
    }

    const handleDisco = async (e) => {
        if (buttonDisable) {
            return
        }
        //////// bugfix/PLAY-3 ///////////
        if (disableDisco) return
        setDisableDisco(true)
        setTimeout(() => {
            setDisableDisco(false)
        }, 1000)
        //////// bugfix/PLAY-3 ///////////

        if (isDisco) {
            setDisco(false)
            setDisable(false)
        } else {
            setButtonDisable(true)
            setTimeout(() => {
                setButtonDisable(false)
            }, 1000)

            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)

            //
            setTalkback(false)
            setGesture(false)
            setDisco(true)
        }

        await discoClick()
    }

    const handleGesture = (e) => {
        if (buttonDisable) {
            return
        }
        if (isGesture) {
            setGesture(false)
            setDisable(false)
        } else {
            setButtonDisable(true)
            setTimeout(() => {
                setButtonDisable(false)
            }, 1000)

            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)

            setTalkback(false)

            setDisco(false)
            setGesture(true)
        }
        gestureClick()
    }

    const talkClick = async () => {
        if (isTalkback) {
            let d = [82, 116, 48]
            writePort(d)
        } else if (data3[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)
            setTimeout(() => {
                let d2 = [82, 116, 49]
                writePort(d2)
            }, 1000)
        } else if (data2[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)

            setTimeout(() => {
                let d2 = [82, 116, 49]
                writePort(d2)
            }, 1000)
        } else {
            // writePort(M8)
            let data = [
                'R'.charCodeAt(),
                'L'.charCodeAt(),
                0,
                0,
                0,

                'R'.charCodeAt(),
                0,
                0,
                0,

                'B'.charCodeAt(),
                0,
                0,
                0,
                0,
                0,
            ]

            setTimeout(() => {
                writePort(data)
            }, 300)
            setTimeout(() => {
                let d2 = [82, 116, 49] //Rt1
                writePort(d2)
            }, 700)
        }
    }

    const gestureClick = async () => {
        if (isGesture) {
            let d = [82, 103, 48]
            writePort(d)
        } else if (data1[2] == 49) {
            let d = [82, 116, 48]
            writePort(d)
            setTimeout(() => {
                let d2 = [82, 103, 49]
                writePort(d2)
            }, 1000)
        } else if (data3[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)

            setTimeout(() => {
                let d2 = [82, 103, 49]
                writePort(d2)
            }, 1000)
        } else {
            // writePort(M8)
            let data = [
                'R'.charCodeAt(),
                'L'.charCodeAt(),
                0,
                0,
                0,

                'R'.charCodeAt(),
                0,
                0,
                0,

                'B'.charCodeAt(),
                0,
                0,
                0,
                0,
                0,
            ]

            setTimeout(() => {
                writePort(data) //with this gesture not working
            }, 300)
            setTimeout(() => {
                let d2 = [82, 103, 49]
                writePort(d2)
            }, 700)
        }
    }

    const discoClick = async () => {
        //await writePort(M8);
        if (isDisco) {
            let d = [82, 100, 48]
            writePort(d)
        } else if (data1[2] == 49) {
            // await writePort(M8)
            let d = [82, 116, 48]
            writePort(d)
            // await writePort([82, 116, 48]);

            setTimeout(() => {
                let d2 = [82, 100, 49]
                writePort(d2)
            }, 1000)
        } else if (data2[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)

            setTimeout(() => {
                let d2 = [82, 100, 49]
                writePort(d2)
            }, 1000)
        } else {
            // writePort(M8)
            let data = [
                'R'.charCodeAt(),
                'L'.charCodeAt(),
                0,
                0,
                0,

                'R'.charCodeAt(),
                0,
                0,
                0,

                'B'.charCodeAt(),
                0,
                0,
                0,
                0,
                0,
            ]

            // setTimeout(() => {
            //     writePort(data)    //with this- disco not working
            // }, 300)
            setTimeout(() => {
                let d2 = [82, 100, 49]
                writePort(d2)
            }, 700)
        }
    }

    const Sliderhandler = (value, name) => {
        if (name == 'Right_green_slider') {
            setRightGreenSlider(value)
        } else if (name == 'Right_blue_slider') {
            setRightBlueSlider(value)
        } else if (name == 'Right_red_slider') {
            setRightRedSlider(value)
        }

        switch (name) {
            case 'Left_green_slider': {
                setLeftGreenSlider(value)
                break
            }
            case 'Left_blue_slider': {
                setLeftBlueSlider(value)
                break
            }
            case 'Left_red_slider': {
                setLeftRedSlider(value)
                break
            }
            case 'buzzer_slider': {
                setBuzzer(value)
                break
            }
        }
    }

    const handleSimle1 = (e) => {
        setSmile1(!isSmile1)
    }
    const handleSimle2 = (e) => {
        setSmile2(!isSmile2)
    }

    const handleSimle3 = (e) => {
        setSmile3(!isSmile3)
    }

    const handleSimle4 = (e) => {
        setSmile4(!isSmile4)
    }

    const leftEyeData = async () => {
        console.log('L_B', isL_Blue)

        console.log('left eye data activated')
        let data = [
            'R'.charCodeAt(),
            'L'.charCodeAt(),
            isLeftRedSlider ? JSON.parse(isL_Red) : 0,
            isLeftGreenSlider ? JSON.parse(isL_Green) : 0,
            isLeftBlueSlider ? JSON.parse(isL_Blue) : 0,

            'R'.charCodeAt(),
            isRightRedSlider ? JSON.parse(isR_Red) : 0,
            isRightGreenSlider ? JSON.parse(isR_Green) : 0,
            isRightBlueSlider ? JSON.parse(isR_Blue) : 0,

            'B'.charCodeAt(),
            JSON.parse(isFreq),
            isSmile1 ? '1'.charCodeAt() : '0'.charCodeAt(),
            isSmile2 ? '1'.charCodeAt() : '0'.charCodeAt(),
            isSmile3 ? '1'.charCodeAt() : '0'.charCodeAt(),
            isSmile4 ? '1'.charCodeAt() : '0'.charCodeAt(),
        ]
        console.log('emitting EyeData ', data)
        //socket.emit("/remote", data, "firoz");
        let no = props.webSerial.isConnected
        if (no) {
            //await props.webSerial.port.readable.getReader().cancel();
            //debugger;

            // await props.webSerial.port.cancel()

            await writePort(data)
        }
    }

    async function writePort(data) {
        if (props.webSerial.isConnected) {
            console.log('Play Mode', data)
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        }
        // await sendBytes(data, props.webSerial.port)
    }

    useEffect(() => {
        if (!props.webSerial.isConnected) {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setGesture(false)
            setDisco(false)
            setTalkback(false)
        } else {
            setDisable(false)
        }
    }, [props.webSerial.isConnected])
    console.log('WEBSIRAL', props.webSerial.isConnected)
    return (
        <div>
            {' '}
            <img
                className="Back_BTN"
                src={renderImage('backBtn')}
                onClick={gobackUrl}
            />
            <div className="Remote_Body">
                <div className="Buzzer_Lefteye">
                    <div className="Buzzer_Partition">
                        <div>
                            <img
                                className="Buzzer_Bg"
                                src={renderImage('buzzer_bg_Svg')}
                                draggable="false"
                            ></img>
                            <h2 className="Buzzer_txt">Buzzer Frequency </h2>
                        </div>

                        <div className="Slider_Div3">
                            {isBuzzer > 0 ? (
                                <Slider
                                    rangImgName="frequency_slider"
                                    title="buzzer_slider"
                                    onChangehandler={Sliderhandler}
                                    componentName="freq"
                                    leftEyeData={leftEyeData}
                                    max={100}
                                    min={0}
                                    setFreq={setFreq}
                                    isFreq={isFreq}
                                    disable={disable}
                                />
                            ) : (
                                <Slider
                                    rangImgName="inactiveslider"
                                    title="buzzer_slider"
                                    onChangehandler={Sliderhandler}
                                    componentName="freq"
                                    leftEyeData={leftEyeData}
                                    max={100}
                                    min={0}
                                    setFreq={setFreq}
                                    isFreq={isFreq}
                                    disable={disable}
                                />
                            )}
                        </div>
                    </div>

                    <div className="Left_Partition">
                        <div>
                            <img
                                className="Left_Eye_Backdround"
                                src={renderImage('eye_bg_Svg')}
                                draggable="false"
                            ></img>
                        </div>
                        <div className="Slider_Div1">
                            <div>
                                {isLeftRedSlider > 0 ? (
                                    <Slider
                                        rangImgName="red_slider"
                                        title="Left_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_red"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setL_Red={setL_Red}
                                        isL_Red={isL_Red}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Left_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_red"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setL_Red={setL_Red}
                                        isL_Red={isL_Red}
                                        disable={disable}
                                    />
                                )}
                            </div>
                            <div>
                                {isLeftGreenSlider > 0 ? (
                                    <Slider
                                        rangImgName="green_slider"
                                        title="Left_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_green"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setL_Green={setL_Green}
                                        isL_Green={isL_Green}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Left_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_green"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setL_Green={setL_Green}
                                        isL_Green={isL_Green}
                                        disable={disable}
                                    />
                                )}
                            </div>
                            <div>
                                {isLeftBlueSlider > 0 ? (
                                    <Slider
                                        rangImgName="blue_slider"
                                        title="Left_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_blue"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setL_Blue={setL_Blue}
                                        isL_Blue={isL_Blue}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Left_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_blue"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setL_Blue={setL_Blue}
                                        isL_Blue={isL_Blue}
                                        disable={disable}
                                    />
                                )}
                            </div>
                        </div>

                        <h2 className="Left_Eye_txt">
                            Left <br /> Eye
                        </h2>
                    </div>
                </div>
                <div className="Ace_Teeth">
                    <div style={RstyleDevicePC} className="ace">
                        {isBuzzer > 0 ? (
                            <img
                                className="Buzzer_Inactive"
                                src={renderImage('buzzerAc_Svg')}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Buzzer_Inactive"
                                src={renderImage('buzzerIA_Svg')}
                                draggable="false"
                            ></img>
                        )}

                        {isLeftGreenSlider > 0 ||
                        isLeftRedSlider > 0 ||
                        isLeftBlueSlider > 0 ? (
                            <img
                                className="Left_Eye_Inactive"
                                src={renderImage('eyeAc_Svg')}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Left_Eye_Inactive"
                                src={renderImage('eyeIA_Svg')}
                                draggable="false"
                            ></img>
                        )}

                        {isRightGreenSlider > 0 ||
                        isRightRedSlider > 0 ||
                        isRightBlueSlider > 0 ? (
                            <img
                                className="Right_Eye_Inactive"
                                src={renderImage('eyeAc_Svg')}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Right_Eye_Inactive"
                                src={renderImage('eyeIA_Svg')}
                                draggable="false"
                            ></img>
                        )}
                        {/* <img className="Right_Eye_Inactive" src={eyeIA}></img> */}

                        {isSmile1 == false ? (
                            <img
                                className="Ace_Teeth1"
                                src={renderImage('teethIA_Svg')}
                            ></img>
                        ) : (
                            <img
                                className="Ace_Teeth1"
                                src={renderImage('teethAc_Svg')}
                                draggable="false"
                            ></img>
                        )}

                        {isSmile2 == false ? (
                            <img
                                className="Ace_Teeth2"
                                src={renderImage('teethIA_Svg')}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Ace_Teeth2"
                                src={renderImage('teethAc_Svg')}
                                draggable="false"
                            ></img>
                        )}

                        {isSmile3 == false ? (
                            <img
                                className="Ace_Teeth3"
                                src={renderImage('teethIA_Svg')}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Ace_Teeth3"
                                src={renderImage('teethAc_Svg')}
                                draggable="false"
                            ></img>
                        )}

                        {isSmile4 == false ? (
                            <img
                                className="Ace_Teeth4"
                                src={renderImage('teethIA_Svg')}
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Ace_Teeth4"
                                src={renderImage('teethAc_Svg')}
                                draggable="false"
                            ></img>
                        )}

                        <img
                            className="Ace_4Teeth"
                            src={renderImage('FourteethIA_Svg')}
                            draggable="false"
                        ></img>
                    </div>
                    <div>
                        <img
                            className="Teeth_Bg"
                            src={renderImage('teeth_bg_Svg')}
                            draggable="false"
                        ></img>

                        {isSmile1 ? (
                            <img
                                className="Teeth_Smile1"
                                src={renderImage('smile1_Active')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle1()
                                }}
                                alt="smileOneInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Teeth_Smile1"
                                src={renderImage('smile1')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle1()
                                }}
                                alt="smileOneInactive"
                                draggable="false"
                            ></img>
                        )}

                        {isSmile2 ? (
                            <img
                                className="Teeth_Smile2"
                                src={renderImage('smile2_Active')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle2()
                                }}
                                alt="smileTwoInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Teeth_Smile2"
                                src={renderImage('smile2')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle2()
                                }}
                                alt="smileTwoInactive"
                                draggable="false"
                            ></img>
                        )}

                        {isSmile3 ? (
                            <img
                                className="Teeth_Smile3"
                                src={renderImage('smile3_Active')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle3()
                                }}
                                alt="smileThreeInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Teeth_Smile3"
                                src={renderImage('smile3')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle3()
                                }}
                                alt="smileThreeActive"
                                draggable="false"
                            ></img>
                        )}
                        {isSmile4 ? (
                            <img
                                className="Teeth_Smile4"
                                src={renderImage('smile4_Active')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle4()
                                }}
                                alt="smileFourInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Teeth_Smile4"
                                src={renderImage('smile4')}
                                onClick={() => {
                                    if (disable) return
                                    handleSimle4()
                                }}
                                alt="smileFourActive"
                                draggable="false"
                            ></img>
                        )}
                    </div>
                </div>
                <div className="Func_Righteye">
                    <div>
                        {isTalkback == false ? (
                            <img
                                style={
                                    buttonDisable
                                        ? { opacity: '0.4', cursor: 'default' }
                                        : { opacity: '1', cursor: 'pointer' }
                                }
                                className="Talkback_IA"
                                src={renderImage('talk_IA')}
                                onClick={(e) => {
                                    handleTalkback()
                                }}
                                // onClick={handleTalkback}
                                alt="TalkbackInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Talkback_IA"
                                src={renderImage('talk_Ac')}
                                onClick={(e) => {
                                    handleTalkback()
                                }}
                                // onClick={handleTalkback}
                                alt="TalkbackActive"
                                draggable="false"
                            ></img>
                        )}

                        <p
                            style={
                                buttonDisable
                                    ? { opacity: '0.4' }
                                    : { opacity: '1' }
                            }
                            className="Talkback_txt"
                        >
                            Talk Back
                        </p>

                        {isDisco == false ? (
                            <img
                                style={
                                    buttonDisable
                                        ? { opacity: '0.4', cursor: 'default' }
                                        : { opacity: '1', cursor: 'pointer' }
                                }
                                className="Disco_IA"
                                src={renderImage('disco_IA')}
                                onClick={(e) => {
                                    handleDisco()
                                }}
                                // onClick={handleDisco}
                                alt="DiscoInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Disco_IA"
                                src={renderImage('disco_Ac')}
                                onClick={(e) => {
                                    handleDisco()
                                }}
                                // onClick={handleDisco}
                                alt="DiscoActive"
                                draggable="false"
                            ></img>
                        )}
                        <p
                            style={
                                buttonDisable
                                    ? { opacity: '0.4' }
                                    : { opacity: '1' }
                            }
                            className="Disco_txt"
                        >
                            Disco
                        </p>

                        {isGesture == false ? (
                            <img
                                style={
                                    buttonDisable
                                        ? { opacity: '0.4', cursor: 'default' }
                                        : { opacity: '1', cursor: 'pointer' }
                                }
                                className="Gesture_IA"
                                src={renderImage('gesture_IA')}
                                onClick={(e) => {
                                    handleGesture()
                                }}
                                // onClick={handleGesture}
                                alt="GestureInactive"
                                draggable="false"
                            ></img>
                        ) : (
                            <img
                                className="Gesture_IA"
                                src={renderImage('gesture_Ac')}
                                onClick={(e) => {
                                    handleGesture()
                                }}
                                // onClick={handleGesture}
                                alt="GestureActive"
                                draggable="false"
                            ></img>
                        )}

                        <p
                            style={
                                buttonDisable
                                    ? { opacity: '0.4' }
                                    : { opacity: '1' }
                            }
                            className="Gesture_txt"
                        >
                            Gesture
                        </p>
                    </div>
                    <div className="Right_Partition">
                        <div>
                            <img
                                className="Right_Eye_Backdround"
                                src={renderImage('eye_bg_Svg')}
                                draggable="false"
                            ></img>
                            <h2 className="Right_Eye_txt">Right Eye</h2>
                        </div>
                        <div className="Slider_Div2">
                            <div>
                                {isRightRedSlider > 0 ? (
                                    <Slider
                                        rangImgName="red_slider"
                                        title="Right_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_red"
                                        leftEyeData={leftEyeData}
                                        max={101}
                                        setR_Red={setR_Red}
                                        isR_Red={isR_Red}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Right_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_red"
                                        leftEyeData={leftEyeData}
                                        max={101}
                                        setR_Red={setR_Red}
                                        isR_Red={isR_Red}
                                        disable={disable}
                                    />
                                )}
                            </div>

                            <div>
                                {isRightGreenSlider > 0 ? (
                                    <Slider
                                        rangImgName="green_slider"
                                        title="Right_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_green"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setR_Green={setR_Green}
                                        isR_Green={isR_Green}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Right_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_green"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setR_Green={setR_Green}
                                        isR_Green={isR_Green}
                                        disable={disable}
                                    />
                                )}
                            </div>

                            <div>
                                {isRightBlueSlider > 0 ? (
                                    <Slider
                                        rangImgName="blue_slider"
                                        title="Right_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_blue"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setR_Blue={setR_Blue}
                                        isR_Blue={isR_Blue}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Right_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_blue"
                                        leftEyeData={leftEyeData}
                                        max={100}
                                        setR_Blue={setR_Blue}
                                        isR_Blue={isR_Blue}
                                        disable={disable}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Remote)
