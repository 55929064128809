import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import LogicStyle from './LogicPrgm.module.css'
import renderPrgImage from '../../../../source/programImg'
import renderImage from '../../../../source/importImg'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    zIndex: '1000',
                    // right: '-17%',
                    left: '101%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={renderImage('rightArrow')}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={renderImage('leftArrow')}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
export class LogicPrgm extends Component {
    render() {
        let deviceName = 'PlayComputer'
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        switch (connectedDevice) {
            case 'Ace':
                deviceName = 'PlayComputer'
                break
            case 'Humanoid':
                deviceName = 'Zing'
                break
            case 'Hexapod':
                deviceName = 'Crawl-e'
                break
            case 'Klaw':
                deviceName = 'Klaw-b'
                break
            default:
                deviceName = connectedDevice
        }
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        }
        return (
            <div>
                <div className={LogicStyle.container}></div>
                <Slider
                    {...settings}
                    style={{
                        transform: 'translate(-50%,-50%)',
                        top: '47%',
                        left: '50%',
                        position: 'absolute',
                    }}
                >
                    <div className={LogicStyle.wdt}>
                        <img
                            className={LogicStyle.img}
                            src={renderPrgImage('LogicPrgf1')}
                        />
                        <div className={LogicStyle.Hel}>
                            <p className={LogicStyle.Hel_P}>
                                START:This is the default hex block that
                                indicates the start of the program flow.
                                <br />
                                <br />
                                Select: Clicking this hex button, enables an
                                option to add more hex blocks.
                                <br />
                                <br />
                                On double click, on any block, we get 2 options
                                “ Insert” and “Delete”. Note: this is not
                                applicable to “Start” block. for start block
                                open the start panel variable list.
                                <br />
                                <br />
                                Insert: Use this option, when you want to insert
                                a new hex block in between 2 existing hex
                                blocks.
                                <br />
                                <br />
                                delete: Use this option, when you want to delete
                                a hex block. Note: this cannot be used for
                                “Start” block.
                            </p>
                        </div>
                    </div>
                    {/* <div className="wdt>
            <img
              style={{ height: "40px" }}
              src={}
              className="img"
            />
          </div> */}
                    <div className={LogicStyle.wdt}>
                        <img
                            className={LogicStyle.img}
                            src={renderPrgImage('LogicPrgf2')}
                        />
                        <div className={LogicStyle.con}>
                            <p className={LogicStyle.con_P}>
                                Decision: use this hex block when you want to
                                check for a condition of any sensor.
                                <br />
                                <br />
                                Stop: use this hex block, when you want to close
                                the conditional check and its respective output
                                blocks. This is similar to closing bracket for
                                an if statement in C programming.
                            </p>
                        </div>
                    </div>
                    <div className={LogicStyle.wdt}>
                        <img
                            className={LogicStyle.img}
                            src={renderPrgImage('LogicPrgf3')}
                        />
                        <div className={LogicStyle.Mus}>
                            <p className={LogicStyle.Mus_P}>
                                Wait: use this hex block when you need a time
                                delay for an activity or run that activity for a
                                stipulated period of time. We an set the wait in
                                milliseconds, seconds, minutes and hours.
                                <br />
                                <br />
                                Action: use this hex block, to set an output
                                port as either on or off, or various
                                frequencies, or set a variable to a defined
                                value.
                            </p>
                        </div>
                    </div>
                    <div className={LogicStyle.wdt}>
                        <img
                            className={LogicStyle.img}
                            src={renderPrgImage('LogicPrgf4')}
                        />
                        <div className={LogicStyle.Cam}>
                            <p className={LogicStyle.Cam_P}>
                                loop: use this hex block, when you need a
                                following set of hex blocks to run defined
                                number of times, given by a value in its
                                property panel.
                                <br />
                                {/* <br />
                                End loop: use this hex block to close the loop
                                set of hex blocks, which run for defined number
                                of times. */}
                            </p>
                        </div>
                    </div>

                    <div className={LogicStyle.wdt}>
                        <img
                            className={LogicStyle.img}
                            src={renderPrgImage('LogicPrgf5')}
                        />
                        <div className={LogicStyle.Ca}>
                            <p className={LogicStyle.Ca_P}>
                                Repeat: Use this hex block, if you need to
                                repeat the entire program indefinitely forever.
                                <br />
                                <br />
                                Stop: use this hex block, if you need the
                                program to run only one time.
                            </p>
                        </div>
                    </div>

                    {/* <div className="wdt">
            <img className="img" src={"assets/w2.png"} className="img" />
          </div> */}
                </Slider>
            </div>
        )
    }
}

export default LogicPrgm
