import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowRight,
    faFileDownload,
    faTrash,
} from '@fortawesome/free-solid-svg-icons'

import MainHeader from '../../ReusableComponents/Header/MainHeader'
import renderPrgImage from '../../../source/programImg'
import style from './ShowAction.module.css'

function ShowAction() {
    const history = useHistory()

    const actionGroup = JSON.parse(
        sessionStorage.getItem('standbyActionGroupToLoad')
    )

    const handleLoadActionGroup = () => {
        sessionStorage.setItem(
            'createActionState',
            JSON.stringify(actionGroup.createActionState)
        )
        // sessionStorage.removeItem('standbyActionGroupToLoad')
        history.push('/code/project/action/select')
    }

    const handleDeleteActionGroup = () => {
        if (!window.confirm('Are you Sure ?')) return

        const allActionGroups = JSON.parse(
            localStorage.getItem('customActionGroupData')
        )

        delete allActionGroups[actionGroup.name]

        localStorage.setItem(
            'customActionGroupData',
            JSON.stringify(allActionGroups)
        )

        history.push('/code/project/actions')
    }

    const downloadAG = () => {
        // let content = { name: 'Edwin' }
        let a = document.createElement('a')

        // let content = JSON.stringify(actionGroup)
        let content = JSON.stringify(actionGroup)
            .split('')
            .map((c) => c.charCodeAt(0))
            .join(' ')
        let file = new Blob([content], {
            type: 'application/json',
        })
        a.href = URL.createObjectURL(file)
        a.download = `${actionGroup.name}.ag`
        a.click()
    }

    return (
        <div>
            <MainHeader
                title="Action Group"
                goBack={() => history.push('/code/project/actions')}
            />
            <div className={style.leftCol}>
                <div className={style.inputCover}>
                    <span className={style.caption}>Name</span>
                    <input
                        className={style.input}
                        value={actionGroup.name}
                        disabled
                    />
                </div>

                <div className={style.inputCover}>
                    <span className={style.caption}>Description</span>
                    <textarea
                        className={style.textarea}
                        value={actionGroup.description}
                        disabled
                    />
                </div>
            </div>

            <div className={style.rightCol}>
                <div className={style.btnGrp}>
                    <button className={style.iconBtn} onClick={downloadAG}>
                        <FontAwesomeIcon
                            icon={faFileDownload}
                            color="white"
                            style={{ scale: '1.5' }}
                        />
                    </button>

                    <button
                        className={style.iconBtn}
                        onClick={handleDeleteActionGroup}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            color="white"
                            style={{ scale: '1.5' }}
                        />
                    </button>

                    <button
                        className={style.iconBtn}
                        onClick={handleLoadActionGroup}
                    >
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            color="white"
                            style={{ scale: '1.5' }}
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ShowAction
