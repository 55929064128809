import { React, useEffect } from 'react'
import { webSerialConnect } from '../../../redux/actions/index'
import './ExternalAccessories.css'

import { connect } from 'react-redux'

import CenterMode from '../../concept/index'

import { useState } from 'react'
import EXternalScPrgm from '../../ReusableComponents/PrgmSlider/ExternalPrgm/ExternalScPrgm'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import renderPrgImage from '../../../source/programImg'

function ExternalAccessoriesScreen(props) {
    console.log('props history', props)

    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    return (
        <div className="ExternalAccessories-Main">
            <div>
                <div>
                    {sessionStorage.getItem('connectedDevice') === 'Ace' ? (
                        <ProgramHeader
                            data={{
                                options: [
                                    'Select',
                                    'Assemble',
                                    'Code',
                                    'Simulate',
                                ],
                                selected: 0,
                            }}
                            showSave={true}
                            showHelp={true}
                            handleHelp={handleHelpBtn}
                        />
                    ) : (
                        <ProgramHeader
                            data={{
                                options: ['Select', 'Assemble', 'Code', ''],
                                selected: 0,
                            }}
                            showSave={true}
                            showHelp={true}
                            handleHelp={handleHelpBtn}
                        />
                    )}
                </div>
            </div>

            <div style={{ width: '100%', height: '90%' }}>
                <CenterMode history={props.history} />
            </div>

            {isHelp && (
                <div
                    className="Ss_slide"
                    style={{ height: '100vh', width: '105vw' }}
                >
                    {' '}
                    <EXternalScPrgm />{' '}
                    <img
                        className="helpClo"
                        src={renderPrgImage('closBtn')}
                        onClick={handleHelpBtn}
                    ></img>
                </div>
            )}

            {/* <CenterMode history={props.history} /> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(ExternalAccessoriesScreen)
