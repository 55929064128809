import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import MainHeader from '../../../Components/ReusableComponents/Header/MainHeader'
import renderImage from '../../../source/importImg'
import RemSlider from '../../../Components/ReusableComponents/RemSlider/RemSlider'
import JoyStick from '../PlayMode/JoyStick/JoyStick'
import RoboticArmPlay from './RoboticArm/RoboticArmPlay'
import Remote from '../../../Components/play/Remote/Ace/RemoteSection/RemoteSection'
import '../PlayMode/JoyStick/JoyStick.css'
import cacheAssets from '../../../utils/cacheAssets'

function Play(props) {
    const [toggle, setToggle] = useState(false)
    const [pc, setPC] = useState(true)
    const [arm, setArm] = useState(false)
    const version = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')

    const writePort = async (data) => {
        if (props.webSerial.port !== undefined) {
            // if (
            //     version?.startsWith('1') ||
            //     ['Roboki'].includes(connectedDevice)
            // ) {
            if (
                props.webSerial.isConnected &&
                props.webSerial.port !== undefined
            ) {
                try {
                    props.worker.postMessage({
                        type: 'writeArray',
                        value: data,
                    })
                    // const bytes = new Uint8Array(data)
                    // await props.webSerial.port.transferOut(2, bytes)
                } catch (e) {}
            }
            // } else {
            //     await sendBytes(data, props.webSerial.port)
            // }
        }
    }

    useEffect(() => {
        if (toggle) {
            const KLAW = [
                'K'.charCodeAt(),
                'L'.charCodeAt(),
                'A'.charCodeAt(),
                'W'.charCodeAt(),
            ]
            const resetBytesRoboki = [
                'R'.charCodeAt(),
                'L'.charCodeAt(),
                0,
                0,
                0,

                'R'.charCodeAt(),
                0,
                0,
                0,
            ]
            if (['Roboki'].includes(connectedDevice)) {
                writePort(resetBytesRoboki)
            } else {
                writePort(KLAW)
            }
        }
    }, [toggle])

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderImage('remoteH'),
            renderImage('remoteH1'),
            renderImage('remoteH2'),
            renderImage('Remt1'),
            renderImage('Remt2'),
            renderImage('Remt3'),
            renderImage('Remt4'),
            renderImage('Remt5'),
            renderImage('Remt6'),
            renderImage('PlayH1'),
            renderImage('PlayH2'),
            renderImage('PlayH3'),
            renderImage('PlayH4'),
            renderImage('PlayH5'),
            renderImage('PlayH6'),
            renderImage('PlayH7'),
            renderImage('PlayH8'),
            renderImage('PlayH9'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <div>
            <div className="Main_Remote">
                <MainHeader
                    title="Joystick"
                    helper={<RemSlider />}
                    showBluetoothBtn={true}
                />
                {toggle ? (
                    <RoboticArmPlay />
                ) : !version?.startsWith('1') &&
                  !['Roboki'].includes(connectedDevice) ? (
                    <Remote />
                ) : (
                    <JoyStick />
                )}

                {((connectedDevice == 'Ace' && version.startsWith('0.1')) ||
                    ['Roboki'].includes(connectedDevice)) && (
                    <div
                        style={{
                            display: 'grid',
                            alignSelf: 'center',
                            justifySelf: 'end',
                            zIndex: 100,
                            position: 'absolute',
                        }}
                    >
                        {' '}
                        <img src={renderImage('sideToggle_bg')} />
                        <img
                            src={renderImage(
                                pc ? 'PC_ButtonActive' : 'PC_ButtonInActive'
                            )}
                            style={{
                                position: 'absolute',
                                alignSelf: 'center',
                                justifySelf: 'center',
                                marginTop: pc ? '-25%' : '-40%',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setToggle(!toggle)
                                setPC(!pc)
                                setArm(!arm)
                            }}
                        />
                        <img
                            src={renderImage(
                                arm ? 'ArmButtonActive' : 'ArmButtonInActive'
                            )}
                            style={{
                                position: 'absolute',
                                alignSelf: 'center',
                                justifySelf: 'center',
                                marginTop: '40%',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setToggle(!toggle)
                                setPC(!pc)
                                setArm(!arm)
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(Play)
