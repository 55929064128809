import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import io from 'socket.io-client'
import Hammer from 'react-hammerjs'
import { HTML5Backend } from 'react-dnd-html5-backend-old'
import { DragDropContext } from 'react-dnd-old'
import { webSerialConnect } from '../../redux/actions/index'
import unicodeToChar from '../../utils/unicodeToChar'
// import DragDropContext from 'react-dnd-old';
// import TouchBackend from 'react-dnd-old-touch-backend';
import getBytes from '../Simulate/BytesGeneration/convertBytes'
import { connect } from 'react-redux'
import HexBoard from './HexBoard'
import BottomPanel from './BottomPanel'
// import RightPanel from './RightPanel'
import SizesHelper from '../../helpers/Sizes'
import Sizes from './Sizes'
import ProgramToDrawing from './ProgramToDrawing'
import '../../css/logic.css'
import Modal from 'react-modal'
import '../../css/logic.css'
import renderPrgImage from '../../source/programImg'
import LogicPrgm from '../ReusableComponents/PrgmSlider/LogicPrgm/LogicPrgm'
import * as atatus from 'atatus-spa'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import { sendBytes } from '../ReusableComponents/ByteTransfer'
import { readBytes } from '../ReusableComponents/ByteTransfer/byteTransfer'
import ProgramHeader from '../ReusableComponents/Header/ProgramHeader'
import Footer from '../ReusableComponents/FooterComponent'
import { continousPABytesReadToggle } from '../ReusableComponents/ByteTransfer'

var _ = require('lodash')
var countLogic
var oldDeltaX, oldDeltaY, panning
var idss = []
var IDIS = ''
var clientX, clientY
var drawing,
    drawingNew,
    defaultScale = 1
var panning = false,
    zooming = false,
    dontTriggerClick = false
var finalOffset = { left: 0, top: 0 }
let newArr = []
let num = 0

const defaultLogic = {
    program: [
        {
            type: 'start',
            state: {
                bic1: false,
                bic2: false,
                bic3: false,
                bid1: false,
                bid2: false,
                bif1: false,
                bif2: false,
                bif3: false,
                bidata1: false,
                bidata2: false,
                asgn1: false,
                asgn2: false,
                asgn3: false,
                asgn4: false,
                asgn5: false,
            },
            bic1: false,
            bic2: false,
            bic3: false,
            bid2: false,
            bif1: false,
            bif2: false,
            bif3: false,
            bid3: false,
            bid1: false,
            bmp3: false,
            bidata1: false,
            bidata2: false,
            asgn1: false,
            asgn2: false,
            asgn3: false,
            asgn4: false,
            asgn5: false,
        },
    ],
    end: { type: 'end', state: 'repeat' },
    insertState: false,

    offset: { left: 0, top: 0 },
    scale: 1,
    currentProgramGuide: 0,
    active: [-1, -1],
    bottomPanel: 'border',
    CUSTOMGROUPACTIONS12: {},
}

// CHNAGE by: SOUMITYA

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     height: "28%",
//     width: " 30%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "#9ecee8",
//     border: "2px solid #188dcc",
//     zIndex: 1,
//   },
// };

const saveToLocalStorage = () => {
    let programMode = sessionStorage.programMode
    let connectedDevice = sessionStorage.connectedDevice
    let version = sessionStorage.deviceVersion

    let updatelocalStorage = JSON.parse(localStorage.getItem('recoveryData'))
    if (programMode in updatelocalStorage) {
        if (connectedDevice in updatelocalStorage[programMode]) {
            updatelocalStorage[programMode][connectedDevice][version] =
                sessionStorage
            localStorage.setItem(
                'recoveryData',
                JSON.stringify(updatelocalStorage)
            )
        } else {
            updatelocalStorage[programMode][connectedDevice] = {
                [version]: sessionStorage,
            }
            localStorage.setItem(
                'recoveryData',
                JSON.stringify(updatelocalStorage)
            )
        }
    } else {
        updatelocalStorage[programMode] = {
            [connectedDevice]: {
                [version]: sessionStorage,
            },
        }
        localStorage.setItem('recoveryData', JSON.stringify(updatelocalStorage))
    }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '23%',
        width: ' 25%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // backgroundColor: "#9ecee8",
        // border: "2px solid #188dcc",

        border: '5px solid rgb(255,140,25)',
        borderRadius: '20px',

        overflow: 'initial',
        // zIndex: 1,
    },
}

const customStylesUpload = {
    content: {
        top: '50%',
        left: '50%',
        height: ' 30%',
        width: ' 30%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        zIndex: 1000000,
        borderRadius: '15px',
        transform: 'translate(-50%, -50%)',
        border: '5px solid orange',
    },
}

let newId = 0

class Logic extends Component {
    constructor(props) {
        super(props)
        var { logic } = this.props

        var tutorialLogic = sessionStorage.getItem('tutorialLogic')

        var tutorialDescArray = []
        var selectionType = localStorage.getItem('programMode')

        if (selectionType == 'learn') {
            var tutorialDesc = JSON.parse(
                sessionStorage.getItem('tutorialLogic')
            )
            // tutorialDescArray.push(JSON.parse(tutorialDesc).logic1)
            // tutorialDescArray.push(JSON.parse(tutorialDesc).logic2)
        }

        drawing = ProgramToDrawing(
            undefined,
            logic.program,
            logic.end,
            logic.currentProgramGuide,
            logic.active,
            this.add,
            logic.insertState,
            this.insertNode,
            this.insertBtwnNode,
            this.deleteNode
        )
        var curLogicScreen
        curLogicScreen = 'hexa'
        // this.click = this.click.bind(this)
        // this.add = this.add.bind(this)

        this.state = {
            hexType: '',
            uploadOpen: false,
            tutorialDesc: tutorialDesc,
            tutorialLogic: JSON.parse(tutorialLogic),
            modalIsOpen: false,
            clearProgramModal: false,
            usbOpen: false,
            isusb: false,
            isHelp: false,
            currentLogicScreen: curLogicScreen,
            currentNode: {},
            visible: false,
            currentNodeIndex: 0,
            nodeCount: 1,
            detected: false,
            readyForSimulation: '',
            checkEndProgram: false,
            excitDataLimitPopup: false,
            clickedBottom: false,
            isConnected: this.props.isConnected,
            uploadSucess: false,
            slideDirection: sessionStorage.getItem('slideDirection'),
        }
        window.addEventListener('load', async (e) => {
            console.log('HEY_CALIIN', this.props.state)
            navigator.serial.addEventListener('connect', (e) => {
                var user = 1
                sessionStorage.setItem('user', JSON.stringify(user))
                this.handleUsb()
                if (window.location.pathname === '/deviceSelection') {
                    sessionStorage.setItem('autoCunnection', 'true')
                }
                // window.location.reload(false)
            })

            navigator.serial.addEventListener('disconnect', async (e) => {
                var user = 0
                sessionStorage.setItem('user', JSON.stringify(user))
                this.handleUsb()
                const p_Port = this.props.webSerial.port
                try {
                    await p_Port.close()
                } catch (e) {}
            })
            try {
                const portList = await navigator.serial.getPorts()

                if (portList.length === 1) {
                    console.log(portList, 'Hardware connected')
                    let port1 = portList[0]
                    await props.webSerialConnect({ port: port1 }) // dispatching function of redux
                    //debugger;
                    this.setState.p1({
                        selected: true,
                        port: portList[0],
                    })
                } else {
                    console.log('No hardware')

                    this.setState.p1(this.state.p1)
                }
            } catch (err) {
                console.log(err.message)
            }
        })
    }

    componentWillMount = () => {
        sessionStorage.setItem('isReadingZingPA', false)
        atatus.beginTransaction('Logic Screen')
        const { program, end, insertState, currentProgramGuide, active } =
            this.props.logic

        drawing = ProgramToDrawing(
            undefined,
            program,
            end,
            currentProgramGuide,
            active,
            this.add,
            insertState,
            this.insertNode,
            this.insertBtwnNode,
            this.deleteNode
        )
    }
    componentWillUnmount() {
        atatus.endTransaction('Logic Screen')
    }
    componentWillUpdate(nextProps, nextState) {
        const { program, end, insertState, currentProgramGuide, active } =
            this.props.logic

        drawing = ProgramToDrawing(
            undefined,
            program,
            end,
            currentProgramGuide,
            active,
            this.add,
            insertState,
            this.insertNode,
            this.insertBtwnNode,
            this.deleteNode
        )
    }
    OpenReadComPort = async () => {
        const port = this.props.webSerial.port
        console.log('PORTLIST', port)
        // console.log(port, "pPort");
        try {
            // await port.close();
            await port.open({ baudRate: 120000 })
        } catch (e) {
            console.log(e)
        }
        // this.writePort("notWrite");
        // let valresponceTp0 = "",
        //   valdis = "";
        // // setTimeout(async () => {
        // if (this.state.readbytes) {
        // this.readdata();
        // }

        // }, 1000);
    }

    handleUsb = (e) => {
        this.setState({ isusb: !this.state.isusb })
    }
    helpBtn = (e) => {
        this.setState({ isHelp: !this.state.isHelp })
    }
    clearProgramTrue = () => {
        this.setState({ clearProgramModal: true })
    }
    clearProgramfalse = () => {
        this.setState({ clearProgramModal: false })
    }
    clearProgram = async () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        sessionStorage.removeItem('logic')
        sessionStorage.removeItem('programEnd')
        // window.location.reload(false)

        this.props.update(JSON.parse(JSON.stringify(defaultLogic)))
        await new Promise((reslove) => setTimeout(reslove, 0))

        sessionStorage.removeItem('EndSwitch')
        ///////// Humanoid Program Data ///////////
        sessionStorage.removeItem('humanoidProgramData')
        sessionStorage.removeItem('PET')

        this.setState({ clearProgramModal: false })
    }
    componentDidMount = async () => {
        // var socket = io.connect("http://localhost:3008");
        // socket.emit("_usbDetection", "Hi");
        // socket.on("/usbDetection1", (data) => {
        //   // console.log("...............1", data);
        //   // // let kill = Array.from(data);
        //   // // console.log("...............5", kill);
        //   // if (data == 1) {
        //   //   this.handleUsb(true);
        //   //   console.log("LLLLLLLLLLLLLLL", data);
        //   // } else {
        //   //   this.handleUsb(false);
        //   // }
        // });
        // await new Promise((resolve) =>
        //     setTimeout(() => {
        //         this.setState({
        //             showUpload: true,
        //         })
        //         resolve()
        //     }, 2000)
        // )
        let data = JSON.parse(sessionStorage.getItem('user'))

        if (data === 1) {
            this.setState({
                isusb: true,
            })
            console.log('Logic AD !!')
            // this.setState({ isusb: true });
        }
        if (data === 0) {
            this.setState({
                isusb: false,
            })
            console.log('Logic AD')
            // this.setState({ isusb: false });
        }
        // socket.on("/usbDetection", (data) => {
        //   console.log("DATAE:----", data);
        //   this.setState({ detected: data.detected, usbOpen: !data.detected });
        // });
        // this.setState({ hexType: "wait" })
        if (sessionStorage.getItem('programEnd') != null) {
            this.setState({ readyForSimulation: 'repeat' })
        }
    }

    constraintOffset = (offset) => {
        var el = document.getElementById('logicOffsetTransformer', this.props)
        const BBox = el.getBBox()
        const { height, width } = this.props.assembly
        const scale = this.props.logic.scale * defaultScale
        if (offset.left * scale > width / 2) offset.left = width / (2 * scale)
        else if (offset.left < -BBox.width + width / (2 * scale))
            offset.left = -BBox.width + width / (2 * scale)
        if (offset.top * scale > height / 2) offset.top = height / (2 * scale)
        else if (offset.top < -BBox.height + height / (2 * scale))
            offset.top = -BBox.height + height / (2 * scale)
    }

    /**
     * Pan event handler : does not update state for smooth panning. Also throttles the event
     */
    pan = (e) => {
        if (panning) return
        panning = true
        var el = document.getElementById('logicOffsetTransformer')
        var BBox = el.getBBox()
        const { offset } = this.props.logic

        const { height, width } = this.props.assembly

        finalOffset.left = offset.left + e.deltaX
        finalOffset.top = offset.top + e.deltaY
        if (finalOffset.left > width / 2) finalOffset.left = width / 2
        else if (finalOffset.left < -BBox.width + width / 2)
            finalOffset.left = -BBox.width + width / 2
        if (finalOffset.top > height / 2) finalOffset.top = height / 2
        else if (finalOffset.top < -BBox.height + height / 2)
            finalOffset.top = -BBox.height + height / 2
        el.setAttribute(
            'transform',
            'translate(' + finalOffset.left + ',' + finalOffset.top + ')'
        )
        panning = false
    }

    /**
     * Set dontTriggerClick variable to true
     */
    panStart = () => {
        dontTriggerClick = true
    }
    /**
     * Update state variables
     */
    panEnd = (e) => {
        var { logic } = this.props
        logic.offset.left = finalOffset.left
        logic.offset.top = finalOffset.top
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic, () => (panning = false))
    }
    panEndNew = () => {
        panning = false
        oldDeltaX = 0
        oldDeltaY = 0
    }

    zoom = (scale, clientX, clientY) => {
        var { height, width } = this.props.assembly
        var { logic } = this.props
        var clientY = height
        if (zooming) return
        var { height, width } = this.props.assembly
        var { logic } = this.props
        var clientY = height

        if ((logic.scale < 0.5 && scale < 1) || (logic.scale > 2 && scale > 1))
            return
        // zooming = true;
        var clientY = height
        // client = (offset + x/y) * scale
        // client of oldx/y = client of newx/y (current mouse position)
        // oldx/y = client / scale - offset
        // (newoffset + oldx/y) * newscale = (oldOffset + oldx/y) * oldscale
        const oldX = clientX / (logic.scale * defaultScale) - logic.offset.left

        const oldY = clientY / (logic.scale * defaultScale) - logic.offset.top
        logic.offset.left = (logic.offset.left + oldX) / scale - oldX
        logic.offset.top = (logic.offset.top + oldY) / scale - oldY

        logic.scale *= scale

        this.constraintOffset(logic.offset)
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic, () => {
            zooming = false
        })
    }

    /**
     * Pinch Out event handler
     */
    pinchOut = (e) => {
        this.zoom(6 / 5, e.center.x, e.center.y)
        e.preventDefault()
        return false
    }

    /**
     * Pinch In event handler
     */
    pinchIn = (e) => {
        this.zoom(5 / 6, e.center.x, e.center.y)
        e.preventDefault()
        return false
    }
    pinchOutNew = (e) => {
        this.zoomNew(12 / 11, e.center.x, e.center.y)
        e.preventDefault()
        return false
    }
    /**
     * Pinch In event handler
     */
    pinchInNew = (e) => {
        this.zoomNew(11 / 12, e.center.x, e.center.y)
        e.preventDefault()
        return false
    }
    /**
     * Reset pinch variables
     */
    pinchEnd = () => {
        zooming = false
    }
    /**
     * Wheel event handler
     */
    wheel = (e) => {
        // e.preventDefault();
        const { clientX, clientY, deltaY } = e
        if (deltaY > 0) {
            this.zoom(5 / 6, clientX, clientY)
        } else {
            this.zoom(6 / 5, clientX, clientY)
        }
        return false
    }
    wheelNew = (e) => {
        const { clientX, clientY, deltaY } = e
        if (deltaY > 0) this.zoomNew(5 / 6, clientX, clientY)
        else this.zoomNew(6 / 5, clientX, clientY)
        // e.preventDefault();
        return false
    }

    click = (row, col) => {
        // alert("CLICK");
        // alert(window.event.clientX)
        // IDIS = `${1}${col}`;
        // IDIS = window.event.clientX*window.event.clientY;

        if (dontTriggerClick) {
            dontTriggerClick = false
            return
        }
        var { logic } = this.props

        const { currentProgramGuide } = logic

        var todo = 'current'

        logic.insertState = false

        const { type } = drawing.board[row][col]

        if (type === 'blank' || type === 'hand' || type === 'highlighted_hand')
            todo = 'blank'

        if (todo === 'current') {
            logic.active = [row, col]

            drawing = ProgramToDrawing(
                undefined,
                logic.program,
                logic.end,
                logic.currentProgramGuide,
                logic.active,
                this.add,
                logic.insertState,
                this.insertNode,
                this.insertBtwnNode,
                this.deleteNode,
                this.click
            )

            var logicState = {}
            this.props.update(logic)
        } else if (todo === 'blank') {
            logic.active = [-1, -1]

            // Uncomment the following line to auto-minimize bottomPanel on blank space click
            // logic.bottomPanel = 'border';
            drawing = ProgramToDrawing(
                undefined,
                logic.program,
                logic.end,
                logic.currentProgramGuide,
                logic.active,
                this.add,
                logic.insertState,
                this.insertNode,
                this.insertBtwnNode,
                this.deleteNode
            )

            var logicState = {}
            logicState['type'] = this.state.currentLogicScreen
            logicState['state'] = logic

            this.props.update(logic)
        }
    }
    recurseAdd = (instructions, nesting, toPush) => {
        // alert("RECURSE_ADD");

        if (instructions.length !== 0) {
            if (nesting === 0) {
                instructions.push(toPush)
            } else {
                this.recurseAdd(
                    instructions[instructions.length - 1].subprogram,
                    nesting - 1,
                    toPush
                )
            }
        }
    }
    add = (type) => {
        // alert("ADD()");
        console.log('checking add', type)
        if (type == 'repeat' || type == 'end') {
            this.setState({ readyForSimulation: type })
            sessionStorage.setItem('programEnd', type)
        }

        if (dontTriggerClick) {
            dontTriggerClick = false
            return
        }

        var { logic } = this.props

        logic.active = [-1, -1]

        // console.log("logic.program new", logic.program, type)

        if (
            type === 'end_variable' ||
            type === 'end_sensor' ||
            type === 'end_condition' ||
            type === 'end_if' ||
            type === 'end_loop'
            // ||
            // type === "repeat"
        )
            logic.currentProgramGuide--
        else {
            var toPush = { type: type, state: {} }

            if (
                type === 'variable' ||
                type === 'sensor' ||
                type === 'condition' ||
                type === 'loop'
            )
                toPush.subprogram = []
            this.recurseAdd(logic.program, logic.currentProgramGuide, toPush)
            if (
                type === 'variable' ||
                type === 'sensor' ||
                type === 'condition' ||
                type === 'loop'
            )
                logic.currentProgramGuide++
        }

        drawing = ProgramToDrawing(
            type,
            logic.program,
            logic.end,
            logic.currentProgramGuide,
            logic.active,
            this.add
        )
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)

        saveToLocalStorage()
    }

    /////////////// HUMANOID FUNCTION /////////////
    addUniqueId = (program, type) => {
        if (
            ['Ace', 'Roboki'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        )
            return

        let newNodeId = parseInt(sessionStorage.getItem('nodeCounter')) || 0
        newNodeId += 1
        sessionStorage.setItem('nodeCounter', newNodeId)

        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData') || ''
        )
        const humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )

        let stateData = {}
        Object.entries(humanoidPortData).map((port) => {
            stateData[port[0]] = {
                name: humanoidPortData[port[0]].name,
                value: humanoidPortData[port[0]].value,
                isActionEnabled: humanoidPortData[port[0]].isActionEnabled,
                isLiveBtnActive: false,
            }
            // if([''].includes(port[0]))
        })

        let node = {
            id: newNodeId,
            type: type,
            state: stateData,
        }
        if (type == 'condition') node.state = { port: '', value: 0, value1: 0 }

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify({ ...humanoidProgramData, [node.id]: node })
        )

        this.findAndAttachNewId(program, newNodeId)
    }

    /////////////// HUMANOID FUNCTION /////////////
    findAndAttachNewId = (program, newNodeId) => {
        for (let node = 0; node < program.length; node++) {
            // newNodeId is attached to that node which doesn't have an id yet.
            // At any point, only the newly created node will not have an id and that's how we find the newNode.
            // This nodeId is also the key for each object in *humanoidProgramData*.
            if (
                program[node].nodeId === undefined &&
                (program[node].type === 'hardware' ||
                    program[node].type === 'condition' ||
                    program[node].type === 'sensor')
            ) {
                program[node].nodeId = newNodeId
                break
            }
            if (
                program[node].type === 'condition' ||
                program[node].type === 'sensor' ||
                program[node].type === 'loop'
            ) {
                this.findAndAttachNewId(program[node].subprogram, newNodeId)
            }
        }
    }

    insertNode = (type) => {
        // for(let n = 0; n<drawing.activeParentRef.slice(drawing.activeIndex, drawing.activeParentRef.length).length; n++){
        //   drawing.activeParentRef[n].id= JSON.stringify(Number(drawing.activeParentRef[n].id) + 1)
        // }
        num++

        var { logic } = this.props

        // if (!logic.insertState) {
        //   logic.insertState = true;
        // } //removed by gautam to change to single click
        if (type == null) {
            logic.insertState = true
        } else {
            // this condition is for the  render the bolck wait click it action and then wait hadrware panke will open
            if (type != 'action') {
                logic.insertState = false
                var temp = drawing.activeParentRef[drawing.activeIndex]

                // HERE I AM STOPING TO ADD THE ACTION on HEXBOARD

                var toPush = { type: type, state: {}, id: IDIS }
                if (
                    type === 'end_variable' ||
                    type === 'end_sensor' ||
                    type === 'end_condition' ||
                    type === 'end_if' ||
                    type === 'end_loop'
                    // ||
                    // type === "repeat"
                ) {
                    logic.currentProgramGuide--
                }
                if (
                    type === 'variable' ||
                    type === 'condition' ||
                    type === 'sensor' ||
                    type === 'loop' ||
                    type === 'if'
                ) {
                    logic.currentProgramGuide++
                    toPush.subprogram = []
                }
                drawing.activeParentRef[drawing.activeIndex] = toPush

                for (
                    let i = drawing.activeIndex + 1;
                    i < drawing.activeParentRef.length;
                    i++
                ) {
                    var temp2 = drawing.activeParentRef[i]
                    drawing.activeParentRef[i] = temp
                    temp = temp2
                }
                if (temp) drawing.activeParentRef.push(temp)
                logic.active = [-1, -1]

                logic.program.map((i, id) => {
                    i.id = `${1}${id}`
                })

                /////////////////////////////////////////////
                ///////////// Humanoid code /////////////////
                if (type === 'hardware' || type === 'condition') {
                    this.addUniqueId(logic.program, type)
                }
                /////////// Humanoid code ends //////////////
                /////////////////////////////////////////////
            }
        }

        drawing = ProgramToDrawing(
            type,
            logic.program,
            logic.end,
            logic.currentProgramGuide,
            logic.active,
            this.add,
            logic.insertState,
            this.insertNode,
            this.insertBtwnNode,
            this.deleteNode
        )
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
        saveToLocalStorage()
    }

    insertBtwnNode = (type) => {
        // for(let n = 0; n<drawing.activeParentRef.slice(drawing.activeIndex, drawing.activeParentRef.length).length; n++){
        //   drawing.activeParentRef[n].id= JSON.stringify(Number(drawing.activeParentRef[n].id) + 1)
        // }
        num++

        var { logic } = this.props
        console.log('checkingvalues', type)
        // if (!logic.insertState) {
        //   logic.insertState = true;
        // } //removed by gautam to change to single click
        if (type == null) {
            logic.insertState = true
        } else {
            // this condition is for the  render the bolck wait click it action and then wait hadrware panke will open
            if (type != 'action') {
                logic.insertState = false
                var temp = drawing.activeParentRef[drawing.activeIndex]

                // HERE I AM STOPING TO ADD THE ACTION on HEXBOARD

                var toPush = { type: type, state: {}, id: IDIS, newId: num }

                if (
                    type === 'variable' ||
                    type === 'condition' ||
                    type === 'sensor' ||
                    type === 'loop' ||
                    type === 'if'
                ) {
                    // logic.currentProgramGuide++;
                    toPush.subprogram = []
                }
                drawing.activeParentRef[drawing.activeIndex] = toPush

                for (
                    let i = drawing.activeIndex + 1;
                    i < drawing.activeParentRef.length;
                    i++
                ) {
                    var temp2 = drawing.activeParentRef[i]
                    drawing.activeParentRef[i] = temp
                    temp = temp2
                }
                if (temp) drawing.activeParentRef.push(temp)
                logic.active = [-1, -1]

                logic.program.map((i, id) => {
                    i.id = `${1}${id}`
                })

                /////////// Humanoid code ////////////////////
                if (type === 'hardware' || type === 'condition') {
                    this.addUniqueId(logic.program, type)
                }
            }
        }

        drawing = ProgramToDrawing(
            type,
            logic.program,
            logic.end,
            logic.currentProgramGuide,
            logic.active,
            this.add,
            logic.insertState,
            this.insertNode,
            this.insertBtwnNode,
            this.deleteNode
        )
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
        saveToLocalStorage()
    }

    deleteNode = () => {
        var { logic } = this.props

        ////////////////// PLAYC-71 ////////////////////
        /// Active Hand lost after removing loop ///////

        if (
            drawing.activeRef.type == 'repeat' ||
            drawing.activeRef.type == 'end'
        ) {
            sessionStorage.removeItem('PET')
            sessionStorage.removeItem('EndSwitch')
        }

        /////////////////////////////////////////////////

        if (
            drawing.activeParentRef.length - 1 == drawing.activeIndex &&
            (drawing.activeRef.type === 'variable' ||
                drawing.activeRef.type === 'sensor' ||
                drawing.activeRef.type === 'condition' ||
                drawing.activeRef.type === 'loop')
        ) {
            let currentProgramGuide = 0,
                temp = logic.program
            while (
                temp != drawing.activeParentRef &&
                currentProgramGuide < logic.currentProgramGuide
            ) {
                temp = temp[temp.length - 1].subprogram
                currentProgramGuide++
            }
            if (currentProgramGuide < logic.currentProgramGuide)
                logic.currentProgramGuide = currentProgramGuide
        }

        if (drawing.activeRef.type === 'end') {
            logic.currentProgramGuide = 0
        }
        // alert(drawing.activeIndex)
        drawing.activeParentRef.splice(drawing.activeIndex, 1)
        logic.active = [-1, -1]

        drawing = ProgramToDrawing(
            undefined,
            logic.program,
            logic.end,
            logic.currentProgramGuide,
            logic.active,
            this.add,
            logic.insertState,
            this.insertNode,
            this.insertBtwnNode,
            this.deleteNode
        )

        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
        saveToLocalStorage()
    }

    toggleBottomPanel = (e, nodeId) => {
        //if click on live buttn that time you can not close the panel, because the zing and hexapod v1,
        //sending the setting bytes, after 2.3 sec again we send the bytes, other waise it does not worke
        if (this.props.concept?.disableAfterSettingByt) {
            return
        }

        if (sessionStorage.getItem('isReadingZingPA') == 'true') {
            sessionStorage.setItem('isReadingZingPA', false)
            continousPABytesReadToggle(
                null,
                null,
                this.props.webSerial.port,
                null,
                'OFF'
            )
        }

        var { logic, logicEditor, update } = this.props
        var endchek = true
        console.log('endchek', e, nodeId)
        try {
            if (e.includes('end_')) {
                endchek = false
            }
        } catch (error) {}

        if (endchek) {
            if (logic.bottomPanel === 'show') {
                logic.bottomPanel = 'border'
                // console.log('VVVVVVVVVVVV1111', drawing.activeRef.type)
                //disabled all live button when close the action panel
                if (drawing.activeRef.type === 'hardware') {
                    let newState = drawing.activeRef.state

                    //send the PLAY bytes to stop the all live  output, if live output enable
                    if (
                        Object.keys(newState).some((objKey) => {
                            if (objKey.includes('liveBtn')) {
                                return newState[objKey]
                            } else return false
                        })
                    ) {
                        //here
                        setTimeout(() => {
                            this.props.worker.postMessage({
                                type: 'writeArray',
                                value: [
                                    'S'.charCodeAt(0),
                                    'T'.charCodeAt(0),
                                    'O'.charCodeAt(0),
                                    'P'.charCodeAt(0),
                                ],
                            })
                        }, 100)
                    }

                    //for zing klaw hexapod desabled the liveBtn
                    if (
                        ['Klaw', 'Humanoid', 'Hexapod'].includes(
                            sessionStorage.getItem('connectedDevice')
                        )
                    ) {
                        let humanoidProgramData = {}
                        let humanoidProgramDataId = drawing.activeRef.nodeId
                        try {
                            humanoidProgramData = JSON.parse(
                                sessionStorage.getItem('humanoidProgramData')
                            )
                        } catch (e) {
                            console.log('ERROR', e)
                        }
                        let humanoidProgramDataState =
                            humanoidProgramData[humanoidProgramDataId].state

                        Object.keys(humanoidProgramDataState).map((objKey) => {
                            if (
                                humanoidProgramDataState[objKey]
                                    ?.isLiveBtnActive
                            ) {
                                humanoidProgramDataState[
                                    objKey
                                ].isLiveBtnActive = false
                            }
                        })
                        sessionStorage.setItem(
                            'humanoidProgramData',
                            JSON.stringify(humanoidProgramData)
                        )

                        //--------------------------------------------
                        const updateLogicProgram = (program) => {
                            for (let node = 0; node < program.length; node++) {
                                if (
                                    program[node].nodeId ===
                                    drawing.activeRef.nodeId
                                ) {
                                    // program[node].state = newState
                                    Object.keys(program[node].state).map(
                                        (objKey) => {
                                            if (objKey.includes('liveBtn')) {
                                                program[node].state[
                                                    objKey
                                                ] = false
                                            }
                                        }
                                    )
                                    break
                                }
                                if (
                                    program[node].type === 'condition' ||
                                    program[node].type === 'sensor' ||
                                    program[node].type === 'loop'
                                ) {
                                    updateLogicProgram(program[node].subprogram)
                                }
                            }
                        }
                        console.log(
                            'BBBBBBBBB',
                            JSON.parse(JSON.stringify(logic.program))
                        )
                        updateLogicProgram(logic.program)
                        ///-------------
                    } else {
                        Object.keys(newState).map((objKey) => {
                            if (objKey.includes('liveBtn')) {
                                newState[objKey] = false
                            }
                        })
                    }

                    // drawing.activeRef.state = newState
                }
            } else logic.bottomPanel = 'show'
        }

        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        var logicToBeChanged
        // if (this.state.currentLogicScreen == "hexa") {
        console.log('VVVVVVVV1111', logic)
        logicToBeChanged = logic
        // } else if (this.state.currentLogicScreen == "flow") {
        //   logicToBeChanged = logicNew;
        // }
        // else if (this.state.currentLogicScreen == "python") {
        //   logicToBeChanged = pythonEditor;
        // } else {
        //   logicToBeChanged = logicEditor;
        // }

        logicState['state'] = logicToBeChanged

        this.props.update(logic)
        console.log('VVVVVVVV', logic)
    }
    /* 
    function to recursively delete start keys which are false and are 
    present in other nodes of the program.
  */
    bottomPanelDelete = (key) => {
        var { logic } = this.props
        var search = key
        function recursiveDeleteFromObject(search, OBJ) {
            for (var key in OBJ) {
                if (!(key == search) && typeof OBJ[key] === 'object') {
                    recursiveDeleteFromObject(search, OBJ[key])
                } else {
                    if (key == search) {
                        delete OBJ[key]
                    }
                }
            }
            return OBJ
        }
        function recursiveDeleteFromArrayObject(search, Arr_OBJ) {
            for (var key in Arr_OBJ) {
                Arr_OBJ[key] = recursiveDeleteFromObject(search, Arr_OBJ[key])
            }
            return Arr_OBJ
        }
        var result = recursiveDeleteFromArrayObject(search, logic.program)
        logic.program = result
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
        saveToLocalStorage()
    }

    bottomPanelChange = (state, type) => {
        var { logic } = this.props

        if (type) {
            drawing.activeRef.type = type
        }
        // if (state) {
        drawing.activeRef.state = state
        if (state == 'end') {
            drawing.activeRef.type = 'end'
            sessionStorage.setItem('EndSwitch', 'end')
        }
        if (state == 'repeat') {
            drawing.activeRef.type = 'repeat'
            sessionStorage.removeItem('EndSwitch')
        }
        // }

        if (drawing.activeRef.type === 'end')
            console.log('bottomPanelChange end')

        drawing = ProgramToDrawing(
            undefined,
            logic.program,
            logic.end,
            logic.currentProgramGuide,
            logic.active,
            this.add
        )
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
        saveToLocalStorage()
    }

    handleBottomPanelClick = () => {
        var { logic } = this.props
        if (logic.bottomPanel === 'show') {
            logic.bottomPanel = 'border'
            dontTriggerClick = true
        }
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
    }

    seperateClicked = () => {
        // $(document).ready(function =()=>{
        //   $(".hide").click(function =()=>{
        //     $("p").hide();
        //   });
        //   $(".show").click(function =()=>{
        //     $("p").show();
        //   });
        // });
    }

    clickmeUp = () => {
        var { logic } = this.props
        if (logic.bottomPanel != 'show') logic.bottomPanel = 'show'
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
    }
    clickmeDown = () => {
        var { logic } = this.props
        if (logic.bottomPanel === 'show') logic.bottomPanel = 'border'
        var logicState = {}
        logicState['type'] = this.state.currentLogicScreen
        logicState['state'] = logic

        this.props.update(logic)
    }

    setCurrentNode(current, index) {
        this.setState({ currentNode: current, currentNodeIndex: index })
    }

    showSetting = () => {
        document.getElementById('divH').style.display = 'inline'
    }
    closeSetting = () => {
        document.getElementById('divH').style.display = 'none'
        document.getElementById('divH1').style.display = 'none'
    }
    showConverter = () => {
        document.getElementById('divH1').style.display = 'block'
    }

    Save = () => {
        // console.log("SAVE==>");
    }

    close = () => {
        this.setState({ modalIsOpen: false })
    }

    closeUpload = () => {
        this.setState({ uploadOpen: false })
    }
    closeUsb = () => {
        this.setState({ usbOpen: false })
    }
    closeUploadSucess = () => {
        this.setState({ uploadSucess: false })
    }
    check = () => {
        var program = JSON.parse(sessionStorage.getItem('logic')).program
        var end = JSON.parse(sessionStorage.getItem('logic')).end
        var upldbtn = document.getElementById('uploadLogicscrn')
        upldbtn.style.pointerEvents = 'none'
        upldbtn.style.cursor = 'not-allowed'

        this.setState({ uploadOpen: true })
        setTimeout(() => {
            upldbtn.style.cursor = 'pointer'

            upldbtn.style.pointerEvents = 'all'

            this.setState({ uploadOpen: false })
        }, 5000)

        //var socket = socketIOClient("http://localhost:3008");
        if (localStorage.getItem('programMode') == 'learn') {
            // socket.emit("/checkLogic", program, end, "Final");
            // socket.on("/logicResult", (data) => {
            //   if (!data) {
            //     this.setState({ modalIsOpen: true });
            //     return true;
            //   } else {
            //     var params = {
            //       screen: "hexa",
            //       logic: this.props.logic,
            //       components: this.props.assembly.PortConnections,
            //     };
            //     var Peripherial = JSON.parse(localStorage.getItem("Bluetooth"));
            //     // console.log("this.props.history DONE LOGIC", Peripherial[0]["mac"])
            //     if (Peripherial) {
            //       socket.emit("/upload", { code: params }, Peripherial[0]["mac"]);
            //     } else {
            //       socket.emit("/upload", { code: params });
            //     }
            //     socket.on("_upload", (data) => {
            //       // this.setState({ modalIsOpen: true })
            //     });
            //     return false;
            //     // this.props.history.push("/Learn")
            //   }
            // });
        } else {
            var params = {
                screen: 'hexa',
                logic: this.props.logic,
                components: this.props.assembly.PortConnections,
            }
            var Peripherial = JSON.parse(localStorage.getItem('Bluetooth'))
            // console.log("this.props.history DONE LOGIC", Peripherial[0]["mac"])

            // if (Peripherial) {
            //   socket.emit("/upload", { code: params }, Peripherial[0]["mac"]);
            // } else {
            //   socket.emit("/upload", { code: params });
            // }

            // socket.on("_upload", (data) => {
            //   // this.setState({ modalIsOpen: true })
            // });
            return false
            // this.props.history.push("/Learn")
        }
    }
    tutor = () => {
        this.props.history.push('/saveTutorials')
    }
    hexTypeCheck = (value) => {
        this.setState({ hexType: value })
    }
    if = (value) => {
        return (
            <div>
                Click on the hand hex and click on action hex ,double click the
                action hex and select {value.type}{' '}
                {
                    ((
                        <div>
                            {' '}
                            Set{' '}
                            {Object.entries(value.state).map(([key, value]) => {
                                return (
                                    <span>
                                        {' '}
                                        {key} {value}
                                    </span>
                                )
                            })}
                        </div>
                    ),
                    (
                        <div>
                            {' '}
                            Down to {value.type}{' '}
                            {value.subprogram
                                ? value.subprogram.map((value, index) => {
                                      return value.type == 'hardware' ||
                                          value.type == 'variable' ||
                                          value.type == 'wait'
                                          ? this.output(value)
                                          : value.type == 'sensor' ||
                                            value.type == 'variable'
                                          ? this.if(value)
                                          : ''
                                      // return <div>{index}{value.type}</div>
                                  })
                                : ''}
                        </div>
                    ))
                }
            </div>
        )
    }
    output = (value) => {
        var hardware = []
        var keyValueArray = []
        var keyTypeArray = []
        var keyValue
        var keyType
        if (value.type == 'hardware') {
            hardware.push('Click on the hand icon and select Action ')
            hardware.push('Double Click and select hardware ')
            Object.entries(value.state).map(([key, value]) => {
                // return (<span> {key} {value}</span>)
                // var key1 = key[
                // var value1 = key["value"]
                if (key.includes('assign')) {
                    keyType = key.split('gn')
                    keyTypeArray.push(keyType[1])
                }

                if (key.includes('value')) {
                    keyValue = key.split('ue')
                }

                for (let i = 0; i < keyTypeArray.length; i++) {
                    if (keyValue) {
                        if (keyValue[1] == keyTypeArray[i]) {
                            keyValueArray.push([keyTypeArray[i] + ':' + value])
                        }
                    }
                }

                hardware.push('set ' + key + ' to ' + value)
            })
        }

        return (
            <div>
                Click on the hand hex and click on action hex ,double click the
                action hex and select {value.type}{' '}
                {
                    <div>
                        {' '}
                        Set{' '}
                        {Object.entries(value.state).map(([key, value]) => {
                            return (
                                <span>
                                    {' '}
                                    {key} {value}
                                </span>
                            )
                        })}
                    </div>
                }
            </div>
        )
    }

    checkForSimulation = () => {
        // let {bottomPanel} = this.props.logic
        // if(bottomPanel){
        //   return;
        // }
        if (sessionStorage.getItem('PET') == null) {
            this.setState({ checkEndProgram: true })
            return
        }
        localStorage.setItem('recoveryData', JSON.stringify(sessionStorage))
        if (this.state.readyForSimulation === 'repeat') {
            this.props.history.push('/simulate')
            window.location.reload(false)
        } else {
            this.setState({ checkEndProgram: true })
        }
    }
    closecheckEndProgram = () => {
        this.setState({ checkEndProgram: false })
    }
    closeExcitDataLimitPopup = () => {
        this.setState({ excitDataLimitPopup: false })
    }

    // ITS =(e) => {
    //   alert(e)
    // }
    async writePort(data) {
        try {
            const filters = [{ usbVendorId: 0x1a86, usbProductId: 0x7523 }]
            const ports = await navigator.serial.getPorts({ filters })
            console.log('portsss', ports)

            console.log('portsss', ports[0].writable, this.props.webSerial.port)
            // const outputStream = ports[0].writable,
            if (this.props.webSerial.port !== undefined) {
                const writer = this.props.webSerial.port.writable.getWriter()
                // writer = outputStream.getWriter();
                const sata = data
                const data1 = new Uint8Array(sata) // hello// 82, 76, 0, 0, 0, 82, 0, 0, 0, 66, 0, 0, 1, 0, 1,
                console.log('send data:+', data1)

                await writer.write(data1)

                writer.releaseLock()
            }
        } catch (e) {
            console.log(e)
        }
    }
    bytesSending = async (data) => {
        // if (
        //     (sessionStorage.getItem('connectedDevice') == 'Ace' &&
        //         sessionStorage.getItem('deviceVersion')?.includes('1.0.0')) ||
        //     sessionStorage.getItem('deviceVersion').includes('2.0.0')
        // ) {
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        console.log('data is write', data, this.props.worker)
        if (['Roboki'].includes(connectedDevice)) {
            if (data.length > 1020) {
                this.setState({ uploadOpen: false })
                this.setState({ excitDataLimitPopup: true })
                return
            }
        }

        this.props.worker.postMessage({
            type: 'writeArray',
            value: data,
        })
        this.props.worker.onmessage = async (e) => {
            if (e.data.type === 'read' && e.data.value.includes('OK')) {
                console.log('recieved', e.data.value)
                // if (['Roboki'].includes(connectedDevice)) {
                //     await new Promise((reslove) => setTimeout(reslove, 600))
                // }
                this.props.worker.postMessage({
                    type: 'write',
                    value: 'REST',
                })
                this.setState({ uploadSucess: true })
                this.setState({ uploadOpen: false })
                setTimeout(() => this.setState({ uploadSucess: false }), 2500)
            }
        }
        // } else {
        //     if (
        //         sessionStorage.getItem('connectedDevice') === 'Humanoid' &&
        //         !['1', '2'].includes(
        //             (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
        //         )
        //     ) {
        //         await sendBytes(
        //             ['E'.charCodeAt(0), 'N'.charCodeAt(0), 'D'.charCodeAt(0)],
        //             this.props.webSerial.port
        //         )
        //         let replyOK = await readBytes(this.props.webSerial.port, 'OK')
        //         if (replyOK === true) {
        //             // debugger;
        //             await sendBytes(data, this.props.webSerial.port)
        //             let replyOK = await readBytes(
        //                 this.props.webSerial.port,
        //                 'OK'
        //             )
        //             if (replyOK === true) {
        //                 await sendBytes(
        //                     [
        //                         'R'.charCodeAt(0),
        //                         'E'.charCodeAt(0),
        //                         'S'.charCodeAt(0),
        //                         'T'.charCodeAt(0),
        //                     ],
        //                     this.props.webSerial.port
        //                 )
        //                 this.setState({ uploadSucess: true })
        //                 this.setState({ uploadOpen: false })
        //                 setTimeout(
        //                     () => this.setState({ uploadSucess: false }),
        //                     2500
        //                 )
        //             }
        //         } else if (replyOK == 'PAREST') {
        //             // debugger;
        //             setTimeout(async () => {
        //                 await this.bytesSending(data)
        //             }, 2000)
        //         } else {
        //             this.setState({ uploadSucess: false })
        //         }
        //     } else {
        //         //debugger;+
        //         // laxmi

        //         // debugger;
        //         await sendBytes(data, this.props.webSerial.port)
        //         let replyOK = await readBytes(this.props.webSerial.port, 'OK')
        //         if (replyOK === true) {
        //             await sendBytes(
        //                 [
        //                     'R'.charCodeAt(0),
        //                     'E'.charCodeAt(0),
        //                     'S'.charCodeAt(0),
        //                     'T'.charCodeAt(0),
        //                 ],
        //                 this.props.webSerial.port
        //             )
        //             this.setState({ uploadSucess: true })
        //             this.setState({ uploadOpen: false })
        //             setTimeout(
        //                 () => this.setState({ uploadSucess: false }),
        //                 2500
        //             )
        //         }
        //     }
        // }
    }
    uploadProgram = async () => {
        //for clearing the chunks
        this.props.worker.postMessage({
            type: 'clearChunks',
        })
        sessionStorage.setItem('isPlay', false)
        var params = {
            screen: 'hexa',
            logic: this.props.logic,
            components: this.props.assembly.PortConnections,
            internalaccessories: JSON.parse(sessionStorage.getItem('concept'))
                .internalaccessories,
        }
        if (sessionStorage.getItem('PET') == null) {
            this.setState({ checkEndProgram: true })
            return
        }
        console.log(this.props.logic)
        this.setState({ uploadOpen: true })
        if (this.state.checkEndProgram) return
        if (!this.props.webSerial.isConnected) return
        //debugger;
        var upldbtn = document.getElementById('uploadLogicscrn')
        upldbtn.style.pointerEvents = 'none'
        upldbtn.style.cursor = 'none'
        await getBytes({ code: params })
        let bytes = sessionStorage.getItem('convert_Bytes')
        var data = bytes.split(',')
        console.log(data, 'KAMAL SIMULATE')
        //this.myRef.current.upload();

        // await this.writePort(data);
        // this.readForSucess();

        // Sending Bytes and Receiving for 'OK' acknowledgement
        // const writer = this.props.webSerial.port.writable.getWriter();
        // await writer.releaseLock();
        // const reader = await this.props.webSerial.port.readable.getReader();
        // await reader.releaseLock()

        /** if (
            !(
                sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
                ['Ace'].includes(sessionStorage.getItem('connectedDevice'))
            ) &&
            !['Humanoid', 'Roboki', 'Hexapod'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        ) {
            console.log(this.props.webSerial.port.readable.locked, 'gsk prot')
            try {
                await this.props.webSerial.port.readable.cancel()
            } catch (e) {
                console.log(e)
            }
        }
**/
        await this.bytesSending(data)
        setTimeout(() => {
            upldbtn.style.cursor = 'pointer'
            upldbtn.style.pointerEvents = 'all'
        }, 2500)
    }
    //older firmware upload with ack
    // uploadProgram = () => {
    //   var params = {
    //     screen: "hexa",
    //     logic: this.props.logic,
    //     components: this.props.assembly.PortConnections,
    //     internalaccessories: JSON.parse(sessionStorage.getItem("concept"))
    //       .internalaccessories,
    //   };
    //   this.setState({ uploadOpen: true });
    //   var upldbtn = document.getElementById("uploadLogicscrn");
    //   upldbtn.style.pointerEvents = "none";
    //   upldbtn.style.cursor = "none";
    //   getBytes({ code: params });
    //   let bytes = sessionStorage.getItem("convert_Bytes");
    //   var data = bytes.split(",");
    //   console.log(data, "KAMAL SIMULATE");
    //   //this.myRef.current.upload();
    //   // this.writePort(data);
    //   sendBytes(data, this.props.webSerial.port);
    //   setTimeout(() => {
    //     upldbtn.style.cursor = "pointer";

    //     upldbtn.style.pointerEvents = "all";
    //     this.setState({ uploadOpen: false });
    //   }, 3000);

    //   // console.log("UPLOAD DATA", this.myRef.current.upload()); //it will call anyFun which is available at simulateLogic.js
    // };

    render = () => {
        console.log('Logic CONDITION PROPS', this.props)
        const { program, scale, offset, currentProgramGuide, active } =
            this.props.logic
        let microPython
        try {
            microPython = JSON.parse(sessionStorage.getItem('microPython'))
        } catch (e) {
            microPython = false
        }

        var tempData
        countLogic = 0
        //description
        if (localStorage.getItem('programMode') == 'learn') {
            this.state.tutorialDesc.map((value, index) => {
                // (value.type == "hardware" || value.type == "variable" || value.type == "wait") ? this.output(value) : (value.type == "sensor" || value.type == "variable") ? this.if(value) : ""
                // return <div>{index}{value.type}</div>
                if (countLogic == 0) {
                    if (program[index]) {
                        if (program[index].type != 'start') {
                            if (program[index].type == value.type) {
                                // console.log("program check khushboo", value, program[index].state, countLogic, JSON.stringify(program[index].state) == JSON.stringify(value.state))
                                // _.isEqual(program[index].state, value.state);
                                if (
                                    _.isEqual(program[index].state, value.state)
                                ) {
                                    if (value.subprogram !== undefined) {
                                        value.subprogram.map(
                                            (value1, indexSub) => {
                                                if (
                                                    program[index].subprogram[
                                                        indexSub
                                                    ] &&
                                                    value.subprogram[indexSub]
                                                ) {
                                                    if (
                                                        program[index]
                                                            .subprogram[
                                                            indexSub
                                                        ].type ==
                                                        value.subprogram[
                                                            indexSub
                                                        ].type
                                                    ) {
                                                        if (
                                                            _.isEqual(
                                                                program[index]
                                                                    .subprogram[
                                                                    indexSub
                                                                ].state,
                                                                value
                                                                    .subprogram[
                                                                    indexSub
                                                                ].state
                                                            )
                                                        ) {
                                                            // countLogic++
                                                            // tempData = (value.type == "hardware" || value.type == "variable" || value.type == "wait") ? this.output(value, "state") : (value.type == "sensor" || value.type == "variable") ? this.if(value) : ""
                                                        } else {
                                                            countLogic++
                                                            tempData =
                                                                value
                                                                    .subprogram[
                                                                    indexSub
                                                                ].type ==
                                                                    'hardware' ||
                                                                value
                                                                    .subprogram[
                                                                    indexSub
                                                                ].type ==
                                                                    'variable' ||
                                                                value
                                                                    .subprogram[
                                                                    indexSub
                                                                ].type == 'wait'
                                                                    ? this.output(
                                                                          value
                                                                              .subprogram[
                                                                              indexSub
                                                                          ],
                                                                          'state'
                                                                      )
                                                                    : value.type ==
                                                                          'sensor' ||
                                                                      value.type ==
                                                                          'variable'
                                                                    ? this.if(
                                                                          value,
                                                                          'state'
                                                                      )
                                                                    : ''
                                                        }
                                                    } else {
                                                        countLogic++
                                                        tempData =
                                                            value.subprogram[
                                                                indexSub
                                                            ].type ==
                                                                'hardware' ||
                                                            value.subprogram[
                                                                indexSub
                                                            ].type ==
                                                                'variable' ||
                                                            value.subprogram[
                                                                indexSub
                                                            ].type == 'wait'
                                                                ? this.output(
                                                                      value
                                                                          .subprogram[
                                                                          indexSub
                                                                      ]
                                                                  )
                                                                : value.type ==
                                                                      'sensor' ||
                                                                  value.type ==
                                                                      'variable'
                                                                ? this.if(
                                                                      value,
                                                                      'subprogram'
                                                                  )
                                                                : ''
                                                    }
                                                } else {
                                                    countLogic++
                                                    tempData =
                                                        value.subprogram[
                                                            indexSub
                                                        ].type == 'hardware' ||
                                                        value.subprogram[
                                                            indexSub
                                                        ].type == 'variable' ||
                                                        value.subprogram[
                                                            indexSub
                                                        ].type == 'wait'
                                                            ? this.output(
                                                                  value
                                                                      .subprogram[
                                                                      indexSub
                                                                  ]
                                                              )
                                                            : value.type ==
                                                                  'sensor' ||
                                                              value.type ==
                                                                  'variable'
                                                            ? this.if(
                                                                  value,
                                                                  'subprogram'
                                                              )
                                                            : ''
                                                }
                                            }
                                        )
                                    }
                                    // countLogic++
                                    // tempData = (value.type == "hardware" || value.type == "variable" || value.type == "wait") ? this.output(value, "state") : (value.type == "sensor" || value.type == "variable") ? this.if(value) : ""
                                } else {
                                    countLogic++
                                    tempData =
                                        value.type == 'hardware' ||
                                        value.type == 'variable' ||
                                        value.type == 'wait'
                                            ? this.output(value, 'state')
                                            : value.type == 'sensor' ||
                                              value.type == 'variable'
                                            ? this.if(value, 'state')
                                            : ''
                                }
                            } else {
                                countLogic++
                                tempData =
                                    value.type == 'hardware' ||
                                    value.type == 'variable' ||
                                    value.type == 'wait'
                                        ? this.output(value)
                                        : value.type == 'sensor' ||
                                          value.type == 'variable'
                                        ? this.if(value)
                                        : ''
                            }
                        }
                    } else {
                        countLogic++

                        tempData =
                            value.type == 'hardware' ||
                            value.type == 'variable' ||
                            value.type == 'wait'
                                ? this.output(value)
                                : value.type == 'sensor' ||
                                  value.type == 'variable'
                                ? this.if(value)
                                : ''
                    }
                }
                // tempData = <div>hye</div>
            })
        }
        // As according to app approx 11.5 hexagons in a row in the display

        defaultScale = SizesHelper.width / (18.5 * Sizes.xdiff)
        var { bottomPanel } = this.props.logic

        const { height, width } = this.props.assembly

        var value = {}
        var current = ''
        var App = this.props.app
        var LogicFlowButtons
        var logicEditorScreenType = this.state.currentLogicScreen
        var { components } = this.props.assembly.workspace
        var { PortConnections } = this.props.assembly

        // Object.keys(PortConnections).map(port => PortConnections[port] = null);
        Object.keys(components).map((type) => {
            components[type].map((component, index) => {
                if (type == 'dc_motor' && component.connectedTo == 'A2') {
                    PortConnections[component.connectedTo] = { type, index }
                    index = index + 1
                    PortConnections['A1'] = { type, index }
                }
                if (type == 'dc_motor' && component.connectedTo == 'A3') {
                    PortConnections[component.connectedTo] = { type, index }
                    index = index + 1
                    PortConnections['A4'] = { type, index }
                }
                if (type == 'dc_motor' && component.connectedTo == 'F1') {
                    PortConnections[component.connectedTo] = { type, index }
                    index = index + 1
                    PortConnections['F2'] = { type, index }
                }
                if (type == 'dc_motor' && component.connectedTo == 'G1') {
                    PortConnections[component.connectedTo] = { type, index }
                    index = index + 1
                    PortConnections['G2'] = { type, index }
                } else {
                    PortConnections[component.connectedTo] = { type, index }
                }
            })
        })

        if (active) {
            if (active[0] !== -1)
                current = drawing.board[active[0]][active[1]].type
        }

        //console.log("curdraw", row);
        for (var row in drawing.board) {
            for (var i in drawing.board[row]) {
                //console.log("curdraw", drawing.board[row][i]);
                var block = drawing.board[row][i].type
                if (block == 'active_hand') {
                    i++
                    block = drawing.board[row][i].type
                    console.log('active end', block)
                    if (block.startsWith('end')) {
                        // console.log("active final", drawing.board[row][i]);
                        //drawing.board[row][i] = { type: "blank" };
                    }
                }
            }
        }
        // console.log("curdraw", drawing, active);
        if (
            current === 'blank' ||
            current === 'active_hand' ||
            current === '' ||
            current === 'hand' ||
            current === 'highlighted_hand' ||
            current == 'action'
        )
            bottomPanel = 'none'

        if (drawing && drawing.activeRef.state) {
            value = drawing.activeRef.state
        }
        if (!drawing.activeRef.state) {
            drawing.activeRef.state = {}
        }
        if (this.state.detected == true) {
            var imageURL = 'images/Learn/ble_connection.png'
        } else {
            imageURL = 'images/Learn/ble_disconnection.png'
        }
        newId = newArr.push(Math.floor(Math.random() * 1000000000000))

        console.log('DRAWING REF', drawing.activeRef)
        const { slideDirection } = this.state

        return (
            <>
                <DialogModal
                    show={this.state.excitDataLimitPopup}
                    text="Your current program exceed the size limit."
                    showCloseBtn={true}
                    handleDialogModalClose={this.closeExcitDataLimitPopup}
                />
                <DialogModal
                    show={this.state.checkEndProgram}
                    text="Please end the program with Loop or Stop."
                    showCloseBtn={true}
                    handleDialogModalClose={this.closecheckEndProgram}
                />
                <DialogModal
                    show={this.state.uploadOpen}
                    text={
                        this.props.webSerial.isConnected
                            ? 'Uploading Program'
                            : 'Connect your device to upload program.'
                    }
                    showCloseBtn={true}
                    handleDialogModalClose={this.closeUpload}
                />
                <DialogModal
                    show={this.state.uploadSucess}
                    text={'Uploading Successful'}
                    showCloseBtn={true}
                    handleDialogModalClose={this.closeUploadSucess}
                />
                <DialogModal
                    show={this.state.clearProgramModal}
                    text="Are you sure you want to clear this program"
                    optionsToSelect={[
                        { text: 'YES', do: this.clearProgram },
                        { text: 'NO', do: this.clearProgramfalse },
                    ]}
                />
                <DialogModal
                    show={this.state.modalIsOpen}
                    text="Device not connected!"
                    showCloseBtn={true}
                    handleDialogModalClose={this.close}
                    optionsToSelect={[
                        {
                            text: 'Reconnect',
                            do: () => {
                                this.props.history.push('/deviceSelection')
                            },
                        },
                    ]}
                />

                <div
                    style={{
                        // backgroundColor: "rgb(166, 209, 225)",
                        background: '#FCFCFC',
                        height: '100%',
                        width: '100vw',
                        overflow: 'hidden',
                        userSelect: 'none',
                    }}
                >
                    {/* MENU */}
                    <div style={{ height: '10%' }}>
                        <div>
                            <div>
                                <ProgramHeader
                                    data={{
                                        options: [
                                            'Select',
                                            'Assemble',
                                            'Code',
                                            'Simulate',
                                        ],
                                        selected: 2,
                                    }}
                                    showSave={true}
                                    showHelp={true}
                                    handleHelp={this.helpBtn}
                                    showClear={true}
                                    handleClear={this.clearProgramTrue}
                                />
                            </div>
                        </div>

                        <div className="CSD">
                            {/* DISPLAY : NONE */}
                            <div
                                id="divH"
                                onMouseOver={this.showSetting}
                                onMouseOut={this.closeSetting}
                                style={{
                                    position: 'absolute',
                                    top: '10vh',
                                    left: '-2vw',
                                    height: '70%',
                                    width: '70%',
                                    display: 'none',
                                }}
                            >
                                <div>
                                    {/* <div style={{ margin: "5px 0px 0px 140px" }}> */}

                                    <div
                                        style={{
                                            height: '60px',
                                            width: '300px',
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <h3
                                            style={{
                                                color: '#3234a8',
                                                width: '230px',
                                                textAlign: 'end',
                                            }}
                                        >
                                            Remote
                                        </h3>
                                        <img
                                            src="images/Learn/learn_button_remote.png"
                                            style={{
                                                height: '100%',
                                                width: '70px',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/* <div style={{ margin: "5px 0px 0px 93px" }}> */}

                                    <div
                                        style={{
                                            height: '60px',
                                            width: '300px',
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <h3
                                            style={{
                                                color: '#3234a8',
                                                width: '230px',
                                                textAlign: 'end',
                                            }}
                                        >
                                            Music Player
                                        </h3>
                                        <img
                                            src="images/Learn/learn_button_music.png"
                                            style={{
                                                height: '100%',
                                                width: '70px',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/* <div style={{ margin: "5px 0px 0px 54px" }}> */}

                                    <div
                                        style={{
                                            height: '60px',
                                            width: '300px',
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <h3
                                            style={{
                                                color: '#3234a8',
                                                width: '230px',
                                                textAlign: 'end',
                                            }}
                                        >
                                            Image Processing
                                        </h3>
                                        <img
                                            src="images/Learn/learn_button_image.png"
                                            style={{
                                                height: '100%',
                                                width: '70px',
                                            }}
                                            alt="adfasfdsgr"
                                        />
                                    </div>
                                </div>
                                {/* <div style={{ margin: "5px 0px 0px 155px" }}> */}
                                <div>
                                    <div
                                        style={{
                                            height: '60px',
                                            width: '300px',
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <h3
                                            style={{
                                                color: '#3234a8',
                                                width: '230px',
                                                textAlign: 'end',
                                            }}
                                        >
                                            Plotter
                                        </h3>{' '}
                                        <img
                                            src="images/Learn/learn_button_plotter.png"
                                            style={{
                                                height: '100%',
                                                width: '70px',
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div style={{ margin: "5px 0px 0px 150px" }}> */}
                                <div>
                                    <div
                                        style={{
                                            height: '60px',
                                            width: '300px',
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <h3
                                            style={{
                                                width: '230px',
                                                textAlign: 'end',
                                                color: '#3234a8',
                                            }}
                                        >
                                            Speech
                                        </h3>
                                        <img
                                            src="images/Learn/learn_button_speech.png"
                                            style={{
                                                height: '100%',
                                                width: '70px',
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div style={{ margin: "5px 0px 0px 155px" }}> */}
                                <div>
                                    <div
                                        style={{
                                            height: '60px',
                                            width: '300px',
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <h3
                                            style={{
                                                width: '230px',
                                                textAlign: 'end',
                                                color: '#3234a8',
                                            }}
                                        >
                                            Update
                                        </h3>
                                        <img
                                            src="images/Learn/learn_button_update.png"
                                            style={{
                                                height: '100%',
                                                width: '70px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* DISPLAY : NONE */}
                            <div
                                id="divH1"
                                onMouseOver={this.showConverter}
                                onMouseOut={this.closeSetting}
                                style={{
                                    marginTop: '17%',
                                    marginLeft: '0%',
                                    height: '22%',
                                    width: '40%',
                                    display: 'none',
                                }}
                            >
                                <div
                                    style={{
                                        height: '24%',
                                        marginLeft: '-77%',
                                    }}
                                >
                                    <h3
                                        style={{
                                            float: 'left',
                                            color: '#3234a8',
                                        }}
                                    >
                                        Convert to C
                                    </h3>
                                    <div
                                        style={{
                                            height: '60px',
                                            width: '60px',
                                            float: 'left',
                                        }}
                                    >
                                        <img
                                            src="images/Learn/learn_button_remote.png"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: '25%',
                                        color: '#3234a8',
                                        marginLeft: '-113%',
                                    }}
                                >
                                    <h3 style={{ float: 'left' }}>
                                        Convert to Python
                                    </h3>
                                    <div
                                        style={{
                                            height: '60px',
                                            width: '60px',
                                            float: 'left',
                                        }}
                                    >
                                        <img
                                            src="images/Learn/learn_button_remote.png"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* HAX BLOCKS  */}

                    <div
                        className={`${
                            slideDirection === 'true'
                                ? 'slide-right'
                                : 'slide-left'
                        }`}
                        style={{ height: '80%' }}
                    >
                        {' '}
                        <div className="draw" style={{ height: '80%' }}>
                            {/* <Link to="/assembly">
            <div className="back_btn_logic">BACK</div>
          </Link> */}

                            {/* <div className="XXX">HELLO BUDDY</div> */}
                            <div onWheel={this.wheel}>
                                <Hammer
                                    onPan={this.pan}
                                    onPanStart={this.panStart}
                                    onPanEnd={this.panEnd}
                                    onPinchIn={this.pinchIn}
                                    onPinchOut={this.pinchOut}
                                    options={{
                                        recognizers: {
                                            pinch: { enable: true },
                                        },
                                    }}
                                >
                                    <div
                                        className="draw"
                                        style={{
                                            height: '1000px',
                                        }}
                                    >
                                        {/* <img
                  style={{
                    position: "absolute",
                    top: "4.8%",
                    rotate: "-90deg",
                    left: "36.5%",
                  }}
                  src={renderPrgImage("StartArrow")}
                ></img> */}
                                        <svg
                                            height="100%"
                                            width="100%"
                                            style={{
                                                backgroundColor: '#FCFCFC',
                                            }}
                                            className="noselect"
                                        >
                                            <g
                                                transform={
                                                    'scale(' +
                                                    defaultScale +
                                                    ')'
                                                }
                                            >
                                                <g
                                                    transform={
                                                        'scale(' + scale + ')'
                                                    }
                                                    id="logicScaleTransformer"
                                                >
                                                    <g
                                                        transform={
                                                            'translate(' +
                                                            offset.left +
                                                            ',' +
                                                            offset.top +
                                                            ')'
                                                        }
                                                        id="logicOffsetTransformer"
                                                    >
                                                        <HexBoard
                                                            hexType={
                                                                this.state
                                                                    .hexType
                                                            }
                                                            drawing={drawing}
                                                            onClick={this.click}
                                                            onDoubleClick={
                                                                this
                                                                    .toggleBottomPanel
                                                            }
                                                            // onClick={this.ITS}
                                                            id={IDIS}
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        {/* <div style={{ height: "10%", width: "65%", border: "2px solid #bed5fa", backgroundColor: "white", borderRadius: "20px", top: "80vh", left: "10%", position: 'inherit' }}>
                <h3 style={{ marginLeft: "20px", marginTop: "2px" }}>
                  {Object.entries(this.state.tutorialDesc).map(([key, value]) => {
                    console.log("logic Connections 2", key, value)
                    return < div> {parseInt(key) + 1}. {value}</div>
                  })}
                </h3>
              </div> */}

                                        {localStorage.getItem('programMode') ==
                                            'learn' && tempData ? (
                                            <div
                                                style={{
                                                    height: 'auto',
                                                    width: '65vw',
                                                    border: '2px solid #bed5fa',
                                                    backgroundColor: 'white',
                                                    borderRadius: '20px',
                                                    bottom: '3%',
                                                    left: '17%',
                                                    position: 'fixed',
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        marginLeft: '20px',
                                                        marginTop: '2px',
                                                    }}
                                                >
                                                    {/* {this.state.tutorialDesc[0].type} */}

                                                    {/* {Object.entries(this.state.tutorialDesc).map(([key, value]) => {
                    console.log("logic Connections 2", key, value)
                    return < div> {key}. {value}</div>
                  })} */}

                                                    {/* {this.state.tutorialDesc.map((value, index) => {
                    console.log("program check", program[index].type)
                    return (value.type == "hardware" || value.type == "variable" || value.type == "wait") ? this.output(value) : (value.type == "sensor" || value.type == "variable") ? this.if(value) : ""
                    // return <div>{index}{value.type}</div>
                  })} */}
                                                    {tempData}
                                                </h3>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </Hammer>
                            </div>

                            {/* OPEN THE PANEL THIE ACTIONS When you dubble click it will open  */}

                            <BottomPanel
                                componentProps={
                                    this.props.concept.componentProps
                                }
                                PortConnections={PortConnections}
                                value={value}
                                show={bottomPanel}
                                toggle={this.toggleBottomPanel}
                                current={current}
                                state={drawing.activeRef.state}
                                nodeId={drawing.activeRef.nodeId}
                                onChange={this.bottomPanelChange}
                                startState={program[0].state}
                                hexTypeCheck={this.hexTypeCheck}
                                bottomPanelDeleteKey={this.bottomPanelDelete}
                            />
                        </div>
                    </div>

                    {/* bottom */}
                    <div
                        style={{
                            height: '10%',
                            position: 'relative',
                            zIndex: '10',
                        }}
                    >
                        <Footer
                            isGoBack={false}
                            goBack={async () => {
                                saveToLocalStorage()
                                this.props.history.push('/assembly')
                                this.setState({ slideDirection: true })
                                sessionStorage.setItem('slideDirection', 'true')
                                //window.location.reload()
                            }}
                            isGoNext={
                                sessionStorage.getItem('connectedDevice') ===
                                    'Ace' &&
                                !sessionStorage
                                    .getItem('deviceVersion')
                                    ?.startsWith('1') &&
                                !['Roboki'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                )
                                    ? true
                                    : false
                            }
                            goNext={this.checkForSimulation}
                        />
                    </div>
                    {/* <div
                        className="SelectScreenBottom"
                        style={{
                            zIndex: '1000',
                            bottom: '11%',
                        }}
                    >
                        <div className="bottom-child">
                            <Link to="/assembly">
                                <img
                                    className="iconBtnSize imgBackBtn"
                                    src={renderPrgImage('backBtn')}
                                    style={{ top: '13.7vh' }}
                                    onClick={async () => {
                                        saveToLocalStorage()
                                    }}
                                />
                            </Link>
                            {sessionStorage.getItem('connectedDevice') ===
                                'Ace' &&
                            sessionStorage.getItem('deviceVersion') !== '1.0.03' ? (
                                <img
                                    className="iconBtnSize imgNextBtn"
                                    src={renderPrgImage('nextBtn')}
                                    style={{ top: '13.7vh' }}
                                    onClick={this.checkForSimulation}
                                />
                            ) : null}
                        </div>
                    </div> */}
                    {sessionStorage.getItem('connectedDevice') === 'Ace' &&
                    !sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
                    !['Roboki'].includes(
                        sessionStorage.getItem('connectedDevice')
                    ) ? (
                        <img
                            onClick={() => {
                                if (microPython) {
                                    return
                                }
                                saveToLocalStorage()
                                if (
                                    this.state.readyForSimulation !==
                                        'repeat' &&
                                    this.state.readyForSimulation !== 'end'
                                ) {
                                    this.setState({ checkEndProgram: true })
                                } else {
                                    this.uploadProgram()
                                }
                            }}
                            className="nextButton" //search for ".nextButton" in project directory to edit style
                            id="uploadLogicscrn"
                            src={renderPrgImage('uploadBtn')}
                            style={{
                                visibility: 'visible',
                                top: '78vh',
                                opacity: microPython ? '0.5' : '1',
                            }}
                        ></img>
                    ) : (
                        <img
                            onClick={() => {
                                if (microPython) {
                                    return
                                }
                                saveToLocalStorage()
                                if (
                                    this.state.readyForSimulation !==
                                        'repeat' &&
                                    this.state.readyForSimulation !== 'end'
                                ) {
                                    this.setState({ checkEndProgram: true })
                                } else {
                                    this.uploadProgram()
                                }
                            }}
                            className="nextButton" //search for ".nextButton" in project directory to edit style
                            id="uploadLogicscrn"
                            src={renderPrgImage('uploadBtn')}
                            style={{
                                visibility: 'visible',
                                top: '91%',
                                width: '60px',
                                height: '60px',
                                cursor: microPython ? 'default' : 'pointer',
                                opacity: microPython ? '0.5' : '1',
                            }}
                        ></img>
                    )}
                </div>

                {this.state.isHelp && (
                    <div className="Ss_slide" style={{ left: '50%' }}>
                        <LogicPrgm />
                        <img
                            className="helpClo"
                            src={renderPrgImage('closBtn')}
                            onClick={this.helpBtn}
                        ></img>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        PortConnections: (data) => {
            dispatch({ type: 'PORT_Connection', payload: data })
        },
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        setDisableAfterSettingByt: (data) => {
            dispatch({ type: 'DISABLE_AFTER_SETTING_BYTE', payload: data })
        },
    }
}
// Logic = DragDropContext(TouchBackend({ enableMouseEvents: true }))(Logic);
Logic = withRouter(DragDropContext(HTML5Backend)(Logic))
Logic = connect(mapStateToProps, mapDispatchToProps)(Logic)
export default Logic
