import React, { Component } from 'react'
import { isWindows } from 'react-device-detect'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../css/learn.css'
import { webSerialAction } from '../../redux/actions/index'
import renderCompImg from '../../source/Comp_Img'
import renderPrgImage from '../../source/programImg'
import { activeCheckBox } from '../Assembly/CheckboxData'
import componentProps from './componentProps' //Empty object
import Coverflow from './Coverflow.js'
import components from './data.js' //component details
import Sidebar from './Sidebar.js'
import removeSensorProgramData from '../../utils/removeSensorProgramData'
import Footer from '../ReusableComponents/FooterComponent'
import { upcastType } from '@tensorflow/tfjs'
import { generateDeviceDataKey } from '../ReusableComponents/ByteTransfer/utils'
import { throttle } from 'lodash'
var data
var nextButtonVisibilty = 'visible'
var pageReload = false

// STYLE CHANGE BY : SOUMITYA
// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     height: "28%",
//     width: " 30%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "#9ecee8",
//     border: "2px solid #188dcc",
//   },
// };

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '23%',
        width: ' 25%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // backgroundColor: "#9ecee8",
        // border: "2px solid #188dcc",

        border: '5px solid rgb(255,140,25)',
        borderRadius: '20px',

        overflow: 'initial',
        // zIndex: 1,
    },
}
const getUrl = {
    PeeCeeAlpha: '/selectScreen/InternalAccessories',
    PeeCeeBeta: '/v1/selectScreen/InternalAccessories',
    Hexapod: '/code/InternalAccessories',
    Humanoid: '/code/InternalAccessories',
    // Klaw: '/code/project',
    Klaw: '/code/InternalAccessories',
    Roboki: '/v1/selectScreen/InternalAccessories',
}
class Content extends Component {
    constructor(props) {
        var Device = []
        var Device = sessionStorage.getItem('tutorialConcept')
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        let version = sessionStorage.getItem('deviceVersion')
        const deviceName = generateDeviceDataKey(connectedDevice, version)
        // let deviceName = `${connectedDevice.toUpperCase()}${version.slice(
        //     0,
        //     3
        // )}`

        var selectionType = localStorage.getItem('programMode')
        if (selectionType == 'program') {
            data = components[deviceName]
        } else {
            data = components[deviceName].filter((value) => {
                if (Device.includes(value.name)) {
                    //commented code for restriction
                    return value
                }
            })
        }

        var tutorialDescArray = []
        // var selectionType = localStorage.getItem("programMode")
        var compData
        if (selectionType == 'learn') {
            tutorialDescArray = sessionStorage
                .getItem('tutorialConcept')
                .split(',')
            compData = data
            // var tutorialDesc = sessionStorage.getItem("tutorialDesc")
            // tutorialDescArray.push(JSON.parse(tutorialDesc).concept1)
            // tutorialDescArray.push(JSON.parse(tutorialDesc).concept2)
        } else {
            compData = components[deviceName]
        }

        super(props)

        this.state = {
            slideDirection: sessionStorage.getItem('slideDirection'),
            Alldata: data,
            sidebarContents: [],
            components: compData,
            redata: components[deviceName],
            coverflowActive: 0,
            workspace: {
                bibox: { top: 20, left: 80 },
                components: {
                    // Other components come here
                    // eg. "led": [{top: 20, left: 80, connectedTo: 'A1'}, ...], ...
                },
                offset: { top: 0, left: 0 },
                scale: 1,
            },
            modalIsOpen: false,
            detected: false,
            usbOpen: false,
            erasedProgram: false,
            abc: false,
            isAnimating: false,
            touchPostion: 0,
        }
        data.forEach(function (component) {
            componentProps[component.type] = component //set all these propaties to empty object(Imported from componentProps) type:"..."
            // component.url = require('../' + component.url);
        })
        this.props.ComponentProps(componentProps)
    }

    componentDidMount() {
        // var socket = socketIOClient.connect("http://localhost:3008");
        sessionStorage.setItem('Reload', false)
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        let version = sessionStorage.getItem('deviceVersion')
        const deviceName = generateDeviceDataKey(connectedDevice, version)
        // let deviceName = `${connectedDevice.toUpperCase()}${version.slice(
        //     0,
        //     3
        // )}`
        if (localStorage.getItem('programMode') == 'learn') {
            // this.setState({components: this.state.Alldata});
            this.state.components.forEach(function (component) {
                component.selected = false //set the selected proparties in component(imported from data.js)
                component.name = component.type.replace(/_/g, ' ') //removeing the _ from component type
                // component.url =
                //   "Bisoft_UI/Accessories/oldComponents/component_" +
                //   component.type +
                //   ".png";

                component.url = renderCompImg(component.type) //setting the image path

                componentProps[component.type] = component //set all these propaties to empty object(Imported from componentProps) type:"..."
                // component.url = require('../' + component.url);
            })

            this.setState({ redata: this.state.components })
        } else {
            // if(localStorage.getItem('biboxTypes')){
            components[deviceName].forEach(function (component) {
                component.selected = false //set the selected proparties in component(imported from data.js)
                component.name = component.type.replace(/_/g, ' ').toUpperCase() //removeing the _ from component type

                // component.url =
                //   "Bisoft_UI/Accessories/oldComponents/component_" +
                //   component.type +
                //   ".png";

                component.url = renderCompImg(component.type) //setting the image path

                componentProps[component.type] = component //set all these propaties to empty object(Imported from componentProps) type:"..."
                // component.url = require('../' + component.url);
            })
            // }
            this.setState({ redata: this.state.components })
        }

        if (JSON.parse(sessionStorage.getItem('AppDetails')) != null) {
            this.setState({
                redata: JSON.parse(sessionStorage.getItem('AppDetails')),
            })
        }
        if (sessionStorage.getItem('Index') != null) {
            this.setState({
                sidebarContents: JSON.parse(sessionStorage.getItem('Index')),
            })

            // let arr = []
            // let Index = JSON.parse(sessionStorage.getItem('Index'))
            // if (Index.length == 1 && Index[0] == 17) {
            //     let el = this.state.components[Index[0]]
            //     arr.push(el)
            //     // console.log(this.state.components))
            //     sessionStorage.setItem('SelectedComp', JSON.stringify(arr))
            // }

            var ret = JSON.parse(sessionStorage.getItem('Index'))
            var ttt = this.state.components
            for (let i = 0; i < ret.length; i++) {
                ttt[ret[i]].selected = true
            }
            this.setState({ redata: ttt })
        } else if (this.props.indexData.concept.Index.length > 0) {
            this.setState({
                sidebarContents: this.props.indexData.concept.Index,
            })

            var ret = this.props.indexData.concept.Index
            var ttt = this.state.components
            for (let i = 0; i < ret.length; i++) {
                ttt[ret[i]].selected = true
            }
        }
        window.addEventListener('keydown', (e) => {
            if (e.code == 'ArrowLeft') {
                if (this.state.coverflowActive <= 0) {
                    this.setState({
                        coverflowActive:
                            this.state.components.length -
                            this.state.sidebarContents.length -
                            1,
                        workspace: this.state.workspace,
                    })
                } else {
                    this.setState({
                        coverflowActive: this.state.coverflowActive - 1,
                        workspace: this.state.workspace,
                    })
                }
            } else if (e.code == 'ArrowRight') {
                if (
                    this.state.coverflowActive <
                    this.state.components.length -
                        this.state.sidebarContents.length -
                        1
                ) {
                    this.setState({
                        coverflowActive: this.state.coverflowActive + 1,
                        workspace: this.state.workspace,
                    })
                } else {
                    this.setState({
                        coverflowActive: 0,
                        workspace: this.state.workspace,
                    })
                }
            }
        })
        window.addEventListener('touchstart', (e) => {
            this.setState({ touchPostion: e.touches[0].clientX })
            sessionStorage.setItem('pageReloadConcept', false)
        })

        window.addEventListener(
            'touchmove',
            throttle((e) => {
                if (
                    e.target.id == 'cfscroll1' ||
                    e.target.id == 'cfscroll2' ||
                    e.target.parentNode.id == 'coverflowElement'
                ) {
                    let newCoverflowActive = this.state.coverflowActive
                    if (e.touches[0].clientX - this.state.touchPostion > 0) {
                        console.log('RIGHT')
                        if (this.state.coverflowActive <= 0) {
                            newCoverflowActive =
                                this.state.components.length -
                                this.state.sidebarContents.length -
                                1
                        } else {
                            newCoverflowActive = this.state.coverflowActive - 1
                        }
                    } else {
                        console.log('LEFT')
                        if (
                            this.state.coverflowActive <
                            this.state.components.length -
                                this.state.sidebarContents.length -
                                1
                        ) {
                            newCoverflowActive = this.state.coverflowActive + 1
                        } else {
                            newCoverflowActive = 0
                        }
                    }
                    this.setState({
                        coverflowActive: newCoverflowActive,
                        workspace: this.state.workspace,
                        touchPostion: e.touches[0].clientX,
                    })
                }
            }, 100) // Adjust the delay (in milliseconds) as needed
        )
        // when the concept screen reload/refresed it will set value as true/false this is for get the correct coverflow card on reload time

        window.onbeforeunload = function () {
            sessionStorage.setItem('pageReloadConcept', true)
        }
    }

    scrollgreyed = (e) => {
        sessionStorage.setItem('pageReloadConcept', false)

        if (e.deltaY < 0) {
            if (this.state.coverflowActive <= 0) {
                this.setState({
                    coverflowActive:
                        this.state.components.length -
                        this.state.sidebarContents.length -
                        1,
                    workspace: this.state.workspace,
                })
            } else {
                this.setState({
                    coverflowActive: this.state.coverflowActive - 1,
                    workspace: this.state.workspace,
                })
            }
        } else {
            if (
                this.state.coverflowActive <
                this.state.components.length -
                    this.state.sidebarContents.length -
                    1
            ) {
                this.setState({
                    coverflowActive: this.state.coverflowActive + 1,
                    workspace: this.state.workspace,
                })
            } else {
                this.setState({
                    coverflowActive: 0,
                    workspace: this.state.workspace,
                })
            }
        }
    }
    saveToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion

        let updatelocalStorage = JSON.parse(
            localStorage.getItem('recoveryData')
        )
        if (programMode in updatelocalStorage) {
            if (connectedDevice in updatelocalStorage[programMode]) {
                updatelocalStorage[programMode][connectedDevice][version] =
                    sessionStorage
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            } else {
                updatelocalStorage[programMode][connectedDevice] = {
                    [version]: sessionStorage,
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        } else {
            updatelocalStorage[programMode] = {
                [connectedDevice]: {
                    [version]: sessionStorage,
                },
            }
            localStorage.setItem(
                'recoveryData',
                JSON.stringify(updatelocalStorage)
            )
        }
    }

    select = (index, coverflowActive) => {
        var sidebarContents = this.state.sidebarContents
        sidebarContents.push(index)
        sessionStorage.setItem('Index', JSON.stringify(sidebarContents))
        var components = this.state.components
        components[index].selected = true
        // if (this.props.tutorialMode == "true") {
        //   this.refs.concept.refs.tutorial.workspaceUpdate(index, components);
        // }
        this.setState({
            coverflowActive: coverflowActive,
            sidebarContents: sidebarContents,
            components: components,
        })

        var arr = []
        // if (sessionStorage.getItem('SelectedComp') != null)
        // {
        //   arr = JSON.parse(sessionStorage.getItem('SelectedComp'));
        // }
        // else { arr = []; }

        this.state.sidebarContents.map((el) => {
            arr.push(this.state.components[el])
        })
        sessionStorage.setItem('SelectedComp', JSON.stringify(arr))

        var { sidebarContents, components, Alldata } = this.state
        components[index].selected = true
        this.setState({
            redata: components,
        })
        sessionStorage.setItem('AppDetails', JSON.stringify(this.state.Alldata))

        const filterData = this.state.Alldata.filter((m) => {
            return m.name.toUpperCase() == components[index].name.toUpperCase()
        })

        // console.log("YEP",filterData);
        //  this.setState({ Alldata: filterData })
        // sessionStorage.setItem("AppDetails", JSON.stringify(this.state.Alldata));
        // this.props.selecteComponent(filterData[0]);
        this.props.selecteComponent(
            JSON.parse(sessionStorage.getItem('SelectedComp'))
        )
        this.props.indexSelection(JSON.parse(sessionStorage.getItem('Index')))
        this.saveToLocalStorage()
    }
    /**
     * Remove a component from project (Remove mean removal from sidebar)
     * @param  {number} index Index wrt sidebar
     */
    remove = (index) => {
        const play_shield_ports = {
            play_shield_v1: [
                'E',
                'F',
                'servo_motor1',
                'servo_motor2',
                'M1',
                'M2',
                'M3',
                'M4',
            ],
            play_shield: [
                'F',
                'A1',
                'A2',
                'B1',
                'B2',
                'C1',
                'C2',
                'D1',
                'D2',
                'M1',
                'M2',
                'M3',
                'M4',
            ],
            peeCee_ATR: ['M1', 'M2', 'M3', 'M4', 'S1', 'S2'],
            arm_shield: ['SPK', 'SS', 'P0', 'P1', 'P2', 'MOTOR1'],
        }

        var {
            sidebarContents,
            components,
            workspace,
            coverflowActive,
            redata,
        } = this.state
        var removeIndex = sidebarContents[index]
        if (sidebarContents.length === components.length)
            coverflowActive = removeIndex
        sidebarContents.splice(index, 1)
        components[removeIndex].selected = false

        if (this.props.tutorialMode == 'true') {
            this.refs.concept.refs.tutorial.workspaceUpdate(index, components)
        }

        redata[removeIndex].selected = false

        sessionStorage.setItem('AppDetails', JSON.stringify(this.state.Alldata))
        sessionStorage.setItem('Index', JSON.stringify(sidebarContents))

        let GP = JSON.parse(sessionStorage.getItem('SelectedComp'))
        var x = GP.filter((m) => m.name != redata[removeIndex].name)

        //fix for playC 794 with redux update
        let portConnections = JSON.parse(
            sessionStorage.getItem('assembly')
        ).PortConnections
        Object.entries(this.props.indexData.assembly.PortConnections).map(
            (port) => {
                if (
                    port[1] != null &&
                    port[1].type == redata[removeIndex].type
                ) {
                    portConnections[port[0]] = null
                }
            }
        )

        sessionStorage.setItem('SelectedComp', JSON.stringify(x))
        this.props.selecteComponent(x)

        this.props.indexSelection(JSON.parse(sessionStorage.getItem('Index')))

        const removeFromworkSpace = (component, port) => {
            this.props.indexData.assembly.workspace.components[component.type] =
                this.props.indexData.assembly.workspace.components[
                    component.type
                ].filter((list) => !list.connectedTo.includes(port))
        }

        const dual_splitter = (preKnownPortName, sensor) => {
            Object.entries(this.props.indexData.assembly.PortConnections).map(
                (index) => {
                    if (
                        (sensor != null &&
                            index[1] !== null &&
                            index[1].type === sensor) ||
                        (preKnownPortName != null &&
                            index[1] !== null &&
                            preKnownPortName === index[0])
                    ) {
                        ;[`${index[0]}1`, `${index[0]}2`].map((port) => {
                            let component =
                                this.props.indexData.assembly.PortConnections[
                                    port
                                ]
                            if (component !== null) {
                                removeFromworkSpace(component, port)
                                removeSensorProgramData(
                                    this.props.indexData.logic.program,
                                    port,
                                    component.type
                                )
                            }
                        })
                    }
                }
            )
        }

        const other_than_dual_splitter = (preKnownPortName, sensor) => {
            Object.entries(this.props.indexData.assembly.PortConnections).map(
                (index) => {
                    if (
                        (sensor != null &&
                            index[1] !== null &&
                            index[1].type === sensor) ||
                        (preKnownPortName != null &&
                            index[1] !== null &&
                            preKnownPortName === index[0])
                    ) {
                        removeSensorProgramData(
                            this.props.indexData.logic.program,
                            index[0],
                            sensor
                        )
                    }
                }
            )
        }

        if (
            redata[removeIndex].type in
            this.props.indexData.assembly.workspace.components
        ) {
            this.props.indexData.assembly.workspace.components[
                redata[removeIndex].type
            ] = []
            if (redata[removeIndex].type === 'dual_splitter') {
                dual_splitter(null, redata[removeIndex].type)
            } else if (
                [
                    'play_shield_v1',
                    'play_shield',
                    'arm_shield',
                    'peeCee_ATR',
                ].includes(redata[removeIndex].type)
            ) {
                play_shield_ports[`${redata[removeIndex].type}`].map((port) => {
                    let component =
                        this.props.indexData.assembly.PortConnections[port]
                    if (component != null)
                        if (
                            ['A', 'B', 'C', 'D'].includes(port.slice(0, 1)) &&
                            this.props.indexData.assembly.PortConnections[
                                port.slice(0, 1)
                            ] !== null &&
                            this.props.indexData.assembly.PortConnections[
                                port.slice(0, 1)
                            ].type === 'dual_splitter'
                        ) {
                            ///// '{}' equivalent to python pass ////////
                            {
                            }
                        } else {
                            if (component.type === 'dual_splitter') {
                                removeFromworkSpace(component, port)
                                dual_splitter(port, null)
                            } else {
                                removeFromworkSpace(component, port)
                                other_than_dual_splitter(port, null)
                            }
                        }
                })
            } else {
                other_than_dual_splitter(null, redata[removeIndex].type)
            }
        }

        if (
            ['play_shield_v1', 'play_shield'].includes(redata[removeIndex].type)
        ) {
            sessionStorage.setItem('shield', false)
            let sessionItem = JSON.parse(sessionStorage.getItem('dualsplitter'))
            if (sessionItem !== null)
                if ('play_shield_v1' in sessionItem) {
                    sessionItem['play_shield_v1'] = []
                } else {
                    sessionItem['play_shield'] = []
                }
            sessionStorage.setItem(
                'assembly',
                JSON.stringify(this.props.indexData.assembly)
            )
            if (sessionItem !== null)
                sessionStorage.setItem(
                    'dualsplitter',
                    JSON.stringify(sessionItem)
                )
            // window.location.reload()
        }
        if (redata[removeIndex].type == 'arm_shield') {
            sessionStorage.setItem('arm_shield', false)
            let sessionItem = JSON.parse(sessionStorage.getItem('dualsplitter'))
            if (sessionItem !== null) sessionItem['arm_shield'] = []
            sessionStorage.setItem(
                'assembly',
                JSON.stringify(this.props.indexData.assembly)
            )
            if (sessionItem !== null)
                sessionStorage.setItem(
                    'dualsplitter',
                    JSON.stringify(sessionItem)
                )
            // window.location.reload()
        }
        if (redata[removeIndex].type == 'peeCee_ATR') {
            sessionStorage.setItem('peeCeeATR', false)
            let sessionItem = JSON.parse(sessionStorage.getItem('dualsplitter'))
            if (sessionItem !== null) sessionItem['peeCeeATR'] = []
            sessionStorage.setItem(
                'assembly',
                JSON.stringify(this.props.indexData.assembly)
            )
            if (sessionItem !== null)
                sessionStorage.setItem(
                    'dualsplitter',
                    JSON.stringify(sessionItem)
                )
            // window.location.reload()
        }
        this.setState({
            sidebarContents: sidebarContents,
            workspace: workspace,
            redata: redata,
            coverflowActive: coverflowActive,
        })
        sessionStorage.setItem(
            'assembly',
            JSON.stringify(this.props.indexData.assembly)
        )
        this.props.PortConnections(portConnections)
        this.saveToLocalStorage()

        // let arr=this.state.redata;
        // let data=components[index]
        // console.log("OLD DATA",this.state.redata);
        // console.log("NEW DATA",data);
        //  arr.splice(index, 0, data);
        // console.log("TYTY",arr);
    }

    /**
     * Changes the active element of the coverflow in concept tab
     * @param  {number} coverflowActive
     * @see module:components/concept/Coverflow~onChange
     */
    changeCoverflowActive = (coverflowActive) => {
        sessionStorage.setItem('pageReloadConcept', false)

        this.setState({
            coverflowActive: coverflowActive,
        })
    }
    check = async () => {
        this.setState({ slideDirection: false })
        sessionStorage.setItem('slideDirection', 'false')
        let reloadRetrive = sessionStorage.getItem('Reload')
        if (localStorage.getItem('programMode') == 'program') {
            this.props.history.push('/Assembly')
            //window.location.href = "/Assembly";

            if (reloadRetrive) {
                // window.location.reload(false);
                //  const portList = await navigator.serial.getPorts();
                //console.log(">>>>>>>>>>>>>>>>>>>>>>>>", portList);
                // this.props.webSerialAction({ port: portList[0] }); // dispatching function of redux

                sessionStorage.setItem('Reload', true)
            }
        } else {
            // for restriction...

            var SelectedComp = JSON.parse(
                sessionStorage.getItem('SelectedComp')
            )
            // socket.emit("/checkConcept", SelectedComp, "Final");
            // socket.on("/conceptResult", (data) => {
            //   if (!data) {
            //     this.setState({ modalIsOpen: true });
            //   } else {
            //     this.props.history.push("/Assembly");
            //   }
            // });
        }
    }
    close = () => {
        this.setState({ modalIsOpen: false })
    }
    closeUsb = () => {
        this.setState({ usbOpen: false })
    }
    goBack = () => {
        this.props.history.goBack()
    }

    erasedConceptData = () => {
        this.setState({ slideDirection: true })
        sessionStorage.setItem('slideDirection', 'true')
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (
            programMode in JSON.parse(localStorage.getItem('recoveryData')) &&
            connectedDevice in
                JSON.parse(localStorage.getItem('recoveryData'))[programMode]
        ) {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            updatelocalStorage[programMode][connectedDevice][version] =
                sessionStorage
            localStorage.setItem(
                'recoveryData',
                JSON.stringify(updatelocalStorage)
            )
        }

        this.props.history.push(
            getUrl[
                connectedDevice == 'Ace'
                    ? version.startsWith('1')
                        ? 'PeeCeeBeta'
                        : 'PeeCeeAlpha'
                    : connectedDevice
            ]
        )
        // if (sessionStorage.getItem('connectedDevice') === 'Ace')
        //     if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
        //         this.props.history.push('/v1/selectScreen/InternalAccessories')
        //     } else {
        //         this.props.history.push('/selectScreen/InternalAccessories')
        //     }
        // else if (sessionStorage.getItem('connectedDevice') === 'Humanoid')
        //     this.props.history.push('/code/InternalAccessories')
        // window.location.reload(false);
    }
    shouldErase = (info) => {
        if (info == 'Yes') {
            let connectedDevice = sessionStorage.connectedDevice
            let version = sessionStorage.deviceVersion
            sessionStorage.removeItem('Index')
            this.props.indexData.concept.Index = []
            // if (sessionStorage.getItem('connectedDevice') === 'Ace')
            //     this.props.history.push('/selectScreen/InternalAccessories')
            // else if (sessionStorage.getItem('connectedDevice') === 'Humanoid')
            //     this.props.history.push('/code/InternalAccessories')
            this.props.history.push(
                getUrl[
                    connectedDevice == 'Ace'
                        ? version.startsWith('1')
                            ? 'PeeCeeBeta'
                            : 'PeeCeeAlpha'
                        : connectedDevice
                ]
            )
            window.location.reload(false)
        } else {
            this.setState({ erasedProgram: false })
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //   console.log("CALLING888 getDerivedStateFromProps PROPS", props);

    //   console.log("CALLING888 getDerivedStateFromProps STATE", state);
    //   console.log(document.querySelector("#coverflowElement"), "WORKING");

    //   // if (document.querySelector("#coverflowElement") != null) {
    //   //   console.log(
    //   //     document.querySelector("#coverflowElement").getAttribute("cardId"),
    //   //     "WORKING GDSFP"
    //   //   );
    //   // }
    //   // this.setState({
    //   //   coverflowActive: document.querySelector("#coverflowElement").cardid,
    //   // });
    // }

    componentDidUpdate() {
        if (document.querySelector('#coverflowElement') != null) {
            sessionStorage.setItem(
                'coverflowActive',
                document
                    .querySelector('#coverflowElement')
                    .getAttribute('cardId')
            )
        }
    }

    render() {
        sessionStorage.setItem(
            'assemblyCheckbox',
            JSON.stringify(activeCheckBox)
        )
        let inlow = this.state.sidebarContents
        if (inlow.includes(this.state.coverflowActive)) {
            this.state.abc = true
        } else {
            this.state.abc = false
        }
        // var App=this.props.app;
        if (localStorage.getItem('programMode') == 'learn') {
            if (
                this.state.sidebarContents.length ===
                this.state.components.length
            ) {
                // this.state.Alldata != 0
                nextButtonVisibilty = 'visible'
            }
        } else {
            nextButtonVisibilty = 'visible'
        }

        var usbDetectionModel = (
            <Modal isOpen={this.state.usbOpen} style={customStyles}>
                <img
                    onClick={this.closeUsb}
                    className="closeconceptModal"
                    src={renderPrgImage('close')}
                ></img>
                <div className="connectconceptMsg">
                    <p>Device not connected..</p>
                    <button>
                        {' '}
                        <Link
                            style={{ textDecoration: 'none', color: 'black' }}
                            to="/deviceSelection"
                        >
                            Reconnect
                        </Link>
                    </button>
                </div>
            </Modal>
        )
        var tutorialModal = (
            <Modal
                isOpen={this.state.modalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <img
                    onClick={this.close}
                    className="closeconceptModal"
                    src={renderPrgImage('close')}
                ></img>
                <div className="connectconceptMsg">
                    <p>Device not connected</p>
                    <button className="">
                        {' '}
                        <Link
                            style={{ textDecoration: 'none', color: 'black' }}
                            to="/deviceSelection"
                        >
                            Reconnect
                        </Link>
                    </button>
                </div>
            </Modal>
        )
        var dataErased = (
            <Modal
                isOpen={this.state.erasedProgram}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {/* STYLE CHANGES By: SOUMITYA 
            both btn STYLE 
            customStyles STYLE
        */}
                <div className="erasedConceptMsg" style={{ zIndex: '999' }}>
                    <p>All Unsaved program will be Erased, Continue ?</p>
                    <button
                        className="BtnPopup"
                        onClick={() => this.shouldErase('Yes')}
                    >
                        Yes
                    </button>
                    <button
                        className="BtnPopup"
                        onClick={() => this.shouldErase('No')}
                        style={{ position: 'relative', left: '10px' }}
                    >
                        No
                    </button>
                </div>
            </Modal>
        )

        const { slideDirection } = this.state

        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    userSelect: 'none',
                }}
            >
                {/* <Tutorials ref='tutorial' step={this.props.step} projId={this.props.projId} currentTab={this.props.currentTab} tutorialMode={this.props.tutorialMode} app={App}/> */}
                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : 'slide-left'
                    }`}
                    style={{ height: '100%' }}
                >
                    {' '}
                    <div
                        style={{
                            width: '80%',
                            height: '100%',
                            position: 'absolute',
                            left: 0,
                            background: '#FCFCFC',
                        }}
                    >
                        {/* DESCRIPTION */}
                        <div
                            className=""
                            style={{
                                display: 'flex',
                                position: 'absolute',
                                bottom: '-8%',
                                left: '62%',
                                transform: 'translate(-50%,0%)',
                                clear: 'both',
                                display: 'inline-block',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {this.state.sidebarContents.length ===
                            this.state.components.length ? null : JSON.parse(
                                  sessionStorage.getItem('pageReloadConcept')
                              ) === true ? (
                                <p className="External_Description">
                                    {
                                        this.state.components[
                                            sessionStorage.getItem(
                                                'coverflowActive'
                                            )
                                        ].description
                                    }
                                </p>
                            ) : this.state.components[
                                  this.state.coverflowActive
                              ] ? (
                                <p className="External_Description">
                                    {this.state.abc
                                        ? this.state.coverflowActive == 0
                                            ? this.state.components[
                                                  this.state.coverflowActive +
                                                      14
                                              ].description
                                            : this.state.components[
                                                  this.state.coverflowActive - 1
                                              ].description
                                        : this.state.components[
                                              this.state.coverflowActive
                                          ].description}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                top: '50%',
                                transform: 'translateY(-50%)',
                            }}
                        >
                            {
                                // console.log(
                                //   "NOOOOOO",
                                //   this.state.sidebarContents,
                                //   this.state.components
                                // )
                            }
                            {this.state.sidebarContents.length ===
                            this.state.components.length ? (
                                <div
                                    style={{
                                        display: 'table',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            display: 'table-cell',
                                            textAlign: 'center',
                                            verticalAlign: 'middle',
                                            fontSize: 'x-large',
                                            fontWeight: 'bold',
                                            color: '#656768',
                                        }}
                                    >
                                        All Components have been selected
                                    </div>
                                    {dataErased}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'table',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'table-row',
                                            color: '#656768',
                                            textAlign: 'center',
                                            height: '15%',
                                            fontWeight: 'normal',
                                            fontSize: '30px',
                                        }}
                                    >
                                        {/* <div
                  style={{
                    display: "table-cell",
                    textAlign: "left",
                    color: "#423D3D",
                    paddingLeft: "10%",
                    paddingTop: "7%",
                    verticalAlign: "bottom",
                  }}
                >
                  Select components:
                </div> */}
                                    </div>
                                    {tutorialModal}

                                    {/* REMOVE the pop */}
                                    {/* {usbDetectionModel} */}
                                    {dataErased}
                                    <div
                                        style={{
                                            display: 'table-row',
                                            height: '65%',
                                            zIndex: '100',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'table-cell',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            <div
                                                id="cfscroll1"
                                                className="coverflow_1"
                                                style={{ overflow: 'scroll' }}
                                                onWheel={this.scrollgreyed}
                                            ></div>
                                            <div
                                                id="cfscroll2"
                                                className="coverflow_2"
                                                style={{ overflow: 'scroll' }}
                                                onWheel={this.scrollgreyed}
                                            ></div>
                                            <Coverflow
                                                id="coverflowcomp"
                                                components={this.state.redata}
                                                select={this.select}
                                                active={
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'pageReloadConcept'
                                                        )
                                                    ) == true
                                                        ? JSON.parse(
                                                              sessionStorage.getItem(
                                                                  'coverflowActive'
                                                              )
                                                          )
                                                        : this.state
                                                              .coverflowActive
                                                }
                                                onChange={
                                                    this.changeCoverflowActive
                                                }
                                                displayQuantityOfSide={2}
                                                // navigation
                                                infiniteScroll
                                                enableHeading
                                                media={{
                                                    '@media (max-width: 900px)':
                                                        {
                                                            width: '600px',
                                                            height: '300px',
                                                        },
                                                    '@media (min-width: 900px)':
                                                        {
                                                            width: '960px',
                                                            height: '600px',
                                                        },
                                                }}
                                            />
                                            {/* <ComponentsSelector /> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* SIDE_BAR */}
                    <div
                        style={{
                            width: '17.5%',
                            height: '120%',
                            // border: '1px solid green',
                            backgroundColor: '#F6F6F6',
                            position: 'absolute',
                            left: '82.5%',
                            top: '0',
                        }}
                    >
                        <Sidebar
                            appState={this}
                            components={this.state.components}
                            sidebarContents={this.state.sidebarContents}
                            remove={this.remove}
                            projId={this.props.projId}
                        />
                    </div>
                </div>
                {/* COVERFLOW  */}

                {/* BOTTOM BACK,NEXT BTN and discription*/}
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                        top: '5%',
                        transform: 'translateY(-50%)',
                    }}
                >
                    <Footer
                        goBack={this.erasedConceptData}
                        isGoNext={true}
                        goNext={this.check}
                    />

                    {/* <div className="bottom-child">
                        <img
                            className="iconBtnSize imgBackBtn"
                            src={renderPrgImage('backBtn')}
                            onClick={this.erasedConceptData}
                        />
                        <img
                            className="iconBtnSize imgNextBtn"
                            src={renderPrgImage('nextBtn')}
                            onClick={this.check}
                        />
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
        device: state.device,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'COMPONENT_SELECTION', payload: data })
        },
        ComponentProps: (data) => {
            dispatch({ type: 'COMPONENT_PROPS', payload: data })
        },
        indexSelection: (data) => {
            dispatch({ type: 'Index_selection', payload: data })
        },
        webSerialAction: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialAction(data))
        },
        PortConnections: (data) => {
            dispatch({ type: 'PORT_CONNECTION', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
// export default Content;
