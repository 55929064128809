if (sessionStorage.getItem('concept') != null) {
    var concept = sessionStorage.getItem('concept')
    concept = JSON.parse(concept)
} else {
    var concept = {
        counter: [],
        componentProps: {},
        Index: [],
        internalaccessories: {
            Four_in_one_sensor: {
                isDistanceSensors: false,
                isGestureSensor: false,
                isLightSensor: false,
                isColorSensor: false,
            },
            isFour_in_one_sensor: false,

            // input
            isTouchZero: false,
            isTouchOne: false,
            isTouchTwo: false,
            isTouchThree: false,
            // output
            isTouchZeroOutput: false,
            isTouchOneOutput: false,
            isTouchTwoOutput: false,
            isTouchThreeOutput: false,

            isMic: false,
            isTemperature: false,
            isBattery: false,
            isAccelerometer: false,

            isLeftEye: false,
            isRightEye: false,
            isEYES: false,
            isbuzzer: false,

            isSmileOne: false,
            isSmileTwo: false,
            isSmileThree: false,
            isSmileFour: false,
            //humanoid
            isBattery: false,
            isUltrasonic: false,
            isAccelerometer: false,
            isGyroX: false,
            isGyroY: false,
            isGyroZ: false,
            isAccelerometerX: false,
            isUltrasonic: false,
            isAccelerometerY: false,
            isAccelerometerZ: false,
            // output
            isLeftRGB: false,
            isRightRGB: false,
            isMp3: false,
            isHeadServo: false,
            isIndivdualServo: false,
            isActionGroup: false,
            isM1: false,
            isM2: false,
            isSimpleServo: false,

            //klaw
            // isJoystick1: false,
            // isJoystick2: false,
            // isJoystick3: false,
            // isSwitch1: false,
            // isSwitch2: false,
            // isSwitch3: false,
            isPot1: false,
            isPot2: false,
            isPot3: false,
            isPot4: false,
            isPot5: false,
            isPot6: false,
        },
        disableAfterSettingByt: false,
    }
}

// if (sessionStorage.getItem("internalaccessories") !== null) {
//   var internalaccessories = sessionStorage.getItem("internalaccessories");
//   internalaccessories = JSON.parse(internalaccessories);
// } else {
//   var internalaccessories = {
//     Four_in_one_sensor: {
//       isDistanceSensors: false,
//       isGestureSensor: false,
//       isLightSensor: false,
//       isColorSensor: false,
//     },
//     isMic: false,
//     isTouchZero: false,
//     isTouchOne: false,
//     isTouchTwo: false,

//     isLeftEye: false,
//     isRightEye: false,
//     isbuzzer: false,

//     isSmileOne: false,
//     isSmileTwo: false,
//     isSmileThree: false,
//     isSmileFour: false,
//   };
// }

const selectedCompo_Reducer = (state = concept, action) => {
    switch (action.type) {
        case 'INTERNAL_ACCESSORIES':
            console.log('INTERNAL_ACCESSORIES REDUX')
            var counter = action.payload
            console.log(counter, 'countercountercounter')

            Object.keys(counter).map((val, index) => {
                console.log('KEYS', val)
                console.log('value', counter[val])

                if (
                    val == 'isColorSensor' ||
                    val == 'isDistanceSensors' ||
                    val == 'isGestureSensor' ||
                    val == 'isLightSensor'
                ) {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    state.internalaccessories.Four_in_one_sensor[val] =
                        counter[val]
                } else {
                    state.internalaccessories[val] = counter[val]
                }
            })

            var data = { ...state }
            sessionStorage.setItem('concept', JSON.stringify(data))
            return data

        case 'CLEAR_COMPONENT_SELECTION':
            const newComponentProps = { ...state.componentProps }
            Object.entries(newComponentProps).map((device) => {
                newComponentProps[device[0]].selected = false
            })
            const newState = {
                ...state,
                // Index: [],
                componentProps: newComponentProps,
                // counter: [],
                counter: [],
                Index: [],
                internalaccessories: {
                    Four_in_one_sensor: {
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isLightSensor: false,
                        isColorSensor: false,
                    },
                    isFour_in_one_sensor: false,

                    // input
                    isTouchZero: false,
                    isTouchOne: false,
                    isTouchTwo: false,
                    isTouchThree: false,
                    // output
                    isTouchZeroOutput: false,
                    isTouchOneOutput: false,
                    isTouchTwoOutput: false,
                    isTouchThreeOutput: false,

                    isMic: false,
                    isTemperature: false,
                    isBattery: false,
                    isAccelerometer: false,

                    isLeftEye: false,
                    isRightEye: false,
                    isEYES: false,
                    isbuzzer: false,

                    isSmileOne: false,
                    isSmileTwo: false,
                    isSmileThree: false,
                    isSmileFour: false,
                    //humanoid
                    isBattery: false,
                    isUltrasonic: false,
                    isAccelerometer: false,
                    isGyroX: false,
                    isGyroY: false,
                    isGyroZ: false,
                    isAccelerometerX: false,
                    isUltrasonic: false,
                    isAccelerometerY: false,
                    isAccelerometerZ: false,
                    // output
                    isLeftRGB: false,
                    isRightRGB: false,
                    isMp3: false,
                    isHeadServo: false,
                    isIndivdualServo: false,
                    isActionGroup: false,
                    isM1: false,
                    isM2: false,
                    isSimpleServo: false,
                },
                disableAfterSettingByt: false,
            }

            sessionStorage.removeItem('Index')
            sessionStorage.removeItem('AppDetails')
            sessionStorage.removeItem('Index')
            sessionStorage.removeItem('SelectedComp')
            sessionStorage.setItem('concept', JSON.stringify(newState))

            return newState

        case 'COMPONENT_SELECTION':
            var counter = action.payload

            var data = { ...state, counter }
            console.log('COUNTER COMPONENT_SELECTION', counter)
            sessionStorage.setItem('concept', JSON.stringify(data))
            sessionStorage.setItem('isSave', JSON.stringify(false))
            return data

        case 'Index_selection':
            var Index = action.payload

            var data = { ...state, Index }
            console.log('COUNTER Index_selection', Index)
            sessionStorage.setItem('concept', JSON.stringify(data))
            sessionStorage.setItem('isSave', JSON.stringify(false))
            return data

        case 'COMPONENT_PROPS':
            var componentProps = action.payload

            var data = { ...state, componentProps }
            console.log('COUNTER COMPONENT_PROPS', Index)

            return data

        case 'COMPONENT':
            var { payload } = action
            var { counter, componentProps } = payload

            var data = { ...state, counter, componentProps }
            console.log('COUNTER COMPONENT', Index)

            return data

        case 'DISABLE_AFTER_SETTING_BYTE':
            var disableAfterSettingByt = action.payload

            var data = {
                ...state,
                disableAfterSettingByt: disableAfterSettingByt,
            }
            return data
        default:
            sessionStorage.setItem('concept', JSON.stringify(state))
            return state
    }
}

export default selectedCompo_Reducer
