// NEW UI
import saveBtnActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/save - secondary@2x.png'

import plays from '../Assets/play button.png'

import saveBtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/save@2x.png'

import oneXspeedInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/1xspeed@2x.png'

import saveBtnInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/save - secondary@2x.png'

import helpBtnActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/help@2x.png'

import helpBtnInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/help@2x.png'

import uploadBtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/upload@2x.png'

import playrunBtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/play-run@2x.png'

import clearProgram from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/clearProgram.png'

import pauseBtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/pause_btn@3x.png'

import usbOFF from '../Assets/Bisoft_UI/Buttons/Active with shadow/usb - off@2x.png'
import usbON from '../Assets/Bisoft_UI/Buttons/Active with shadow/usb - on@2x.png'

import HW_SW_btn from '../Assets/Bisoft_UI/Buttons/Active with shadow/hardware-software@2x.png'

import HWbtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/hardware@2x.png'

import SWbtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/software@2x.png'

import OneXspeed from '../Assets/Bisoft_UI/Buttons/Active with shadow/1xspeed@2x.png'

import backBtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/back@2x.png'

import nextBtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/next@2x.png'

import toggle from '../Assets/Bisoft_UI/Play/SVG/piano toggle bg.svg'

import externalAccessoriesBtnAtive from '../Assets/Bisoft_UI/Buttons/Active without shadow/externalaccessories@2x.png'

import externalAccessoriesBtnInAtive from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/externalaccessories@2x.png'

import pcInternalSensorsActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/pc(internalsensors)@2x.png'

import pcInternalSensorsInActive from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/pc(internalsensors)@2x.png'

//
import batteryActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/battery@2x.png'
import batteryInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/battery@2x.png'

import accelerometerActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/accelerometer@2x.png'
import accelerometerInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/accelerometer@2x.png'

import distancesensorsActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/distancesensor@2x.png'

import distancesensorsInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/distancesensor@2x.png'

import gesturesensorActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/gesturesensor@2x.png'

import gesturesensorInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/gesturesensor@2x.png'

import lightsensorActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/lightsensor@2x.png'
import lightsensorInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/lightsensor@2x.png'

import colorsensorActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/colorsensor@2x.png'
import colorsensorInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/colorsensor@2x.png'

////////

import micActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/mic@2x.png'

import micInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/mic@2x.png'
import tempActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/temp@2x.png'

import tempInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/temp@2x.png'
import touch0Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/touch0@2x.png'
import touch0InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/touch0@2x.png'

import touch1Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/touch1@2x.png'
import touch1InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/touch1@2x.png'

import touch2Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/touch2@2x.png'
import touch2InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/touch2@2x.png'

import touch3Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/touch3@2x.png'
import touch3InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/touch3@2x.png'

//OUTPUT

import lefteyeActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/lefteye@2x.png'
import lefteyeInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/lefteye@2x.png'

import righteyeActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/righteye@2x.png'
import righteyeInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/righteye@2x.png'

import eyeActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/eye@2x.png'
import eyeInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/eye@2x.png'

import smile1Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile1@2x.png'
import smile1InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile1@2x.png'

import smile2Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile2@2x.png'
import smile2InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile2@2x.png'

import smile3Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile3@2x.png'
import smile3InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile3@2x.png'

import smile4Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile4@2x.png'
import smile4InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile4@3x.png'

import readPCActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/read@2x.png'
import readPCInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/read@2x.png'

import readPC from '../Assets/Bisoft_UI/Buttons/Active without shadow/read@2x.png'

import buzzerActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/buzzer@2x.png'
import buzzerInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/buzzer@2x.png'

// DEVICE PC

import devicePc from '../Assets/Bisoft_UI/Main/PNG/PC_image@3x.png'

import devicePc_v1 from '../Assets/Bisoft_UI/Main/PNG/PC_v1_image@3x.png'

import PlayComputerImg from '../Assets/Bisoft_UI/Main/PNG/PC_image@2x.png'
import PlayComputerwithShieldImg from '../Assets/Bisoft_UI/Main/PNG/play_shield+pc@2x.png'
// import PlayComputerwithShieldImg_v1 from '../Assets/Bisoft_UI/Main/PNG/play_shield+pc_v1@2x.png'
import PlayComputerwithShieldImg_v1 from '../Assets/Bisoft_UI/Accessories/External_NewComponents/pcshield.png'
import PlayComputerwithArmShield from '../Assets/Bisoft_UI/Main/PNG/arm_shield+pc@2x.png'
import PlayComputerwithPeeCeeATR from '../Assets/Bisoft_UI/Main/PNG/pcatr@2x.png'
import PlayComputerwithPeeCeeATRV1 from '../Assets/Bisoft_UI/Main/PNG/pc1atr@2x.png'

// MIC
import PcinternalMicInActive from '../Assets/Bisoft_UI/Play/SVG/internal mic - inactive.svg'
import PcinternalMicActive from '../Assets/Bisoft_UI/Play/SVG/internal mic - active.svg'

// BUZZER
import PcinternalBuzzerInActive from '../Assets/Bisoft_UI/Play/SVG/buzzer - inactive.svg'
import PcinternalBuzzerActive from '../Assets/Bisoft_UI/Play/SVG/buzzer - active.svg'

// TOUCH PAD
import PcinternalTouchpadsInActive from '../Assets/Bisoft_UI/Play/SVG/touch pads - inactive.svg'
import PcinternalTouchpadsActive from '../Assets/Bisoft_UI/Play/SVG/touch pads - active.svg'

// TOUCH PAD 3 version 1.0.03
import PcinternalTouch3InActive_v1 from '../Assets/Bisoft_UI/Play/SVG/touchpad3-Inactive_v1.svg'
import PcinternalTouch3Active_v1 from '../Assets/Bisoft_UI/Play/SVG/touchpad3_active_v1.svg'

//EYE
import PcinternalEYEInActive from '../Assets/Bisoft_UI/Play/SVG/eye - inactive.svg'
import PcinternalEYEActive from '../Assets/Bisoft_UI/Play/SVG/eye - active_v1.svg'

// EYE version 1.0.03
import PcinternalEYEInActive_v1 from '../Assets/Bisoft_UI/Play/SVG/eye - inactive_v1.svg'
import PcinternalEYEActive_v1 from '../Assets/Bisoft_UI/Play/SVG/eye - active_v1.svg'

// TEETH
import PcinternalTeethActive from '../Assets/Bisoft_UI/Play/SVG/teeth - active.svg'
import PcinternalTeethInActive from '../Assets/Bisoft_UI/Play/SVG/teeth - inactive.svg'

// TEETH version 1.0.03
import PcinternalTeethActive_v1 from '../Assets/Bisoft_UI/Play/SVG/teeth - active_v1.svg'
import PcinternalTeethInActive_v1 from '../Assets/Bisoft_UI/Play/SVG/teeth - inactive_v1.svg'

import Pcinternal4in1Active from '../Assets/Bisoft_UI/Play/SVG/4 in 1 - active.svg'
import Pcinternal4in1InActive from '../Assets/Bisoft_UI/Play/SVG/4 in 1 - inactive.svg'

// 4 in 1 sensor version 1.0.03
import Pcinternal4in1Active_v1 from '../Assets/Bisoft_UI/Play/SVG/Four-in-one-sensor-active_v1.svg'
import Pcinternal4in1InActive_v1 from '../Assets/Bisoft_UI/Play/SVG/Four-in-one-sensor-Inactive_v1.svg'

import popupcardType from '../Assets/Bisoft_UI/Program/PNG/popupcard@2x.png'
import flowchartbasedgroupbutton from '../Assets/Bisoft_UI/Program/PNG/flowchartbasedgroupbutton@2x.png'

import projectbased from '../Assets/Bisoft_UI/Program/PNG/projectbased@2x.png'

import blockbased from '../Assets/Bisoft_UI/Program/PNG/block based group button@2x.png'

import Cgroupbutton from '../Assets/Bisoft_UI/Program/PNG/c coding group button@2x.png'

import pythoncodingbutton from '../Assets/Bisoft_UI/Program/PNG/python coding group button@2x.png'

import programmenucard from '../Assets/Bisoft_UI/Program/PNG/programmenucard-small@2x.png'

import yourprojectsgroupbutton from '../Assets/Bisoft_UI/Program/PNG/yourprojectsgroupbutton@2x.png'

import newfilegroupbutton from '../Assets/Bisoft_UI/Program/PNG/newfilegroupbutton@2x.png'

import newactiongroupbutton from '../Assets/Bisoft_UI/Program/PNG/newactiongroupbutton@2x.png'
import youractiongroupbutton from '../Assets/Bisoft_UI/Program/PNG/youractiongroupbutton@2x.png'

import timericon from '../Assets/Bisoft_UI/Program/PNG/timericon@2x.png'

import closeBtn from '../Assets/Bisoft_UI/Program/PNG/close@2x.png'
import close from '../Assets/Bisoft_UI/Program/PNG/close.png'
import closed from '../Assets/Bisoft_UI/Program/PNG/closed.png'
import closBtn from '../Assets/Bisoft_UI/Program/PNG/close@2x.png'
import closeBtnShadow from '../Assets/Bisoft_UI/Buttons/Active with shadow/close@2x.png'

import assemblebar from '../Assets/Bisoft_UI/Program/PNG/assemblebar@2x.png'

import codebar from '../Assets/Bisoft_UI/Program/PNG/codebar@2x.png'

import selectbar from '../Assets/Bisoft_UI/Program/PNG/selectbar@2x.png'

import leftComponentBar from '../Assets/Bisoft_UI/Program/PNG/leftComponentBar@2x.png'

import smallleftComponentBar from '../Assets/Bisoft_UI/Program/PNG/leftcomponentbar-small@2x.png'

import CloseleftComponentBar from '../Assets/Bisoft_UI/Program/PNG/expand-contract-button-2@2x.png'

import OpenleftComponentBar from '../Assets/Bisoft_UI/Program/PNG/expand-contract-button-3@2x.png'

import propertypanel from '../Assets/Bisoft_UI/Program/PNG/propertypanel@2x.png'

import popupcard from '../Assets/Bisoft_UI/Play/SVG/popup card.svg'

import propertypanelSlider from '../Assets/Bisoft_UI/Play/SVG/frequency slider for sound.svg'

import propertypanelInactiveSlider from '../Assets/Bisoft_UI/Play/SVG/inactiveslider.svg'

import propertypanelGreenSlider from '../Assets/Bisoft_UI/Play/SVG/green_slider.svg'

import propertypanelRedSlider from '../Assets/Bisoft_UI/Play/SVG/red_slider.svg'

import propertypanelBlueSlider from '../Assets/Bisoft_UI/Play/SVG/blue_slider.svg'
import greaterthanActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/greaterthan@2x.png'
import greaterthanInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/greaterthan@2x.png'

import lessthanActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/lessthan@2x.png'
import lessthanInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/lessthan@2x.png'

import inbetweenActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/inbetween@2x.png'
import inbetweenInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/inbetween@2x.png'

import equaltoActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/equalto@2x.png'
import equaltoInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/equalto@2x.png'

import notequaltoActive from '../Assets/Bisoft_UI/Buttons/Active with shadow/notequalto@2x.png'
import notequaltoInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/notequalto@2x.png'
import VirtualPrgf1 from '../Assets/Bisoft_UI/Help screen/Program/Group 3224@2x.png'
import VirtualPrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3223@2x.png'
import VirtualPrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3225@2x.png'
import VirtualPrgf4 from '../Assets/Bisoft_UI/Help screen/Program/Group 3226@2x.png'
import VirtualPrgf5 from '../Assets/Bisoft_UI/Help screen/Program/Group 3221@2x.png'
import VirtualPrgf6 from '../Assets/Bisoft_UI/Help screen/Program/Group 3222@2x.png'

import SelectionPrgf1 from '../Assets/Bisoft_UI/Help screen/Program/Group 3218@2x.png'
import SelectionPrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3217@2x.png'
import SelectionPrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3216@2x.png'

import InternalPrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3215@2x.png'
import InternalPrgf4 from '../Assets/Bisoft_UI/Help screen/Program/Group 3214@2x.png'
import InternalPrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3213@2x.png'
import InternalPrgf1 from '../Assets/Bisoft_UI/Help screen/Program/Group 3212@2x.png'
import Pcv1SelectH1 from '../Assets/Bisoft_UI/Help screen/Program/Pcv1SelectH1.png'
import Pcv1SelectH2 from '../Assets/Bisoft_UI/Help screen/Program/Pcv1SelectH2.png'

import ExternalPrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3210@2x.png'
import ExternalPrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3211@2x.png'

import AssemblyPrgf1 from '../Assets/Bisoft_UI/Help screen/Program/pcvassembly.png'
import AssemblyPrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3208@2x.png'
import AssemblyPrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3207@2x.png'
import AssemblyPrgf4 from '../Assets/Bisoft_UI/Help screen/Program/Group 3200@2x.png'

import LogicPrgf1 from '../Assets/Bisoft_UI/Help screen/Program/Group 3201@2x.png'
import LogicPrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3199@2x.png'
import LogicPrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3198@2x.png'
import LogicPrgf4 from '../Assets/Bisoft_UI/Help screen/Program/Group 3197@2x.png'
import LogicPrgf5 from '../Assets/Bisoft_UI/Help screen/Program/Group 3196@2x.png'
import ZingPC from '../Assets/humanoid/ZingPC.png'
import ZingPCv1 from '../Assets/humanoid/Group 5614/zingPcv1.png'
import SimulatePrgf1 from '../Assets/Bisoft_UI/Help screen/Program/Group 3202@2x.png'
import SimulatePrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3203@2x.png'
import hexapod from '../Assets/hexapod/hexapodTop@2x.png'

import SavePrgf1 from '../Assets/Bisoft_UI/Help screen/Program/Group 3204@2x.png'
import SavePrgf2 from '../Assets/Bisoft_UI/Help screen/Program/Group 3205@2x.png'
import SavePrgf3 from '../Assets/Bisoft_UI/Help screen/Program/Group 3206@2x.png'
import StartArrow from '../Assets/Bisoft_UI/Program/PNG/startArrow.png'
import repeatInactive from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/repeat button@2x.png'
import repeatActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/repeat button@2x.png'

import stopInactive from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/stop button@2x.png'
import stopActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/stop button@2x.png'

import PCimg from '../Assets/Bisoft_UI/Main/PNG/PC_image@3x.png'

import SaveProg from '../Assets/Bisoft_UI/Program/PNG/default image@2x.png'
// login screen
import yellowBg from '../Assets/images/yellowbg.svg'
import yuduRoboticsLogo from '../Assets/images/yuduroboticsLogo.svg'
import popupCard from '../Assets/img/propertypanel@2x.png'
// login screen
// import yellowBg from '../Assets/images/yellowbg.svg'
import YourProgram from '../Assets/Project all included@3x.png'

import graphbtn from '../Assets/images/graphbtn.svg'
import LineGraph from '../Assets/images/LineGraph.svg'
import BarGraph from '../Assets/images/BarGraph.svg'
import MeterGraph from '../Assets/images/MeterGraph.svg'
import sidebar from '../Assets/images/sidebar.svg'
import play from '../Assets/images/play.svg'
import pause from '../Assets/images/pause.svg'
import stop from '../Assets/images/stop.svg'
import bgBarGraph from '../Assets/images/bgBarGraph.svg'
import graphHelpBtn from '../Assets/images/graphHelpBtn.svg'
import connectionOn from '../Assets/images/connectionOn.svg'
import pop from '../Assets/images/pop.svg'
import micIcon from '../Assets/images/micIcon.svg'
import colorIcon from '../Assets/images/colorIcon.svg'
import ledIcon from '../Assets/images/ledIcon.svg'
import tempIcon from '../Assets/images/tempIcon.svg'
import CloseGraphSideBar from '../Assets/images/CloseGraphSideBar.svg'
import activePop from '../Assets/images/activePop.svg'
import mic from '../Assets/images/inActiveBtn/mic.svg'
import temp from '../Assets/images/inActiveBtn/temp.svg'
import batt from '../Assets/images/inActiveBtn/batt.svg'
import light from '../Assets/images/inActiveBtn/light.svg'
import color from '../Assets/images/inActiveBtn/color.svg'
import acceX from '../Assets/images/inActiveBtn/acceX.svg'
import acceY from '../Assets/images/inActiveBtn/acceY.svg'
import acceZ from '../Assets/images/inActiveBtn/acceZ.svg'
import distance from '../Assets/images/inActiveBtn/distance.svg'
import gesture from '../Assets/images/inActiveBtn/gesture.svg'
import touchOne from '../Assets/images/inActiveBtn/touchOne.svg'
import touchZero from '../Assets/images/inActiveBtn/touchZero.svg'
import touchTwo from '../Assets/images/inActiveBtn/touchTwo.svg'
import touchThree from '../Assets/images/inActiveBtn/touchThree.svg'
import headUltrasonic from '../Assets/images/inActiveBtn/headUltrasonic.svg'
import switch1 from '../Assets/images/inActiveBtn/switch1.svg'
import switch2 from '../Assets/images/inActiveBtn/switch2.svg'

import Activemic from '../Assets/images/ActiveBtn/mic.svg'
import Activetemp from '../Assets/images/ActiveBtn/temp.svg'
import Activebatt from '../Assets/images/ActiveBtn/batt.svg'
import Activelight from '../Assets/images/ActiveBtn/light.svg'
import Activecolor from '../Assets/images/ActiveBtn/color.svg'
import ActiveacceX from '../Assets/images/ActiveBtn/acceX.svg'
import ActiveacceY from '../Assets/images/ActiveBtn/acceY.svg'
import ActiveacceZ from '../Assets/images/ActiveBtn/acceZ.svg'
import Activedistance from '../Assets/images/ActiveBtn/distance.svg'
import Activegesture from '../Assets/images/ActiveBtn/gesture.svg'
import ActivetouchOne from '../Assets/images/ActiveBtn/touchOne.svg'
import ActivetouchZero from '../Assets/images/ActiveBtn/touchZero.svg'
import ActivetouchTwo from '../Assets/images/ActiveBtn/touchTwo.svg'
import ActivetouchThree from '../Assets/images/ActiveBtn/touchThree.svg'
import ActiveHeadUltrasonice from '../Assets/images/ActiveBtn/headultrasonic.svg'
import ActiveSwitch1 from '../Assets/images/ActiveBtn/switch1.svg'
import ActiveSwitch2 from '../Assets/images/ActiveBtn/switch2.svg'
import PortPopUp from '../Assets/images/portPopUp.svg'
import whiteBtn from '../Assets/images/whiteBtn.svg'
import orangeBtn from '../Assets/images/orangeBtn.svg'
import playButton from '../Assets/images/playbutton.png'
import learnButton from '../Assets/images/Learnbutton2.png'
import dataButton from '../Assets/images/databutton.png'
import codeButton from '../Assets/images/codebutton.png'
import appButton from '../Assets/images/appbutton.png'
import timepopUp from '../Assets/images/timepopUp.svg'
import Rectangle from '../Assets/images/Rectangle.svg'
import Colon from '../Assets/images/colon.svg'

// Internal Accessories Screen Btn in SVG
// Active Btn
import micActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/mic.svg'
import tempActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/temp.svg'
import touchZeroActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/touchZero.svg'
import touchOneActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/touchOne.svg'
import touchTwoActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/touchTwo.svg'
import colorSensorActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/colorSensor.svg'
import gestureSensorActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/gestureSensor.svg'
import lightSensorActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/lightSensor.svg'
import smileOneActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/smileOne.svg'
import smileTwoActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/smileTwo.svg'
import smileThreeActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/smileThree.svg'
import smileFourActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/smileFour.svg'
import lefteyeActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/lefteye.svg'
import righteyeActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/righteye.svg'
import buzzerActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Selected/buzzer.svg'

// InActive Btn

import micInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/mic.svg'
import tempInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/temp.svg'
import touchZeroInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/touchZero.svg'
import touchOneInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/touchOne.svg'
import touchTwoInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/touchTwo.svg'
import colorSensorInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/colorSensor.svg'
import gestureSensorInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/gestureSensor.svg'
import lightSensorInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/lightSensor.svg'
import smileOneInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/smileOne.svg'
import smileTwoInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/smileTwo.svg'
import smileThreeInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/smileThree.svg'
import smileFourInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/smileFour.svg'
import lefteyeInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/lefteye.svg'
import righteyeInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/righteye.svg'
import buzzerInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/buzzer.svg'
import distanceSensorInActiveBtn from '../Assets/Bisoft_UI/Accessories/SVGs for select screen/Deselected/distanceSensor.svg'

// import LEDComponent from "../Assets/Bisoft_UI/PC Accessories/Mask Group 27@3x.png";

// graphData HelpScreen
import lineGraphH from '../Assets/Bisoft_UI/Help screen/graphData/selectPlotter.png'
import barGraphH from '../Assets/Bisoft_UI/Help screen/graphData/selectPlotter1.png'
import graphTimeH from '../Assets/Bisoft_UI/Help screen/graphData/graphTime.png'
import graphPortH from '../Assets/Bisoft_UI/Help screen/graphData/selectPort.png'
import graphSensorH from '../Assets/Bisoft_UI/Help screen/graphData/graphSensor.png'

// zingInternalAccessories helpscrren
import selectScreenH1 from '../Assets/Bisoft_UI/Help screen/ZingHex/selectScreenH1.png'
import selectScreenH2 from '../Assets/Bisoft_UI/Help screen/ZingHex/selectScreenH2.png'
import assemblyH1 from '../Assets/Bisoft_UI/Help screen/ZingHex/assemblyH1.png'
import assemblyH2 from '../Assets/Bisoft_UI/Help screen/ZingHex/assemblyH2.png'
import assemblyH from '../Assets/Bisoft_UI/Help screen/ZingHex/assemblyH.png'
import assemblyH3 from '../Assets/Bisoft_UI/Help screen/ZingHex/assemblyH3.png'
import selectPortH from '../Assets/Bisoft_UI/Help screen/ZingFlowchart/selectPortH.png'
import inputH from '../Assets/Bisoft_UI/Help screen/ZingFlowchart/inputH.png'
import digitalH from '../Assets/Bisoft_UI/Help screen/ZingFlowchart/digitalH.png'
import ZingProjectH from '../Assets/Bisoft_UI/Help screen/ZingProject/ZingProjectH.png'
import ZingProjectH1 from '../Assets/Bisoft_UI/Help screen/ZingProject/ZingProjectH1.png'
import ZingProjectH2 from '../Assets/Bisoft_UI/Help screen/ZingProject/ZingProjectH2.png'
import ZingProjectH3 from '../Assets/Bisoft_UI/Help screen/ZingProject/ZingProjectH3.png'
import ZingProjectH4 from '../Assets/Bisoft_UI/Help screen/ZingProject/ZingProjectH4.png'
import ZingCreateActionH from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/ZingCreateActionH.png'
import ZingCreateActionH1 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/ZingCreateActionH1.png'

import ZingCreateActionH2 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/ZingCreateActionH2@3x.png'
import ZingCreateActionH3 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/ZingCreateActionH3@3x.png'

import createActionH1 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/createActionH1@3x.png'
import createActionH2 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/createActionH2@3x.png'
import createActionH3 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/createActionH3@3x.png'
import createActionH4 from '../Assets/Bisoft_UI/Help screen/ZingCreateAction/createActionH4@3x.png'

// AppMode HelpScreen
import AppRemoteH from '../Assets/Bisoft_UI/Help screen/AppMode/AppRemoteH.png'
import AppCameraH from '../Assets/Bisoft_UI/Help screen/AppMode/AppCameraH.png'
import AppMicH from '../Assets/Bisoft_UI/Help screen/AppMode/AppMicH.png'
import AppPcH from '../Assets/Bisoft_UI/Help screen/AppMode/AppPcH.png'
import faceH from '../Assets/Bisoft_UI/Help screen/AppMode/FaceH.png'
import emotionH from '../Assets/Bisoft_UI/Help screen/AppMode/emotionH.png'
import objectH from '../Assets/Bisoft_UI/Help screen/AppMode/objectH.png'
import PrgmH from '../Assets/Bisoft_UI/Help screen/AppMode/PrgmH.png'
import PrgmH1 from '../Assets/Bisoft_UI/Help screen/AppMode/PrgmH1.png'
import PrgmH2 from '../Assets/Bisoft_UI/Help screen/AppMode/PrgmH2.png'
import PrgmH3 from '../Assets/Bisoft_UI/Help screen/AppMode/PrgmH3.png'
import PrgmH4 from '../Assets/Bisoft_UI/Help screen/AppMode/PrgmH4.png'
import appModeRemoteH from '../Assets/Bisoft_UI/Help screen/AppMode/appModeRemoteH.png'
import appModeRemoteH1 from '../Assets/Bisoft_UI/Help screen/AppMode/appModeRemoteH1.png'
import uploadH from '../Assets/Bisoft_UI/Help screen/AppMode/uploadH.png'
import uploadH1 from '../Assets/Bisoft_UI/Help screen/AppMode/uploadH1.png'
import emotionDecH from '../Assets/Bisoft_UI/Help screen/AppMode/emotionDecH.png'
import emotionDecH1 from '../Assets/Bisoft_UI/Help screen/AppMode/emotionDecH1.png'
import emotionDecH2 from '../Assets/Bisoft_UI/Help screen/AppMode/emotionDecH2.png'
import emotionDecH3 from '../Assets/Bisoft_UI/Help screen/AppMode/emotionDecH3.png'
import emotionDecH4 from '../Assets/Bisoft_UI/Help screen/AppMode/emotionDecH4.png'
import emotionDecH5 from '../Assets/Bisoft_UI/Help screen/AppMode/emotionDecH5.png'

// PCV1 Assembly HelpScrren
import Pcv1AssemblyH from '../Assets/Bisoft_UI/Help screen/Program/Pcv1AssemblyH.png'
import Pcv1AssemblyH1 from '../Assets/Bisoft_UI/Help screen/Program/Pcv1AssemblyH1.png'
import Pcv1AssemblyH2 from '../Assets/Bisoft_UI/Help screen/Program/Pcv1AssemblyH2.png'
import Pcv1AssemblyH3 from '../Assets/Bisoft_UI/Help screen/Program/Pcv1AssemblyH3.png'
import pcv1assembly from '../Assets/Bisoft_UI/Help screen/Program/Pcv1AssemblyH.png'

// input Screen PC0.1
import internalEYEActive from '../Assets/internalAccessories/eye - active.svg'
import internalEYEInActive from '../Assets/internalAccessories/eye - inactive.svg'
import internalTeethInActive from '../Assets/internalAccessories/teeth - inactive.svg'
import internalTeethActive from '../Assets/internalAccessories/teeth - active.svg'
import internal4in1Active from '../Assets/internalAccessories/4 in 1 - active.svg'
import internal4in1InActive from '../Assets/internalAccessories/4 in 1 - inactive.svg'
import internalMicInActive from '../Assets/internalAccessories/internal mic - inactive.svg'
import internalMicActive from '../Assets/internalAccessories/internal mic - active.svg'
import internalBuzzerInActive from '../Assets/internalAccessories/buzzer - inactive.svg'
import internalBuzzerActive from '../Assets/internalAccessories/buzzer - active.svg'
import internalTouchpadsInActive from '../Assets/internalAccessories/touch pads - inactive.svg'
import internalTouchpadsActive from '../Assets/internalAccessories/touch pads - active.svg'
import PCv1 from '../Assets/Bisoft_UI/Main/PNG/PC_v1_image@3x.png'
import pcImg from '../Assets/internalAccessories/PC_image@3x.png'

//Roboki imges
import Roboki from '../Assets/Bisoft_UI/Main/PNG/Roboki.png'
import switch1InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/switch1.png'
import switch2InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/switch2.png'
import switch1Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/switch1.png'
import switch2Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/switch2.png'

//klaw
import activeJoystick from '../Assets/images/activeImg/gyroX.svg'
import activeSwitch from '../Assets/images/activeImg/gyroY.svg'
import inactiveJoystick from '../Assets/images/inactiveImg/gyroX.svg'
import inactiveSwitch from '../Assets/images/inactiveImg/gyroY.svg'

import klawShield from '../Assets/klaw/Mask Group 173@3x.png'
import NewklawShield from '../Assets/klaw/RoboticArmAssembly@3x.png'
import Klaw from '../Assets/klaw/toy-robotic-arm@2x.png'
import Klaw_3x from '../Assets/klaw/toy-robotic-arm@3x.png'

function renderPrgImage(imageName) {
    switch (imageName) {
        case 'pcImg':
            return pcImg
        case 'PCv1':
            return PCv1
        case 'Roboki':
            return Roboki
        case 'internalTouchpadsActive':
            return internalTouchpadsActive
        case 'internalTouchpadsInActive':
            return internalTouchpadsInActive
        case 'internal4in1Active':
            return internal4in1Active
        case 'internal4in1InActive':
            return internal4in1InActive
        case 'internalMicInActive':
            return internalMicInActive
        case 'internalMicActive':
            return internalMicActive
        case 'internalBuzzerInActive':
            return internalBuzzerInActive
        case 'internalBuzzerActive':
            return internalBuzzerActive
        case 'internalEYEActive':
            return internalEYEActive
        case 'internalEYEInActive':
            return internalEYEInActive
        case 'internalTeethInActive':
            return internalTeethInActive
        case 'internalTeethActive':
            return internalTeethActive
        case 'micActiveBtn':
            return micActiveBtn
        case 'tempActiveBtn':
            return tempActiveBtn
        case 'touchZeroActiveBtn':
            return touchZeroActiveBtn
        case 'touchOneActiveBtn':
            return touchOneActiveBtn
        case 'touchTwoActiveBtn':
            return touchTwoActiveBtn
        case 'colorSensorActiveBtn':
            return colorSensorActiveBtn
        case 'gestureSensorActiveBtn':
            return gestureSensorActiveBtn
        case 'lightSensorActiveBtn':
            return lightSensorActiveBtn
        case 'smileOneActiveBtn':
            return smileOneActiveBtn
        case 'smileTwoActiveBtn':
            return smileTwoActiveBtn
        case 'smileThreeActiveBtn':
            return smileThreeActiveBtn
        case 'smileFourActiveBtn':
            return smileFourActiveBtn
        case 'lefteyeActiveBtn':
            return lefteyeActiveBtn
        case 'righteyeActiveBtn':
            return righteyeActiveBtn
        case 'buzzerActiveBtn':
            return buzzerActiveBtn
        case 'ActiveHeadUltrasonice':
            return ActiveHeadUltrasonice
        case 'ActiveSwitch1':
            return ActiveSwitch1
        case 'ActiveSwitch2':
            return ActiveSwitch2

        // Inactive Btn

        case 'micInActiveBtn':
            return micInActiveBtn
        case 'tempInActiveBtn':
            return tempInActiveBtn
        case 'touchZeroInActiveBtn':
            return touchZeroInActiveBtn
        case 'touchOneInActiveBtn':
            return touchOneInActiveBtn
        case 'touchTwoInActiveBtn':
            return touchTwoInActiveBtn
        case 'colorSensorInActiveBtn':
            return colorSensorInActiveBtn
        case 'gestureSensorInActiveBtn':
            return gestureSensorInActiveBtn
        case 'lightSensorInActiveBtn':
            return lightSensorInActiveBtn
        case 'smileOneInActiveBtn':
            return smileOneInActiveBtn
        case 'smileTwoInActiveBtn':
            return smileTwoInActiveBtn
        case 'smileThreeInActiveBtn':
            return smileThreeInActiveBtn
        case 'smileFourInActiveBtn':
            return smileFourInActiveBtn
        case 'lefteyeInActiveBtn':
            return lefteyeInActiveBtn
        case 'righteyeInActiveBtn':
            return righteyeInActiveBtn
        case 'buzzerInActiveBtn':
            return buzzerInActiveBtn
        case 'distanceSensorInActiveBtn':
            return distanceSensorInActiveBtn
        case 'headUltrasonic':
            return headUltrasonic
        case 'switch1':
            return switch1
        case 'switch2':
            return switch2

        case 'playButton':
            return playButton
        case 'timepopUp':
            return timepopUp
        case 'Rectangle':
            return Rectangle
        case 'Colon':
            return Colon

        case 'learnButton':
            return learnButton
        case 'dataButton':
            return dataButton
        case 'codeButton':
            return codeButton
        case 'appButton':
            return appButton
        case 'PortPopUp':
            return PortPopUp
        case 'whiteBtn':
            return whiteBtn
        case 'orangeBtn':
            return orangeBtn
        case 'mic':
            return mic
        case 'temp':
            return temp
        case 'batt':
            return batt
        case 'color':
            return color
        case 'light':
            return light
        case 'distance':
            return distance
        case 'acceX':
            return acceX
        case 'acceY':
            return acceY
        case 'acceZ':
            return acceZ
        case 'touchZero':
            return touchZero
        case 'touchOne':
            return touchOne
        case 'touchTwo':
            return touchTwo
        case 'touchThree':
            return touchThree
        case 'gesture':
            return gesture

        case 'Activemic':
            return Activemic
        case 'Activetemp':
            return Activetemp
        case 'Activebatt':
            return Activebatt
        case 'Activecolor':
            return Activecolor
        case 'Activelight':
            return Activelight
        case 'Activedistance':
            return Activedistance
        case 'ActiveacceX':
            return ActiveacceX
        case 'ActiveacceY':
            return ActiveacceY
        case 'ActiveacceZ':
            return ActiveacceZ
        case 'ActivetouchZero':
            return ActivetouchZero
        case 'ActivetouchOne':
            return ActivetouchOne
        case 'ActivetouchTwo':
            return ActivetouchTwo
        case 'ActivetouchThree':
            return ActivetouchThree
        case 'Activegesture':
            return Activegesture
        case 'yourProgram':
            return YourProgram
        case 'yellowBg':
            return yellowBg
        case 'yuduRoboticsLogo':
            return yuduRoboticsLogo
        case 'CloseGraphSideBar':
            return CloseGraphSideBar
        case 'micIcon':
            return micIcon
        case 'tempIcon':
            return tempIcon
        case 'colorIcon':
            return colorIcon
        case 'ledIcon':
            return ledIcon
        case 'activePop':
            return activePop
        case 'pop':
            return pop
        case 'graphHelpBtn':
            return graphHelpBtn
        case 'connectionOn':
            return connectionOn
        case 'graphbtn':
            return graphbtn
        case 'LineGraph':
            return LineGraph
        case 'BarGraph':
            return BarGraph
        case 'MeterGraph':
            return MeterGraph
        case 'sidebar':
            return sidebar
        case 'play':
            return play
        case 'pause':
            return pause
        case 'stop':
            return stop
        case 'bgBarGraph':
            return bgBarGraph
        case 'saveBtnActive':
            return saveBtnActive
        case 'saveBtn':
            return saveBtn
        case 'oneXspeedInActive':
            return oneXspeedInActive
        case 'saveBtnInActive':
            return saveBtnInActive
        case 'helpBtnActive':
            return helpBtnActive
        case 'helpBtnInActive':
            return helpBtnInActive
        // case "bluetoothBtnActive":
        //   return bluetoothBtnActive;
        // case "bluetoothBtnInActive":
        //   return bluetoothBtnInActive;
        case 'clearProgram':
            return clearProgram
        case 'uploadBtn':
            return uploadBtn
        case 'playrunBtn':
            return playrunBtn
        case 'pauseBtn':
            return pauseBtn
        case 'HumanoidWithPC':
            return ZingPC
        case 'humanoidWithPCv1':
            return ZingPCv1
        case 'pc_v1':
            return devicePc_v1
        case 'hexapod':
            return hexapod
        case 'usbOFF':
            return usbOFF
        case 'usbON':
            return usbON
        case 'HW_SW_btn':
            return HW_SW_btn
        case 'HWbtn':
            return HWbtn
        case 'SWbtn':
            return SWbtn
        case 'OneXspeed':
            return OneXspeed
        case 'nextBtn':
            return nextBtn
        case 'toggle':
            return toggle
        case 'externalAccessoriesBtnAtive':
            return externalAccessoriesBtnAtive
        case 'externalAccessoriesBtnInAtive':
            return externalAccessoriesBtnInAtive
        case 'pcInternalSensorsActive':
            return pcInternalSensorsActive
        case 'pcInternalSensorsInActive':
            return pcInternalSensorsInActive

        case 'distancesensorsActive':
            return distancesensorsActive
        case 'distancesensorsInActive':
            return distancesensorsInActive
        case 'gesturesensorActive':
            return gesturesensorActive
        case 'gesturesensorInActive':
            return gesturesensorInActive
        case 'lightsensorActive':
            return lightsensorActive
        case 'lightsensorInActive':
            return lightsensorInActive
        case 'colorsensorActive':
            return colorsensorActive
        case 'colorsensorInActive':
            return colorsensorInActive
        case 'batteryActive':
            return batteryActive
        case 'batteryInActive':
            return batteryInActive
        case 'accelerometerActive':
            return accelerometerActive
        case 'accelerometerInActive':
            return accelerometerInActive

        case 'micActive':
            return micActive
        case 'micInActive':
            return micInActive
        case 'tempActive':
            return tempActive
        case 'tempInActive':
            return tempInActive
        case 'touch0Active':
            return touch0Active
        case 'touch0InActive':
            return touch0InActive
        case 'touch1Active':
            return touch1Active
        case 'touch1InActive':
            return touch1InActive
        case 'touch2Active':
            return touch2Active
        case 'touch2InActive':
            return touch2InActive
        case 'touch3Active':
            return touch3Active
        case 'touch3InActive':
            return touch3InActive

        case 'EyeInActive':
            return eyeInActive
        case 'EyeActive':
            return eyeActive
        case 'lefteyeActive':
            return lefteyeActive
        case 'lefteyeInActive':
            return lefteyeInActive
        case 'righteyeActive':
            return righteyeActive
        case 'righteyeInActive':
            return righteyeInActive
        case 'smile1Active':
            return smile1Active
        case 'smile1InActive':
            return smile1InActive
        case 'smile2Active':
            return smile2Active
        case 'smile2InActive':
            return smile2InActive
        case 'smile3Active':
            return smile3Active
        case 'smile3InActive':
            return smile3InActive
        case 'smile4Active':
            return smile4Active
        case 'smile4InActive':
            return smile4InActive
        case 'readPCActive':
            return readPCActive
        case 'readPCInActive':
            return readPCInActive
        case 'readPc':
            return readPC
        case 'buzzerActive':
            return buzzerActive
        case 'buzzerInActive':
            return buzzerInActive

        case 'devicePc':
            return devicePc
        case 'PlayComputerImg':
            return PlayComputerImg
        case 'PlayComputerwithShieldImg':
            return PlayComputerwithShieldImg
        case 'PlayComputerwithShieldImg_v1':
            return PlayComputerwithShieldImg_v1
        case 'PcinternalMicActive':
            return PcinternalMicActive
        case 'PcinternalMicInActive':
            return PcinternalMicInActive

        ////////  4 in 1 sensor ////////
        case 'Pcinternal4in1Active_v1':
            return Pcinternal4in1Active_v1
        case 'Pcinternal4in1InActive_v1':
            return Pcinternal4in1InActive_v1
        ////////////////////////////////

        case 'Pcinternal4in1Active':
            return Pcinternal4in1Active
        case 'Pcinternal4in1InActive':
            return Pcinternal4in1InActive

        case 'PcinternalTouch3InActive_v1':
            return PcinternalTouch3InActive_v1
        case 'PcinternalTouch3Active_v1':
            return PcinternalTouch3Active_v1

        case 'PcinternalBuzzerInActive':
            return PcinternalBuzzerInActive
        case 'PcinternalBuzzerActive':
            return PcinternalBuzzerActive

        //////// Eye version 1.0.03 ///////
        case 'PcinternalEYEActive_v1':
            return PcinternalEYEActive_v1
        case 'PcinternalEYEInActive_v1':
            return PcinternalEYEInActive_v1
        /////////////////////////////

        case 'PcinternalEYEActive':
            return PcinternalEYEActive
        case 'PcinternalEYEInActive':
            return PcinternalEYEInActive

        ///////// Teeth version 1.0.03 //////
        case 'PcinternalTeethActive_v1':
            return PcinternalTeethActive_v1
        case 'PcinternalTeethInActive_v1':
            return PcinternalTeethInActive_v1
        //////////////////////////////

        case 'PcinternalTeethActive':
            return PcinternalTeethActive
        case 'PcinternalTeethInActive':
            return PcinternalTeethInActive
        case 'PcinternalTouchpadsActive':
            return PcinternalTouchpadsActive
        case 'PcinternalTouchpadsInActive':
            return PcinternalTouchpadsInActive

        case 'popupcardType':
            return popupcardType
        case 'flowchartbasedgroupbutton':
            return flowchartbasedgroupbutton
        case 'projectbased':
            return projectbased
        case 'blockbased':
            return blockbased
        case 'Cgroupbutton':
            return Cgroupbutton
        case 'pythoncodingbutton':
            return pythoncodingbutton
        case 'programmenucard':
            return programmenucard
        case 'yourprojectsgroupbutton':
            return yourprojectsgroupbutton
        case 'newfilegroupbutton':
            return newfilegroupbutton
        case 'newactiongroupbutton':
            return newactiongroupbutton
        case 'youractiongroupbutton':
            return youractiongroupbutton
        case 'timericon':
            return timericon
        case 'closeBtn':
            return closeBtn

        case 'close':
            return close
        case 'closeBtnShadow':
            return closeBtnShadow
        case 'assemblebar':
            return assemblebar
        case 'codebar':
            return codebar
        case 'selectbar':
            return selectbar
        case 'leftComponentBar':
            return leftComponentBar
        case 'smallleftComponentBar':
            return smallleftComponentBar
        case 'CloseleftComponentBar':
            return CloseleftComponentBar
        case 'OpenleftComponentBar':
            return OpenleftComponentBar
        case 'propertypanel':
            return propertypanel

        case 'popupcard':
            return popupcard
        case 'propertypanelSlider':
            return propertypanelSlider
        case 'propertypanelInactiveSlider':
            return propertypanelInactiveSlider
        case 'propertypanelRedSlider':
            return propertypanelRedSlider
        case 'propertypanelGreenSlider':
            return propertypanelGreenSlider
        case 'propertypanelBlueSlider':
            return propertypanelBlueSlider
        case 'greaterthanActive':
            return greaterthanActive
        case 'greaterthanInActive':
            return greaterthanInActive
        case 'lessthanActive':
            return lessthanActive
        case 'lessthanInActive':
            return lessthanInActive

        case 'inbetweenActive':
            return inbetweenActive
        case 'inbetweenInActive':
            return inbetweenInActive
        case 'equaltoActive':
            return equaltoActive
        case 'equaltoInActive':
            return equaltoInActive
        case 'notequaltoActive':
            return notequaltoActive
        case 'notequaltoInActive':
            return notequaltoInActive
        case 'backBtn':
            return backBtn
        case 'closBtn':
            return closBtn
        case 'VirtualPrgf1':
            return VirtualPrgf1

        case 'VirtualPrgf2':
            return VirtualPrgf2

        case 'VirtualPrgf3':
            return VirtualPrgf3

        case 'VirtualPrgf4':
            return VirtualPrgf4

        case 'VirtualPrgf5':
            return VirtualPrgf5

        case 'VirtualPrgf6':
            return VirtualPrgf6

        case 'SelectionPrgf1':
            return SelectionPrgf1
        case 'SelectionPrgf2':
            return SelectionPrgf2
        case 'SelectionPrgf3':
            return SelectionPrgf3

        case 'InternalPrgf1':
            return InternalPrgf1
        case 'InternalPrgf2':
            return InternalPrgf2
        case 'InternalPrgf3':
            return InternalPrgf3
        case 'InternalPrgf4':
            return InternalPrgf4

        case 'ExternalPrgf2':
            return ExternalPrgf2
        case 'ExternalPrgf3':
            return ExternalPrgf3

        case 'AssemblyPrgf1':
            return AssemblyPrgf1
        case 'AssemblyPrgf2':
            return AssemblyPrgf2
        case 'AssemblyPrgf3':
            return AssemblyPrgf3
        case 'AssemblyPrgf4':
            return AssemblyPrgf4

        case 'LogicPrgf1':
            return LogicPrgf1
        case 'LogicPrgf2':
            return LogicPrgf2
        case 'LogicPrgf3':
            return LogicPrgf3
        case 'LogicPrgf4':
            return LogicPrgf4
        case 'LogicPrgf5':
            return LogicPrgf5

        case 'SimulatePrgf1':
            return SimulatePrgf1
        case 'SimulatePrgf2':
            return SimulatePrgf2

        case 'SavePrgf1':
            return SavePrgf1
        case 'SavePrgf2':
            return SavePrgf2
        case 'SavePrgf3':
            return SavePrgf3

        case 'StartArrow':
            return StartArrow

        case 'repeatInactive':
            return repeatInactive

        case 'repeatActive':
            return repeatActive

        case 'stopInactive':
            return stopInactive

        case 'stopActive':
            return stopActive

        case 'PCimg':
            return PCimg

        case 'SaveProg':
            return SaveProg

        case 'closed':
            return closed

        case 'graphSensorH':
            return graphSensorH

        case 'graphPortH':
            return graphPortH

        case 'graphTimeH':
            return graphTimeH

        case 'lineGraphH':
            return lineGraphH

        case 'barGraphH':
            return barGraphH

        case 'selectScreenH1':
            return selectScreenH1

        case 'selectScreenH2':
            return selectScreenH2

        case 'assemblyH1':
            return assemblyH1

        case 'assemblyH2':
            return assemblyH2

        case 'assemblyH':
            return assemblyH

        case 'assemblyH3':
            return assemblyH3

        case 'selectPortH':
            return selectPortH

        case 'inputH':
            return inputH

        case 'digitalH':
            return digitalH

        case 'zingProjectH1':
            return ZingProjectH1

        case 'zingProjectH2':
            return ZingProjectH2

        case 'zingProjectH':
            return ZingProjectH

        case 'zingProjectH3':
            return ZingProjectH3

        case 'zingProjectH4':
            return ZingProjectH4

        case 'ZingCreateActionH':
            return ZingCreateActionH

        case 'ZingCreateActionH1':
            return ZingCreateActionH1
        case 'ZingCreateActionH2':
            return ZingCreateActionH2
        case 'ZingCreateActionH3':
            return ZingCreateActionH3

        case 'createActionH1':
            return createActionH1
        case 'createActionH2':
            return createActionH2
        case 'createActionH3':
            return createActionH3
        case 'createActionH4':
            return createActionH4

        case 'AppRemoteH':
            return AppRemoteH

        case 'AppCameraH':
            return AppCameraH

        case 'AppMicH':
            return AppMicH

        case 'AppPcH':
            return AppPcH

        case 'faceH':
            return faceH

        case 'emotionH':
            return emotionH

        case 'objectH':
            return objectH

        case 'PrgmH':
            return PrgmH

        case 'PrgmH1':
            return PrgmH1

        case 'PrgmH2':
            return PrgmH2

        case 'PrgmH3':
            return PrgmH3

        case 'PrgmH4':
            return PrgmH4

        case 'appModeRemoteH':
            return appModeRemoteH

        case 'appModeRemoteH1':
            return appModeRemoteH1

        case 'uploadH':
            return uploadH

        case 'uploadH1':
            return uploadH1

        case 'emotionDecH':
            return emotionDecH

        case 'emotionDecH1':
            return emotionDecH1

        case 'emotionDecH2':
            return emotionDecH2

        case 'emotionDecH3':
            return emotionDecH3

        case 'emotionDecH4':
            return emotionDecH4

        case 'emotionDecH5':
            return emotionDecH5

        case 'Pcv1SelectH1':
            return Pcv1SelectH1

        case 'Pcv1SelectH2':
            return Pcv1SelectH2

        case 'Pcv1AssemblyH':
            return Pcv1AssemblyH

        case 'Pcv1AssemblyH1':
            return Pcv1AssemblyH1

        case 'Pcv1AssemblyH2':
            return Pcv1AssemblyH2

        case 'Pcv1AssemblyH3':
            return Pcv1AssemblyH3
    }
    switch (imageName) {
        case 'saveBtnActive':
            return saveBtnActive
        case 'saveBtn':
            return saveBtn
        case 'oneXspeedInActive':
            return oneXspeedInActive
        case 'saveBtnInActive':
            return saveBtnInActive
        case 'helpBtnActive':
            return helpBtnActive
        case 'helpBtnInActive':
            return helpBtnInActive
        // case "bluetoothBtnActive":
        //   return bluetoothBtnActive;
        // case "bluetoothBtnInActive":
        //   return bluetoothBtnInActive;
        case 'clearProgram':
            return clearProgram
        case 'uploadBtn':
            return uploadBtn
        case 'playrunBtn':
            return playrunBtn
        case 'pauseBtn':
            return pauseBtn
        case 'usbOFF':
            return usbOFF
        case 'usbON':
            return usbON
        case 'HW_SW_btn':
            return HW_SW_btn
        case 'HWbtn':
            return HWbtn
        case 'SWbtn':
            return SWbtn
        case 'OneXspeed':
            return OneXspeed
        case 'nextBtn':
            return nextBtn
        case 'toggle':
            return toggle
        case 'externalAccessoriesBtnAtive':
            return externalAccessoriesBtnAtive
        case 'externalAccessoriesBtnInAtive':
            return externalAccessoriesBtnInAtive
        case 'pcInternalSensorsActive':
            return pcInternalSensorsActive
        case 'pcInternalSensorsInActive':
            return pcInternalSensorsInActive

        case 'distancesensorsActive':
            return distancesensorsActive
        case 'distancesensorsInActive':
            return distancesensorsInActive
        case 'gesturesensorActive':
            return gesturesensorActive
        case 'gesturesensorInActive':
            return gesturesensorInActive
        case 'lightsensorActive':
            return lightsensorActive
        case 'lightsensorInActive':
            return lightsensorInActive
        case 'colorsensorActive':
            return colorsensorActive
        case 'colorsensorInActive':
            return colorsensorInActive

        case 'micActive':
            return micActive
        case 'micInActive':
            return micInActive
        case 'tempActive':
            return tempActive
        case 'tempInActive':
            return tempInActive
        case 'touch0Active':
            return touch0Active
        case 'touch0InActive':
            return touch0InActive
        case 'touch1Active':
            return touch1Active
        case 'touch1InActive':
            return touch1InActive
        case 'touch2Active':
            return touch2Active
        case 'touch2InActive':
            return touch2InActive

        case 'lefteyeActive':
            return lefteyeActive
        case 'lefteyeInActive':
            return lefteyeInActive
        case 'righteyeActive':
            return righteyeActive
        case 'righteyeInActive':
            return righteyeInActive
        case 'smile1Active':
            return smile1Active
        case 'smile1InActive':
            return smile1InActive
        case 'smile2Active':
            return smile2Active
        case 'smile2InActive':
            return smile2InActive
        case 'smile3Active':
            return smile3Active
        case 'smile3InActive':
            return smile3InActive
        case 'smile4Active':
            return smile4Active
        case 'smile4InActive':
            return smile4InActive
        case 'readPCActive':
            return readPCActive
        case 'readPCInActive':
            return readPCInActive
        case 'readPc':
            return readPC
        case 'buzzerActive':
            return buzzerActive
        case 'buzzerInActive':
            return buzzerInActive

        case 'devicePc':
            return devicePc
        case 'PlayComputerImg':
            return PlayComputerImg
        case 'PlayComputerwithShieldImg':
            return PlayComputerwithShieldImg
        case 'PlayComputerwithArmShield':
            return PlayComputerwithArmShield
        case 'PlayComputerwithPeeCeeATR':
            return PlayComputerwithPeeCeeATR
        case 'PlayComputerwithPeeCeeATRV1':
            return PlayComputerwithPeeCeeATRV1
        case 'PcinternalMicActive':
            return PcinternalMicActive
        case 'PcinternalMicInActive':
            return PcinternalMicInActive
        case 'Pcinternal4in1Active':
            return Pcinternal4in1Active
        case 'Pcinternal4in1InActive':
            return Pcinternal4in1InActive
        case 'PcinternalBuzzerInActive':
            return PcinternalBuzzerInActive
        case 'PcinternalBuzzerActive':
            return PcinternalBuzzerActive
        case 'PcinternalEYEActive':
            return PcinternalEYEActive
        case 'PcinternalEYEInActive':
            return PcinternalEYEInActive
        case 'PcinternalTeethActive':
            return PcinternalTeethActive
        case 'PcinternalTeethInActive':
            return PcinternalTeethInActive
        case 'PcinternalTouchpadsActive':
            return PcinternalTouchpadsActive
        case 'PcinternalTouchpadsInActive':
            return PcinternalTouchpadsInActive

        case 'popupcardType':
            return popupcardType
        case 'flowchartbasedgroupbutton':
            return flowchartbasedgroupbutton
        case 'projectbased':
            return projectbased
        case 'blockbased':
            return blockbased
        case 'Cgroupbutton':
            return Cgroupbutton
        case 'pythoncodingbutton':
            return pythoncodingbutton
        case 'programmenucard':
            return programmenucard
        case 'yourprojectsgroupbutton':
            return yourprojectsgroupbutton
        case 'newfilegroupbutton':
            return newfilegroupbutton
        case 'timericon':
            return timericon
        case 'closeBtn':
            return closeBtn

        case 'close':
            return close
        case 'closeBtnShadow':
            return closeBtnShadow
        case 'assemblebar':
            return assemblebar
        case 'codebar':
            return codebar
        case 'selectbar':
            return selectbar
        case 'leftComponentBar':
            return leftComponentBar
        case 'smallleftComponentBar':
            return smallleftComponentBar
        case 'CloseleftComponentBar':
            return CloseleftComponentBar
        case 'OpenleftComponentBar':
            return OpenleftComponentBar
        case 'propertypanel':
            return propertypanel

        case 'popupcard':
            return popupcard
        case 'propertypanelSlider':
            return propertypanelSlider
        case 'propertypanelInactiveSlider':
            return propertypanelInactiveSlider
        case 'propertypanelRedSlider':
            return propertypanelRedSlider
        case 'propertypanelGreenSlider':
            return propertypanelGreenSlider
        case 'propertypanelBlueSlider':
            return propertypanelBlueSlider
        case 'greaterthanActive':
            return greaterthanActive
        case 'greaterthanInActive':
            return greaterthanInActive
        case 'lessthanActive':
            return lessthanActive
        case 'lessthanInActive':
            return lessthanInActive

        case 'inbetweenActive':
            return inbetweenActive
        case 'inbetweenInActive':
            return inbetweenInActive
        case 'equaltoActive':
            return equaltoActive
        case 'equaltoInActive':
            return equaltoInActive
        case 'notequaltoActive':
            return notequaltoActive
        case 'notequaltoInActive':
            return notequaltoInActive
        case 'backBtn':
            return backBtn
        case 'closBtn':
            return closBtn
        case 'VirtualPrgf1':
            return VirtualPrgf1

        case 'VirtualPrgf2':
            return VirtualPrgf2

        case 'VirtualPrgf3':
            return VirtualPrgf3

        case 'VirtualPrgf4':
            return VirtualPrgf4

        case 'VirtualPrgf5':
            return VirtualPrgf5

        case 'VirtualPrgf6':
            return VirtualPrgf6

        case 'SelectionPrgf1':
            return SelectionPrgf1
        case 'SelectionPrgf2':
            return SelectionPrgf2
        case 'SelectionPrgf3':
            return SelectionPrgf3

        case 'InternalPrgf1':
            return InternalPrgf1
        case 'InternalPrgf2':
            return InternalPrgf2
        case 'InternalPrgf3':
            return InternalPrgf3
        case 'InternalPrgf4':
            return InternalPrgf4

        case 'ExternalPrgf2':
            return ExternalPrgf2
        case 'ExternalPrgf3':
            return ExternalPrgf3

        case 'AssemblyPrgf1':
            return AssemblyPrgf1
        case 'AssemblyPrgf2':
            return AssemblyPrgf2
        case 'AssemblyPrgf3':
            return AssemblyPrgf3
        case 'AssemblyPrgf4':
            return AssemblyPrgf4

        case 'LogicPrgf1':
            return LogicPrgf1
        case 'LogicPrgf2':
            return LogicPrgf2
        case 'LogicPrgf3':
            return LogicPrgf3
        case 'LogicPrgf4':
            return LogicPrgf4
        case 'LogicPrgf5':
            return LogicPrgf5

        case 'SimulatePrgf1':
            return SimulatePrgf1
        case 'SimulatePrgf2':
            return SimulatePrgf2

        case 'SavePrgf1':
            return SavePrgf1
        case 'SavePrgf2':
            return SavePrgf2
        case 'SavePrgf3':
            return SavePrgf3

        case 'StartArrow':
            return StartArrow

        case 'repeatInactive':
            return repeatInactive

        case 'repeatActive':
            return repeatActive

        case 'stopInactive':
            return stopInactive

        case 'stopActive':
            return stopActive

        case 'PCimg':
            return PCimg

        case 'SaveProg':
            return SaveProg

        case 'closed':
            return closed
        case 'popupCard':
            return popupCard
        case 'switch1InActive':
            return switch1InActive
        case 'switch2InActive':
            return switch2InActive
        case 'switch1Active':
            return switch1Active
        case 'switch2Active':
            return switch2Active

        case 'klawShield':
            return klawShield
        case 'NewklawShield':
            return NewklawShield
        case 'Klaw':
            return Klaw
        case 'Klaw_3x':
            return Klaw_3x
        case 'activeJoystick':
            return activeJoystick
        case 'inactiveJoystick':
            return inactiveJoystick
        case 'activeSwitch':
            return activeSwitch
        case 'inactiveSwitch':
            return inactiveSwitch
    }
}

export default renderPrgImage
