import React, { useEffect, useState } from 'react'
import selectbarX from '../../../Assets/Bisoft_UI/AppMode/selectbar@2.png'
import backY from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import skip from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/skip@2x.png'
import captureButton from '../../../Assets/Bisoft_UI/AppMode/capture.png'
import startCamera from '../../../Assets/Bisoft_UI/AppMode/startCamera.png'
import PopupCard from '../../../Assets/Bisoft_UI/AppMode/popupCard@5.png'
import { useHistory } from 'react-router'
import NavBar from '../NavBar'
import closeBtn from '../../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../../source/programImg'
import * as tf from '@tensorflow/tfjs'

let allTensor = []

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    zIndex: '1000',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

const UploadImage = () => {
    const history = useHistory()
    const [stream, setStream] = useState(false)
    const [captureVideo, setCaptureVideo] = useState(false)
    const [capturBtnDsable, setCapturBtnDsable] = useState(false)
    const [length, setlength] = useState(0)
    const MOBILE_NET_INPUT_WIDTH = 224
    const MOBILE_NET_INPUT_HEIGHT = 224
    const STOP_DATA_GATHER = -1
    const CLASS_NAMES = []
    const videoHeight = 320
    const videoRef = React.useRef()
    let examplesCount = []
    let gatherDataState = STOP_DATA_GATHER

    // const [image, setImage] = useState(() => {
    //   if (sessionStorage.getItem("img") !== null) {
    //     return sessionStorage.getItem("img");
    //   } else {
    //     return PopupCard;
    //   }
    // });
    const [ishelpButton, sethelpButton] = useState(false)
    const [mobilenet, setMoblienet] = useState(undefined)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    useEffect(() => {
        const loadmodels = async () => {
            const URL =
                'https://tfhub.dev/google/tfjs-model/imagenet/mobilenet_v3_small_100_224/feature_vector/5/default/1'
            await tf.loadGraphModel(URL, { fromTFHub: true }).then((model) => {
                setMoblienet(model)
                console.log('model is loaded .....')
            })
        }
        loadmodels()
    }, [])

    const calculateFeaturesOnCurrentFrame = () => {
        return tf.tidy(function () {
            // Grab pixels from current VIDEO frame.
            let videoFrameAsTensor = tf.browser.fromPixels(videoRef.current)
            // Resize video frame tensor to be 224 x 224 pixels which is needed by MobileNet for input.
            let resizedTensorFrame = tf.image.resizeBilinear(
                videoFrameAsTensor,
                [MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH],
                true
            )
            let normalizedTensorFrame = resizedTensorFrame.div(255)
            return mobilenet
                .predict(normalizedTensorFrame.expandDims())
                .squeeze()
        })
    }

    const dataGatherLoop = async () => {
        if (!mobilenet) {
            alert(
                "Oops! It seems there's a slow internet connection. The model is currently loading. Please wait."
            )
            return
        }
        if (videoRef.current !== null) {
            let imageFeatures = calculateFeaturesOnCurrentFrame()
            if (allTensor.length < 10) {
                await imageFeatures.array().then((value) => {
                    if (allTensor.length < 10) {
                        allTensor.push(value)
                        setlength(allTensor.length)
                    }
                })
            }
            if (allTensor.length === 1) {
                takePicture()
            }
            if (allTensor.length === 10) {
                sessionStorage.setItem('tensor', JSON.stringify(allTensor))
                allTensor = []
                closeWebcam()
                history.push('/enterName')
            }

            if (examplesCount[gatherDataState] === undefined) {
                examplesCount[gatherDataState] = 0
            }

            examplesCount[gatherDataState]++

            for (let n = 0; n < CLASS_NAMES.length; n++) {
                console.log(
                    `${
                        CLASS_NAMES[n] +
                        ' data count: ' +
                        examplesCount[n] +
                        '. '
                    }`
                )
            }
        }
    }

    const base64toBlob = (image) => {
        const byteCharacters = window.atob(image)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'image/jpeg' })
        const blobUrl = URL.createObjectURL(blob)
        sessionStorage.setItem('img', blobUrl)
    }

    const takePicture = () => {
        let canvas = document.getElementById('canvas')
        canvas
            .getContext('2d')
            .drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)
        let image_data_url = canvas.toDataURL('image/jpeg')
        let image = image_data_url.split(',')[1]
        base64toBlob(image)
    }

    const startWebcam = () => {
        setCapturBtnDsable(true)
        setTimeout(() => setCapturBtnDsable(false), 3000)
        setCaptureVideo(true)
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
                setStream(true)
                let video = videoRef.current
                video.srcObject = stream
                video.play()
            })
            .catch((err) => {
                console.error('error:', err)
            })
    }

    const closeWebcam = () => {
        videoRef.current.pause()
        videoRef.current.srcObject.getTracks()[0].stop()
        setCaptureVideo(false)
        setStream(false)
    }

    return (
        <div style={{ position: 'relative' }}>
            {/* NavBar */}
            <NavBar
                selectbar={selectbarX}
                selectbarwidth="45vw"
                headers={[
                    { name: 'Enable', color: 'white' },
                    { name: 'Train', color: 'white' },
                    { name: 'Code', color: 'black' },
                    { name: 'Run', color: 'black' },
                ]}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />
            {/* Main */}
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
            >
                <div style={{ position: 'relative' }}>
                    <img
                        style={{
                            position: 'absolute',
                            marginLeft: '10vw',
                            top: '41vh',
                            left: '20%',
                            zIndex: '-1',
                            transform: 'translate(-50%,-50%)',
                        }}
                        draggable="false"
                        src={PopupCard}
                        alt="popupCard"
                        height="320vh"
                    />
                    <div style={{ zIndex: '4' }}>
                        {!stream ? (
                            <div>
                                <img
                                    style={{
                                        position: 'absolute',
                                        top: '41vh',
                                        left: '70vw',
                                        transform: 'translate(-50%,-50%)',
                                        cursor: 'pointer',
                                    }}
                                    src={startCamera}
                                    draggable="false"
                                    alt="captureButton"
                                    onClick={startWebcam}
                                    height="36px"
                                    width="116px"
                                />
                            </div>
                        ) : (
                            <div>
                                <img
                                    style={
                                        capturBtnDsable
                                            ? {
                                                  position: 'absolute',
                                                  top: '41vh',
                                                  left: '70vw',
                                                  transform:
                                                      'translate(-50%,-50%)',
                                                  cursor: 'default',
                                                  opacity: '.5',
                                              }
                                            : {
                                                  position: 'absolute',
                                                  top: '41vh',
                                                  left: '70vw',
                                                  transform:
                                                      'translate(-50%,-50%)',
                                                  cursor: 'pointer',
                                                  opacity: '1',
                                              }
                                    }
                                    draggable="false"
                                    src={captureButton}
                                    alt="captureButton"
                                    onClick={dataGatherLoop}
                                    height="36px"
                                    width="116px"
                                />
                            </div>
                        )}
                        {captureVideo ? (
                            <div style={{ position: 'relative' }}>
                                <video
                                    style={{
                                        position: 'absolute',
                                        zIndex: '2',
                                        left: '28%',
                                        top: '41vh',
                                        transform: 'translate(-50%,-50%)',
                                        borderRadius: '10px',
                                    }}
                                    ref={videoRef}
                                    height={videoHeight}
                                />
                                <canvas
                                    id="canvas"
                                    width="320"
                                    height="1080    720240"
                                    hidden={true}
                                ></canvas>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div style={{ position: 'relative' }}>
                {captureVideo ? (
                    <div>
                        <h1
                            style={{
                                position: 'absolute',
                                bottom: '-68vh',
                                left: '28vw',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '18px',
                                color: 'grey',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <strong>{length}/10</strong>
                        </h1>
                        <p
                            style={{
                                position: 'absolute',
                                left: '28vw',
                                bottom: '-73vh',
                                transform: 'translate(-50%,-50%)',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '12px',
                                color: 'grey',
                            }}
                        >
                            Point at the face you want to capture
                        </p>
                    </div>
                ) : (
                    <p
                        style={{
                            position: 'absolute',
                            left: '30%',
                            top: '62vh',
                            transform: 'translate(-50%,-50%)',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '12px',
                            color: 'grey',
                        }}
                    >
                        Point at the face you want to capture
                    </p>
                )}
                <img
                    src={skip}
                    draggable="false"
                    alt="skip"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '83.7vh',
                        left: '95.8vw',
                        width: '52px',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    height="52px"
                    // width="100%"
                    onClick={() => {
                        setSlideDirection(false)
                        sessionStorage.setItem('slideDirection', false)
                        if (stream === true) {
                            closeWebcam()
                            history.push('/savedFaces')
                        } else {
                            history.push('/savedFaces')
                        }
                    }}
                />

                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '82.3vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        if (stream === true) {
                            closeWebcam()
                            history.push('/selectDetection')
                        } else {
                            history.push('/selectDetection')
                        }
                    }}
                />
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '49.8vh',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('uploadH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '54%',
                                    left: '45.9%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    This windows shows the camera's view, that
                                    can be used to point in the direction of the
                                    Face that needs to be trained in the app
                                    mode. You can use the camera switching to
                                    switch between front vs back camera.
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('uploadH1')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '54%',
                                    left: '59.3%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to capture your face
                                    photo's to train the app mode to be able to
                                    recognise you when running the program
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}

export default UploadImage
