import deviceVersionObj from '../../deviceVersion.json'

//PC 1.0
const flashFilesDataPCv1 = [
    // { fileName: 'bootloader', data: null, address: parseInt('0x0') },
    // { fileName: 'partition-table', data: null, address: parseInt('0x8000') },
    // { fileName: 'main', data: null, address: parseInt('0x10000') },
]

//zing 0.1
const flashFilesDataZingA = [
    { fileName: 'bootloader', data: null, address: parseInt('0x1000') },
    { fileName: 'partition-table', data: null, address: parseInt('0x8000') },
    { fileName: 'main', data: null, address: parseInt('0x10000') },
]
//zing 1.0
const flashFilesDataZingB = [
    { fileName: 'bootloader', data: null, address: parseInt('0x1000') },
    { fileName: 'partition-table', data: null, address: parseInt('0x8000') },
    { fileName: 'main', data: null, address: parseInt('0x10000') },
    { fileName: 'spiffs', data: null, address: parseInt('0x210000') },
]
const addressData = {
    'PC-V1.0': ['0x0', '0x8000', '0x10000'],
    'PC-V1.0-PY': ['0x0', '0x8000', '0x10000'],
    'ZING-V1.0': ['0x1000', '0x8000', '0x10000', '0x210000'],
    'ZING-V0.1': ['0x1000', '0x8000', '0x10000'],
}

export const getFlashFilesPCv1 = async (link, device, address) => {
    try {
        await fetch(link)
            .then((res) => res.blob())
            .then((res) => {
                res.name = 'main'
                res.index = 2

                let reader = new FileReader()
                console.log(device)
                reader.onload = ((theFile) => {
                    return (e) => {
                        device.flashFileData.push({
                            fileName: 'playcomputer.bin',
                            data: e.target.result,
                            address: parseInt(address),
                        })
                        // device.flashFileData[0].fileName = 'main'
                        // device.flashFileData[0].data = e.target.result
                        // device.flashFileData[0].address = parseInt(address)
                    }
                })(res)

                reader.readAsBinaryString(res)
            })

        // let binFileId = device.binFileId
        // flashFilesDataPCv1[0].address = parseInt(addressData['PC-V1.0'][0])
        // flashFilesDataPCv1[1].address = parseInt(addressData['PC-V1.0'][1])
        // flashFilesDataPCv1[2].address = parseInt(addressData['PC-V1.0'][2])

        console.log(flashFilesDataPCv1, 'gsk is cool')
    } catch (e) {
        console.log(`Error: ${e.message}`)
        // setScreenConsole(`Error: ${e.message}`)
    }
}
export const getFlashFilesZingB = async (link, device, address) => {
    try {
        await fetch(`${link}/bootloader.bin`)
            .then((res) => res.blob())
            .then((res) => {
                //   console.log(res);
                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingB[0].data = e.target.result
                    }
                })(res)
                reader.readAsBinaryString(res)
            })

        await fetch(`${link}/partition-table.bin`)
            .then((res) => res.blob())
            .then((res) => {
                //   console.log(res);
                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingB[1].data = e.target.result
                    }
                })(res)
                reader.readAsBinaryString(res)
            })

        await fetch(`${link}/main.bin`)
            .then((res) => res.blob())
            .then((res) => {
                res.name = 'main'
                res.index = 2

                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingB[2].data = e.target.result
                    }
                })(res)

                reader.readAsBinaryString(res)
            })

        await fetch(`${link}/spiffs.bin`)
            .then((res) => res.blob())
            .then((res) => {
                res.name = 'main'
                res.index = 2

                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingB[3].data = e.target.result
                    }
                })(res)

                reader.readAsBinaryString(res)
            })

        flashFilesDataZingB[0].address = parseInt(addressData['ZING-V1.0'][0])
        flashFilesDataZingB[1].address = parseInt(addressData['ZING-V1.0'][1])
        flashFilesDataZingB[2].address = parseInt(addressData['ZING-V1.0'][2])
        flashFilesDataZingB[3].address = parseInt(addressData['ZING-V1.0'][3])

        console.log(flashFilesDataZingB, 'gsk is cool')
    } catch (e) {
        console.log(`Error: ${e.message}`)
        // setScreenConsole(`Error: ${e.message}`)
    }
}
export const getFlashFilesZingA = async (link, device, address) => {
    try {
        await fetch(`${link}/bootloader.bin`)
            .then((res) => res.blob())
            .then((res) => {
                //   console.log(res);
                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingA[0].data = e.target.result
                    }
                })(res)
                reader.readAsBinaryString(res)
            })

        await fetch(`${link}/partition-table.bin`)
            .then((res) => res.blob())
            .then((res) => {
                //   console.log(res);
                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingA[1].data = e.target.result
                    }
                })(res)
                reader.readAsBinaryString(res)
            })

        await fetch(`${link}/main.bin`)
            .then((res) => res.blob())
            .then((res) => {
                res.name = 'main'
                res.index = 2

                let reader = new FileReader()
                reader.onload = ((theFile) => {
                    return (e) => {
                        flashFilesDataZingA[2].data = e.target.result
                    }
                })(res)

                reader.readAsBinaryString(res)
            })

        flashFilesDataZingA[0].address = parseInt(addressData['ZING-V0.1'][0])
        flashFilesDataZingA[1].address = parseInt(addressData['ZING-V0.1'][1])
        flashFilesDataZingA[2].address = parseInt(addressData['ZING-V0.1'][2])

        console.log(flashFilesDataZingA, 'gsk is cool')
    } catch (e) {
        console.log(`Error: ${e.message}`)
        // setScreenConsole(`Error: ${e.message}`)
    }
}
export const deviceLinks = [
    {
        name: 'PeeCeeB',
        versionLink:
            'https://firmware.plode.org/bin_files/pc_1.0/playcomputer/PeeCeev1.0.json',
        fileLink: 'https://firmware.plode.org/bin_files/pc_1.0b/pc_v1.0.03b',
        version: '1.0',
        flashFileData: flashFilesDataPCv1,
        flashFile: getFlashFilesPCv1,
        address: '',
    },
    {
        name: 'PeeCeeA',
        versionLink:
            'https://firmware.plode.org/bin_files/PC_v0.1/playcomputer/PeeCee0.1.json',
        fileLink: '',
        version: '0.1',
        flashFileData: '',
        flashFile: '',
        address: '',
    },
    {
        name: 'ZingA',
        versionLink:
            'https://firmware.plode.org/bin_files/zing_0.1/zing/zing0.1.json',
        fileLink: 'https://zing.plode.org/bin/ZING-V0.1',
        version: '0.1.10',
        flashFileData: flashFilesDataZingA,
        flashFile: getFlashFilesZingA,
        address: '',
    },
    {
        name: 'ZingB',
        versionLink:
            'https://firmware.plode.org/bin_files/zing_1.0/zing1.0.json',
        fileLink: 'https://zing.plode.org/bin/ZING-V1.0',
        version: '1.0.01',
        flashFileData: flashFilesDataZingB,
        flashFile: getFlashFilesZingB,
        address: '',
    },
]

export const fetchVersion = async (link, device = '', version = '') => {
    try {
        const res = await fetch(link)
        const data = await res.json()
        console.log(data, device, version)
        // const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
        //https://embedded.plode.org/bins/production/playcomputer/changelog.json

        if (device == 'Playcomputer') {
            let path = data[device][version].path
            let latestVersion = data[device][version].version
            let deviceName = path.split('production/')[1]
            const res2 = await fetch(
                `https://embedded.plode.org/bins/production/${deviceName}/changelog.json`
            )
            const changeLogs = await res2.json()
            console.log(changeLogs)
            let dataArr = Object.keys(changeLogs)
            dataArr = dataArr.filter((data) => !data.includes('micropython'))

            return {
                latestVersion,
                location:
                    version[2] == 0
                        ? changeLogs[dataArr[dataArr.length - 2]].location
                        : changeLogs[dataArr[dataArr.length - 1]].location,
            }
        } else {
            // console.log()
            return { latestVersion: data[device][version].version }
        }
    } catch (err) {
        console.log('error in fetch version', err)
        return { latestVersion: deviceVersionObj[device][version].version }
        // return { latestVersion: data[device][version].version }
    }
    // } else {
    //     let dataArr = Object.keys(data)
    //     dataArr = dataArr.filter((data) => !data.includes('MicroPython'))
    //     console.log('dataARRRRRR', dataArr)
    //     return { latestVersion: dataArr[dataArr.length - 1], location: '' }
    // }
}
