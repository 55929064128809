import React, { Component } from 'react'
import LoopSliderBox from '../../../../../ReusableComponents/NewSlider/LoopSliderBox'

import './loop.css'
let count = []
for (let i = 0; i < 1000; i++)
    count[i] = parseInt(sessionStorage.getItem(`loop${i}`)) || 0
class LoopPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            times: count[this.props.check],
        }
    }
    componentWillUnmount() {
        count[this.props.check] = this.state.times
    }

    render() {
        sessionStorage.setItem(`loop${this.props.check}`, this.state.times)
        return (
            <div className="outertabDiv-loop">
                <LoopSliderBox
                    style={{ marginTop: '20%' }}
                    min={0}
                    max={255}
                    value={this.state.times || 1}
                    onChange={(e) => {
                        this.setState({ times: e.target.value })
                    }}
                />
            </div>
        )
    }
}

export default LoopPanel
