import AdvanceSettingStyle from './AdvanceSetting.module.css'
import renderImage from '../../source/importImg'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import packageJson from '../../../package.json'
import MainHeader from '../ReusableComponents/Header/MainHeader'
// import { getS3Version } from '../ReusableComponents/ByteTransfer/getVersion'
import { connect } from 'react-redux'

function AboutApplication(props) {
    let history = useHistory()
    const [isHelp, setHelp] = useState(false)
    const [firmwareVersion, setFirmwareVersion] = useState('')
    // const [deviceName, setdeviceName] = useState('')
    const isConnectRef = useRef(props.isConnected)
    const versionRef = useRef(false)

    const grtDeviceName = (firmwareVersion) => {
        let result = firmwareVersion.match(/^([A-Za-z]+)v?([\d.]+)/)
        if (result) {
            switch (result[1]) {
                case 'CW':
                    return 'Crawl-e'
                case 'ZG':
                    if (result[2]?.[0] == '0') return 'Zing Alpha'
                    else return 'Zing Beta'
                case 'RB':
                    return 'Roboki'
                case 'KW':
                    return 'Klaw-b'
                case 'PCv':
                case 'PC':
                    if (result[2]?.[0] == '0') return 'PeeCee Alpha'
                    else return 'PeeCee Beta'
                case 'AT':
                    return 'ATR'
                default:
                    return 'PeeCee'
            }
        } else return ''
    }

    useEffect(() => {
        const getVersion = async () => {
            while (true) {
                if (isConnectRef.current && !versionRef.current) {
                    props.worker.postMessage({
                        type: 'write',
                        value: 'WHO',
                    })
                    await new Promise((reslove) => setTimeout(reslove, 300))
                } else {
                    versionRef.current = false
                    break
                }
            }
        }
        if (props.isConnected) {
            isConnectRef.current = true
            getVersion()
        } else {
            isConnectRef.current = false
        }
    }, [props.isConnected])

    useEffect(() => {
        const messageHandel = (e) => {
            console.log('NNNNNNN', e.data.value)
            if (e.data.type === 'read') {
                const garbageRemovalRegex = /[\u0000\x00]+|[^a-zA-Z0-9.v]+/g
                const cleanedVersion = e.data.value.replace(
                    garbageRemovalRegex,
                    ''
                )
                // let match = cleanedVersion.match(/^([A-Za-z]+)v?([\d.]+)/)

                // console.log('VVVVVV', match)
                // if (match) {
                const allowedPrefixes = [
                    'CW',
                    'ZG',
                    'RB',
                    'KW',
                    'PCv',
                    'PC',
                    'AT',
                ]

                const isValidPrefix = allowedPrefixes.some((allowed) =>
                    cleanedVersion.startsWith(allowed)
                )
                if (isValidPrefix) {
                    setFirmwareVersion(cleanedVersion)
                    versionRef.current = true
                }
            }
        }
        props.worker.addEventListener('message', messageHandel)
        return () => {
            props.worker.removeEventListener('message', messageHandel)
            versionRef.current = true
        }
    }, [])
    return (
        <div className={AdvanceSettingStyle.Main_Select}>
            <MainHeader
                title="About"
                titlePosition="left"
                help_btnPosition="right"
                // helper={<VisualPrgm />}
                isGoBack={false}
                showBluetoothBtn={true}
                goBack={() => {
                    history.push('/advanceSettingv1.0')
                }}
            />

            <div className={AdvanceSettingStyle.body}>
                <div
                    className={AdvanceSettingStyle.inner_body}
                    // style={{ backgroundColor: 'red' }}
                >
                    <img
                        src={renderImage('yuduRobotics')}
                        width="40%"
                        alt="Yudu Robotics"
                        style={{ marginBottom: '10vh' }}
                    />
                    <p>App Version : V{packageJson.version}</p>
                    {firmwareVersion && (
                        <p>
                            Firmware {grtDeviceName(firmwareVersion)} Version :{' '}
                            {firmwareVersion}
                        </p>
                    )}
                </div>
            </div>

            <div className={AdvanceSettingStyle.footer}>
                <p className={AdvanceSettingStyle.copyRightText}>
                    <span style={{ fontSize: '1.1rem' }}>&copy;</span>
                    {'  '}
                    PLAYFULLY CRAFTED BY YUDU ROBOTICS
                </p>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        webserialPort: state.webSerial,
        worker: state.worker,
        device: state.device,
        deviceMismatch: state.deviceMismatch,
        isConnected: state.webSerial.isConnected,
    }
}

export default connect(mapStateToProps)(AboutApplication)
