import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import renderImage from '../../../../source/importImg'
import '../RoboticArm/RoboticArm.css'

import joystickStyle from '../../../../Components/play/Remote/humanoid-hexapod/Remote/component/joystick/joystick.module.css'
///Remote/component/joystick/joystick.module.css' //'./joystick.module.css'

import upBtn from '../../../../Assets/humanoid/Up.png' //'../../../../../../../Assets/humanoid/Up.png'
import upDarkBtn from '../../../../Assets/humanoid/up_dark.png'
import downBtn from '../../../../Assets/humanoid/down.png'
import downDarkBtn from '../../../../Assets/humanoid/down_dark.png'
import leftBtn from '../../../../Assets/humanoid/left.png'
import leftDarkBtn from '../../../../Assets/humanoid/left_dark.png'
import rightBtn from '../../../../Assets/humanoid/right.png'
import rightDarkBtn from '../../../../Assets/humanoid/right_dark.png'
import { ColorRing } from 'react-loader-spinner'
// import midBtn from '../../../../Assets/humanoid/mid.png'
// import midDarkBtn from '../../../../Assets/humanoid/mid.png'

let initalRender = true
let endpointOut_ = 2
let loop = false

const IMAGES = {
    upBtn,
    upDarkBtn,
    downBtn,
    downDarkBtn,
    leftBtn,
    leftDarkBtn,
    rightBtn,
    rightDarkBtn,
    // midBtn,
    // midDarkBtn,
}
const CONTROLS = ['up', 'down', 'left', 'right']
const CONTROLSRIGHT = ['goUp', 'goDown']
const ACTIONS = {
    up: 'MoveForward',
    down: 'MoveBackward',
    left: 'MoveLeft',
    right: 'MoveRight',
    // mid: 'Attention',
    goUp: 'GoUp',
    goDown: 'GoDown',
}

const ORIGINAL_ACTION_STATE = {
    isMoveRight: false,
    isMoveLeft: false,
    isMoveBackward: false,
    isMoveForward: false,
    isGoUp: false,
    isGoDown: false,
}

const robokiActionByets = {
    MoveForward: [82, 66, 100, 0, 100, 0, 0, 0], // R -> 82, B -> 66
    MoveBackward: [82, 66, 0, 100, 0, 100, 0, 0],
    MoveLeft: [82, 66, 100, 0, 0, 100, 0, 0],
    MoveRight: [82, 66, 0, 100, 100, 0, 0, 0],
    GoUp: [82, 66, 0, 0, 0, 0, 100, 0],
    GoDown: [82, 66, 0, 0, 0, 0, 0, 100],
}

function RoboticArmPlay(props) {
    const history = useHistory()
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const [continuous, setContinuous] = useState(false)
    const [count, setCount] = useState(1)
    const [loader, setLoader] = useState(false)

    const [id, setID] = useState(null)
    const [actionState, setActionState] = useState(ORIGINAL_ACTION_STATE)
    const gobackUrl = () => {
        history.goBack()
    }

    useEffect(() => {
        if (continuous) {
            const time = setInterval(() => {
                actionBytes(id)
            }, 100)
            sessionStorage.setItem('readSetIntervalId', JSON.stringify(time))
        } else {
            clearInterval(
                JSON.parse(sessionStorage.getItem('readSetIntervalId'))
            )
        }
    }, [continuous])

    //peecee action byets generat
    const actionBytes = (id) => {
        const bytes = Array(6).fill(0)
        bytes.unshift('K'.charCodeAt(), 'W'.charCodeAt())
        switch (id) {
            case 'B_R': {
                bytes[2] = 'R'.charCodeAt()
                break
            }
            case 'SR1_UP': {
                bytes[3] = 'R'.charCodeAt()
                break
            }
            case 'SR2_UP': {
                bytes[4] = 'R'.charCodeAt()
                break
            }
            case 'SR3_UP': {
                bytes[5] = 'R'.charCodeAt()
                break
            }
            case 'GB_R': {
                bytes[6] = 'R'.charCodeAt()
                break
            }
            case 'pick': {
                bytes[7] = 'R'.charCodeAt()
                break
            }
            case 'B_L': {
                bytes[2] = 'L'.charCodeAt()
                break
            }
            case 'SR1_DN': {
                bytes[3] = 'L'.charCodeAt()
                break
            }
            case 'SR2_DN': {
                bytes[4] = 'L'.charCodeAt()
                break
            }
            case 'SR3_DN': {
                bytes[5] = 'L'.charCodeAt()
                break
            }
            case 'GB_L': {
                bytes[6] = 'L'.charCodeAt()
                break
            }
            case 'drop': {
                bytes[7] = 'L'.charCodeAt()
                break
            }
        }
        writePort(bytes)
    }

    //roboki action byets sgenerat
    const robokiAction = (actionname) => {
        console.log('GGGGGGGG', actionname)
        if (actionState['is' + actionname]) {
            let newActionState = { ...actionState }
            newActionState['is' + actionname] = false
            setActionState(newActionState)
            writePort([82, 66, 0, 0, 0, 0, 0, 0])
        } else {
            let newActionState = { ...actionState }
            newActionState['is' + actionname] = true
            setActionState(newActionState)
            writePort(robokiActionByets[actionname])
        }
    }
    const robokiActionStop = (actionname) => {
        if (actionState['is' + actionname]) {
            let newActionState = { ...actionState }
            newActionState['is' + actionname] = false
            setActionState(newActionState)
            writePort([82, 66, 0, 0, 0, 0, 0, 0])
        }
    }

    const writePort = async (data) => {
        console.log(data)
        if (props.webSerial.port !== undefined) {
            if (
                props.webSerial.isConnected &&
                props.webSerial.port !== undefined
            ) {
                props.worker.postMessage({
                    type: 'writeArray',
                    value: data,
                })
            }
        }
    }

    //get image url
    const getImgUrl = () => {
        if (
            sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            connectedDevice === 'Ace'
        ) {
            return renderImage('Arm')
        } else if (connectedDevice === 'Roboki') {
            return renderImage('Roboki')
        } else {
            return renderImage('Arm')
        }
    }

    const sendPlayBytes = () => {
        const PLAY = [
            'P'.charCodeAt(),
            'L'.charCodeAt(),
            'A'.charCodeAt(),
            'Y'.charCodeAt(),
        ]
        const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
        const playBytes = setTimeout(() => {
            writePort(PLAY)
            clearTimeout(playBytes)
        }, 250)
        const clearPin = setTimeout(() => {
            writePort(M8)
            clearTimeout(clearPin)
        }, 350)
    }
    useEffect( () => {
        if (
            sessionStorage.getItem('isDeviceConnected') === 'true' &&
            count === 0 && sessionStorage.getItem('connectedDevice') !== 'Roboki'
        ) {
            sendPlayBytes()
        }
        console.log('loader:')

        if (
            sessionStorage.getItem('isDeviceConnected') === 'true' &&
            count === 0 && sessionStorage.getItem('connectedDevice') === 'Roboki'
        ) {
            
            setLoader(true)
            setTimeout(() => {
                console.log('loader:',loader)
                sendPlayBytes()
                setLoader(false)
            }, 6000)
            // sendPlayBytes()
            setCount(1)
            
        }
        if (sessionStorage.getItem('isDeviceConnected') === 'false') {
            setCount(0)
        }
    },[sessionStorage.getItem('isDeviceConnected')])

    return (
        <div>
            <img
                className="Back_BTN"
                src={renderImage('backBtn')}
                onClick={gobackUrl}
            />
            <div
                className="Arm_Body"
                style={{
                    gridTemplateColumns:
                        connectedDevice === 'Roboki'
                            ? '35% 30% 30% 5%'
                            : // ? '50% 37%'
                              '35% 35% 30%',
                              
                }}
            >
                {/* Left  */}
                <div className="Arm_Left" >
                    <img
                        src={getImgUrl()}
                        style={{ opacity: count ===1 && sessionStorage.getItem('isDeviceConnected') && !loader ? '100%':'20%',}}
                        width={connectedDevice === 'Roboki' ? '290' : '350px'}
                    />
                </div>

                {/* Main  */}
                {connectedDevice !== 'Roboki' && (
                    <div className="Arm_Main">
                        <div className="Main_Img">
                            <img
                                id="SR1_UP"
                                src={renderImage(
                                    id === 'SR1_UP' ? 'upActive' : 'upInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                        </div>
                        <div className="Main_Img">
                            <img
                                id="SR2_UP"
                                src={renderImage(
                                    id === 'SR2_UP' ? 'upActive' : 'upInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                        </div>
                        <div className="Main_Img">
                            <img
                                id="SR3_UP"
                                src={renderImage(
                                    id === 'SR3_UP' ? 'upActive' : 'upInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                id="SR1_DN"
                                src={renderImage(
                                    id === 'SR1_DN'
                                        ? 'downActive'
                                        : 'downInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                            <p style={{ display: 'block' }}>Servo 1</p>
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                id="SR2_DN"
                                src={renderImage(
                                    id === 'SR2_DN'
                                        ? 'downActive'
                                        : 'downInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                            <p style={{ display: 'block' }}>Servo 2</p>
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                id="SR3_DN"
                                src={renderImage(
                                    id === 'SR3_DN'
                                        ? 'downActive'
                                        : 'downInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                            <p style={{ display: 'block' }}>Servo 3</p>
                        </div>
                    </div>
                )}

                {/* Right  */}
                {connectedDevice !== 'Roboki' && (
                    <div className="Arm_Right">
                        {' '}
                        <div className="Main_Img_Txt">
                            <img
                                id="pick"
                                src={renderImage('pick')}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setContinuous(false)
                                }}
                            />
                            <p
                                style={{
                                    display: 'block',
                                }}
                            >
                                Pick
                            </p>
                        </div>
                        <div
                            className="Main_Img_Txt"
                            style={{
                                justifySelf: 'start',
                            }}
                        >
                            <img
                                id="drop"
                                src={renderImage('drop')}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setContinuous(false)
                                }}
                            />
                            <p
                                style={{
                                    display: 'block',
                                }}
                            >
                                Drop
                            </p>
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                style={{
                                    marginLeft: '40%',
                                }}
                                id="GB_R"
                                src={renderImage(
                                    id === 'GB_R'
                                        ? 'leftActive'
                                        : 'leftInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    marginLeft: '80%',
                                    width: '100%',
                                }}
                            >
                                Grip base
                            </p>
                        </div>
                        <div>
                            {' '}
                            <img
                                id="GB_L"
                                style={{
                                    marginLeft: '-10%',
                                    cursor: 'pointer',
                                }}
                                src={renderImage(
                                    id === 'GB_L'
                                        ? 'rightActive'
                                        : 'rightInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                id="B_R"
                                style={{
                                    marginLeft: '30%',
                                }}
                                src={renderImage(
                                    id === 'B_R' ? 'leftActive' : 'leftInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    marginLeft: '90%',
                                    width: '100%',
                                }}
                            >
                                Base
                            </p>
                        </div>
                        <div>
                            {' '}
                            <img
                                style={{
                                    marginLeft: '-10%',
                                    cursor: 'pointer',
                                }}
                                id="B_L"
                                src={renderImage(
                                    id === 'B_L'
                                        ? 'rightActive'
                                        : 'rightInActive'
                                )}
                                onMouseDown={(event) => {
                                    setID(event.target.id)
                                    setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    setID(null)
                                    setContinuous(false)
                                }}
                            />
                        </div>
                    </div>
                )}

                {/* Roboki arm mood left right up down controler */}
                {connectedDevice === 'Roboki' && (
                    <>
                    {loader && (
                        <div
                        style={{
                            position: 'absolute',
                            zIndex: '1',
                            left: '47%',
                            top:'45%'
                        }}
                    >
                        <ColorRing />
                    </div>
                     ) }
                    <div
                        className={joystickStyle['joystick-image']}
                        style={{
                            // height: '74%',
                            // width: '95%',
                            height: '68%',
                            width: '95%',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            opacity: count ===1 && sessionStorage.getItem('isDeviceConnected') && !loader ? '100%':'20%'

                           
                        }}
                    >
                        {CONTROLS.map((control) => (
                            <img
                                key={control}
                                className={joystickStyle[`${control}-button`]}
                                // style={isEnabled ? null : disabledJoyStick}
                                src={ count===0 || loader ? IMAGES[`${control}Btn`] :
                                    actionState[`is${ACTIONS[control]}`]
                                        ? IMAGES[`${control}DarkBtn`]
                                        : IMAGES[`${control}Btn`]
                                }
                                onMouseDown={() => {
                                    //     sessionStorage.setItem("isClicked",true)
                                    if(count===0 || loader)
                                        return
                                    robokiAction(ACTIONS[control])
                                }}
                                onMouseUp={() => {
                                    //     sessionStorage.setItem('isClicked', false)
                                    //     setContinousAction(() => {})
                                    if(count===0 || loader)
                                            return
                                    robokiActionStop(ACTIONS[control])
                                }}
                                // onClick={() => handleAction(ACTIONS[control])}
                                // style={{
                                //     opacity: count===1 ? '100%':'30%',
                                // }}
                                
                            />
                        ))}
                    </div>
                    </>
                )}

                {connectedDevice == 'Roboki' && (
                    <div
                        className={`${joystickStyle['joystick-image']} hidden`}  //   !Remove hidden to unhide
                        style={{
                            height: '53%',
                            width: '95%',
                            alignSelf: 'center',
                            justifySelf: 'center',
                        }}
                    >
                        {CONTROLSRIGHT.map((control) => {
                            let buttonName = control.substring(2).toLowerCase()
                            return (
                                <img
                                    key={control}
                                    className={
                                        joystickStyle[`${buttonName}-button`]
                                    }
                                    // style={isEnabled ? null : disabledJoyStick}
                                    src={
                                        actionState[`is${ACTIONS[control]}`]
                                            ? IMAGES[`${buttonName}DarkBtn`]
                                            : IMAGES[`${buttonName}Btn`]
                                    }
                                    onMouseDown={() => {
                                        //     sessionStorage.setItem("isClicked",true)
                                        robokiAction(ACTIONS[control])
                                    }}
                                    onMouseUp={() => {
                                        //     sessionStorage.setItem('isClicked', false)
                                        //     setContinousAction(() => {})
                                        robokiActionStop(ACTIONS[control])
                                    }}
                                    // onClick={() => handleAction(ACTIONS[control])}
                                />
                            )
                        })}
                    </div>
                )}

                {connectedDevice == 'Roboki' && <div></div>}
            </div>
        </div>


    )
}
// export default RemoteSection;

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(RoboticArmPlay)
