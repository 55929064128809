import React, { useEffect, useState, version } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { sendBytes } from '../ReusableComponents/ByteTransfer'
import renderImage from '../../source/importImg'
import ImgSlider from '../ReusableComponents/ImgSlider/ImgSlider'
import cacheAssets from '../../utils/cacheAssets'
import './PlayMode.css'
import MainHeader from '../ReusableComponents/Header/MainHeader'
import unicodeToChar from '../../utils/unicodeToChar'

function Play(props) {
    let history = useHistory()
    let version = sessionStorage.getItem('deviceVersion')
    // let zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    const [isShow_comingSoon_text, setIsShow_comingSoon_text] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )

    const gobackUrl = () => {
        history.push('/selection')
    }
    const [isHelp, setHelp] = useState(false)
    const [isUsb, setUsb] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }
    const divstyle = {
        height: '90%',
        width: '90%',
        marginTop: '5%',
        marginLeft: '5%',
        cursor: 'pointer',
    }

    const RemoteBtn = () => {
        sessionStorage.removeItem('slideDirection')
        if (connectedDevice === 'Ace') {
            if (version?.startsWith('1')) {
                history.push('/v1/remote-Ace')
            } else {
                history.push('/remote-Ace')
            }
        } else if (connectedDevice === 'Roboki') {
            history.push('/v1/remote-Ace')
        } else if (['Humanoid', 'Hexapod'].includes(connectedDevice))
            history.push(`/remote-${connectedDevice.toLowerCase()}`)
        else if (connectedDevice == 'Klaw') history.push('/remote-Klaw')
    }
    const SpeechBtn = () => {
        if (connectedDevice == 'Klaw') {
            setIsShow_comingSoon_text(true)
            setTimeout(() => setIsShow_comingSoon_text(false), 2000)
            return
        }

        sessionStorage.removeItem('slideDirection')
        console.log('SPEECH CLICKED')
        history.push('/speak')
    }
    const MusicBtn = () => {
        if (['Roboki', 'Klaw'].includes(connectedDevice)) {
            setIsShow_comingSoon_text(true)
            setTimeout(() => setIsShow_comingSoon_text(false), 2000)

            return
        }
        sessionStorage.removeItem('slideDirection')
        history.push('/music')
    }
    const CameraBtn = () => {
        if (['Roboki', 'Klaw'].includes(connectedDevice)) {
            setIsShow_comingSoon_text(true)
            setTimeout(() => setIsShow_comingSoon_text(false), 2000)

            return
        }
        sessionStorage.removeItem('slideDirection')
        history.push('/camera')
    }

    // Caching the assets (images) of the next pages
    useEffect(() => {
        const handleBytesSending = async () => {
            let assetsToBeCached = [
                renderImage('disco_Ac'),
                renderImage('gesture_Ac'),
                renderImage('PcpianoAc'),
                renderImage('PianokeysAc'),
                renderImage('smile1_Active'),
                renderImage('smile2_Active'),
                renderImage('smile3_Active'),
                renderImage('smile4_Active'),
                renderImage('talk_Ac'),
                renderImage('reconnect'),
                renderImage('leftArrow'),
                renderImage('rightArrow'),
                renderImage('add3xIA'),
                renderImage('devices'),
                renderImage('disco_IA'),
                renderImage('gesture_IA'),
                renderImage('helpBtnInActive'),
                renderImage('add3xIA'),
                renderImage('devices'),
                renderImage('disco_IA'),
                renderImage('gesture_IA'),
                renderImage('helpBtnInActive'),

                // Music Screen
                renderImage('PcpianoIA'),
                renderImage('PianokeysIA'),
                renderImage('remove3xIA'),
                renderImage('skip'),
                renderImage('smile1'),
                renderImage('smile2'),
                renderImage('smile3'),
                renderImage('smile4'),
                renderImage('talk_IA'),
                renderImage('PianokeysIA'),
                renderImage('AddInfo'),
                renderImage('Connect'),
                renderImage('PlyComp'),
                renderImage('Ply'),
                renderImage('Prog'),
                renderImage('Bild'),
                renderImage('Remt'),
                renderImage('Peech'),
                renderImage('Msic'),
                renderImage('Camr'),
                renderImage('Remt1'),
                renderImage('Remt2'),
                renderImage('Remt3'),
                renderImage('Remt4'),
                renderImage('Remt5'),
                renderImage('Remt6'),
                renderImage('Musc1'),
                renderImage('Musc2'),
                renderImage('Musc3'),
                renderImage('Cam1'),
                renderImage('Cam2'),
                renderImage('bisoft_logo'),
                renderImage('Computer'),
                renderImage('leading_page'),
                renderImage('Pc'),
                renderImage('PC'),
                renderImage('PCimg'),
                renderImage('FourteethIA_Svgsc3'),
                renderImage('Blueslider_Svg'),
                renderImage('buzzerAc_Svg'),
                renderImage('buzzerIA_Svg'),
                renderImage('buzzer_bg_Svg'),
                renderImage('Camera_Svg'),
                renderImage('Disconnected_Svg'),

                //REMOTE Screen
                renderImage('eyeAc_Svg'),
                renderImage('eyeIA_Svg'),
                renderImage('eye_bg_Svg'),
                renderImage('Freq_Svg'),
                renderImage('Greenslider_Svg'),
                renderImage('sliderIA'),
                renderImage('MusicCard_Svg'),
                renderImage('Pianosmall_Svg'),
                renderImage('Pianotogglebg_Svg'),
                renderImage('PlayCard_Svg'),
                renderImage('popup_Svg'),
                renderImage('Popup'),
                renderImage('Redslider_Svg'),
                renderImage('RemoteCard_Svg'),

                //SPEECH Screen
                renderImage('Soundwaves_Svg'),
                renderImage('SpeechAc_Svg'),
                renderImage('SpeechCard_Svg'),
                renderImage('SpeechIA_Svg'),
                renderImage('teethAc_Svg'),
                renderImage('teethIA_Svg'),
                renderImage('teeth_bg_Svg'),
                renderImage('bisoft_logo1'),
            ]
            cacheAssets(assetsToBeCached)
            // const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
            // if (
            //     (sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            //         ['Ace'].includes(connectedDevice)) ||
            //     (version?.startsWith('2') &&
            //         ['Humanoid'].includes(connectedDevice))||connectedDevice=="Hexapod"
            // )
            //     return
            // writePort(PLAY)
            // setTimeout(() => {
            //     writePort(M8)
            // }, 200)
        }
        handleBytesSending()
    }, [])
    async function writePort(data) {
        if (
            (version?.startsWith('1') && ['Ace'].includes(connectedDevice)) ||
            (version?.startsWith('2') &&
                ['Humanoid', 'Hexapod'].includes(connectedDevice)) ||
            ['Roboki'].includes(connectedDevice)
        ) {
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
            console.log('PlayBytes Sent')
        } else {
            console.log('sending...', data)
            await sendBytes(data, props.webSerial.port)
        }
    }

    return (
        <div className="Main_Play">
            <div>
                <MainHeader
                    title=" Play"
                    titlePosition="left"
                    help_btnPosition="right"
                    helper={<ImgSlider />}
                    isGoBack={false}
                    goBack={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/selection')
                    }}
                />
                {/* <img alt=''
                    className="Back_Btn"
                    src={renderImage('backBtn')}
                    onClick={gobackUrl}
                ></img>
                <img
                    className="Play_Card"
                    src={renderImage('PlayCard_Svg')}
                ></img>
                <h1 className="Play_txxt">Play</h1>
                {isHelp == false ? (
                    <img
                        className="Help_Button"
                        src={renderImage('helpBtnInActive')}
                        onClick={handleHelpBtn}
                    ></img>
                ) : (
                    <div className="IMG-slide">
                        <ImgSlider /><ImgSlider />
                    </div>
                )}
                {isHelp ? (
                    <img
                        className="help_close"
                        src={renderImage('clos')}
                        onClick={handleHelpBtn}
                    ></img>
                ) : null} */}
            </div>
            <div
                className={`${slideDirection === 'true' ? 'slide-right' : ''}`}
                style={{ height: '100%', position: 'relative' }}
            >
                {' '}
                <div
                    className="Play_Functions"
                    style={
                        connectedDevice === 'Roboki'
                            ? { gridTemplateColumns: '32.5% 16% 3% 16% 32.5% ' }
                            : {}
                    }
                >
                    <div></div>
                    {isHelp ? (
                        <div style={{ zIndex: '-10' }}>
                            <Link to="/remote">
                                <img
                                    alt=""
                                    className="Remote_Card"
                                    src={renderImage('RemoteCard_Svg')}
                                ></img>

                                <h1 className="Remote_txt">Joystick </h1>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <div style={divstyle} onClick={RemoteBtn}></div>
                            <img
                                alt=""
                                className="Remote_Card"
                                src={renderImage('RemoteCard_Svg')}
                                style={{
                                    left:
                                        connectedDevice === 'Roboki'
                                            ? '40%'
                                            : '21%',
                                }}
                            ></img>

                            <h1
                                className="Remote_txt"
                                style={{
                                    left:
                                        connectedDevice === 'Roboki'
                                            ? '38%'
                                            : '21%',
                                }}
                                onClick={RemoteBtn}
                            >
                                Joystick
                                {/* {version === '1.0.03' ? 'Joystick' : ' Remote'} */}
                            </h1>
                        </div>
                    )}

                    <div></div>

                    {isHelp ? (
                        <div style={{ zIndex: '-10' }}>
                            <Link to="/speak">
                                <img
                                    alt=""
                                    className="Speech_Card"
                                    src={renderImage('SpeechCard_Svg')}
                                ></img>

                                <h1 className="Speech_txt">Speech</h1>
                            </Link>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    ...divstyle,
                                    cursor: ['Klaw'].includes(connectedDevice)
                                        ? 'default'
                                        : 'pointer',
                                }}
                                onClick={SpeechBtn}
                            ></div>

                            <img
                                alt=""
                                className="Speech_Card"
                                style={{
                                    left:
                                        connectedDevice === 'Roboki'
                                            ? '60%'
                                            : '40%',
                                    opacity:
                                        connectedDevice === 'Klaw'
                                            ? '0.5'
                                            : '1',
                                }}
                                src={renderImage('SpeechCard_Svg')}
                            ></img>

                            <h1
                                className="Speech_txt"
                                style={{
                                    left:
                                        connectedDevice === 'Roboki'
                                            ? '58%'
                                            : '38%',
                                    opacity:
                                        connectedDevice === 'Klaw'
                                            ? '0.5'
                                            : '1',
                                    cursor: ['Klaw'].includes(connectedDevice)
                                        ? 'default'
                                        : 'pointer',
                                }}
                                onClick={SpeechBtn}
                            >
                                Speech
                            </h1>
                        </div>
                    )}
                    <div></div>

                    {
                        // connectedDevice === 'Hexapod' ? (
                        //     <div style={{ opacity: 0.5 }}>
                        //         <div style={divstyle}></div>
                        //         <img
                        //             alt=""
                        //             className="Music_Card"
                        //             src={renderImage('MusicCard_Svg')}
                        //         ></img>

                        //         <h1 className="Music_txt">Music</h1>
                        //     </div>
                        // ) :
                        isHelp ? (
                            <div style={{ zIndex: '-10' }}>
                                <Link to="/music">
                                    <img
                                        alt=""
                                        className="Music_Card"
                                        src={renderImage('MusicCard_Svg')}
                                    ></img>

                                    <h1 className="Music_txt">Music</h1>
                                </Link>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display:
                                        connectedDevice === 'Roboki'
                                            ? 'none'
                                            : 'block',
                                }}
                            >
                                <div
                                    style={{
                                        ...divstyle,
                                        cursor: ['Roboki', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                            ? 'default'
                                            : 'pointer',
                                    }}
                                    onClick={MusicBtn}
                                ></div>
                                <img
                                    alt=""
                                    className="Music_Card"
                                    src={renderImage('MusicCard_Svg')}
                                    style={
                                        ['Roboki', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                            ? { opacity: '0.5' }
                                            : {}
                                    }
                                ></img>

                                <h1
                                    className="Music_txt"
                                    onClick={MusicBtn}
                                    style={
                                        ['Roboki', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                            ? {
                                                  opacity: '0.5',
                                                  cursor: 'default',
                                              }
                                            : {}
                                    }
                                >
                                    Music
                                </h1>
                            </div>
                        )
                    }

                    <div></div>

                    {
                        // connectedDevice === 'Hexapod' ? (
                        //     <div style={{ opacity: 0.5 }}>
                        //         <div style={divstyle}></div>
                        //         <img
                        //             alt=""
                        //             className="Camera_Card"
                        //             src={renderImage('Camera_Svg')}
                        //         ></img>

                        //         <h1 className="Camera_txt">Camera</h1>
                        //     </div>
                        // ) :
                        isHelp ? (
                            <div style={{ zIndex: '-10' }}>
                                <Link to="/camera">
                                    <img
                                        alt=""
                                        className="Camera_Card"
                                        src={renderImage('Camera_Svg')}
                                    ></img>

                                    <h1 className="Camera_txt">Camera</h1>
                                </Link>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display:
                                        connectedDevice === 'Roboki'
                                            ? 'none'
                                            : 'block',
                                }}
                            >
                                <div
                                    style={{
                                        ...divstyle,
                                        cursor: ['Roboki', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                            ? 'default'
                                            : 'pointer',
                                    }}
                                    onClick={CameraBtn}
                                ></div>
                                <img
                                    alt=""
                                    className="Camera_Card"
                                    src={renderImage('Camera_Svg')}
                                    style={
                                        ['roboki', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                            ? { opacity: '0.5' }
                                            : {}
                                    }
                                ></img>

                                <h1
                                    className="Camera_txt"
                                    onClick={CameraBtn}
                                    style={
                                        ['Roboki', 'Klaw'].includes(
                                            connectedDevice
                                        )
                                            ? {
                                                  opacity: '0.5',
                                                  cursor: 'default',
                                              }
                                            : {}
                                    }
                                >
                                    Camera
                                </h1>
                            </div>
                        )
                    }

                    <div></div>

                    <div></div>
                </div>
                {/* <p className="comingSoon_text">
                    The feature is not currently available for this device.
                    Coming soon.
                </p> */}
            </div>
            <div></div>

            {isShow_comingSoon_text && (
                <p className="comingSoon_text">
                    The feature is not currently available for this device.
                    Coming soon.
                </p>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        webSerial: state.webSerial,
        worker: state.worker,
    }
}

export default connect(mapStateToProps)(Play)
