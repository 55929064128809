if (sessionStorage.getItem('graphPlotStorage') != null) {
    var graphPlotStorage = sessionStorage.getItem('graphPlotStorage')
    graphPlotStorage = JSON.parse(graphPlotStorage)
} else {
    var portsData = [
        {
            key: 'A',
            ports: [
                {
                    portName: 'A1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#E25432',
                },
                {
                    portName: 'A2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#B0D745',
                },
            ],
        },

        {
            key: 'B',
            ports: [
                {
                    portName: 'B1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#23DE93',
                },
                {
                    portName: 'B2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#3A3A3A',
                },
            ],
        },

        {
            key: 'C',
            ports: [
                {
                    portName: 'C1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#0ACEF7',
                },
                {
                    portName: 'C2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#5061A8',
                },
            ],
        },

        {
            key: 'D',
            ports: [
                {
                    portName: 'D1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#0E3C11',
                },
                {
                    portName: 'D2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#DD1624',
                },
            ],
        },

        {
            key: 'E',
            ports: [
                {
                    portName: 'E1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#F79B47',
                },
                {
                    portName: 'E2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#F666D0',
                },
            ],
        },

        {
            key: 'F',
            ports: [
                {
                    portName: 'F1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#3D5B53',
                },
                {
                    portName: 'F2',
                    isActive: false,
                    isDigital: false,
                    isColor: 'black',
                },
            ],
        },
    ]

    var graphPlotStorage = {
        counter: [],
        Index: [],
        time: { h: 0, m: 0, s: 1, ms: 0 },
        internalaccessories: {
            Four_in_one_sensor: {
                isDistanceSensors: false,
                isGestureSensor: false,
                isLightSensor: false,
                isColorSensor: false,
            },

            // input
            isTouchZero: false,
            isTouchOne: false,
            isTouchTwo: false,
            isTouchThree: false,
            isMic: false,
            isTemperature: false,
            isBattery: false,
            isAccelerometer: false,
            isAccelerometerX: false,
            isAccelerometerY: false,
            isAccelerometerZ: false,
            isGyroX: false,
            isGyroY: false,
            isGyroZ: false,
            isUltrasonic: false,
            isSwitchOne: false,
            isSwitchTwo: false,
            portsData,
        },
    }
}

const graphPlotStorage_Reducer = (state = graphPlotStorage, action) => {
    switch (action.type) {
        case 'GRAPH_PLOTSTORAGE':
            var counter = action.payload
            console.log('fregrgegegeg', counter)
            Object.keys(counter).map((val, index) => {
                if (
                    val == 'isDistanceSensors' ||
                    val == 'isGestureSensor' ||
                    val == 'isLightSensor' ||
                    val == 'isColorSensor'
                ) {
                    state.internalaccessories.Four_in_one_sensor[val] =
                        counter[val]
                } else if (val == 'time') {
                    state.time = counter[val]
                } else {
                    state.internalaccessories[val] = counter[val]
                }
            })

            var data = { ...state }
            sessionStorage.setItem('graphPlotStorage', JSON.stringify(data))
            return data

        default:
            sessionStorage.setItem('graphPlotStorage', JSON.stringify(state))
            return state
    }
}

export default graphPlotStorage_Reducer
