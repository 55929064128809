import { useState } from 'react'
import './toggle.css'
import style from './toggle.module.css'

function Toggle(props) {
    // const handleToggle = () => {
    //     let newData = data
    //     Object.values(newData).forEach((value, index) => {
    //         console.log('ACTIVE', value.ports[0].isActive)
    //         if (value.ports[0].isActive == true) {
    //             value.ports[0].isDigital = !value.ports[0].isDigital
    //             sessionStorage.setItem('portsData', JSON.stringify(portsData))
    //         }

    //         if (value.ports[1].isActive == true) {
    //             value.ports[1].isDigital = !value.ports[1].isDigital
    //             sessionStorage.setItem('portsData', JSON.stringify(portsData))
    //         }
    //     })
    //     console.log('setData', newData)
    //     setData(newData)
    //     setToggle(!toggle)
    // }

    const styleNotAllowed =
        props.isActive == false
            ? {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
              }
            : null
    console.log('PROPS.ISACTIVE', props.isActive)
    const activeState = props.isActive && props.isDigital

    return (
        <div
            className={style[`switch-button-${activeState}`]}
            id={style.switchButtonContainer}
            style={{
                // height: '70%',
                // width: '75%',
                opacity: `${props.isActive ? 1 : 0.3}`,
            }}
        >
            <input
                active={false}
                className="switch-button-checkbox"
                type="checkbox"
                checked={props.isDigital}
                onChange={
                    props.portName == 'D1' || props.portName == 'D2'
                        ? null
                        : () => props.handleToggle(props.portName)
                }
                name={props.portName}
                style={styleNotAllowed}
            ></input>
            <label
                className="switch-button-label"
                id={style.switchButtonSubContainer}
                htmlFor=""
            >
                {props.isDigital == true ? (
                    <span
                        class="switch-button-label-span"
                        className={style[`switch-text-${props.isActive}`]}
                        id="in1"
                        style={{ color: '#717171' }}
                    >
                        Digital
                    </span>
                ) : (
                    <span
                    class="switch-button-label-span"
                    className={style[`switch-text-${props.isActive}`]}
                        id="in1"
                    >
                        Digital
                    </span>
                )}
            </label>
        </div>
    )
}
export default Toggle
