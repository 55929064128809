import styles from './style.module.css'

const LeftToggleSection = ({
    A1,
    A2,
    B1,
    B2,
    E1,
    E2,
    M1,
    M2,
    S1,
    S2,
    aUltra,
    bttnColor,
    bttnColor2,
    a1Checked,
    a1CheckedState,
    a2Checked,
    a2CheckedState,
    b1Checked,
    b1CheckedState,
    b2Checked,
    b2CheckedState,
    e1Checked,
    e1CheckedState,
    e2Checked,
    e2CheckedState,
    m1CheckedState,
    m2CheckedState,
    s1CheckedState,
    s2CheckedState,
    UltraA,
    bRGB,
    bMP3,
    RgbB,
    Mp3B,
}) => {
    const isPCv1 = sessionStorage.getItem('deviceVersion')?.startsWith('1')
        ? true
        : false
    return (
        <>
            <div className={styles.ButtonDivInput}>
                {isPCv1 && (
                    <>
                        <div
                            className={styles.Inputs_flow_left_upper_ultrasonic}
                        >
                            <div
                                className={
                                    styles.Inputs_flow_left_upper_grp_ultrasonic
                                }
                            >
                                <label
                                    className={
                                        A1 &&
                                        !aUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((A1 && !aUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        A1
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((A1 && !aUltra) || false)
                                        }
                                        id="s1"
                                        style={{
                                            color: bttnColor[0],
                                        }}
                                    >
                                        {/* <div> */}
                                        <input
                                            // disabled={!A1 || aUltra}
                                            checked={a1Checked}
                                            onChange={a1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        {/* </div> */}

                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in1"
                                                style={{
                                                    color: bttnColor2[0],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        A2 &&
                                        !aUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((A2 && !aUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        A2
                                    </span>
                                    <div
                                        className={
                                            'switch-button-' +
                                            ((A2 && !aUltra) || false)
                                        }
                                        id="s2"
                                        style={{
                                            color: bttnColor[1],
                                        }}
                                    >
                                        <input
                                            // disabled={!A2 || aUltra}
                                            checked={a2Checked}
                                            onChange={a2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                                style={{
                                                    color: bttnColor2[1],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        aUltra +
                                        'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                        aUltra +
                                        ' ultrasonic-disabled-' +
                                        (!A1 || !A2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-ultrasonic-' +
                                            (aUltra || false)
                                        }
                                        id="s2"
                                    >
                                        <input
                                            className="switch-button-checkbox-ultrasonic"
                                            type="checkbox"
                                            // disabled={!A1 || !A2}
                                            checked={aUltra}
                                            onChange={UltraA}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                            >
                                                Ultra Sonic
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>

                        {/* b Port */}
                        <div className="Inputs-flow-left-upper-rgb">
                            <div className="Inputs-flow-left-upper-grp-rgb">
                                <label
                                    className={
                                        B1 &&
                                        (!bRGB && !bMP3) +
                                            'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((B1 && !bRGB && !bMP3) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        B1
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((B1 && !bRGB && !bMP3) || false)
                                        }
                                        id="s3"
                                        style={{
                                            color: bttnColor[2],
                                        }}
                                    >
                                        <input
                                            // disabled={!B1 || bRGB || bMP3}
                                            checked={b1Checked}
                                            onChange={b1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span "
                                                id="in3"
                                                style={{
                                                    color: bttnColor2[2],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        B2 &&
                                        (!bMP3 && !bRGB) +
                                            'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((B2 && !bRGB && !bMP3) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        B2
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((B2 && !bRGB && !bMP3) || false)
                                        }
                                        id="s4"
                                        style={{
                                            color: bttnColor[3],
                                        }}
                                    >
                                        <input
                                            // disabled={!B2 || bRGB || bMP3}
                                            checked={b2Checked}
                                            onChange={b2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in4"
                                                style={{
                                                    color: bttnColor2[3],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        bRGB +
                                        'input upper-label-input upper-label-input-rgb upper-label-input-rgb-' +
                                        bRGB +
                                        ' rgb-disabled-' +
                                        (!B1 || !B2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-rgb-' +
                                            (bRGB || false)
                                        }
                                        style={{
                                            width: '100px',
                                            margin: '0px',
                                            top: '-1vh',
                                        }}
                                        id="s2"
                                    >
                                        <input
                                            className="switch-button-checkbox-rgb"
                                            type="checkbox"
                                            // disabled={!B1 || !B2}
                                            checked={bRGB}
                                            onChange={RgbB}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                            >
                                                RGgg
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <label
                                    className={
                                        bMP3 +
                                        'input upper-label-input upper-label-input-rgb upper-label-input-rgb-' +
                                        bMP3 +
                                        ' rgb-disabled-' +
                                        (!B1 || !B2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-mp3-' +
                                            (bMP3 || false)
                                        }
                                        id="s2"
                                    >
                                        <input
                                            className="switch-button-checkbox-mp3"
                                            type="checkbox"
                                            // disabled={!B1 || !B2} 12
                                            checked={bMP3}
                                            onChange={Mp3B}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                            >
                                                RGgg
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>

                        {/* <div className="Inputs-flow-left-upper">
                                        <div className="Inputs-flow-left-upper-grp">
                                            <label
                                                className={
                                                    B1 +
                                                    'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        (B1 || false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    B1
                                                </span>
                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        (B1 || false)
                                                    }
                                                    id="s3"
                                                    style={{
                                                        color: bttnColor[2],
                                                    }}
                                                >
                                                    <input
                                                        // disabled={!E1}
                                                        checked={b1Checked}
                                                        onChange={
                                                            b1CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in3"
                                                            style={{
                                                                color: bttnColor2[2],
                                                            }}
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                            <br />
                                            <label
                                                className={
                                                    B2 +
                                                    'input upper-label-input'
                                                }
                                            >
                                                <span
                                                    className={
                                                        (B2 || false) +
                                                        '-span textsp'
                                                    }
                                                >
                                                    B2
                                                </span>
                                                <div
                                                    className={
                                                        'switch-button-' +
                                                        (B2 || false)
                                                    }
                                                    id="s4"
                                                    style={{
                                                        color: bttnColor[3],
                                                    }}
                                                >
                                                    <input
                                                        // disabled={!E2}
                                                        checked={b2Checked}
                                                        onChange={
                                                            b2CheckedState
                                                        }
                                                        className="switch-button-checkbox"
                                                        type="checkbox"
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in4"
                                                            style={{
                                                                color: bttnColor2[3],
                                                            }}
                                                        >
                                                            Input
                                                        </span>
                                                    </label>
                                                </div>
                                            </label>
                                        </div>
                                    </div> */}
                    </>
                )}
                {!isPCv1 && (
                    <>
                        <div className="Inputs-flow-left-upper-ultrasonic">
                            <div className="Inputs-flow-left-upper-grp-ultrasonic">
                                <label
                                    className={
                                        A1 &&
                                        !aUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((A1 && !aUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        A1
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((A1 && !aUltra) || false)
                                        }
                                        id="s1"
                                        style={{
                                            color: bttnColor[0],
                                        }}
                                    >
                                        {/* <div> */}
                                        <input
                                            // disabled={!A1 || aUltra}
                                            checked={a1Checked}
                                            onChange={a1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        {/* </div> */}

                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in1"
                                                style={{
                                                    color: bttnColor2[0],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        A2 &&
                                        !aUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((A2 && !aUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        A2
                                    </span>
                                    <div
                                        className={
                                            'switch-button-' +
                                            ((A2 && !aUltra) || false)
                                        }
                                        id="s2"
                                        style={{
                                            color: bttnColor[1],
                                        }}
                                    >
                                        <input
                                            // disabled={!A2 || aUltra}
                                            checked={a2Checked}
                                            onChange={a2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                                style={{
                                                    color: bttnColor2[1],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        aUltra +
                                        'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                        aUltra +
                                        ' ultrasonic-disabled-' +
                                        (!A1 || !A2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-ultrasonic-' +
                                            (aUltra || false)
                                        }
                                        id="s2"
                                    >
                                        <input
                                            className="switch-button-checkbox-ultrasonic"
                                            type="checkbox"
                                            // disabled={!A1 || !A2}
                                            checked={aUltra}
                                            onChange={UltraA}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                            >
                                                Ultra Sonic
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="Inputs-flow-left-upper-rgb">
                            <div className="Inputs-flow-left-upper-grp-rgb">
                                <label
                                    className={
                                        B1 &&
                                        (!bRGB && !bMP3) +
                                            'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((B1 && !bRGB && !bMP3) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        B1
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((B1 && !bRGB && !bMP3) || false)
                                        }
                                        id="s3"
                                        style={{
                                            color: bttnColor[2],
                                        }}
                                    >
                                        <input
                                            // disabled={!B1 || bRGB || bMP3}
                                            checked={b1Checked}
                                            onChange={b1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span "
                                                id="in3"
                                                style={{
                                                    color: bttnColor2[2],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        B2 &&
                                        (!bMP3 && !bRGB) +
                                            'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((B2 && !bRGB && !bMP3) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        B2
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((B2 && !bRGB && !bMP3) || false)
                                        }
                                        id="s4"
                                        style={{
                                            color: bttnColor[3],
                                        }}
                                    >
                                        <input
                                            // disabled={!B2 || bRGB || bMP3}
                                            checked={b2Checked}
                                            onChange={b2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in4"
                                                style={{
                                                    color: bttnColor2[3],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        bRGB +
                                        'input upper-label-input upper-label-input-rgb upper-label-input-rgb-' +
                                        bRGB +
                                        ' rgb-disabled-' +
                                        (!B1 || !B2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-rgb-' +
                                            (bRGB || false)
                                        }
                                        id="s2"
                                    >
                                        <input
                                            className="switch-button-checkbox-rgb"
                                            type="checkbox"
                                            // disabled={!B1 || !B2}
                                            checked={bRGB}
                                            onChange={RgbB}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in2"
                                            >
                                                RGgg
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                {/* <label
                                                className={
                                                    bMP3 +
                                                    'input upper-label-input upper-label-input-mp3 upper-label-input-mp3-' +
                                                    bMP3 +
                                                    ' mp3-disabled-' +
                                                    (!B1 || !B2)
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-button-mp3-' +
                                                        (bMP3 || false)
                                                    }
                                                    id="s2"
                                                >
                                                    <input
                                                        className="switch-button-checkbox-mp3"
                                                        type="checkbox"
                                                        // disabled={!B1 || !B2}
                                                        checked={bMP3}
                                                        onChange={Mp3B}
                                                    ></input>
                                                    <label
                                                        className="switch-button-label"
                                                        for=""
                                                    >
                                                        <span
                                                            className="switch-button-label-span"
                                                            id="in2"
                                                        ></span>
                                                    </label>
                                                </div>
                                            </label> */}
                            </div>
                        </div>
                    </>
                )}

                <div className="Inputs-flow-left-upper">
                    <div className="Inputs-flow-left-upper-grp">
                        <label className={E1 + 'input upper-label-input'}>
                            <span className={(E1 || false) + '-span textsp'}>
                                E1
                            </span>
                            <div
                                className={'switch-button-' + (E1 || false)}
                                id="s9"
                                style={{ color: bttnColor[8] }}
                            >
                                <input
                                    // disabled={!E1}
                                    checked={e1Checked}
                                    onChange={e1CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in9"
                                        style={{
                                            color: bttnColor2[8],
                                        }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label className={E2 + 'input upper-label-input'}>
                            <span className={(E2 || false) + '-span textsp'}>
                                E2
                            </span>
                            <div
                                className={'switch-button-' + (E2 || false)}
                                id="s10"
                                style={{ color: bttnColor[9] }}
                            >
                                <input
                                    // disabled={!E2}
                                    checked={e2Checked}
                                    onChange={e2CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in10"
                                        style={{
                                            color: bttnColor2[9],
                                        }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="Inputs-flow-left-upper">
                    <div className="Inputs-flow-left-upper-grp">
                        <label className={M1 + 'input upper-label-input'}>
                            <span className={(M1 || false) + '-span textsp'}>
                                L1
                            </span>

                            <div
                                className={'switch-button-' + (M1 || false)}
                                id="s13"
                                style={{ color: ' #fcfcfc' }}
                            >
                                <input
                                    // disabled={true}
                                    checked={true}
                                    onChange={m1CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in13"
                                        style={{ color: '#717171' }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label className={M2 + 'input upper-label-input'}>
                            <span className={(M2 || false) + '-span textsp'}>
                                L2
                            </span>

                            <div
                                className={'switch-button-' + (M2 || false)}
                                id="s14"
                                style={{ color: ' #fcfcfc' }}
                            >
                                <input
                                    // disabled={true}
                                    checked={true}
                                    onChange={m2CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in14"
                                        style={{ color: '#717171' }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
                {isPCv1 && (
                    <div className="Inputs-flow-left-upper">
                        <div className="Inputs-flow-left-upper-grp">
                            <label className={S1 + 'input upper-label-input'}>
                                <span
                                    className={(S1 || false) + '-span textsp'}
                                >
                                    S1
                                </span>

                                <div
                                    className={'switch-button-' + (S1 || false)}
                                    id="s17"
                                    style={{ color: ' #fcfcfc' }}
                                >
                                    <input
                                        // disabled={true}
                                        checked={true}
                                        onChange={s1CheckedState}
                                        className="switch-button-checkbox"
                                        type="checkbox"
                                    ></input>
                                    <label
                                        className="switch-button-label"
                                        for=""
                                    >
                                        <span
                                            className="switch-button-label-span"
                                            id="in17"
                                            style={{
                                                color: '#717171',
                                            }}
                                        >
                                            Input
                                        </span>
                                    </label>
                                </div>
                            </label>
                            <br />
                            <label className={S2 + 'input upper-label-input'}>
                                <span
                                    className={(S2 || false) + '-span textsp'}
                                >
                                    S2
                                </span>

                                <div
                                    className={'switch-button-' + (S2 || false)}
                                    id="s18"
                                    style={{ color: ' #fcfcfc' }}
                                >
                                    <input
                                        // disabled={true}
                                        checked={true}
                                        onChange={s2CheckedState}
                                        className="switch-button-checkbox"
                                        type="checkbox"
                                    ></input>
                                    <label
                                        className="switch-button-label"
                                        for=""
                                    >
                                        <span
                                            className="switch-button-label-span"
                                            id="in18"
                                            style={{
                                                color: '#717171',
                                            }}
                                        >
                                            Input
                                        </span>
                                    </label>
                                </div>
                            </label>
                        </div>
                    </div>
                )}

                <div style={{ marginTop: '4rem' }}>.</div>
            </div>
        </>
    )
}
export default LeftToggleSection
