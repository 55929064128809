import { sendBytes } from './byteTransfer'
import { PCVersionCode } from './byteCodes'
import { utf16ToChar } from './utils'
import { resolve } from 'path'
import { func } from 'prop-types'

export const getVersion = async (port, flag = false) => {
    await sendBytes(PCVersionCode, port)
    const response = flag
        ? await readForVersion2(port)
        : await readForVersion3(port)

    if (response && response.ok) {
        return response.data
        //     const versionData = flag ? response.data : cleanVersion(response.data)
        //     console.log(versionData)
        //     return versionData
    } else {
        return { Version: '', device: '' }
    }
}

const cleanVersion = (data) => {
    const filterdVersion = utf16ToChar(data).filter(
        (char) => char === '.' || !isNaN(parseInt(char))
    )

    return filterdVersion.join('')
}

const readForVersion = async (port) => {
    if (port === undefined) {
        console.log('Port undefined')
        return
    }

    let combinedResponse = []
    while (port.readable) {
        if (port.readable.locked) return

        let reader = port.readable.getReader()
        let count = 5
        try {
            while (true) {
                console.log('waiting for data ... ')
                const { value } = await reader.read()
            }
        } catch (err) {
            console.log(err)
        } finally {
            reader.releaseLock()
            port.readable.cancel()
            console.log('Reader lock released')
        }
    }
}
const readForVersion2 = async (port) => {
    if (port === undefined) {
        console.log('Port undefined')
        return
    }

    let combinedResponse = []
    while (port.readable) {
        if (port.readable.locked) return

        let reader = port.readable.getReader()
        try {
            while (true) {
                console.log('waiting for data ... ')
                const { value } = await reader.read()
                console.log(value)
                const data = Array.from(value)
                combinedResponse = combinedResponse.concat(data)
                // console.log("Received data : " + data);
                console.log('Combined data : ' + combinedResponse)

                return { ok: true, data: combinedResponse }
            }
        } catch (err) {
            console.log(err)
        } finally {
            reader.releaseLock()
            port.readable.cancel()
            console.log('Reader lock released')
        }
    }
}

//continuous reading and check based on version in readBytes
const readForVersion3 = async (port) => {
    if (port === undefined) {
        console.log('Port undefined')
        return
    }
    try {
        const res = await startReading(port)
        console.log('rejected', res)
        return res
    } catch (e) {
        return null
    }
}

const startReading = async (port) => {
    return new Promise(async (resolve, reject) => {
        var reqDataFound = false
        let bytesRead = 0
        let combinedResponse = []
        let isLocked = true
        while (port.readable) {
            console.log('getting reader')
            if (port.readable.locked) return
            var reader = port.readable.getReader()
            try {
                const timeOutPromise = new Promise((_, reject) => {
                    displayMessages(reqDataFound, resolveMessage)
                    setTimeout(() => {
                        console.log('time out')
                        if (bytesRead === 0 || !reqDataFound) {
                            let path = window.location.pathname
                            if (path.includes('deviceSelection'))
                                sendData('unable to connect the device')
                            reject('unable to connect the device')
                            // reject('no data found')
                        }
                    }, 10000)
                })
                var resolveMessage
                const msgPromise = new Promise((resolve) => {
                    resolveMessage = resolve
                })

                await Promise.race([
                    timeOutPromise,
                    Promise.all([msgPromise, readDataBytes()]),
                ])

                async function readDataBytes() {
                    while (true) {
                        const { value } = await reader.read()

                        if (value) {
                            bytesRead = value.length

                            const data = Array.from(value)

                            combinedResponse = combinedResponse.concat(data)
                            let strData = combinedResponse.join(',')
                            let unicode = utf16ToChar(combinedResponse).join('')
                            let regxPC =
                                /(?:PC(?:v?0)\.1\.\d{2}|ZG(?:1\.0\.\d{2}|0\.1\.\d{2})|CW1\.0\.\d{2})/
                            // let regxZG = /ZG(?:1\.0\.\d{2}|0\.1\.\d{2})/

                            const match = unicode.match(regxPC)

                            if (match) {
                                let device = match[0].includes('ZG')
                                    ? 'Humanoid'
                                    : match[0].includes('CW')
                                    ? 'Hexapod'
                                    : 'Ace'
                                let result = match[0].replace(/^[a-zA-Z]+/, '')

                                reqDataFound = true
                                resolve({
                                    ok: true,
                                    data: { Version: result, device },
                                })
                                resolveMessage()
                                break
                            }
                        }
                        await new Promise((resolve, reject) => {
                            setTimeout(resolve, 0)
                        })
                    }
                }
            } catch (e) {
                console.log('error caused', e)
                reject(e)
                break
            } finally {
                await reader.releaseLock()

                port.readable.cancel()
            }
        }
    })
}
//for error msg display if unable to read
const displayMessages = async (reqDataFound, resolveMessage) => {
    console.log('start message1', reqDataFound)
    sendData('device connecting')
    await new Promise((resolve) => setTimeout(resolve, 2000))
    if (reqDataFound) {
        resolveMessage()
        return
    } else {
        console.log('start message2', reqDataFound)
        sendData('fetching the version')
        await new Promise((resolve) => setTimeout(resolve, 2000))
    }
}
//send the error text to selection screen
const sendData = (text) => {
    const event = new CustomEvent('custom', { detail: text })
    document.dispatchEvent(event)
}

export const getS3Version = async (worker) => {
    return new Promise(async (resolve, reject) => {
        let reqDataFound = false
        // debugger
        let intervalID = setInterval(() => {
            worker.postMessage({
                type: 'write',
                value: 'WHO',
            })
        }, 100)
        try {
            let resolveMessageBeta
            const msgPromise = new Promise((resolve) => {
                resolveMessageBeta = resolve
            })
            const timeoutPromise = new Promise((_, reject) => {
                displayMessages(reqDataFound, resolveMessageBeta)

                setTimeout(() => {
                    if (!reqDataFound) {
                        let path = window.location.pathname
                        if (path.includes('deviceSelection'))
                            sendData('unable to connect the device')
                        clearInterval(intervalID)
                        resolve({ Version: 'no data found', device: '' })
                        worker.onmessage = null
                    }
                }, 9000)
            })

            await Promise.race([
                timeoutPromise,
                Promise.all([msgPromise, dataListener()]),
            ])

            displayMessages(reqDataFound, resolveMessageBeta)

            async function dataListener() {
                worker.onmessage = async (e) => {
                    console.log(e.data.value)
                    if (
                        e.data.type == 'read' &&
                        (e.data.value.includes('PCv') ||
                            e.data.value.includes('PC') ||
                            e.data.value.includes('CW') ||
                            e.data.value.includes('ZG') ||
                            e.data.value.includes('KW') ||
                            e.data.value.includes('RB'))
                    ) {
                        console.log('worker data', e.data.value)
                        let version = e.data.value

                        // if (version.includes('PCMP_1.0')) {
                        //     version = 'PC1.1.6.0'
                        //     window.location.replace(
                        //         'https://edu.plode.org/python/?device=peecee'
                        //     )
                        // }
                        // if (
                        //     version.includes('ZGMP_1.0') ||
                        //     version.includes('ZGMP_2.0') ||
                        //     version.includes('CWMP_1.0') ||
                        //     version.includes('CWMP_2.0')
                        // ) {
                        //     version = 'ZG2.1.2.0'
                        //     window.location.replace(
                        //         'https://edu.plode.org/python/?device=zing'
                        //     )
                        // }

                        // let r =
                        // /(?:(PCv1\.0\.\d{2}[ab])|((?:ZG2|CW2|PC1)\.\d{1,3}\.\d{1,3})|RB\d{1}(\.\d{1,3}){2})/ //devtest
                        let regx =
                            /(?:(PCv1\.\d{1,3}\.\d{2}[ab]?)|((PCv0\.1\.\d{2}))|((?:ZG|CW|KW|PC)[0,1,2]\.\d{1,3}\.\d{1,3})|RB\d{1}(\.\d{1,3}){2}|((ZG|PC)MP_[0-9]\.[0-9]))/

                        reqDataFound = true
                        let match = version.match(regx)
                        console.log('got version', match, version)
                        if (match) {
                            let device = match[0].includes('CW')
                                ? 'Hexapod'
                                : match[0].includes('ZG')
                                ? 'Humanoid'
                                : match[0].includes('RB')
                                ? 'Roboki'
                                : match[0].includes('KW')
                                ? 'Klaw'
                                : match[0].includes('PC')
                                ? 'Ace'
                                : 'Ace'

                            let result = ''
                            if (match[0].includes('PCMP_1')) {
                                result = '1.1.7.0'
                                sessionStorage.setItem('microPython', 'true')
                            } else if (match[0].includes('ZGMP_2')) {
                                let allowedDevice =
                                    sessionStorage.getItem('allowedDevice')
                                if (allowedDevice == 'Hexapod') {
                                    result = '2.0.2.0'
                                    device = 'Hexapod'
                                } else {
                                    result = '2.1.3.0'
                                }
                                sessionStorage.setItem('microPython', 'true')
                            } else {
                                result = match[0].replace(/^[a-zA-Z]+/, '')
                                sessionStorage.setItem('microPython', 'false')
                            }
                            // device = result === '0.2.01' ? 'Roboki' : device
                            clearInterval(intervalID)

                            resolve({ Version: result, device })
                            resolveMessageBeta()
                            worker.onmessage = null
                        }
                    }
                    // if(e.data.type == 'connected' && !e.data.value){
                    //     clearInterval(intervalID)
                    //     reject('port disconnected due to some reason')
                    // }
                }
            }
        } catch (e) {
            console.log('error', e)
            reject(e)
        }
    })
}
