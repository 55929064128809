import React, { Component } from 'react'
import LoopSliderBox from '../../ReusableComponents/NewSlider/LoopSliderBox'
import InputNumber from './helpers/InputNumber'

import './loop.css'

class LoopPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    onChange = (value) => {
        if (value < 1) value = 1
        else if (value > 255) value = 255
        this.props.onChange({ times: value })
    }
    render() {
        const { state } = this.props
        return (
            <LoopSliderBox
                style={{ marginTop: '15%' }}
                min={0}
                max={255}
                value={state.times || 1}
                onChange={(e) => {
                    this.props.onChange({ times: e.target.value })
                }}
            />
        )
    }
}

export default LoopPanel
