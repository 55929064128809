const RightToggleSection = ({
    bttnColor,
    bttnColor2,
    C1,
    C2,
    D1,
    D2,
    F1,
    F2,
    M3,
    M4,
    c1Checked,
    c2Checked,
    d1Checked,
    d2Checked,
    f1Checked,
    f2Checked,
    m3CheckedState,
    m4CheckedState,
    c1CheckedState,
    c2CheckedState,
    d1CheckedState,
    d2CheckedState,
    f1CheckedState,
    f2CheckedState,
    cUltra,
    UltraC,
    OledD,
    dOLED,
    isDistanceSensors,
    isGestureSensor,
    isLightSensor,
    isColorSensor,
    isAccelerometer,
}) => {
    const isPCv1 = sessionStorage.getItem('deviceVersion')?.startsWith('1')
        ? true
        : false
    return (
        <>
            <div className="ButtonRightDivInput">
                {isPCv1 && (
                    <>
                        <div className="Inputs-flow-left-upper-ultrasonic">
                            <div className="Inputs-flow-left-upper-grp-ultrasonic">
                                <label
                                    className={
                                        C1 &&
                                        !cUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((C1 && !cUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        C1
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((C1 && !cUltra) || false)
                                        }
                                        id="s5"
                                        style={{
                                            color: bttnColor[4],
                                        }}
                                    >
                                        <input
                                            // disabled={!C1 || cUltra}
                                            checked={c1Checked}
                                            onChange={c1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in5"
                                                style={{
                                                    color: bttnColor2[4],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        C2 &&
                                        !cUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((C2 && !cUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        C2
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((C2 && !cUltra) || false)
                                        }
                                        id="s6"
                                        style={{
                                            color: bttnColor[5],
                                        }}
                                    >
                                        <input
                                            // disabled={!C2 || cUltra}
                                            checked={c2Checked}
                                            onChange={c2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in6"
                                                style={{
                                                    color: bttnColor2[5],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        cUltra +
                                        'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                        cUltra +
                                        ' ultrasonic-disabled-' +
                                        (!C1 || !C2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-ultrasonic-' +
                                            (cUltra || false)
                                        }
                                        id="s7"
                                    >
                                        <input
                                            className="switch-button-checkbox-ultrasonic"
                                            type="checkbox"
                                            // disabled={!C1 || !C2}
                                            checked={cUltra}
                                            onChange={UltraC}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in7"
                                            >
                                                Ultrasonic
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="Inputs-flow-left-upper-oled">
                            <div className="Inputs-flow-left-upper-grp-oled">
                                <label
                                    className={
                                        (D1 &&
                                            !dOLED &&
                                            !(
                                                isDistanceSensors ||
                                                isGestureSensor ||
                                                isLightSensor ||
                                                isColorSensor
                                            )) + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((D1 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isAccelerometer &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false) + '-span textsp'
                                        }
                                    >
                                        D1
                                    </span>
                                    <div
                                        className={
                                            'switch-button-' +
                                            ((D1 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isAccelerometer &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false)
                                        }
                                        id="sD1"
                                        style={
                                            !d1Checked
                                                ? {
                                                      color: '#717171',
                                                  }
                                                : {
                                                      color: '#fcfcfc',
                                                  }
                                        }
                                    >
                                        <input
                                            // disabled={true}
                                            // disabled={!D1 || dOLED}
                                            checked={d1Checked}
                                            onChange={d1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="inD1"
                                                style={
                                                    !d1Checked
                                                        ? {
                                                              color: '#fcfcfc',
                                                          }
                                                        : {
                                                              color: '#717171',
                                                          }
                                                }
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        (D2 &&
                                            !dOLED &&
                                            !(
                                                isDistanceSensors ||
                                                isGestureSensor ||
                                                isLightSensor ||
                                                isColorSensor
                                            )) + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((D2 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isAccelerometer &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false) + '-span textsp'
                                        }
                                    >
                                        D2
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((D2 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isAccelerometer &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false)
                                        }
                                        id="sD2"
                                        style={
                                            !d2Checked
                                                ? {
                                                      color: '#717171',
                                                  }
                                                : {
                                                      color: '#fcfcfc',
                                                  }
                                        }
                                    >
                                        <input
                                            // disabled={true}
                                            checked={d2Checked}
                                            onChange={d2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="inD2"
                                                style={
                                                    !d2Checked
                                                        ? {
                                                              color: '#fcfcfc',
                                                          }
                                                        : {
                                                              color: '#717171',
                                                          }
                                                }
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        dOLED +
                                        'input upper-label-input upper-label-input-ultrasonic upper-label-input-oled-' +
                                        dOLED +
                                        ' oled-disabled-' +
                                        (!D1 || !D2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-oled-' +
                                            (dOLED || false)
                                        }
                                        id="s7"
                                    >
                                        <input
                                            className="switch-button-checkbox-oled"
                                            type="checkbox"
                                            // disabled={!D1 || !D2}
                                            checked={dOLED}
                                            onChange={OledD}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in7"
                                            >
                                                Ultrasonic
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </>
                )}
                {!isPCv1 && (
                    <>
                        <div className="Inputs-flow-left-upper-ultrasonic">
                            <div className="Inputs-flow-left-upper-grp-ultrasonic">
                                <label
                                    className={
                                        C1 &&
                                        !cUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((C1 && !cUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        C1
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((C1 && !cUltra) || false)
                                        }
                                        id="s5"
                                        style={{
                                            color: bttnColor[4],
                                        }}
                                    >
                                        <input
                                            // disabled={!C1 || cUltra}
                                            checked={c1Checked}
                                            onChange={c1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in5"
                                                style={{
                                                    color: bttnColor2[4],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        C2 &&
                                        !cUltra + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((C2 && !cUltra) || false) +
                                            '-span textsp'
                                        }
                                    >
                                        C2
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((C2 && !cUltra) || false)
                                        }
                                        id="s6"
                                        style={{
                                            color: bttnColor[5],
                                        }}
                                    >
                                        <input
                                            // disabled={!C2 || cUltra}
                                            checked={c2Checked}
                                            onChange={c2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in6"
                                                style={{
                                                    color: bttnColor2[5],
                                                }}
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        cUltra +
                                        'input upper-label-input upper-label-input-ultrasonic upper-label-input-ultrasonic-' +
                                        cUltra +
                                        ' ultrasonic-disabled-' +
                                        (!C1 || !C2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-ultrasonic-' +
                                            (cUltra || false)
                                        }
                                        id="s7"
                                    >
                                        <input
                                            className="switch-button-checkbox-ultrasonic"
                                            type="checkbox"
                                            // disabled={!C1 || !C2}
                                            checked={cUltra}
                                            onChange={UltraC}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in7"
                                            >
                                                Ultrasonic
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="Inputs-flow-left-upper-oled">
                            <div className="Inputs-flow-left-upper-grp-oled">
                                <label
                                    className={
                                        D1 && !dOLED + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((D1 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false) + '-span textsp'
                                        }
                                    >
                                        D1
                                    </span>
                                    <div
                                        className={
                                            'switch-button-' +
                                            ((D1 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false)
                                        }
                                        id="sD1"
                                        style={
                                            !d1Checked
                                                ? {
                                                      color: '#717171',
                                                  }
                                                : {
                                                      color: '#fcfcfc',
                                                  }
                                        }
                                    >
                                        <input
                                            // disabled={true}
                                            // disabled={!D1 || dOLED}
                                            checked={d1Checked}
                                            onChange={d1CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="inD1"
                                                style={
                                                    !d1Checked
                                                        ? {
                                                              color: '#fcfcfc',
                                                          }
                                                        : {
                                                              color: '#717171',
                                                          }
                                                }
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        D2 && !dOLED + 'input upper-label-input'
                                    }
                                >
                                    <span
                                        className={
                                            ((D2 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false) + '-span textsp'
                                        }
                                    >
                                        D2
                                    </span>

                                    <div
                                        className={
                                            'switch-button-' +
                                            ((D2 &&
                                                !dOLED &&
                                                !isDistanceSensors &&
                                                !isColorSensor &&
                                                !isGestureSensor &&
                                                !isLightSensor) ||
                                                false)
                                        }
                                        id="sD2"
                                        style={
                                            !d2Checked
                                                ? {
                                                      color: '#717171',
                                                  }
                                                : {
                                                      color: '#fcfcfc',
                                                  }
                                        }
                                    >
                                        <input
                                            // disabled={true}
                                            // disabled={!D1 || dOLED}
                                            checked={d2Checked}
                                            onChange={d2CheckedState}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="inD2"
                                                style={
                                                    !d2Checked
                                                        ? {
                                                              color: '#fcfcfc',
                                                          }
                                                        : {
                                                              color: '#717171',
                                                          }
                                                }
                                            >
                                                Input
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        dOLED +
                                        'input upper-label-input upper-label-input-ultrasonic upper-label-input-oled-' +
                                        dOLED +
                                        ' oled-disabled-' +
                                        (!D1 || !D2)
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-oled-' +
                                            (dOLED || false)
                                        }
                                        id="s7"
                                    >
                                        <input
                                            className="switch-button-checkbox-oled"
                                            type="checkbox"
                                            // disabled={!D1 || !D2}
                                            checked={dOLED}
                                            onChange={OledD}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in7"
                                            >
                                                OLED
                                            </span>
                                        </label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </>
                )}

                <div className="Inputs-flow-left-upper">
                    <div className="Inputs-flow-left-upper-grp">
                        <label className={F1 + 'input upper-label-input'}>
                            <span className={(F1 || false) + '-span textsp'}>
                                F1
                            </span>

                            <div
                                className={'switch-button-' + (F1 || false)}
                                id="s11"
                                style={{ color: bttnColor[10] }}
                            >
                                <input
                                    // disabled={!F1}
                                    checked={f1Checked}
                                    onChange={f1CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span "
                                        id="in11"
                                        style={{
                                            color: bttnColor2[10],
                                        }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label className={F2 + 'input upper-label-input'}>
                            <span className={(F2 || false) + '-span textsp'}>
                                F2
                            </span>

                            <div
                                className={'switch-button-' + (F2 || false)}
                                id="s12"
                                style={{ color: bttnColor[11] }}
                            >
                                <input
                                    // disabled={!F2}
                                    checked={f2Checked}
                                    onChange={f2CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in12"
                                        style={{
                                            color: bttnColor2[11],
                                        }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="Inputs-flow-left-upper">
                    <div className="Inputs-flow-left-upper-grp">
                        <label className={M3 + 'input upper-label-input'}>
                            <span className={(M3 || false) + '-span textsp'}>
                                L3
                            </span>
                            <div
                                className={'switch-button-' + (M3 || false)}
                                style={{ color: '#fcfcfc' }}
                            >
                                <input
                                    // disabled={true}
                                    checked={true}
                                    onChange={m3CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        style={{ color: '#717171' }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label className={M4 + 'input upper-label-input'}>
                            <span className={(M4 || false) + '-span textsp'}>
                                L4
                            </span>

                            <div
                                className={'switch-button-' + (M4 || false)}
                                style={{ color: '#fcfcfc' }}
                            >
                                <input
                                    // disabled={true}
                                    checked={true}
                                    onChange={m4CheckedState}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        style={{ color: '#717171' }}
                                    >
                                        Input
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RightToggleSection
