import React, { useEffect, useState } from 'react'
import renderImage from '../../source/importImg'
import selectStyle from './HumanoidSelectFeatures.module.css'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import { PORTDATA, GROUPACTIONS, CUSTOMGROUPACTIONS } from './defaultData'
import ProgramHeader from '../ReusableComponents/Header/ProgramHeader'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'

import cacheAssets from '../../utils/cacheAssets'
import renderCompImg from '../../source/Comp_Img'
import renderPrgImage from '../../source/programImg'
import removeHumanoidLocalProgramData from '../../utils/removeHumanoidLocalProgramData'
import closeBtn from '../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import Footer from '../ReusableComponents/FooterComponent'
import { generateDeviceDataKey } from '../ReusableComponents/ByteTransfer/utils'

const UIDATA = {
    HUMANOID: {
        21: {
            className: 'battery',
            activeImg: renderImage('activeBattery'),
            inActiveImg: renderImage('inactiveBattery'),
        },
        22: {
            className: 'ultrasonic',
            activeImg: renderImage('activeUltrasonic'),
            inActiveImg: renderImage('inactiveUltrasonic'),
        },
        23: {
            className: 'accelerometerX',
            activeImg: renderImage('activeAccelerometerX'),
            inActiveImg: renderImage('inactiveAccelerometerX'),
        },
        24: {
            className: 'accelerometerY',
            activeImg: renderImage('activeAccelerometerY'),
            inActiveImg: renderImage('inactiveAccelerometerY'),
        },
        25: {
            className: 'accelerometerZ',
            activeImg: renderImage('activeAccelerometerZ'),
            inActiveImg: renderImage('inactiveAccelerometerZ'),
        },
        26: {
            className: 'gyroX',
            activeImg: renderImage('activeGyroX'),
            inActiveImg: renderImage('inactiveGyroX'),
        },
        27: {
            className: 'gyroY',
            activeImg: renderImage('activeGyroY'),
            inActiveImg: renderImage('inactiveGyroY'),
        },
        28: {
            className: 'gyroZ',
            activeImg: renderImage('activeGyroZ'),
            inActiveImg: renderImage('inactiveGyroZ'),
        },
        0: {
            className: 'leftRGB',
            activeImg: renderImage('activeLeftRGB'),
            inActiveImg: renderImage('inactiveLeftRGB'),
        },
        1: {
            className: 'rightRGB',
            activeImg: renderImage('activeRightRBG'),
            inActiveImg: renderImage('inactiveRightRGB'),
        },
        2: {
            className: 'mp3',
            activeImg: renderImage('activeMp3'),
            inActiveImg: renderImage('inactiveMp3'),
        },
        '4b': {
            className: 'individualServo',
            activeImg: renderImage('activeIndividual'),
            inActiveImg: renderImage('inactiveIndividual'),
        },
        '4a': {
            className: 'actionGroup',
            activeImg: renderImage('activeActionGroup'),
            inActiveImg: renderImage('inactiveActionGroup'),
        },
        3: {
            className: 'headServo',
            activeImg: renderImage('activeHeadServo'),
            inActiveImg: renderImage('inactiveHeadServo'),
        },
        39: {
            className: 'M1',
            activeImg: renderImage('activeM1'),
            inActiveImg: renderImage('M1'),
        },
        40: {
            className: 'M2',
            activeImg: renderImage('activeM2'),
            inActiveImg: renderImage('M2'),
        },
        41: {
            className: 'SimpleServo',
            activeImg: renderImage('activesimpleServo'),
            inActiveImg: renderImage('simpleServo'),
        },
    },
    HEXAPOD: {
        23: {
            className: 'battery',
            activeImg: renderImage('activeBattery'),
            inActiveImg: renderImage('inactiveBattery'),
        },
        24: {
            className: 'ultrasonic',
            activeImg: renderImage('activeUltrasonic'),
            inActiveImg: renderImage('inactiveUltrasonic'),
        },
        25: {
            className: 'accelerometerX',
            activeImg: renderImage('activeAccelerometerX'),
            inActiveImg: renderImage('inactiveAccelerometerX'),
        },
        26: {
            className: 'accelerometerY',
            activeImg: renderImage('activeAccelerometerY'),
            inActiveImg: renderImage('inactiveAccelerometerY'),
        },
        27: {
            className: 'accelerometerZ',
            activeImg: renderImage('activeAccelerometerZ'),
            inActiveImg: renderImage('inactiveAccelerometerZ'),
        },
        28: {
            className: 'gyroX',
            activeImg: renderImage('activeGyroX'),
            inActiveImg: renderImage('inactiveGyroX'),
        },
        29: {
            className: 'gyroY',
            activeImg: renderImage('activeGyroY'),
            inActiveImg: renderImage('inactiveGyroY'),
        },
        30: {
            className: 'gyroZ',
            activeImg: renderImage('activeGyroZ'),
            inActiveImg: renderImage('inactiveGyroZ'),
        },
        0: {
            className: 'leftRGBHexapod',
            activeImg: renderImage('activeLeftRGB'),
            inActiveImg: renderImage('inactiveLeftRGB'),
        },
        1: {
            className: 'rightRGBHexapod',
            activeImg: renderImage('activeRightRBG'),
            inActiveImg: renderImage('inactiveRightRGB'),
        },
        2: {
            className: 'mp3-hexapod',
            activeImg: renderImage('activeMp3'),
            inActiveImg: renderImage('inactiveMp3'),
        },
        '4b': {
            className: 'individualServoHexapod',
            activeImg: renderImage('activeIndividual'),
            inActiveImg: renderImage('inactiveIndividual'),
        },
        '4a': {
            className: 'actionGroupHexapod',
            activeImg: renderImage('activeActionGroup'),
            inActiveImg: renderImage('inactiveActionGroup'),
        },
        3: {
            className: 'headServo',
            activeImg: renderImage('activeHeadServo'),
            inActiveImg: renderImage('inactiveHeadServo'),
        },
        39: {
            className: 'M1',
            activeImg: renderImage('activeM1'),
            inActiveImg: renderImage('M1'),
        },
        40: {
            className: 'M2',
            activeImg: renderImage('activeM2'),
            inActiveImg: renderImage('M2'),
        },
        41: {
            className: 'SimpleServo-hexapod',
            activeImg: renderImage('activesimpleServo'),
            inActiveImg: renderImage('simpleServo'),
        },
    },
    KLAW: {
        0: {
            className: 'joystick1',
            activeImg: renderImage('activePot1'),
            inActiveImg: renderImage('inactivePot1'),
        },
        1: {
            className: 'joystick2',
            activeImg: renderImage('activePot2'),
            inActiveImg: renderImage('inactivePot2'),
        },
        2: {
            className: 'joystick3',
            activeImg: renderImage('activePot3'),
            inActiveImg: renderImage('inactivePot3'),
        },
        '4b': {
            className: 'individualServo_klaw',
            activeImg: renderImage('activeIndividual'),
            inActiveImg: renderImage('inactiveIndividual'),
        },
        3: {
            className: 'switch1',
            activeImg: renderImage('activePot4'),
            inActiveImg: renderImage('inactivePot4'),
        },
        4: {
            className: 'switch2',
            activeImg: renderImage('activePot5'),
            inActiveImg: renderImage('inactivePot5'),
        },
        5: {
            className: 'switch3',
            activeImg: renderImage('activePot6'),
            inActiveImg: renderImage('inactivePot6'),
        },
    },
}
const INTERNALOUTPUTS = {
    HUMANOID: [0, 1, 2, 3, '4a', '4b'],
    HEXAPOD: [0, 1, '4a', '4b'],
    KLAW: [],
}
const INTERNALINPUTS = {
    HUMANOID: [21, 22, 23, 24, 25, 26, 27, 28],
    HEXAPOD: [23, 24, 25, 26, 27, 28, 29, 30],
    KLAW: [0, 1, 2, 3, 4, 5],
}
let logicNew = {
    cards: [
        {
            type: 'start',
            cardId: 0,
            connections: [{ to: 0, toPort: 0 }],
            left: 346,
            top: 100,
            invalid: false,
            state: {
                bic1: false,
                bic2: false,
                bic3: false,
                bid2: false,
                bif1: false,
                bif2: false,
                bif3: false,
                bid3: false,
                bid1: false,
                bmp3: false,
            },
        },
    ],
    nodeCount: 1,
    cardConnections: {
        0: {
            type: 'start',
            cardId: 0,
            connections: [{ to: 0, toPort: 0 }],
            left: 346,
            top: 100,
            invalid: false,
            state: {
                bic1: false,
                bic2: false,
                bic3: false,
                bid2: false,
                bif1: false,
                bif2: false,
                bif3: false,
                bid3: false,
                bid1: false,
                bmp3: false,
            },
        },
    },
    end: { type: 'end', state: 'repeat' },
    offset: { left: 0, top: 0 },
    scale: 1,
    bottomPanel: 'border',
}
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    zIndex: '1000',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

function HumanoidSelectFeatures(props) {
    const history = useHistory()
    // let ZingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const PORTDATAKey = generateDeviceDataKey(connectedDevice, deviceVersion)
    const connectedDeviceName =
        connectedDevice === 'Humanoid' ? 'Zing' : connectedDevice
    const [feedbackMsg, setFeedbackMsg] = useState(
        `Select the Internal Accessories of ${connectedDeviceName}`
    )
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [portData, setPortData] = useState(PORTDATA[PORTDATAKey])
    const [erasedProgram, setErasedProgram] = useState(false)
    ////////////////Add 22 to enable ultrasoinc to the below array//////////
    const INPUTS = INTERNALINPUTS[`${connectedDevice.toUpperCase()}`]
    ////////////////Add 0, 1 to enable L RGB & R RGB to the below array//////////
    const OUTPUTS = INTERNALOUTPUTS[`${connectedDevice.toUpperCase()}`]
    ////////////////Add  0, 1  to enable L RGB & R RGB to the below array//////////
    const PCOUTPUTS = {
        HUMANOID: [0, 1, 2, 3, '4a', '4b'], //39, 40, 41
        HEXAPOD:
            deviceVersion[0] == '1' ? [0, 1, '4a', '4b'] : [0, 1, '4a', '4b'],
        KLAW: ['4b'],
    }
    const [ishelpButton, sethelpButton] = useState(false)
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    // loads the data from sessionStorage
    useEffect(() => {
        const savedData = sessionStorage.getItem('humanoidPortData')
        console.log('dasdasdassadasdadsadas', JSON.parse(savedData))
        if (savedData) {
            setPortData(JSON.parse(savedData))

            // setting storage on first visit to page
        } else {
            sessionStorage.setItem(
                'humanoidPortData',
                JSON.stringify(PORTDATA[PORTDATAKey])
            )
            /*
in the portData initial state is set directly from the PORTDATA[PORTDATAKey]
that causes if any update the state update the PORTDATA[PORTDATAKey] object
when we select any internal input or output in the page "/code/InternalAccessories" change the object PORTDATA[PORTDATAKey].
when we goes to back on page "/code/project/" and again came the "/code/InternalAccessories" we show the previous select item now also select
this happen because we used the same object,

so in this line we assign a new object in portData State to avoid this bug
*/
            setPortData(JSON.parse(sessionStorage.getItem('humanoidPortData')))
        }
        // const Index = sessionStorage.getItem('Index')
        // if (Index == null) {
        //     sessionStorage.setItem('Index', JSON.stringify([17]))
        // }
    }, [])

    // Resetting feedback message
    useEffect(() => {
        const feedbackTimer = setTimeout(() => {
            setFeedbackMsg(
                `Select the Intenal Accessories of ${connectedDeviceName}`
            )
        }, 1500)

        return () => {
            clearTimeout(feedbackTimer)
        }
    }, [portData])

    const removeFromProgram = async (e, obj) => {
        if (obj.length == 0) return
        for (var key in obj) {
            if (
                obj[key].type == 'hardware' ||
                obj[key].type == 'output' ||
                obj[key].type == 'variable_output'
            ) {
                if (e.slice(0, 5) === 'value') {
                    obj[key].state[e] = 0
                } else {
                    obj[key].state[e] = false
                }
            }
            if (
                obj[key].type == 'condition' ||
                obj[key].type == 'if' ||
                obj[key].type == 'else' ||
                obj[key].type == 'sensor' ||
                obj[key].type == 'variable' ||
                obj[key].type == 'loop'
            ) {
                await removeFromProgram(e, obj[key].subprogram)
            }
        }
    }

    // handles (un)selecting sensors
    const handleSelect = (id) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const newPortData = { ...portData }
        if (connectedDevice == 'Klaw') {
            props.selecteComponent({
                [portData[id].reduxName]: !portData[id].isPortSelected,
            })
            // updating state
            newPortData[id].isPortSelected = !portData[id].isPortSelected

            setPortData(newPortData)

            // updaing sessionStorage
            sessionStorage.setItem(
                'humanoidPortData',
                JSON.stringify(newPortData)
            )
            if (newPortData[id].isPortSelected) {
                setFeedbackMsg(`${newPortData[id].name} is selected`)
            } else {
                setFeedbackMsg(`${newPortData[id].name} is deselected`)
            }
        } else {
            // disabled M1 and m2
            if (
                (id === 39 || id === 40) &&
                deviceVersion[0] === '2' &&
                ['Humanoid'].includes(connectedDevice)
            ) {
                return
            }
            if (
                id === 2 &&
                ['1', '2'].includes(
                    deviceVersion[0] && ['Humanoid'].includes(connectedDevice)
                )
            ) {
                return
            }

            if (
                (id === 3 || id === 41) &&
                !['1', '2'].includes(
                    deviceVersion[0] && ['Humanoid'].includes(connectedDevice)
                )
            ) {
                newPortData[39].isPortSelected = false
            } else if (
                id === 39 &&
                !(
                    ['1', '2'].includes(deviceVersion[0]) &&
                    ['Humanoid'].includes(connectedDevice)
                )
            ) {
                newPortData[3].isPortSelected = false
                newPortData[41].isPortSelected = false
            }

            // individualServo and actionGroup toggle behaviour
            // if (
            //     id === '4a' &&
            //     !portData[id].isPortSelected
            // ) {
            //     newPortData['4b'].isPortSelected = false
            // } else if (
            //     id === '4b' &&
            //     !portData[id].isPortSelected &&
            //     portData['4a'].isPortSelected
            // ) {
            //     newPortData['4a'].isPortSelected = false
            // }

            // Removes the sensor data from redux logic when it is disabled.
            if (newPortData[id].isPortSelected)
                removeHumanoidLocalProgramData(id)

            // Updating Lgic Redux when Output btn disable -
            // LeftEye
            if (id === 0 && portData[0].isPortSelected) {
                // removeFromProgram('assignLeftEyeR', props.indexData.logic.program)
                // removeFromProgram('assignLeftEyeG', props.indexData.logic.program)
                // removeFromProgram('assignLeftEyeB', props.indexData.logic.program)
                // removeFromProgram('valueLeftEyeR', props.indexData.logic.program)
                // removeFromProgram('valueLeftEyeG', props.indexData.logic.program)
                // removeFromProgram('valueLeftEyeB', props.indexData.logic.program)
                for (let i = 1; i <= 3; i++) {
                    removeFromProgram(
                        `assignEye${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueEyeR${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueEyeG${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueEyeB${i}`,
                        props.indexData.logic.program
                    )
                }
            }

            // Right Eye
            if (id === 1 && portData[1].isPortSelected) {
                // removeFromProgram('assignRightEyeR', props.indexData.logic.program)
                // removeFromProgram('assignRightEyeG', props.indexData.logic.program)
                // removeFromProgram('assignRightEyeB', props.indexData.logic.program)
                // removeFromProgram('valueRightEyeR', props.indexData.logic.program)
                // removeFromProgram('valueRightEyeG', props.indexData.logic.program)
                // removeFromProgram('valueRightEyeB', props.indexData.logic.program)

                for (let i = 4; i <= 6; i++) {
                    removeFromProgram(
                        `assignEye${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueEyeR${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueEyeG${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueEyeB${i}`,
                        props.indexData.logic.program
                    )
                }
            }

            // ZingMp3
            if (id === 2 && portData[2].isPortSelected) {
                removeFromProgram(
                    'assignZingMp3',
                    props.indexData.logic.program
                )
                removeFromProgram('valueZingMp3', props.indexData.logic.program)
            }

            // Servo Head
            if (id === 3 && portData[3].isPortSelected) {
                removeFromProgram(
                    'assignHeadServo',
                    props.indexData.logic.program
                )
                removeFromProgram(
                    'valueHeadServo',
                    props.indexData.logic.program
                )
            }

            // Action Group
            if (id === '4a' && portData['4a'].isPortSelected) {
                Object.entries({
                    ...GROUPACTIONS[`${connectedDevice.toUpperCase()}`],
                    ...CUSTOMGROUPACTIONS[`${connectedDevice.toUpperCase()}`],
                }).map((action) => {
                    return removeFromProgram(
                        `assign${action[1].name}`,
                        props.indexData.logic.program
                    )
                })
            }

            // individual Servo
            if (id === '4b' && portData['4b'].isPortSelected) {
                removeFromProgram(
                    'assignServoTime',
                    props.indexData.logic.program
                )
                removeFromProgram(
                    'valueServoTime',
                    props.indexData.logic.program
                )

                for (let i = 1; i <= 16; i++) {
                    removeFromProgram(
                        `assignS${i}`,
                        props.indexData.logic.program
                    )
                    removeFromProgram(
                        `valueS${i}`,
                        props.indexData.logic.program
                    )
                }
            }

            // M1
            if (id === 39 && portData[39].isPortSelected) {
                removeFromProgram('assignM1', props.indexData.logic.program)
                removeFromProgram('valueM1', props.indexData.logic.program)
            }

            // M2
            if (id === 40 && portData[40].isPortSelected) {
                removeFromProgram('assignM2', props.indexData.logic.program)
                removeFromProgram('valueM2', props.indexData.logic.program)
            }

            // Simple Servo
            if (id === 41 && portData[41].isPortSelected) {
                removeFromProgram(
                    'assignSimpleServo',
                    props.indexData.logic.program
                )
                removeFromProgram(
                    'valueSimpleServo',
                    props.indexData.logic.program
                )
            }

            // updating redux
            props.selecteComponent({
                [portData[id].reduxName]: !portData[id].isPortSelected,
            })
            // updating state
            newPortData[id].isPortSelected = !portData[id].isPortSelected

            setPortData(newPortData)

            // updaing sessionStorage
            sessionStorage.setItem(
                'humanoidPortData',
                JSON.stringify(newPortData)
            )

            // showing Feedback message
            // if (newPortData[id].isPortSelected)
            //     setFeedbackMsg(`${newPortData[id].name} is enabled`)
            // else setFeedbackMsg(`${newPortData[id].name} is disabled`)

            if (id === '4b') {
                if (newPortData[id].isPortSelected) {
                    setFeedbackMsg(`${newPortData[id].name} are selected`)
                } else {
                    setFeedbackMsg(`${newPortData[id].name} are deselected`)
                }
            } else {
                if (newPortData[id].isPortSelected) {
                    setFeedbackMsg(`${newPortData[id].name} is selected`)
                } else {
                    setFeedbackMsg(`${newPortData[id].name} is deselected`)
                }
            }
        }
    }

    // updating the recovery data on clicking next button
    const handleClickNext = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }

        if (sessionStorage.getItem('programMode') === 'flowchartBased')
            history.push('/flow/selectports')
        else history.push('/selectScreen/ExternalAccessories')
    }

    const backBtnAction = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        // localStorage.setItem('recoveryData', JSON.stringify(sessionStorage))

        let saved = JSON.parse(sessionStorage.getItem('isSave'))
        if (saved) {
            props.history.push('/code/project')
            // window.location.reload(false)
            shouldErase('Yes')
        } else {
            setErasedProgram(true)
        }
    }

    const shouldErase = (info) => {
        if (info == 'Yes') {
            let programMode = sessionStorage.programMode
            let connectedDevice = sessionStorage.connectedDevice
            let version = sessionStorage.deviceVersion
            sessionStorage.setItem('isSave', JSON.stringify(false))
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            if (
                localStorage.getItem('recoveryData') !== null &&
                programMode in recoveryData &&
                connectedDevice in recoveryData[programMode] &&
                version in recoveryData[programMode][connectedDevice]
            ) {
                if (Object.keys(recoveryData).length === 1) {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            localStorage.removeItem('recoveryData')
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                            localStorage.setItem(
                                'recoveryData',
                                JSON.stringify(recoveryData)
                            )
                        }
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                }
            }
            sessionStorage.removeItem('humanoidPortData')
            props.clearComponents()
            sessionStorage.setItem('pip', false)
            sessionStorage.setItem('name', 'null')

            sessionStorage.removeItem('Index')
            sessionStorage.removeItem('PET')
            sessionStorage.removeItem('SelectedStatus')
            sessionStorage.removeItem('EndSwitch')
            sessionStorage.removeItem('concept')
            sessionStorage.removeItem('AppDetails')
            sessionStorage.removeItem('SelectedComp')
            sessionStorage.setItem('logic', JSON.stringify(logicNew))
            props.DeselectedComponent({
                // input
                isBattery: false,
                isUltrasonic: false,
                isGyroX: false,
                isGyroY: false,
                isGyroZ: false,
                isAccelerometerX: false,
                isAccelerometerY: false,
                isAccelerometerZ: false,

                // output

                isHeadServo: false,
                isIndivdualServo: false,
                isActionGroup: false,
                isM1: false,
                isM2: false,
                isSimpleServo: false,
                isEyeLeft: false,
                isEyeRight: false,
                isZingmp3: false,
            })
            // sessionStorage.removeItem("logic");
            // props.clearComponents();

            //updating the port data to initial state
            Object.keys(PORTDATA).forEach((port) => {
                PORTDATA[port].isPortSelected = false
                PORTDATA[port].isActionEnabled = false
            })
            if (sessionStorage.getItem('programMode') === 'flowchartBased') {
                var arr = Object.keys(sessionStorage)
                for (const i of arr) {
                    if (
                        i != 'connectedDevice' &&
                        i != 'Hardware' &&
                        i != 'userData' &&
                        i != 'concept' &&
                        i != 'webSerialPortList' &&
                        i != 'user' &&
                        i != 'deviceVersion' &&
                        i != 'programMode' &&
                        i != 'isWindowOnFocus' &&
                        i != 'slideDirection' &&
                        i != 'assembly' &&
                        i != 'logic'
                    ) {
                        sessionStorage.removeItem(i)
                    }
                }
                props.history.push('/flow')
                // window.location.reload()
            } else history.push('/code/project/')
            // window.location.reload()
        } else {
            setErasedProgram(false)
        }
    }

    const getOutputImgStyle = (id) => {
        if (
            id === 2 &&
            ['1', '2'].includes(deviceVersion[0]) &&
            ['Humanoid'].includes(connectedDevice)
        ) {
            return {
                opacity: '.5',
                pointerEvents: 'none',
            }
        } else if (
            (id === 0 || id === 1) &&
            deviceVersion.startsWith('2') &&
            connectedDevice === 'Hexapod'
        ) {
            return {
                opacity: '.5',
                pointerEvents: 'none',
            }
        } else if (
            (id === 0 || id === 1) &&
            connectedDevice === 'Humanoid' &&
            deviceVersion.startsWith('2.1')
        ) {
            return {
                opacity: '.5',
                pointerEvents: 'none',
            }
        } else
            return {
                opacity: '1',
            }
    }

    const navbar = sessionStorage.getItem('programMode')

    // Caching External Component assets
    useEffect(() => {
        let assetsToBeCached = [
            renderPrgImage('HumanoidWithPC'),
            renderCompImg('pot'),
            renderCompImg('distance_sensor'),
            renderCompImg('joystick'),
            renderCompImg('dual_switch'),
            renderCompImg('laser'),
            renderCompImg('light_sensor'),
            renderCompImg('servo_motor'),
            renderCompImg('servo_motor_270'),
            renderCompImg('servo_motor_360'),
            renderCompImg('led'),
            renderCompImg('tact_switch'),
            renderCompImg('touch_sensor'),
            renderCompImg('led_1c'),
            renderCompImg('tact_switch_2c'),
            renderCompImg('ultrasonic_sensor'),
            renderCompImg('RGB'),
            renderCompImg('OLED'),
            renderCompImg('mp3'),
            renderCompImg('dip_switch'),
            renderCompImg('rainsensor'),
            renderCompImg('linear_pot'),
            renderCompImg('humidity'),
            renderCompImg('metal_detector'),
            renderCompImg('extender'),
            renderCompImg('temperature_sensor'),
            renderCompImg('gas'),
            renderCompImg('pc_motor_driver'),
            renderCompImg('dual_splitter'),
            renderCompImg('dc_motor'),
            renderCompImg('dynamex_motor'),
            renderCompImg('geared_motor'),
            renderCompImg('mini_geared_motor'),
            renderCompImg('play_shield'),
            renderPrgImage('selectScreenH1'),
            renderPrgImage('selectScreenH2'),
            renderPrgImage('ExternalPrgf2'),
            renderPrgImage('ExternalPrgf3'),
            renderPrgImage('assemblyH'),
            renderPrgImage('Pcv1AssemblyH'),
            renderPrgImage('AssemblyPrgf1'),
            renderPrgImage('assemblyH2'),
            renderPrgImage('Pcv1AssemblyH2'),
            renderPrgImage('AssemblyPrgf2'),
            renderPrgImage('assemblyH3'),
            renderPrgImage('Pcv1AssemblyH3'),
            renderPrgImage('AssemblyPrgf3'),
            renderPrgImage('AssemblyPrgf4'),
            renderPrgImage('LogicPrgf1'),
            renderPrgImage('LogicPrgf2'),
            renderPrgImage('LogicPrgf3'),
            renderPrgImage('LogicPrgf4'),
            renderPrgImage('LogicPrgf5'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])
    let assembly = JSON.parse(sessionStorage.getItem('assembly'))

    return (
        <>
            <DialogModal
                show={erasedProgram}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldErase('Yes'),
                    },
                    {
                        text: 'NO',
                        do: () => shouldErase('No'),
                    },
                ]}
            />
            <div className={selectStyle['select-container']}>
                <div>
                    {' '}
                    {navbar === 'flowchartBased' ? (
                        <ProgramHeader
                            data={{
                                options: [
                                    'Select',
                                    'Input/Output',
                                    'Digital/Analog',
                                    'Flowchart',
                                ],
                                selected: 0,
                            }}
                            showSave={true}
                            showHelp={true}
                            handleHelp={handleClickhelpbtn}
                        />
                    ) : (
                        <ProgramHeader
                            data={{
                                options: ['Select', 'Assemble', 'Code', ''],
                                selected: 0,
                            }}
                            showSave={true}
                            showHelp={true}
                            handleHelp={handleClickhelpbtn}
                        />
                    )}
                </div>

                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    }`}
                >
                    {' '}
                    <div
                        className={
                            // connectedDevice == 'Klaw'
                            //     ? selectStyle['body-container-Klaw']
                            selectStyle['body-container']
                        }
                        style={{ height: '80vh' }}
                    >
                        {connectedDevice === 'Humanoid' && (
                            <div
                                className={selectStyle['select-humanoid']}
                                style={{
                                    backgroundImage: `url(${renderImage(
                                        'humanoid'
                                    )})`,
                                }}
                            >
                                {/* <img className={selectStyle["select-buzzer"]} src={isBuzzer.img} /> */}
                                <img
                                    className={selectStyle['select-leftEye']}
                                    src={
                                        portData[0].isPortSelected
                                            ? renderImage('activeEye')
                                            : renderImage('inactiveEye')
                                    }
                                />
                                <img
                                    className={selectStyle['select-rightEye']}
                                    src={
                                        portData[1].isPortSelected
                                            ? renderImage('activeEye')
                                            : renderImage('inactiveEye')
                                    }
                                />
                            </div>
                        )}
                        {connectedDevice == 'Hexapod' && (
                            <div
                                className={selectStyle['select-humanoid']}
                                style={{
                                    height: '25vw',
                                    width: '29vw',
                                    backgroundImage: `url(${renderImage(
                                        'hexapod'
                                    )})`,
                                }}
                            >
                                {/* <img
                                    className={selectStyle['select-buzzer']}
                                    src={
                                        portData[2].isPortSelected
                                            ? renderImage('activeBuzzer')
                                            : renderImage('inactiveBuzzer')
                                    }
                                /> */}
                            </div>
                        )}
                        {connectedDevice == 'Klaw' && (
                            <div
                                className={selectStyle['select-humanoid']}
                                style={{
                                    height: '34vw',
                                    width: '29vw',
                                    backgroundImage: `url(${renderImage(
                                        'NewKlaw_3x'
                                    )})`,
                                }}
                            >
                                {/* <img
                                    className={selectStyle['select-buzzer']}
                                    src={
                                        portData[2].isPortSelected
                                            ? renderImage('activeBuzzer')
                                            : renderImage('inactiveBuzzer')
                                    }
                                /> */}
                            </div>
                        )}
                        {/* <img
                            className={selectStyle['select-buzzer']}
                            src={
                                portData[2].isPortSelected
                                    ? renderImage('activeBuzzer')
                                    : renderImage('inactiveBuzzer')
                            }
                        /> */}
                        {connectedDevice == 'Klaw' && (
                            <>
                                <div
                                    className={selectStyle['select-popupcard1']}
                                    style={{
                                        backgroundImage: `url(${renderImage(
                                            'popupcard'
                                        )})`,
                                    }}
                                >
                                    <p className={selectStyle['select-inputs']}>
                                        Inputs
                                    </p>

                                    <div
                                        style={{
                                            height: '45%',
                                            width: '56%',
                                            position: 'relative',
                                            top: '57%',
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        {INPUTS.map((id) => (
                                            <img
                                                key={id}
                                                id={id}
                                                className={`${
                                                    selectStyle[
                                                        'sm-popupcard-button'
                                                    ]
                                                } ${
                                                    selectStyle[
                                                        UIDATA[
                                                            `${connectedDevice.toUpperCase()}`
                                                        ][id].className
                                                    ]
                                                }`}
                                                src={
                                                    portData[id].isPortSelected
                                                        ? UIDATA[
                                                              `${connectedDevice.toUpperCase()}`
                                                          ][id].activeImg
                                                        : UIDATA[
                                                              `${connectedDevice.toUpperCase()}`
                                                          ][id].inActiveImg
                                                }
                                                onClick={() => handleSelect(id)}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <div
                                    className={selectStyle['select-popupcard1']}
                                    style={{
                                        backgroundImage: `url(${renderImage(
                                            'popupcard'
                                        )})`,
                                    }}
                                >
                                    <p
                                        className={
                                            selectStyle['select-Outputs']
                                        }
                                    >
                                        Outputs
                                    </p>
                                    <div
                                        style={{
                                            height: '45%',
                                            width: '60%',
                                            position: 'relative',
                                            top: '57%',
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        {PCOUTPUTS[
                                            connectedDevice.toUpperCase()
                                        ].map((id) => (
                                            <>
                                                <img
                                                    key={id}
                                                    id={id}
                                                    className={`${
                                                        selectStyle[
                                                            'sm-outputpopupcard-button'
                                                        ]
                                                    } ${
                                                        selectStyle[
                                                            UIDATA[
                                                                `${connectedDevice.toUpperCase()}`
                                                            ][id].className
                                                        ]
                                                    }`}
                                                    src={
                                                        portData[id]
                                                            .isPortSelected
                                                            ? UIDATA[
                                                                  `${connectedDevice.toUpperCase()}`
                                                              ][id].activeImg
                                                            : UIDATA[
                                                                  `${connectedDevice.toUpperCase()}`
                                                              ][id].inActiveImg
                                                    }
                                                    onClick={() =>
                                                        handleSelect(id)
                                                    }
                                                />
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        {['Hexapod', 'Humanoid'].includes(connectedDevice) && (
                            <>
                                <div
                                    className={selectStyle['select-popupcard1']}
                                    style={{
                                        backgroundImage: `url(${renderImage(
                                            'popupcard'
                                        )})`,
                                    }}
                                >
                                    <p className={selectStyle['select-inputs']}>
                                        Inputs
                                    </p>

                                    <div
                                        style={{
                                            height: '45%',
                                            width: '56%',
                                            position: 'relative',
                                            top: '57%',
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        {INPUTS.map((id) => (
                                            <img
                                                key={id}
                                                id={id}
                                                className={`${
                                                    selectStyle[
                                                        'sm-popupcard-button'
                                                    ]
                                                } ${
                                                    selectStyle[
                                                        UIDATA[
                                                            `${connectedDevice.toUpperCase()}`
                                                        ][id].className
                                                    ]
                                                }`}
                                                style={
                                                    (id === 23 ||
                                                        id === 24 ||
                                                        id === 25) &&
                                                    ((['1'].includes(
                                                        deviceVersion
                                                    ) &&
                                                        ['Humanoid'].includes(
                                                            connectedDevice
                                                        )) ||
                                                        connectedDevice ==
                                                            'Hexapod') &&
                                                    assembly.PortConnections
                                                        .D != null &&
                                                    assembly.PortConnections.D
                                                        .type != 'OLED'
                                                        ? {
                                                              opacity: '.5',
                                                              pointerEvents:
                                                                  'none',
                                                          }
                                                        : { opacity: '1' }
                                                }
                                                src={
                                                    portData[id].isPortSelected
                                                        ? UIDATA[
                                                              `${connectedDevice.toUpperCase()}`
                                                          ][id].activeImg
                                                        : UIDATA[
                                                              `${connectedDevice.toUpperCase()}`
                                                          ][id].inActiveImg
                                                }
                                                onClick={() => handleSelect(id)}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div
                                    className={selectStyle['select-popupcard1']}
                                    style={{
                                        backgroundImage: `url(${renderImage(
                                            'popupcard'
                                        )})`,
                                    }}
                                >
                                    <p
                                        className={
                                            selectStyle['select-Outputs']
                                        }
                                    >
                                        Outputs
                                    </p>
                                    {deviceVersion.includes('0.1') &&
                                    ['Humanoid'].includes(connectedDevice) ? ( //  ZingVersion == 0 || ZingVersion.includes('0.1')
                                        <div
                                            style={{
                                                height: '45%',
                                                width: '60%',
                                                position: 'relative',
                                                top: '57%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%,-50%)',
                                            }}
                                        >
                                            {OUTPUTS.map((id) => (
                                                <img
                                                    key={id}
                                                    id={id}
                                                    className={`${
                                                        selectStyle[
                                                            'sm-outputpopupcard-button'
                                                        ]
                                                    } ${
                                                        selectStyle[
                                                            UIDATA[
                                                                `${connectedDevice.toUpperCase()}`
                                                            ][id].className
                                                        ]
                                                    }`}
                                                    src={
                                                        portData[id]
                                                            .isPortSelected
                                                            ? UIDATA[
                                                                  `${connectedDevice.toUpperCase()}`
                                                              ][id].activeImg
                                                            : UIDATA[
                                                                  `${connectedDevice.toUpperCase()}`
                                                              ][id].inActiveImg
                                                    }
                                                    style={
                                                        id === 3 ||
                                                        id === 39 ||
                                                        id === 40 ||
                                                        id === 0 ||
                                                        id === 1
                                                            ? {
                                                                  opacity:
                                                                      '0.6',
                                                                  pointerEvents:
                                                                      'none',
                                                              }
                                                            : null
                                                    }
                                                    // style={
                                                    //     (id === 0 ||
                                                    //         id === 1 ||
                                                    //         id === 3) &&
                                                    //     !sessionStorage
                                                    //         .getItem('zingVersion')
                                                    //         .includes('ZG1.0')
                                                    //         ? {
                                                    //               opacity: '.5',
                                                    //               pointerEvents: 'none',
                                                    //           }
                                                    //         : { opacity: '1' }
                                                    // }

                                                    onClick={() =>
                                                        handleSelect(id)
                                                    }
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                height: '45%',
                                                width: '60%',
                                                position: 'relative',
                                                top: '57%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%,-50%)',
                                            }}
                                        >
                                            {PCOUTPUTS[
                                                connectedDevice.toUpperCase()
                                            ].map((id) => (
                                                <img
                                                    key={id}
                                                    id={id}
                                                    className={`${
                                                        selectStyle[
                                                            'sm-outputpopupcard-button'
                                                        ]
                                                    } ${
                                                        selectStyle[
                                                            UIDATA[
                                                                `${connectedDevice.toUpperCase()}`
                                                            ][id].className
                                                        ]
                                                    }`}
                                                    src={
                                                        portData[id]
                                                            .isPortSelected
                                                            ? UIDATA[
                                                                  `${connectedDevice.toUpperCase()}`
                                                              ][id].activeImg
                                                            : UIDATA[
                                                                  `${connectedDevice.toUpperCase()}`
                                                              ][id].inActiveImg
                                                    }
                                                    style={
                                                        getOutputImgStyle(id)
                                                        // id === 40 &&
                                                        // assembly.PortConnections.B !=null

                                                        /////////////////
                                                        // id === 2 &&
                                                        // ['1', '2'].includes(
                                                        //     deviceVersion[0]
                                                        // ) &&
                                                        // ['Humanoid'].includes(
                                                        //     connectedDevice
                                                        // )
                                                        //     ? {
                                                        //           opacity: '.5',
                                                        //           pointerEvents:
                                                        //               'none',
                                                        //       }
                                                        //     : (id === 0 ||
                                                        //           id === 1) &&
                                                        //       deviceVersion.startsWith(
                                                        //           '2.0'
                                                        //       ) &&
                                                        //       connectedDevice ===
                                                        //           'Hexapod'
                                                        //     ? {
                                                        //           opacity: '.5',
                                                        //           pointerEvents:
                                                        //               'none',
                                                        //       }
                                                        //     : (id === 0 ||
                                                        //           id === 1) &&
                                                        //       connectedDevice ===
                                                        //           'Humanoid' &&
                                                        //       deviceVersion.startsWith(
                                                        //           '2'
                                                        //       )
                                                        //     ? {
                                                        //           opacity: '.5',
                                                        //           pointerEvents:
                                                        //               'none',
                                                        //       }
                                                        //     : { opacity: '1' }
                                                    }
                                                    onClick={() =>
                                                        handleSelect(id)
                                                    }
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={selectStyle['select-bottom']}>
                    {sessionStorage.getItem('programMode') ===
                    'projectBased' ? (
                        <Footer
                            isGoBack={false}
                            goBack={backBtnAction}
                            title={feedbackMsg}
                            isGoNext={true}
                            goNext={handleClickNext}
                        />
                    ) : (
                        <>
                            <div className="SelectScreenBottom">
                                <div className="bottom-child">
                                    {/* <Link to="/programSelection"> */}
                                    <img
                                        className="iconBtnSize imgBackBtn"
                                        src={renderImage('backbutton')}
                                        onClick={backBtnAction}
                                    />
                                    {/* </Link> */}
                                    <div
                                        style={{
                                            fontFamily: 'Halcyon_SemiBold',
                                            color: 'rgb(112, 112, 112)',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                        }}
                                    >
                                        <p>{feedbackMsg}</p>
                                    </div>

                                    <img
                                        className="iconBtnSize imgNextBtn"
                                        src={renderImage('nextbutton')}
                                        onClick={handleClickNext}
                                    />
                                </div>
                            </div>
                            {/* <img
                                className={selectStyle['select-backbutton']}
                                src={renderImage('backbutton')}
                                onClick={backBtnAction}
                            />
                            <p className={selectStyle['select-description']}>
                                {feedbackMsg}
                            </p>
                            <img
                                className={selectStyle['select-nextbutton']}
                                src={renderImage('nextbutton')}
                                onClick={handleClickNext}
                            /> */}
                        </>
                    )}

                    {/* <img
                        className={selectStyle['select-backbutton']}
                        src={renderImage('backbutton')}
                        onClick={backBtnAction}
                    />
                    <p className={selectStyle['select-description']}>
                        {feedbackMsg}
                    </p>
                    <img
                        className={selectStyle['select-nextbutton']}
                        src={renderImage('nextbutton')}
                        onClick={handleClickNext}
                    /> */}
                </div>

                {ishelpButton == true ? (
                    <div
                        style={{
                            height: '100vh',
                            width: '100vw',
                            background: '#fff',
                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            zIndex: '1000',
                            position: 'absolute',
                        }}
                    >
                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '15%',
                                right: '18%',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                        <Slider
                            {...settings}
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '46%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                            }}
                        >
                            {/* First screen */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={renderPrgImage('selectScreenH1')}
                                    style={{ width: '100%' }}
                                />

                                <div
                                    style={{
                                        height: '6%',
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '38.5%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        The list of all internal
                                        accessories-Input type, that can be
                                        enabled or disabled
                                    </p>
                                </div>
                            </div>
                            {/* second screen */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={renderPrgImage('selectScreenH2')}
                                    style={{ width: '100%' }}
                                />
                                <div
                                    style={{
                                        height: '6%',
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '58.1%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        The list of all internal
                                        accessories-Output type, that can be
                                        enabled or disabled
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                ) : null}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        indexData: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        clearComponents: () => {
            dispatch({ type: 'CLEAR_COMPONENT_SELECTION' })
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HumanoidSelectFeatures)
