import React from 'react'
import renderImage from '../../../source/importImg'
import { useHistory } from 'react-router'
import renderPrgImage from '../../../source/programImg'

function Footer({ goBack, isGoBack, title, goNext, isGoNext, onMouseDowns }) {
    const history = useHistory()
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
            }}
        >
            <div
                style={{
                    width: '10%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {isGoBack || goBack ? (
                    <img
                        style={{
                            width: '60px',
                            height: '60px',
                            cursor: 'pointer',
                        }}
                        src={renderImage('backBtn')}
                        onClick={() => {
                            if (goBack) goBack()
                            else history.goBack()
                        }}
                    ></img>
                ) : null}
            </div>
            <div
                style={{
                    width: '80%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#707070',
                }}
            >
                {' '}
                <p>{title}</p>
            </div>
            <div
                style={{
                    width: '10%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '999',
                }}
            >
                {isGoNext ? (
                    <img
                        style={{
                            width: '60px',
                            height: '60px',
                            cursor: 'pointer',
                        }}
                        src={renderPrgImage('nextBtn')}
                        onClick={() => {
                            goNext()
                        }}
                    ></img>
                ) : null}
            </div>
        </div>
    )
}
export default Footer
