import { DEFAULTSERVOVALUES } from '../Components/Code/CreateAction/defaultData'
import { PORTDATA } from '../Components/Humanoid/defaultData'
import { generateDeviceDataKey } from '../Components/ReusableComponents/ByteTransfer/utils'

const removeHumanoidLocalProgramData = (port) => {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const humanoidProgramData = JSON.parse(
        sessionStorage.getItem('humanoidProgramData')
    )

    if (!humanoidProgramData) return

    if (typeof port !== 'number' && port != '4a' && port != '4b')
        port = getSensorIdFromPort(port)

    console.log('Edwin', port)

    Object.entries(humanoidProgramData).map((block) => {
        if (!block[1].state) return

        if (
            block[1].type == 'condition' &&
            port >= 21 &&
            port <= 28 &&
            block[1].state.port == port
        ) {
            humanoidProgramData[block[0]].state.port = ''
            humanoidProgramData[block[0]].state.value = 0
            humanoidProgramData[block[0]].state.value1 = 0
            ////fix: playC1074 : state update on sensor change
            humanoidProgramData[block[0]].state.condition = ''
        }
        const servoDataKey = generateDeviceDataKey(
            connectedDevice,
            deviceVersion
        )
        Object.entries(block[1].state).map((sensor) => {
            // OUTPUT - Individual servos : 1 - 16
            if (port === '4b' && sensor[0] >= 5 && sensor[0] <= 20) {
                humanoidProgramData[block[0]].state[sensor[0]].value =
                    DEFAULTSERVOVALUES[servoDataKey][sensor[0] - 4]
                humanoidProgramData[block[0]].state[
                    sensor[0]
                ].isActionEnabled = false

                // OUTPUT - Few Internal and all External sensors
            } else if (port !== '4b' && sensor[0] == port) {
                if (port === 0 || port === 1)
                    humanoidProgramData[block[0]].state[sensor[0]].value = [
                        0, 0, 0,
                    ]
                else humanoidProgramData[block[0]].state[sensor[0]].value = 0
                humanoidProgramData[block[0]].state[
                    sensor[0]
                ].isActionEnabled = false
            }
        })
    })

    sessionStorage.setItem(
        'humanoidProgramData',
        JSON.stringify(humanoidProgramData)
    )
}

const getSensorIdFromPort = (port) => {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    let res = null

    if (port && port.match(/^[ABCDEFGHI]$/)) port = `${port[0]}1`
    let PORTDATAKey = generateDeviceDataKey(connectedDevice, deviceVersion)
    let PORTData = PORTDATA[PORTDATAKey]
    Object.entries(PORTData).map((sensor) => {
        if (sensor[1].name == `Port ${port}`) res = sensor[0]
    })

    return res
}

export default removeHumanoidLocalProgramData
