import React from 'react'
import './newSlider.css'

function OnlySlider({ min, max, value, onChange }) {
    return (
        <input
            type="range"
            className="onlySlider"
            min={min}
            max={max}
            value={value}
            onChange={onChange}
        />
    )
}

export default OnlySlider
