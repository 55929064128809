import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useIndexedDB } from 'react-indexed-db'
import {
    readBytesAppMode,
    sendBytes,
} from '../../Components/ReusableComponents/ByteTransfer/byteTransfer'
import pause from '../../Assets/Bisoft_UI/Buttons/Active with shadow/pause_btn@3x.png'
import backY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import play from '../../Assets/Bisoft_UI/Buttons/Active with shadow/play-run.png'
import close from '../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import cameraP5 from '../../Assets/Bisoft_UI/AppMode/camera(speech).png'
import selectBarfive from '../../Assets/Bisoft_UI/AppMode/selectBarfive.png'
import popupCardfive from '../../Assets/Bisoft_UI/AppMode/popupCardfive.png'
import popupCardone from '../../Assets/Bisoft_UI/AppMode/popupCardone.png'
import projectbig from '../../Assets/Bisoft_UI/AppMode/projectBig.png'
import musicIcon from '../../Assets/Bisoft_UI/AppMode/musicIcon.png'
import NavBar from './NavBar'
import styles from './Appmode.module.css'
import { connect } from 'react-redux'
import closeBtn from '../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../source/programImg'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}
let send = false

const PlayComputer = (props) => {
    const allDetails = JSON.parse(sessionStorage.getItem('allDetails'))
    const history = useHistory()
    const { getByID } = useIndexedDB('ProgramData')
    const [isPlay, setPlay] = useState(false)
    const [text, setText] = useState('value')
    const [image, setImage] = useState(popupCardfive)
    const [popup, setPopup] = useState(false)
    const [display, setDisplay] = useState(true)
    const [size, setSize] = useState(0)
    const sendBytesInterval = React.useRef()
    const readBytesInterval = React.useRef()
    const [ishelpButton, sethelpButton] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')

    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }
    const closeModal = () => {
        sethelpButton(false)
    }

    useEffect(() => {
        if (isPlay === true) {
            ///////////// Setting Interval for sending bytes every 2.5 secs////////////////
            sendBytesInterval.current = setInterval(() => {
                if (
                    (connectedDevice == 'Ace' &&
                        deviceVersion?.startsWith('1')) ||
                    deviceVersion?.startsWith('2') ||
                    connectedDevice == 'Roboki'
                ) {
                    props.worker.postMessage({
                        type: 'writeArray',
                        value: [85, 83, 0],
                    })
                    send = true
                } else if (props.webSerial.port.readable.locked === false) {
                    sendBytes([85, 83, 0], props.webSerial.port)
                    send = true
                }
            }, 2500)

            ///////////// Setting Interval for Reading bytes every 10 secs////////////////
            ////////////  10 secs because only then user will able to see actions ////////
            if (
                !(connectedDevice == 'Ace' && deviceVersion?.startsWith('1')) &&
                // !sessionStorage.getItem('zingVersion')?.startsWith('2') &&
                !deviceVersion?.startsWith('2') &&
                connectedDevice !== 'Roboki'
            )
                readBytesInterval.current = setInterval(async () => {
                    console.log('Reading')
                    let bytes
                    if (send === true) {
                        bytes = await readBytesAppMode(props.webSerial.port, '')
                        send = false
                        if (
                            bytes !== undefined &&
                            bytes.split('\n').length >= 2
                        ) {
                            let length = bytes.split('\n').length
                            bytes = bytes.split('\n')[length - 1]
                        }
                        if (
                            bytes !== undefined &&
                            2 < bytes.length <= 5 &&
                            bytes.slice(0, 2) === 'US'
                        ) {
                            Object.keys(allDetails).map((value) => {
                                if (
                                    allDetails[value].usbValue ===
                                    bytes.slice(2)
                                ) {
                                    if (
                                        allDetails[value].displayText !== null
                                    ) {
                                        setText(allDetails[value].displayText)
                                    }
                                    if (allDetails[value].image !== null) {
                                        getByID(allDetails[value].id).then(
                                            (event) => {
                                                setImage(event.imageURL)
                                            }
                                        )
                                    }
                                    if (
                                        allDetails[value].sliderOption === 'TX1'
                                    ) {
                                        sendBytes(
                                            [
                                                85,
                                                83,
                                                Number(
                                                    allDetails[value]
                                                        .sliderValue
                                                ),
                                            ],
                                            props.webSerial.port
                                        )
                                        send = true
                                    }
                                    setSize(allDetails[value].selectedSize)
                                }
                            })
                        }
                    }
                }, 10000)
        }
        return () => {
            props.worker.onmessage = (e) => {
                if (e.data.type === 'Appread') {
                    console.log('e', e)
                    if (isPlay) return
                    console.log('gsk value', e.data.value)
                    console.log('all details', allDetails)
                    let val = e.data.value
                    Object.keys(allDetails).map((value) => {
                        console.log(
                            allDetails[value].usbValue,
                            val[3],
                            'gsk value',
                            isPlay
                        )
                        if (allDetails[value].usbValue == val[3]) {
                            if (allDetails[value].displayText !== null) {
                                setText(allDetails[value].displayText)
                            }
                            if (allDetails[value].image !== null) {
                                getByID(allDetails[value].id).then((event) => {
                                    setImage(event.imageURL)
                                })
                            }
                            if (allDetails[value].sliderOption === 'TX1') {
                                console.log('data sending from here:')
                                props.worker.postMessage({
                                    type: 'writeArray',
                                    value: [
                                        85,
                                        83,
                                        Number(allDetails[value].sliderValue),
                                    ],
                                })
                                send = true
                            }
                            setSize(allDetails[value].selectedSize)
                        }
                    })
                    // if (value.includes('US'))
                }
            }
        }
    }, [isPlay])
    useEffect(
        () => () => {
            clearInterval(sendBytesInterval.current)
            clearInterval(readBytesInterval.current)
        },
        []
    )
    const Popup = (props) => {
        useEffect(() => {
            if (props.trigger) {
                props.set(false)
            }
        }, [])

        return props.trigger ? (
            <div className={styles.popup}>
                <div className={styles.popupInner}>
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            bottom: '70vh',
                            right: '-1.2vw',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setPopup(false)
                            props.set(true)
                        }}
                    />
                </div>
                {props.displayImage !== popupCardfive ? (
                    <div style={{ zIndex: '3' }}>
                        <img
                            style={{ width: '100%', maxWidth: '60vw' }}
                            src={props.displayImage}
                            height="350vh"
                            width="350vw"
                            alt="displayImage"
                            draggable="false"
                        />
                        <p
                            style={{
                                fontSize: `${2 * Number(props.font)}vh`,
                                zIndex: '4',
                                textAlign: 'center',
                            }}
                        >
                            {props.displayText}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </div>
        ) : (
            ''
        )
    }

    const stopPlay = () => {
        setImage(popupCardfive)
        setText('value')
    }

    return (
        <div>
            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectBarfive}
                    selectbarwidth="90%"
                    headers={[
                        { name: 'Enable', color: 'white' },
                        { name: 'Control', color: 'white' },
                        { name: 'Run', color: 'white' },
                    ]}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
            </div>
            {/* Main */}
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
            >
                <div style={{ position: 'relative' }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: '22vh',
                            right: '25vw',
                            zIndex: '1',
                        }}
                    >
                        <label>
                            <select
                                className={styles.selectBox}
                                style={{
                                    position: 'absolute',
                                    right: '14vw',
                                    top: '16vh',
                                    width: '19.4vw',
                                    height: '6.2vh',
                                    font: 'Halcyon Regular',
                                    fontFamily: 'Halcyon Regular',
                                    fontSize: '15px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <option>Text </option>
                            </select>
                        </label>
                    </div>
                    {/* Element to Move Dynamically */}
                    <div
                        style={{
                            position: 'relative',
                            top: '53vh',
                            left: '2vh',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '15px',
                            zIndex: '1',
                            textAlign: 'center',
                        }}
                    >
                        <strong>{text}</strong>
                    </div>
                    <img
                        src={popupCardfive}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '39vh',
                            left: '18vw',
                            width: '23.5vw',
                            height: '62vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={cameraP5}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '35vh',
                            left: '18vw',
                            width: '6vw',
                            // height: "12vh",
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            left: '18%',
                            top: '41vh',
                            transform: 'translate(-50%,0%)',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '15px',
                            textAlign: 'center',
                        }}
                    >
                        Camera Inactive
                    </p>
                    {display === true && image !== null ? (
                        <img
                            src={image}
                            draggable="false"
                            alt="displayimage"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '39vh',
                                left: '83vw',
                                width: '19vw',
                                height: '62vh',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setPopup(true)
                            }}
                        />
                    ) : (
                        <img
                            src={popupCardfive}
                            draggable="false"
                            alt="displayimage"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '39vh',
                                left: '83vw',
                                width: '19vw',
                                height: '62vh',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    <Popup
                        trigger={popup}
                        displayImage={image}
                        displayText={text}
                        font={size}
                        set={setDisplay}
                    />
                    <img
                        src={popupCardfive}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '50.5vh',
                            left: '51.2vw',
                            width: '26.8vw',
                            height: '39vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={popupCardone}
                        draggable="false"
                        alt="popupCardone"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '23vh',
                            left: '50.3vw',
                            width: '34vw',
                            height: '33vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={musicIcon}
                        draggable="false"
                        alt="popupCardone"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '23vh',
                            left: '45.3vw',
                            width: '7vw',
                            // height: "10vh",
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={projectbig}
                        draggable="false"
                        alt="projectbig"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '55.8vh',
                            left: '51.4vw',
                            width: '24vw',
                            height: '23vh',
                            borderWidth: 2,
                            borderColor: 'black',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                </div>
            </div>

            {/* Footer */}
            <div>
                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        if (!isPlay) {
                            stopPlay()
                            clearInterval(sendBytesInterval.current)
                            clearInterval(readBytesInterval.current)
                            history.push('/programmingPage')
                        } else {
                            history.push('/programmingPage')
                        }
                    }}
                />
                {isPlay === false ? (
                    <img
                        src={play}
                        draggable="false"
                        alt="play"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '92vh',
                            left: '91.5vw',
                            width: '4vw',
                            cursor: 'pointer',
                            transform: 'translate(-50%, -50%)',
                        }}
                        onClick={() => {
                            setPlay(true)
                        }}
                    />
                ) : (
                    <>
                        <img
                            src={pause}
                            draggable="false"
                            alt="pause"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '93vh',
                                left: '91.5vw',
                                width: '4vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setPlay(false)
                                stopPlay()
                                clearInterval(sendBytesInterval.current)
                                clearInterval(readBytesInterval.current)
                            }}
                        />
                    </>
                )}
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '25.4%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Camera scene is the output window of what
                                    the web camera will see & detect
                                </p>
                            </div>
                        </div>

                        {/* <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH1')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '41.5%',
                                    left: '23.5%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Message & values display screen show the
                                    message or values as an output for the
                                    program that is running
                                </p>
                            </div>
                        </div> */}

                        {/* <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH2')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '60.5%',
                                    left: '30.7%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Message & values display screen show the
                                    message or values as an output for the
                                    program that is running
                                </p>
                            </div>
                        </div> */}

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH3')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '55%',
                                    left: '34.8%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    The image/video output space, shows the
                                    images if any of the if condition is true
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH4')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '78%',
                                    left: '46.6%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to run the app mode.
                                    The respective action will be executed, when
                                    the if condition is true
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH5')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '78%',
                                    left: '56.6%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to stop the app mode
                                    code execution
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}
const mapStateToProps = (state) => {
    // console.log("mapStateToProps", state);

    return state
}

export default connect(mapStateToProps)(PlayComputer)
