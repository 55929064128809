import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import NavBar from './NavBar'
import center from '../../Assets/Bisoft_UI/AppMode/joystickMain.svg'
import right from '../../Assets/Bisoft_UI/AppMode/Right.svg'
import left from '../../Assets/Bisoft_UI/AppMode/Left.svg'
import Top from '../../Assets/Bisoft_UI/AppMode/Top.svg'
import Down from '../../Assets/Bisoft_UI/AppMode/Down.svg'
import One from '../../Assets/Bisoft_UI/AppMode/one.svg'
import Two from '../../Assets/Bisoft_UI/AppMode/two.svg'
import Three from '../../Assets/Bisoft_UI/AppMode/three.svg'
import Four from '../../Assets/Bisoft_UI/AppMode/four.svg'
import Five from '../../Assets/Bisoft_UI/AppMode/five.svg'
import Six from '../../Assets/Bisoft_UI/AppMode/six.svg'
import Seven from '../../Assets/Bisoft_UI/AppMode/seven.svg'
import Eight from '../../Assets/Bisoft_UI/AppMode/eight.svg'
import backY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import selectBarfive from '../../Assets/Bisoft_UI/AppMode/selectBarfive.png'
import { useHistory } from 'react-router'
import cacheAssets from '../../utils/cacheAssets'
import AppMode_Img from '../../source/AppMode_Img'
import { sendBytes } from '../ReusableComponents/ByteTransfer/byteTransfer'
import closeBtn from '../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../source/programImg'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

const Remote = (props) => {
    const history = useHistory()
    const images = [One, Two, Three, Four, Five, Six, Seven, Eight]
    const [value, setValue] = useState('')
    const version = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice') || ''
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const [ishelpButton, sethelpButton] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    useEffect(() => {
        let assetsToBeCached = [
            AppMode_Img('selectbarX'),
            AppMode_Img('ellipse'),
            AppMode_Img('buttonPlus'),
            AppMode_Img('popupCard'),
            AppMode_Img('pause'),
            AppMode_Img('play'),
            AppMode_Img('cameraP5'),
            AppMode_Img('selectBarfive'),
            AppMode_Img('popupCardfive'),
            AppMode_Img('popupCardone'),
            AppMode_Img('projectbig'),
            AppMode_Img('musicIcon'),
            AppMode_Img('RecognizeText'),
            AppMode_Img('faceY'),
            AppMode_Img('faceXActive'),
            AppMode_Img('objectY'),
            AppMode_Img('objectXActive'),
            AppMode_Img('emotionY'),
            AppMode_Img('emotionXActive'),
            AppMode_Img('skip'),
            AppMode_Img('captureButton'),
            AppMode_Img('startCamera'),
            AppMode_Img('PopupCard'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const setRemoteBytes = (byte) => {
        setValue(byte)
        let byteStream = []
        if (version?.startsWith('0')) {
            byteStream = ['G'.charCodeAt(), 0]
            byteStream[1] = Number(byte)
        } else {
            byteStream = ['X'.charCodeAt(), 'J'.charCodeAt(), 0]
            byteStream[2] = Number(byte)
        }
        writePort(byteStream)
    }

    const writePort = async (data) => {
        if (props.webSerial.isConnected) {
            // if (
            //     (version?.startsWith('1') &&
            //         ['Ace'].includes(connectedDevice)) ||
            //     version?.startsWith('2') ||
            //     ['Roboki'].includes(connectedDevice)
            // ) {
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
            // } else {
            //     if (props.webSerial.port)
            //         await sendBytes(data, props.webSerial.port)
            // }
        }
    }
    const imgStyle = {
        cursor: 'pointer',
        width: '90px',
    }

    return (
        <div
            style={{
                // border: '2px solid red',
                display: 'grid',
                gridTemplateColumns: '100%',
                gridTemplateRows: '10% 70% 20%',
                height: '100%',
            }}
        >
            {/* NavBar */}
            <div
            // style={{ border: '2px solid yellow' }}
            >
                <NavBar
                    selectbar={selectBarfive}
                    selectbarwidth="80%"
                    headers={[
                        { name: 'Enable', color: 'white' },
                        { name: 'Run', color: 'white' },
                    ]}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
            </div>
            {/* Main */}

            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
            >
                {' '}
                <div
                    style={{
                        // border: '2px solid blue',
                        position: 'relative',
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '50% 50% ',
                            width: '100%',
                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                top: '7%',
                                left: '19%',
                                display: 'grid',
                                gridTemplateColumns: '43% 14% 43% ',
                                gridTemplateRows: '33.3% 33.3% 33.3% ',
                                position: 'relative',
                            }}
                        >
                            <div></div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    justifySelf: 'center',
                                    alignSelf: 'center',
                                }}
                            >
                                <img
                                    src={Top}
                                    id="9"
                                    style={imgStyle}
                                    draggable="false"
                                    onClick={(event) => {
                                        setRemoteBytes(event.target.id)
                                    }}
                                />
                            </div>
                            <div></div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    justifySelf: 'end',
                                    alignSelf: 'center',
                                }}
                            >
                                <img
                                    src={left}
                                    id={12}
                                    style={imgStyle}
                                    draggable="false"
                                    onClick={(event) => {
                                        setRemoteBytes(event.target.id)
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    justifySelf: 'center',
                                    alignSelf: 'center',
                                }}
                            >
                                <img
                                    src={center}
                                    id={0}
                                    draggable="false"
                                    style={{ cursor: 'pointer', width: '75px' }}
                                    onClick={(event) => {
                                        setRemoteBytes(event.target.id)
                                    }}
                                />
                            </div>
                            <img
                                src={right}
                                id={10}
                                draggable="false"
                                style={imgStyle}
                                onClick={(event) => {
                                    setRemoteBytes(event.target.id)
                                }}
                            />
                            <div></div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    justifySelf: 'center',
                                    alignSelf: 'center',
                                }}
                            >
                                <img
                                    src={Down}
                                    id={11}
                                    style={imgStyle}
                                    draggable="false"
                                    onClick={(event) => {
                                        setRemoteBytes(event.target.id)
                                    }}
                                />
                            </div>
                            <div></div>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                top: '28%',
                                display: 'grid',
                                gridTemplateColumns: '11% 11% 11% 11% ',
                                gridTemplateRows: '32% 32% ',
                                columnGap: '3vh',
                                position: 'relative',
                            }}
                        >
                            {images.map((image, index) => {
                                return (
                                    <div key={index}>
                                        <img
                                            src={image}
                                            id={index + 1}
                                            style={{
                                                // padding: '10px',
                                                width: '70px',
                                                cursor: 'pointer',
                                                borderRadius: '45px',
                                            }}
                                            draggable="false"
                                            onClick={(event) => {
                                                setRemoteBytes(event.target.id)
                                            }}
                                        />
                                        {index === 3 ? <br></br> : null}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div>
                        <input
                            type="text"
                            value={value}
                            style={{
                                left: '50%',
                                top: '97%',
                                position: 'absolute',
                                transform: 'translate(-50%,-50%)',
                                textAlign: 'center',
                                height: '4vh',
                                borderRadius: '20px',
                                border: '2.5px solid black',
                                color: 'black',
                                outline: 'none',
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div
            // tyle={{ border: '3px solid green ' }}
            >
                <img
                    src={backY}
                    alt="back"
                    draggable="false"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/chooseDevice')
                    }}
                />
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('appModeRemoteH')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '54%',
                                    left: '43.1%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    There are 5 buttons is a form of
                                    joystick/direction button to control your
                                    device, provided you have programmed in at
                                    the code mode
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('appModeRemoteH1')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '54%',
                                    left: '56.2%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    There are 8 button, which can be assigned to
                                    perform any desired action, on the device.
                                    You need to program the device to detect
                                    these button input accordingly at the Code
                                    mode of the app.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(Remote)
