export var portsData = [
    {
        key: 'A',
        ports: [
            {
                portName: 'A1',
                isActive: false,
                isDigital: false,
                isColor: '#E25432',
            },
            {
                portName: 'A2',
                isActive: false,
                isDigital: false,
                isColor: '#B0D745',
            },
        ],
    },

    {
        key: 'B',
        ports: [
            {
                portName: 'B1',
                isActive: false,
                isDigital: false,
                isColor: '#23DE93',
            },
            {
                portName: 'B2',
                isActive: false,
                isDigital: false,
                isColor: '#3A3A3A',
            },
        ],
    },

    {
        key: 'C',
        ports: [
            {
                portName: 'C1',
                isActive: false,
                isDigital: false,
                isColor: '#0ACEF7',
            },
            {
                portName: 'C2',
                isActive: false,
                isDigital: false,
                isColor: '#5061A8',
            },
        ],
    },

    {
        key: 'D',
        ports: [
            {
                portName: 'D1',
                isActive: false,
                isDigital: false,
                isColor: '#0E3C11',
            },
            {
                portName: 'D2',
                isActive: false,
                isDigital: false,
                isColor: '#DD1624',
            },
        ],
    },

    {
        key: 'E',
        ports: [
            {
                portName: 'E1',
                isActive: false,
                isDigital: false,
                isColor: '#F79B47',
            },
            {
                portName: 'E2',
                isActive: false,
                isDigital: false,
                isColor: '#F666D0',
            },
        ],
    },

    {
        key: 'F',
        ports: [
            {
                portName: 'F1',
                isActive: false,
                isDigital: false,
                isColor: '#3D5B53',
            },
            {
                portName: 'F2',
                isActive: false,
                isDigital: false,
                isColor: 'black',
            },
        ],
    },
]

export var GraphPlotStorage = {
    Four_in_one_sensor: {
        isDistanceSensors: false,
        isGestureSensor: false,
        isLightSensor: false,
        isColorSensor: false,
    },

    isMic: false,
    isTemperature: false,

    // input
    isTouchZero: false,
    isTouchOne: false,
    isTouchTwo: false,
    isAccelerometerX: false,
    isAccelerometerY: false,
    isAccelerometerZ: false,
    isGyroX: false,
    isGyroY: false,
    isGyroZ: false,

    portsData,
}
console.log('Internal Accessories', GraphPlotStorage)
