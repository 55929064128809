import React, { Component } from 'react'
import Logic from '..'
import './end.css'
import LogicSwitchComp from '../helpers/SwitchComp/LogicSwitchComp'
class End extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name:
                sessionStorage.getItem('endStatus' + this.props.check) == 'end'
                    ? 'END'
                    : 'Loop',
            checked:
                sessionStorage.getItem('endStatus' + this.props.check) == 'end'
                    ? true
                    : false,
        }
    }
    switchActionHandler = (e) => {
        let value = e.target.value
        let isChecked = e.target.checked

        if (isChecked) {
            this.setState({
                name: 'End',
            })
            this.setState({ checked: true })
            this.props.onChange('end')
            let pass = this.props.passEnd
            this.props.handler(pass, 'end')
        } else {
            this.setState({
                name: 'Loop',
            })
            this.setState({ checked: false })
            this.props.onChange('repeat')
            let pass = this.props.passEnd
            this.props.handler(pass, 'end/repeat')
        }
    }

    render() {
        return (
            <div className="outertabDiv-end">
                <div
                    style={{
                        position: 'absolute',
                        left: '45%',
                        top: '42%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    {console.log('@@@@', this.state.checked)}
                    <LogicSwitchComp
                        ComponentName="END/Loop"
                        switchActionHandler={this.switchActionHandler}
                        checked={this.state.checked}
                    />
                </div>
                <p
                    style={{
                        color: '#311B92',
                        fontSize: '20px',
                        fontWeight: '500',
                        position: 'absolute',
                        left: '48%',
                        top: '44%',
                        transform: 'translate(-54%,-50%)',
                    }}
                >
                    {this.state.name}
                </p>
            </div>
        )
    }
}

export default End
