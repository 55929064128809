import React from 'react'
import createStyle from './ActionsCreate.module.css'

const trashIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className="bi bi-trash"
        viewBox="0 0 16 16"
    >
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path
            fillRule="evenodd"
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
    </svg>
)

const copyIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className="bi bi-clipboard"
        viewBox="0 0 16 16"
    >
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
    </svg>
)

const playIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        fill="currentColor"
        className="bi bi-play"
        viewBox="0 0 15 15"
        // style={{ marginLeft: '-2px', marginTop: '-5px' }}
    >
        <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
    </svg>
)

const buttonStyle = {
    border: '1px solid white',
    borderRadius: '2rem',
    height: '3rem',
    width: '3rem',
    background: 'none',
}

function ActionButton({
    selectedAction,
    hoverActionId,
    showPrimaryButtons,
    index,
    action,
    handleSelectAction,
    handleToggleButtons,
    deleteAction,
    copyAction,
    handlePlayAction,
    display,
}) {
    return (
        <div
            key={action.id}
            className={
                selectedAction == index
                    ? createStyle['enabledBtn']
                    : createStyle['disabledBtn']
            }
            style={
                hoverActionId == index && showPrimaryButtons
                    ? {
                          cursor: 'grab',
                          width: '90%',
                      }
                    : {
                          padding: '0 1rem',
                          cursor: 'pointer',
                          width: '90%',
                      }
            }
            onClick={() => handleSelectAction(index)}
            onMouseOver={() => handleToggleButtons(index, true)}
            onMouseLeave={() => handleToggleButtons(index, false)}
            // draggable={true}
            // onMouseOver={handleToggleButtons}
        >
            {hoverActionId == index && showPrimaryButtons ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        alignItems: 'center',
                        gap: '0.5rem',
                        padding: '0 1rem',
                    }}
                >
                    <p
                        style={{
                            paddingTop: '1rem',
                            marginRight: 'auto',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {' '}
                        Action {index + 1}
                    </p>

                    <button
                        style={{
                            display: display,
                            ...buttonStyle,
                        }}
                        onClick={handlePlayAction}
                    >
                        {playIcon}
                    </button>

                    <button
                        style={buttonStyle}
                        onClick={(event) => deleteAction(event, index)}
                    >
                        {trashIcon}
                    </button>
                    <button
                        style={buttonStyle}
                        onClick={(event) => copyAction(event, index)}
                    >
                        {copyIcon}
                    </button>
                </div>
            ) : (
                <p
                    // className="bg-primary"
                    style={{
                        paddingTop: '1.25rem',
                        marginRight: 'auto',
                    }}
                >
                    Action {index + 1}
                </p>
            )}
        </div>
    )
}

export default ActionButton
