import {
    DEFAULTSERVOVALUES,
    minMaxZing,
} from '../Code/CreateAction/defaultData'
const connectedDevice = ['Hexapod', 'Klaw'].includes(
    sessionStorage.getItem('connectedDevice')
)
    ? sessionStorage.getItem('connectedDevice').toUpperCase()
    : 'HUMANOID'

// const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
// export const generateDeviceDataKey = (Device, version) => {
//     if (Device == 'Hexapod' && version[0] == '1') {
//         return 'HEXAPOD1'
//     } else if (Device == 'Hexapod' && version[0] == '2') {
//         return 'HEXAPOD2'
//     } else if (Device == 'Humanoid' && version[0] == '0') {
//         return 'HUMANOID0'
//     } else if (Device == 'Humanoid' && version[0] == '1') {
//         return 'HUMANOID1'
//     } else if (Device == 'Humanoid' && version[0] == '2') {
//         return 'HUMANOID2'
//     } else {
//         return 'HUMANOID2'
//     }
// }

// export const allPortDefaultData = () => {}
export const PORTDATA = {
    HUMANOID0: {
        0: {
            name: 'Left RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeLeft',
            reduxLogicName: ['assignLeftEye', 'valueLeftEye'],
        },
        1: {
            name: 'Right RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeRight',
            reduxLogicName: ['assignRightEye', 'valueRightEye'],
        },
        2: {
            name: 'MP3 Port',
            value: 0,
            min: 0,
            max: 5,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isZingmp3',
            reduxLogicName: ['assignZingMp3', 'valueZingMp3'],
        },
        3: {
            name: 'Servo Head',
            value: 90,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isHeadServo',
            reduxLogicName: ['assignHeadServo', 'valueHeadServo'],
        },
        '4a': {
            name: 'Action Group',
            value: 0,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'actionGroupOption',
            reduxName: 'isActionGroup',
        },
        '4b': {
            name: 'Individual Servos',
            isPortSelected: false,
            isActionEnabled: false,
            type: 'individualServoOption',
            reduxName: 'isActionServo',
        },
        4.5: {
            name: 'Servo Time',
            value: 1000,
            min: 0,
            max: 5000,
            isActionEnabled: false,
            type: 'servoTime',
            reduxLogicName: ['assignServoTime', 'valueServoTime'],
        },
        5: {
            name: 'Servo Motor - 1',
            value: 500,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS1', 'valueS1'],
        },
        6: {
            name: 'Servo Motor - 2',
            value: 387,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS2', 'valueS2'],
        },
        7: {
            name: 'Servo Motor - 3',
            value: 500,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS3', 'valueS3'],
        },
        8: {
            name: 'Servo Motor - 4',
            value: 593,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS4', 'valueS4'],
        },
        9: {
            name: 'Servo Motor - 5',
            value: 500,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS5', 'valueS5'],
        },
        10: {
            name: 'Servo Motor - 6',
            value: 575,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS6', 'valueS6'],
        },
        11: {
            name: 'Servo Motor - 7',
            value: 800,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS7', 'valueS7'],
        },
        12: {
            name: 'Servo Motor - 8',
            value: 724,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS8', 'valueS8'],
        },
        13: {
            name: 'Servo Motor - 9',
            value: 500,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS9', 'valueS9'],
        },
        14: {
            name: 'Servo Motor - 10',
            value: 612,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS10', 'valueS10'],
        },
        15: {
            name: 'Servo Motor - 11',
            value: 500,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS11', 'valueS11'],
        },
        16: {
            name: 'Servo Motor - 12',
            value: 406,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS12', 'valueS12'],
        },
        17: {
            name: 'Servo Motor - 13',
            value: 500,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS13', 'valueS13'],
        },
        18: {
            name: 'Servo Motor - 14',
            value: 425,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS14', 'valueS14'],
        },
        19: {
            name: 'Servo Motor - 15',
            value: 200,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS15', 'valueS15'],
        },
        20: {
            name: 'Servo Motor - 16',
            value: 275,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS16', 'valueS16'],
        },
        21: {
            name: 'Battery',
            value: 0,
            min: 0,
            max: 100,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isBattery',
            reduxLogicName: ['assignBattery', 'valueBattery'],
        },
        22: {
            name: 'Ultrasonic',
            value: 0,
            min: 0,
            max: minMaxZing['headUltraSonicMax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isUltrasonic',
            reduxLogicName: ['assignUltrasonic', 'valueUltrasonic'],
        },
        23: {
            name: 'Accelerometer X',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerX',
            reduxLogicName: ['assignAccelerometerX', 'valueAccelerometerX'],
        },
        24: {
            name: 'Accelerometer Y',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerY',
            reduxLogicName: ['assignAccelerometerY', 'valueAccelerometerY'],
        },
        25: {
            name: 'Accelerometer Z',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerZ',
            reduxLogicName: ['assignAccelerometerZ', 'valueAccelerometerZ'],
        },
        26: {
            name: 'Gyro X',
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroX',
            reduxLogicName: ['assignGyroX', 'valueGyroX'],
        },
        27: {
            name: 'Gyro Y',
            value: 0,
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroY',
            reduxLogicName: ['assignGyroY', 'valueGyroY'],
        },
        28: {
            name: 'Gyro Z',
            value: 0,
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroZ',
            reduxLogicName: ['assignGyroZ', 'valueGyroZ'],
        },
        31: {
            name: 'Port A1',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA1', 'valueA1'],
        },
        32: {
            name: 'Port A2',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA2', 'valueA2'],
        },
        33: {
            name: 'Port B1',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            rgbState: {
                1: { isEnabled: false, show: true, value: [0, 0, 0] },
                2: { isEnabled: false, show: false, value: [0, 0, 0] },
                3: { isEnabled: false, show: false, value: [0, 0, 0] },
                4: { isEnabled: false, show: false, value: [0, 0, 0] },
                5: { isEnabled: false, show: false, value: [0, 0, 0] },
                6: { isEnabled: false, show: false, value: [0, 0, 0] },
                7: { isEnabled: false, show: false, value: [0, 0, 0] },
                8: { isEnabled: false, show: false, value: [0, 0, 0] },
                9: { isEnabled: false, show: false, value: [0, 0, 0] },
                10: { isEnabled: false, show: false, value: [0, 0, 0] },
            },
            rgbCount: 1,
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB1', 'valueB1'],
        },
        34: {
            name: 'Port B2',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB2', 'valueB2'],
        },
        35: {
            name: 'Port C1',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC1', 'valueC1'],
        },
        36: {
            name: 'Port C2',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC2', 'valueC2'],
        },
        37: {
            name: 'Port D1',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            oledState: {
                1: { isEnabled: false, value: '' },
                2: { isEnabled: false, value: '' },
                3: { isEnabled: false, value: '' },
                4: { isEnabled: false, value: '' },
            },
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD1', 'valueD1'],
        },
        38: {
            name: 'Port D2',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD2', 'valueD2'],
        },
        39: {
            name: 'M1',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isM1',
            reduxLogicName: ['assignM1', 'valueM1'],
        },
        40: {
            name: 'M2',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isM2',
            reduxLogicName: ['assignM2', 'valueM2'],
        },
        41: {
            name: 'Servo',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isSimpleServo',
            reduxLogicName: ['assignSimpleServo', 'valueSimpleServo'],
        },
        52: {
            name: 'Bicounter1',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic1', 'valueBic1'],
        },
        53: {
            name: 'Bicounter2',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic2', 'valueBic2'],
        },
        54: {
            name: 'BiData2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid2',
                'assignBid2Value',
                'valueBid2',
                'assignBid2Assign',
                'valueBid2Assign',
            ],
        },
        55: {
            name: 'Biflag1',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif1', 'valueBif1'],
        },
        56: {
            name: 'Biflag2',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif2', 'valueBif2'],
        },
        // "-6": {
        57: {
            name: 'BT RX1',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-7": {
        58: {
            name: 'BT RX2',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-8": {
        59: {
            name: 'BT RX3',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-9": {
        60: {
            name: 'BT RX4',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-10": {
        61: {
            name: 'BT RX5',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-11": {
        62: {
            name: 'USB RX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-12": {
        63: {
            name: 'BiData1',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid1',
                'assignBid1Value',
                'valueBid1',
                'assignBid1Assign',
                'valueBid1Assign',
            ],
        },
        // "-13": {
        64: {
            name: 'BT Remote',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-14": {
        65: {
            name: 'USB TX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignUsbtx', 'valueUsbtx'],
        },
        // "-15": {
        66: {
            name: 'Assign1',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn1',
                'valueAsgn1Output',
                'valueAsgn1Input',
            ],
        },
        // "-16": {
        67: {
            name: 'Assign2',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn2',
                'valueAsgn2Output',
                'valueAsgn2Input',
            ],
        },
        // "-17": {
        68: {
            name: 'Assign3',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn3',
                'valueAsgn3Output',
                'valueAsgn3Input',
            ],
        },
        // "-18": {
        69: {
            name: 'Assign4',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn4',
                'valueAsgn4Output',
                'valueAsgn4Input',
            ],
        },
        // "-19": {
        70: {
            name: 'Assign5',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn5',
                'valueAsgn5Output',
                'valueAsgn5Input',
            ],
        },

        // "-20": {
        71: {
            name: 'BT TX 1',
            // value: 0,
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            // reduxLogicName: ["assignBtTx1", "valueBtTx1"],
            reduxLogicName: [
                'assignBtTx1',
                'assignBtTx1Value',
                'valueBtTx1',
                'assignBtTx1Assign',
                'valueBtTx1Assign',
            ],
        },
        // "-21": {
        72: {
            name: 'BT TX 2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx2',
                'assignBtTx2Value',
                'valueBtTx2',
                'assignBtTx2Assign',
                'valueBtTx2Assign',
            ],
        },
        // "-22": {
        73: {
            name: 'BT TX 3',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx3',
                'assignBtTx3Value',
                'valueBtTx3',
                'assignBtTx3Assign',
                'valueBtTx3Assign',
            ],
        },
        // "-23": {
        74: {
            name: 'BT TX 4',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx4',
                'assignBtTx4Value',
                'valueBtTx4',
                'assignBtTx4Assign',
                'valueBtTx4Assign',
            ],
        },
        // "-24": {
        75: {
            name: 'BT TX 5',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx5',
                'assignBtTx5Value',
                'valueBtTx5',
                'assignBtTx5Assign',
                'valueBtTx5Assign',
            ],
        },
        76: {
            name: 'Left RGB 1',
            value: [0, 0, 0],
            valueEyeR1: 0,
            valueEyeG1: 0,
            valueEyeB1: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft1',
            reduxLogicName: [
                'assignEye1',
                'valueEyeR1',
                'valueEyeG1',
                'valueEyeB1',
            ],
        },
        77: {
            name: 'Left RGB 2',
            value: [0, 0, 0],
            valueEyeR2: 0,
            valueEyeG2: 0,
            valueEyeB2: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft2',
            reduxLogicName: [
                'assignEye2',
                'valueEyeR2',
                'valueEyeG2',
                'valueEyeB2',
            ],
        },
        78: {
            name: 'Left RGB 3',
            value: [0, 0, 0],
            valueEyeR3: 0,
            valueEyeG3: 0,
            valueEyeB3: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft3',
            reduxLogicName: [
                'assignEye3',
                'valueEyeR3',
                'valueEyeG3',
                'valueEyeB3',
            ],
        },
        79: {
            name: 'Right RGB 4',
            value: [0, 0, 0],
            valueEyeR4: 0,
            valueEyeG4: 0,
            valueEyeB4: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight1',
            reduxLogicName: [
                'assignEye4',
                'valueEyeR4',
                'valueEyeG4',
                'valueEyeB4',
            ],
        },
        80: {
            name: 'Right RGB 5',
            value: [0, 0, 0],
            valueEyeR5: 0,
            valueEyeG5: 0,
            valueEyeB5: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight2',
            reduxLogicName: [
                'assignEye5',
                'valueEyeR5',
                'valueEyeG5',
                'valueEyeB5',
            ],
        },
        81: {
            name: 'Right RGB 6',
            value: [0, 0, 0],
            valueEyeR6: 0,
            valueEyeG6: 0,
            valueEyeB6: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight3',
            reduxLogicName: [
                'assignEye6',
                'valueEyeR6',
                'valueEyeG6',
                'valueEyeB6',
            ],
        },
    },
    HUMANOID1: {
        0: {
            name: 'Left RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeLeft',
            reduxLogicName: ['assignLeftEye', 'valueLeftEye'],
        },
        1: {
            name: 'Right RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeRight',
            reduxLogicName: ['assignRightEye', 'valueRightEye'],
        },
        2: {
            name: 'MP3 Port',
            value: 0,
            min: 0,
            max: 5,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isZingmp3',
            reduxLogicName: ['assignZingMp3', 'valueZingMp3'],
        },
        3: {
            name: 'Servo Head',
            value: 90,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isHeadServo',
            reduxLogicName: ['assignHeadServo', 'valueHeadServo'],
        },
        '4a': {
            name: 'Action Group',
            value: 0,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'actionGroupOption',
            reduxName: 'isActionGroup',
        },
        '4b': {
            name: 'Individual Servos',
            isPortSelected: false,
            isActionEnabled: false,
            type: 'individualServoOption',
            reduxName: 'isActionServo',
        },
        4.5: {
            name: 'Servo Time',
            value: 1000,
            min: 0,
            max: 5000,
            isActionEnabled: false,
            type: 'servoTime',
            reduxLogicName: ['assignServoTime', 'valueServoTime'],
        },
        5: {
            name: 'Servo Motor - 1',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS1', 'valueS1'],
        },
        6: {
            name: 'Servo Motor - 2',
            value: 1585,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS2', 'valueS2'],
        },
        7: {
            name: 'Servo Motor - 3',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS3', 'valueS3'],
        },
        8: {
            name: 'Servo Motor - 4',
            value: 2428,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS4', 'valueS4'],
        },
        9: {
            name: 'Servo Motor - 5',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS5', 'valueS5'],
        },
        10: {
            name: 'Servo Motor - 6',
            value: 2355,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS6', 'valueS6'],
        },
        11: {
            name: 'Servo Motor - 7',
            value: 3276,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS7', 'valueS7'],
        },
        12: {
            name: 'Servo Motor - 8',
            value: 2965,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS8', 'valueS8'],
        },
        13: {
            name: 'Servo Motor - 9',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS9', 'valueS9'],
        },
        14: {
            name: 'Servo Motor - 10',
            value: 2506,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS10', 'valueS10'],
        },
        15: {
            name: 'Servo Motor - 11',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS11', 'valueS11'],
        },
        16: {
            name: 'Servo Motor - 12',
            value: 1662,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS12', 'valueS12'],
        },
        17: {
            name: 'Servo Motor - 13',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS13', 'valueS13'],
        },
        18: {
            name: 'Servo Motor - 14',
            value: 1740,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS14', 'valueS14'],
        },
        19: {
            name: 'Servo Motor - 15',
            value: 819,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS15', 'valueS15'],
        },
        20: {
            name: 'Servo Motor - 16',
            value: 1126,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS16', 'valueS16'],
        },
        21: {
            name: 'Battery',
            value: 0,
            min: 0,
            max: 100,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isBattery',
            reduxLogicName: ['assignBattery', 'valueBattery'],
        },
        22: {
            name: 'Ultrasonic',
            value: 0,
            min: 0,
            max: minMaxZing['headUltraSonicMax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isUltrasonic',
            reduxLogicName: ['assignUltrasonic', 'valueUltrasonic'],
        },
        23: {
            name: 'Accelerometer X',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerX',
            reduxLogicName: ['assignAccelerometerX', 'valueAccelerometerX'],
        },
        24: {
            name: 'Accelerometer Y',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerY',
            reduxLogicName: ['assignAccelerometerY', 'valueAccelerometerY'],
        },
        25: {
            name: 'Accelerometer Z',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerZ',
            reduxLogicName: ['assignAccelerometerZ', 'valueAccelerometerZ'],
        },
        26: {
            name: 'Gyro X',
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroX',
            reduxLogicName: ['assignGyroX', 'valueGyroX'],
        },
        27: {
            name: 'Gyro Y',
            value: 0,
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroY',
            reduxLogicName: ['assignGyroY', 'valueGyroY'],
        },
        28: {
            name: 'Gyro Z',
            value: 0,
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroZ',
            reduxLogicName: ['assignGyroZ', 'valueGyroZ'],
        },
        31: {
            name: 'Port A1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA1', 'valueA1', 'liveBtnA1'],
        },
        32: {
            name: 'Port A2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA2', 'valueA2', 'liveBtnA2'],
        },
        33: {
            name: 'Port B1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            rgbState: {
                1: { isEnabled: false, show: true, value: [0, 0, 0] },
                2: { isEnabled: false, show: false, value: [0, 0, 0] },
                3: { isEnabled: false, show: false, value: [0, 0, 0] },
                4: { isEnabled: false, show: false, value: [0, 0, 0] },
                5: { isEnabled: false, show: false, value: [0, 0, 0] },
                6: { isEnabled: false, show: false, value: [0, 0, 0] },
                7: { isEnabled: false, show: false, value: [0, 0, 0] },
                8: { isEnabled: false, show: false, value: [0, 0, 0] },
                9: { isEnabled: false, show: false, value: [0, 0, 0] },
                10: { isEnabled: false, show: false, value: [0, 0, 0] },
            },
            rgbCount: 1,
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB1', 'valueB1', 'liveBtnB1'],
        },
        34: {
            name: 'Port B2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB2', 'valueB2', 'liveBtnB2'],
        },
        35: {
            name: 'Port C1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC1', 'valueC1', 'liveBtnC1'],
        },
        36: {
            name: 'Port C2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC2', 'valueC2', 'liveBtnC2'],
        },
        37: {
            name: 'Port D1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            oledState: {
                1: { isEnabled: false, value: '' },
                2: { isEnabled: false, value: '' },
                3: { isEnabled: false, value: '' },
                4: { isEnabled: false, value: '' },
            },
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD1', 'valueD1', 'liveBtnD1'],
        },
        38: {
            name: 'Port D2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD2', 'valueD2', 'liveBtnD2'],
        },
        39: {
            name: 'M1',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM1',
            reduxLogicName: ['assignM1', 'valueM1', 'liveBtnM1'],
        },
        40: {
            name: 'M2',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM2',
            reduxLogicName: ['assignM2', 'valueM2', 'liveBtnM2'],
        },
        41: {
            name: 'Servo',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isSimpleServo',
            reduxLogicName: ['assignSimpleServo', 'valueSimpleServo'],
        },
        52: {
            name: 'Bicounter1',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic1', 'valueBic1'],
        },
        53: {
            name: 'Bicounter2',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic2', 'valueBic2'],
        },
        54: {
            name: 'BiData2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid2',
                'assignBid2Value',
                'valueBid2',
                'assignBid2Assign',
                'valueBid2Assign',
            ],
        },
        55: {
            name: 'Biflag1',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif1', 'valueBif1'],
        },
        56: {
            name: 'Biflag2',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif2', 'valueBif2'],
        },
        // "-6": {
        57: {
            name: 'BT RX1',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-7": {
        58: {
            name: 'BT RX2',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-8": {
        59: {
            name: 'BT RX3',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-9": {
        60: {
            name: 'BT RX4',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-10": {
        61: {
            name: 'BT RX5',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-11": {
        62: {
            name: 'USB RX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-12": {
        63: {
            name: 'BiData1',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid1',
                'assignBid1Value',
                'valueBid1',
                'assignBid1Assign',
                'valueBid1Assign',
            ],
        },
        // "-13": {
        64: {
            name: 'BT Remote',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-14": {
        65: {
            name: 'USB TX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignUsbtx', 'valueUsbtx'],
        },
        // "-15": {
        66: {
            name: 'Assign1',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn1',
                'valueAsgn1Output',
                'valueAsgn1Input',
            ],
        },
        // "-16": {
        67: {
            name: 'Assign2',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn2',
                'valueAsgn2Output',
                'valueAsgn2Input',
            ],
        },
        // "-17": {
        68: {
            name: 'Assign3',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn3',
                'valueAsgn3Output',
                'valueAsgn3Input',
            ],
        },
        // "-18": {
        69: {
            name: 'Assign4',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn4',
                'valueAsgn4Output',
                'valueAsgn4Input',
            ],
        },
        // "-19": {
        70: {
            name: 'Assign5',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn5',
                'valueAsgn5Output',
                'valueAsgn5Input',
            ],
        },

        // "-20": {
        71: {
            name: 'BT TX 1',
            // value: 0,
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            // reduxLogicName: ["assignBtTx1", "valueBtTx1"],
            reduxLogicName: [
                'assignBtTx1',
                'assignBtTx1Value',
                'valueBtTx1',
                'assignBtTx1Assign',
                'valueBtTx1Assign',
            ],
        },
        // "-21": {
        72: {
            name: 'BT TX 2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx2',
                'assignBtTx2Value',
                'valueBtTx2',
                'assignBtTx2Assign',
                'valueBtTx2Assign',
            ],
        },
        // "-22": {
        73: {
            name: 'BT TX 3',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx3',
                'assignBtTx3Value',
                'valueBtTx3',
                'assignBtTx3Assign',
                'valueBtTx3Assign',
            ],
        },
        // "-23": {
        74: {
            name: 'BT TX 4',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx4',
                'assignBtTx4Value',
                'valueBtTx4',
                'assignBtTx4Assign',
                'valueBtTx4Assign',
            ],
        },
        // "-24": {
        75: {
            name: 'BT TX 5',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx5',
                'assignBtTx5Value',
                'valueBtTx5',
                'assignBtTx5Assign',
                'valueBtTx5Assign',
            ],
        },
        76: {
            name: 'Left RGB 1',
            value: [0, 0, 0],
            valueEyeR1: 0,
            valueEyeG1: 0,
            valueEyeB1: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft1',
            reduxLogicName: [
                'assignEye1',
                'valueEyeR1',
                'valueEyeG1',
                'valueEyeB1',
            ],
        },
        77: {
            name: 'Left RGB 2',
            value: [0, 0, 0],
            valueEyeR2: 0,
            valueEyeG2: 0,
            valueEyeB2: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft2',
            reduxLogicName: [
                'assignEye2',
                'valueEyeR2',
                'valueEyeG2',
                'valueEyeB2',
            ],
        },
        78: {
            name: 'Left RGB 3',
            value: [0, 0, 0],
            valueEyeR3: 0,
            valueEyeG3: 0,
            valueEyeB3: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft3',
            reduxLogicName: [
                'assignEye3',
                'valueEyeR3',
                'valueEyeG3',
                'valueEyeB3',
            ],
        },
        79: {
            name: 'Right RGB 4',
            value: [0, 0, 0],
            valueEyeR4: 0,
            valueEyeG4: 0,
            valueEyeB4: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight1',
            reduxLogicName: [
                'assignEye4',
                'valueEyeR4',
                'valueEyeG4',
                'valueEyeB4',
            ],
        },
        80: {
            name: 'Right RGB 5',
            value: [0, 0, 0],
            valueEyeR5: 0,
            valueEyeG5: 0,
            valueEyeB5: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight2',
            reduxLogicName: [
                'assignEye5',
                'valueEyeR5',
                'valueEyeG5',
                'valueEyeB5',
            ],
        },
        81: {
            name: 'Right RGB 6',
            value: [0, 0, 0],
            valueEyeR6: 0,
            valueEyeG6: 0,
            valueEyeB6: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight3',
            reduxLogicName: [
                'assignEye6',
                'valueEyeR6',
                'valueEyeG6',
                'valueEyeB6',
            ],
        },
    },
    HUMANOID2: {
        0: {
            name: 'Left RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeLeft',
            reduxLogicName: ['assignLeftEye', 'valueLeftEye'],
        },
        1: {
            name: 'Right RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeRight',
            reduxLogicName: ['assignRightEye', 'valueRightEye'],
        },
        2: {
            name: 'MP3 Port',
            value: 0,
            min: 0,
            max: 5,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isZingmp3',
            reduxLogicName: ['assignZingMp3', 'valueZingMp3'],
        },
        3: {
            name: 'Servo Head',
            value: 90,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isHeadServo',
            reduxLogicName: ['assignHeadServo', 'valueHeadServo'],
        },
        '4a': {
            name: 'Action Group',
            value: 0,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'actionGroupOption',
            reduxName: 'isActionGroup',
        },
        '4b': {
            name: 'Individual Servos',
            isPortSelected: false,
            isActionEnabled: false,
            type: 'individualServoOption',
            reduxName: 'isActionServo',
        },
        4.5: {
            name: 'Servo Time',
            value: 1000,
            min: 0,
            max: 5000,
            isActionEnabled: false,
            type: 'servoTime',
            reduxLogicName: ['assignServoTime', 'valueServoTime'],
        },
        5: {
            name: 'Servo Motor - 1',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS1', 'valueS1'],
        },
        6: {
            name: 'Servo Motor - 2',
            value: 1585,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS2', 'valueS2'],
        },
        7: {
            name: 'Servo Motor - 3',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS3', 'valueS3'],
        },
        8: {
            name: 'Servo Motor - 4',
            value: 2428,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS4', 'valueS4'],
        },
        9: {
            name: 'Servo Motor - 5',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS5', 'valueS5'],
        },
        10: {
            name: 'Servo Motor - 6',
            value: 2355,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS6', 'valueS6'],
        },
        11: {
            name: 'Servo Motor - 7',
            value: 3276,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS7', 'valueS7'],
        },
        12: {
            name: 'Servo Motor - 8',
            value: 2965,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS8', 'valueS8'],
        },
        13: {
            name: 'Servo Motor - 9',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS9', 'valueS9'],
        },
        14: {
            name: 'Servo Motor - 10',
            value: 2506,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS10', 'valueS10'],
        },
        15: {
            name: 'Servo Motor - 11',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS11', 'valueS11'],
        },
        16: {
            name: 'Servo Motor - 12',
            value: 1662,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS12', 'valueS12'],
        },
        17: {
            name: 'Servo Motor - 13',
            value: 2048,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS13', 'valueS13'],
        },
        18: {
            name: 'Servo Motor - 14',
            value: 1740,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS14', 'valueS14'],
        },
        19: {
            name: 'Servo Motor - 15',
            value: 819,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS15', 'valueS15'],
        },
        20: {
            name: 'Servo Motor - 16',
            value: 1126,
            min: 0,
            max: 1000,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS16', 'valueS16'],
        },
        21: {
            name: 'Battery',
            value: 0,
            min: 0,
            max: 100,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isBattery',
            reduxLogicName: ['assignBattery', 'valueBattery'],
        },
        22: {
            name: 'Ultrasonic',
            value: 0,
            min: 0,
            max: minMaxZing['headUltraSonicMax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isUltrasonic',
            reduxLogicName: ['assignUltrasonic', 'valueUltrasonic'],
        },
        23: {
            name: 'Accelerometer X',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerX',
            reduxLogicName: ['assignAccelerometerX', 'valueAccelerometerX'],
        },
        24: {
            name: 'Accelerometer Y',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerY',
            reduxLogicName: ['assignAccelerometerY', 'valueAccelerometerY'],
        },
        25: {
            name: 'Accelerometer Z',
            value: 0,
            min: minMaxZing['accXmin'],
            max: minMaxZing['accXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerZ',
            reduxLogicName: ['assignAccelerometerZ', 'valueAccelerometerZ'],
        },
        26: {
            name: 'Gyro X',
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroX',
            reduxLogicName: ['assignGyroX', 'valueGyroX'],
        },
        27: {
            name: 'Gyro Y',
            value: 0,
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroY',
            reduxLogicName: ['assignGyroY', 'valueGyroY'],
        },
        28: {
            name: 'Gyro Z',
            value: 0,
            min: minMaxZing['gyroXmin'],
            max: minMaxZing['gyroXmax'],
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroZ',
            reduxLogicName: ['assignGyroZ', 'valueGyroZ'],
        },
        31: {
            name: 'Port A1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA1', 'valueA1', 'liveBtnA1'],
        },
        32: {
            name: 'Port A2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA2', 'valueA2', 'liveBtnA2'],
        },
        33: {
            name: 'Port B1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            rgbState: {
                1: { isEnabled: false, show: true, value: [0, 0, 0] },
                2: { isEnabled: false, show: false, value: [0, 0, 0] },
                3: { isEnabled: false, show: false, value: [0, 0, 0] },
                4: { isEnabled: false, show: false, value: [0, 0, 0] },
                5: { isEnabled: false, show: false, value: [0, 0, 0] },
                6: { isEnabled: false, show: false, value: [0, 0, 0] },
                7: { isEnabled: false, show: false, value: [0, 0, 0] },
                8: { isEnabled: false, show: false, value: [0, 0, 0] },
                9: { isEnabled: false, show: false, value: [0, 0, 0] },
                10: { isEnabled: false, show: false, value: [0, 0, 0] },
            },
            rgbCount: 1,
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB1', 'valueB1', 'liveBtnB1'],
        },
        34: {
            name: 'Port B2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB2', 'valueB2', 'liveBtnB2'],
        },
        35: {
            name: 'Port C1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC1', 'valueC1', 'liveBtnC1'],
        },
        36: {
            name: 'Port C2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC2', 'valueC2', 'liveBtnC2'],
        },
        37: {
            name: 'Port D1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            oledState: {
                1: { isEnabled: false, value: '' },
                2: { isEnabled: false, value: '' },
                3: { isEnabled: false, value: '' },
                4: { isEnabled: false, value: '' },
            },
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD1', 'valueD1', 'liveBtnD1'],
        },
        38: {
            name: 'Port D2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD2', 'valueD2', 'liveBtnD2'],
        },
        39: {
            name: 'M1',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM1',
            reduxLogicName: ['assignM1', 'valueM1', 'liveBtnM1'],
        },
        40: {
            name: 'M2',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM2',
            reduxLogicName: ['assignM2', 'valueM2', 'liveBtnM2'],
        },
        41: {
            name: 'Servo',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isSimpleServo',
            reduxLogicName: ['assignSimpleServo', 'valueSimpleServo'],
        },
        52: {
            name: 'Bicounter1',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic1', 'valueBic1'],
        },
        53: {
            name: 'Bicounter2',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic2', 'valueBic2'],
        },
        54: {
            name: 'BiData2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid2',
                'assignBid2Value',
                'valueBid2',
                'assignBid2Assign',
                'valueBid2Assign',
            ],
        },
        55: {
            name: 'Biflag1',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif1', 'valueBif1'],
        },
        56: {
            name: 'Biflag2',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif2', 'valueBif2'],
        },
        // "-6": {
        57: {
            name: 'BT RX1',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-7": {
        58: {
            name: 'BT RX2',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-8": {
        59: {
            name: 'BT RX3',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-9": {
        60: {
            name: 'BT RX4',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-10": {
        61: {
            name: 'BT RX5',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-11": {
        62: {
            name: 'USB RX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-12": {
        63: {
            name: 'BiData1',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid1',
                'assignBid1Value',
                'valueBid1',
                'assignBid1Assign',
                'valueBid1Assign',
            ],
        },
        // "-13": {
        64: {
            name: 'BT Remote',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-14": {
        65: {
            name: 'USB TX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignUsbtx', 'valueUsbtx'],
        },
        // "-15": {
        66: {
            name: 'Assign1',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn1',
                'valueAsgn1Output',
                'valueAsgn1Input',
            ],
        },
        // "-16": {
        67: {
            name: 'Assign2',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn2',
                'valueAsgn2Output',
                'valueAsgn2Input',
            ],
        },
        // "-17": {
        68: {
            name: 'Assign3',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn3',
                'valueAsgn3Output',
                'valueAsgn3Input',
            ],
        },
        // "-18": {
        69: {
            name: 'Assign4',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn4',
                'valueAsgn4Output',
                'valueAsgn4Input',
            ],
        },
        // "-19": {
        70: {
            name: 'Assign5',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn5',
                'valueAsgn5Output',
                'valueAsgn5Input',
            ],
        },

        // "-20": {
        71: {
            name: 'BT TX 1',
            // value: 0,
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            // reduxLogicName: ["assignBtTx1", "valueBtTx1"],
            reduxLogicName: [
                'assignBtTx1',
                'assignBtTx1Value',
                'valueBtTx1',
                'assignBtTx1Assign',
                'valueBtTx1Assign',
            ],
        },
        // "-21": {
        72: {
            name: 'BT TX 2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx2',
                'assignBtTx2Value',
                'valueBtTx2',
                'assignBtTx2Assign',
                'valueBtTx2Assign',
            ],
        },
        // "-22": {
        73: {
            name: 'BT TX 3',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx3',
                'assignBtTx3Value',
                'valueBtTx3',
                'assignBtTx3Assign',
                'valueBtTx3Assign',
            ],
        },
        // "-23": {
        74: {
            name: 'BT TX 4',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx4',
                'assignBtTx4Value',
                'valueBtTx4',
                'assignBtTx4Assign',
                'valueBtTx4Assign',
            ],
        },
        // "-24": {
        75: {
            name: 'BT TX 5',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx5',
                'assignBtTx5Value',
                'valueBtTx5',
                'assignBtTx5Assign',
                'valueBtTx5Assign',
            ],
        },
        76: {
            name: 'Left RGB 1',
            value: [0, 0, 0],
            valueEyeR1: 0,
            valueEyeG1: 0,
            valueEyeB1: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft1',
            reduxLogicName: [
                'assignEye1',
                'valueEyeR1',
                'valueEyeG1',
                'valueEyeB1',
            ],
        },
        77: {
            name: 'Left RGB 2',
            value: [0, 0, 0],
            valueEyeR2: 0,
            valueEyeG2: 0,
            valueEyeB2: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft2',
            reduxLogicName: [
                'assignEye2',
                'valueEyeR2',
                'valueEyeG2',
                'valueEyeB2',
            ],
        },
        78: {
            name: 'Left RGB 3',
            value: [0, 0, 0],
            valueEyeR3: 0,
            valueEyeG3: 0,
            valueEyeB3: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft3',
            reduxLogicName: [
                'assignEye3',
                'valueEyeR3',
                'valueEyeG3',
                'valueEyeB3',
            ],
        },
        79: {
            name: 'Right RGB 4',
            value: [0, 0, 0],
            valueEyeR4: 0,
            valueEyeG4: 0,
            valueEyeB4: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight1',
            reduxLogicName: [
                'assignEye4',
                'valueEyeR4',
                'valueEyeG4',
                'valueEyeB4',
            ],
        },
        80: {
            name: 'Right RGB 5',
            value: [0, 0, 0],
            valueEyeR5: 0,
            valueEyeG5: 0,
            valueEyeB5: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight2',
            reduxLogicName: [
                'assignEye5',
                'valueEyeR5',
                'valueEyeG5',
                'valueEyeB5',
            ],
        },
        81: {
            name: 'Right RGB 6',
            value: [0, 0, 0],
            valueEyeR6: 0,
            valueEyeG6: 0,
            valueEyeB6: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight3',
            reduxLogicName: [
                'assignEye6',
                'valueEyeR6',
                'valueEyeG6',
                'valueEyeB6',
            ],
        },
    },

    HEXAPOD2: {
        0: {
            name: 'Left RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeLeft',
            reduxLogicName: ['assignLeftEye', 'valueLeftEye'],
        },
        1: {
            name: 'Right RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeRight',
            reduxLogicName: ['assignRightEye', 'valueRightEye'],
        },
        2: {
            name: 'MP3 Port',
            value: 0,
            min: 0,
            max: 5,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isZingmp3',
            reduxLogicName: ['assignZingMp3', 'valueZingMp3'],
        },
        3: {
            name: 'Servo Head',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isHeadServo',
            reduxLogicName: ['assignHeadServo', 'valueHeadServo'],
        },
        '4a': {
            name: 'Action Group',
            value: 0,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'actionGroupOption',
            reduxName: 'isActionGroup',
        },
        '4b': {
            name: 'Individual Servos',
            isPortSelected: false,
            isActionEnabled: false,
            type: 'individualServoOption',
            reduxName: 'isActionServo',
        },
        4.5: {
            name: 'Servo Time',
            value: 1000,
            min: 0,
            max: 5000,
            isActionEnabled: false,
            type: 'servoTime',
            reduxLogicName: ['assignServoTime', 'valueServoTime'],
        },
        5: {
            name: 'Servo Motor - 1',
            value: 2048,
            min: 400,
            max: 3700,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS1', 'valueS1'],
        },
        6: {
            name: 'Servo Motor - 2',
            value: 1277,
            min: 440,
            max: 3670,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS2', 'valueS2'],
        },
        7: {
            name: 'Servo Motor - 3',
            value: 819,
            min: 300,
            max: 4095,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS3', 'valueS3'],
        },
        8: {
            name: 'Servo Motor - 4',
            value: 2048,
            min: 500,
            max: 3700,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS4', 'valueS4'],
        },
        9: {
            name: 'Servo Motor - 5',
            value: 1277,
            min: 440,
            max: 3730,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS5', 'valueS5'],
        },
        10: {
            name: 'Servo Motor - 6',
            value: 819,
            min: 320,
            max: 4095,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS6', 'valueS6'],
        },
        11: {
            name: 'Servo Motor - 7',
            value: 2048,
            min: 360,
            max: 3670,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS7', 'valueS7'],
        },
        12: {
            name: 'Servo Motor - 8',
            value: 1277,
            min: 440,
            max: 3730,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS8', 'valueS8'],
        },
        13: {
            name: 'Servo Motor - 9',
            value: 819,
            min: 420,
            max: 4095,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS9', 'valueS9'],
        },
        14: {
            name: 'Servo Motor - 10',
            value: 2048,
            min: 360,
            max: 3700,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS10', 'valueS10'],
        },
        15: {
            name: 'Servo Motor - 11',
            value: 2818,
            min: 400,
            max: 3580,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS11', 'valueS11'],
        },
        16: {
            name: 'Servo Motor - 12',
            value: 3276,
            min: 0,
            max: 3760,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS12', 'valueS12'],
        },
        17: {
            name: 'Servo Motor - 13',
            value: 2048,
            min: 360,
            max: 3620,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS13', 'valueS13'],
        },
        18: {
            name: 'Servo Motor - 14',
            value: 2818,
            min: 360,
            max: 3610,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS14', 'valueS14'],
        },
        19: {
            name: 'Servo Motor - 15',
            value: 3276,
            min: 0,
            max: 3760,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS15', 'valueS15'],
        },
        20: {
            name: 'Servo Motor - 16',
            value: 2048,
            min: 360,
            max: 3670,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS16', 'valueS16'],
        },
        21: {
            name: 'Servo Motor - 17',
            value: 2818,
            min: 360,
            max: 3570,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS17', 'valueS17'],
        },
        22: {
            name: 'Servo Motor - 18',
            value: 3276,
            min: 0,
            max: 3660,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS18', 'valueS18'],
        },
        23: {
            name: 'Battery',
            value: 0,
            min: 0,
            max: 100,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isBattery',
            reduxLogicName: ['assignBattery', 'valueBattery'],
        },
        24: {
            name: 'Ultrasonic',
            value: 0,
            min: 0,
            max: 400,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isUltrasonic',
            reduxLogicName: ['assignUltrasonic', 'valueUltrasonic'],
        },
        25: {
            name: 'Accelerometer X',
            value: 0,
            min: -32768,
            max: 32768,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerX',
            reduxLogicName: ['assignAccelerometerX', 'valueAccelerometerX'],
        },
        26: {
            name: 'Accelerometer Y',
            value: 0,
            min: -32768,
            max: 32768,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerY',
            reduxLogicName: ['assignAccelerometerY', 'valueAccelerometerY'],
        },
        27: {
            name: 'Accelerometer Z',
            value: 0,
            min: -32768,
            max: 32768,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerZ',
            reduxLogicName: ['assignAccelerometerZ', 'valueAccelerometerZ'],
        },
        28: {
            name: 'Gyro X',
            value: 0,
            min: -2500,
            max: 2500,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroX',
            reduxLogicName: ['assignGyroX', 'valueGyroX'],
        },
        29: {
            name: 'Gyro Y',
            value: 0,
            min: -2500,
            max: 2500,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroY',
            reduxLogicName: ['assignGyroY', 'valueGyroY'],
        },
        30: {
            name: 'Gyro Z',
            value: 0,
            min: -2500,
            max: 2500,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroZ',
            reduxLogicName: ['assignGyroZ', 'valueGyroZ'],
        },
        31: {
            name: 'Port A1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA1', 'valueA1', 'liveBtnA1'],
        },
        32: {
            name: 'Port A2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA2', 'valueA2', 'liveBtnA2'],
        },
        33: {
            name: 'Port B1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            rgbState: {
                1: { isEnabled: false, show: true, value: [0, 0, 0] },
                2: { isEnabled: false, show: false, value: [0, 0, 0] },
                3: { isEnabled: false, show: false, value: [0, 0, 0] },
                4: { isEnabled: false, show: false, value: [0, 0, 0] },
                5: { isEnabled: false, show: false, value: [0, 0, 0] },
                6: { isEnabled: false, show: false, value: [0, 0, 0] },
                7: { isEnabled: false, show: false, value: [0, 0, 0] },
                8: { isEnabled: false, show: false, value: [0, 0, 0] },
                9: { isEnabled: false, show: false, value: [0, 0, 0] },
                10: { isEnabled: false, show: false, value: [0, 0, 0] },
            },
            rgbCount: 1,
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB1', 'valueB1', 'liveBtnB1'],
        },
        34: {
            name: 'Port B2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB2', 'valueB2', 'liveBtnB2'],
        },
        35: {
            name: 'Port C1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC1', 'valueC1', 'liveBtnC1'],
        },
        36: {
            name: 'Port C2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC2', 'valueC2', 'liveBtnC2'],
        },
        37: {
            name: 'Port D1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            oledState: {
                1: { isEnabled: false, value: '' },
                2: { isEnabled: false, value: '' },
                3: { isEnabled: false, value: '' },
                4: { isEnabled: false, value: '' },
            },
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD1', 'valueD1', 'liveBtnD1'],
        },
        38: {
            name: 'Port D2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD2', 'valueD2', 'liveBtnD2'],
        },
        39: {
            name: 'M1',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM1',
            reduxLogicName: ['assignM1', 'valueM1', 'liveBtnM1'],
        },
        40: {
            name: 'M2',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM2',
            reduxLogicName: ['assignM2', 'valueM2', 'liveBtnM2'],
        },
        41: {
            name: 'Servo',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isSimpleServo',
            reduxLogicName: ['assignSimpleServo', 'valueSimpleServo'],
        },
        52: {
            name: 'Bicounter1',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic1', 'valueBic1'],
        },
        53: {
            name: 'Bicounter2',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic2', 'valueBic2'],
        },
        54: {
            name: 'BiData2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid2',
                'assignBid2Value',
                'valueBid2',
                'assignBid2Assign',
                'valueBid2Assign',
            ],
        },
        55: {
            name: 'Biflag1',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif1', 'valueBif1'],
        },
        56: {
            name: 'Biflag2',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif2', 'valueBif2'],
        },
        // "-6": {
        57: {
            name: 'BT RX1',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-7": {
        58: {
            name: 'BT RX2',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-8": {
        59: {
            name: 'BT RX3',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-9": {
        60: {
            name: 'BT RX4',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-10": {
        61: {
            name: 'BT RX5',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-11": {
        62: {
            name: 'USB RX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-12": {
        63: {
            name: 'BiData1',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid1',
                'assignBid1Value',
                'valueBid1',
                'assignBid1Assign',
                'valueBid1Assign',
            ],
        },
        // "-13": {
        64: {
            name: 'BT Remote',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-14": {
        65: {
            name: 'USB TX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignUsbtx', 'valueUsbtx'],
        },
        // "-15": {
        66: {
            name: 'Assign1',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn1',
                'valueAsgn1Output',
                'valueAsgn1Input',
            ],
        },
        // "-16": {
        67: {
            name: 'Assign2',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn2',
                'valueAsgn2Output',
                'valueAsgn2Input',
            ],
        },
        // "-17": {
        68: {
            name: 'Assign3',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn3',
                'valueAsgn3Output',
                'valueAsgn3Input',
            ],
        },
        // "-18": {
        69: {
            name: 'Assign4',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn4',
                'valueAsgn4Output',
                'valueAsgn4Input',
            ],
        },
        // "-19": {
        70: {
            name: 'Assign5',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn5',
                'valueAsgn5Output',
                'valueAsgn5Input',
            ],
        },

        // "-20": {
        71: {
            name: 'BT TX 1',
            // value: 0,
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            // reduxLogicName: ["assignBtTx1", "valueBtTx1"],
            reduxLogicName: [
                'assignBtTx1',
                'assignBtTx1Value',
                'valueBtTx1',
                'assignBtTx1Assign',
                'valueBtTx1Assign',
            ],
        },
        // "-21": {
        72: {
            name: 'BT TX 2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx2',
                'assignBtTx2Value',
                'valueBtTx2',
                'assignBtTx2Assign',
                'valueBtTx2Assign',
            ],
        },
        // "-22": {
        73: {
            name: 'BT TX 3',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx3',
                'assignBtTx3Value',
                'valueBtTx3',
                'assignBtTx3Assign',
                'valueBtTx3Assign',
            ],
        },
        // "-23": {
        74: {
            name: 'BT TX 4',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx4',
                'assignBtTx4Value',
                'valueBtTx4',
                'assignBtTx4Assign',
                'valueBtTx4Assign',
            ],
        },
        // "-24": {
        75: {
            name: 'BT TX 5',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx5',
                'assignBtTx5Value',
                'valueBtTx5',
                'assignBtTx5Assign',
                'valueBtTx5Assign',
            ],
        },
        76: {
            name: 'Left RGB 1',
            value: [0, 0, 0],
            valueEyeR1: 0,
            valueEyeG1: 0,
            valueEyeB1: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft1',
            reduxLogicName: [
                'assignEye1',
                'valueEyeR1',
                'valueEyeG1',
                'valueEyeB1',
            ],
        },
        77: {
            name: 'Left RGB 2',
            value: [0, 0, 0],
            valueEyeR2: 0,
            valueEyeG2: 0,
            valueEyeB2: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft2',
            reduxLogicName: [
                'assignEye2',
                'valueEyeR2',
                'valueEyeG2',
                'valueEyeB2',
            ],
        },
        78: {
            name: 'Left RGB 3',
            value: [0, 0, 0],
            valueEyeR3: 0,
            valueEyeG3: 0,
            valueEyeB3: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft3',
            reduxLogicName: [
                'assignEye3',
                'valueEyeR3',
                'valueEyeG3',
                'valueEyeB3',
            ],
        },
        79: {
            name: 'Right RGB 4',
            value: [0, 0, 0],
            valueEyeR4: 0,
            valueEyeG4: 0,
            valueEyeB4: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight1',
            reduxLogicName: [
                'assignEye4',
                'valueEyeR4',
                'valueEyeG4',
                'valueEyeB4',
            ],
        },
        80: {
            name: 'Right RGB 5',
            value: [0, 0, 0],
            valueEyeR5: 0,
            valueEyeG5: 0,
            valueEyeB5: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight2',
            reduxLogicName: [
                'assignEye5',
                'valueEyeR5',
                'valueEyeG5',
                'valueEyeB5',
            ],
        },
        81: {
            name: 'Right RGB 6',
            value: [0, 0, 0],
            valueEyeR6: 0,
            valueEyeG6: 0,
            valueEyeB6: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight3',
            reduxLogicName: [
                'assignEye6',
                'valueEyeR6',
                'valueEyeG6',
                'valueEyeB6',
            ],
        },
    },
    HEXAPOD1: {
        0: {
            name: 'Left RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeLeft',
            reduxLogicName: ['assignLeftEye', 'valueLeftEye'],
        },
        1: {
            name: 'Right RGB',
            value: [0, 0, 0],
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isEyeRight',
            reduxLogicName: ['assignRightEye', 'valueRightEye'],
        },
        2: {
            name: 'MP3 Port',
            value: 0,
            min: 0,
            max: 5,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isZingmp3',
            reduxLogicName: ['assignZingMp3', 'valueZingMp3'],
        },
        3: {
            name: 'Servo Head',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isHeadServo',
            reduxLogicName: ['assignHeadServo', 'valueHeadServo'],
        },
        '4a': {
            name: 'Action Group',
            value: 0,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'actionGroupOption',
            reduxName: 'isActionGroup',
        },
        '4b': {
            name: 'Individual Servos',
            isPortSelected: false,
            isActionEnabled: false,
            type: 'individualServoOption',
            reduxName: 'isActionServo',
        },
        4.5: {
            name: 'Servo Time',
            value: 1000,
            min: 0,
            max: 5000,
            isActionEnabled: false,
            type: 'servoTime',
            reduxLogicName: ['assignServoTime', 'valueServoTime'],
        },
        5: {
            name: 'Servo Motor - 1',
            value: 2048,
            min: 400,
            max: 3700,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS1', 'valueS1'],
        },
        6: {
            name: 'Servo Motor - 2',
            value: 1277,
            min: 440,
            max: 3670,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS2', 'valueS2'],
        },
        7: {
            name: 'Servo Motor - 3',
            value: 819,
            min: 300,
            max: 4095,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS3', 'valueS3'],
        },
        8: {
            name: 'Servo Motor - 4',
            value: 2048,
            min: 500,
            max: 3700,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS4', 'valueS4'],
        },
        9: {
            name: 'Servo Motor - 5',
            value: 1277,
            min: 440,
            max: 3730,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS5', 'valueS5'],
        },
        10: {
            name: 'Servo Motor - 6',
            value: 819,
            min: 320,
            max: 4095,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS6', 'valueS6'],
        },
        11: {
            name: 'Servo Motor - 7',
            value: 2048,
            min: 360,
            max: 3670,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS7', 'valueS7'],
        },
        12: {
            name: 'Servo Motor - 8',
            value: 1277,
            min: 440,
            max: 3730,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS8', 'valueS8'],
        },
        13: {
            name: 'Servo Motor - 9',
            value: 819,
            min: 420,
            max: 4095,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS9', 'valueS9'],
        },
        14: {
            name: 'Servo Motor - 10',
            value: 2048,
            min: 360,
            max: 3700,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS10', 'valueS10'],
        },
        15: {
            name: 'Servo Motor - 11',
            value: 2818,
            min: 400,
            max: 3580,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS11', 'valueS11'],
        },
        16: {
            name: 'Servo Motor - 12',
            value: 3276,
            min: 0,
            max: 3760,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS12', 'valueS12'],
        },
        17: {
            name: 'Servo Motor - 13',
            value: 2048,
            min: 360,
            max: 3620,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS13', 'valueS13'],
        },
        18: {
            name: 'Servo Motor - 14',
            value: 2818,
            min: 360,
            max: 3610,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS14', 'valueS14'],
        },
        19: {
            name: 'Servo Motor - 15',
            value: 3276,
            min: 0,
            max: 3760,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS15', 'valueS15'],
        },
        20: {
            name: 'Servo Motor - 16',
            value: 2048,
            min: 360,
            max: 3670,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS16', 'valueS16'],
        },
        21: {
            name: 'Servo Motor - 17',
            value: 2818,
            min: 360,
            max: 3570,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS17', 'valueS17'],
        },
        22: {
            name: 'Servo Motor - 18',
            value: 3276,
            min: 0,
            max: 3660,
            isActionEnabled: false,
            type: 'servo',
            reduxLogicName: ['assignS18', 'valueS18'],
        },
        23: {
            name: 'Battery',
            value: 0,
            min: 0,
            max: 100,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isBattery',
            reduxLogicName: ['assignBattery', 'valueBattery'],
        },
        24: {
            name: 'Ultrasonic',
            value: 0,
            min: 0,
            max: 400,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isUltrasonic',
            reduxLogicName: ['assignUltrasonic', 'valueUltrasonic'],
        },
        25: {
            name: 'Accelerometer X',
            value: 0,
            min: -32768,
            max: 32768,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerX',
            reduxLogicName: ['assignAccelerometerX', 'valueAccelerometerX'],
        },
        26: {
            name: 'Accelerometer Y',
            value: 0,
            min: -32768,
            max: 32768,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerY',
            reduxLogicName: ['assignAccelerometerY', 'valueAccelerometerY'],
        },
        27: {
            name: 'Accelerometer Z',
            value: 0,
            min: -32768,
            max: 32768,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isAccelerometerZ',
            reduxLogicName: ['assignAccelerometerZ', 'valueAccelerometerZ'],
        },
        28: {
            name: 'Gyro X',
            value: 0,
            min: -2500,
            max: 2500,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroX',
            reduxLogicName: ['assignGyroX', 'valueGyroX'],
        },
        29: {
            name: 'Gyro Y',
            value: 0,
            min: -2500,
            max: 2500,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroY',
            reduxLogicName: ['assignGyroY', 'valueGyroY'],
        },
        30: {
            name: 'Gyro Z',
            value: 0,
            min: -2500,
            max: 2500,
            isPortSelected: false,
            type: 'input',
            reduxName: 'isGyroZ',
            reduxLogicName: ['assignGyroZ', 'valueGyroZ'],
        },
        31: {
            name: 'Port A1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA1', 'valueA1', 'liveBtnA1'],
        },
        32: {
            name: 'Port A2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA2', 'valueA2', 'liveBtnA2'],
        },
        33: {
            name: 'Port B1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            rgbState: {
                1: { isEnabled: false, show: true, value: [0, 0, 0] },
                2: { isEnabled: false, show: false, value: [0, 0, 0] },
                3: { isEnabled: false, show: false, value: [0, 0, 0] },
                4: { isEnabled: false, show: false, value: [0, 0, 0] },
                5: { isEnabled: false, show: false, value: [0, 0, 0] },
                6: { isEnabled: false, show: false, value: [0, 0, 0] },
                7: { isEnabled: false, show: false, value: [0, 0, 0] },
                8: { isEnabled: false, show: false, value: [0, 0, 0] },
                9: { isEnabled: false, show: false, value: [0, 0, 0] },
                10: { isEnabled: false, show: false, value: [0, 0, 0] },
            },
            rgbCount: 1,
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB1', 'valueB1', 'liveBtnB1'],
        },
        34: {
            name: 'Port B2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB2', 'valueB2', 'liveBtnB2'],
        },
        35: {
            name: 'Port C1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC1', 'valueC1', 'liveBtnC1'],
        },
        36: {
            name: 'Port C2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignC2', 'valueC2', 'liveBtnC2'],
        },
        37: {
            name: 'Port D1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            oledState: {
                1: { isEnabled: false, value: '' },
                2: { isEnabled: false, value: '' },
                3: { isEnabled: false, value: '' },
                4: { isEnabled: false, value: '' },
            },
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD1', 'valueD1', 'liveBtnD1'],
        },
        38: {
            name: 'Port D2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignD2', 'valueD2', 'liveBtnD2'],
        },
        39: {
            name: 'M1',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM1',
            reduxLogicName: ['assignM1', 'valueM1', 'liveBtnM1'],
        },
        40: {
            name: 'M2',
            value: 0,
            min: -100,
            max: 100,
            isPortSelected: false,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'output',
            reduxName: 'isM2',
            reduxLogicName: ['assignM2', 'valueM2', 'liveBtnM2'],
        },
        41: {
            name: 'Servo',
            value: 0,
            min: 0,
            max: 180,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'output',
            reduxName: 'isSimpleServo',
            reduxLogicName: ['assignSimpleServo', 'valueSimpleServo'],
        },
        52: {
            name: 'Bicounter1',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic1', 'valueBic1'],
        },
        53: {
            name: 'Bicounter2',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic2', 'valueBic2'],
        },
        54: {
            name: 'BiData2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid2',
                'assignBid2Value',
                'valueBid2',
                'assignBid2Assign',
                'valueBid2Assign',
            ],
        },
        55: {
            name: 'Biflag1',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif1', 'valueBif1'],
        },
        56: {
            name: 'Biflag2',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif2', 'valueBif2'],
        },
        // "-6": {
        57: {
            name: 'BT RX1',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-7": {
        58: {
            name: 'BT RX2',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-8": {
        59: {
            name: 'BT RX3',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-9": {
        60: {
            name: 'BT RX4',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-10": {
        61: {
            name: 'BT RX5',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-11": {
        62: {
            name: 'USB RX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-12": {
        63: {
            name: 'BiData1',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 60000,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid1',
                'assignBid1Value',
                'valueBid1',
                'assignBid1Assign',
                'valueBid1Assign',
            ],
        },
        // "-13": {
        64: {
            name: 'BT Remote',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-14": {
        65: {
            name: 'USB TX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignUsbtx', 'valueUsbtx'],
        },
        // "-15": {
        66: {
            name: 'Assign1',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn1',
                'valueAsgn1Output',
                'valueAsgn1Input',
            ],
        },
        // "-16": {
        67: {
            name: 'Assign2',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn2',
                'valueAsgn2Output',
                'valueAsgn2Input',
            ],
        },
        // "-17": {
        68: {
            name: 'Assign3',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn3',
                'valueAsgn3Output',
                'valueAsgn3Input',
            ],
        },
        // "-18": {
        69: {
            name: 'Assign4',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn4',
                'valueAsgn4Output',
                'valueAsgn4Input',
            ],
        },
        // "-19": {
        70: {
            name: 'Assign5',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn5',
                'valueAsgn5Output',
                'valueAsgn5Input',
            ],
        },

        // "-20": {
        71: {
            name: 'BT TX 1',
            // value: 0,
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            // reduxLogicName: ["assignBtTx1", "valueBtTx1"],
            reduxLogicName: [
                'assignBtTx1',
                'assignBtTx1Value',
                'valueBtTx1',
                'assignBtTx1Assign',
                'valueBtTx1Assign',
            ],
        },
        // "-21": {
        72: {
            name: 'BT TX 2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx2',
                'assignBtTx2Value',
                'valueBtTx2',
                'assignBtTx2Assign',
                'valueBtTx2Assign',
            ],
        },
        // "-22": {
        73: {
            name: 'BT TX 3',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx3',
                'assignBtTx3Value',
                'valueBtTx3',
                'assignBtTx3Assign',
                'valueBtTx3Assign',
            ],
        },
        // "-23": {
        74: {
            name: 'BT TX 4',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx4',
                'assignBtTx4Value',
                'valueBtTx4',
                'assignBtTx4Assign',
                'valueBtTx4Assign',
            ],
        },
        // "-24": {
        75: {
            name: 'BT TX 5',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx5',
                'assignBtTx5Value',
                'valueBtTx5',
                'assignBtTx5Assign',
                'valueBtTx5Assign',
            ],
        },
        76: {
            name: 'Left RGB 1',
            value: [0, 0, 0],
            valueEyeR1: 0,
            valueEyeG1: 0,
            valueEyeB1: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft1',
            reduxLogicName: [
                'assignEye1',
                'valueEyeR1',
                'valueEyeG1',
                'valueEyeB1',
            ],
        },
        77: {
            name: 'Left RGB 2',
            value: [0, 0, 0],
            valueEyeR2: 0,
            valueEyeG2: 0,
            valueEyeB2: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft2',
            reduxLogicName: [
                'assignEye2',
                'valueEyeR2',
                'valueEyeG2',
                'valueEyeB2',
            ],
        },
        78: {
            name: 'Left RGB 3',
            value: [0, 0, 0],
            valueEyeR3: 0,
            valueEyeG3: 0,
            valueEyeB3: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeLeft3',
            reduxLogicName: [
                'assignEye3',
                'valueEyeR3',
                'valueEyeG3',
                'valueEyeB3',
            ],
        },
        79: {
            name: 'Right RGB 4',
            value: [0, 0, 0],
            valueEyeR4: 0,
            valueEyeG4: 0,
            valueEyeB4: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight1',
            reduxLogicName: [
                'assignEye4',
                'valueEyeR4',
                'valueEyeG4',
                'valueEyeB4',
            ],
        },
        80: {
            name: 'Right RGB 5',
            value: [0, 0, 0],
            valueEyeR5: 0,
            valueEyeG5: 0,
            valueEyeB5: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight2',
            reduxLogicName: [
                'assignEye5',
                'valueEyeR5',
                'valueEyeG5',
                'valueEyeB5',
            ],
        },
        81: {
            name: 'Right RGB 6',
            value: [0, 0, 0],
            valueEyeR6: 0,
            valueEyeG6: 0,
            valueEyeB6: 0,
            min: [0, 0, 0],
            max: [255, 255, 255],
            isPortSelected: false,
            isActionEnabled: false,
            type: 'rgbEye',
            reduxName: 'isEyeRight3',
            reduxLogicName: [
                'assignEye6',
                'valueEyeR6',
                'valueEyeG6',
                'valueEyeB6',
            ],
        },
    },
    KLAW1: {
        0: {
            name: 'Pot 1',
            value: 0,
            min: 0,
            max: 4095,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'input',
            reduxName: 'isPot1',
            reduxLogicName: ['assignPot1', 'valuePot1'],
        },
        1: {
            name: 'Pot 2',
            value: 0,
            min: 0,
            max: 4095,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'input',
            reduxName: 'isPot2',
            reduxLogicName: ['assignPot2', 'valuePot2'],
        },
        2: {
            name: 'Pot 3',
            value: 0,
            min: 0,
            max: 4095,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'input',
            reduxName: 'isPot3',
            reduxLogicName: ['assignPot3', 'valuePot3'],
        },
        '4b': {
            name: 'Individual Servos',
            isPortSelected: false,
            isActionEnabled: false,
            type: 'individualServoOption',
            reduxName: 'isActionServo',
        },
        3: {
            name: 'Pot 4',
            value: 0,
            min: 0,
            max: 4095,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'input',
            reduxName: 'isPot4',
            reduxLogicName: ['assignPot4', 'valuePot4'],
        },
        4: {
            name: 'Pot 5',
            value: 0,
            min: 0,
            max: 4095,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'input',
            reduxName: 'isPot5',
            reduxLogicName: ['assignPot5', 'valuePot5'],
        },
        5: {
            name: 'Pot 6',
            value: 0,
            min: 0,
            max: 4095,
            isPortSelected: false,
            isActionEnabled: false,
            type: 'input',
            reduxName: 'isPot6',
            reduxLogicName: ['assignPot6', 'valuePot6'],
        },
        6: {
            name: 'PWM Motor-1',
            value: 150,
            min: 0,
            max: 300,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'pwm',
            reduxLogicName: ['assignPW1', 'valuePW1', 'liveBtnPW1'],
        },
        7: {
            name: 'PWM Motor-2',
            value: 150,
            min: 80,
            max: 180,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'pwm',
            reduxLogicName: ['assignPW2', 'valuePW2', 'liveBtnPW2'],
        },
        8: {
            name: 'PWM Motor-3',
            value: 150,
            min: 50,
            max: 280,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'pwm',
            reduxLogicName: ['assignPW3', 'valuePW3', 'liveBtnPW3'],
        },
        9: {
            name: 'PWM Motor-4',
            value: 150,
            min: 50,
            max: 250,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'pwm',
            reduxLogicName: ['assignPW4', 'valuePW4', 'liveBtnPW4'],
        },
        10: {
            name: 'PWM Motor-5',
            value: 160,
            min: 60,
            max: 160,
            isActionEnabled: false,
            isLiveBtnActive: false,
            type: 'pwm',
            reduxLogicName: ['assignPW5', 'valuePW5', 'liveBtnPW5'],
        },
        // 11: {
        //     name: 'PWM Motor-6',
        //     value: 0,
        //     min: 0,
        //     max: 180,
        //     isActionEnabled: false,
        //     type: 'pwm',
        //     reduxLogicName: ['assignPW6', 'valuePW6'],
        // },
        31: {
            name: 'Port A1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA1', 'valueA1', 'liveBtnA1'],
        },
        32: {
            name: 'Port A2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignA2', 'valueA2', 'liveBtnA2'],
        },
        33: {
            name: 'Port B1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            rgbCount: 1,
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB1', 'valueB1', 'liveBtnB1'],
        },
        34: {
            name: 'Port B2',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignB2', 'valueB2', 'liveBtnB2'],
        },
        35: {
            name: 'Port F1',
            value: 0,
            isPortSelected: false,
            isLiveBtnActive: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignF1', 'valueF1'],
        },
        36: {
            name: 'Port F2',
            value: 0,
            isPortSelected: false,
            type: 'input',
            type2: 'digital',
            device: null,
            isActionEnabled: false,
            reduxLogicName: ['assignF2', 'valueF2'],
        },
        52: {
            name: 'Bicounter1',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic1', 'valueBic1'],
        },
        53: {
            name: 'Bicounter2',
            value: 0,
            min: -1,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBic2', 'valueBic2'],
        },
        54: {
            name: 'BiData2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid2',
                'assignBid2Value',
                'valueBid2',
                'assignBid2Assign',
                'valueBid2Assign',
            ],
        },
        55: {
            name: 'Biflag1',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif1', 'valueBif1'],
        },
        56: {
            name: 'Biflag2',
            value: 0,
            min: 0,
            max: 1,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignBif2', 'valueBif2'],
        },
        // "-6": {
        57: {
            name: 'BT RX1',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-7": {
        58: {
            name: 'BT RX2',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-8": {
        59: {
            name: 'BT RX3',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-9": {
        60: {
            name: 'BT RX4',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-10": {
        61: {
            name: 'BT RX5',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-11": {
        62: {
            name: 'USB RX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-12": {
        63: {
            name: 'BiData1',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBid1',
                'assignBid1Value',
                'valueBid1',
                'assignBid1Assign',
                'valueBid1Assign',
            ],
        },
        // "-13": {
        64: {
            name: 'BT Remote',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [],
        },
        // "-14": {
        65: {
            name: 'USB TX',
            value: 0,
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: ['assignUsbtx', 'valueUsbtx'],
        },
        // "-15": {
        66: {
            name: 'Assign1',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn1',
                'valueAsgn1Output',
                'valueAsgn1Input',
            ],
        },
        // "-16": {
        67: {
            name: 'Assign2',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn2',
                'valueAsgn2Output',
                'valueAsgn2Input',
            ],
        },
        // "-17": {
        68: {
            name: 'Assign3',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn3',
                'valueAsgn3Output',
                'valueAsgn3Input',
            ],
        },
        // "-18": {
        69: {
            name: 'Assign4',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn4',
                'valueAsgn4Output',
                'valueAsgn4Input',
            ],
        },
        // "-19": {
        70: {
            name: 'Assign5',
            value: ['default', 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignAsgn5',
                'valueAsgn5Output',
                'valueAsgn5Input',
            ],
        },

        // "-20": {
        71: {
            name: 'BT TX 1',
            // value: 0,
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            // reduxLogicName: ["assignBtTx1", "valueBtTx1"],
            reduxLogicName: [
                'assignBtTx1',
                'assignBtTx1Value',
                'valueBtTx1',
                'assignBtTx1Assign',
                'valueBtTx1Assign',
            ],
        },
        // "-21": {
        72: {
            name: 'BT TX 2',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx2',
                'assignBtTx2Value',
                'valueBtTx2',
                'assignBtTx2Assign',
                'valueBtTx2Assign',
            ],
        },
        // "-22": {
        73: {
            name: 'BT TX 3',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx3',
                'assignBtTx3Value',
                'valueBtTx3',
                'assignBtTx3Assign',
                'valueBtTx3Assign',
            ],
        },
        // "-23": {
        74: {
            name: 'BT TX 4',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx4',
                'assignBtTx4Value',
                'valueBtTx4',
                'assignBtTx4Assign',
                'valueBtTx4Assign',
            ],
        },
        // "-24": {
        75: {
            name: 'BT TX 5',
            value: [false, false, 0, false, 'default'],
            min: 0,
            max: 255,
            isPortSelected: false,
            isActionEnabled: false,
            type: '',
            reduxName: '',
            reduxLogicName: [
                'assignBtTx5',
                'assignBtTx5Value',
                'valueBtTx5',
                'assignBtTx5Assign',
                'valueBtTx5Assign',
            ],
        },
    },
}

// const zing_ag_data = JSON.parse(localStorage.getItem('zingAGData'))

// group action to bytes mapping
export const GROUPACTIONS = {
    HUMANOID: {
        // ...zing_ag_data,
        50: { name: 'Attention', value: 0 },
        51: { name: 'Forward', value: 1 },
        52: { name: 'Backward', value: 2 },
        53: { name: 'Left', value: 3 },
        54: { name: 'Right', value: 4 },
        55: { name: 'Wave', value: 5 },
        // 56: { name: 'Bow', value: 6 },
        // 57: { name: 'Wake Up Front', value: 7 },
        58: { name: 'Wake Up Back', value: 8 },
        59: { name: 'Wake Up Back 2', value: 9 },
        // 60: { name: 'Squat', value: 10 },
        61: { name: 'Box Forward', value: 11 },
        62: { name: 'Left Kick', value: 12 },
        63: { name: 'Right Kick', value: 13 },
        64: { name: 'Break Dance', value: 14 },
        // 65: { name: 'Gangnam Style', value: 15 },
        // 66: { name: 'Apple Dance', value: 16 },
        67: { name: 'Slide To Left', value: 17 },
        68: { name: 'Slide To Right', value: 18 },
        69: { name: 'Hook Left', value: 19 },
        70: { name: 'Hook Right', value: 20 },
        71: { name: 'Push Up', value: 21 },
        72: { name: 'Sit UP', value: 22 },
        73: { name: 'Salute', value: 23 },
        74: { name: 'CustomAction', value: 24 },
    },
    HEXAPOD: {
        50: { name: 'Attention', value: 0 },
        51: { name: 'Forward', value: 1 },
        52: { name: 'Backward', value: 2 },
        53: { name: 'Left', value: 3 },
        54: { name: 'Right', value: 4 },
        55: { name: 'SlideToLeft', value: 5 },
        56: { name: 'SlideToRight', value: 6 },
        57: { name: 'Defence', value: 7 },
        58: { name: 'Fight', value: 8 },
        59: { name: 'Greetings', value: 9 },
        60: { name: 'Attack', value: 10 },
        61: { name: 'TurnAround', value: 11 },
        62: { name: 'Booting', value: 12 },
        63: { name: 'Dance', value: 13 },
        74: { name: 'CustomAction', value: 24 },
    },
}

// custom createAction to bytes mapping
export const CUSTOMGROUPACTIONS = {
    HUMANOID: {
        7481: { name: 'CreateAction 1', value: 25 },
        7482: { name: 'CreateAction 2', value: 26 },
        7483: { name: 'CreateAction 3', value: 27 },
        7484: { name: 'CreateAction 4', value: 28 },
        7485: { name: 'CreateAction 5', value: 29 },
    },
    HEXAPOD: {
        // 7481: { name: 'CreateAction 1', value: 25 },
        // 7482: { name: 'CreateAction 2', value: 26 },
        // 7483: { name: 'CreateAction 3', value: 27 },
        // 7484: { name: 'CreateAction 4', value: 28 },
        // 7485: { name: 'CreateAction 5', value: 29 },
    },
    KLAW: {},
}

// export const HUMANOIDCUSTOMGROUPACTIONSZINGV1_0 = {}
export const CUSTOMGROUPACTIONS12 = {}
// min, max and type for external accessories
export const EXTERNALDEVICES = {
    //'v1' for zing version 0.1 and version 1.0
    //also this is used for hexapod version 1.0
    v1: {
        led_1c: { name: 'LED 1C', type: 'output', min: 0, max: 100 },
        led: { name: 'LED 2C', type: 'output', min: 0, max: 100 },
        laser: { name: 'Laser', type: 'output', min: 0, max: 1 },
        RGB: {
            name: 'RGB',
            type: 'output',
            min: [0, 0, 0],
            max: [100, 100, 100],
        },
        mp3: { name: 'mp3', type: 'output', min: 0, max: 5 },
        OLED: { name: 'OLED', type: 'output' },
        geared_motor: {
            name: 'Geared Motor',
            type: 'output',
            min: -100,
            max: 100,
        },
        mini_geared_motor: {
            name: 'Mini Geared Motor',
            type: 'output',
            min: -100,
            max: 100,
        },
        dc_motor: { name: 'DC Motor', type: 'output', min: -100, max: 100 },
        servo_motor: { name: 'Servo Motor', type: 'output', min: 0, max: 180 },
        servo_motor_270: {
            name: 'Servo Motor 270',
            type: 'output',
            min: 0,
            max: 270,
        },
        servo_motor_360: {
            name: 'Servo Motor 360',
            type: 'output',
            min: -100,
            max: 100,
        },
        beeper: { name: 'Beeper', type: 'output', min: 0, max: 1 },

        tact_switch: { name: 'Tact Swtch', type: 'input', min: 0, max: 1 },
        tact_switch_2c: {
            name: 'Tact Swtch 2C',
            type: 'input',
            min: 0,
            max: 1,
        },
        dip_switch: { name: 'Dip Swtch', type: 'input', min: 0, max: 1 },
        dual_switch: { name: 'Dual Swtch', type: 'input', min: 0, max: 1 },
        touch_sensor: {
            name: 'Touch Sensor',
            type: 'input',
            min: 0,
            max: 1000,
        },
        metal_detector: {
            name: 'Metal Detector',
            type: 'input',
            min: 0,
            max: 1,
        },
        heartbeat_sensor: {
            name: 'Heartbeat Sensor',
            type: 'input',
            min: 0,
            max: 1,
        },
        light_sensor: {
            name: 'Light Sensor',
            type: 'input',
            min: 0,
            max: 1024,
        },
        distance_sensor: {
            name: 'Distance Sensor',
            type: 'input',
            min: 0,
            max: 1024,
        },
        temperature_sensor: {
            name: 'Temperature Sensor',
            type: 'input',
            min: 0,
            max: 1,
        },
        sound_sensor: {
            name: 'Sound Sensor',
            type: 'input',
            min: 0,
            max: 1024,
        },
        rain_sensor: { name: 'Rain Sensor', type: 'input', min: 0, max: 1024 },
        humidity: { name: 'humidity', type: 'input', min: 0, max: 1024 },
        gas: { name: 'Gas Sensor', type: 'input', min: 0, max: 1024 },
        pot: { name: 'Pot', type: 'input', min: 0, max: 1024 },
        linear_pot: { name: 'Linear Pot', type: 'input', min: 0, max: 1024 },
        ultrasonic_sensor: {
            name: 'Ultrasonic',
            type: 'input',
            min: 0,
            max: 400,
        },
        joystick: { name: 'Joy Stick', type: 'input', min: 0, max: 1024 },
        extender: { name: 'Extender', type: 'input', min: 0, max: 1024 },
    },
    //'v2' is used for zing and hexapod version 2.0
    v2: {
        led_1c: { name: 'LED 1C', type: 'output', min: 0, max: 100 },
        led: { name: 'LED 2C', type: 'output', min: 0, max: 100 },
        laser: { name: 'Laser', type: 'output', min: 0, max: 1 },
        RGB: {
            name: 'RGB',
            type: 'output',
            min: [0, 0, 0],
            max: [100, 100, 100],
        },
        mp3: { name: 'mp3', type: 'output', min: 0, max: 5 },
        OLED: { name: 'OLED', type: 'output' },
        geared_motor: {
            name: 'Geared Motor',
            type: 'output',
            min: -100,
            max: 100,
        },
        mini_geared_motor: {
            name: 'Mini Geared Motor',
            type: 'output',
            min: -100,
            max: 100,
        },
        dc_motor: { name: 'DC Motor', type: 'output', min: -100, max: 100 },
        servo_motor: { name: 'Servo Motor', type: 'output', min: 0, max: 180 },
        servo_motor_270: {
            name: 'Servo Motor 270',
            type: 'output',
            min: 0,
            max: 270,
        },
        servo_motor_360: {
            name: 'Servo Motor 360',
            type: 'output',
            min: -100,
            max: 100,
        },
        beeper: { name: 'Beeper', type: 'output', min: 0, max: 1 },

        tact_switch: { name: 'Tact Swtch', type: 'input', min: 0, max: 1 },
        tact_switch_2c: {
            name: 'Tact Swtch 2C',
            type: 'input',
            min: 0,
            max: 1,
        },
        dip_switch: { name: 'Dip Swtch', type: 'input', min: 0, max: 1 },
        dual_switch: { name: 'Dual Swtch', type: 'input', min: 0, max: 1 },
        touch_sensor: {
            name: 'Touch Sensor',
            type: 'input',
            min: 0,
            max: 1000,
        },
        metal_detector: {
            name: 'Metal Detector',
            type: 'input',
            min: 0,
            max: 1,
        },
        heartbeat_sensor: {
            name: 'Heartbeat Sensor',
            type: 'input',
            min: 0,
            max: 1,
        },
        light_sensor: {
            name: 'Light Sensor',
            type: 'input',
            min: 0,
            max: 4095,
        },
        distance_sensor: {
            name: 'Distance Sensor',
            type: 'input',
            min: 0,
            max: 4095,
        },
        temperature_sensor: {
            name: 'Temperature Sensor',
            type: 'input',
            min: 0,
            max: 1,
        },
        sound_sensor: {
            name: 'Sound Sensor',
            type: 'input',
            min: 0,
            max: 4095,
        },
        rain_sensor: { name: 'Rain Sensor', type: 'input', min: 0, max: 4095 },
        humidity: { name: 'humidity', type: 'input', min: 0, max: 4095 },
        gas: { name: 'Gas Sensor', type: 'input', min: 0, max: 4095 },
        pot: { name: 'Pot', type: 'input', min: 0, max: 4095 },
        linear_pot: { name: 'Linear Pot', type: 'input', min: 0, max: 4095 },
        ultrasonic_sensor: {
            name: 'Ultrasonic',
            type: 'input',
            min: 0,
            max: 400,
        },
        joystick: { name: 'Joy Stick', type: 'input', min: 0, max: 4095 },
        extender: { name: 'Extender', type: 'input', min: 0, max: 4095 },
    },
}
