module.exports = {
    bic1: 'Bicounter1',
    bic2: 'Bicounter2',
    bic3: 'BI Counter 3',
    bif1: 'Biflag1',
    bif2: 'Biflag2',
    bif3: 'BI Flag 3',
    bid1: 'BI Data 1',
    bid2: 'BI Data 2',
    bid3: 'BI Data 3',
    bmp3: 'BTMp3',
    /*bpr: 'BEEPER',
  irr: 'IR Remote',*/
    btr: 'BT Remote',
    bts: 'BT Slider',
    IOT1: 'IOT1',
    IOT2: 'IOT2',
    IOT3: 'IOT3',
    IOT4: 'IOT4',
    IOT5: 'IOT5',
    IOT6: 'IOT6',
    IOT7: 'IOT7',
    IOT8: 'IOT8',
    IOT9: 'IOT9',
    IOT10: 'IOT10',
    edt: '--Edit Text--',
}
