import React, { useState, useEffect, useLayoutEffect } from 'react'
import style from './Meter.module.css'
import GraphHeader from '../../../ReusableComponents/Header/GraphHeader'
import { useHistory } from 'react-router'
import Graph from '../Graph'
import renderPrgImage from '../../../../source/programImg'
import { GraphPlotStorage } from '../GraphPlotStorage'
import { connect } from 'react-redux'
import stylee from '../Graph.module.css'
import {
    CartesianGrid,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    ResponsiveContainer,
} from 'recharts'
import { ContactsOutlined } from '@material-ui/icons'
function unicodeToChar(data) {
    let str = ''

    data.forEach((element) => {
        str = str.concat(String.fromCharCode(element))
    })

    console.log(str)

    return str
}
function BarGraph(props) {
    let history = useHistory()
    const [isMic, setMic] = useState(false)
    const [datas, setDatas] = useState([
        {
            name: '',
            pv: '',
            uv: 0,
            mic: 0,
            temp: 0,
            batt: 0,
            touch0: 0,
            touch2: 0,
            touch3: 0,
            acceX: 0,
            acceY: 0,
            acceZ: 0,
            red: 0,
            green: 0,
            blue: 0,
            distance: 0,
            light: 0,
            gesture: 0,
            A1: 0,
            A2: 0,
            B1: 0,
            B2: 0,
            C1: 0,
            C2: 0,
            D1: 0,
            D2: 0,
            E1: 0,
            E2: 0,
            F1: 0,
            F2: 0,
        },
    ])
    const [isPauseBtn, setPauseBtn] = useState(false)
    const [isArrowBtn, setArrowBtn] = useState(false)
    const [isTemperature, setTemperature] = useState(false)
    const [isBattery, setBattery] = useState(false)
    const [isTouchZero, setTouchZero] = useState(false)
    const [isTouchOne, setTouchOne] = useState('')
    const [isTouchTwo, setTouchTwo] = useState(false)
    const [isTouchThree, setTouchThree] = useState(false)
    const [isAccelerometerX, setAccelerometerX] = useState(false)
    const [isAccelerometerY, setAccelerometerY] = useState(false)
    const [isAccelerometerZ, setAccelerometerZ] = useState(false)
    const [isColorSensorRed, setColorSensorRed] = useState(false)
    const [isColorSensorGreen, setColorSensorGreen] = useState(false)
    const [isColorSensorBlue, setColorSensorBlue] = useState(false)
    const [isDistanceSensors, setDistanceSensors] = useState(false)
    const [isGestureSensor, setGestureSensor] = useState(false)
    const [isLightSensor, setLightSensor] = useState(false)
    const [isConnectedPort, setConnectedPort] = useState('')
    const [A1, setA1] = useState('')
    const [A2, setA2] = useState('')
    const [B1, setB1] = useState('')
    const [B2, setB2] = useState('')
    const [C1, setC1] = useState('')
    const [C2, setC2] = useState('')
    const [D1, setD1] = useState('')
    const [D2, setD2] = useState('')
    const [E1, setE1] = useState('')
    const [E2, setE2] = useState('')
    const [F1, setF1] = useState('')
    const [F2, setF2] = useState('')
    useEffect(() => {
        console.log(props)
    }, [])

    const handleEventsClick = async (e) => {
        switch (e.target.alt) {
            case 'mic': {
                if (isMic) {
                    console.log('false')
                    GraphPlotStorage.isMic = false
                    props.graphPlotStorage({ isMic: false })
                    setMic(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.isMic = true
                    props.graphPlotStorage({ isMic: true })
                    setMic(true)
                }
                break
            }

            case 'temperature': {
                if (isTemperature) {
                    GraphPlotStorage.isTemperature = false
                    props.graphPlotStorage({ isTemperature: false })
                    setTemperature(false)
                } else {
                    GraphPlotStorage.isTemperature = true

                    props.graphPlotStorage({ isTemperature: true })

                    setTemperature(true)
                }
                break
            }

            case 'battery': {
                if (isBattery) {
                    GraphPlotStorage.isBattery = false
                    props.graphPlotStorage({ isBattery: false })
                    setBattery(false)
                } else {
                    GraphPlotStorage.isBattery = true

                    props.graphPlotStorage({ isBattery: true })

                    setBattery(true)
                }
                break
            }

            case 'touch0': {
                if (isTouchZero) {
                    GraphPlotStorage.isTouchZero = false
                    props.graphPlotStorage({ isTouchZero: false })
                    setTouchZero(false)
                } else {
                    GraphPlotStorage.isTouchZero = true

                    props.graphPlotStorage({
                        isTouchZero: true,
                        A1: false,
                        A2: false,
                    })
                    setA1(false)
                    setA2(false)
                    setTouchZero(true)
                }
                break
            }

            case 'touch1': {
                if (isTouchOne) {
                    GraphPlotStorage.isTouchOne = false
                    props.graphPlotStorage({ isTouchOne: false })
                    setTouchOne(false)
                } else {
                    GraphPlotStorage.isTouchOne = true

                    props.graphPlotStorage({
                        isTouchOne: true,
                        B1: false,
                        B2: false,
                    })
                    setB1(false)
                    setB2(false)
                    setTouchOne(true)
                }
                break
            }

            case 'touch2': {
                if (isTouchTwo) {
                    GraphPlotStorage.isTouchTwo = false
                    props.graphPlotStorage({ isTouchTwo: false })
                    setTouchTwo(false)
                } else {
                    GraphPlotStorage.isTouchTwo = true

                    props.graphPlotStorage({
                        isTouchTwo: true,
                        C1: false,
                        C2: false,
                    })
                    setC1(false)
                    setC2(false)

                    setTouchTwo(true)
                }
                break
            }

            case 'touch3': {
                if (isTouchThree) {
                    GraphPlotStorage.isTouchThree = false
                    props.graphPlotStorage({ isTouchThree: false })
                    setTouchThree(false)
                } else {
                    GraphPlotStorage.isTouchThree = true

                    props.graphPlotStorage({
                        isTouchThree: true,
                        D1: false,
                        D2: false,
                    })
                    setD1(false)
                    setD2(false)

                    setTouchThree(true)
                }
                break
            }

            case 'accelerometerX': {
                if (isAccelerometerX) {
                    GraphPlotStorage.isAccelerometerX = false
                    props.graphPlotStorage({ isAccelerometerX: false })
                    setAccelerometerX(false)
                    sessionStorage.setItem('isAccelerometerX', false)
                } else {
                    GraphPlotStorage.isAccelerometerX = true
                    props.graphPlotStorage({ isAccelerometerX: true })
                    setAccelerometerX(true)
                    sessionStorage.setItem('isAccelerometerX', true)
                }
                break
            }

            case 'accelerometerY': {
                if (isAccelerometerY) {
                    GraphPlotStorage.isAccelerometerY = false
                    props.graphPlotStorage({ isAccelerometerY: false })
                    setAccelerometerY(false)
                    sessionStorage.setItem('isAccelerometerY', false)
                } else {
                    GraphPlotStorage.isAccelerometerY = true
                    props.graphPlotStorage({ isAccelerometerY: true })
                    setAccelerometerY(true)
                    sessionStorage.setItem('isAccelerometerY', true)
                }
                break
            }
            case 'accelerometerZ': {
                if (isAccelerometerZ) {
                    GraphPlotStorage.isAccelerometerZ = false
                    props.graphPlotStorage({ isAccelerometerZ: false })
                    setAccelerometerZ(false)
                    sessionStorage.setItem('isAccelerometerZ', false)
                } else {
                    GraphPlotStorage.isAccelerometerZ = true
                    props.graphPlotStorage({ isAccelerometerZ: true })
                    setAccelerometerZ(true)
                    sessionStorage.setItem('isAccelerometerZ', true)
                }
                break
            }

            case 'A1': {
                if (A1) {
                    console.log('false')
                    GraphPlotStorage.A1 = false
                    props.graphPlotStorage({ A1: false })
                    setA1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.A1 = true
                    props.graphPlotStorage({ A1: true, isTouchZero: false })
                    setTouchZero(false)
                    setA1(true)
                }
                break
            }

            case 'A2': {
                if (A2) {
                    console.log('false')
                    GraphPlotStorage.A2 = false
                    setA2(false)
                    props.graphPlotStorage({ A2: false })
                } else {
                    console.log('true')
                    GraphPlotStorage.A2 = true
                    props.graphPlotStorage({ A2: true, isTouchZero: false })
                    setTouchZero(false)
                    setA2(true)
                }
                break
            }

            case 'B1': {
                if (B1) {
                    console.log('false')
                    GraphPlotStorage.B1 = false
                    props.graphPlotStorage({ B1: false })
                    setB1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.B1 = true
                    props.graphPlotStorage({ B1: true, isTouchOne: false })
                    setTouchOne(false)
                    setB1(true)
                }
                break
            }

            case 'B2': {
                if (B2) {
                    console.log('false')
                    GraphPlotStorage.B2 = false
                    props.graphPlotStorage({ B2: false })
                    setB2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.B2 = true
                    props.graphPlotStorage({ B2: true, isTouchOne: false })
                    setTouchOne(false)
                    setB2(true)
                }
                break
            }

            case 'C1': {
                if (C1) {
                    console.log('false')
                    GraphPlotStorage.C1 = false
                    props.graphPlotStorage({ C1: false })
                    setC1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.C1 = true
                    props.graphPlotStorage({ C1: true, isTouchTwo: false })
                    setTouchTwo(false)
                    setC1(true)
                }
                break
            }

            case 'C2': {
                if (C2) {
                    console.log('false')
                    GraphPlotStorage.C2 = false
                    props.graphPlotStorage({ C2: false })
                    setC2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.C2 = true
                    props.graphPlotStorage({ C2: true, isTouchTwo: false })
                    setTouchTwo(false)
                    setC2(true)
                }
                break
            }

            case 'D1': {
                if (D1) {
                    console.log('false')
                    GraphPlotStorage.D1 = false
                    props.graphPlotStorage({ D1: false })
                    setD1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.D1 = true
                    props.graphPlotStorage({ D1: true, isTouchThree: false })
                    setTouchThree(false)
                    setD1(true)
                }
                break
            }

            case 'D2': {
                if (D2) {
                    console.log('false')
                    GraphPlotStorage.D2 = false
                    props.graphPlotStorage({ D2: false })
                    setD2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.D2 = true
                    props.graphPlotStorage({ D2: true, isTouchThree: false })
                    setTouchThree(false)
                    setD2(true)
                }
                break
            }

            case 'E1': {
                if (E1) {
                    console.log('false')
                    GraphPlotStorage.E1 = false
                    props.graphPlotStorage({ E1: false })
                    setE1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.E1 = true
                    props.graphPlotStorage({ E1: true })
                    setE1(true)
                }
                break
            }

            case 'E2': {
                if (E2) {
                    console.log('false')
                    GraphPlotStorage.E2 = false
                    props.graphPlotStorage({ E2: false })
                    setE2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.E2 = true
                    props.graphPlotStorage({ E2: true })
                    setE2(true)
                }
                break
            }

            case 'F1': {
                if (F1) {
                    console.log('false')
                    GraphPlotStorage.F1 = false
                    props.graphPlotStorage({ F1: false })
                    setF1(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.F1 = true
                    props.graphPlotStorage({ F1: true })
                    setF1(true)
                }
                break
            }

            case 'F2': {
                if (F2) {
                    console.log('false')
                    GraphPlotStorage.F2 = false
                    props.graphPlotStorage({ F2: false })
                    setF2(false)
                } else {
                    console.log('true')
                    GraphPlotStorage.F2 = true
                    props.graphPlotStorage({ F2: true })
                    setF2(true)
                }
                break
            }
        }
    }

    const handleFounInOneSensor = (e) => {
        switch (e.target.alt) {
            case 'lightsensor': {
                if (isLightSensor) {
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    props.graphPlotStorage({ isLightSensor: false })

                    setLightSensor(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: true,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = true
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(true)
                }
                break
            }

            case 'distancesensor': {
                if (isDistanceSensors) {
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    props.graphPlotStorage({ isDistanceSensors: false })

                    setDistanceSensors(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: false,
                        isDistanceSensors: true,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = true
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                    setDistanceSensors(true)
                    setGestureSensor(false)
                    setLightSensor(false)
                }
                break
            }

            case 'gesturesensor': {
                if (isGestureSensor) {
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    props.graphPlotStorage({ isGestureSensor: false })

                    setGestureSensor(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: true,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                    setDistanceSensors(false)
                    setGestureSensor(true)
                    setLightSensor(false)
                }
                break
            }

            case 'colorsensorRed': {
                if (isColorSensorRed) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    props.graphPlotStorage({ isColorSensorRed: false })

                    setColorSensorRed(false)
                } else {
                    props.graphPlotStorage({
                        isColorSensorRed: true,
                        isColorSensorGreen: false,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    setColorSensorRed(true)
                    setColorSensorGreen(false)
                    setColorSensorBlue(false)
                }
                break
            }

            case 'colorsensorGreen': {
                if (isColorSensorGreen) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    props.graphPlotStorage({ isColorSensorGreen: false })
                    setColorSensorGreen(false)
                } else {
                    props.graphPlotStorage({
                        isColorSensorGreen: true,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorBlue: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    setColorSensorRed(false)
                    setColorSensorBlue(false)
                    setColorSensorGreen(true)
                }
                break
            }

            case 'colorsensorBlue': {
                if (isColorSensorBlue) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = false
                    props.graphPlotStorage({ isColorSensorBlue: false })

                    setColorSensorBlue(false)
                } else {
                    props.graphPlotStorage({
                        isColorSensorBlue: true,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensorRed: false,
                        isColorSensorGreen: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorBlue = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorRed = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensorGreen = false
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    setDistanceSensors(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                    setColorSensorRed(false)
                    setColorSensorGreen(false)
                    setColorSensorBlue(true)
                }
                break
            }
        }
    }

    const handleClick = () => {
        if (isArrowBtn == false) {
            console.log('false')
            // sessionStorage.setItem('ArrowBtn', false)
            setArrowBtn(true)
        } else {
            console.log('true')
            // sessionStorage.setItem('ArrowBtn', true)
            setArrowBtn(false)
        }
    }

    const pauseHandleClick = () => {
        if (isConnectedPort.opened) {
            console.log('WORKING')
            handleReadByte_v1(isConnectedPort)

            setPauseBtn(!isPauseBtn)
        } else {
            console.log('falseArrow')
            setPauseBtn(false)
        }
    }

    const stopHandleClick = () => {
        if (isPauseBtn == true) {
            setPauseBtn(false)
            handleReadByte_v1(isConnectedPort)
        }

        console.log('stop')
    }

    let configNumber_ = 1
    let interfaceNumber_ = 1
    let endpointOut_ = 2
    let endpointIn_ = 2

    const MSBLSBToDecimal = (data) => {
        if (data.length !== 2) return
        const [MSBInDecimal, LSBInDecimal] = data
        const MSBInBinary = parseInt(MSBInDecimal).toString(2).padStart(8, 0)
        const LSBInBinary = parseInt(LSBInDecimal).toString(2).padStart(8, 0)
        const dataInBinary = MSBInBinary + LSBInBinary
        const dataInDecimal = parseInt(dataInBinary, 2)

        return dataInDecimal
    }
    const playComputerPABytesToSensorData = (receivedPABytes, selectedPort) => {
        if (selectedPort == 2)
            // A1
            return MSBLSBToDecimal([receivedPABytes[1], receivedPABytes[0]])
        else if (selectedPort == 3)
            // A2
            return MSBLSBToDecimal([receivedPABytes[3], receivedPABytes[2]])
        else if (selectedPort == 4)
            // B1
            return MSBLSBToDecimal([receivedPABytes[5], receivedPABytes[4]])
        else if (selectedPort == 5)
            // B2
            return MSBLSBToDecimal([receivedPABytes[7], receivedPABytes[6]])
        else if (selectedPort == 6)
            // C1
            return MSBLSBToDecimal([receivedPABytes[9], receivedPABytes[8]])
        else if (selectedPort == 7)
            // C2
            return MSBLSBToDecimal([receivedPABytes[11], receivedPABytes[10]])
        else if (selectedPort == 8)
            // D1
            return MSBLSBToDecimal([receivedPABytes[13], receivedPABytes[12]])
        else if (selectedPort == 9)
            // D2
            return MSBLSBToDecimal([receivedPABytes[15], receivedPABytes[14]])
        else if (selectedPort == 10)
            // E1
            return MSBLSBToDecimal([receivedPABytes[17], receivedPABytes[16]])
        else if (selectedPort == 11)
            // E2
            return MSBLSBToDecimal([receivedPABytes[19], receivedPABytes[18]])
        else if (selectedPort == 12)
            // F1
            return MSBLSBToDecimal([receivedPABytes[21], receivedPABytes[20]])
        else if (selectedPort == 13)
            // F2
            return MSBLSBToDecimal([receivedPABytes[23], receivedPABytes[22]])
        else if (selectedPort == 14) {
            // Mic
            return MSBLSBToDecimal([receivedPABytes[25], receivedPABytes[24]])
        } else if (selectedPort == 15)
            // Temperature
            return MSBLSBToDecimal([receivedPABytes[27], receivedPABytes[26]])
        else if (selectedPort == 16)
            // Touch Pad 0
            return MSBLSBToDecimal([receivedPABytes[29], receivedPABytes[28]])
        else if (selectedPort == 17)
            // Touch Pad 1
            return MSBLSBToDecimal([receivedPABytes[31], receivedPABytes[30]])
        else if (selectedPort == 18)
            // Touch Pad 2
            return MSBLSBToDecimal([receivedPABytes[33], receivedPABytes[32]])
        else if (selectedPort == 19)
            // Touch Pad 3
            return MSBLSBToDecimal([receivedPABytes[35], receivedPABytes[34]])
        else if (selectedPort == '20L')
            // Light
            return receivedPABytes[36]
        else if (selectedPort == '20D')
            // Distance
            return receivedPABytes[37]
        else if (selectedPort == '20G') {
            // Gesture
            if (receivedPABytes[38] === '1') return 'Down'
            else if (receivedPABytes[38] === '2') return 'Up'
            else if (receivedPABytes[38] === '3') return 'Right'
            else if (receivedPABytes[38] === '4') return 'Left'
            else return receivedPABytes[38]
        } else if (selectedPort == '20C')
            // Color [R, G, B]
            return [
                receivedPABytes[36],
                receivedPABytes[37],
                receivedPABytes[38],
            ]
        else if (selectedPort == '21X')
            // Accelerometer X
            return MSBLSBToDecimal([receivedPABytes[40], receivedPABytes[39]])
        else if (selectedPort == '21Y')
            // Accelerometer Y
            return MSBLSBToDecimal([receivedPABytes[42], receivedPABytes[41]])
        else if (selectedPort == '21Z')
            // Accelerometer Z
            return MSBLSBToDecimal([receivedPABytes[44], receivedPABytes[43]])
        else if (selectedPort == 22)
            // Battery
            return receivedPABytes[45]

        return
    }

    const continousPABytesReadToggle_v1 = async (
        setPABytes_V1,
        expectedResponseArraySize,
        port,
        callBackFunc,
        overRide
    ) => {
        var int8array
        let combinedResponse = []
        const bytes = new Uint8Array(setPABytes_V1)

        await port.transferOut(endpointOut_, bytes)

        const reading = async () => {
            await port.transferIn(endpointIn_, 64).then((result) => {
                int8array = new Uint8Array(result.data.buffer)
                let uniIntToArray = Array.from(int8array)
                combinedResponse = combinedResponse.concat(uniIntToArray)
                result = Array.from(
                    unicodeToChar(combinedResponse).trim().split(' ')
                )
                if (result.length === 48 && !(result.length >= 49)) {
                    combinedResponse = []
                    callBackFunc(result)
                    return true
                } else {
                    if (result.length > 48) {
                        combinedResponse = []
                    } else {
                        reading()
                    }
                }
            })
        }

        reading()

        // await port.transferIn(endpointIn_, 64).then((result) => {
        //   int8view2 = new Uint8Array(result.data.buffer);
        //   mergedArray = new Uint8Array(int8view1.length + int8view2.length);
        //   mergedArray.set(int8view1);
        //   mergedArray.set(int8view2, int8view1.length);
        //   combinedResponse = unicodeToChar(mergedArray);
        //   final.push(combinedResponse.trim().split(" "));
        // });

        // callBackFunc(final[0]);
    }

    const setPABytes_V1 = () => {
        let sendBytes = Array(25).fill('0'.charCodeAt())

        sendBytes[0] = 'P'.charCodeAt()
        sendBytes[1] = 'A'.charCodeAt()
        sendBytes[23] = 'E'.charCodeAt()
        sendBytes[24] = 'R'.charCodeAt()

        let internalAccessories = JSON.parse(
            sessionStorage.getItem('graphPlotStorage')
        ).internalaccessories

        //Suppose if the user Select PORT A
        if (internalAccessories.portsData[0].ports[0].isDigital === true) {
            sendBytes[2] = 'A'.charCodeAt()
        } else {
            sendBytes[2] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[0].ports[1].isDigital === true) {
            sendBytes[3] = 'A'.charCodeAt()
        } else {
            sendBytes[3] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[1].ports[0].isDigital === true) {
            sendBytes[4] = 'A'.charCodeAt()
        } else {
            sendBytes[4] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[1].ports[1].isDigital === true) {
            sendBytes[5] = 'A'.charCodeAt()
        } else {
            sendBytes[5] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[2].ports[0].isDigital === true) {
            sendBytes[6] = 'A'.charCodeAt()
        } else {
            sendBytes[6] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[2].ports[1].isDigital === true) {
            sendBytes[7] = 'A'.charCodeAt()
        } else {
            sendBytes[7] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[3].ports[0].isDigital === true) {
            sendBytes[8] = 'A'.charCodeAt()
        } else {
            sendBytes[8] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[3].ports[1].isDigital === true) {
            sendBytes[9] = 'A'.charCodeAt()
        } else {
            sendBytes[9] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[4].ports[0].isDigital === true) {
            sendBytes[10] = 'A'.charCodeAt()
        } else {
            sendBytes[10] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[4].ports[1].isDigital === true) {
            sendBytes[11] = 'A'.charCodeAt()
        } else {
            sendBytes[11] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[5].ports[0].isDigital === true) {
            sendBytes[12] = 'A'.charCodeAt()
        } else {
            sendBytes[12] = 'I'.charCodeAt()
        }

        if (internalAccessories.portsData[5].ports[1].isDigital === true) {
            sendBytes[13] = 'A'.charCodeAt()
        } else {
            sendBytes[13] = 'I'.charCodeAt()
        }
        if (internalAccessories.isMic === true) {
            sendBytes[14] = 'M'.charCodeAt()
        }
        if (internalAccessories.isTemperature === true) {
            sendBytes[15] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchZero === true) {
            sendBytes[16] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchOne === true) {
            sendBytes[17] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchTwo === true) {
            sendBytes[18] = 'T'.charCodeAt()
        }
        if (internalAccessories.isTouchThree === true) {
            sendBytes[19] = 'T'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isDistanceSensors === true) {
            sendBytes[20] = 'D'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isGestureSensor === true) {
            sendBytes[20] = 'G'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isColorSensor === true) {
            sendBytes[20] = 'C'.charCodeAt()
        }
        if (internalAccessories.Four_in_one_sensor.isLightSensor === true) {
            sendBytes[20] = 'L'.charCodeAt()
        }
        if (internalAccessories.isAccelerometer === true) {
            sendBytes[21] = 'A'.charCodeAt()
        }
        if (internalAccessories.isBattery === true) {
            sendBytes[22] = 'B'.charCodeAt()
        }
        var bytes = unicodeToChar(sendBytes)
        console.log(bytes)
        return sendBytes
    }
    const recievedBytes_v = (bytesRecieved) => {
        console.log(bytesRecieved, 'recievedData')
        console.log('sensirA1', A1, 'isMic', isMic)
        const sensorData = {
            A1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 2),
            A2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 3),
            B1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 4),
            B2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 5),
            C1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 6),
            C2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 7),
            D1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 8),
            D2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 9),
            E1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 10),
            E2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 11),
            F1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 12),
            F2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 13),
            isMic: playComputerPABytesToSensorData(bytesRecieved.slice(2), 14),
            isTemperature: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                15
            ),

            isTouchZero: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                16
            ),
            isTouchOne: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                17
            ),

            isTouchTwo: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                18
            ),
            isTouchThree: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                19
            ),
            isAccelerometerX: Number(
                playComputerPABytesToSensorData(bytesRecieved.slice(2), '21X')
            ),

            isAccelerometerY: Number(
                playComputerPABytesToSensorData(bytesRecieved.slice(2), '21Y')
            ),

            isAccelerometerZ: Number(
                playComputerPABytesToSensorData(bytesRecieved.slice(2), '21Z')
            ),

            isColorSensorRed: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[0],

            isColorSensorGreen: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[1],

            isColorSensorBlue: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[2],

            isGestureSensor: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20G'
            ),
            isDistanceSensors: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20D'
            ),
            isLightSensor: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20L'
            ),

            isBattery: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                22
            ),
        }
        recievedBytes_v1(sensorData)
    }

    let time = 0
    const recievedBytes_v1 = async (sensorData) => {
        console.log('RECIVEDDATA', sensorData)
        let startTime = window.performance.now()
        let value = sensorData
        // let value = recievedBytes_v
        let endTime = window.performance.now()
        time += endTime - startTime
        console.log('Value-Y', value, 'Time-X', time)
        setDatas([
            { uv: sensorData.isTouchOne },
            { mic: sensorData.isMic },
            { temp: sensorData.isTemperature },
            { batt: sensorData.isTemperature },
            { touch0: sensorData.isTouchZero },
            { touch2: sensorData.isTouchTwo },
            { touch3: sensorData.isTouchThree },
            { acceX: sensorData.isAccelerometerX },
            { acceY: sensorData.isAccelerometerY },
            { acceZ: sensorData.isAccelerometerZ },
            { red: sensorData.isColorSensorRed },
            { green: sensorData.isColorSensorGreen },
            { blue: sensorData.isColorSensorBlue },
            { distance: sensorData.isDistanceSensors },
            { light: sensorData.isLightSensor },
            { gesture: sensorData.isGestureSensor },
            { A1: sensorData.A1 },
            { A2: sensorData.A2 },
            { B1: sensorData.B1 },
            { B2: sensorData.B2 },
            { C1: sensorData.C1 },
            { C2: sensorData.C2 },
            { D1: sensorData.D1 },
            { D2: sensorData.D2 },
            { E1: sensorData.E1 },
            { E2: sensorData.E2 },
            { F1: sensorData.F1 },
            { F2: sensorData.F2 },
            { pv: time },
        ])

        // setDatas([...datas])

        console.log(datas)
    }
    const handleReadByte_v1 = async (port) => {
        const intervalId = sessionStorage.getItem('readSetIntervalId')
        if (intervalId !== null) {
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
            return
        }
        const newIntervalId = setInterval(() => {
            continousPABytesReadToggle_v1(
                setPABytes_V1(),
                // data,
                48,
                port,
                recievedBytes_v
                // recievedBytes_v
            )
        }, 100)
        sessionStorage.setItem('readSetIntervalId', newIntervalId)
    }

    return (
        <>
            <div className={style.Main_Container}>
                <div id={style.Navbar}>
                    <GraphHeader
                        goBack={() => {
                            history.push('/graphPlotter')

                            props.DeselectedComponent({
                                Four_in_one_sensor: {
                                    isDistanceSensors: false,
                                    isGestureSensor: false,
                                    isLightSensor: false,
                                    isColorSensorRed: false,
                                    isColorSensorGreen: false,
                                    isColorSensorBlue: false,
                                },

                                isMic: false,
                                isTemperature: false,
                                isBattery: false,
                                isAccelerometerX: false,
                                isAccelerometerY: false,
                                isAccelerometerZ: false,

                                // input
                                isTouchZero: false,
                                isTouchOne: false,
                                isTouchTwo: false,
                                isTouchThree: false,
                                A1: false,
                                A2: false,
                                B1: false,
                                B2: false,
                                C1: false,
                                C2: false,
                                D1: false,
                                D2: false,
                                E1: false,
                                E2: false,
                                F1: false,
                                F2: false,
                            })
                        }}
                        isGoBack={true}
                        text="Bar Graph"
                        showGraph={false}
                        helpBtn={true}
                        webSerial={true}
                        setConnectedPort={setConnectedPort}
                    />
                </div>
                <div id={style.Body}>
                    {' '}
                    <div className={stylee.Main_Container}>
                        {/* sidebar */}
                        {isArrowBtn === false ? (
                            <>
                                {' '}
                                <div
                                    id={stylee.sidebar}
                                    style={{
                                        backgroundImage: `url("${renderPrgImage(
                                            'smallleftComponentBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',
                                        position: 'relative',
                                        borderRadius: '8px',
                                    }}
                                >
                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isMic ? (
                                        <div
                                            className={stylee.sidebarImg}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="mic"
                                            />
                                            <div className={stylee.sidebarText}>
                                                <p>Mic</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="mic"
                                            />
                                            <div className={stylee.sidebarText}>
                                                <p>Mic</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isTemperature ? (
                                        <div
                                            className={stylee.sidebarImg1}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="temperature"
                                            />
                                            <div
                                                className={stylee.sidebarText1}
                                            >
                                                <p>Temp</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg1}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="temperature"
                                            />
                                            <div
                                                className={stylee.sidebarText1}
                                            >
                                                <p>Temp</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isBattery ? (
                                        <div
                                            className={stylee.sidebarImg2}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="battery"
                                            />
                                            <div
                                                className={stylee.sidebarText2}
                                            >
                                                <p>Battery</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg2}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="battery"
                                            />
                                            <div
                                                className={stylee.sidebarText2}
                                            >
                                                <p>Battery</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isTouchZero ? (
                                        <div
                                            className={stylee.sidebarImg3}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="touch0"
                                            />
                                            <div
                                                className={stylee.sidebarText3}
                                            >
                                                <p>TouchZero</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg3}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="touch0"
                                            />
                                            <div
                                                className={stylee.sidebarText3}
                                            >
                                                <p>TouchZero</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isTouchOne ? (
                                        <div
                                            className={stylee.sidebarImg4}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="touch1"
                                            />
                                            <div
                                                className={stylee.sidebarText4}
                                            >
                                                <p>TouchOne</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg4}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="touch1"
                                            />
                                            <div
                                                className={stylee.sidebarText4}
                                            >
                                                <p>TouchOne</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isTouchTwo ? (
                                        <div
                                            className={stylee.sidebarImg5}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="touch2"
                                            />
                                            <div
                                                className={stylee.sidebarText5}
                                            >
                                                <p>TouchTwo</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg5}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="touch2"
                                            />
                                            <div
                                                className={stylee.sidebarText5}
                                            >
                                                <p>TouchTwo</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.isTouchThree ? (
                                        <div
                                            className={stylee.sidebarImg6}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="touch3"
                                            />
                                            <div
                                                className={stylee.sidebarText6}
                                            >
                                                <p>TouchThree</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg6}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="touch3"
                                            />
                                            <div
                                                className={stylee.sidebarText6}
                                            >
                                                <p>TouchThree</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories
                                        .isAccelerometerX ? (
                                        <div
                                            className={stylee.sidebarImg7}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="accelerometerX"
                                            />
                                            <div
                                                className={stylee.sidebarText7}
                                            >
                                                <p>AccelerometerX</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg7}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="accelerometerX"
                                            />
                                            <div
                                                className={stylee.sidebarText7}
                                            >
                                                <p>AccelerometerX</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories
                                        .isAccelerometerY ? (
                                        <div
                                            className={stylee.sidebarImg8}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="accelerometerY"
                                            />
                                            <div
                                                className={stylee.sidebarText8}
                                            >
                                                <p>AccelerometerY</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg8}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="accelerometerY"
                                            />
                                            <div
                                                className={stylee.sidebarText8}
                                            >
                                                <p>AccelerometerY</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories
                                        .isAccelerometerZ ? (
                                        <div
                                            className={stylee.sidebarImg9}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="accelerometerZ"
                                            />
                                            <div
                                                className={stylee.sidebarText9}
                                            >
                                                <p>AccelerometerZ</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg9}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="accelerometerZ"
                                            />
                                            <div
                                                className={stylee.sidebarText9}
                                            >
                                                <p>AccelerometerZ</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.Four_in_one_sensor
                                        .isColorSensorRed ? (
                                        <div
                                            className={stylee.sidebarImg10}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="colorsensorRed"
                                            />
                                            <div
                                                className={stylee.sidebarText10}
                                            >
                                                <p>ColourSensor(RED)</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg10}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="colorsensorRed"
                                            />
                                            <div
                                                className={stylee.sidebarText10}
                                            >
                                                <p>ColourSensor(RED)</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.Four_in_one_sensor
                                        .isColorSensorGreen ? (
                                        <div
                                            className={stylee.sidebarImg11}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="colorsensorGreen"
                                            />
                                            <div
                                                className={stylee.sidebarText11}
                                            >
                                                <p>ColourSensor(GREEN)</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg11}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="colorsensorGreen"
                                            />
                                            <div
                                                className={stylee.sidebarText11}
                                            >
                                                <p>ColourSensor(GREEN)</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.Four_in_one_sensor
                                        .isColorSensorBlue ? (
                                        <div
                                            className={stylee.sidebarImg12}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="colorsensorBlue"
                                            />
                                            <div
                                                className={stylee.sidebarText12}
                                            >
                                                <p>ColourSensor(BLUE)</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg12}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="colorsensorBlue"
                                            />
                                            <div
                                                className={stylee.sidebarText12}
                                            >
                                                <p>ColourSensor(BLUE)</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.Four_in_one_sensor
                                        .isDistanceSensors ? (
                                        <div
                                            className={stylee.sidebarImg13}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="distancesensor"
                                            />
                                            <div
                                                className={stylee.sidebarText13}
                                            >
                                                <p>DistanceSensor</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg13}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="distancesensor"
                                            />
                                            <div
                                                className={stylee.sidebarText13}
                                            >
                                                <p>DistanceSensor</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.Four_in_one_sensor
                                        .isLightSensor ? (
                                        <div
                                            className={stylee.sidebarImg14}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="lightsensor"
                                            />
                                            <div
                                                className={stylee.sidebarText14}
                                            >
                                                <p>LightSensor</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg14}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="lightsensor"
                                            />
                                            <div
                                                className={stylee.sidebarText14}
                                            >
                                                <p>LightSensor</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.Four_in_one_sensor
                                        .isGestureSensor ? (
                                        <div
                                            className={stylee.sidebarImg15}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="gesturesensor"
                                            />
                                            <div
                                                className={stylee.sidebarText15}
                                            >
                                                <p>GestureSensor</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg15}
                                            onClick={handleFounInOneSensor}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="gesturesensor"
                                            />
                                            <div
                                                className={stylee.sidebarText15}
                                            >
                                                <p>GestureSensor</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.A1 ? (
                                        <div
                                            className={stylee.sidebarImg16}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="A1"
                                            />
                                            <div
                                                className={stylee.sidebarText16}
                                            >
                                                <p>A1</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg16}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="A1"
                                            />
                                            <div
                                                className={stylee.sidebarText16}
                                            >
                                                <p>A1</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.A2 ? (
                                        <div
                                            className={stylee.sidebarImg17}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="A2"
                                            />
                                            <div
                                                className={stylee.sidebarText17}
                                            >
                                                <p>A2</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg17}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="A2"
                                            />
                                            <div
                                                className={stylee.sidebarText17}
                                            >
                                                <p>A2</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.B1 ? (
                                        <div
                                            className={stylee.sidebarImg18}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="B1"
                                            />
                                            <div
                                                className={stylee.sidebarText18}
                                            >
                                                <p>B1</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg18}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="B1"
                                            />
                                            <div
                                                className={stylee.sidebarText18}
                                            >
                                                <p>B1</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.B2 ? (
                                        <div
                                            className={stylee.sidebarImg19}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="B2"
                                            />
                                            <div
                                                className={stylee.sidebarText19}
                                            >
                                                <p>B2</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg19}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="B2"
                                            />
                                            <div
                                                className={stylee.sidebarText19}
                                            >
                                                <p>B2</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.C1 ? (
                                        <div
                                            className={stylee.sidebarImg20}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="C1"
                                            />
                                            <div
                                                className={stylee.sidebarText20}
                                            >
                                                <p>C1</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg20}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="C1"
                                            />
                                            <div
                                                className={stylee.sidebarText20}
                                            >
                                                <p>C1</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.C2 ? (
                                        <div
                                            className={stylee.sidebarImg21}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="C2"
                                            />
                                            <div
                                                className={stylee.sidebarText21}
                                            >
                                                <p>C2</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg21}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="C2"
                                            />
                                            <div
                                                className={stylee.sidebarText21}
                                            >
                                                <p>C2</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.D1 ? (
                                        <div
                                            className={stylee.sidebarImg22}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="D1"
                                            />
                                            <div
                                                className={stylee.sidebarText22}
                                            >
                                                <p>D1</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg22}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="D1"
                                            />
                                            <div
                                                className={stylee.sidebarText22}
                                            >
                                                <p>D1</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.D2 ? (
                                        <div
                                            className={stylee.sidebarImg23}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="D2"
                                            />
                                            <div
                                                className={stylee.sidebarText23}
                                            >
                                                <p>D2</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg23}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="D2"
                                            />
                                            <div
                                                className={stylee.sidebarText23}
                                            >
                                                <p>D2</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.E1 ? (
                                        <div
                                            className={stylee.sidebarImg24}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="E1"
                                            />
                                            <div
                                                className={stylee.sidebarText24}
                                            >
                                                <p>E1</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg24}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="E1"
                                            />
                                            <div
                                                className={stylee.sidebarText24}
                                            >
                                                <p>E1</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.E2 ? (
                                        <div
                                            className={stylee.sidebarImg25}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="E2"
                                            />
                                            <div
                                                className={stylee.sidebarText25}
                                            >
                                                <p>E2</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg25}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="E2"
                                            />
                                            <div
                                                className={stylee.sidebarText25}
                                            >
                                                <p>E2</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.F1 ? (
                                        <div
                                            className={stylee.sidebarImg26}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="F1"
                                            />
                                            <div
                                                className={stylee.sidebarText26}
                                            >
                                                <p>F1</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg26}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="F1"
                                            />
                                            <div
                                                className={stylee.sidebarText26}
                                            >
                                                <p>F1</p>
                                            </div>
                                        </div>
                                    )}

                                    {props.indexData.graphPlotStorage
                                        .internalaccessories.F2 ? (
                                        <div
                                            className={stylee.sidebarImg27}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage(
                                                    'activePop'
                                                )}
                                                alt="F2"
                                            />
                                            <div
                                                className={stylee.sidebarText27}
                                            >
                                                <p>F2</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={stylee.sidebarImg27}
                                            onClick={handleEventsClick}
                                        >
                                            <img
                                                src={renderPrgImage('pop')}
                                                alt="F2"
                                            />
                                            <div
                                                className={stylee.sidebarText27}
                                            >
                                                <p>F2</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/* sidebar arrow button */}
                                <div
                                    id={stylee.arrowBtn}
                                    style={{
                                        backgroundImage: `url("${renderPrgImage(
                                            'CloseGraphSideBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '40.5% 13%',
                                        position: 'relative',
                                        transform: 'translate(0,43%)',
                                    }}
                                    onClick={handleClick}
                                ></div>
                            </>
                        ) : (
                            <div
                                id={stylee.arrowBtn}
                                style={{
                                    backgroundImage: `url("${renderPrgImage(
                                        'CloseGraphSideBar'
                                    )}")`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '40.5% 13%',
                                    position: 'relative',
                                    transform: 'translate(-300%, 43%)',
                                }}
                                onClick={handleClick}
                            ></div>
                        )}
                        {/* graph */}
                        <div id={stylee.body}>
                            {/* <ResponsiveContainer width="100%">
                                <BarChart
                                    width={1000}
                                    height={250}
                                    data={datas}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                   

                                    {isTouchOne === true ? (
                                        <Bar dataKey="uv" fill="#EDDD8A" />
                                    ) : null}

                                    {isMic === true ? (
                                        <Bar dataKey="mic" fill="#EC9EA4" />
                                    ) : null}
                                    {isTemperature === true ? (
                                        <Bar dataKey="temp" fill="#69D2C8" />
                                    ) : null}
                                    {isBattery === true ? (
                                        <Bar dataKey="batt" fill="#98D0ED" />
                                    ) : null}
                                    {isTouchZero === true ? (
                                        <Bar dataKey="touch0" fill="#E3EDE7" />
                                    ) : null}
                                    {isTouchTwo === true ? (
                                        <Bar dataKey="touch2" fill="#FA8D5A" />
                                    ) : null}
                                    {isTouchThree === true ? (
                                        <Bar dataKey="touch3" fill="#541743" />
                                    ) : null}
                                    {isAccelerometerX === true ? (
                                        <Bar dataKey="acceX" fill="#E3EDE7" />
                                    ) : null}
                                    {isAccelerometerY === true ? (
                                        <Bar dataKey="acceY" fill="#FA8D5A" />
                                    ) : null}
                                    {isAccelerometerZ === true ? (
                                        <Bar dataKey="acceZ" fill="#541743" />
                                    ) : null}

                                    {isColorSensorRed === true ? (
                                        <Bar dataKey="red" fill="red" />
                                    ) : null}
                                    {isColorSensorGreen === true ? (
                                        <Bar dataKey="green" fill="green" />
                                    ) : null}
                                    {isColorSensorBlue === true ? (
                                        <Bar dataKey="blue" fill="blue" />
                                    ) : null}
                                    {isDistanceSensors === true ? (
                                        <Bar
                                            dataKey="distance"
                                            fill="#F17D1C"
                                        />
                                    ) : null}
                                    {isLightSensor === true ? (
                                        <Bar dataKey="light" fill="#758B70" />
                                    ) : null}
                                    {isGestureSensor === true ? (
                                        <Bar dataKey="gesture" fill="#606060" />
                                    ) : null}
                                    {A1 === true ? (
                                        <Bar dataKey="A1" fill="#EC9EA4" />
                                    ) : null}
                                    {A2 === true ? (
                                        <Bar dataKey="A2" fill="#EC9EA4" />
                                    ) : null}
                                    {B1 === true ? (
                                        <Bar dataKey="B1" fill="#EC9EA4" />
                                    ) : null}
                                    {B2 === true ? (
                                        <Bar dataKey="B2" fill="#EC9EA4" />
                                    ) : null}
                                    {C1 === true ? (
                                        <Bar dataKey="C1" fill="#EC9EA4" />
                                    ) : null}
                                    {C2 === true ? (
                                        <Bar dataKey="C2" fill="#EC9EA4" />
                                    ) : null}
                                    {D1 === true ? (
                                        <Bar dataKey="D1" fill="#FAFAFA" />
                                    ) : null}
                                    {D2 === true ? (
                                        <Bar dataKey="D2" fill="#FAFAFA" />
                                    ) : null}
                                    {E1 === true ? (
                                        <Bar dataKey="E1" fill="#EC9EA4" />
                                    ) : null}

                                    {E2 === true ? (
                                        <Bar dataKey="E2" fill="#EC9EA4" />
                                    ) : null}
                                    {F1 === true ? (
                                        <Bar dataKey="F1" fill="#EC9EA4" />
                                    ) : null}

                                    {F2 === true ? (
                                        <Bar dataKey="F2" fill="blue" />
                                    ) : null}
                                </BarChart>
                            </ResponsiveContainer> */}
                        </div>

                        {/* bottom */}

                        <div id={stylee.bottom}>
                            <div className={stylee.sub_Bottom}>
                                {isPauseBtn === false ? (
                                    <img
                                        src={renderPrgImage('play')}
                                        onClick={pauseHandleClick}
                                    ></img>
                                ) : (
                                    <img
                                        src={renderPrgImage('pause')}
                                        onClick={pauseHandleClick}
                                    ></img>
                                )}

                                <img
                                    src={renderPrgImage('stop')}
                                    onClick={stopHandleClick}
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        graphPlotStorage: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        assemblyComponent: (data) => {
            dispatch({ type: 'ASSEMBLY_SELECTION', payload: data })
        },
        // webSerialConnect: (data) => {
        //     dispatch(webSerialConnect(data))
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BarGraph)
