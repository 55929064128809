import { forEach, result } from 'lodash'
import unicodeToChar from '../../../utils/unicodeToChar'
import { utf16ToChar } from './utils'
import { portsData } from '../../Assembly/GraphPlotter/GraphPlotStorage'
let endpointOut_ = 2
let endpointIn_ = 2
let readState = false
let count = 1

///////////////////////////////////////////////////////////////////////////////////////////////////////////

const sendBytes = async (data, port) => {
    console.log('send bytes', data)
    if (port === undefined) {
        console.log('Port undefined')
        return false
    }
    try {
        const writer = port.writable.getWriter()
        const bytes = new Uint8Array(data)
        await writer.write(bytes)
        console.log('Data sent : ', data.join(' '))
        // console.log("porttt",port)
        writer.releaseLock()
        return true
    } catch (err) {
        console.log('Data NOT sent : ' + data)
        console.log(err)
        return false
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////

const readBytes = async (port, endPhrase) => {
    console.log(endPhrase, 'EXPECTED PHRASE')
    if (port === undefined) {
        console.log('Port undefined')
        return
    }
    let receivedData = ''
    while (port.readable) {
        if (port.readable.locked) return

        let reader = port.readable.getReader()
        try {
            while (true) {
                console.log('waiting for data ... ')
                const { value, done } = await reader.read()
                const data = unicodeToChar(value).trim()

                // bytes sometime get scambled so we are concatanating the
                receivedData = receivedData.concat(data)
                // console.log('Combined data: ' + receivedData)
                if (receivedData.includes('ERR')) return false
                if (receivedData.includes('Error')) return false
                if (endPhrase === 'OK') {
                    if (receivedData.includes(endPhrase)) return true
                    //if (receivedData.includes("End")) return true;
                    if (receivedData.includes('PAREST')) return 'PAREST'
                } else {
                    if (receivedData.includes(endPhrase)) return true
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            reader.releaseLock()
            console.log('lock released')
        }
    }
}

function timeoutPromise(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new Error('Timeout exceeded'))
        }, ms)
    })
}

const readBytesPa = async (port, condn, length) => {
    if (port === undefined) {
        console.log('Port undefined')
        return
    }
    let receivedData = []
    // console.log('read bytesss', port.readable)
    while (port.readable) {
        console.log('bytes read', port)
        if (port.readable.locked) return
        let reader = port.readable.getReader()
        try {
            while (true) {
                console.log('waiting for data 2... ', port.readable)
                let result
                try {
                    result = await Promise.race([
                        reader.read(),
                        timeoutPromise(5000),
                    ])

                    console.log(result, 'checkinggggg')
                } catch (error) {
                    console.log(error, 'gsk###')
                    // window.location.reload()
                }
                if (result == undefined) return
                const { value, done } = result
                console.log('gsk read value', value)
                const data = arrayConvert(value)
                receivedData = [...receivedData, ...data]

                console.log('receivedData ', receivedData)
                if (
                    receivedData[0] === 80 &&
                    receivedData[1] === 65 &&
                    receivedData[receivedData.length - 2] === 82 &&
                    receivedData[receivedData.length - 1] === 10
                )
                    return receivedData
                if (
                    receivedData[0] === 80 &&
                    receivedData[1] === 65 &&
                    receivedData.length === 36
                ) {
                    console.log('humanoid data', receivedData)
                    return receivedData
                }

                if (condn) {
                    try {
                        reader.cancel()
                        reader.releaseLock()
                    } catch (e) {}
                    return
                }
                if (receivedData.length > length) return
            }
        } catch (err) {
            console.log(err)
        } finally {
            reader.releaseLock()
        }
    }
}

////////////////////////////////////////////////////////////////////////////////////////
////////////  'startReadingBytes' can return the result or keep listening based on the
////////////  'isReturnResult' if <True> - read loop ends after the right response.
////////////            <False> - read loops forever
////////////////////////////////////////////////////////////////////////////////////////

//////////////////////// For version 0.1.13 ///////////////////////////
const startReadingBytes = async (
    port,
    expectedArraySize,
    isReturnResult,
    callBackFunc,
    dataMood
) => {
    if (port === undefined) {
        console.log('Port undefined')
        return
    }

    let combinedResponse = []
    while (port.readable) {
        if (port.readable.locked) return

        let reader = port.readable.getReader()
        try {
            while (true) {
                let result
                console.log('waiting for data gsk... ')

                result = await reader.read()

                const { value } = result
                console.log(value)
                const data = Array.from(value)
                combinedResponse = combinedResponse.concat(data)

                //for zing send the payBytes

                let connectedDevice = sessionStorage.getItem('connectedDevice')
                if (['Humanoid', 'Hexapod'].includes(connectedDevice)) {
                    if (
                        combinedResponse.length === 2 &&
                        combinedResponse[0] == 79 &&
                        combinedResponse[1] == 75
                    ) {
                        console.log(combinedResponse)
                        return { ok: true, data: combinedResponse }
                    }

                    const stringResult = combinedResponse.toString()
                    let matchResult = ''
                    if (
                        connectedDevice !== 'Hexapod' &&
                        (sessionStorage.getItem('deviceVersion') ||
                            '0.0.0')[0] === '0' &&
                        ['Humanoid'].includes(connectedDevice)
                    ) {
                        matchResult = stringResult.match(/80,65.*?69,78,68/)
                    } else {
                        matchResult = stringResult.match(/80,65.*?69,82/)
                    }
                    console.log('combined response', matchResult)
                    if (matchResult) {
                        const extractedPortion = matchResult[0]
                        const extractArray = extractedPortion
                            .split(',')
                            .map(Number)
                        if (isReturnResult)
                            return { ok: true, data: extractArray }
                        console.log('Result data', extractArray)
                        if (dataMood === 'dataMood') {
                            combinedResponse = []
                            return extractArray
                        }
                        callBackFunc(extractArray)
                        combinedResponse = []
                    }
                } else {
                    console.log('Combined data : ' + combinedResponse)
                    if (
                        combinedResponse.length === 2 &&
                        combinedResponse[0] == 79 &&
                        combinedResponse[1] == 75
                    ) {
                        return { ok: true, data: combinedResponse }
                    }

                    // In case of continous reading for PA bytes, we are unable to
                    // release lock from outside of the function.
                    // [Break condition]: when we send WHO, we receive version from humanoid

                    ////////////////////////////////////////////////////////////////////

                    if (combinedResponse.length < expectedArraySize) continue

                    if (combinedResponse.length === expectedArraySize) {
                        if (isReturnResult)
                            return { ok: true, data: combinedResponse }

                        callBackFunc(combinedResponse)
                        combinedResponse = []
                    }
                }

                if (combinedResponse.length > expectedArraySize)
                    combinedResponse = []

                if (isReturnResult) return { ok: false, data: null }
            }
        } catch (err) {
            console.log(err)
        } finally {
            reader.releaseLock()
            if (port !== undefined && port.readable !== null) {
                port.readable.cancel()
            }
            console.log('Reader lock released')
        }
    }
}

//////////////////////  For AppMode  //////////////////////////////////
const readBytesAppMode = async (port, endPhrase) => {
    let receivedData = ''

    while (port.readable.locked === false) {
        console.log(port.readable)
        const reader = port.readable.getReader()
        try {
            while (true) {
                console.log('waiting for data ... ')
                const { value, done } = await reader.read()
                const data = unicodeToChar(value).trim()
                receivedData = receivedData.concat(data)
                if (receivedData.includes(endPhrase)) return receivedData
            }
        } catch (err) {
            console.log(err)
        } finally {
            reader.releaseLock()
            console.log('reader lock released')
        }
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
//////
//////    'continousPABytesReadToggle' func toggles on and off the PAbytes reading on each function call
//////
//////    port: webSerial.port
//////    callBackFunc(response): gets triggered when the cumulative
//////              array size is 'expectedArraySize'
//////    response is passed as a parameter to 'callBackFunc'
//////
//////    overRide: if "OFF", it cancels the setInterval
//////
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const continousPABytesReadToggle = (
    PAbytesToSend,
    expectedResponseArraySize,
    port,
    callBackFunc,
    overRide
) => {
    if (port === undefined) return
    console.log('AAAAAAAAAAAAAA')
    const intervalId = sessionStorage.getItem('readSetIntervalId')
    console.log(intervalId)
    if (intervalId !== null || overRide === 'OFF') {
        clearInterval(intervalId)
        sessionStorage.removeItem('readSetIntervalId')
        if (sessionStorage.getItem('connectedDevice') !== 'Klaw') {
            setTimeout(() => {
                // sendBytes(['R'.charCodeAt(0), 'a'.charCodeAt(0)], port)
                sendBytes(
                    ['E'.charCodeAt(0), 'N'.charCodeAt(0), 'D'.charCodeAt(0)],
                    port
                )
            }, 500)
            setTimeout(() => {
                sendBytes(['M'.charCodeAt(0), '8'.charCodeAt(0)], port)
            }, 800)
        }
        return
    }

    startReadingBytes(port, expectedResponseArraySize, false, callBackFunc)

    const newIntervalId = setInterval(() => {
        sendBytes(PAbytesToSend, port)
    }, 100)

    sessionStorage.setItem('readSetIntervalId', newIntervalId)
}
const arrayConvert = (buffer) => {
    const view = new Uint8Array(buffer)
    const array = Array.from(view)
    return array
}
const continousPABytesReadToggle_v1 = async (
    PAbytesToSend,
    expectedResponseArraySize,
    port,
    callBackFunc,
    overRide
) => {
    if (port === undefined) return

    var int8array
    let combinedResponse = []
    const bytes = new Uint8Array(PAbytesToSend)

    await port.transferOut(endpointOut_, bytes)

    const reading = async () => {
        await port
            .transferIn(endpointIn_, 64)
            .then((result) => {
                result = arrayConvert(result.data.buffer)
                console.log('gsk result', result.length, result)
                if (result.length == 57) {
                    combinedResponse = []
                    callBackFunc(result)
                    console.log(result)
                }
                //  else reading()
            })
            .catch((e) => {
                if (
                    String(e).includes('transferIn') ||
                    String(e).includes('transferOut')
                ) {
                    const interval = sessionStorage.getItem('readSetIntervalId')
                    clearInterval(interval)
                }
                throw e
            })
    }
    await reading()
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////

const sendAndWaitForAck = async (data, ackLength, port) => {
    await sendBytes(data, port)

    const response = await startReadingBytes(port, ackLength, true)
    console.log(response, 'gsk')
    if (response === undefined) return { ok: false }

    // if (response.ok) return response.data;
    return response
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////ZIng Read//////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
const startReadingBytesZing = async (
    port,
    expectedArraySize,
    isReturnResult
) => {
    if (port === undefined) {
        console.log('Port undefined')
        return
    }
    let combinedResponse = []
    while (port.readable) {
        if (port.readable.locked) return
        let reader = port.readable.getReader()
        try {
            while (true) {
                const { value, done } = await reader.read()
                const data = Array.from(value)
                combinedResponse = combinedResponse.concat(data)
                if (combinedResponse.length == 5)
                    return { ok: true, data: combinedResponse }
                console.log(data, 'gsk is cool')
            }
        } catch (e) {
            console.log(e)
        } finally {
            reader.releaseLock()
            port.readable.cancel()
            console.log('Reader lock released')
        }
    }
}

const sendAndWaitForAckForZing = async (data, ackLength, port) => {
    await sendBytes(data, port)
    const response = await startReadingBytesZing(port, ackLength, true)
    console.log(response, 'gsk')
    if (response === undefined) return { ok: false }

    // if (response.ok) return response.data;
    return response
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export {
    sendBytes,
    readBytes,
    readBytesPa,
    readBytesAppMode,
    startReadingBytes,
    sendAndWaitForAck,
    sendAndWaitForAckForZing,
    continousPABytesReadToggle,
    continousPABytesReadToggle_v1,
}
