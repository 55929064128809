import closeBtn from '../../../../../../Assets/img/close.png'
import inputInactiveURAT from '../../../../../../Assets/img/inputInactiveURAT.png'
import Slider from 'react-slick'
import SampleNextArrow from './components/NextArrow'
import SamplePrevArrow from './components/PreviousArrow'

const HelpScreen = ({ ishelpButton, closeModal }) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }
    if (!ishelpButton) {
        return null
    }

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                background: '#fff',
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                zIndex: '1000',
                position: 'absolute',
            }}
        >
            <div
                onClick={closeModal}
                style={{
                    borderRadius: '50%',
                    zIndex: '2000',
                    position: 'absolute',
                    top: '15%',
                    right: '18%',
                }}
            >
                <img src={closeBtn} style={{ width: '7vh', height: 'auto' }} />
            </div>

            <Slider
                {...settings}
                style={{
                    transform: 'translate(-50%,-50%)',
                    top: '46%',
                    left: '50%',
                    zIndex: '10',
                    position: 'absolute',
                }}
            >
                {/* First screen */}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img src={inputInactiveURAT} style={{ width: '100%' }} />

                    <div
                        style={{
                            // height: "90px",
                            width: '3%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '47%',
                            left: '28.9%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '35px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '4px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                                marginBottom: '0px',
                            }}
                        >
                            The enabled ports from the previous screen, can be
                            set as input or Output mode
                        </p>
                    </div>
                </div>
                {/* second screen */}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img src={inputInactiveURAT} style={{ width: '100%' }} />
                    <div
                        style={{
                            // height: "160px",
                            width: '3%',
                            // zIndex: "2000",
                            position: 'absolute',
                            top: '47%',
                            left: '41.4%',
                            fontSize: '22px',
                            borderRadius: '35px',
                            transform: 'translate(-50%,-50%)',
                            background: '#fff',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '4px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular ',
                                borderRadius: '12px',
                                marginBottom: '0px',
                            }}
                        >
                            When enabled as input mode, you can connect input
                            accessories, whereas when enabled as Output mode,
                            you can connect output accessories
                        </p>
                    </div>
                </div>
                {/* third screen */}
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img src={inputInactiveURAT} style={{ width: '100%' }} />
                    <div
                        style={{
                            // height: "160px",
                            width: '3%',
                            // zIndex: "2000",
                            position: 'absolute',
                            top: '49.2%',
                            left: '54%',
                            fontSize: '22px',
                            borderRadius: '35px',
                            transform: 'translate(-50%,-50%)',
                            background: '#fff',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '6px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular ',
                                borderRadius: '12px',
                                marginBottom: '0px',
                            }}
                        >
                            Special Accessories like- Ultrasonic sensor, MP3,
                            OLED display, etc can be enabled here separately at
                            the available ports
                        </p>
                    </div>
                </div>
            </Slider>
        </div>
    )
}
export default HelpScreen
