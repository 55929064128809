import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import RemStyle from './RemSlider.module.css'
import renderImage from '../../../source/importImg'
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={renderImage('rightArrow')}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '53%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={renderImage('leftArrow')}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

export class RemSlider extends Component {
    render() {
        let isRoboki = sessionStorage.getItem('connectedDevice') === 'Roboki'
        let isPCv1 =
            sessionStorage.getItem('connectedDevice') == 'Ace' &&
            (sessionStorage.getItem('deviceVersion') || '0.1.1')?.startsWith(
                '1'
            )
                ? true
                : false
        let connectedDevice = ['Humanoid', 'Hexapod'].includes(
            sessionStorage.getItem('connectedDevice')
        )
            ? true
            : false
        let device = ''
        switch (sessionStorage.getItem('connectedDevice')) {
            case 'Hexapod':
                device = 'Crawl-e'
                break
            case 'Humanoid':
                device = 'Zing'
                break
            case 'Klaw':
                device = 'Klaw-b'
                break
            case 'Ace':
                device = 'PlayComputer'
                break
            default:
                device = sessionStorage.getItem('connectedDevice')
        }
        // sessionStorage.getItem('connectedDevice') == 'Hexapod'
        //     ? 'Crawlee'
        //     : 'Zing'
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        }
        return (
            <div>
                <div
                    className="container"
                    style={{
                        height: '100vh',
                        background: 'white',
                        left: '19.5%',
                    }}
                >
                    <div
                        className="Ss_sliderPlay"
                        style={{
                            background: '#fff',
                            width: '100vw',
                            height: '100vh',
                            position: 'relative',
                        }}
                    >
                        {connectedDevice && (
                            <Slider
                                {...settings}
                                style={{
                                    top: '46%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('remoteH')}
                                        />
                                    </div>
                                    <div
                                        className={RemStyle.Hel}
                                        style={{ right: '64.5%' }}
                                    >
                                        <p className={RemStyle.Hel_P}>
                                            {' '}
                                            Help Button will explain all the
                                            features and functionality of the
                                            entire app for each screen{' '}
                                        </p>
                                    </div>
                                    <div
                                        className={RemStyle.Rem}
                                        style={{
                                            right: '63%',
                                            top: '40.5%',
                                            width: '5%',
                                        }}
                                    >
                                        <p className={RemStyle.Rem_P}>
                                            When the app is connected to the
                                            {' ' + device}, the icon will be
                                            GREEN in colour, & when
                                            disconnected, the icon will turn
                                            RED. If the app gets disconnected
                                            from the {device}, then on top of
                                            this button, it’ll attempt to
                                            connect automatically.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('remoteH1')}
                                        />
                                    </div>
                                    <div className={RemStyle.Rem1}>
                                        <p className={RemStyle.Rem_P1}>
                                            There are 5 buttons in a form of
                                            joystick/direction button to control
                                            your {device} to move
                                            front,back,left,right or stop.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('remoteH2')}
                                        />
                                    </div>
                                    <div className={RemStyle.Rem2}>
                                        <p className={RemStyle.Rem_P1}>
                                            On the click of these buttons the
                                            &nbsp;{device} will perform the
                                            respective actions that are already
                                            pre-programmed.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        )}

                        {!isPCv1 && !connectedDevice && !isRoboki && (
                            <Slider
                                {...settings}
                                style={{
                                    top: '46%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt1')}
                                        />
                                    </div>
                                    <div className={RemStyle.Hel}>
                                        <p className={RemStyle.Hel_P}>
                                            {' '}
                                            Help Button will explain all the
                                            features and functionality of the
                                            entire app for each screen{' '}
                                        </p>
                                    </div>
                                    <div className={RemStyle.Rem}>
                                        <p className={RemStyle.Rem_P}>
                                            When the app is connected to the
                                            {' ' + device}, the icon will be
                                            GREEN in colour, & when
                                            disconnected, the icon will turn
                                            RED. If the app gets disconnected
                                            from the {device}, then on top of
                                            this button, it’ll attempt to
                                            connect automatically.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt2')}
                                        />
                                    </div>
                                    <div className={RemStyle.con}>
                                        <p className={RemStyle.con_P}>
                                            You can make the {device + '’s'}
                                            beeper play various tones from high
                                            frequency to low frequency.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt3')}
                                        />
                                    </div>
                                    <div className={RemStyle.Mus}>
                                        <p className={RemStyle.Mus_P}>
                                            The {device} has RGB LED as eye,
                                            which you can control independently.
                                            There are 3 sliders for each RGB LED
                                            using which you can control the RED
                                            GREEN BLUE contrasts of the LED.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt4')}
                                        />
                                    </div>
                                    <div className={RemStyle.Cam}>
                                        <p className={RemStyle.Cam_P}>
                                            The {device} has RGB LED as eye,
                                            which you can control independently.
                                            There are 3 sliders for each RGB LED
                                            using which you can control the RED
                                            GREEN BLUE contrasts of the LED.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt5')}
                                        />
                                    </div>
                                    <div className={RemStyle.Re5}>
                                        <p className={RemStyle.Re5_P}>
                                            You can turn ON/OFF each of the 4
                                            Smile leds using the buttons
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt6')}
                                        />
                                    </div>

                                    <div className={RemStyle.Re6}>
                                        <p className={RemStyle.Re6_P}>
                                            {' '}
                                            Talk back mode: The {device}
                                            will go into listening mode, as long
                                            as the button is pressed. You can
                                            speak anything you want to the
                                            {device} for a maximum of 5 seconds
                                            & the {device} will repeat the same
                                            in its own voice.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt6')}
                                        />
                                    </div>

                                    <div className={RemStyle.Re7}>
                                        <p className={RemStyle.Re7_P}>
                                            Gesture mode: The {device + '’s'}
                                            gesture sensor can detect the
                                            direction of motion of your hand/
                                            finger above it. You can swipe above
                                            the sensor left, right, up or down &
                                            the {device} will emote the same
                                            using the RGB leds & the smile LEDs.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <img
                                        className={RemStyle.img}
                                        src={renderImage('Remt6')}
                                    />
                                    <div className={RemStyle.Re8}>
                                        <p className={RemStyle.Re8_P}>
                                            Gesture mode: The {device + '’s'}
                                            gesture sensor can detect the
                                            direction of motion of your hand/
                                            finger above it. You can swipe above
                                            the sensor left, right, up or down &
                                            the {device} will emote the same
                                            using the RGB leds & the smile LEDs.
                                        </p>
                                    </div>
                                </div>
                            </Slider>
                        )}

                        {isPCv1 && (
                            <Slider
                                {...settings}
                                style={{
                                    top: '46%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH1')}
                                        />
                                    </div>
                                    <div
                                        className={RemStyle.Hel}
                                        style={{ left: '18.1%', width: '2.7%' }}
                                    >
                                        <p className={RemStyle.Hel_P}>
                                            {' '}
                                            Help Button will explain all the
                                            features and functionality of the
                                            entire app for each screen{' '}
                                        </p>
                                    </div>
                                    <div
                                        className={RemStyle.Rem}
                                        style={{ left: '18.1%', width: '2.8%' }}
                                    >
                                        <p className={RemStyle.Rem_P}>
                                            When the app is connected to the
                                            {' ' + device}, the icon will be
                                            GREEN in colour, & when
                                            disconnected, the icon will turn
                                            RED. If the app gets disconnected
                                            from the {device}, then on top of
                                            this button, it’ll attempt to
                                            connect automatically.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH2')}
                                        />
                                    </div>
                                    <div className={RemStyle.Level}>
                                        <p className={RemStyle.Level_P}>
                                            These are Mode which the PeeCee Ted
                                            will be work as<br></br>
                                            1. Level: PeeCee will work as a
                                            spirit level meter, by displaying
                                            the level on the 12 RGB Led's
                                            <br></br>
                                            2. Disco: the PeeCee will run some
                                            random lights & play tone in form of
                                            a Disco <br></br>
                                            3. Gesture: PeeCee will react to
                                            gestures on its sensor in 4
                                            directions.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH3')}
                                        />
                                    </div>
                                    <div className={RemStyle.Gui}>
                                        <p className={RemStyle.Gui_P}>
                                            1.Dice: PeeCee can be used as a
                                            Dice, by shaking it for a few
                                            seconds, post which it'll randomly
                                            turn ON the RGB Led's indicating the
                                            number<br></br>
                                            2. Guitar: PeeCee can work as a
                                            vitual guitar, by touching its touch
                                            pads as tone set & shaking the
                                            PeeCee up & down like the strings of
                                            an actual guitar
                                            <br></br>
                                            3. Simon says: You can play the
                                            squid game, by using PeeCee. It'll
                                            play a tone with green light turned
                                            ON. during which you can move. when
                                            it stops at a random time, you need
                                            to be stationary & not moving. If
                                            you move, the PeeCee will detect
                                            your movement & indicate as OUT.{' '}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH4')}
                                        />
                                    </div>
                                    <div className={RemStyle.Rgb}>
                                        <p className={RemStyle.Rgb_P}>
                                            RGB Led's Left set of eyes & right
                                            set of eye - colour control -
                                            sliders. These can be slided and set
                                            to required colour combination.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH5')}
                                        />
                                    </div>
                                    <div className={RemStyle.Bep}>
                                        <p className={RemStyle.Bep_P}>
                                            Beeper frequency control slider to
                                            change the sound frequency the
                                            beeper created on the PeeCee
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH6')}
                                        />
                                    </div>
                                    <div className={RemStyle.Smi}>
                                        <p className={RemStyle.Smi_P}>
                                            Smile Led - On & Off button.
                                        </p>
                                    </div>
                                </div>

                                {/* <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('PlayH7')}
                                        />
                                    </div>
                                    <div className={RemStyle.Rob}>
                                        <p className={RemStyle.Rob_P}>
                                            Robotic Arm - movement control
                                            button. use these buttons to control
                                            the 1st 3 servo motor's up &
                                            downward motion.
                                        </p>
                                    </div>
                                </div> */}

                                {/* <div>
                                    <img
                                        className={RemStyle.img}
                                        src={renderImage('PlayH8')}
                                    />
                                    <div className={RemStyle.Arm}>
                                        <p className={RemStyle.Arm_P}>
                                            Robotic Arm - finger grasper motion
                                            control button. Use these button to
                                            control the amount of Opening /
                                            closing of the finger grasper on the
                                            Arm
                                        </p>
                                    </div>
                                </div> */}

                                {/* <div>
                                    <img
                                        className={RemStyle.img}
                                        src={renderImage('PlayH9')}
                                    />
                                    <div className={RemStyle.Mot}>
                                        <p className={RemStyle.Mot_P}>
                                            Left right motion of the Robotic Arm
                                            - base &^ finger grasper. Use this
                                            button to control the left/ right
                                            direction control of the Robotic arm
                                            base motor or the Finger grasper -
                                            Servo motor
                                        </p>
                                    </div>
                                </div> */}
                            </Slider>
                        )}

                        {isRoboki && (
                            <Slider
                                {...settings}
                                style={{
                                    top: '46%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt1')}
                                        />
                                    </div>
                                    <div
                                        className={RemStyle.Hel}
                                        style={{ right: '62.6%', width: '7%' }}
                                    >
                                        <p className={RemStyle.Hel_P}>
                                            {' '}
                                            Help Button will explain all the
                                            features and functionality of the
                                            entire app for each screen{' '}
                                        </p>
                                    </div>
                                    <div
                                        className={RemStyle.Rem}
                                        style={{ right: '62.6%', width: '7%' }}
                                    >
                                        <p className={RemStyle.Rem_P}>
                                            When the app is connected to the
                                            {' ' + device}, the icon will be
                                            GREEN in colour, & when
                                            disconnected, the icon will turn
                                            RED. If the app gets disconnected
                                            from the {device}, then on top of
                                            this button, it’ll attempt to
                                            connect automatically.
                                        </p>
                                    </div>
                                </div>

                                {/* <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt2')}
                                        />
                                    </div>
                                    <div className={RemStyle.con}>
                                        <p className={RemStyle.con_P}>
                                            You can make the {device + '’s'}
                                            beeper play various tones from high
                                            frequency to low frequency.
                                        </p>
                                    </div>
                                </div> */}

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt3')}
                                        />
                                    </div>
                                    <div
                                        className={RemStyle.Mus}
                                        style={{ right: '49.6%', width: '5%' }}
                                    >
                                        <p className={RemStyle.Mus_P}>
                                            The {device} has 2 RGB LED as eye,
                                            which you can control independently.
                                            There are 3 sliders for each RGB LED
                                            using which you can control the RED
                                            GREEN BLUE contrasts of the LED.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt4')}
                                        />
                                    </div>
                                    <div
                                        className={RemStyle.Cam}
                                        style={{ right: '40.4%', width: '5%' }}
                                    >
                                        <p className={RemStyle.Cam_P}>
                                            The {device} has 2 RGB LED as eye,
                                            which you can control independently.
                                            There are 3 sliders for each RGB LED
                                            using which you can control the RED
                                            GREEN BLUE contrasts of the LED.
                                        </p>
                                    </div>
                                </div>

                                {/* <div>
                                    <div>
                                        {' '}
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt5')}
                                        />
                                    </div>
                                    <div className={RemStyle.Re5}>
                                        <p className={RemStyle.Re5_P}>
                                            You can turn ON/OFF each of the 4
                                            Smile leds using the buttons
                                        </p>
                                    </div>
                                </div> */}

                                {/* <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt6')}
                                        />
                                    </div>

                                    <div className={RemStyle.Re6}>
                                        <p className={RemStyle.Re6_P}>
                                            {' '}
                                            Talk back mode: The {device}
                                            will go into listening mode, as long
                                            as the button is pressed. You can
                                            speak anything you want to the
                                            {device} for a maximum of 5 seconds
                                            & the {device} will repeat the same
                                            in its own voice.
                                        </p>
                                    </div>
                                </div> */}

                                {/* <div>
                                    <div>
                                        <img
                                            className={RemStyle.img}
                                            src={renderImage('Remt6')}
                                        />
                                    </div>

                                    <div className={RemStyle.Re7}>
                                        <p className={RemStyle.Re7_P}>
                                            Gesture mode: The {device + '’s'}
                                            gesture sensor can detect the
                                            direction of motion of your hand/
                                            finger above it. You can swipe above
                                            the sensor left, right, up or down &
                                            the {device} will emote the same
                                            using the RGB leds & the smile LEDs.
                                        </p>
                                    </div>
                                </div> */}
                                {/* 
                                <div>
                                    <img
                                        className={RemStyle.img}
                                        src={renderImage('Remt6')}
                                    />
                                    <div className={RemStyle.Re8}>
                                        <p className={RemStyle.Re8_P}>
                                            Gesture mode: The {device + '’s'}
                                            gesture sensor can detect the
                                            direction of motion of your hand/
                                            finger above it. You can swipe above
                                            the sensor left, right, up or down &
                                            the {device} will emote the same
                                            using the RGB leds & the smile LEDs.
                                        </p>
                                    </div>
                                </div> */}
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default RemSlider
