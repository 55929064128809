import React from 'react'
import {
    Switch,
    Route,
    // Redirect
} from 'react-router-dom'
import PlayComputerSelectPorts from './Port'
// import InputOutput from './Input'
import InputOutput from './components/Input/Input'
import Digital from './components/Digital/Digital'
// import Digital from './Digital'
import FlowchartPage from './FlowchartPage'
import PlayComputerInternalAccessories from './InternalAccessories'
import ProgramSelection from './programSelection'
import SaveFlow from '../SavePageFlow'
import SavedFlow from '../SavePageFlow/savedFlow'
import Header from './Header'
import HumanoidSelectFeatures from '../Humanoid/HumanoidSelectFeatures'
import HumanoidSelectPorts from './Humanoid/HumanoidSelectPorts'
import HumanoidIIOPort from './Humanoid/HumanoidIIOPort'
import HumanoidDA from './Humanoid/HumanoidDA'

const deviceToComponent = {
    ACE: {
        InternalAccessories: PlayComputerInternalAccessories,
        selectports: PlayComputerSelectPorts,
        'input-output': InputOutput,
        'digital-analog': Digital,
    },
    HUMANOID: {
        InternalAccessories: HumanoidSelectFeatures,
        selectports: HumanoidSelectPorts,
        'input-output': HumanoidIIOPort,
        'digital-analog': HumanoidDA,
    },
    HEXAPOD: {
        InternalAccessories: HumanoidSelectFeatures,
        selectports: HumanoidSelectPorts,
        'input-output': HumanoidIIOPort,
        'digital-analog': HumanoidDA,
    },
}

function Flow() {
    const connectedDevice = sessionStorage
        .getItem('connectedDevice')
        .toUpperCase()
    return (
        <>
            <Switch>
                <Route exact path="/flow" component={ProgramSelection} />
                <Route
                    exact
                    path="/flow/InternalAccessories"
                    component={
                        deviceToComponent[connectedDevice][
                            'InternalAccessories'
                        ]
                    }
                />
                <Route
                    exact
                    path="/flow/selectports"
                    component={
                        deviceToComponent[connectedDevice]['selectports']
                    }
                    a
                />
                <Route
                    exact
                    path="/flow/input-output"
                    component={
                        deviceToComponent[connectedDevice]['input-output']
                    }
                    a
                />
                <Route
                    exact
                    path="/flow/digital-analog"
                    component={
                        deviceToComponent[connectedDevice]['digital-analog']
                    }
                    a
                />
                {/* <Route exact path="/flow/digital-analog" component={Digital} /> */}
                <Route exact path="/flow/flowchart" component={FlowchartPage} />
                <Route exact path="/flow/save" component={SaveFlow} />
                <Route exact path="/flow/savedFlow" component={SavedFlow} />
            </Switch>
        </>
    )
}

export default Flow
