import React, { useState, useEffect } from 'react'
import {
    startReadingBytes,
    sendBytes,
    continousPABytesReadToggle,
    sendAndWaitForAck,
    readBytes,
} from './byteTransfer'
import {
    HUMANOID_PA_SEND_BYTES,
    PCClearPin,
    PCClearProgram,
    PCVersionCode,
    ZingByteCodes,
} from './byteCodes'
import { connect } from 'react-redux'
import { decimalToMSBLSB, MSBLSBToDecimal, utf16ToChar } from './utils'
import {getVersion} from './getVersion'

const humanoidWave = ['R'.charCodeAt(0), 'k'.charCodeAt(0)]

HUMANOID_PA_SEND_BYTES[10] = 'B'.charCodeAt(0)
HUMANOID_PA_SEND_BYTES[11] = 'A'.charCodeAt(0)
HUMANOID_PA_SEND_BYTES[12] = 'G'.charCodeAt(0)

function TestingWebSerial({ webSerial }) {
    const [response, setResponse] = useState([])

    const handlegetVersion = async () => {
        const version = await getVersion(webSerial.port)
        console.log(version)
    }

    const handleSend = () => {
        sendBytes(['F'.charCodeAt(0), 'S'.charCodeAt(0)], webSerial.port)
        // readBytes(webSerial.port, "");
    }

    const clearProgram = () => {
        sendBytes(PCClearProgram, webSerial.port)
        // readBytes(webSerial.port, "");
    }

    const clearPin = () => {
        sendBytes(PCClearPin, webSerial.port)
        // readBytes(webSerial.port, "");
    }

    const handleSendContinous = () => {
        // setInterval(() => {
        // 	handleRead();
        // }, 1000);

        continousPABytesReadToggle(
            PCVersionCode,
            10,
            webSerial.port,
            (response) => {
                setResponse(response)
            }
            // "OFF"
        )
    }

    const handleRead = async () => {
        // sendBytes(humanoidPCBytes, webSerial.port);
        readBytes(webSerial.port)
    }

    const buttonStyle = { padding: '0.5rem 1rem', margin: '1rem' }

    return (
        <>
            <h1>TESTING WEBSERIAL</h1>
            <br />
            <p>Connection: {webSerial.isConnected ? 'TRUE' : 'FALSE'}</p>
            <p>Response : {utf16ToChar(response)}</p>
            {/* <p>Version : {utf16ToChar([48, 46, 49, 46, 49, 50, 13, 10])}</p> */}
            <p>Response Array length : {response.length}</p>
            <button onClick={handleRead} style={buttonStyle}>
                Read
            </button>
            <button onClick={handleSend} style={buttonStyle}>
                Send
            </button>
            <button onClick={handleSendContinous} style={buttonStyle}>
                ON / OFF Continous SEND
            </button>
            <button onClick={clearProgram} style={buttonStyle}>
                clear PC Program
            </button>
            <button onClick={clearPin} style={buttonStyle}>
                clear PC Pin
            </button>
            <br />
            <p>
                Data in Decimal : 256 ----------- Data in MSB, LSB :{' '}
                {decimalToMSBLSB(256).toString()}
            </p>
            <p>
                Data in MSB, LSB : 1, 1 --------- Data in Decimal :{' '}
                {MSBLSBToDecimal([1, 1])}
            </p>
            <p>{utf16ToChar([87, 72, 79])}</p>
            <p>
                latest Play Computer version:{' '}
                {utf16ToChar([80, 67, 48, 46, 49, 46, 49, 51])}
            </p>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(TestingWebSerial)
