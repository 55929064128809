import React from 'react'
import { connect } from 'react-redux'

import DialogModal from '../DialogModal/DialogModal'
import { hideModal } from '../../../redux/actions'

function PopUp({ popUpModal, hideModal }) {
    const givePopUpContent = (type) => {
        if (type == 'PeeCeeUSBDriver')
            return (
                <p>
                    Kindly Install the{' '}
                    <a href="/PeeCeeDriver.exe">PeeCee driver</a> for windows
                </p>
            )
    }

    return (
        <div>
            <DialogModal
                show={popUpModal.showModal}
                text={givePopUpContent(popUpModal.modalType)}
                showCloseBtn={true}
                handleDialogModalClose={() => hideModal()}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => {
            dispatch(hideModal())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUp)
