import { Translate } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import renderPrgImage from '../../source/programImg'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import appMenu from '../../Assets/Bisoft_UI/AppMode/appMenu@2x.png'
import style from './Appmode.module.css'
import MainHeader from '../ReusableComponents/Header/MainHeader'
import PrgmSelection from '../ReusableComponents/PrgmSlider/PrgmSelection/PrgmSelection'
import cacheAssets from '../../utils/cacheAssets'

const AppModeSelection = () => {
    const history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const appModeData = [
        'enable',
        'count',
        'arr',
        'appDetails',
        'img',
        'tensor',
        'allDetails',
        'currentSavedSessionId',
    ]

    const newProject = () => {
        history.push('/chooseDevice')
    }

    const openProject = (event) => {
        let file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (evt) => {
            const sessionData = JSON.parse(evt.target.result)
            Object.entries(sessionData).map((value) => {
                if (appModeData.includes(value[0])) {
                    sessionStorage.setItem(value[0], value[1])
                }
            })
            history.push('/chooseDevice')
        }
        reader.readAsText(file)
    }

    const backButton = () => {
        history.push('/Selection')
    }

    const helpBtn = (e) => {
        console.log('save')
    }

    const container = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '22rem',
        width: '100vw',
        paddingTop: '180px',
        position: 'absolute',
        top: '30vh',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    }

    const menuText = {
        position: 'relative',
        top: '-8.5rem',
        left: '6rem',
        fontSize: '1.3rem',
        cursor: 'pointer',
    }

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderPrgImage('SelectionPrgf1'),
            renderPrgImage('SelectionPrgf2'),
            renderPrgImage('SelectionPrgf3'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])
    return (
        <>
            <div>
                {/* Header */}
                <MainHeader
                    help_btnPosition="right"
                    helper={
                        <div
                            className="PS-S_slide"
                            style={{
                                left: '70%',
                                transform: 'translate(-50%)',
                            }}
                        >
                            <PrgmSelection />
                        </div>
                    }
                    goBack={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/Selection')
                    }}
                    isGoBack={true}
                />
                <img
                    src={appMenu}
                    style={{
                        position: 'absolute',
                        top: '8.5%',
                        left: '12%',
                        transform: 'translate(-50%,-50%)',
                        height: '22%',
                    }}
                />

                {/* <div>
                    
                    <img
                        src={renderPrgImage('backBtn')}
                        draggable="false"
                        onClick={backButton}
                        style={{
                            position: 'absolute',
                            top: '7.5%',
                            left: '4%',
                            transform: 'translate(-50%,-50%)',
                            height: '9%',
                            zIndex: '10',
                            cursor: 'pointer',
                        }}
                    />
                    <img
                        src={appMenu}
                        style={{
                            position: 'absolute',
                            top: '8.5%',
                            left: '12%',
                            transform: 'translate(-50%,-50%)',
                            height: '25%',
                        }}
                    />
                    <img
                        src={renderPrgImage('helpBtnInActive')}
                        onClick={helpBtn}
                        style={{
                            position: 'absolute',
                            top: '7.5%',
                            right: '4%',
                            transform: 'translate(-50%,-50%)',
                            height: '9%',
                            zIndex: '10',
                            cursor: 'pointer',
                        }}
                    />
                </div> */}
                {/* Body */}
                <div style={{ height: '80%', position: 'relative' }}>
                    <div style={container}>
                        <div style={{ width: '19rem', height: '15rem' }}>
                            <img
                                src={renderPrgImage('newfilegroupbutton')}
                                draggable="false"
                                style={{ width: '19rem', cursor: 'pointer' }}
                                onClick={newProject}
                            />

                            <p style={menuText} onClick={newProject}>
                                New project
                            </p>
                        </div>
                        <div style={{ width: '19rem', height: '15rem' }}>
                            <label htmlFor="file-input">
                                <img
                                    src={renderPrgImage(
                                        'yourprojectsgroupbutton'
                                    )}
                                    draggable="false"
                                    style={{
                                        width: '19rem',
                                        cursor: 'pointer',
                                    }}
                                />
                                <p style={menuText}>Open project</p>
                            </label>
                            <input
                                id="file-input"
                                type="file"
                                accept=".pld"
                                hidden
                                onInput={(event) => openProject(event)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppModeSelection
