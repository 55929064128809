///////////////////////////////////////////////////////
///////////////// HUMANOID ////////////////////////////
///////////////////////////////////////////////////////

const ZingByteCodes = {
    MOVEFORWARD: ['R'.charCodeAt(0), 'u'.charCodeAt(0), 'u'.charCodeAt(0)],
    MOVEBACKWARD: ['R'.charCodeAt(0), 'd'.charCodeAt(0), 'd'.charCodeAt(0)],
    MOVELEFT: ['R'.charCodeAt(0), 'l'.charCodeAt(0), 'l'.charCodeAt(0)],
    MOVERIGHT: ['R'.charCodeAt(0), 'r'.charCodeAt(0), 'r'.charCodeAt(0)],

    FORWARD: ['R'.charCodeAt(0), 'u'.charCodeAt(0), 'u'.charCodeAt(0)],
    FORNT: ['R'.charCodeAt(0), 'u'.charCodeAt(0), 'u'.charCodeAt(0)],
    BACKWARD: ['R'.charCodeAt(0), 'd'.charCodeAt(0), 'd'.charCodeAt(0)],
    BACK: ['R'.charCodeAt(0), 'd'.charCodeAt(0), 'd'.charCodeAt(0)],
    TURNLEFT: ['R'.charCodeAt(0), 'l'.charCodeAt(0), 'l'.charCodeAt(0)],
    SLIDELEFT: ['R'.charCodeAt(0), 'l'.charCodeAt(0), 'l'.charCodeAt(0)],
    TURNRIGHT: ['R'.charCodeAt(0), 'r'.charCodeAt(0), 'r'.charCodeAt(0)],
    SLIDERIGHT: ['R'.charCodeAt(0), 'r'.charCodeAt(0), 'r'.charCodeAt(0)],

    ATTENTION: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    ATTENTIONMID: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    // STOPDANCE: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    // STOPTHEDANCE: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    STOP: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    SIDEKICKLEFT: ['R'.charCodeAt(0), 'c'.charCodeAt(0)],
    SIDEKICKRIGHT: ['R'.charCodeAt(0), 'b'.charCodeAt(0)],
    SALUTE: ['R'.charCodeAt(0), 'f'.charCodeAt(0)],
    DANCE: ['R'.charCodeAt(0), 't'.charCodeAt(0)],
    WAVE: ['R'.charCodeAt(0), 'd'.charCodeAt(0)],
    FIGHT: ['R'.charCodeAt(0), 'e'.charCodeAt(0)],
    PUSHUP: ['R'.charCodeAt(0), 's'.charCodeAt(0)],
    FRONTKICKLEFT: ['R'.charCodeAt(0), 'x'.charCodeAt(0)],
    FRONTKICKRIGHT: ['R'.charCodeAt(0), 'y'.charCodeAt(0)],
    SMARTMODE: ['R'.charCodeAt(0), 'z'.charCodeAt(0)],
    BOW: ['R'.charCodeAt(0), 'g'.charCodeAt(0)],
    SQUAT: ['R'.charCodeAt(0), 'i'.charCodeAt(0)],
}
const HexapodByteCodes = {
    MOVELEFT: ['R'.charCodeAt(0), 'l'.charCodeAt(0), 'l'.charCodeAt(0)],
    MOVERIGHT: ['R'.charCodeAt(0), 'r'.charCodeAt(0), 'r'.charCodeAt(0)],
    TURNLEFT: ['R'.charCodeAt(0), 'l'.charCodeAt(0), 'l'.charCodeAt(0)],
    TURNRIGHT: ['R'.charCodeAt(0), 'r'.charCodeAt(0), 'r'.charCodeAt(0)],
    MOVEFORWARD: ['R'.charCodeAt(0), 'u'.charCodeAt(0), 'u'.charCodeAt(0)],
    MOVEBACKWARD: ['R'.charCodeAt(0), 'd'.charCodeAt(0), 'd'.charCodeAt(0)],
    FORWARD: ['R'.charCodeAt(0), 'u'.charCodeAt(0), 'u'.charCodeAt(0)],
    FRONT: ['R'.charCodeAt(0), 'u'.charCodeAt(0), 'u'.charCodeAt(0)],
    BACKWARD: ['R'.charCodeAt(0), 'd'.charCodeAt(0), 'd'.charCodeAt(0)],
    BACK: ['R'.charCodeAt(0), 'd'.charCodeAt(0), 'd'.charCodeAt(0)],
    // LEFT: ['R'.charCodeAt(0), 'l'.charCodeAt(0), 'l'.charCodeAt(0)],
    // RIGHT: ['R'.charCodeAt(0), 'r'.charCodeAt(0), 'r'.charCodeAt(0)],
    STOPDANCE: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    STOP: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    ATTENTION: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    ATTENTIONMID: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    ATTACK: ['R'.charCodeAt(0), 'g'.charCodeAt(0)],
    SLIDELEFT: ['R'.charCodeAt(0), 'b'.charCodeAt(0)],
    SLIDERIGHT: ['R'.charCodeAt(0), 'c'.charCodeAt(0)],
    TURNAROUND: ['R'.charCodeAt(0), 'i'.charCodeAt(0)],
    DANCE: ['R'.charCodeAt(0), 't'.charCodeAt(0)],
    GREETING: ['R'.charCodeAt(0), 'f'.charCodeAt(0)],
    FIGHT: ['R'.charCodeAt(0), 'e'.charCodeAt(0)],
    DEFENCE: ['R'.charCodeAt(0), 'd'.charCodeAt(0)],
}
const KlawByteCodes = {
    PICK: ['A'.charCodeAt(0), 'C'.charCodeAt(0)],
    PICK_LEFT_DROP_RIGHT: [
        'A'.charCodeAt(0),
        'C'.charCodeAt(0),
        'P'.charCodeAt(0),
        'L'.charCodeAt(0),
    ],
    PICK_RIGHT_DROP_LEFT: [
        'A'.charCodeAt(0),
        'C'.charCodeAt(0),
        'P'.charCodeAt(0),
        'R'.charCodeAt(0),
    ],
    WAVE: [
        'A'.charCodeAt(0),
        'C'.charCodeAt(0),
        'W'.charCodeAt(0),
        'A'.charCodeAt(0),
    ],
    SCRIBBLE: ['A'.charCodeAt(0), 'C'.charCodeAt(0)],
    CIRCLE: ['A'.charCodeAt(0), 'C'.charCodeAt(0)],
}
const HUMANOID_PA_SEND_BYTES = [
    'P'.charCodeAt(0), // 0 - Prefix
    'A'.charCodeAt(0), // 1 - Prefix
    'O'.charCodeAt(0), // 2 - A1 port           values: [O: <Sensor doesn't read: Default>, U: <ultrasonic>, A: <Analog>, I: <Digital>]
    'O'.charCodeAt(0), // 3 - A2 port
    'O'.charCodeAt(0), // 4 - B1 port
    'O'.charCodeAt(0), // 5 - B2 port
    'O'.charCodeAt(0), // 6 - C1 port
    'O'.charCodeAt(0), // 7 - C2 port
    'O'.charCodeAt(0), // 8 - D1 port
    'O'.charCodeAt(0), // 9 - D2 port
    'O'.charCodeAt(0), // 10 - Battery           values: [B: <Battery>, O: <Sensor doesn't read: Default>]
    'O'.charCodeAt(0), // 11 - Accelerometer     values: [A: <Accelerometer>, O: <Sensor doesn't read: Default>]
    'O'.charCodeAt(0), // 12 - Gyro              values: [G: <Gyro>, O: <Sensor doesn't read: Default>]
    'O'.charCodeAt(0), // 13 - Ultrasonic
]

//humanoid PA bytes for zing V1
const HUMANOID_V1_PA_SEND_BYTES = [
    'P'.charCodeAt(0), // 0 - Prefix
    'A'.charCodeAt(0), // 1 - Prefix
    'O'.charCodeAt(0), // 2 - A1 port           values: [O: <Sensor doesn't read: Default>, U: <ultrasonic>, A: <Analog>, I: <Digital>]
    'O'.charCodeAt(0), // 3 - A2 port
    'O'.charCodeAt(0), // 4 - B1 port
    'O'.charCodeAt(0), // 5 - B2 port
    'O'.charCodeAt(0), // 6 - C1 port
    'O'.charCodeAt(0), // 7 - C2 port
    'O'.charCodeAt(0), // 8 - Head RGB
    // 'O'.charCodeAt(0), // 9 - Servo Read
    'O'.charCodeAt(0), //9 - MPU
    'O'.charCodeAt(0), //10 - Battery
    'E'.charCodeAt(0), // 11 - Suffix
    'R'.charCodeAt(0), // 12 - Suffix
]

const HEXAPOD_PA_SEND_BYTES = [
    'P'.charCodeAt(0), // 0 - Prefix
    'A'.charCodeAt(0), // 1 - Prefix
    'O'.charCodeAt(0), // 2 - A1 port           values: [O: <Sensor doesn't read: Default>, U: <ultrasonic>, A: <Analog>, I: <Digital>]
    'O'.charCodeAt(0), // 3 - A2 port
    'O'.charCodeAt(0), // 4 - B1 port
    'O'.charCodeAt(0), // 5 - B2 port
    'O'.charCodeAt(0), // 6 - C1 port
    'O'.charCodeAt(0), // 7 - C2 port
    'O'.charCodeAt(0), // 8 - Head
    'O'.charCodeAt(0), //9 - MPU
    'O'.charCodeAt(0), //10 - Battery
    'E'.charCodeAt(0), // 11 - Suffix
    'R'.charCodeAt(0), // 12 - Suffix
]
const KLAW_PA_SEND_BYTES = [
    'P'.charCodeAt(0), // 0 - Prefix
    'A'.charCodeAt(0), // 1 - Prefix
    'O'.charCodeAt(0), // 2 - A1 port           values: [O: <Sensor doesn't read: Default>, U: <ultrasonic>, A: <Analog>, I: <Digital>]
    'O'.charCodeAt(0), // 3 - A2 port
    'O'.charCodeAt(0), // 4 - B1 port
    'O'.charCodeAt(0), // 5 - B2 port
    'O'.charCodeAt(0), // 6 - C1 port for Pot1
    'O'.charCodeAt(0), // 7 - C2 port for Pot2
    'O'.charCodeAt(0), // 8 - D1 port for Pot3
    'O'.charCodeAt(0), // 9 - D2 port for Pot4
    'O'.charCodeAt(0), // 10 - E1 port for Pot5
    'O'.charCodeAt(0), // 11 - E2 port for Pot6
    // 'O'.charCodeAt(0), // 8 - F2 port
    // 'O'.charCodeAt(0), //9 - F2 port
    // 'O'.charCodeAt(0), //10 - G1 port
    'O'.charCodeAt(0), // 12 - B for bttery
    'E'.charCodeAt(0), // 13 - Suffix
    'R'.charCodeAt(0), // 14 - Suffix
]

// Only for reference [not used anywhere]
// 80,65,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,80,2,2,212,3,213,1,248,1,254,1,2,2,0,0,69,78,68
const HUMANOID_PA_RECEIVE_BYTES = [
    80, // 0 - Prefix - P
    65, // 1 - Prefix - A
    0, // 2 - A1 - MSB
    0, // 3 - A1 - LSB
    0, // 4 - A2 - MSB
    0, // 5 - A2 - LSB
    0, // 6 - B1 - MSB
    0, // 7 - B1 - LSB
    0, // 8 - B2 - MSB
    0, // 9 - B2 - LSB
    0, // 10 - C1 - MSB
    0, // 11 - C1 - LSB
    0, // 12 - C2 - MSB
    0, // 13 - C2 - LSB
    0, // 14 - D1 - MSB
    0, // 15 - D1 - LSB
    0, // 16 - D2 - MSB
    0, // 17 - D2 - LSB
    0, // 18 - Battery [Decimal]
    0, // 19 - AccelerometerX - MSB
    0, // 20 - AccelerometerX - LSB
    0, // 21 - AccelerometerY - MSB
    0, // 22 - AccelerometerY - LSB
    0, // 23 - AccelerometerZ - MSB
    0, // 24 - AccelerometerZ - LSB
    0, // 25 - GyroX - MSB
    0, // 26 - GyroX - LSB
    0, // 27 - GyroY - MSB
    0, // 28 - GyroY - LSB
    0, // 29 - GyroZ - MSB
    0, // 30 - GyroZ - LSB
    0, // 31 - Ultrasonic - MSB
    0, // 32 - Ultrasonic - LSB
    0, // 33 - Suffix - E
    0, // 34 - Suffix - N
    0, // 35 - Suffix - D
]

///////////////////////////////////////////////////////
////////////////////// ACE ////////////////////////////
///////////////////////////////////////////////////////

const AceByteCodes = {
    LIGHTOFF: ['S'.charCodeAt(0), '0'.charCodeAt(0)],
    WHITELIGHT: ['S'.charCodeAt(0), '1'.charCodeAt(0)],
    REDLIGHT: ['S'.charCodeAt(0), '2'.charCodeAt(0)],
    GREENLIGHT: ['S'.charCodeAt(0), '3'.charCodeAt(0)],
    BLUELIGHT: ['S'.charCodeAt(0), '4'.charCodeAt(0)],
    DISCO: ['S'.charCodeAt(0), '5'.charCodeAt(0)],
    SMILE: ['S'.charCodeAt(0), '6'.charCodeAt(0)],
}

const PCVersionCode = ['W'.charCodeAt(0), 'H'.charCodeAt(0), 'O'.charCodeAt(0)]

const PCClearProgram = [
    'P'.charCodeAt(0),
    'L'.charCodeAt(0),
    'A'.charCodeAt(0),
    'Y'.charCodeAt(0),
]

const PCClearPin = ['M'.charCodeAt(0), '8'.charCodeAt(0)]

const PC_PA_SEND_BYTES = [
    'P'.charCodeAt(0), // 0 - Prefix
    'A'.charCodeAt(0), // 1 - Prefix
    'O'.charCodeAt(0), // 2 - A1 port           values: [A: <Analog>, I: <Digital>, U: <>]
    'O'.charCodeAt(0), // 3 - A2 port           values: [A: <Analog>, I: <Digital>]
    'O'.charCodeAt(0), // 4 - B1 port
    'O'.charCodeAt(0), // 5 - B2 port
    'O'.charCodeAt(0), // 6 - C1 port           values: [A: <Analog>, I: <Digital>, U: <>]
    'O'.charCodeAt(0), // 7 - C2 port
    'O'.charCodeAt(0), // 8 - D1 port
    'O'.charCodeAt(0), // 9 - D2 port
    'O'.charCodeAt(0), // 10 - E1 port
    'O'.charCodeAt(0), // 11 - E2 port
    'O'.charCodeAt(0), // 12 - F1 port
    'O'.charCodeAt(0), // 13 - F2 port
    'O'.charCodeAt(0), // 14 - Mic               values: [M: <Mic>, O: <Sensor doesn't read: Default>]
    'O'.charCodeAt(0), // 15 - Temperature       values: [T: <Temperature>]
    'O'.charCodeAt(0), // 16 - Touch Pad 0       values: [T: <TouchPad>]
    'O'.charCodeAt(0), // 17 - Touch Pad 1       values: [T: <TouchPad>]
    'O'.charCodeAt(0), // 18 - Touch Pad 2       values: [T: <TouchPad>]
    'O'.charCodeAt(0), // 19 - Touch Pad 3       values: [T: <TouchPad>]
    'O'.charCodeAt(0), // 20 - 4 in 1 sensor     values: [C: <Color>, G: <Gesture>, D: <Distance>, L : <Light>]
    'O'.charCodeAt(0), // 21 - Accelerometer     values: [A: <Accelerometer>]
    'O'.charCodeAt(0), // 22 - Battery           values: [B: <Battery>]
    'E'.charCodeAt(0), // 23 - Suffix
    'R'.charCodeAt(0), // 24 - Suffix
]

// Only for reference [not used anywhere]
const PC_PA_RECEIVE_BYTES = [
    0, // 0 - A1 - MSB
    0, // 1 - A1 - LSB
    0, // 2 - A2 - MSB
    0, // 3 - A2 - LSB
    0, // 4 - B1 - MSB
    0, // 5 - B1 - LSB
    0, // 6 - B2 - MSB
    0, // 7 - B2 - LSB
    0, // 8 - C1 - MSB
    0, // 9 - C1 - LSB
    0, // 10 - C2 - MSB
    0, // 11 - C2 - LSB
    0, // 12 - D1 - MSB
    0, // 13 - D1 - LSB
    0, // 14 - D2 - MSB
    0, // 15 - D2 - LSB
    0, // 16 - E1 - MSB
    0, // 17 - E1 - LSB
    0, // 18 - E2 - MSB
    0, // 19 - E2 - LSB
    0, // 20 - F1 - MSB
    0, // 21 - F1 - LSB
    0, // 22 - F2 - MSB
    0, // 23 - F2 - LSB

    0, // 24 - Mic - MSB
    0, // 25 - Mic - LSB
    0, // 26 - Temperature - MSB
    0, // 27 - Temperature - LSB
    0, // 28 - Touch Pad 0 - MSB
    0, // 29 - Touch Pad 0 - LSB
    0, // 30 - Touch Pad 1 - MSB
    0, // 31 - Touch Pad 1 - LSB
    0, // 32 - Touch Pad 2 - MSB
    0, // 33 - Touch Pad 2 - LSB
    0, // 34 - Touch Pad 3 - MSB
    0, // 35 - Touch Pad 3 - LSB
    0, // 36 - 4 in 1 sensor - Red / Light
    0, // 37 - 4 in 1 sensor - Green / Distance
    0, // 38 - 4 in 1 sensor - Blue / Gesture
    0, // 39 - AccelerometerX - MSB
    0, // 40 - AccelerometerX - LSB
    0, // 41 - AccelerometerY - LSB
    0, // 42 - AccelerometerY - MSB
    0, // 43 - AccelerometerZ - LSB
    0, // 44 - AccelerometerZ - LSB
    0, // 45 - Battery
]

export {
    ZingByteCodes,
    HexapodByteCodes,
    HUMANOID_PA_SEND_BYTES,
    HUMANOID_V1_PA_SEND_BYTES,
    HEXAPOD_PA_SEND_BYTES,
    AceByteCodes,
    PCVersionCode,
    PCClearProgram,
    PCClearPin,
    PC_PA_SEND_BYTES,
    KlawByteCodes,
    KLAW_PA_SEND_BYTES,
}
