export const retrieveAssemblyData = [
    'connectedDevice',
    'deviceVersion',
    'shield',
    'armShield',
    'dragingItem',
    'AppDetails-new',
    'recoveryData',
    'IMGEURL',
    'assempblyImageHTML',
    'assempblyImageURI',
    'assemblyCheckbox',
    'Index',
    'SelectedComp',
    'coverflowActive',
    'Types',
    'Types3',
    'Types5',
    'editorCode',
    'pythonCode',
    'CurrentLogicScreen',
    'programEnd',
    'EndSwitch',
    'PET',
    'currentActiveHexBlock',
    'SelectedStatus',
    'rangeValCheckbox',
    'HumanoidActiveBtn',
    'logic',
    'assembly',
    'concept',
    'dualsplitter',
    'humanoidPortData',
    'humanoidProgramData',
]

export const retrieveFlowDataExcept = [
    'connectedDevice',
    'deviceVersion',
    'shield',
    'armShield',
    'dragingItem',
    'AppDetails-new',
    'recoveryData',
    'IMGEURL',
    'assempblyImageHTML',
    'assempblyImageURI',
    'assemblyCheckbox',
    'Index',
    'SelectedComp',
    'coverflowActive',
    'Types',
    'Types3',
    'Types5',
    'editorCode',
    'pythonCode',
    'CurrentLogicScreen',
    'programEnd',
    'EndSwitch',
    'PET',
    'currentActiveHexBlock',
    'SelectedStatus',
    'rangeValCheckbox',
    'HumanoidActiveBtn',
    'logic',
    'assembly',
    'concept',
    'dualsplitter',
]
/*  

 graphPlotStorage
nodeCounter
 isReadingZingPA
flow-logic
 AppDetails

*/
