import React from 'react'
import OnlyNumberInput from './OnlyNumberInput'

import OnlySlider from './OnlySlider'

function LoopSliderBox({ min, max, value, onChange, style }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDireciton: 'row',
                padding: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
                fontFamily: 'Halcyon_SemiBold',
                fontSize: '1.25rem',
                color: 'black',
                position: 'relative',
                top: '40%',
                left: '48%',
                transform: 'translate(-50%,-50%)',
                margin: '0',
            }}
        >
            <span style={{ paddingTop: '10px' }}>Repeat</span>
            <OnlySlider min={min} max={max} value={value} onChange={onChange} />
            <OnlyNumberInput
                min={min}
                max={max}
                value={value}
                onChange={onChange}
            />

            <span>times</span>
        </div>
    )
}

export default LoopSliderBox
