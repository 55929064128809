export var HumanoidAction = function (actionName) {
    switch (actionName) {
        case 'Attention': {
            return 1
        }
        case 'Forward': {
            return 2
        }
        case 'Backward': {
            return 3
        }
        case 'Mourn': {
            return 4
        }
        case 'Left': {
            return 5
        }
        case 'Right': {
            return 6
        }
        case 'Wave': {
            return 7
        }
        case 'Bow': {
            return 8
        }
        case 'Wings': {
            return 9
        }
        case 'Hook_Left': {
            return 10
        }
        case 'Hook_Right': {
            return 11
        }
        case 'Right_Curved_Hook': {
            return 12
        }
        case 'Left_Curved_Hook': {
            return 13
        }
        case 'Push_up': {
            return 14
        }
        case 'Sit_up': {
            return 15
        }
        case 'Squat': {
            return 16
        }
        case 'Laugh': {
            return 17
        }
        case 'Box_Forward': {
            return 18
        }
        case 'Box_Squat': {
            return 19
        }
        case 'Box_Left': {
            return 20
        }
        case 'Box_Right': {
            return 21
        }
        case 'Break_Dance': {
            return 22
        }
        case 'Gangnam_style': {
            return 23
        }
    }
}

export var HumanoidActiveBtn = {
    isCheckRadioAction: 'false',
}
