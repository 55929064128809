import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import {
    sendBytes,
    ZingByteCodes,
    AceByteCodes,
} from '../../../ReusableComponents/ByteTransfer'
import speechToCommand from '../speechToCommand'
import renderImage from '../../../../source/importImg'
import SpchSlider from '../../../ReusableComponents/SpchSlider/SpchSlider'
import MainHeader from '../../../ReusableComponents/Header/MainHeader'
import './speak.scss'
import { HexapodByteCodes } from '../../../ReusableComponents/ByteTransfer/byteCodes'
import unicodeToChar from '../../../../utils/unicodeToChar'

const feedbackStyle = {
    height: 'auto',
    fontSize: '19px',
    fontFamily: 'Halcyon_Medium !important',
    color: '#707070',
    bottom: '6%',
}

let recognition = undefined

const bytesCode = {
    ACE: AceByteCodes,
    HUMANOID: ZingByteCodes,
    HEXAPOD: HexapodByteCodes,
}
const DeviceSpeech = ({ webSerial, worker }) => {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDeviceName =
        connectedDevice === 'Ace'
            ? 'Play Computer'
            : connectedDevice === 'Hexapod'
            ? 'Crawl-e'
            : 'Humanoid'
    const [isListening, setIsListening] = useState(false)
    const [feedback, setFeedback] = useState(
        `Tap the microphone to start talking to your ${connectedDeviceName}`
    )
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    const checkDone = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!JSON.parse(sessionStorage.getItem('sendDataAsPacket'))) {
                    return resolve(true)
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    // Initializing speech recognition
    useEffect(() => {
        try {
            var SpeechRecognition =
                window.SpeechRecognition || window.webkitSpeechRecognition

            recognition = new SpeechRecognition()
            recognition.continuous = true
        } catch (e) {
            console.log(e)
        }
    }, [])

    // SpeechRecognition event Listeners
    useEffect(() => {
        recognition.addEventListener('start', handleRecognitionOnStart)
        recognition.addEventListener('end', handleRecognitionOnEnd)
        recognition.addEventListener('result', handleRecognitionOnResult)

        recognition.onerror = (event) => {
            // console.log(event.error + "error");
            setFeedback(event.error + ' error')
            setIsListening(false)
        }

        return () => {
            recognition.removeEventListener('start', handleRecognitionOnStart)
            recognition.removeEventListener('end', handleRecognitionOnEnd)
            recognition.removeEventListener('result', handleRecognitionOnResult)
        }
    }, [webSerial])

    async function writePort(data) {
        worker.postMessage({
            type: 'writeArray',
            value: data,
        })
        console.log('PlayBytes Sent')
    }
    const readBytes = async () => {
        if (!webSerial.port) return
        console.log('read', await webSerial.port.readable.locked)
        while (webSerial.port.readable) {
            const reader = webSerial.port.readable.getReader()
            let combinedResult = ''
            try {
                while (true) {
                    let result
                    console.log('waiting for reply...')
                    try {
                        result = await Promise.race([
                            reader.read(),
                            timeoutPromise(12000),
                        ])
                    } catch (e) {
                        reader.releaseLock()

                        console.log('While loop broken')
                        return
                    }
                    const reply = unicodeToChar(result.value).trim()
                    combinedResult = combinedResult + reply
                    if (
                        combinedResult.includes('Rdone') ||
                        combinedResult.includes('RDONE')
                    ) {
                        return combinedResult
                    }
                }
            } catch (err) {
                console.log(err)
            } finally {
                reader.releaseLock()
            }
        }
    }

    useEffect(() => {
        const handleBytesSending = async () => {
            if (webSerial.isConnected) {
                const PLAY = [
                    'P'.charCodeAt(),
                    'L'.charCodeAt(),
                    'A'.charCodeAt(),
                    'Y'.charCodeAt(),
                ]
                const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
                const Attention = ['R'.charCodeAt(), 'a'.charCodeAt()]
                if (
                    connectedDevice == 'Ace' &&
                    sessionStorage.getItem('deviceVersion')?.startsWith('1')
                )
                    return
                writePort(PLAY)

                if (connectedDevice == 'Hexapod') {
                    setTimeout(async () => {
                        writePort(Attention)
                    }, 200)
                    // setTimeout(async () => {
                    //     await readBytes()
                    // }, 200)
                }
                setTimeout(() => {
                    //     writePort(M8)
                    // }, 200)
                })
            }
        }
        handleBytesSending()
    }, [])
    // Handler functions
    const handleRecognitionOnStart = () => {
        console.log('Speech recognition service : STARTED')
        setFeedback('Listening......')
        setIsListening(true)
    }

    const handleRecognitionOnEnd = () => {
        console.log('Speech recognition service : STOPPED ')
        setFeedback('Tap the microphone to start talking to your Play Computer')
        setIsListening(false)
    }

    const handleRecognitionOnResult = async (e) => {
        console.log('hiiii')
        let speechInput = e.results[e.resultIndex][0].transcript
        console.log('Input from mic: ' + speechInput)
        const commands = speechToCommand(speechInput, connectedDevice)
        console.log('corresponding command: ' + commands)

        if (commands.length !== 0) {
            commands.map(async (command) => {
                worker.postMessage({
                    type: 'writeArray',
                    value: bytesCode[connectedDevice.toUpperCase()][command],
                })
                sessionStorage.setItem('sendDataAsPacket', 'true')
                // if (['Humanoid', 'Hexapod'].includes(connectedDevice)) {
                //     setTimeout(() => {
                //         worker.postMessage({
                //             type: 'writeArray',
                //             value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                //         })
                //     }, 1500)
                // }
                try {
                    let replyOK = await Promise.race([
                        checkDone(),
                        timeoutPromise(12000),
                    ])
                    if (command !== 'STOP') {
                        worker.postMessage({
                            type: 'writeArray',
                            value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                        })
                    }
                } catch {
                    sessionStorage.setItem('sendDataAsPacket', 'true')
                    return
                }
            })
            setFeedback(`${commands} accepted by ${connectedDeviceName}`)
        } else {
            setFeedback(
                "I didn't quite catch that, click on the mic to try again."
            )
        }
    }

    // Listening Toggle
    const handleListenToggle = () => {
        if (isListening) {
            recognition.stop()
            setFeedback(
                'Tap the microphone and start talking to your Play Computer'
            )
        } else {
            recognition.start()
        }
    }

    let history = useHistory()

    //recived the data for zing v2
    useEffect(() => {
        const messageHandler = (e) => {
            if (e.data.type === 'read') {
                let combinedResult = e.data.value
                if (combinedResult.includes('RDONE')) {
                    sessionStorage.setItem('sendDataAsPacket', 'false')
                }
            }
        }
        worker.addEventListener('message', messageHandler)

        // return worker.removeEventListener('message', messageHandler)
    }, [])

    return (
        <div className="Speech-Main">
            <MainHeader
                title="Speech"
                helper={<SpchSlider />}
                showBluetoothBtn={true}
                goBack={() => {
                    sessionStorage.setItem('slideDirection', true)
                    history.push('/introduction')
                    worker.postMessage({
                        type: 'writeArray',
                        value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                    })
                }}
            />

            <div className="Ace_Speech">
                <div>
                    {connectedDevice === 'Ace' && (
                        <img className="Ace_img" src={renderImage('PC')}></img>
                    )}
                    {connectedDevice === 'Humanoid' && (
                        <img
                            className="Ace_img"
                            src={renderImage('Zing')}
                        ></img>
                    )}
                    {connectedDevice === 'Hexapod' && (
                        <img
                            className="Hexa_img"
                            src={renderImage('Hexapod')}
                        ></img>
                    )}
                </div>
                <div>
                    {!isListening ? (
                        <img
                            className="Mic_imgIA"
                            src={renderImage('SpeechIA_Svg')}
                            // onClick={handleMic}
                            onClick={handleListenToggle}
                        ></img>
                    ) : (
                        <img
                            className="Mic_img"
                            src={renderImage('SpeechAc_Svg')}
                            // onClick={handleMic}
                            onClick={handleListenToggle}
                        ></img>
                    )}
                </div>
            </div>
            <div className="SoundWave">
                {isListening && (
                    <img
                        className="Sound_Wave"
                        src={renderImage('Soundwaves_Svg')}
                    ></img>
                )}
                {/* <img className="Sound_Wave" src={Soundwaves_Svg}></img> */}
            </div>
            <div>
                <h3 className="text-center " style={feedbackStyle}>
                    {feedback}
                </h3>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(DeviceSpeech)
