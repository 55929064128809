var PortConnections
export default PortConnections = {
    A: null,
    B: null,
    C: null,
    D: null,
    E: null,
    F: null,
    G: null,
    H: null,
    I: null,
    A1: null,
    A2: null,
    B1: null,
    B2: null,
    C1: null,
    C2: null,
    D1: null,
    D2: null,
    E1: null,
    E2: null,
    F1: null,
    F2: null,
    G1: null,
    G2: null,
    H1: null,
    H2: null,
    I1: null,
    I2: null,
    servo_motor1: null,
    servo_motor2: null,
    MOTOR1: null,
    M1: null,
    M2: null,
    M3: null,
    M4: null,
    P0: null,
    P1: null,
    P2: null,
    S1: null,
    S2: null,
    SS: null,
    SPK: null,

    //for klaw port
    serial_servo1: null,
    serial_servo2: null,
    PW1: null,
    PW2: null,
    PW3: null,
    PW4: null,
    PW5: null,
    PW6: null,

    AB: null,
    FG: null,
    ECHI: null,

    STPM: null,

    FOUR_in_ONE_Sensor: null,
    MICROPHONE: null,

    TEMPERATURE: null,
    // New UI Data
    TouchZero: null,
    TouchOne: null,
    TouchTwo: null,
}
