import React, { useLayoutEffect, useState, useEffect, useRef } from 'react'
import Bottom from '../Bottom'
import { Nav } from 'react-bootstrap'
import { useLocalStorage } from '../../LocalStorage/LocalStorage'
import pcImg from '../../../Assets/internalAccessories/PC_image@3x.png'
import selectImg from '../../../Assets/img/select bar@2x.png'
import secondaryImg from '../../../Assets/img/save - secondary.png'
import strokeImg from '../../../Assets/img/button 52x52 - stroke.png'
import connectionImg from '../../../Assets/usb - off@2x.png'
import { Link, useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { webSerialAction } from '../../../redux/actions'

import '../pcimage.css'
import '../Navbar.css'
import '../style.css'
import renderPrgImage from '../../../source/programImg'

import selectBgimg from '../../../Assets/img/selectbgimg.png'
import closeBtn from '../../../Assets/img/close.png'
import renderImage from '../../../source/importImg'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import selectStyle from '../../Humanoid/HumanoidSelectFeatures.module.css' //'./HumanoidSelectFeatures.module.css'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    zIndex: '1000',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

let i = []
let a1color = 'black',
    a2color = 'black'
let bttnColor = []
let bttType = [
    'A1',
    'A2',
    'B1',
    'B2',
    'C1',
    'C2',
    'D1',
    'D2',
    'E1',
    'E2',
    'F1',
    'F2',
    'M1',
    'M2',
    'M3',
    'M4',
]
for (let i = 0; i < 16; i++) {
    bttnColor[i] = 'black'
    if (JSON.parse(sessionStorage.getItem(bttType[i]))) bttnColor[i] = 'white'
}

if (JSON.parse(sessionStorage.getItem('A1'))) a1color = 'white'
if (JSON.parse(sessionStorage.getItem('A2'))) a2color = 'white'

for (let j = 0; j < 8; j++) i[j] = 1
let pa1 = false
const Port = (props) => {
    const history = useHistory()
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const [isAnimating, setIsAnimating] = useState(false)
    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')
    const humanoidPortData = JSON.parse(
        sessionStorage.getItem('humanoidPortData')
    )

    useLayoutEffect(() => {
        return () => {
            console.log(
                'GSK',
                JSON.parse(sessionStorage.getItem('A1')),
                typeof JSON.parse(sessionStorage.getItem('A1'))
            )
            // if(JSON.parse(sessionStorage.getItem("A1")))
            //   a1color="white"
            // else
            //   a1color="black"
            // if(JSON.parse(sessionStorage.getItem("A2")))
            //   a2color="white"
            // else
            //   a2color="black"
            for (let i = 0; i < 16; i++) {
                if (JSON.parse(sessionStorage.getItem(bttType[i])))
                    bttnColor[i] = 'white'
                else bttnColor[i] = 'black'
            }

            //  myFunction1();
        }
    })

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const next = () => {
        saveDataToLocalStorage()

        history.push('/flow/input-output')
    }
    const backBtnAction = () => {
        sessionStorage.setItem('slideDirection', true)
        saveDataToLocalStorage()
        history.push('/flow/InternalAccessories')
    }
    const [a1, setA1] = useLocalStorage('A1', false)
    const [a2, setA2] = useLocalStorage('A2', false)
    const [a, setA] = useLocalStorage('A', false)
    const [b1, setB1] = useLocalStorage('B1', false)
    const [b2, setB2] = useLocalStorage('B2', false)
    const [b, setB] = useLocalStorage('B', false)
    const [c1, setC1] = useLocalStorage('C1', false)
    const [c2, setC2] = useLocalStorage('C2', false)
    const [c, setC] = useLocalStorage('C', false)
    const [d1, setD1] = useLocalStorage('D1', false)
    const [d2, setD2] = useLocalStorage('D2', false)
    const [d, setD] = useLocalStorage('D', false)
    const [e1, setE1] = useLocalStorage('E1', false)
    const [e2, setE2] = useLocalStorage('E2', false)
    const [e, setE] = useLocalStorage('E', false)
    const [f1, setF1] = useLocalStorage('F1', false)
    const [f2, setF2] = useLocalStorage('F2', false)
    const [f, setF] = useLocalStorage('F', false)
    const [m1, setM1] = useLocalStorage('M1', false)
    const [m2, setM2] = useLocalStorage('M2', false)
    const [m, setM] = useLocalStorage('M', false)
    const [m3, setM3] = useLocalStorage('M3', false)
    const [m4, setM4] = useLocalStorage('M4', false)
    const [n, setN] = useLocalStorage('N', false)
    const [aUltra, setAUltra] = useLocalStorage(
        'AUltra',
        JSON.parse(sessionStorage.getItem('AUltra'))
    )
    const [bRGB, setBRGB] = useLocalStorage(
        'BRGB',
        JSON.parse(sessionStorage.getItem('BRGB'))
    )
    const [bMP3, setBMP3] = useLocalStorage(
        'BMP3',
        JSON.parse(sessionStorage.getItem('BMP3'))
    )
    const [dOLED, setDOLED] = useLocalStorage(
        'DOLED',
        JSON.parse(sessionStorage.getItem('DOLED'))
    )

    const [cUltra, setCUltra] = useLocalStorage(
        'CUltra',
        JSON.parse(sessionStorage.getItem('CUltra'))
    )
    const [dUltra, setDUltra] = useLocalStorage(
        'DUltra',
        JSON.parse(sessionStorage.getItem('DUltra'))
    )
    const [a1Servo, setA1Servo] = useLocalStorage(
        'A1Servo',
        JSON.parse(sessionStorage.getItem('A1Servo'))
    )
    const [a1Servo360, setA1Servo360] = useLocalStorage(
        'A1Servo360',
        JSON.parse(sessionStorage.getItem('A1Servo360'))
    )
    const [a2Servo, setA2Servo] = useLocalStorage(
        'A2Servo',
        JSON.parse(sessionStorage.getItem('A2Servo'))
    )
    const [a2Servo360, setA2Servo360] = useLocalStorage(
        'A2Servo360',
        JSON.parse(sessionStorage.getItem('A2Servo360'))
    )
    const [b1Servo, setB1Servo] = useLocalStorage(
        'B1Servo',
        JSON.parse(sessionStorage.getItem('B1Servo'))
    )
    const [b1Servo360, setB1Servo360] = useLocalStorage(
        'B1Servo360',
        JSON.parse(sessionStorage.getItem('B1Servo360'))
    )
    const [b2Servo, setB2Servo] = useLocalStorage(
        'B2Servo',
        JSON.parse(sessionStorage.getItem('B2Servo'))
    )
    const [b2Servo360, setB2Servo360] = useLocalStorage(
        'B2Servo360',
        JSON.parse(sessionStorage.getItem('B2Servo360'))
    )
    const [c1Servo, setC1Servo] = useLocalStorage(
        'C1Servo',
        JSON.parse(sessionStorage.getItem('C1Servo'))
    )
    const [c1Servo360, setC1Servo360] = useLocalStorage(
        'C1Servo360',
        JSON.parse(sessionStorage.getItem('C1Servo360'))
    )
    const [c2Servo, setC2Servo] = useLocalStorage(
        'C2Servo',
        JSON.parse(sessionStorage.getItem('C2Servo'))
    )
    const [c2Servo360, setC2Servo360] = useLocalStorage(
        'C2Servo360',
        JSON.parse(sessionStorage.getItem('C2Servo360'))
    )
    const [d1Servo, setD1Servo] = useLocalStorage(
        'D1Servo',
        JSON.parse(sessionStorage.getItem('D1Servo'))
    )
    const [d1Servo360, setD1Servo360] = useLocalStorage(
        'D1Servo360',
        JSON.parse(sessionStorage.getItem('D1Servo360'))
    )
    const [d2Servo, setD2Servo] = useLocalStorage(
        'D2Servo',
        JSON.parse(sessionStorage.getItem('D2Servo'))
    )
    const [d2Servo360, setD2Servo360] = useLocalStorage(
        'D2Servo360',
        JSON.parse(sessionStorage.getItem('D2Servo360'))
    )

    const onA1ValueChange = async () => {
        if (
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) == false &&
            JSON.parse(sessionStorage.getItem('isTouchZero')) == false
        ) {
            setA1(!a1)
            if (a1 === false && a2 === true) {
                setA(true)
            } else {
                setA(false)
            }
        }
        if (
            JSON.parse(sessionStorage.getItem('isTouchZero')) == null ||
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) == null
        ) {
            setA1(!a1)
            if (a1 === false && a2 === true) {
                setA(true)
            } else {
                setA(false)
            }
        }
    }

    const onA2ValueChange = () => {
        setA2(!a2)
        if (a1 === true && a2 === false) {
            setA(true)
        } else {
            setA(false)
        }
    }

    const onB1ValueChange = () => {
        if (
            JSON.parse(sessionStorage.getItem('isTouchOne')) == false &&
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) == false
        ) {
            setB1(!b1)
            if (b1 === false && b2 === true) {
                setB(true)
            } else {
                setB(false)
            }
        }
        if (
            JSON.parse(sessionStorage.getItem('isTouchOne')) == null ||
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) == null
        ) {
            setB1(!b1)
            if (b1 === false && b2 === true) {
                setB(true)
            } else {
                setB(false)
            }
        }
    }
    const onB2ValueChange = () => {
        setB2(!b2)
        if (b1 === true && b2 === false) {
            setB(true)
        } else {
            setB(false)
        }
    }

    const onC1ValueChange = () => {
        console.log('byeee')
        if (
            JSON.parse(sessionStorage.getItem('isTouchTwo')) == false &&
            JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) == false
        ) {
            setC1(!c1)
            if (c1 === false && c2 === true) {
                setC(true)
            } else {
                setC(false)
            }
        } else if (
            JSON.parse(sessionStorage.getItem('isTouchTwo')) == null ||
            JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) == null
        ) {
            setC1(!c1)
            if (c1 === false && c2 === true) {
                setC(true)
            } else {
                setC(false)
            }
        }
    }
    const onC2ValueChange = () => {
        setC2(!c2)
        if (c1 === true && c2 === false) {
            setC(true)
        } else {
            setC(false)
        }
    }

    const onD1ValueChange = () => {
        if (
            JSON.parse(sessionStorage.getItem('isDistanceSensors')) == false &&
            JSON.parse(sessionStorage.getItem('isGestureSensor')) == false &&
            JSON.parse(sessionStorage.getItem('isLightSensor')) == false &&
            JSON.parse(sessionStorage.getItem('isColorSensor')) == false
        ) {
            setD1(!d1)
            if (d1 === false && d2 === true) {
                setD(true)
            } else {
                setD(false)
            }
        } else if (
            JSON.parse(sessionStorage.getItem('isDistanceSensors')) == null ||
            JSON.parse(sessionStorage.getItem('isGestureSensor')) == null ||
            JSON.parse(sessionStorage.getItem('isLightSensor')) == null ||
            JSON.parse(sessionStorage.getItem('isColorSensor')) == null
        ) {
            setD1(!d1)
            if (d1 === false && d2 === true) {
                setD(true)
            } else {
                setD(false)
            }
        }
    }
    const onD2ValueChange = () => {
        setD2(!d2)
        if (d1 === true && d2 === false) {
            setD(true)
        } else {
            setD(false)
        }
    }

    function findIndex(array, string) {
        var index = []
        for (var i = 0; i < array.length; i++) {
            if (
                array[i].indexOf(string) > -1 &&
                array[i].indexOf('rgb1') == -1 &&
                array[i].indexOf('rgb2') == -1 &&
                array[i].indexOf(`${string}-I/O`) == -1
            ) {
                index.push(i)
            }
        }
        return index
    }
    function findIndex_new(array, string) {
        var index = []
        for (var i = 0; i < array.length; i++) {
            if (
                array[i].indexOf(string) > -1 &&
                array[i].indexOf('countRGB') == -1
            ) {
                index.push(i)
            }
        }
        return index
    }
    const myFunction1 = async () => {
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (
            JSON.parse(sessionStorage.getItem('isTouchZero')) ||
            JSON.parse(sessionStorage.getItem('isTouchZeroOutput')) == true
        ) {
            setToastMsg('Disable Touch Pad 0 To Enable A1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('A1'))
        if (await JSON.parse(sessionStorage.getItem('A1'))) {
            document.getElementById('foo1').style.cssText = 'color: white; '
            humanoidPortData[31].isPortSelected = true
        } else {
            document.getElementById('foo1').style.cssText = 'color: black; '
            //  a1color="black"
            setAUltra(false)
            setA1Servo(false)
            setA1Servo360(false)
            humanoidPortData[31].isPortSelected = false
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )

        let a = findIndex(Object.keys(sessionStorage), 'a1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction2 = async () => {
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        console.log('A!!!GSK', await JSON.parse(sessionStorage.getItem('A1')))

        if (await JSON.parse(sessionStorage.getItem('A2'))) {
            document.getElementById('foo2').style.cssText = 'color: white; '
            humanoidPortData[32].isPortSelected = true
        } else {
            document.getElementById('foo2').style.cssText = 'color: black; '
            humanoidPortData[32].isPortSelected = false
            setAUltra(false)
            setA2Servo(false)
            if (
                deviceVersion[0] === '0' &&
                ['Humanoid'].includes(connectedDevice)
            )
                setA2Servo360(false)
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )

        let a = findIndex(Object.keys(sessionStorage), 'a2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction3 = async () => {
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (
            JSON.parse(sessionStorage.getItem('isTouchOne')) ||
            JSON.parse(sessionStorage.getItem('isTouchOneOutput')) == true
        ) {
            setToastMsg('Disable Touch Pad 1 To Enable B1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('B1'))
        if (await JSON.parse(sessionStorage.getItem('B1'))) {
            document.getElementById('foo3').style.cssText = 'color: white; '
            humanoidPortData[33].isPortSelected = true
        } else {
            document.getElementById('foo3').style.cssText = 'color: black; '
            humanoidPortData[33].isPortSelected = false
            // setAUltra(false)
            setBRGB(false)
            setBMP3(false)
            setB1Servo(false)
            setB1Servo360(false)
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
        let a = findIndex(Object.keys(sessionStorage), 'b1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        let b = [
            ...findIndex_new(Object.keys(sessionStorage), 'mp3'),
            ...findIndex_new(Object.keys(sessionStorage), 'rgb'),
            ...findIndex_new(Object.keys(sessionStorage), 'RGB'),
        ]
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction4 = async () => {
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        await JSON.parse(sessionStorage.getItem('B2'))
        if (await JSON.parse(sessionStorage.getItem('B2'))) {
            document.getElementById('foo4').style.cssText = 'color: white; '
            humanoidPortData[34].isPortSelected = true
        } else {
            document.getElementById('foo4').style.cssText = 'color: black; '
            humanoidPortData[34].isPortSelected = false
            setBRGB(false)
            setB2Servo(false)
            setB2Servo360(false)
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
        let a = findIndex(Object.keys(sessionStorage), 'b2')
        let b = [
            ...findIndex_new(Object.keys(sessionStorage), 'mp3'),
            ...findIndex_new(Object.keys(sessionStorage), 'rgb'),
            ...findIndex_new(Object.keys(sessionStorage), 'RGB'),
        ]
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction5 = async () => {
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        console.log('hiii')
        if (
            JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
            JSON.parse(sessionStorage.getItem('isTouchTwoOutput')) == true
        ) {
            setToastMsg('Disable Touch Pad 2 To Enable C1')
            setToastActive(true)
            disableToast()
        }

        await JSON.parse(sessionStorage.getItem('C1'))
        if (await JSON.parse(sessionStorage.getItem('C1'))) {
            document.getElementById('foo5').style.cssText = 'color: white; '
            humanoidPortData[35].isPortSelected = true
        } else {
            document.getElementById('foo5').style.cssText = 'color: black; '
            humanoidPortData[35].isPortSelected = false
            if (
                !(
                    deviceVersion[0] === '0' &&
                    ['Humanoid'].includes(connectedDevice)
                )
            )
                setCUltra(false)
            setC1Servo(false)
            setC1Servo360(false)
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
        let a = findIndex(Object.keys(sessionStorage), 'c1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction6 = async () => {
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        await JSON.parse(sessionStorage.getItem('C2'))
        if (await JSON.parse(sessionStorage.getItem('C2'))) {
            document.getElementById('foo6').style.cssText = 'color: white; '
            humanoidPortData[36].isPortSelected = true
        } else {
            document.getElementById('foo6').style.cssText = 'color: black; '
            humanoidPortData[36].isPortSelected = false
            if (
                deviceVersion[0] === '0' &&
                ['Humanoid'].includes(connectedDevice)
            ) {
                setC2Servo(false)
                setC2Servo360(false)
            } else {
                setCUltra(false)
            }
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
        let a = findIndex(Object.keys(sessionStorage), 'c2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
    }
    const myFunction7 = async () => {
        if (JSON.parse(sessionStorage.getItem('isDistanceSensors')) == true) {
            setToastMsg('Disable DistanceSensors To Enable D1')
            setToastActive(true)
            disableToast()
        } else if (
            JSON.parse(sessionStorage.getItem('isGestureSensor')) == true
        ) {
            setToastMsg('Disable GestureSensor To Enable D1')
            setToastActive(true)
            disableToast()
        } else if (
            JSON.parse(sessionStorage.getItem('isLightSensor')) == true
        ) {
            setToastMsg('Disable LightSensor To Enable D1')
            setToastActive(true)
            disableToast()
        } else if (
            JSON.parse(sessionStorage.getItem('isColorSensor')) == true
        ) {
            setToastMsg('Disable ColorSensor To Enable D1')
            setToastActive(true)
            disableToast()
        }
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )

        await JSON.parse(sessionStorage.getItem('D1'))
        if (await JSON.parse(sessionStorage.getItem('D1'))) {
            document.getElementById('foo7').style.cssText = 'color: white; '
            humanoidPortData[37].isPortSelected = true
        } else {
            document.getElementById('foo7').style.cssText = 'color: black; '
            humanoidPortData[37].isPortSelected = false
            if (
                deviceVersion[0] === '0' &&
                ['Humanoid'].includes(connectedDevice)
            ) {
                setD1Servo(false)
                setDOLED(false)
                setD1Servo360(false)
                setDUltra(false)
            }
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
        let a = findIndex(Object.keys(sessionStorage), 'd1')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        let b = findIndex_new(Object.keys(sessionStorage), 'oled')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], '')
        }
        b = findIndex_new(Object.keys(sessionStorage), 'oledChk')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }
    const myFunction8 = async () => {
        console.log('JJJJJJJJJJJJJJJJJ')
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )

        await JSON.parse(sessionStorage.getItem('D2'))
        if (await JSON.parse(sessionStorage.getItem('D2'))) {
            document.getElementById('foo8').style.cssText = 'color: white; '
            humanoidPortData[38].isPortSelected = true
        } else {
            document.getElementById('foo8').style.cssText = 'color: black; '
            humanoidPortData[38].isPortSelected = false
            if (
                deviceVersion[0] === '0' &&
                ['Humanoid'].includes(connectedDevice)
            ) {
                setD2Servo(false)
                setDOLED(false)
                setD2Servo360(false)
                setDUltra(false)
            }
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
        let a = findIndex(Object.keys(sessionStorage), 'd2')
        console.log('gsk', a)
        for (let i in a) {
            sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
        }
        let b = findIndex_new(Object.keys(sessionStorage), 'oled')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], '')
        }
        b = findIndex_new(Object.keys(sessionStorage), 'oledChk')
        for (let i in b) {
            sessionStorage.setItem(Object.keys(sessionStorage)[b[i]], 0)
        }
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    const [ishelpButton, sethelpButton] = useState(false)
    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    return (
        <>
            <ProgramHeader
                data={{
                    options: [
                        'Select Ports',
                        'Input/Output',
                        'Digital/Analog',
                        'Flowchart',
                    ],
                    selected: 0,
                }}
                showSave={true}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />
            {/* <div className="HeaderContainer">
				<div
					className="flowchart-navbarContainer navbarContainer"
					style={{ zIndex: "1000" }}
				>
					<div className="flowchart-navbar_content navbar_content">
						<div className="flowchart-navbar_new navbar_new isActive" href="/">
							Select Ports
						</div>
						<div
							className="flowchart-navbar_new navbar_new"
							href="/input-output"
							eventKey="link-1"
						>
							Input/Output
						</div>
						<div
							className="flowchart-navbar_new navbar_new "
							href="/digital-analog"
							eventKey="link-2"
						>
							Digital/Analog
						</div>
						<div
							className="flowchart-navbar_new navbar_new"
							href="/flowchart"
							eventKey="link-3"
						>
							Flowchart
						</div>
					</div>
					<img
						src={selectImg}
						style={{
							height: "100%",
							width: "17%",
							position: "relative",
							right: "43vw",
						}}
					/>
					<div className="flowchart-navbar-Action navbar-Action">
						<img
							className="flowchart-iconBtnSize iconBtnSize"
							style={{ width: "61px", height: "61px", marginRight: "10px" }}
							src={secondaryImg}
						></img>
						<img
							className="flowchart-iconBtnSize iconBtnSize"
							style={{ width: "61px", height: "61px", marginRight: "10px" }}
							src={strokeImg}
							onClick={handleClickhelpbtn}
						></img>

						{isUsb ? (
							<img src={renderPrgImage("usbON")} onClick={HdleUsb} />
						) : (
							<img src={renderPrgImage("usbOFF")} onClick={HdleUsb} />
						)}
					</div>
				</div>
			</div> */}

            {/* Toast msg */}
            <div
                className={`toast-msg 
        ${isToastActive ? 'toast-msg-active' : 'toast-msg-inactive'}`}
                ref={toastRef}
            >
                {toastMsg}
            </div>
            <div className="MainContainer">
                {/* <div className="CenterImgs">
                    <img
                        src={renderImage('Zing')}
                        style={{
                            position: 'absolute',
                            left: '80%',
                            top: '-6vh',
                            transform: 'translate(-50%,-50%)',
                        }}
                    />
                    <img
                        // className={selectStyle['select-buzzer']}
                        style={{
                            position: 'absolute',
                            left: '80%',
                            top: '-29vh',
                            transform: 'translate(-50%,-50%)',
                            width: '80%',
                        }}
                        src={
                            humanoidPortData[2].isPortSelected
                                ? renderImage('activeBuzzer')
                                : renderImage('inactiveBuzzer')
                        }
                    />
                </div> */}
                <div
                    className={selectStyle['select-humanoid']}
                    style={{
                        backgroundImage: `url(${renderImage('humanoid')})`,
                        top: '26%',
                        left: '15%',
                        backgroundSize: '100% 80%',
                    }}
                >
                    {/* <img className={selectStyle["select-buzzer"]} src={isBuzzer.img} /> */}
                    <img
                        className={selectStyle['select-leftEye']}
                        src={
                            humanoidPortData[0].isPortSelected
                                ? renderImage('activeEye')
                                : renderImage('inactiveEye')
                        }
                        style={{ top: '15.8%', left: '46%' }}
                    />
                    <img
                        className={selectStyle['select-rightEye']}
                        src={
                            humanoidPortData[1].isPortSelected
                                ? renderImage('activeEye')
                                : renderImage('inactiveEye')
                        }
                        style={{ top: '15.8%', left: '54.1%' }}
                    />
                    <img
                        className={selectStyle['select-buzzer']}
                        src={
                            humanoidPortData[2].isPortSelected
                                ? renderImage('activeBuzzer')
                                : renderImage('inactiveBuzzer')
                        }
                        style={{ top: '17.3%', width: '100%' }}
                    />
                </div>

                <div
                    className={`page2-content ${
                        isAnimating ? 'slide-out2' : ''
                    }`}
                    style={{ height: '100vh', width: '100vw' }}
                >
                    {' '}
                    <div className="ports-Container">
                        <div
                            className="ButtonDiv"
                            style={{ marginTop: '150px' }}
                        >
                            {/* A PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            'InputLabel-' +
                                            (JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchZero'
                                                )
                                            ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchZeroOutput'
                                                    )
                                                ))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchZero'
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchZeroOutput'
                                                        )
                                                    )
                                            )}`}
                                            type="checkbox"
                                            checked={a1}
                                            onClick={() => myFunction1()}
                                            onChange={() => onA1ValueChange()}
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isTouchZero")) ||
                                            //   JSON.parse(sessionStorage.getItem("isTouchZeroOutput"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo1"
                                            style={{ color: bttnColor[0] }}
                                        >
                                            A1
                                        </span>
                                    </span>
                                    <span className="InputLabel">
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={a2}
                                            onClick={() => myFunction2()}
                                            onChange={() => onA2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo2"
                                            style={{ color: bttnColor[1] }}
                                        >
                                            A2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* B PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            (JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchOne'
                                                )
                                            ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchOneOutput'
                                                    )
                                                ))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchOne'
                                                ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'isTouchOneOutput'
                                                        )
                                                    )
                                            )}`}
                                            type="checkbox"
                                            checked={b1}
                                            onClick={() => myFunction3()}
                                            onChange={() => onB1ValueChange()}
                                            disabled={
                                                ['1', '2'].includes(
                                                    deviceVersion[0]
                                                ) &&
                                                ['Humanoid'].includes(
                                                    connectedDevice
                                                )
                                                    ? true
                                                    : false
                                            }
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isTouchOne")) ||
                                            //   JSON.parse(sessionStorage.getItem("isTouchOneOutput"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo3"
                                            style={{ color: bttnColor[2] }}
                                        >
                                            B1
                                        </span>
                                    </span>
                                    <span className="InputLabel">
                                        <input
                                            disabled={
                                                ['1', '2'].includes(
                                                    deviceVersion[0]
                                                ) &&
                                                ['Humanoid'].includes(
                                                    connectedDevice
                                                )
                                                    ? true
                                                    : false
                                            }
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={b2}
                                            onClick={() => myFunction4()}
                                            onChange={() => onB2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo4"
                                            style={{ color: bttnColor[3] }}
                                        >
                                            B2
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            className="ButtonRightDiv"
                            style={{ marginTop: '150px' }}
                        >
                            {/* C PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel ' +
                                            ' InputLabel-' +
                                            (JSON.parse(
                                                sessionStorage.getItem(
                                                    'isTouchTwo'
                                                )
                                            ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwoOutput'
                                                    )
                                                ))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwo'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isTouchTwoOutput'
                                                    )
                                                )
                                            }`}
                                            type="checkbox"
                                            checked={c1}
                                            onClick={() => myFunction5()}
                                            onChange={() => onC1ValueChange()}
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isTouchTwo")) ||
                                            //   JSON.parse(sessionStorage.getItem("isTouchTwoOutput"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo5"
                                            style={{ color: bttnColor[4] }}
                                        >
                                            C1
                                        </span>
                                    </span>
                                    <span className="InputLabel">
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={c2}
                                            onClick={() => myFunction6()}
                                            onChange={() => onC2ValueChange()}
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo6"
                                            style={{ color: bttnColor[5] }}
                                        >
                                            C2
                                        </span>
                                    </span>
                                </div>
                            </div>
                            {/* D PORT */}
                            <div className="tlb">
                                <div className="tlbIn ">
                                    <span
                                        className={
                                            'InputLabel' +
                                            ' InputLabel-' +
                                            (JSON.parse(
                                                sessionStorage.getItem(
                                                    'isDistanceSensors'
                                                )
                                            ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isGestureSensor'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isLightSensor'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isColorSensor'
                                                    )
                                                ))
                                        }
                                    >
                                        <input
                                            className={`InputCheckBox  disabled_-${
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isDistanceSensors'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isGestureSensor'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isLightSensor'
                                                    )
                                                ) ||
                                                JSON.parse(
                                                    sessionStorage.getItem(
                                                        'isColorSensor'
                                                    )
                                                )
                                            }`}
                                            type="checkbox"
                                            checked={d1}
                                            onClick={() => myFunction7()}
                                            onChange={() => onD1ValueChange()}
                                            disabled={
                                                (['1', '2'].includes(
                                                    deviceVersion[0]
                                                ) &&
                                                    ['Humanoid'].includes(
                                                        connectedDevice
                                                    )) ||
                                                connectedDevice == 'Hexapod'
                                            }
                                            // disabled={
                                            //   JSON.parse(sessionStorage.getItem("isDistanceSensors")) ||
                                            //   JSON.parse(sessionStorage.getItem("isGestureSensor")) ||
                                            //   JSON.parse(sessionStorage.getItem("isLightSensor")) ||
                                            //   JSON.parse(sessionStorage.getItem("isColorSensor"))
                                            // }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo7"
                                            style={{ color: bttnColor[6] }}
                                        >
                                            D1
                                        </span>
                                    </span>
                                    <span className="InputLabel">
                                        <input
                                            className="InputCheckBox"
                                            type="checkbox"
                                            checked={d2}
                                            onClick={() => myFunction8()}
                                            onChange={() => onD2ValueChange()}
                                            disabled={
                                                (['1', '2'].includes(
                                                    deviceVersion[0]
                                                ) &&
                                                    ['Humanoid'].includes(
                                                        connectedDevice
                                                    )) ||
                                                connectedDevice == 'Hexapod'
                                            }
                                        />
                                        <span
                                            disabled="disabled"
                                            className="A1"
                                            id="foo8"
                                            style={{ color: bttnColor[7] }}
                                        >
                                            D2
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="SelectScreenBottom">
                <div className="bottom-child">
                    {/* <Link to="/programSelection"> */}
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    {/* </Link> */}
                    <div
                        className="decription"
                        style={{
                            fontFamily: 'Halcyon_SemiBold',
                            zIndex: '999',
                            left: '50%',
                        }}
                    >
                        <p>Select the Ports</p>
                    </div>

                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('nextBtn')}
                        onClick={next}
                    />
                </div>
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '1000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        {/* First screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('selectPortH')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    height: '6%',
                                    width: '5%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '41.5%',
                                    left: '40%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '2px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                    }}
                                >
                                    All the accessible ports are available as a
                                    scrollable. To enable a port, click on the
                                    port name buttons
                                </p>
                            </div>
                        </div>
                        {/* second screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('selectPortH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    height: '6%',
                                    width: '5%',
                                    // zIndex: "2000",
                                    position: 'absolute',
                                    top: '40%',
                                    left: '56.5%',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                    background: '#fff',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '2px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular ',
                                        borderRadius: '12px',
                                    }}
                                >
                                    You can enable all the ports or the ones you
                                    need. Note: Only those ports enable here
                                    will be available in the next set of screens
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </>
    )
}

// export default Port;

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialAction: (data) => {
            dispatch(webSerialAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Port)
