import React, { Component } from 'react'
import { connect } from 'react-redux'
import { webSerialConnect } from '../../../../../../redux/actions'
import renderPrgImage from '../../../../../../source/programImg'

import Select from '../helpers/Select'
import Slider from '../helpers/Slider'
import { gestureValueToText } from '../../../../../../utils'
import unicodeToChar from '../../../../../../utils/unicodeToChar'
import {
    sendBytes,
    continousPABytesReadToggle_v1,
    readBytesPa,
} from '../../../../../ReusableComponents/ByteTransfer'
import { playComputerPABytesToSensorData } from '../../../../../ReusableComponents/ByteTransfer/utils'

import './condition.css'
import DecisionPanel from '../../../../../Humanoid/DecisionPanel'

let a1Checked = JSON.parse(sessionStorage.getItem('a1-I/O'))
let a1Digi = JSON.parse(sessionStorage.getItem('A1DIGI'))
let a2Checked = JSON.parse(sessionStorage.getItem('a2-I/O'))
let a2Digi = JSON.parse(sessionStorage.getItem('A2DIGI'))
let b1Checked = JSON.parse(sessionStorage.getItem('b1-I/O'))
let b1Digi = JSON.parse(sessionStorage.getItem('B1DIGI'))
let b2Checked = JSON.parse(sessionStorage.getItem('b2-I/O'))
let b2Digi = JSON.parse(sessionStorage.getItem('B2DIGI'))
let c1Checked = JSON.parse(sessionStorage.getItem('c1-I/O'))
let c1Digi = JSON.parse(sessionStorage.getItem('C1DIGI'))
let c2Checked = JSON.parse(sessionStorage.getItem('c2-I/O'))
let c2Digi = JSON.parse(sessionStorage.getItem('C2DIGI'))
let d1Checked = JSON.parse(sessionStorage.getItem('D1'))
let d1Digi = JSON.parse(sessionStorage.getItem('D1DIGI'))
let d2Checked = JSON.parse(sessionStorage.getItem('D2'))
let d2Digi = JSON.parse(sessionStorage.getItem('D2DIGI'))
let e1Checked = JSON.parse(sessionStorage.getItem('e1-I/O'))
let e1Digi = JSON.parse(sessionStorage.getItem('E1DIGI'))
let e2Checked = JSON.parse(sessionStorage.getItem('e2-I/O'))
let e2Digi = JSON.parse(sessionStorage.getItem('E2DIGI'))
let f1Checked = JSON.parse(sessionStorage.getItem('f1-I/O'))
let f1Digi = JSON.parse(sessionStorage.getItem('F1DIGI'))
let f2Checked = JSON.parse(sessionStorage.getItem('f2-I/O'))
let f2Digi = JSON.parse(sessionStorage.getItem('F2DIGI'))
let m1Checked = JSON.parse(sessionStorage.getItem('m1-I/O'))
let m1Digi = JSON.parse(sessionStorage.getItem('M1DIGI'))
let m2Checked = JSON.parse(sessionStorage.getItem('m2-I/O'))
let m2Digi = JSON.parse(sessionStorage.getItem('M2DIGI'))
let m3Checked = JSON.parse(sessionStorage.getItem('m3-I/O'))
let m3Digi = JSON.parse(sessionStorage.getItem('M3DIGI'))
let m4Checked = JSON.parse(sessionStorage.getItem('m4-I/O'))
let m4Digi = JSON.parse(sessionStorage.getItem('M4DIGI'))
let count = []
let count1 = []
let isG = []
let isL = []
let isNe = []
let isE = []
let isIb = []
let selected = [],
    selectedTwo = []

var reader

for (let i = 0; i < 1000; i++)
    selected[i] = sessionStorage.getItem(`ifSelect${i}`) || 'null'
class Condition extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recentGesture: 0,
            state: false,
            isGraterThan: Boolean(
                sessionStorage.getItem(`gt${this.props.check}`) == 'true'
            ),
            isLessThan: Boolean(
                sessionStorage.getItem(`lt${this.props.check}`) == 'true'
            ),
            isNotequalTo: Boolean(
                sessionStorage.getItem(`ne${this.props.check}`) == 'true'
            ),
            isEqualTo: Boolean(
                sessionStorage.getItem(`eq${this.props.check}`) == 'true'
            ),
            isInBtween: Boolean(
                sessionStorage.getItem(`bw${this.props.check}`) == 'true'
            ),
            isRead: false,
            isRead_v1: false,
            Bytes: false,
            responceTp0: '',
            responceTp1: '',
            responceTp2: '',
            touch_pad: '',
            touch_pad2: '',
            rangeA1: '',
            rangeA2: '',
            rangeB1: '',
            rangeB2: '',
            rangeC1: '',
            rangeC2: '',
            rangeD1: '',
            rangeD2: '',
            rangeE1: '',
            rangeE2: '',
            rangeF1: '',
            rangeF2: '',
            tactswitch: '',
            battery: '',
            mic: '',
            temp: '',
            gas: '',
            one: '',
            two: '',
            red: '',
            green: '',
            blue: '',
            light: '',
            gesture: '',
            distance: '',
            accelerometer_X: '',
            accelerometer_Y: '',
            accelerometer_Z: '',
            gyro_X: '',
            gyro_Y: '',
            gyro_Z: '',
            readToggel: false,
            value: parseInt(
                sessionStorage.getItem(`ifValue${this.props.check}`)
            ),
            value1: parseInt(
                sessionStorage.getItem(`ifValue2${this.props.check}`)
            ),
            min: 0,
            max: 1,
            selected:
                sessionStorage.getItem(`ifSelect${this.props.check}`) || 'null',
            selectedTwo: selectedTwo[this.props.check],
            // selectedDropDownList:""
        }
    }
    setPABytes_V1() {
        let sendBytes = Array(25).fill('0'.charCodeAt())

        sendBytes[0] = 'P'.charCodeAt()
        sendBytes[1] = 'A'.charCodeAt()
        sendBytes[23] = 'E'.charCodeAt()
        sendBytes[24] = 'R'.charCodeAt()

        let A1 = JSON.parse(sessionStorage.getItem('A1'))
        let A2 = JSON.parse(sessionStorage.getItem('A2'))

        let A1DIGI = JSON.parse(sessionStorage.getItem('A1DIGI'))
        let A2DIGI = JSON.parse(sessionStorage.getItem('A2DIGI'))

        let AUltra = JSON.parse(sessionStorage.getItem('AUltra'))

        let B1 = JSON.parse(sessionStorage.getItem('B1'))
        let B2 = JSON.parse(sessionStorage.getItem('B2'))

        let B1DIGI = JSON.parse(sessionStorage.getItem('B1DIGI'))
        let B2DIGI = JSON.parse(sessionStorage.getItem('B2DIGI'))

        let BRGB = JSON.parse(sessionStorage.getItem('BRGB'))
        let BMP3 = JSON.parse(sessionStorage.getItem('BMP3'))

        let C1 = JSON.parse(sessionStorage.getItem('C1'))
        let C2 = JSON.parse(sessionStorage.getItem('C2'))

        let C1DIGI = JSON.parse(sessionStorage.getItem('C1DIGI'))
        let C2DIGI = JSON.parse(sessionStorage.getItem('C2DIGI'))

        let CUltra = JSON.parse(sessionStorage.getItem('CUltra'))

        let D1 = JSON.parse(sessionStorage.getItem('D1'))
        let D2 = JSON.parse(sessionStorage.getItem('D2'))

        let D1DIGI = JSON.parse(sessionStorage.getItem('D1DIGI'))
        let D2DIGI = JSON.parse(sessionStorage.getItem('D2DIGI'))

        let E1 = JSON.parse(sessionStorage.getItem('E1'))
        let E2 = JSON.parse(sessionStorage.getItem('E2'))

        let E1DIGI = JSON.parse(sessionStorage.getItem('E1DIGI'))
        let E2DIGI = JSON.parse(sessionStorage.getItem('E2DIGI'))

        let F1 = JSON.parse(sessionStorage.getItem('F1'))
        let F2 = JSON.parse(sessionStorage.getItem('F2'))

        let F1DIGI = JSON.parse(sessionStorage.getItem('F1DIGI'))
        let F2DIGI = JSON.parse(sessionStorage.getItem('F2DIGI'))

        if (A1 === true) {
            if (A1DIGI === true) {
                sendBytes[2] = 'A'.charCodeAt()
            } else {
                sendBytes[2] = 'I'.charCodeAt()
            }
        }
        if (A2 === true) {
            if (A2DIGI === true) {
                sendBytes[3] = 'A'.charCodeAt()
            } else {
                sendBytes[3] = 'I'.charCodeAt()
            }
        }
        if (A1 == true && AUltra == true) {
            sendBytes[2] = 'U'.charCodeAt()
            sendBytes[3] = 'O'.charCodeAt()
        }

        if (B1 === true) {
            if (B1DIGI === true) {
                sendBytes[4] = 'A'.charCodeAt()
            } else {
                sendBytes[4] = 'I'.charCodeAt()
            }
        }
        if (B2 === true) {
            if (B2DIGI === true) {
                sendBytes[5] = 'A'.charCodeAt()
            } else {
                sendBytes[5] = 'I'.charCodeAt()
            }
        }
        if (B1 == true && B2 == true && BMP3 != true && BRGB != true) {
            sendBytes[4] = 'I'.charCodeAt()
            sendBytes[5] = 'I'.charCodeAt()
        }
        if (B1DIGI == true && B2DIGI == true && BMP3 != true && BRGB != true) {
            sendBytes[4] = 'A'.charCodeAt()
            sendBytes[5] = 'A'.charCodeAt()
        }

        if (C1 === true) {
            if (C1DIGI === true) {
                sendBytes[6] = 'A'.charCodeAt()
            } else {
                sendBytes[6] = 'I'.charCodeAt()
            }
        }
        if (C2 === true) {
            if (C2DIGI === true) {
                sendBytes[7] = 'A'.charCodeAt()
            } else {
                sendBytes[7] = 'I'.charCodeAt()
            }
        }
        if (C1 == true && CUltra == true) {
            sendBytes[6] = 'U'.charCodeAt()
            sendBytes[7] = 'O'.charCodeAt()
        }

        if (D1 === true) {
            if (D1DIGI === true) {
                sendBytes[8] = 'A'.charCodeAt()
            } else {
                sendBytes[8] = 'I'.charCodeAt()
            }
        }
        if (D2 === true) {
            if (D2DIGI === true) {
                sendBytes[9] = 'A'.charCodeAt()
            } else {
                sendBytes[9] = 'I'.charCodeAt()
            }
        }

        if (E1 === true) {
            if (E1DIGI === true) {
                sendBytes[10] = 'A'.charCodeAt()
            } else {
                sendBytes[10] = 'I'.charCodeAt()
            }
        }
        if (E2 === true) {
            if (E2DIGI === true) {
                sendBytes[11] = 'A'.charCodeAt()
            } else {
                sendBytes[11] = 'I'.charCodeAt()
            }
        }

        if (F1 === true) {
            if (F1DIGI === true) {
                sendBytes[12] = 'A'.charCodeAt()
            } else {
                sendBytes[12] = 'I'.charCodeAt()
            }
        }
        if (F2 === true) {
            if (F2DIGI === true) {
                sendBytes[13] = 'A'.charCodeAt()
            } else {
                sendBytes[13] = 'I'.charCodeAt()
            }
        }

        if (JSON.parse(sessionStorage.getItem('isMic'))) {
            sendBytes[14] = 'M'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTemperature'))) {
            sendBytes[15] = 'T'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTouchZero'))) {
            sendBytes[16] = 'T'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTouchOne'))) {
            sendBytes[17] = 'T'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTouchTwo'))) {
            sendBytes[18] = 'T'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTouchThree'))) {
            sendBytes[19] = 'T'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isDistanceSensors'))) {
            sendBytes[20] = 'D'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isGestureSensor'))) {
            sendBytes[20] = 'G'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isColorSensor'))) {
            sendBytes[20] = 'C'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isLightSensor'))) {
            sendBytes[20] = 'L'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isAccelerometer'))) {
            sendBytes[21] = 'A'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isBattery'))) {
            sendBytes[22] = 'B'.charCodeAt()
        }
        var bytes = unicodeToChar(sendBytes)
        console.log(bytes, 'bytes')
        return sendBytes
    }
    getBytesToSend() {
        let bytesData = Array(11).fill('O'.charCodeAt())

        bytesData.unshift('A'.charCodeAt())
        bytesData.unshift('P'.charCodeAt())

        let A1 = JSON.parse(sessionStorage.getItem('A1'))
        let A2 = JSON.parse(sessionStorage.getItem('A2'))

        let A1DIGI = JSON.parse(sessionStorage.getItem('A1DIGI'))
        let A2DIGI = JSON.parse(sessionStorage.getItem('A2DIGI'))

        let AUltra = JSON.parse(sessionStorage.getItem('AUltra'))

        let B1 = JSON.parse(sessionStorage.getItem('B1'))
        let B2 = JSON.parse(sessionStorage.getItem('B2'))

        let B1DIGI = JSON.parse(sessionStorage.getItem('B1DIGI'))
        let B2DIGI = JSON.parse(sessionStorage.getItem('B2DIGI'))

        let BRGB = JSON.parse(sessionStorage.getItem('BRGB'))
        let BMP3 = JSON.parse(sessionStorage.getItem('BMP3'))

        let C1 = JSON.parse(sessionStorage.getItem('C1'))
        let C2 = JSON.parse(sessionStorage.getItem('C2'))

        let C1DIGI = JSON.parse(sessionStorage.getItem('C1DIGI'))
        let C2DIGI = JSON.parse(sessionStorage.getItem('C2DIGI'))

        let CUltra = JSON.parse(sessionStorage.getItem('CUltra'))

        let F1 = JSON.parse(sessionStorage.getItem('F1'))
        let F2 = JSON.parse(sessionStorage.getItem('F2'))

        if (JSON.parse(sessionStorage.getItem('A'))) {
            console.log('A1:', A1)
            console.log('A2:', A2)
            console.log('A1DIGI:', A1DIGI)
            console.log('A2DIGI:', A2DIGI)
            if (A1 == true && A2 == true) {
                bytesData[2] = 'I'.charCodeAt()
                bytesData[3] = 'I'.charCodeAt()
            }

            if (A1DIGI == true && A2DIGI == true) {
                bytesData[2] = 'A'.charCodeAt()
                bytesData[3] = 'A'.charCodeAt()
            }
        }
        if (A1 == true) {
            bytesData[2] = 'I'.charCodeAt()
            // bytesData[3] = "I".charCodeAt();
        }
        if (A1 == true && A1DIGI == true) {
            bytesData[2] = 'A'.charCodeAt()
        }

        if (A2 == true) {
            bytesData[3] = 'I'.charCodeAt()
            // bytesData[3] = "I".charCodeAt();
        }
        if (A2 == true && A2DIGI == true) {
            bytesData[3] = 'A'.charCodeAt()
        }
        if (A1 == true && AUltra == true) {
            bytesData[2] = 'U'.charCodeAt()
            bytesData[3] = 'O'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('B'))) {
            if (B1 == true && B2 == true && BMP3 != true && BRGB != true) {
                bytesData[4] = 'I'.charCodeAt()
                bytesData[5] = 'I'.charCodeAt()
            }

            if (
                B1DIGI == true &&
                B2DIGI == true &&
                BMP3 != true &&
                BRGB != true
            ) {
                bytesData[4] = 'A'.charCodeAt()
                bytesData[5] = 'A'.charCodeAt()
            }
        }

        if (B1 == true) {
            bytesData[4] = 'I'.charCodeAt()
            // bytesData[3] = "I".charCodeAt();
        }
        if (B1 == true && B1DIGI == true) {
            bytesData[4] = 'A'.charCodeAt()
        }

        if (B2 == true) {
            bytesData[5] = 'I'.charCodeAt()
            // bytesData[3] = "I".charCodeAt();
        }
        if (B2 == true && B2DIGI == true) {
            bytesData[5] = 'A'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('C'))) {
            if (C1 == true && C2 == true) {
                bytesData[6] = 'I'.charCodeAt()
                bytesData[7] = 'I'.charCodeAt()
            }

            if (C1DIGI == true && C2DIGI == true) {
                bytesData[6] = 'A'.charCodeAt()
                bytesData[7] = 'A'.charCodeAt()
            }
        }

        if (C1 == true) {
            bytesData[6] = 'I'.charCodeAt()
            // bytesData[3] = "I".charCodeAt();
        }
        if (C1 == true && C1DIGI == true) {
            bytesData[6] = 'A'.charCodeAt()
        }

        if (C2 == true) {
            bytesData[7] = 'I'.charCodeAt()
            // bytesData[3] = "I".charCodeAt();
        }
        if (C2 == true && C2DIGI == true) {
            bytesData[7] = 'A'.charCodeAt()
        }
        if (C1 == true && CUltra == true) {
            bytesData[6] = 'U'.charCodeAt()
            bytesData[7] = 'O'.charCodeAt()
        }
        if (F1 == true) {
            bytesData[8] = 'I'.charCodeAt()
        }
        if (F2 == true) {
            bytesData[9] = 'I'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isMic'))) {
            bytesData[11] = 'M'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTemperature'))) {
            bytesData[12] = 'T'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isTouchZero'))) {
            bytesData[2] = 'T'.charCodeAt()
            // bytesData[3] = "T".charCodeAt();
        }
        if (JSON.parse(sessionStorage.getItem('isTouchOne'))) {
            bytesData[4] = 'T'.charCodeAt()
            // bytesData[5] = "T".charCodeAt();
        }
        if (JSON.parse(sessionStorage.getItem('isTouchTwo'))) {
            bytesData[6] = 'T'.charCodeAt()
        }

        if (JSON.parse(sessionStorage.getItem('isDistanceSensors'))) {
            bytesData[10] = 'D'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isGestureSensor'))) {
            bytesData[10] = 'G'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isColorSensor'))) {
            bytesData[10] = 'C'.charCodeAt()
        }
        if (JSON.parse(sessionStorage.getItem('isLightSensor'))) {
            // bytesData[8] = "L".charCodeAt();
            bytesData[10] = 'L'.charCodeAt()
        }

        return bytesData
    }
    MSBLSBToDecimal = (MSBInBinary, LSBInBinary) => {
        return parseInt(
            parseInt(MSBInBinary).toString(2).padStart(8, 0) +
                parseInt(LSBInBinary).toString(2).padStart(8, 0),
            2
        )
    }
    setPABytes_v01 = async (data) => {
        if (data === undefined) return
        data = data.filter((e) => e != 80 && e != 65)
        console.log(this.MSBLSBToDecimal(data[1], data[0]), 'gsk read')
        this.setState({
            zero: this.MSBLSBToDecimal(data[1], data[0]),
            one: this.MSBLSBToDecimal(data[5], data[4]),
            two: this.MSBLSBToDecimal(data[9], data[8]),
            rangeA1: this.MSBLSBToDecimal(data[1], data[0]),
            rangeA2: this.MSBLSBToDecimal(data[3], data[2]),
            rangeB1: this.MSBLSBToDecimal(data[5], data[4]),
            rangeB2: this.MSBLSBToDecimal(data[7], data[6]),
            rangeC1: this.MSBLSBToDecimal(data[9], data[8]),
            rangeC2: this.MSBLSBToDecimal(data[11], data[10]),
            rangeF1: data[12],
            rangeF2: data[13],
            red: data[14],
            green: data[15],
            blue: data[16],
            light: this.MSBLSBToDecimal(data[15], data[14]),
            distance: data[15],
            gesture: data[16],
            mic: this.MSBLSBToDecimal(data[18], data[17]),
            temprature: this.MSBLSBToDecimal(data[20], data[19]),
        })
    }
    handleReadByte = async () => {
        // console.log('gsk sending', this.getBytesToSend())
        if (!this.props.webSerial.isConnected) {
            await this.setState({ isRead: false })
        }
        await this.setState({ isRead: !this.state.isRead })
        sessionStorage.setItem('isRead', this.state.isRead)
        let flag = true
        while (true) {
            if (
                !JSON.parse(sessionStorage.getItem('isRead')) &&
                this.props.webSerial.isConnected
            ) {
                sessionStorage.removeItem('isRead')
                setTimeout(() => {
                    sendBytes(
                        ['M'.charCodeAt(0), '8'.charCodeAt(0)],
                        this.props.webSerial.port
                    )
                }, 500)
                this.setState({ isRead_v1: false })
                return
            }
            if (
                !JSON.parse(sessionStorage.getItem('isRead')) ||
                !this.props.webSerial.isConnected
            ) {
                this.setPABytes_v01([
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0,
                ])

                return
            }
            if (flag) {
                // await sendBytes(
                //     ['M'.charCodeAt(0), '8'.charCodeAt(0)],
                //     this.props.webSerial.port
                // )
                flag = false
                await new Promise((resolve) => setTimeout(resolve, 200))
            } else {
                await sendBytes(
                    this.getBytesToSend(),
                    this.props.webSerial.port
                )
                let read = await readBytesPa(
                    this.props.webSerial.port,
                    !this.state.isRead
                )
                setTimeout(() => this.setPABytes_v01(read), 320)
            }
        }
    }

    handleReadByte_v1 = async () => {
        this.props.worker.onmessage = (e) => {
            if (e.data.type == 'PAread') {
                this.recievedBytes_v1(e.data.value)
            }
        }
        const M8 = new Uint8Array(['M'.charCodeAt(0), '8'.charCodeAt(0)])
        this.setState({ isRead_v1: !this.state.isRead_v1 })
        sessionStorage.setItem('isRead', !this.state.isRead_v1)
        if (!this.state.isRead_v1 === false) {
            return
        }
        while (true) {
            if (
                !JSON.parse(sessionStorage.getItem('isRead')) ||
                !this.props.webSerial.isConnected
            ) {
                this.props.worker.postMessage({
                    type: 'write',
                    value: 'M8',
                })
                ////////// this line of code is to stop reading when close button is clicked and READ button will appear ///////
                sessionStorage.removeItem('isRead')
                return
            } else {
                this.props.worker.postMessage({
                    type: 'writeArray',
                    value: this.setPABytes_V1(),
                })
                await new Promise((resolve) => setTimeout(resolve, 200))
            }
        }
    }

    recievedBytes_v1 = (bytesRecieved) => {
        console.log(bytesRecieved, 'recieved Bytes')

        this.setState({
            rangeA1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 2),
        })

        this.setState({
            rangeA2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 3),
        })

        this.setState({
            rangeB1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 4),
        })

        this.setState({
            rangeB2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 5),
        })

        this.setState({
            rangeC1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 6),
        })

        this.setState({
            rangeC2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 7),
        })

        this.setState({
            rangeD1: playComputerPABytesToSensorData(bytesRecieved.slice(2), 8),
        })

        this.setState({
            rangeD2: playComputerPABytesToSensorData(bytesRecieved.slice(2), 9),
        })

        this.setState({
            rangeE1: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                10
            ),
        })

        this.setState({
            rangeE2: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                11
            ),
        })

        this.setState({
            rangeF1: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                12
            ),
        })

        this.setState({
            rangeF2: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                13
            ),
        })

        this.setState({
            mic: playComputerPABytesToSensorData(bytesRecieved.slice(2), 14),
        })

        this.setState({
            temprature: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                15
            ),
        })

        this.setState({
            zero: playComputerPABytesToSensorData(bytesRecieved.slice(2), 16),
        })

        this.setState({
            one: playComputerPABytesToSensorData(bytesRecieved.slice(2), 17),
        })

        this.setState({
            temp: playComputerPABytesToSensorData(bytesRecieved.slice(2), 18),
        })

        this.setState({
            two: playComputerPABytesToSensorData(bytesRecieved.slice(2), 19),
        })

        this.setState({
            light: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20L'
            ),
        })

        this.setState({
            distance: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20D'
            ),
        })

        this.setState({
            gesture: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20G'
            ),
        })

        this.setState({
            red: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[0],
        })

        this.setState({
            green: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[1],
        })

        this.setState({
            blue: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                '20C'
            )[2],
        })

        this.setState({
            accelerometer_X:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21X'
                    )
                ) - 32767,
        })

        this.setState({
            accelerometer_Y:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21Y'
                    )
                ) - 32767,
        })

        this.setState({
            accelerometer_Z:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21Z'
                    )
                ) - 32767,
        })

        this.setState({
            gyro_X:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21GX'
                    )
                ) - 2500,
        })

        this.setState({
            gyro_Y:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21GY'
                    )
                ) - 2500,
        })

        this.setState({
            gyro_Z:
                Number(
                    playComputerPABytesToSensorData(
                        bytesRecieved.slice(2),
                        '21GZ'
                    )
                ) - 2500,
        })

        this.setState({
            battery: playComputerPABytesToSensorData(
                bytesRecieved.slice(2),
                22
            ),
        })
    }

    componentWillMount() {
        a1Checked = JSON.parse(sessionStorage.getItem('a1-I/O'))
        a1Digi = JSON.parse(sessionStorage.getItem('A1DIGI'))
        a2Checked = JSON.parse(sessionStorage.getItem('a2-I/O'))
        a2Digi = JSON.parse(sessionStorage.getItem('A2DIGI'))
        b1Checked = JSON.parse(sessionStorage.getItem('b1-I/O'))
        b1Digi = JSON.parse(sessionStorage.getItem('B1DIGI'))
        b2Checked = JSON.parse(sessionStorage.getItem('b2-I/O'))
        b2Digi = JSON.parse(sessionStorage.getItem('B2DIGI'))
        c1Checked = JSON.parse(sessionStorage.getItem('c1-I/O'))
        c1Digi = JSON.parse(sessionStorage.getItem('C1DIGI'))
        c2Checked = JSON.parse(sessionStorage.getItem('c2-I/O'))
        c2Digi = JSON.parse(sessionStorage.getItem('C2DIGI'))
        e1Checked = JSON.parse(sessionStorage.getItem('e1-I/O'))
        e1Digi = JSON.parse(sessionStorage.getItem('E1DIGI'))
        e2Checked = JSON.parse(sessionStorage.getItem('e2-I/O'))
        e2Digi = JSON.parse(sessionStorage.getItem('E2DIGI'))
        f1Checked = JSON.parse(sessionStorage.getItem('f1-I/O'))
        f1Digi = JSON.parse(sessionStorage.getItem('F1DIGI'))
        f2Checked = JSON.parse(sessionStorage.getItem('f2-I/O'))
        f2Digi = JSON.parse(sessionStorage.getItem('F2DIGI'))
        m1Checked = JSON.parse(sessionStorage.getItem('m1-I/O'))
        m1Digi = JSON.parse(sessionStorage.getItem('M1DIGI'))
        m2Checked = JSON.parse(sessionStorage.getItem('m2-I/O'))
        m2Digi = JSON.parse(sessionStorage.getItem('M2DIGI'))
        m3Checked = JSON.parse(sessionStorage.getItem('m3-I/O'))
        m3Digi = JSON.parse(sessionStorage.getItem('M3DIGI'))
        m4Checked = JSON.parse(sessionStorage.getItem('m4-I/O'))
        m4Digi = JSON.parse(sessionStorage.getItem('M4DIGI'))
        if (selected[this.props.check] == 'null') return

        this.onChange('hi', selected[this.props.check])
    }
    componentWillUnmount() {
        let no_port = this.props.webSerial
        console.log(no_port.readable)
        if (no_port.name != 'Not Connected') {
            if (no_port.readable != null) {
                if (no_port.readable.locked != false) {
                    reader.cancel()
                }
            }
        }

        count[this.props.check] = this.state.value
        selected[this.props.check] = this.state.selected
        selectedTwo[this.props.check] = this.state.selectedTwo
        isG[this.props.check] = this.state.isGraterThan

        isL[this.props.check] = this.state.isLessThan
        isNe[this.props.check] = this.state.isNotequalTo
        isE[this.props.check] = this.state.isEqualTo
        isIb[this.props.check] = this.state.isInBtween
        count1[this.props.check] = this.state.value1
    }
    // laxmi onchange
    onChange = (name, val) => {
        let version = sessionStorage.getItem('deviceVersion')
        if (name == 'value') {
            this.setState({ value: val })
            return
        } else if (name == 'value1') {
            this.setState({ value1: val })
            return
        }
        if (name !== 'sourceTwo') {
            if (val === 'Bicounter1' || val === 'Bicounter2') {
                this.setState({ min: -1, max: 1 })
            } else if (val === 'Biflag1' || val === 'Biflag2') {
                this.setState({ min: 0, max: 1 })
            } else if (
                val === 'usb rx' ||
                val === 'bt rx 1' ||
                val === 'bt rx 2' ||
                val === 'bt rx 3' ||
                val === 'bt rx 4' ||
                val === 'bt rx 5' ||
                val === 'bt rte'
            ) {
                this.setState({ min: 0, max: 255 })
            } else if (
                val === 'distanceSensor' ||
                val === 'lightSensor' ||
                val === 'colorSensorRed' ||
                val === 'colorSensorGreen' ||
                val === 'colorSensorBlue'
            ) {
                this.setState({ min: 0, max: 255 })
            } else if (val === 'gestureSensor') {
                this.setState({ min: 0, max: 4 })
            } else if (
                [
                    'accelerometer_x',
                    'accelerometer_y',
                    'accelerometer_z',
                ].includes(val)
            ) {
                this.setState({ min: -32768, max: 32768 })
            } else if (['gyro_x', 'gyro_y', 'gyro_z'].includes(val)) {
                this.setState({ min: -2500, max: 2500 })
            } else if (val === 'microphone') {
                this.setState({
                    min: 0,
                    max: version?.startsWith('1') ? 4095 : 2000,
                })
            } else if (val.search('touch') !== -1) {
                this.setState({
                    min: 0,
                    max: version?.startsWith('1') ? 1024 : 500,
                })
            } else if (val === 'temperature') {
                this.setState({
                    min: 0,
                    max: version?.startsWith('1') ? 4095 : 1024,
                })
            } else if (val === 'battery') {
                this.setState({
                    min: 0,
                    max: 100,
                })
            } else if (val === 'port A1') {
                if (a1Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port A2') {
                if (a2Digi)
                    this.setState({
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port B1') {
                if (b1Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port B2') {
                if (b2Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port C1') {
                if (c1Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port C2') {
                if (c2Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port E1') {
                if (e1Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port E2') {
                if (e2Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port F1') {
                if (f1Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port F2') {
                if (f2Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port M1') {
                if (m1Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port M2') {
                if (m2Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port M3') {
                if (m3Digi)
                    this.setState({
                        min: 0,
                        max: version?.startsWith('1') ? 4095 : 1024,
                    })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'port M4') {
                if (m4Digi) this.setState({ min: 0, max: 1024 })
                else this.setState({ min: 0, max: 1 })
            } else if (val == 'ultra A1' || val == 'ultra C1')
                this.setState({ min: 0, max: 400 })
            else {
                if (!a1Checked) {
                    this.onChange('hi', 'port A1')
                } else if (!a2Checked) {
                    this.onChange('hi', 'port A2')
                } else if (!b1Checked) {
                    this.onChange('hi', 'port B1')
                } else if (!b2Checked) {
                    this.onChange('hi', 'port B2')
                } else if (!c1Checked) {
                    this.onChange('hi', 'port C1')
                } else if (!c2Checked) {
                    this.onChange('hi', 'port C2')
                } else if (!e1Checked) {
                    this.onChange('hi', 'port E1')
                } else if (!e2Checked) {
                    this.onChange('hi', 'port E2')
                } else if (!f1Checked) {
                    this.onChange('hi', 'port F1')
                } else if (!f2Checked) {
                    this.onChange('hi', 'port F2')
                } else if (!m1Checked) {
                    this.onChange('hi', 'port M1')
                } else if (!m2Checked) {
                    this.onChange('hi', 'port M2')
                } else if (!m3Checked) {
                    this.onChange('hi', 'port M3')
                } else if (!m4Checked) {
                    this.onChange('hi', 'port M4')
                }
            }
        }
        if (name === 'source') {
            this.setState({ selected: val, value: 0, value1: 0 })
            this.setState({ isGraterThan: true })
            this.setState({ isLessThan: false })
            this.setState({ isInBtween: false })
            this.setState({ isEqualTo: false })
            this.setState({ isNotequalTo: false })
        } else if (name == 'sourceTwo')
            this.setState({ selectedTwo: val, value: 0, value1: 0 })
        // if (this.state.value > this.state.max) this.setState({ value: 0 });

        // if (isNaN(val)==true){
        // 	console.log(val,"checking +++++++++++**********")

        // 	this.setState({selectedDropDownList:val})
        //   }
    }

    onChangeRead = (key, value) => {
        console.log('value=====>', value)
        this.setState({ readToggel: value.trim() })

        // state[key] = value;
    }

    handleOperators(val) {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (val == 'greaterThan')
            if (this.state.isGraterThan) this.setState({ isGraterThan: false })
            else {
                this.setState({ isGraterThan: true })
                this.setState({ isLessThan: false })
                this.setState({ isInBtween: false })
                this.setState({ isEqualTo: false })
                this.setState({ isNotequalTo: false })
            }
        else if (val == 'lessThan')
            if (this.state.isLessThan) this.setState({ isLessThan: false })
            else {
                this.setState({ isLessThan: true })
                this.setState({ isGraterThan: false })
                this.setState({ isInBtween: false })
                this.setState({ isEqualTo: false })
                this.setState({ isNotequalTo: false })
            }
        else if (val == 'inBetween')
            if (this.state.isInBtween) this.setState({ isInBtween: false })
            else {
                this.setState({ isInBtween: true })
                this.setState({ isGraterThan: false })
                this.setState({ isLessThan: false })
                this.setState({ isEqualTo: false })
                this.setState({ isNotequalTo: false })
            }
        else if (val == 'equalTo')
            if (this.state.isEqualTo) this.setState({ isEqualTo: false })
            else {
                this.setState({ isEqualTo: true })
                this.setState({ isInBtween: false })
                this.setState({ isGraterThan: false })
                this.setState({ isLessThan: false })

                this.setState({ isNotequalTo: false })
            }
        else if (val == 'notEqualTo')
            if (this.state.isNotequalTo) this.setState({ isNotequalTo: false })
            else {
                this.setState({ isNotequalTo: true })
                this.setState({ isInBtween: false })
                this.setState({ isGraterThan: false })
                this.setState({ isLessThan: false })
                this.setState({ isEqualTo: false })
            }
    }
    outputGesture = (val) => {
        // return val;
        if (val != 0) {
            this.state.recentGesture = val
        }
        return this.state.recentGesture
    }

    render() {
        sessionStorage.setItem(
            `ifSelect${this.props.check}`,
            this.state.selected
        )

        sessionStorage.setItem(`ifValue${this.props.check}`, this.state.value)
        sessionStorage.setItem(`ifValue2${this.props.check}`, this.state.value1)

        sessionStorage.setItem(`gt${this.props.check}`, this.state.isGraterThan)
        sessionStorage.setItem(`lt${this.props.check}`, this.state.isLessThan)
        sessionStorage.setItem(`bw${this.props.check}`, this.state.isInBtween)
        sessionStorage.setItem(`eq${this.props.check}`, this.state.isEqualTo)
        sessionStorage.setItem(`ne${this.props.check}`, this.state.isNotequalTo)

        let sessionFlowLogic = JSON.parse(sessionStorage.getItem('flow-logic'))
        console.log('KML@@@+++', this.state.readToggel)

        // laxmi gesture
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )

        return (
            <>
                {connectedDevice === 'Ace' && (
                    <div className="outertabDiv-modals">
                        <div className="FlowchartDecisionPanel_Container">
                            {/* first done */}
                            <div
                                style={{ position: 'relative' }}
                                className="Flowsub_Container1"
                            >
                                <div
                                    id="Flowcontent1_text1"
                                    style={{
                                        color: 'black',
                                    }}
                                >
                                    <p
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '7%',
                                            transform: 'translate(0%, -50%)',
                                        }}
                                    >
                                        If the value of
                                    </p>
                                </div>
                                <div
                                    id="Flowcontent1_text2"
                                    style={{
                                        color: 'black',
                                    }}
                                >
                                    <div
                                        style={{
                                            border: '2px solid black',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '0%',
                                            transform: 'translate(0%, -50%)',
                                            width: '100%',
                                            height: '40%',
                                            borderRadius: '15px',
                                            fontFamily: 'Halcyon_Medium',
                                        }}
                                    >
                                        <Select
                                            onChange={(value) =>
                                                this.onChange('source', value)
                                            }
                                            componetName="flowchart"
                                            selected={this.state.selected}
                                        />
                                    </div>
                                </div>
                                <div
                                    id="Flowcontent1_text3"
                                    style={{
                                        color: 'black',
                                    }}
                                >
                                    <p
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '7%',
                                            transform: 'translate(0, -50%)',
                                        }}
                                    >
                                        is
                                    </p>
                                </div>
                            </div>
                            {/* first done */}

                            {/* second */}
                            <div className="Flowsub_Container2">
                                {this.state.isGraterThan ? (
                                    <>
                                        {' '}
                                        <div id="Flowcontent_div1">
                                            <img
                                                src={renderPrgImage(
                                                    'greaterthanActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'greaterThan'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt1">
                                                Greater Than
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div id="Flowcontent_div1">
                                            <img
                                                src={renderPrgImage(
                                                    'greaterthanInActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'greaterThan'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt1">
                                                Greater Than
                                            </p>
                                        </div>
                                    </>
                                )}

                                {this.state.isLessThan ? (
                                    <>
                                        {' '}
                                        <div id="Flowcontent_div2">
                                            <img
                                                src={renderPrgImage(
                                                    'lessthanActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'lessThan'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt2">
                                                Less Than
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div id="Flowcontent_div2">
                                            <img
                                                src={renderPrgImage(
                                                    'lessthanInActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'lessThan'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt2">
                                                Less Than
                                            </p>
                                        </div>
                                    </>
                                )}

                                {this.state.isInBtween ? (
                                    <>
                                        {' '}
                                        <div id="Flowcontent_div3">
                                            <img
                                                src={renderPrgImage(
                                                    'inbetweenActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'inBetween'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt3">
                                                In Between
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div id="Flowcontent_div3">
                                            <img
                                                src={renderPrgImage(
                                                    'inbetweenInActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'inBetween'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt3">
                                                In Between
                                            </p>
                                        </div>
                                    </>
                                )}

                                {this.state.isEqualTo ? (
                                    <>
                                        {' '}
                                        <div id="Flowcontent_div4">
                                            <img
                                                src={renderPrgImage(
                                                    'equaltoActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'equalTo'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt4">
                                                Equals To
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div id="Flowcontent_div4">
                                            <img
                                                src={renderPrgImage(
                                                    'equaltoInActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'equalTo'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt4">
                                                Equals To
                                            </p>
                                        </div>
                                    </>
                                )}

                                {this.state.isNotequalTo ? (
                                    <>
                                        {' '}
                                        <div id="Flowcontent_div5">
                                            <img
                                                src={renderPrgImage(
                                                    'notequaltoActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'notEqualTo'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt5">
                                                Not Equals To
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div id="Flowcontent_div5">
                                            <img
                                                src={renderPrgImage(
                                                    'notequaltoInActive'
                                                )}
                                                onClick={() =>
                                                    this.handleOperators(
                                                        'notEqualTo'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <p id="Flowsensors-txt5">
                                                Not Equals To
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* second */}

                            {/* third */}
                            <div className="Flowsub-container3">
                                {this.state.isInBtween ? (
                                    <>
                                        <div>
                                            <div
                                                style={{
                                                    top: '50%',
                                                    width: '100%',
                                                    position: 'absolute',
                                                    transform:
                                                        'translate(0, -50%)',
                                                }}
                                            >
                                                {' '}
                                                <Slider
                                                    value={
                                                        this.state.value || 0
                                                    }
                                                    onChange={(value) =>
                                                        this.onChange(
                                                            'value',
                                                            value
                                                        )
                                                    }
                                                    max={this.state.max}
                                                    min={this.state.min}
                                                    renderIn="conditionPropertyPanel"
                                                    sensor={this.state.selected}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '57.5%',
                                                    left: '30%',
                                                    transform:
                                                        'translate(-50%,-50%)',
                                                }}
                                            >
                                                {' '}
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {this.state.min}
                                                </p>
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '57.5%',
                                                    left: '83%',
                                                    transform:
                                                        'translate(-50%,-50%)',
                                                }}
                                            >
                                                {' '}
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {this.state.max}
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                style={{
                                                    top: '65%',
                                                    width: '100%',
                                                    position: 'absolute',
                                                    transform:
                                                        'translate(0, -50%)',
                                                }}
                                            >
                                                {' '}
                                                <Slider
                                                    value={
                                                        this.state.value1 || 0
                                                    }
                                                    onChange={(value) =>
                                                        this.onChange(
                                                            'value1',
                                                            value
                                                        )
                                                    }
                                                    max={this.state.max}
                                                    min={this.state.min}
                                                    renderIn="conditionPropertyPanel"
                                                    sensor={this.state.selected}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '73%',
                                                    left: '30%',
                                                    transform:
                                                        'translate(-50%,-50%)',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {this.state.min}
                                                </p>
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '73%',
                                                    left: '83%',
                                                    transform:
                                                        'translate(-50%,-50%)',
                                                }}
                                            >
                                                {' '}
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {this.state.max}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="select-slider margin-section">
                                            <Slider
                                                value={this.state.value || 0}
                                                onChange={(value) =>
                                                    this.onChange(
                                                        'value',
                                                        value
                                                    )
                                                }
                                                max={this.state.max}
                                                min={this.state.min}
                                                renderIn="conditionPropertyPanel"
                                                sensor={this.state.selected}
                                            />

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '67%',
                                                    left: '30%',
                                                    transform:
                                                        'translate(-50%,-50%)',
                                                }}
                                            >
                                                {' '}
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {this.state.min}
                                                </p>
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '67%',
                                                    left: '83%',
                                                    transform:
                                                        'translate(-50%,-50%)',
                                                }}
                                            >
                                                {' '}
                                                <p
                                                    style={{
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {this.state.max}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* third */}

                            {/* fourth div */}
                            <div className="Flowsub_Container4">
                                <div id="Flowcontent5_text1">
                                    <p
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            right: '7%',
                                            transform: 'translate(0%, -50%)',
                                        }}
                                    >
                                        Read the
                                    </p>
                                </div>

                                <div id="Flowcontent5_text2">
                                    <div
                                        style={{
                                            border: '2px solid black',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '0%',
                                            transform: 'translate(0%, -50%)',
                                            width: '100%',
                                            height: '45%',
                                            borderRadius: '15px',
                                        }}
                                    >
                                        <Select
                                            onChange={(value) =>
                                                this.onChangeRead(
                                                    'sourceTwo',
                                                    value
                                                )
                                            }
                                            componetName="flowchart"
                                            selected={this.state.selectedTwo}
                                        />
                                    </div>
                                </div>

                                <div id="Flowcontent5_text3">
                                    {sessionStorage
                                        .getItem('deviceVersion')
                                        ?.startsWith('1') ? (
                                        <>
                                            {this.state.isRead_v1 ? (
                                                <div
                                                    className="subFlowchart_text3"
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                >
                                                    {' '}
                                                    <div
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '55%',
                                                            left: '31%',
                                                            transform:
                                                                'translate(0, -50%)',
                                                            fontSize: '2vh',
                                                            color: 'black',
                                                        }}
                                                        onClick={() => {
                                                            this.handleReadByte_v1()
                                                        }}
                                                    >
                                                        {this.state
                                                            .readToggel ===
                                                            'port A1' ||
                                                        this.state.readToggel ==
                                                            'ultra A1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeA1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port A2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeA2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port B1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeB1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port B2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeB2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                              'port C1' ||
                                                          this.state
                                                              .readToggel ===
                                                              'ultra C1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeC1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port C2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeC2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port D1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeD1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port D2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeD2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port E1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeE1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port E2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeE2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port F1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeF1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'port F2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeF2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'touchZero' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .zero
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'touchOne' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.one}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'touchTwo' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.two}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'touchThree' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .temp
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'battery' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .battery
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'microphone' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.mic}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'temperature' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .temprature
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'accelerometer_x' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .accelerometer_X
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'accelerometer_y' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .accelerometer_Y
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'accelerometer_z' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .accelerometer_Z
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'gyro_x' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .gyro_X
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'gyro_y' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .gyro_Y
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'gyro_z' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .gyro_Z
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'colorSensorBlue' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .blue
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'colorSensorGreen' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .green
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'colorSensorRed' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.red}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'lightSensor' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .light
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'gestureSensor' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .gesture
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ===
                                                          'distanceSensor' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .distance
                                                                }
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="subFlowchart1_text3">
                                                    {' '}
                                                    <div
                                                        id="ID"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '24%',
                                                            transform:
                                                                'translate(0, -50%)',
                                                            fontSize: '2vh',
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                this.props
                                                                    .webSerial
                                                                    .isConnected
                                                            )
                                                                this.handleReadByte_v1()
                                                        }}
                                                    >
                                                        Read
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            {this.state.isRead ? (
                                                <div
                                                    className="subFlowchart_text3"
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '55%',
                                                            left: '31%',
                                                            transform:
                                                                'translate(0, -50%)',
                                                            fontSize: '2vh',
                                                            color: 'black',
                                                        }}
                                                        onClick={() => {
                                                            this.handleReadByte()
                                                        }}
                                                    >
                                                        {' '}
                                                        {this.state
                                                            .readToggel ==
                                                            'port A1' ||
                                                        this.state.readToggel ==
                                                            'ultra A1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeA1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'port A2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeA2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'port B1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeB1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'port B2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeB2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                              'port C1' ||
                                                          this.state
                                                              .readToggel ==
                                                              'ultra C1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeC1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'port C2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeC2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'port F1' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeF1
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'port F2' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .rangeF2
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'touchZero' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .zero
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'touchOne' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.one}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'touchTwo' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.two}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'microphone' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.mic}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'temperature' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .temprature
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'colorSensorBlue' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .blue
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'colorSensorGreen' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .green
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'colorSensorRed' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {this.state.red}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'lightSensor' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .light
                                                                }
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'gestureSensor' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {gestureValueToText(
                                                                    this.outputGesture(
                                                                        this
                                                                            .state
                                                                            .gesture
                                                                    )
                                                                )}
                                                            </p>
                                                        ) : this.state
                                                              .readToggel ==
                                                          'distanceSensor' ? (
                                                            <p
                                                                style={{
                                                                    marginBottom:
                                                                        '0%',
                                                                }}
                                                            >
                                                                {
                                                                    this.state
                                                                        .distance
                                                                }
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="subFlowchart1_text3">
                                                    <div
                                                        id="ID"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '24%',
                                                            transform:
                                                                'translate(0, -50%)',
                                                            fontSize: '2vh',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={async (e) => {
                                                            if (
                                                                ![
                                                                    false,
                                                                    'null',
                                                                ].includes(
                                                                    this.state
                                                                        .readToggel
                                                                ) &&
                                                                this.props
                                                                    .webSerial
                                                                    .isConnected
                                                            )
                                                                await this.handleReadByte()
                                                        }}
                                                    >
                                                        Read
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* fourth div */}
                        </div>
                    </div>
                )}

                {['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice) && (
                    <DecisionPanel check={this.props.check} />
                )}
            </>
        )
    }
}

// export default Condition;
const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, null)(Condition)
