import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { webSerialConnect } from '../../../../redux/actions'
import useLocalStorage from '../../../LocalStorage/LocalStorage'
import { Link, useHistory } from 'react-router-dom'
import renderPrgImage from '../../../../source/programImg'
import ProgramHeader from '../../../ReusableComponents/Header/ProgramHeader'
import LeftToggleSection from './components/LeftToggleSection'
import RightToggleSection from './components/RightToggleSection'
import HelpScreen from './components/HelpScreen'
import styles from './style.module.css'

const isPCv1 = sessionStorage.getItem('deviceVersion')?.startsWith('1')
    ? true
    : false

let bttnColor = []
let bttnColor2 = []
let bttType = [
    'A1DIGI',
    'A2DIGI',
    'B1DIGI',
    'B2DIGI',
    'C1DIGI',
    'C2DIGI',
    'D1DIGI',
    'D2DIGI',
    'F1DIGI',
    'F2DIGI',
    'E1DIGI',
    'E2DIGI',
    'M1DIGI',
    'M2DIGI',
    'M3DIGI',
    'M4DIGI',
]

function Digital(props) {
    const history = useHistory()
    const [isAnimating, setIsAnimating] = useState(false)
    useEffect(() => {
        setIsAnimating(false)
    }, [])
    const [ishelpButton, sethelpButton] = useState(false)
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const next = () => {
        saveDataToLocalStorage()

        history.push('/flow/flowchart')
    }
    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')

    for (let i = 0; i < 16; i++) {
        bttnColor[i] = '#717171'
        bttnColor2[i] = '#fcfcfc'
        if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
            bttnColor[i] = '#fcfcfc'
            bttnColor2[i] = '#717171'
        }
    }

    useLayoutEffect(() => {
        return () => {
            for (let i = 0; i < 16; i++) {
                if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
                    bttnColor[i] = '#fcfcfc'
                    bttnColor2[i] = '#717171'
                } else {
                    bttnColor[i] = '#717171'
                    bttnColor2[i] = '#fcfcfc'
                }
            }
        }
    })
    const backBtnAction = () => {
        saveDataToLocalStorage()

        history.push('/flow/input-output')
    }
    const isDistanceSensors = JSON.parse(
        sessionStorage.getItem('isDistanceSensors')
    )
    const isGestureSensor = JSON.parse(
        sessionStorage.getItem('isGestureSensor')
    )
    const isAccelerometer = JSON.parse(
        sessionStorage.getItem('isAccelerometer')
    )
    const isLightSensor = JSON.parse(sessionStorage.getItem('isLightSensor'))
    const isColorSensor = JSON.parse(sessionStorage.getItem('isColorSensor'))
    const isTemperature = JSON.parse(sessionStorage.getItem('isTemperature'))
    const isMic = JSON.parse(sessionStorage.getItem('isMic'))
    const isTouchZero = JSON.parse(sessionStorage.getItem('isTouchZero'))
    const isTouchOne = JSON.parse(sessionStorage.getItem('isTouchOne'))
    const isTouchTwo = JSON.parse(sessionStorage.getItem('isTouchTwo'))
    const isTouchThree = JSON.parse(sessionStorage.getItem('isTouchThree'))
    const isInternalEye = JSON.parse(sessionStorage.getItem('isInternalEye'))
    const isTouchZeroOutput = JSON.parse(
        sessionStorage.getItem('isTouchZeroOutput')
    )
    const isTouchOneOutput = JSON.parse(
        sessionStorage.getItem('isTouchOneOutput')
    )
    const isTouchTwoOutput = JSON.parse(
        sessionStorage.getItem('isTouchTwoOutput')
    )
    const isTouchThreeOutput = JSON.parse(
        sessionStorage.getItem('isTouchThreeOutput')
    )
    const isEyeLeft = JSON.parse(sessionStorage.getItem('isEyeLeft'))
    const isEyeRight = JSON.parse(sessionStorage.getItem('isEyeRight'))
    const isbuzzer = JSON.parse(sessionStorage.getItem('isBuzzer'))
    const isSmileOne = JSON.parse(sessionStorage.getItem('isSmileOne'))
    const isSmileTwo = JSON.parse(sessionStorage.getItem('isSmileTwo'))
    const isSmileThree = JSON.parse(sessionStorage.getItem('isSmileThree'))
    const isSmileFour = JSON.parse(sessionStorage.getItem('isSmileFour'))

    var flowElements =
        JSON.parse(sessionStorage.getItem('flowchart-elements')) || null
    const A1DIGI = JSON.parse(sessionStorage.getItem('A1'))
    const A2DIGI = JSON.parse(sessionStorage.getItem('A2'))
    const B1DIGI = JSON.parse(sessionStorage.getItem('B1'))
    const B2DIGI = JSON.parse(sessionStorage.getItem('B2'))
    const C1DIGI = JSON.parse(sessionStorage.getItem('C1'))
    const C2DIGI = JSON.parse(sessionStorage.getItem('C2'))
    const D1DIGI = JSON.parse(sessionStorage.getItem('D1'))
    const D2DIGI = JSON.parse(sessionStorage.getItem('D2'))
    const F1DIGI = JSON.parse(sessionStorage.getItem('F1'))
    const F2DIGI = JSON.parse(sessionStorage.getItem('F2'))
    const E1DIGI = JSON.parse(sessionStorage.getItem('E1'))
    const E2DIGI = JSON.parse(sessionStorage.getItem('E2'))
    const M1DIGI = JSON.parse(sessionStorage.getItem('M1'))
    const M2DIGI = JSON.parse(sessionStorage.getItem('M2'))
    const M3DIGI = JSON.parse(sessionStorage.getItem('M3'))
    const M4DIGI = JSON.parse(sessionStorage.getItem('M4'))
    const SPI = JSON.parse(sessionStorage.getItem('spi'))
    const I2C = JSON.parse(sessionStorage.getItem('i2c'))
    const UART = JSON.parse(sessionStorage.getItem('uart'))
    const F1_I_O = JSON.parse(sessionStorage.getItem('f1-I/O')) || false
    const F2_I_O = JSON.parse(sessionStorage.getItem('f2-I/O')) || false
    const D1_I_O = JSON.parse(sessionStorage.getItem('d1-I/O')) || false
    const D2_I_O = JSON.parse(sessionStorage.getItem('d2-I/O')) || false

    const [a1Digi, setA1Digi] = useLocalStorage('A1DIGI', false)
    const [b1Digi, setB1Digi] = useLocalStorage('B1DIGI', false)
    const [c1Digi, setC1Digi] = useLocalStorage('C1DIGI', false)
    const [d1Digi, setD1Digi] = useLocalStorage('D1DIGI', false)
    const [a2Digi, setA2Digi] = useLocalStorage('A2DIGI', false)
    const [b2Digi, setB2Digi] = useLocalStorage('B2DIGI', false)
    const [c2Digi, setC2Digi] = useLocalStorage('C2DIGI', false)
    const [d2Digi, setD2Digi] = useLocalStorage('D2DIGI', false)
    const [f1Digi, setF1Digi] = useLocalStorage('F1DIGI', false)
    const [f2Digi, setF2Digi] = useLocalStorage('F2DIGI', false)
    const [e1Digi, setE1Digi] = useLocalStorage('E1DIGI', false)
    const [e2Digi, setE2Digi] = useLocalStorage('E2DIGI', false)
    const [m1Digi, setM1Digi] = useLocalStorage('M1DIGI', false)
    const [m2Digi, setM2Digi] = useLocalStorage('M2DIGI', false)
    const [m3Digi, setM3Digi] = useLocalStorage('M3DIGI', false)
    const [m4Digi, setM4Digi] = useLocalStorage('M4DIGI', false)

    const [s1] = useLocalStorage('S1', false)
    const [s2] = useLocalStorage('S2', false)
    const [s1Servo, setS1Servo] = useLocalStorage('S1Servo', false)
    const [s2Servo, setS2Servo] = useLocalStorage('S2Servo', false)
    const [s1Servo270, setS1Servo270] = useLocalStorage('S1Servo270', false)
    const [s2Servo270, setS2Servo270] = useLocalStorage('S2Servo270', false)
    const [s1Servo360, setS1Servo360] = useLocalStorage('S1Servo360', false)
    const [s2Servo360, setS2Servo360] = useLocalStorage('S2Servo360', false)

    const [a1Servo, setA1Servo] = useLocalStorage('A1Servo', false)
    const [a1Servo270, setA1Servo270] = useLocalStorage('A1Servo270', false)
    const [a1Servo360, setA1Servo360] = useLocalStorage('A1Servo360', false)
    const [a2Servo, setA2Servo] = useLocalStorage('A2Servo', false)
    const [a2Servo270, setA2Servo270] = useLocalStorage('A2Servo270', false)
    const [a2Servo360, setA2Servo360] = useLocalStorage('A2Servo360', false)
    const [b1Servo, setB1Servo] = useLocalStorage('B1Servo', false)
    const [b1Servo270, setB1Servo270] = useLocalStorage('B1Servo270', false)
    const [b1Servo360, setB1Servo360] = useLocalStorage('B1Servo360', false)
    const [b2Servo, setB2Servo] = useLocalStorage('B2Servo', false)
    const [b2Servo270, setB2Servo270] = useLocalStorage('B2Servo270', false)
    const [b2Servo360, setB2Servo360] = useLocalStorage('B2Servo360', false)
    const [c1Servo, setC1Servo] = useLocalStorage('C1Servo', false)
    const [c1Servo270, setC1Servo270] = useLocalStorage('C1Servo270', false)
    const [c1Servo360, setC1Servo360] = useLocalStorage('C1Servo360', false)
    const [c2Servo, setC2Servo] = useLocalStorage('C2Servo', false)
    const [c2Servo270, setC2Servo270] = useLocalStorage('C2Servo270', false)
    const [c2Servo360, setC2Servo360] = useLocalStorage('C2Servo360', false)

    const [d1Servo, setD1Servo] = useLocalStorage('D1Servo', false)
    const [d1Servo270, setD1Servo270] = useLocalStorage('D1Servo270', false)
    const [d1Servo360, setD1Servo360] = useLocalStorage('D1Servo360', false)
    const [d2Servo, setD2Servo] = useLocalStorage('D2Servo', false)
    const [d2Servo270, setD2Servo270] = useLocalStorage('D2Servo270', false)
    const [d2Servo360, setD2Servo360] = useLocalStorage('D2Servo360', false)

    const [e1Servo, setE1Servo] = useLocalStorage('E1Servo', false)
    const [e1Servo270, setE1Servo270] = useLocalStorage('E1Servo270', false)
    const [e1Servo360, setE1Servo360] = useLocalStorage('E1Servo360', false)
    const [e2Servo, setE2Servo] = useLocalStorage('E2Servo', false)
    const [e2Servo270, setE2Servo270] = useLocalStorage('E2Servo270', false)
    const [e2Servo360, setE2Servo360] = useLocalStorage('E2Servo360', false)

    const [f1Servo, setF1Servo] = useLocalStorage('F1Servo', false)
    const [f1Servo270, setF1Servo270] = useLocalStorage('F1Servo270', false)
    const [f1Servo360, setF1Servo360] = useLocalStorage('F1Servo360', false)
    const [f2Servo, setF2Servo] = useLocalStorage('F2Servo', false)
    const [f2Servo270, setF2Servo270] = useLocalStorage('F2Servo270', false)
    const [f2Servo360, setF2Servo360] = useLocalStorage('F2Servo360', false)
    // Laxmi
    const [m3Mp3, setM3Mp3] = useLocalStorage('M3Mp3', false)
    const [m1Servo, setM1Servo] = useLocalStorage('M1Servo', false)

    useEffect(() => {
        if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (F1_I_O === false && f1Digi) {
                toggleF1()
            }
        }
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (D1_I_O === false && d1Digi) {
                toggleD1()
            }
        }
    }, [f1Digi, d1Digi])
    useEffect(() => {
        if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (F2_I_O === false && f2Digi) {
                toggleF2()
            }
        }
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (D2_I_O === false && d2Digi) {
                toggleD2()
            }
        }
    }, [f2Digi, d2Digi])
    const [pwmA1, setPwmA1] = useLocalStorage(
        'PWMA1',
        JSON.parse(sessionStorage.getItem('a1-I/O')) &&
            JSON.parse(sessionStorage.getItem('A1'))
    )
    const [pwmD1, setPwmD1] = useLocalStorage(
        'PWMD1',
        JSON.parse(sessionStorage.getItem('d1-I/O')) &&
            JSON.parse(sessionStorage.getItem('D1'))
    )
    const [pwmD2, setPwmD2] = useLocalStorage(
        'PWMD2',
        JSON.parse(sessionStorage.getItem('d2-I/O')) &&
            JSON.parse(sessionStorage.getItem('D2'))
    )
    const toggleA1 = async () => {
        if (
            !A1DIGI ||
            a1Servo ||
            a1Servo270 ||
            a1Servo360 ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        ) {
            setToastMsg('Enable A1 To Enable A1 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }
        if (JSON.parse(sessionStorage.getItem('AUltra'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (a1Servo || a1Servo270 || a1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !A1DIGI ||
            a1Servo ||
            a1Servo270 ||
            a1Servo360 ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        )
            return
        if (JSON.parse(sessionStorage.getItem('a1-I/O')) === true) {
            setPwmA1(!pwmA1)
        }
        setA1Digi(!a1Digi)
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a1Chk${value.id}`, false)
                    sessionStorage.setItem(`a1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('A1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('A1DIGI')))) {
            document.getElementById('in1').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s1').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in1').style.cssText = 'color: #717171; '
            document.getElementById('s1').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleA2 = async () => {
        if (
            !A2DIGI ||
            a2Servo ||
            a2Servo270 ||
            a2Servo360 ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        ) {
            setToastMsg('Enable A2 To Enable A2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('AUltra'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (a2Servo || a2Servo270 || a2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !A2DIGI ||
            a2Servo ||
            a2Servo270 ||
            a2Servo360 ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        )
            return
        setA2Digi(!a2Digi)
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a2Chk${value.id}`, false)
                    sessionStorage.setItem(`a2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('A2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('A2DIGI')))) {
            document.getElementById('in2').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s2').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in2').style.cssText = 'color: #717171; '
            document.getElementById('s2').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleB1 = async () => {
        if (
            !B1DIGI ||
            b1Servo ||
            b1Servo270 ||
            b1Servo360 ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        ) {
            setToastMsg('Enable B1 To Enable B1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3'))
        ) {
            console.log('WORKING 1')
            setToastMsg('Disable RGB AND MP3')
            setToastActive(true)
            disableToast()
        }
        if (b1Servo || b1Servo270 || b1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !B1DIGI ||
            b1Servo ||
            b1Servo270 ||
            b1Servo360 ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        )
            return
        setB1Digi(!b1Digi)
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b1Chk${value.id}`, false)
                    sessionStorage.setItem(`b1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('B1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('B1DIGI')))) {
            document.getElementById('in3').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s3').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in3').style.cssText = 'color: #717171; '
            document.getElementById('s3').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleB2 = async () => {
        if (
            !B2DIGI ||
            b2Servo ||
            b2Servo270 ||
            b2Servo360 ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        ) {
            setToastMsg('Enable B2 To Enable B2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3'))
        ) {
            setToastMsg('Disable RGB AND MP3')
            setToastActive(true)
            disableToast()
        }
        if (b2Servo || b2Servo270 || b2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !B2DIGI ||
            b2Servo ||
            b2Servo270 ||
            b2Servo360 ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        )
            return
        setB2Digi(!b2Digi)
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b2Chk${value.id}`, false)
                    sessionStorage.setItem(`b2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('B2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('B2DIGI')))) {
            document.getElementById('in4').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s4').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in4').style.cssText = 'color: #717171; '
            document.getElementById('s4').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleC1 = async () => {
        if (
            !C1DIGI ||
            c1Servo ||
            c1Servo270 ||
            c1Servo360 ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        ) {
            setToastMsg('Enable C1 To Enable C1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('CUltra'))) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (c1Servo || c1Servo270 || c1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !C1DIGI ||
            c1Servo ||
            c1Servo270 ||
            c1Servo360 ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        )
            return
        setC1Digi(!c1Digi)
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c1Chk${value.id}`, false)
                    sessionStorage.setItem(`c1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('C1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('C1DIGI')))) {
            document.getElementById('in5').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s5').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in5').style.cssText = 'color: #717171; '
            document.getElementById('s5').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleC2 = async () => {
        if (
            !C2DIGI ||
            c2Servo ||
            c2Servo270 ||
            c2Servo360 ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        ) {
            setToastMsg('Enable C2 To Enable C2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('CUltra'))) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (c2Servo || c2Servo270 || c2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !C2DIGI ||
            c2Servo ||
            c2Servo270 ||
            c2Servo360 ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        )
            return
        setC2Digi(!c2Digi)
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c2Chk${value.id}`, false)
                    sessionStorage.setItem(`c2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('C2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('C2DIGI')))) {
            document.getElementById('in6').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s6').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in6').style.cssText = 'color: #717171; '
            document.getElementById('s6').style.cssText = '  color: #fcfcfc;'
        }
    }

    const toggleD1 = async () => {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (d1Digi === false && D1_I_O === false) {
                setToastMsg('D1 supports only digital when it is Input')
                setToastActive(true)
                disableToast()
                return
            }
            if (
                D1_I_O &&
                (isColorSensor ||
                    isDistanceSensors ||
                    isLightSensor ||
                    isAccelerometer ||
                    isGestureSensor) &&
                isPCv1
            ) {
                setToastMsg(' D1 port is not avaliable ')
                setToastActive(true)
                disableToast()
                return
            }
        }
        if (
            !JSON.parse(sessionStorage.getItem('D1')) ||
            d1Servo ||
            d1Servo270 ||
            d1Servo360 ||
            JSON.parse(sessionStorage.getItem('DOLED'))
        ) {
            setToastMsg('Enable D1 To Enable D1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('DOLED'))) {
            setToastMsg('Disable  OLED')
            setToastActive(true)
            disableToast()
        }
        if (d1Servo || d1Servo270 || d1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !JSON.parse(sessionStorage.getItem('D1')) ||
            // !JSON.parse(sessionStorage.getItem("d1-I/O")) ||
            d1Servo ||
            d1Servo360 ||
            d1Servo270 ||
            JSON.parse(sessionStorage.getItem('DOLED'))
        )
            return
        // if (JSON.parse(sessionStorage.getItem("d1-I/O")) === true) {
        //   setPwmD1(!pwmD1);
        // }
        setD1Digi(!d1Digi)
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d1Chk${value.id}`, false)
                    sessionStorage.setItem(`d1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('D1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('D1DIGI')))) {
            document.getElementById('in7').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s7').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in7').style.cssText = 'color: #717171; '
            document.getElementById('s7').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleD2 = async () => {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (d2Digi === false && D2_I_O === false) {
                setToastMsg('D2 supports only digital when it is Input')
                setToastActive(true)
                disableToast()
                return
            }
            if (
                D2_I_O &&
                (isColorSensor ||
                    isDistanceSensors ||
                    isLightSensor ||
                    isAccelerometer ||
                    isGestureSensor) &&
                isPCv1
            ) {
                setToastMsg(' D2 port is not avaliable ')
                setToastActive(true)
                disableToast()
                return
            }
        }
        if (
            !JSON.parse(sessionStorage.getItem('D2')) ||
            // !JSON.parse(sessionStorage.getItem("d2-I/O")) ||
            d2Servo ||
            d2Servo360 ||
            d2Servo270 ||
            JSON.parse(sessionStorage.getItem('DOLED'))
        ) {
            setToastMsg('Enable D2 To Enable D2 Digital  or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('DOLED'))) {
            setToastMsg('Disable  OLED')
            setToastActive(true)
            disableToast()
        }
        if (d2Servo || d2Servo270 || d2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !JSON.parse(sessionStorage.getItem('D2')) ||
            // !JSON.parse(sessionStorage.getItem("d2-I/O")) ||
            d2Servo ||
            d2Servo270 ||
            d2Servo360 ||
            JSON.parse(sessionStorage.getItem('DOLED'))
        )
            return

        setD2Digi(!d2Digi)
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d2Chk${value.id}`, false)
                    sessionStorage.setItem(`d2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('D2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('D2DIGI')))) {
            document.getElementById('in8').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s8').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in8').style.cssText = 'color: #717171; '
            document.getElementById('s8').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleF1 = async () => {
        if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            //&& F1_I_O === false
            if (f1Digi === false) {
                setToastMsg('F1 supports only digital')
                setToastActive(true)
                disableToast()
                return
            }
        }

        if (!F1DIGI || false) {
            setToastMsg('Enable F1 To Enable F1 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }

        if (f1Servo || f1Servo270 || f1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (!F1DIGI || f1Servo || f1Servo270 || f1Servo360 || false) return

        setF1Digi(!f1Digi)
        if (
            sessionStorage.getItem('f1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f1Chk${value.id}`, false)
                    sessionStorage.setItem(`f1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('F1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('F1DIGI')))) {
            document.getElementById('in9').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s9').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in9').style.cssText = 'color: #717171; '
            document.getElementById('s9').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleF2 = async () => {
        if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            //&& F2_I_O === false
            if (f2Digi === false) {
                setToastMsg('F2 supports only digital')
                setToastActive(true)
                disableToast()
                return
            }
        }
        if (!F2DIGI || false) {
            setToastMsg('Enable F2 To Enable F2 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }

        if (f2Servo || f2Servo270 || f2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (!F2DIGI || f2Servo || f2Servo270 || f2Servo360 || false) return

        setF2Digi(!f2Digi)
        if (
            sessionStorage.getItem('f2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f2Chk${value.id}`, false)
                    sessionStorage.setItem(`f2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('F2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('F2DIGI')))) {
            document.getElementById('in10').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s10').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in10').style.cssText = 'color: #717171; '
            document.getElementById('s10').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleE1 = async () => {
        if (!E1DIGI) {
            setToastMsg('Enable E1 To Enable E1 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }
        if (e1Servo || e1Servo270 || e1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (!E1DIGI || e1Servo || e1Servo270 || e1Servo360 || false) return

        setE1Digi(!e1Digi)
        if (
            sessionStorage.getItem('e1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e1Chk${value.id}`, false)
                    sessionStorage.setItem(`e1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('E1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('E1DIGI')))) {
            document.getElementById('in11').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s11').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in11').style.cssText = 'color: #717171; '
            document.getElementById('s11').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleE2 = async () => {
        if (!E2DIGI) {
            setToastMsg('Enable E2 To Enable E2 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }
        if (e2Servo || e2Servo270 || e2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (!E2DIGI || e2Servo || e2Servo270 || e2Servo360 || false) return
        setE2Digi(!e2Digi)
        if (
            sessionStorage.getItem('e2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e2Chk${value.id}`, false)
                    sessionStorage.setItem(`e2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('E2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('E2DIGI')))) {
            document.getElementById('in12').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s12').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in12').style.cssText = 'color: #717171; '
            document.getElementById('s12').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleM1 = async () => {
        if (
            (!JSON.parse(sessionStorage.getItem('M1')) && !isSmileTwo) ||
            false
        ) {
            setToastMsg('Enable L1 To Enable L1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (m1Servo) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if ((!M1DIGI && !isSmileOne) || m1Servo || false) return
        setM1Digi(!m1Digi)
        if (
            sessionStorage.getItem('m1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`m1Chk${value.id}`, false)
                    sessionStorage.setItem(`m1${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('M1DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('M1DIGI')))) {
            document.getElementById('in13').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s13').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in13').style.cssText = 'color: #717171; '
            document.getElementById('s13').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleM2 = async () => {
        if (!JSON.parse(sessionStorage.getItem('M2')) && !isSmileTwo) {
            setToastMsg('Enable L2 To Enable L2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if ((!M2DIGI && !isSmileTwo) || false) return

        setM2Digi(!m2Digi)
        if (
            sessionStorage.getItem('m2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`m2Chk${value.id}`, false)
                    sessionStorage.setItem(`m2${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('M2DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('M2DIGI')))) {
            document.getElementById('in14').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s14').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in14').style.cssText = 'color: #717171; '
            document.getElementById('s14').style.cssText = '  color: #fcfcfc;'
        }
    }

    const toggleM3 = async () => {
        if (!M3DIGI && !isSmileThree) {
            console.log('hii')
            setToastMsg('Enable L3 To Enable L3 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }
        // if (M3DIGI) {
        //     setToastMsg('Only digital can be enabled')
        //     setToastActive(true)
        //     disableToast()
        // }

        // if (m3Mp3) {
        //     setToastMsg('Disable  MP3')
        //     setToastActive(true)
        //     disableToast()
        // }

        if (!M3DIGI && !isSmileThree) return
        setM3Digi(!m3Digi)
        if (
            sessionStorage.getItem('m3-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`m3Chk${value.id}`, false)
                    sessionStorage.setItem(`m3${value.id}`, 0)
                }
            })
        }

        await JSON.parse(sessionStorage.getItem('M3DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('M3DIGI')))) {
            document.getElementById('in15').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s15').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in15').style.cssText = 'color: #717171; '
            document.getElementById('s15').style.cssText = '  color: #fcfcfc;'
        }
    }
    const toggleM4 = async () => {
        if (!M4DIGI && !isSmileFour) {
            console.log('hii')
            setToastMsg('Enable L4 To Enable L4 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }
        // if (M4DIGI) {
        //     console.log('hii')
        //     setToastMsg('Only digital can be enabled')
        //     setToastActive(true)
        //     disableToast()
        // }
        if (m3Mp3) {
            setToastMsg('Disable MP3')
            setToastActive(true)
            disableToast()
        }

        if ((!M4DIGI && !isSmileFour) || m3Mp3 || false) return

        setM4Digi(!m4Digi)
        if (
            sessionStorage.getItem('m4-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`m4Chk${value.id}`, false)
                    sessionStorage.setItem(`m4${value.id}`, 0)
                }
            })
        }
        await JSON.parse(sessionStorage.getItem('M4DIGI'))

        if (!(await JSON.parse(sessionStorage.getItem('M4DIGI')))) {
            document.getElementById('in16').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s16').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in16').style.cssText = 'color: #717171; '
            document.getElementById('s16').style.cssText = '  color: #fcfcfc;'
        }
    }

    const togglePWMA1 = () => {
        if (a1Digi === true) {
            setPwmA1(!pwmA1)
        }
    }

    const ServoA1 = () => {
        setA1Servo(!a1Servo)
        if (!a1Servo) {
            setA1Servo(true)
            setA1Servo360(false)
            setA1Servo270(false)
        }
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a1Chk${value.id}`, false)
                    sessionStorage.setItem(`a1${value.id}`, 0)
                }
            })
        }
    }
    const Servo270A1 = (port) => {
        setA1Servo270(!a1Servo270)
        if (!a1Servo270) {
            setA1Servo(false)
            setA1Servo360(false)
            setA1Servo270(true)
        }
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a1Chk${value.id}`, false)
                    sessionStorage.setItem(`a1${value.id}`, 0)
                }
            })
        }
    }
    const Servo360A1 = () => {
        setA1Servo360(!a1Servo360)
        if (!a1Servo360) {
            setA1Servo(false)
            setA1Servo360(true)
            setA1Servo270(false)
        }
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a1Chk${value.id}`, false)
                    sessionStorage.setItem(`a1${value.id}`, 0)
                }
            })
        }
    }
    const ServoA2 = () => {
        setA2Servo(!a2Servo)
        if (!a2Servo) {
            setA2Servo(true)
            setA2Servo270(false)
            setA2Servo360(false)
        }
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a2Chk${value.id}`, false)
                    sessionStorage.setItem(`a2${value.id}`, 0)
                }
            })
        }
    }
    const Servo270A2 = () => {
        setA2Servo270(!a2Servo270)
        if (!a2Servo270) {
            setA2Servo(false)
            setA2Servo360(false)
            setA2Servo270(true)
        }
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a2Chk${value.id}`, false)
                    sessionStorage.setItem(`a2${value.id}`, 0)
                }
            })
        }
    }
    const Servo360A2 = () => {
        setA2Servo360(!a2Servo360)
        if (!a2Servo360) {
            setA2Servo(false)
            setA2Servo270(false)
            setA2Servo360(true)
        }
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`a2Chk${value.id}`, false)
                    sessionStorage.setItem(`a2${value.id}`, 0)
                }
            })
        }
    }
    const ServoB1 = () => {
        setB1Servo(!b1Servo)
        if (!b1Servo) {
            setB1Servo(true)
            setB1Servo270(false)
            setB1Servo360(false)
        }
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b1Chk${value.id}`, false)
                    sessionStorage.setItem(`b1${value.id}`, 0)
                }
            })
        }
    }
    const Servo270B1 = () => {
        setB1Servo270(!b1Servo270)
        if (!b1Servo270) {
            setB1Servo(false)
            setB1Servo360(false)
            setB1Servo270(true)
        }
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b1Chk${value.id}`, false)
                    sessionStorage.setItem(`b1${value.id}`, 0)
                }
            })
        }
    }
    const Servo360B1 = () => {
        setB1Servo360(!b1Servo360)
        if (!b1Servo360) {
            setB1Servo(false)
            setB1Servo270(false)
            setB1Servo360(true)
        }
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b1Chk${value.id}`, false)
                    sessionStorage.setItem(`b1${value.id}`, 0)
                }
            })
        }
    }
    const ServoB2 = () => {
        setB2Servo(!b2Servo)
        if (!b2Servo) {
            setB2Servo(true)
            setB2Servo270(false)
            setB2Servo360(false)
        }
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b2Chk${value.id}`, false)
                    sessionStorage.setItem(`b2${value.id}`, 0)
                }
            })
        }
    }
    const Servo270B2 = () => {
        setB2Servo270(!b2Servo270)
        if (!b2Servo270) {
            setB2Servo(false)
            setB2Servo360(false)
            setB2Servo270(true)
        }
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b2Chk${value.id}`, false)
                    sessionStorage.setItem(`b2${value.id}`, 0)
                }
            })
        }
    }
    const Servo360B2 = () => {
        setB2Servo360(!b2Servo360)
        if (!b2Servo360) {
            setB2Servo(false)
            setB2Servo270(false)
            setB2Servo360(true)
        }
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`b2Chk${value.id}`, false)
                    sessionStorage.setItem(`b2${value.id}`, 0)
                }
            })
        }
    }
    const ServoC1 = () => {
        setC1Servo(!c1Servo)
        if (!c1Servo) {
            setC1Servo(true)
            setC1Servo270(false)
            setC1Servo360(false)
        }
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c1Chk${value.id}`, false)
                    sessionStorage.setItem(`c1${value.id}`, 0)
                }
            })
        }
    }
    const Servo270C1 = () => {
        setC1Servo270(!c1Servo270)
        if (!c1Servo270) {
            setC1Servo(false)
            setC1Servo360(false)
            setC1Servo270(true)
        }
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c1Chk${value.id}`, false)
                    sessionStorage.setItem(`c1${value.id}`, 0)
                }
            })
        }
    }
    const Servo360C1 = () => {
        setC1Servo360(!c1Servo360)
        if (!c1Servo360) {
            setC1Servo(false)
            setC1Servo270(false)
            setC1Servo360(true)
        }
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c1Chk${value.id}`, false)
                    sessionStorage.setItem(`c1${value.id}`, 0)
                }
            })
        }
    }
    const ServoC2 = () => {
        setC2Servo(!c2Servo)
        if (!c2Servo) {
            setC2Servo(true)
            setC2Servo270(false)
            setC2Servo360(false)
        }
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c2Chk${value.id}`, false)
                    sessionStorage.setItem(`c2${value.id}`, 0)
                }
            })
        }
    }
    const Servo270C2 = () => {
        setC2Servo270(!c2Servo270)
        if (!c2Servo270) {
            setC2Servo(false)
            setC2Servo360(false)
            setC2Servo270(true)
        }
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c2Chk${value.id}`, false)
                    sessionStorage.setItem(`c2${value.id}`, 0)
                }
            })
        }
    }
    const Servo360C2 = () => {
        setC2Servo360(!c2Servo360)
        if (!c2Servo360) {
            setC2Servo(false)
            setC2Servo270(false)
            setC2Servo360(true)
        }
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`c2Chk${value.id}`, false)
                    sessionStorage.setItem(`c2${value.id}`, 0)
                }
            })
        }
    }
    const ServoD1 = () => {
        setD1Servo(!d1Servo)
        if (!d1Servo) {
            setD1Servo(true)
            setD1Servo270(false)
            setD1Servo360(false)
        }
        if (
            sessionStorage.getItem('D1Servo') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d1Chk${value.id}`, false)
                    sessionStorage.setItem(`d1${value.id}`, 0)
                }
            })
        }
    }
    const Servo270D1 = () => {
        setD1Servo270(!d1Servo270)
        if (!d1Servo270) {
            setD1Servo(false)
            setD1Servo360(false)
            setD1Servo270(true)
        }
        if (
            sessionStorage.getItem('D1Servo270') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d1Chk${value.id}`, false)
                    sessionStorage.setItem(`d1${value.id}`, 0)
                }
            })
        }
    }
    const Servo360D1 = () => {
        setD1Servo360(!d1Servo360)
        if (!d1Servo360) {
            setD1Servo(false)
            setD1Servo270(false)
            setD1Servo360(true)
        }
        if (
            sessionStorage.getItem('D1Servo360') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d1Chk${value.id}`, false)
                    sessionStorage.setItem(`d1${value.id}`, 0)
                }
            })
        }
    }
    const ServoD2 = () => {
        setD2Servo(!d2Servo)
        if (!d2Servo) {
            setD2Servo(true)
            setD2Servo270(false)
            setD2Servo360(false)
        }
        if (
            sessionStorage.getItem('D2Servo') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d2Chk${value.id}`, false)
                    sessionStorage.setItem(`d2${value.id}`, 0)
                }
            })
        }
    }
    const Servo270D2 = () => {
        setD2Servo270(!d2Servo270)
        if (!d2Servo270) {
            setD2Servo(false)
            setD2Servo270(true)
            setD2Servo360(false)
        }
        if (
            sessionStorage.getItem('D2Servo270') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d2Chk${value.id}`, false)
                    sessionStorage.setItem(`d2${value.id}`, 0)
                }
            })
        }
    }
    const Servo360D2 = () => {
        setD2Servo360(!d2Servo360)
        if (!d2Servo360) {
            setD2Servo(false)
            setD2Servo270(false)
            setD2Servo360(true)
        }
        if (
            sessionStorage.getItem('D2Servo360') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`d2Chk${value.id}`, false)
                    sessionStorage.setItem(`d2${value.id}`, 0)
                }
            })
        }
    }
    const ServoE1 = () => {
        setE1Servo(!e1Servo)
        if (!e1Servo) {
            setE1Servo(true)
            setE1Servo270(false)
            setE1Servo360(false)
        }
        if (
            sessionStorage.getItem('e1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e1Chk${value.id}`, false)
                    sessionStorage.setItem(`e1${value.id}`, 0)
                }
            })
        }
    }
    const Servo270E1 = () => {
        setE1Servo270(!e1Servo270)
        if (!e1Servo270) {
            setE1Servo(false)
            setE1Servo360(false)
            setE1Servo270(true)
        }
        if (
            sessionStorage.getItem('e1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e1Chk${value.id}`, false)
                    sessionStorage.setItem(`e1${value.id}`, 0)
                }
            })
        }
    }
    const Servo360E1 = () => {
        setE1Servo360(!e1Servo360)
        if (!e1Servo360) {
            setE1Servo(false)
            setE1Servo270(false)
            setE1Servo360(true)
        }
        if (
            sessionStorage.getItem('e1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e1Chk${value.id}`, false)
                    sessionStorage.setItem(`e1${value.id}`, 0)
                }
            })
        }
    }
    const ServoE2 = () => {
        setE2Servo(!e2Servo)
        if (!e2Servo) {
            setE2Servo(true)
            setE2Servo270(false)
            setE2Servo360(false)
        }
        if (
            sessionStorage.getItem('e2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e2Chk${value.id}`, false)
                    sessionStorage.setItem(`e2${value.id}`, 0)
                }
            })
        }
    }
    const Servo270E2 = () => {
        setE2Servo270(!e2Servo270)
        if (!e2Servo270) {
            setE2Servo(false)
            setE2Servo360(false)
            setE2Servo270(true)
        }
        if (
            sessionStorage.getItem('e2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e2Chk${value.id}`, false)
                    sessionStorage.setItem(`e2${value.id}`, 0)
                }
            })
        }
    }
    const Servo360E2 = () => {
        setE2Servo360(!e2Servo360)
        if (!e2Servo360) {
            setE2Servo(false)
            setE2Servo270(false)
            setE2Servo360(true)
        }
        if (
            sessionStorage.getItem('e2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`e2Chk${value.id}`, false)
                    sessionStorage.setItem(`e2${value.id}`, 0)
                }
            })
        }
    }
    const ServoF1 = () => {
        setF1Servo(!f1Servo)
        if (!f1Servo) {
            setF1Servo(true)
            setF1Servo270(false)
            setF1Servo360(false)
        }
        if (
            sessionStorage.getItem('f1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f1Chk${value.id}`, false)
                    sessionStorage.setItem(`f1${value.id}`, 0)
                }
            })
        }
    }
    const Servo270F1 = () => {
        setF1Servo270(!f1Servo270)
        if (!f1Servo270) {
            setF1Servo(false)
            setF1Servo360(false)
            setF1Servo270(true)
        }
        if (
            sessionStorage.getItem('f1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f1Chk${value.id}`, false)
                    sessionStorage.setItem(`f1${value.id}`, 0)
                }
            })
        }
    }
    const Servo360F1 = () => {
        setF1Servo360(!f1Servo360)
        if (!f1Servo360) {
            setF1Servo(false)
            setF1Servo270(false)
            setF1Servo360(true)
        }
        if (
            sessionStorage.getItem('f1-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f1Chk${value.id}`, false)
                    sessionStorage.setItem(`f1${value.id}`, 0)
                }
            })
        }
    }
    const ServoF2 = () => {
        setF2Servo(!f2Servo)
        if (!f2Servo) {
            setF2Servo(true)
            setF2Servo270(false)
            setF2Servo360(false)
        }
        if (
            sessionStorage.getItem('f2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f2Chk${value.id}`, false)
                    sessionStorage.setItem(`f2${value.id}`, 0)
                }
            })
        }
    }
    const Servo270F2 = () => {
        setF2Servo270(!f2Servo270)
        if (!f2Servo270) {
            setF2Servo(false)
            setF2Servo360(false)
            setF2Servo270(true)
        }
        if (
            sessionStorage.getItem('f2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f2Chk${value.id}`, false)
                    sessionStorage.setItem(`f2${value.id}`, 0)
                }
            })
        }
    }
    const Servo360F2 = () => {
        setF2Servo360(!f2Servo360)
        if (!f2Servo360) {
            setF2Servo(false)
            setF2Servo360(true)
            setF2Servo270(false)
        }
        if (
            sessionStorage.getItem('f2-I/O') === 'true' &&
            flowElements !== null
        ) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`f2Chk${value.id}`, false)
                    sessionStorage.setItem(`f2${value.id}`, 0)
                }
            })
        }
    }
    const Mp3M3 = () => {
        setM3Mp3(!m3Mp3)
    }
    const M1Servo = () => {
        setM1Servo(!m1Servo)
    }

    const handleS1ServoToggle = () => {
        if (!s1) {
            setToastMsg('Enable S1 to set Serial Servo')
            setToastActive(true)
            disableToast()
            return
        }
        setS1Servo(!s1Servo)
        if (!s1Servo) {
            setS1Servo(true)
            setS1Servo270(false)
            setS1Servo360(false)
        }
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`s1ServoChk${value.id}`, false)
                    sessionStorage.setItem(`s1Servo${value.id}`, 0)
                }
            })
        }
    }
    const handleS1Servo270Toggle = () => {
        if (!s1) {
            setToastMsg('Enable S1 to set Serial Servo270')
            setToastActive(true)
            disableToast()
            return
        }
        setS1Servo270(!s1Servo270)
        if (!s1Servo270) {
            setS1Servo(false)
            setS1Servo360(false)
            setS1Servo270(true)
        }
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`s1ServoChk${value.id}`, false)
                    sessionStorage.setItem(`s1Servo${value.id}`, 0)
                }
            })
        }
    }
    const handleS1Servo360Toggle = () => {
        if (!s1) {
            setToastMsg('Enable S1 to set Serial Servo360')
            setToastActive(true)
            disableToast()
            return
        }
        setS1Servo360(!s1Servo360)
        if (!s1Servo360) {
            setS1Servo(false)
            setS1Servo270(false)
            setS1Servo360(true)
        }
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`s1ServoChk${value.id}`, false)
                    sessionStorage.setItem(`s1Servo${value.id}`, 0)
                }
            })
        }
    }

    const handleS2ServoToggle = () => {
        if (!s2) {
            setToastMsg('Enable S2 to set Serial Servo')
            setToastActive(true)
            disableToast()
            return
        }

        setS2Servo(!s2Servo)
        if (!s2Servo) {
            setS2Servo(true)
            setS2Servo270(false)
            setS2Servo360(false)
        }

        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`s2ServoChk${value.id}`, false)
                    sessionStorage.setItem(`s2Servo${value.id}`, 0)
                }
            })
        }
    }
    const handleS2Servo270Toggle = () => {
        if (!s2) {
            setToastMsg('Enable S2 to set Serial Servo270')
            setToastActive(true)
            disableToast()
            return
        }
        setS2Servo270(!s2Servo270)
        if (!s2Servo270) {
            setS2Servo(false)
            setS2Servo270(true)
            setS2Servo360(false)
        }
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`s2ServoChk${value.id}`, false)
                    sessionStorage.setItem(`s2Servo${value.id}`, 0)
                }
            })
        }
    }
    const handleS2Servo360Toggle = () => {
        if (!s2) {
            setToastMsg('Enable S2 to set Serial Servo360')
            setToastActive(true)
            disableToast()
            return
        }
        setS2Servo360(!s2Servo360)
        if (!s2Servo360) {
            setS2Servo(false)
            setS2Servo270(false)
            setS2Servo360(true)
        }
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`s2ServoChk${value.id}`, false)
                    sessionStorage.setItem(`s2Servo${value.id}`, 0)
                }
            })
        }
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }
    return (
        <div className="Digital">
            <ProgramHeader
                data={{
                    options: [
                        'Select Ports',
                        'Input/Output',
                        'Digital/Analog',
                        'Flowchart',
                    ],
                    selected: 2,
                }}
                showSave={true}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />{' '}
            <div className={styles.MainContainerInputs}>
                {!isPCv1 && (
                    <div className={styles.CenterImg}>
                        <img
                            className={styles.ImgPcv0}
                            src={renderPrgImage('pcImg')}
                        />
                        <div className={styles.deviceContainer}>
                            {isEyeLeft ? (
                                <img
                                    src={renderPrgImage('internalEYEActive')}
                                    className={styles.imgStyleEyeL}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internalEYEInActive')}
                                    className={styles.imgStyleEyeL}
                                />
                            )}
                            {isEyeRight ? (
                                <img
                                    src={renderPrgImage('internalEYEActive')}
                                    className={styles.imgStyleEyeR}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internalEYEInActive')}
                                    className={styles.imgStyleEyeR}
                                />
                            )}
                            {isSmileOne ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth1}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth1}
                                />
                            )}

                            {isSmileTwo ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth2}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth2}
                                />
                            )}

                            {isSmileThree ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth3}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth3}
                                />
                            )}

                            {isSmileFour ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth4}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth4}
                                />
                            )}

                            {isDistanceSensors ||
                            isColorSensor ||
                            isGestureSensor ||
                            isLightSensor ? (
                                <img
                                    src={renderPrgImage('internal4in1Active')}
                                    className={styles.imgStyle4in1}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internal4in1InActive')}
                                    className={styles.imgStyle4in1}
                                />
                            )}

                            {isMic ? (
                                <img
                                    src={renderPrgImage('internalMicActive')}
                                    className={styles.imgStyleMic}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internalMicInActive')}
                                    className={styles.imgStyleMic}
                                />
                            )}
                            {isbuzzer ? (
                                <img
                                    src={renderPrgImage('internalBuzzerActive')}
                                    className={styles.imgStyleBuzzer}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalBuzzerInActive'
                                    )}
                                    className={styles.imgStyleBuzzer}
                                />
                            )}

                            {isTouchZero ? (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsActive'
                                    )}
                                    className={styles.imgStyleTouchpads1}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsInActive'
                                    )}
                                    className={styles.imgStyleTouchpads1}
                                />
                            )}
                            {isTouchOne ? (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsActive'
                                    )}
                                    className={styles.imgStyleTouchpads2}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsInActive'
                                    )}
                                    className={styles.imgStyleTouchpads2}
                                />
                            )}

                            {isTouchTwo ? (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsActive'
                                    )}
                                    className={styles.imgStyleTouchpads3}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsInActive'
                                    )}
                                    className={styles.imgStyleTouchpads3}
                                />
                            )}
                        </div>
                    </div>
                )}

                {isPCv1 && (
                    <div className={styles.Pcv1CenterImg}>
                        <img
                            className={styles.ImgPcv1}
                            src={renderPrgImage('PCv1')}
                        />
                        <div className={styles.deviceContainer}>
                            <div>
                                {[26.8, 32.8, 38.8, 45, 50.5, 57].map(
                                    (value, index) => {
                                        return (
                                            <>
                                                <img
                                                    className={
                                                        styles.isPCv1LeftEye
                                                    }
                                                    src={
                                                        isInternalEye
                                                            ? renderPrgImage(
                                                                  'PcinternalEYEActive_v1'
                                                              )
                                                            : renderPrgImage(
                                                                  'PcinternalEYEInActive_v1'
                                                              )
                                                    }
                                                    style={{
                                                        left: `${value}%`,
                                                    }}
                                                    draggable="false"
                                                />
                                                <img
                                                    className={
                                                        styles.isPCv1RightEye
                                                    }
                                                    src={
                                                        isInternalEye
                                                            ? renderPrgImage(
                                                                  'PcinternalEYEActive_v1'
                                                              )
                                                            : renderPrgImage(
                                                                  'PcinternalEYEInActive_v1'
                                                              )
                                                    }
                                                    style={{
                                                        left: `${value}%`,
                                                    }}
                                                    draggable="false"
                                                />
                                            </>
                                        )
                                    }
                                )}
                            </div>

                            <img
                                className={styles.isPCv1Teeth1}
                                src={
                                    isSmileOne
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />
                            <img
                                className={styles.isPCv1Teeth2}
                                src={
                                    isSmileTwo
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Teeth3}
                                src={
                                    isSmileThree
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Teeth4}
                                src={
                                    isSmileFour
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Teeth5}
                                src={
                                    isDistanceSensors ||
                                    isColorSensor ||
                                    isGestureSensor ||
                                    isLightSensor
                                        ? renderPrgImage(
                                              'Pcinternal4in1Active_v1'
                                          )
                                        : renderPrgImage(
                                              'Pcinternal4in1InActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Mic}
                                src={
                                    isMic
                                        ? renderPrgImage('internalMicActive')
                                        : renderPrgImage('internalMicInActive')
                                }
                            />
                            <img
                                className={styles.isPCv1Buzzer}
                                src={
                                    isbuzzer
                                        ? renderPrgImage('internalBuzzerActive')
                                        : renderPrgImage(
                                              'internalBuzzerInActive'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1TouchZero}
                                style={{
                                    background:
                                        isTouchZero || isTouchZeroOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />

                            <img
                                className={styles.isPCv1TouchOne}
                                style={{
                                    background:
                                        isTouchOne || isTouchOneOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />

                            <img
                                className={styles.isPCv1TouchTwo}
                                style={{
                                    background:
                                        isTouchTwo || isTouchTwoOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />

                            <img
                                className={styles.isPCv1TouchThree}
                                style={{
                                    background:
                                        isTouchThree || isTouchThreeOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />
                        </div>
                    </div>
                )}
                <div
                    className={`page2-content ${
                        isAnimating ? 'slide-out2' : ''
                    }`}
                    style={{ width: '100vw' }}
                >
                    {' '}
                    <div className={styles.Digital_ports_Container}>
                        <LeftToggleSection
                            bttnColor={bttnColor}
                            bttnColor2={bttnColor2}
                            A1DIGI={A1DIGI}
                            A2DIGI={A2DIGI}
                            B1DIGI={B1DIGI}
                            B2DIGI={B2DIGI}
                            E1DIGI={E1DIGI}
                            E2DIGI={E2DIGI}
                            M1DIGI={M1DIGI}
                            M2DIGI={M2DIGI}
                            ServoA1={ServoA1}
                            ServoA2={ServoA2}
                            ServoB1={ServoB1}
                            ServoB2={ServoB2}
                            ServoE1={ServoE1}
                            ServoE2={ServoE2}
                            Servo360A1={Servo360A1}
                            Servo360A2={Servo360A2}
                            Servo360B1={Servo360B1}
                            Servo360B2={Servo360B2}
                            Servo360E1={Servo360E1}
                            Servo360E2={Servo360E2}
                            Servo270A1={Servo270A1}
                            Servo270A2={Servo270A2}
                            Servo270B1={Servo270B1}
                            Servo270B2={Servo270B2}
                            Servo270E1={Servo270E1}
                            Servo270E2={Servo270E2}
                            a1Digi={a1Digi}
                            a2Digi={a2Digi}
                            b1Digi={b1Digi}
                            b2Digi={b2Digi}
                            e1Digi={e1Digi}
                            e2Digi={e2Digi}
                            m1Digi={m1Digi}
                            m2Digi={m2Digi}
                            a1Servo={a1Servo}
                            a1Servo360={a1Servo360}
                            a2Servo360={a2Servo360}
                            a2Servo={a2Servo}
                            b1Servo={b1Servo}
                            b2Servo={b2Servo}
                            b1Servo360={b1Servo360}
                            b2Servo360={b2Servo360}
                            e1Servo={e1Servo}
                            e2Servo={e2Servo}
                            e1Servo360={e1Servo360}
                            e2Servo360={e2Servo360}
                            m1Servo={m1Servo}
                            s1Servo={s1Servo}
                            s1Servo360={s1Servo360}
                            a1Servo270={a1Servo270}
                            a2Servo270={a2Servo270}
                            b1Servo270={b1Servo270}
                            b2Servo270={b2Servo270}
                            e1Servo270={e1Servo270}
                            e2Servo270={e2Servo270}
                            s1Servo270={s1Servo270}
                            s2Servo270={s2Servo270}
                            toggleA1={toggleA1}
                            toggleA2={toggleA2}
                            toggleB1={toggleB1}
                            toggleB2={toggleB2}
                            toggleE1={toggleE1}
                            toggleE2={toggleE2}
                            toggleM1={toggleM1}
                            toggleM2={toggleM2}
                            handleS1Servo360Toggle={handleS1Servo360Toggle}
                            handleS1Servo270Toggle={handleS1Servo270Toggle}
                            handleS1ServoToggle={handleS1ServoToggle}
                            isSmileOne={isSmileOne}
                            isSmileTwo={isSmileTwo}
                            isPCv1={isPCv1}
                            M1Servo={M1Servo}
                            s1={s1}
                            s2={s2}
                            s2Servo={s2Servo}
                            handleS2ServoToggle={handleS2ServoToggle}
                            handleS2Servo270Toggle={handleS2Servo270Toggle}
                            s2Servo360={s2Servo360}
                            handleS2Servo360Toggle={handleS2Servo360Toggle}
                        />

                        <RightToggleSection
                            bttnColor={bttnColor}
                            bttnColor2={bttnColor2}
                            C1DIGI={C1DIGI}
                            C2DIGI={C2DIGI}
                            D1DIGI={D1DIGI}
                            D2DIGI={D2DIGI}
                            F1DIGI={F1DIGI}
                            F2DIGI={F2DIGI}
                            M3DIGI={M3DIGI}
                            M4DIGI={M4DIGI}
                            c1Digi={c1Digi}
                            c2Digi={c2Digi}
                            d1Digi={d1Digi}
                            d2Digi={d2Digi}
                            f1Digi={f1Digi}
                            f2Digi={f2Digi}
                            m3Digi={m3Digi}
                            m4Digi={m4Digi}
                            c1Servo={c1Servo}
                            c2Servo={c2Servo}
                            d1Servo={d1Servo}
                            d2Servo={d2Servo}
                            f1Servo={f1Servo}
                            f2Servo={f2Servo}
                            c1Servo360={c1Servo360}
                            c2Servo360={c2Servo360}
                            d1Servo360={d1Servo360}
                            d2Servo360={d2Servo360}
                            f1Servo360={f1Servo360}
                            f2Servo360={f2Servo360}
                            c1Servo270={c1Servo270}
                            c2Servo270={c2Servo270}
                            d1Servo270={d1Servo270}
                            d2Servo270={d2Servo270}
                            f1Servo270={f1Servo270}
                            f2Servo270={f2Servo270}
                            Mp3M3={Mp3M3}
                            m3Mp3={m3Mp3}
                            Servo360C1={Servo360C1}
                            Servo360C2={Servo360C2}
                            Servo360D1={Servo360D1}
                            Servo360D2={Servo360D2}
                            Servo360F1={Servo360F1}
                            Servo360F2={Servo360F2}
                            Servo270C1={Servo270C1}
                            Servo270C2={Servo270C2}
                            Servo270D1={Servo270D1}
                            Servo270D2={Servo270D2}
                            Servo270F1={Servo270F1}
                            Servo270F2={Servo270F2}
                            ServoC1={ServoC1}
                            ServoC2={ServoC2}
                            ServoD1={ServoD1}
                            ServoD2={ServoD2}
                            ServoF1={ServoF1}
                            ServoF2={ServoF2}
                            toggleC1={toggleC1}
                            toggleC2={toggleC2}
                            toggleD1={toggleD1}
                            toggleD2={toggleD2}
                            toggleF1={toggleF1}
                            toggleF2={toggleF2}
                            toggleM3={toggleM3}
                            toggleM4={toggleM4}
                            isSmileFour={isSmileFour}
                            isSmileThree={isSmileThree}
                            isPCv1={isPCv1}
                        />
                    </div>
                </div>
            </div>
            <div className="SelectScreenBottom">
                <div className="bottom-child">
                    {/* <Link to="/programSelection"> */}
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    {/* </Link> */}

                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('nextBtn')}
                        onClick={next}
                    />
                </div>
            </div>
            <HelpScreen ishelpButton={ishelpButton} closeModal={closeModal} />
            <div
                className={`${styles['toast-msg']} ${
                    isToastActive
                        ? styles['toast-msg-active']
                        : styles['toast-msg-inactive']
                }`}
            >
                {toastMsg}
            </div>
        </div>
    )
}

// export default Digital;
const mapStateToProps = (state) => {
    console.log('mapStateToProps', state)

    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialConnect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Digital)
