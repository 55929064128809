import React, { Component } from 'react'
import InputOutput from '../../Components/Input/Input.js'

class Input extends Component {
    render() {
        return (
            <div>
                <InputOutput topbackbtnVisibility="false" />
            </div>
        )
    }
}

export default Input
