import React from 'react'

function DisplayComponent(props) {
    const h = () => {
        if (props.timer.h === 0) {
            return ''
        } else {
            return (
                <span>
                    {props.timer.h >= 10 ? props.timer.h : '0' + props.timer.h}
                </span>
            )
        }
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {h()}&nbsp;&nbsp;
            <span>
                {props.timer.m >= 10 ? props.timer.m : '0' + props.timer.m}
            </span>
            &nbsp;:&nbsp;
            <span>
                {props.timer.s >= 10 ? props.timer.s : '0' + props.timer.s}
            </span>
            &nbsp;:&nbsp;
            <span>
                {props.timer.ms >= 10 ? props.timer.ms : '0' + props.timer.ms}
            </span>
        </div>
    )
}

export default DisplayComponent
