import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import {
    readBytes,
    sendAndWaitForAck,
    sendAndWaitForAckForZing,
    sendBytes,
    utf16ToChar,
} from '../../ReusableComponents/ByteTransfer'
import renderPrgImage from '../../../source/programImg'
import MainHeader from '../../ReusableComponents/Header/MainHeader'
import { actionsToBytes } from './ActionsCreate'
import { ZING_AG_DATA } from './defaultData'

import style from './UploadAction.module.css'
let status = true
const SLOT_FILLED = 'T'

const INITIAL_FEEDBACK = {
    isOn: false,
    msg: '',
}

function UploadAction({ webSerial }) {
    const history = useHistory()

    const [feedback, setFeedback] = useState(INITIAL_FEEDBACK)
    const [selectedSlot, setSelectedSlot] = useState()
    const [zingSlotData, setZingSlotData] = useState()

    useEffect(async () => {
        fetchActionSlotData()
    }, [webSerial.isConnected])

    useEffect(() => {
        const id = setTimeout(() => {
            setFeedback({ isOn: false })
        }, 3000)
        return () => clearTimeout(id)
    }, [feedback])

    // This Function fetches Zing createAction slot data.
    const fetchActionSlotData = async () => {
        let response = await sendAndWaitForAckForZing(
            ['F'.charCodeAt(0), 'S'.charCodeAt(0)],
            5,
            webSerial.port
        )
        if (response.ok) {
            const zingSlotData = utf16ToChar(response.data)
            setZingSlotData(zingSlotData)
        }
    }

    const handleUpload = async () => {
        if (selectedSlot == null) {
            setFeedback({
                isOn: true,
                msg: 'Select a slot before uploading...',
            })
            return
        }

        setFeedback({
            isOn: true,
            msg: 'Uploading Action Group...',
        })

        const actionsData = JSON.parse(
            sessionStorage.getItem('createActionState')
        )

        const data = actionsToBytes(actionsData, selectedSlot + 1, 'ALLACTIONS')
        sendDataAsPackets(data, 43, webSerial.port)

        // const response = await readBytes(webSerial.port, 'SAVED')
        // if (response.ok && utf16ToChar(response.data).join('') === 'SAVED') {
        //     setFeedback({
        //         isOn: true,
        //         msg: 'Action Group saved successfully',
        //     })
        // }
    }

    // Due to buffer limitations in Zing, we disect the data and send each packet at an inteval of 0.1s.
    const timeoutPromise = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }
    const sendDataAsPackets = async (data, size, port) => {
        const actionLength = JSON.parse(
            sessionStorage.getItem('createActionState')
        ).allActions.length
        let count = 0
        while (data.length !== 0) {
            if (status) {
                let dataToBeSentNow = data.splice(0, size)
                sendBytes(dataToBeSentNow, port)
                count += 1
                status = false
                let replyOK
                try {
                    const resultPromise = (replyOK = await readBytes(
                        port,
                        count === actionLength ? 'SAVED' : 'OK'
                    ))
                    replyOK = Promise.race([
                        resultPromise,
                        new Promise((_, reject) =>
                            setTimeout(reject, 500, new Error('timeout'))
                        ),
                    ])
                    if (count === actionLength && replyOK) {
                        setFeedback({
                            isOn: true,
                            msg: 'Action Group saved successfully',
                        })
                    }
                    if (replyOK) {
                        status = true
                    }
                } catch (error) {
                    window.location.reload()
                }
            }
        }
        // while (data.length !== 0) {
        //     await new Promise((resolve) => {
        //         setTimeout(() => {
        //             let dataToBeSentNow = data.splice(0, size)
        //             resolve(sendBytes(dataToBeSentNow, port))
        //         }, 300)
        //     })
        // }
    }

    return (
        <>
            <MainHeader
                title="Choose the memory slot"
                goBack={() => {
                    history.push('/code/project/action/create')
                    sessionStorage.setItem('slideDirection', true)
                }}
                showBluetoothBtn={true}
            />

            <div className={'slide-left'}>
                {' '}
                <div className={style.container1}>
                    {zingSlotData ? (
                        zingSlotData.map((slotState, index) => {
                            let cardStyle = style.card
                            if (slotState === SLOT_FILLED)
                                cardStyle = style['card-red']
                            if (index === selectedSlot) {
                                cardStyle =
                                    cardStyle + ' ' + style['card-selected']
                            }
                            return (
                                <button
                                    key={index}
                                    className={cardStyle}
                                    onClick={() => setSelectedSlot(index)}
                                ></button>
                            )
                        })
                    ) : (
                        <div className={style.loader}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    )}
                </div>
            </div>

            <footer>
                {/* <br /> */}
                <img
                    style={{
                        width: '4rem',
                        height: '4rem',
                        visibility: 'hidden',
                    }}
                    src={renderPrgImage('backBtn')}
                />
                {feedback.isOn ? (
                    <p>{feedback.msg}</p>
                ) : (
                    <p>
                        You can save 5 custom actions on Zing. If you have more
                        than 5 actions created, <br />
                        replace an older action to save the new one on Zing's
                        memory.
                        <br />
                        Don't worry, all your actions will be saved under "Your
                        Action's tab."
                    </p>
                )}

                <img
                    style={{
                        width: '4rem',
                        height: '4rem',
                        cursor: 'pointer',
                    }}
                    src={renderPrgImage('uploadBtn')}
                    onClick={handleUpload}
                />
            </footer>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(UploadAction)
