import React, { Component } from 'react'
import { connect } from 'react-redux'
import { color_sensor } from '../../IOComponents'
import Checkbox from '../helpers/Checkbox'
import Slider from '../helpers/Slider'
import Select from '../helpers/Select'
import LogicSwitchComp from '../helpers/SwitchComp/LogicSwitchComp'
import IOComponents from '../../IOComponents'
import { rangeStoreVal } from '../../../Assembly/CheckboxData'
import LiveButton, {
    compName_character,
    livePortsNumber,
} from '../helpers/LiveButton'

var device = localStorage.getItem('biboxTypes')
var outputStyle

class SliderRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // isBuzzerFrequency: true,
            // isBuzzerTone: false,
            isLiveBtnActive: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        return
    }

    onhandleBuzzerFrequency = () => {
        const { state, onChange } = this.props.changeState

        // console.log('FREQUENCY')
        if (state.assignBuzzer) {
            state[`assignBuzzerFrequency`] = true
            state[`assignBuzzerTone`] = false
            state['valueBuzzerTone'] = 0

            rangeStoreVal['BuzzerFrequency'].isChecked = true
            rangeStoreVal['BuzzerTone'].isChecked = false
        } else {
            state[`assignBuzzerFrequency`] = false
            state[`assignBuzzerTone`] = false

            rangeStoreVal['BuzzerFrequency'].isChecked = false
            rangeStoreVal['BuzzerTone'].isChecked = false
        }

        onChange(state, 'hardware')
    }
    onhandleBiData1Value = () => {
        const { state, onChange } = this.props.changeState

        // console.log('FREQUENCY')
        if (state.assignBid1) {
            state[`assignBid1Value`] = true
            state[`assignBid1Assign`] = false
        } else {
            state[`assignBid1Value`] = false
            state[`assignBid1Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBiData1Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBid1) {
            state[`assignBid1Value`] = false
            state[`assignBid1Assign`] = true
        } else {
            state[`assignBid1Value`] = false
            state[`assignBid1Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBiData2Value = () => {
        const { state, onChange } = this.props.changeState

        // console.log('FREQUENCY')
        if (state.assignBid2) {
            state[`assignBid2Value`] = true
            state[`assignBid2Assign`] = false
        } else {
            state[`assignBid2Value`] = false
            state[`assignBid2Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBiData2Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBid2) {
            state[`assignBid2Value`] = false
            state[`assignBid2Assign`] = true
        } else {
            state[`assignBid2Value`] = false
            state[`assignBid2Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBuzzerTone = () => {
        const { state, onChange } = this.props.changeState
        // console.log('bUZZERTone123456')
        if (state.assignBuzzer) {
            // console.log('tonebuzzer')
            state[`assignBuzzerFrequency`] = false
            state[`assignBuzzerTone`] = true
            state['valueBuzzerFrequency'] = 0
            rangeStoreVal['BuzzerFrequency'].isChecked = false
            rangeStoreVal['BuzzerTone'].isChecked = true
        } else {
            // console.log('buzzerfalse')
            state[`assignBuzzerFrequency`] = false
            state[`assignBuzzerTone`] = false
            rangeStoreVal['BuzzerFrequency'].isChecked = false
            rangeStoreVal['BuzzerTone'].isChecked = false
        }
        onChange(state, 'hardware')
    }
    //playC 1058 - 0.1 PeeCee - Start Panel - Web Bugs;
    //btTx1
    onhandleBtTx1Value = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx1) {
            console.log('true condition1')
            state[`assignBtTx1Value`] = true
            state[`assignBtTx1Assign`] = false
        } else {
            state[`assignBtTx1Value`] = false
            state[`assignBtTx1Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBtTx1Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx1) {
            console.log('true condition')
            state[`assignBtTx1Value`] = false
            state[`assignBtTx1Assign`] = true
        } else {
            state[`assignBtTx1Value`] = false
            state[`assignBtTx1Assign`] = false
        }
        onChange(state, 'hardware')
    }
    //btTx2
    onhandleBtTx2Value = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx2) {
            console.log('true condition1')
            state[`assignBtTx2Value`] = true
            state[`assignBtTx2Assign`] = false
        } else {
            state[`assignBtTx2Value`] = false
            state[`assignBtTx2Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBtTx2Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx2) {
            console.log('true condition')
            state[`assignBtTx2Value`] = false
            state[`assignBtTx2Assign`] = true
        } else {
            state[`assignBtTx2Value`] = false
            state[`assignBtTx2Assign`] = false
        }
        onChange(state, 'hardware')
    }
    //btTx3
    onhandleBtTx3Value = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx3) {
            console.log('true condition1')
            state[`assignBtTx3Value`] = true
            state[`assignBtTx3Assign`] = false
        } else {
            state[`assignBtTx3Value`] = false
            state[`assignBtTx3Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBtTx3Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx3) {
            console.log('true condition')
            state[`assignBtTx3Value`] = false
            state[`assignBtTx3Assign`] = true
        } else {
            state[`assignBtTx3Value`] = false
            state[`assignBtTx3Assign`] = false
        }
        onChange(state, 'hardware')
    }
    //btTx4
    onhandleBtTx4Value = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx4) {
            console.log('true condition1')
            state[`assignBtTx4Value`] = true
            state[`assignBtTx4Assign`] = false
        } else {
            state[`assignBtTx4Value`] = false
            state[`assignBtTx4Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBtTx4Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx4) {
            console.log('true condition')
            state[`assignBtTx4Value`] = false
            state[`assignBtTx4Assign`] = true
        } else {
            state[`assignBtTx4Value`] = false
            state[`assignBtTx4Assign`] = false
        }
        onChange(state, 'hardware')
    }
    //btTx5
    onhandleBtTx5Value = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx5) {
            console.log('true condition1')
            state[`assignBtTx5Value`] = true
            state[`assignBtTx5Assign`] = false
        } else {
            state[`assignBtTx5Value`] = false
            state[`assignBtTx5Assign`] = false
        }
        onChange(state, 'hardware')
    }
    onhandleBtTx5Assign = () => {
        const { state, onChange } = this.props.changeState
        if (state.assignBtTx5) {
            console.log('true condition')
            state[`assignBtTx5Value`] = false
            state[`assignBtTx5Assign`] = true
        } else {
            state[`assignBtTx5Value`] = false
            state[`assignBtTx5Assign`] = false
        }
        onChange(state, 'hardware')
    }

    /* 
        send the live change for  the output components on slider change 

        */
    liveChange = (port, value) => {
        if (
            (sessionStorage.getItem('connectedDevice') == 'Ace' &&
                sessionStorage.getItem('deviceVersion')?.startsWith('1')) ||
            ['Roboki'].includes(sessionStorage.getItem('connectedDevice'))
        ) {
            let PortConnections = this.props.assembly.PortConnections
            if (
                this.props.isLiveBtnActive &&
                [
                    'led_1c',
                    'led',
                    'mini_geared_motor',
                    'dc_motor',
                    'dynamex_motor',
                    'dynamex_motor',
                    'servo_motor',
                    'servo_motor_270',
                    'servo_motor_360',
                ].includes(PortConnections[port].type)
            ) {
                if (PortConnections[port].type == 'servo_motor_270') {
                    value = Math.round(0.6667 * value)
                } else if (PortConnections[port].type == 'servo_motor_360') {
                    value = 100 + value
                }

                let data = ['L'.charCodeAt(0), 'W'.charCodeAt(0)]
                data.push(compName_character[PortConnections[port].type])
                data.push(livePortsNumber[port])
                data.push(value)

                this.props.worker.postMessage({
                    type: 'writeArray',
                    value: data,
                })
            }
        }
    }

    getLebelPort = (defaultport) => {
        if (
            sessionStorage.getItem('connectedDevice') === 'Roboki' &&
            (defaultport === 'C1' || defaultport === 'C2')
        ) {
            if (defaultport === 'C1') {
                return 'B1'
            } else {
                return 'B2'
            }
        } else {
            return defaultport
        }
    }
    getEyeLebel = (titel) => {
        if (sessionStorage.getItem('connectedDevice') === 'Roboki') {
            if ('RGB 1' === titel) {
                return 'Left Eye'
            } else {
                return 'Right Eye'
            }
        } else {
            return titel
        }
    }

    isLiveBtnStyle = (PortConnections, port) => {
        if (PortConnections && PortConnections[port]) {
            return [
                'led_1c',
                'led',
                'servo_motor',
                'servo_motor_270',
                'servo_motor_360',
            ].includes(PortConnections[port].type)
        } else return false
    }

    // for dc motor and dynamic motor live write value send the peecee board when mouse up
    onMouseUpHandel = async (value, port1, port2, name) => {
        if (value < 0) {
            if (name === 'DYNAMEX MOTOR')
                this.liveChange(port1, Math.round(Math.abs(value * 0.8)))
            else this.liveChange(port1, Math.abs(value))

            await new Promise((resolve) => setTimeout(resolve, 100))
            this.liveChange(port2, 0)
        } else if (value > 0) {
            if (name === 'DYNAMEX MOTOR')
                this.liveChange(port2, Math.round(value * 0.8))
            else this.liveChange(port2, value)
            await new Promise((resolve) => setTimeout(resolve, 100))
            this.liveChange(port1, 0)
        } else {
            this.liveChange(port2, 0)
            await new Promise((resolve) => setTimeout(resolve, 100))
            this.liveChange(port1, 0)
        }
    }

    //onLive Button click the send the bytes for dc motors
    liveBtnClick = async ({
        Btnvalue,
        onChange,
        port1,
        port2,
        value1,
        value2,
        name,
    }) => {
        onChange('liveBtn' + port1, Btnvalue)
        await new Promise((resolve) => setTimeout(resolve, 100))
        onChange('liveBtn' + port2, Btnvalue)

        if (Btnvalue) {
            await new Promise((resolve) => setTimeout(resolve, 100))

            if (name == 'DYNAMEX MOTOR')
                this.liveChange(port1, Math.round(Math.abs(value1 || 0) * 0.8))
            else this.liveChange(port1, Math.abs(value1 || 0))
            await new Promise((resolve) => setTimeout(resolve, 100))
            if (name == 'DYNAMEX MOTOR')
                this.liveChange(port2, Math.round(Math.abs(value2 || 0) * 0.8))
            else this.liveChange(port2, Math.abs(value2 || 0))
            this.props.setDisableAfterSettingByt(false)
        }
    }

    render() {
        // console.log('gsk', this.props)

        var {
            name,
            assign,
            port,
            value,
            onChange,
            isLiveBtnActive,
            handlecheckbox,
            getRangeVal,
            title,
            RGBkey,
            sliderType,
            port1,
            port2,
            sliderType,
            port1,
            port2,
            value1,
            value2,
            changeState,
        } = this.props
        if (assign == undefined) assign = false
        if (value == undefined) value = 0
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const deviceVersion = sessionStorage.getItem('deviceVersion')

        // var values = JSON.parse(sessionStorage.getItem("rangeValCheckbox"));
        // var Lb = values.LeftEye.B.value;
        // var Lg = values.LeftEye.G.value;
        // var Lr = values.LeftEye.R.value;
        // console.log("VALUES",Lr,Lg ,Lb);

        // if (assign == undefined) {//temporaray fix
        //   assign = true
        // }
        const { PortConnections } = this.props.assembly
        //Bidata edits
        let componentProps = this.props.concept.componentProps,
            internalAccessories = this.props.concept.internalaccessories,
            startState = this.props.logic.program[0].state
        let sourceOptionsOrder = [],
            sourceOptionsOrderOutput = [],
            sourceOptions = {},
            sourceOptionsBtTx = {},
            sourceOptionsOutput = {}

        sourceOptionsOrder.push('default')
        sourceOptionsOrderOutput.push('default')
        sourceOptions['default'] = 'Select an option'
        sourceOptionsBtTx['default'] = 'Select an option'
        sourceOptionsOutput['default'] = 'default'
        console.log(startState)
        Object.keys(startState).forEach((port) => {
            if (
                startState[`${port}`] &&
                port != 'btRx' &&
                port != 'usbrx' &&
                port != 'btTx'
            ) {
                if (['bic1', 'bic2', 'bif1', 'bif2'].includes(port)) {
                    sourceOptionsBtTx[`${port}`] = port
                }
                sourceOptionsOrder.push(port)
                sourceOptions[`${port}`] = port
            }
            if (port == 'btTx' && startState[`${port}`]) {
                for (let i = 1; i < 6; i++) {
                    sourceOptionsOrder.push(`${port}${i}`)
                    sourceOptions[`${port}${i}`] = `${port}${i}`
                }
            }
        })
        // console.log('startState', startState, sourceOptions)
        Object.keys(PortConnections).forEach((port) => {
            if (port != 'undefined' && PortConnections[port]) {
                if (!PortConnections[port]) return
                var type = PortConnections[port].type
                if (type == '4_in_1_sensor') {
                    //   var positionPorts = ["R", "G", "B", "D", "L", "M"];
                    //   for (var i = 0; i < positionPorts.length; i++) {
                    //     sourceOptionsOrder.push(positionPorts[i]);
                    //     sourceOptions[positionPorts[i]] =
                    //       componentProps[type].name + " \u2192 " + positionPorts[i];
                    //   }
                } else {
                    if (IOComponents[type].input && port.length != 1) {
                        sourceOptionsOrder.push(port)
                        sourceOptions[port] =
                            componentProps[type].name + ' \u2192 ' + port
                    }
                    if (IOComponents[type].output && port.length != 1) {
                        sourceOptionsOrderOutput.push(port)
                        sourceOptionsOutput[port] =
                            componentProps[type].name + ' \u2192 ' + port
                    }
                }
            }
        })

        Object.keys(internalAccessories).forEach((port) => {
            var type = port
            if (type == 'Four_in_one_sensor') {
                var positionPorts = [
                    'DistanceSensors',
                    'GestureSensor',
                    'LightSensor',
                    'ColorSensor',
                ]
                for (var i = 0; i < positionPorts.length; i++) {
                    if (
                        internalAccessories.Four_in_one_sensor[
                            `is${positionPorts[i]}`
                        ]
                    ) {
                        if (positionPorts[i] == 'ColorSensor') {
                            sourceOptionsOrder.push('RED')
                            sourceOptions['RED'] =
                                '4_in_1_sensor' + ' \u2192 ' + 'RED'
                            sourceOptionsOrder.push('GREEN')
                            sourceOptions['GREEN'] =
                                '4_in_1_sensor' + ' \u2192 ' + 'GREEN'
                            sourceOptionsOrder.push('BLUE')
                            sourceOptions['BLUE'] =
                                '4_in_1_sensor' + ' \u2192 ' + 'BLUE'
                        } else if (positionPorts[i] == 'DistanceSensors') {
                            sourceOptionsOrder.push('DistanceSensors')
                            sourceOptions[positionPorts[i]] =
                                '4_in_1_sensor' + ' \u2192 ' + 'Proximity'
                        } else {
                            sourceOptionsOrder.push(positionPorts[i])
                            sourceOptions[positionPorts[i]] =
                                '4_in_1_sensor' + ' \u2192 ' + positionPorts[i]
                        }
                    }
                }
            } else if (
                type === 'isAccelerometer' &&
                internalAccessories[`${type}`]
            ) {
                sourceOptionsOrder.push('ACCELEROMETER_X')
                sourceOptions['isAccelerometerX'] = ' Accelerometer X'
                sourceOptionsOrder.push('ACCELEROMETER_Y')
                sourceOptions['isAccelerometerY'] = ' Accelerometer Y'
                sourceOptionsOrder.push('ACCELEROMETER_Z')
                sourceOptions['isAccelerometerZ'] = ' Accelerometer Z'
                sourceOptionsOrder.push('GYRO_X')
                sourceOptions['isGyroX'] = ' Gyro X'
                sourceOptionsOrder.push('GYRO_Y')
                sourceOptions['isGyroY'] = ' Gyro Y'
                sourceOptionsOrder.push('GYRO_Z')
                sourceOptions['isGyroZ'] = ' Gyro Z'
            } else if (type != 'isFour_in_one_sensor') {
                if (
                    internalAccessories[`${type}`] &&
                    ![
                        'isTouchZeroOutput',
                        'isTouchOneOutput',
                        'isTouchTwoOutput',
                        'isTouchThreeOutput',
                        'isbuzzer',
                        'isSmileOne',
                        'isSmileTwo',
                        'isSmileThree',
                        'isSmileFour',
                        'isEYES',
                        'isRightEye',
                        'isLeftEye',
                    ].includes(type)
                    // type != 'isTouchZeroOutput' &&
                    // type != 'isTouchOneOutput' &&
                    // type != 'isTouchTwoOutput' &&
                    // type != 'isTouchThreeOutput' &&
                    // type != 'isbuzzer' &&
                    // type != 'isSmileOne' &&
                    // type != 'isSmileTwo' &&
                    // type != 'isSmileThree' &&
                    // type != 'isSmileFour' &&
                    // type != 'isEYES' &&
                    // type != 'isRightEye' &&
                    // type != 'isLeftEye'
                ) {
                    sourceOptionsOrder.push(port)
                    sourceOptions[port] = type.slice(2)
                } else if (
                    (type == 'isTouchZeroOutput' ||
                        type == 'isTouchOneOutput' ||
                        type == 'isTouchTwoOutput' ||
                        type == 'isTouchThreeOutput' ||
                        type == 'isbuzzer' ||
                        type == 'isSmileOne' ||
                        type == 'isSmileTwo' ||
                        type == 'isSmileThree' ||
                        type == 'isSmileFour' ||
                        type == 'isEYES') &&
                    internalAccessories[`${type}`]
                ) {
                    if (type == 'isEYES') {
                        for (let i = 1; i <= 12; i++) {
                            sourceOptionsOrderOutput.push(port + i)
                            sourceOptionsOutput[port + i] = 'Eye' + i
                        }
                    } else {
                        sourceOptionsOrderOutput.push(port)
                        sourceOptionsOutput[port] = type.slice(2)
                    }
                }
            }
        })
        var defaultport
        var min = this.props.min || 0

        var max = this.props.max || 255
        let length = Object.keys(sourceOptionsBtTx).length
        // NEW UI
        if (name == 'smile') {
            // console.log('SMILE DATA SLIDER>>>')

            // console.log('SMILE DATA SLIDER>>>', min, max)

            return (
                <div className={`hardwareInfo-section`}>
                    <div className="portDetails-hw">
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.name}
                            activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Slider
                            title="Intensity"
                            disabled={!assign}
                            value={value || 0}
                            min={min}
                            max={max}
                            onChange={(value, name) => {
                                // console.log('RANGE CLCIK')

                                getRangeVal(this.props.title, port, value)
                            }}
                            renderIn="hardwarePropertyPanel"
                        />
                        <p
                            style={{
                                position: 'absolute',
                                left: '34.5%',
                                // marginTop: '17%',
                                bottom: '-2%',
                            }}
                        >
                            {min}
                        </p>
                        <p
                            style={{
                                position: 'absolute',
                                right: '20%',
                                // marginTop: '17%',
                                bottom: '-2%',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            )
        }

        if (name == 'touchPadOutput') {
            return (
                <div className={`hardwareInfo-section`}>
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.title}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative' }}
                    >
                        <Slider
                            title="Intensity"
                            disabled={!assign}
                            value={value || 0}
                            min={min}
                            max={max}
                            onChange={(value, name) => {
                                // console.log('RANGE CLCIK')

                                getRangeVal(this.props.title, port, value)
                            }}
                            renderIn="hardwarePropertyPanel"
                        />
                        <p
                            style={{
                                position: 'absolute',
                                left: '34.5%',
                                // marginTop: '2%',
                                bottom: '2%',
                            }}
                        >
                            {min}
                        </p>
                        <p
                            style={{
                                position: 'absolute',
                                right: '20%',
                                // marginTop: '2%'
                                bottom: '-2%',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            )
        }
        if (name == 'bic') {
            return (
                <div className={`hardwareInfo-section`}>
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.title}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative' }}
                    >
                        <Slider
                            title="Intensity"
                            disabled={!assign}
                            value={value || 0}
                            min={min}
                            max={max}
                            onChange={(value, name) => {
                                // console.log('RANGE CLCIK')

                                getRangeVal(this.props.title, port, value)
                            }}
                            renderIn="hardwarePropertyPanel"
                        />
                        <p
                            style={{
                                position: 'absolute',
                                left: '33.5%',
                                marginTop: '2%',
                            }}
                        >
                            {min}
                        </p>
                        <p
                            style={{
                                position: 'absolute',
                                right: '20%',
                                marginTop: '2%',
                                // bottom: '-2%',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            )
        }
        if (name == 'bif') {
            return (
                <div className={`hardwareInfo-section`}>
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.title}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative' }}
                    >
                        <Slider
                            title="Intensity"
                            disabled={!assign}
                            value={value || 0}
                            min={min}
                            max={max}
                            onChange={(value, name) => {
                                // console.log('RANGE CLCIK')

                                getRangeVal(this.props.title, port, value)
                            }}
                            renderIn="hardwarePropertyPanel"
                        />
                        <p
                            style={{
                                position: 'absolute',
                                left: '33.5%',
                                marginTop: '2%',
                            }}
                        >
                            {min}
                        </p>
                        <p
                            style={{
                                position: 'absolute',
                                right: '12%',
                                marginTop: '2%',
                                // bottom: '-2%',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            )
        }
        if (name == 'usbtx') {
            return (
                <div className={`hardwareInfo-section`}>
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.title}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative' }}
                    >
                        <Slider
                            title="Intensity"
                            disabled={!assign}
                            value={value || 0}
                            min={min}
                            max={max}
                            onChange={(value, name) => {
                                // console.log('RANGE CLCIK')

                                getRangeVal(this.props.title, port, value)
                            }}
                            renderIn="hardwarePropertyPanel"
                        />
                        <p
                            style={{
                                position: 'absolute',
                                left: '33.5%',
                                marginTop: '2%',
                            }}
                        >
                            {min}
                        </p>
                        <p
                            style={{
                                position: 'absolute',
                                right: '12%',
                                marginTop: '2%',
                                // bottom: '-2%',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            )
        }
        // if (name == 'btTx') {
        //     return (
        //         <div className={`hardwareInfo-section`}>
        //             <div className="portDetails-hw" style={{}}>
        //                 <Checkbox
        //                     checked={assign || false}
        //                     onChange={() => {
        //                         handlecheckbox()
        //                     }}
        //                     label={this.props.title}
        //                 />
        //             </div>
        //             <div
        //                 className={`portSlider-hw  isActivehardwareInfo${assign}`}
        //                 style={{ position: 'relative' }}
        //             >
        //                 <Slider
        //                     title="Intensity"
        //                     disabled={!assign}
        //                     value={value || 0}
        //                     min={min}
        //                     max={max}
        //                     onChange={(value, name) => {
        //                         // console.log('RANGE CLCIK')

        //                         getRangeVal(this.props.title, port, value)
        //                     }}
        //                     renderIn="hardwarePropertyPanel"
        //                 />
        //                 <p
        //                     style={{
        //                         position: 'absolute',
        //                         left: '38%',
        //                         bottom: '-2%',
        //                     }}
        //                 >
        //                     {min}
        //                 </p>
        //                 <p
        //                     style={{
        //                         position: 'absolute',
        //                         right: '12%',
        //                         bottom: '-2%',
        //                     }}
        //                 >
        //                     {max}
        //                 </p>
        //             </div>
        //         </div>
        //     )
        // }
        //playC 1058 - 0.1 PeeCee - Start Panel - Web Bugs;
        if (name == 'BT TX 1') {
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            // onChange={(value) => onChange('assign' + port, value)}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={name}
                            activePort={port}
                        />
                    </div>

                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative', display: 'block' }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx1Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx1Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx1Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx1Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBtTx1Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    disabled={!assign}
                                    value={value || 0}
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                    title={
                                        port === 'SSTime'
                                            ? 'milliseconds'
                                            : 'Intensity'
                                    }
                                />
                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-2%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[
                              `assignBtTx1Assign`
                          ] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                {length > 0 ? (
                                    <select
                                        style={{
                                            height: '10vh',
                                            width: '100%',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            borderStyle: 'dotted',
                                            borderColor: '#e0dede',
                                            background: '#ebf6fa',
                                            fontSize: '18px',
                                        }}
                                        value={
                                            this.props.changeState.state[
                                                `valueBtTx1Assign`
                                            ]
                                        }
                                        onChange={(e) => {
                                            console.log(
                                                'changing',
                                                this.props.changeState
                                            )
                                            const { state, onChange } =
                                                this.props.changeState
                                            state[`valueBtTx1Assign`] =
                                                e.target.value
                                            onChange(state, 'hardware')
                                        }}
                                    >
                                        {Object.keys(sourceOptionsBtTx).map(
                                            (value, index) => (
                                                <option
                                                    key={index}
                                                    value={value}
                                                >
                                                    {sourceOptionsBtTx[value]}
                                                </option>
                                            )
                                        )}
                                    </select>
                                ) : (
                                    <div
                                        style={{
                                            height: '10vh',
                                            width: '100%',
                                            borderRadius: '10px',
                                            background: '#ebf6fa',
                                        }}
                                    ></div>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'BT TX 2') {
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            // onChange={(value) => onChange('assign' + port, value)}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={name}
                            activePort={port}
                        />
                    </div>

                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative', display: 'block' }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx2Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx2Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx2Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx2Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBtTx2Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    disabled={!assign}
                                    value={value || 0}
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                    title={
                                        port === 'SSTime'
                                            ? 'milliseconds'
                                            : 'Intensity'
                                    }
                                />
                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-2%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[
                              `assignBtTx2Assign`
                          ] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '10vh',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={
                                        this.props.changeState.state[
                                            `valueBtTx2Assign`
                                        ]
                                    }
                                    onChange={(e) => {
                                        console.log(
                                            'changing',
                                            this.props.changeState
                                        )
                                        const { state, onChange } =
                                            this.props.changeState
                                        state[`valueBtTx2Assign`] =
                                            e.target.value
                                        onChange(state, 'hardware')
                                    }}
                                >
                                    {Object.keys(sourceOptionsBtTx).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsBtTx[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'BT TX 3') {
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            // onChange={(value) => onChange('assign' + port, value)}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={name}
                            activePort={port}
                        />
                    </div>

                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative', display: 'block' }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx3Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx3Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx3Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx3Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBtTx3Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    disabled={!assign}
                                    value={value || 0}
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                    title={
                                        port === 'SSTime'
                                            ? 'milliseconds'
                                            : 'Intensity'
                                    }
                                />
                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-2%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[
                              `assignBtTx3Assign`
                          ] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '10vh',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={
                                        this.props.changeState.state[
                                            `valueBtTx3Assign`
                                        ]
                                    }
                                    onChange={(e) => {
                                        console.log(
                                            'changing',
                                            this.props.changeState
                                        )
                                        const { state, onChange } =
                                            this.props.changeState
                                        state[`valueBtTx3Assign`] =
                                            e.target.value
                                        onChange(state, 'hardware')
                                    }}
                                >
                                    {Object.keys(sourceOptionsBtTx).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsBtTx[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'BT TX 4') {
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            // onChange={(value) => onChange('assign' + port, value)}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={name}
                            activePort={port}
                        />
                    </div>

                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative', display: 'block' }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx4Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx4Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx4Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx4Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBtTx4Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    disabled={!assign}
                                    value={value || 0}
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                    title={
                                        port === 'SSTime'
                                            ? 'milliseconds'
                                            : 'Intensity'
                                    }
                                />
                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-2%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[
                              `assignBtTx4Assign`
                          ] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '10vh',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={
                                        this.props.changeState.state[
                                            `valueBtTx4Assign`
                                        ]
                                    }
                                    onChange={(e) => {
                                        console.log(
                                            'changing',
                                            this.props.changeState
                                        )
                                        const { state, onChange } =
                                            this.props.changeState
                                        state[`valueBtTx4Assign`] =
                                            e.target.value
                                        onChange(state, 'hardware')
                                    }}
                                >
                                    {Object.keys(sourceOptionsBtTx).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsBtTx[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'BT TX 5') {
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            // onChange={(value) => onChange('assign' + port, value)}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={name}
                            activePort={port}
                        />
                    </div>

                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative', display: 'block' }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx5Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx5Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBtTx5Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBtTx5Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBtTx5Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    disabled={!assign}
                                    value={value || 0}
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                    title={
                                        port === 'SSTime'
                                            ? 'milliseconds'
                                            : 'Intensity'
                                    }
                                />
                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-2%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[
                              `assignBtTx5Assign`
                          ] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '10vh',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={
                                        this.props.changeState.state[
                                            `valueBtTx5Assign`
                                        ]
                                    }
                                    onChange={(e) => {
                                        console.log(
                                            'changing',
                                            this.props.changeState
                                        )
                                        const { state, onChange } =
                                            this.props.changeState
                                        state[`valueBtTx5Assign`] =
                                            e.target.value
                                        onChange(state, 'hardware')
                                    }}
                                >
                                    {Object.keys(sourceOptionsBtTx).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsBtTx[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'LeftEye') {
            // console.log('gkaay', this.props, this.state)
            // if (this.props.port == "R") {
            //   value = this.props.valR;
            // }
            // if (this.props.port == "B") {
            //   value = this.props.valB;
            // }
            // if (this.props.port == "G") {
            //   value = this.props.valG;
            // }
            return (
                <div className="hardwareInfo-section">
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.title}
                            // activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{
                            position: 'relative',
                            height: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valR || 0}
                                min={min}
                                max={max}
                                onChange={(value, name) => {
                                    // console.log('RANGE CLCIK')
                                    getRangeVal(this.props.title, 'R', value)
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="R"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valG || 0}
                                min={min}
                                max={max}
                                onChange={(value, name) => {
                                    // console.log('RANGE CLCIK')

                                    getRangeVal(this.props.title, 'G', value)
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="G"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>{' '}
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valB || 0}
                                min={min}
                                max={max}
                                onChange={(value, name) => {
                                    // console.log('RANGE CLCIK')/
                                    getRangeVal(this.props.title, 'B', value)
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="B"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '12%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }

        if (name == 'RightEye') {
            return (
                <div className="hardwareInfo-section">
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox(RGBkey)
                            }}
                            label={this.getEyeLebel(title)}
                            // activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{
                            position: 'relative',
                            height: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valR || 0}
                                min={min}
                                max={max}
                                onChange={(value, name) => {
                                    // console.log('RANGE CLCIK')
                                    getRangeVal(this.props.title, 'R', value)
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="R"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valG || 0}
                                min={min}
                                max={max}
                                onChange={(value, name) => {
                                    // console.log('RANGE CLCIK')

                                    getRangeVal(this.props.title, 'G', value)
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="G"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>{' '}
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valB || 0}
                                min={min}
                                max={max}
                                onChange={(value, name) => {
                                    // console.log('RANGE CLCIK')
                                    getRangeVal(this.props.title, 'B', value)
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="B"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
        if (name == 'RGBComp') {
            // if (this.props.port == "R") {
            //   value = this.props.valR;
            // }
            // if (this.props.port == "B") {
            //   value = this.props.valB;
            // }
            // if (this.props.port == "G") {
            //   value = this.props.valG;
            // }
            return (
                <div className="hardwareInfo-section">
                    <div className="portDetails-hw" style={{}}>
                        <Checkbox
                            checked={assign}
                            onChange={() => {
                                handlecheckbox(this.props.count)
                            }}
                            label={this.props.title}
                            // activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{
                            position: 'relative',
                            height: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valR || 0}
                                min={min}
                                max={max}
                                onChange={(value) => {
                                    getRangeVal(this.props.count, value, 'R')
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="R"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valG || 0}
                                min={min}
                                max={max}
                                onChange={(value) => {
                                    getRangeVal(this.props.count, value, 'G')
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="G"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>{' '}
                        <div
                            style={{
                                position: 'relative',
                                height: 'auto',
                                width: '100%',
                            }}
                        >
                            <Slider
                                title=""
                                disabled={!assign}
                                value={this.props.valB || 0}
                                min={min}
                                max={max}
                                onChange={(value) => {
                                    getRangeVal(this.props.count, value, 'B')
                                }}
                                renderIn="hardwarePropertyPanel"
                                sliderName="B"
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    left: '33.5%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {min}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    right: '20%',
                                    marginTop: '2%',
                                    // bottom: '-17%',
                                }}
                            >
                                {max}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
        if (name == 'Buzzer') {
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.name}
                            activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign} `}
                        style={{
                            position: 'relative',
                            display: 'block',
                        }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBuzzerFrequency`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBuzzerFrequency}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Frequency</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBuzzerTone`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBuzzerTone}
                            >
                                <p1>Tone</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[
                            `assignBuzzerFrequency`
                        ] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    title="Intensity"
                                    disabled={!assign}
                                    value={this.props.valueBuzzerFrequency || 0}
                                    min={min}
                                    max={max}
                                    onChange={(value) => {
                                        // console.log('RANGE CLCIK')
                                        getRangeVal(
                                            this.props.title,
                                            'Frequency',
                                            value
                                        )
                                    }}
                                    renderIn="hardwarePropertyPanel"
                                />

                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                        // bottom: '-9%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-9%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[`assignBuzzerTone`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    title="Intensity"
                                    disabled={!assign}
                                    value={this.props.valueBuzzerTone || 0}
                                    min={0}
                                    max={5}
                                    onChange={(value) => {
                                        // console.log('RANGE CLCIK')
                                        getRangeVal(
                                            this.props.title,
                                            'Tone',
                                            value
                                        )
                                    }}
                                    renderIn="hardwarePropertyPanel"
                                />

                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '38%',
                                        top: '2%',
                                        // bottom: '0%',
                                    }}
                                >
                                    {0}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '12%',
                                        // bottom: '0%',
                                    }}
                                >
                                    {5}
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'BI Data 1') {
            let bid1SourceOptions = { ...sourceOptions }
            delete bid1SourceOptions.bid1
            if (Object.keys(bid1SourceOptions).length <= 2)
                delete bid1SourceOptions.default
            console.log('start', startState)

            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.name}
                            activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign} `}
                        style={{
                            position: 'relative',
                            display: 'block',
                        }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBid1Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBiData1Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBid1Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBiData1Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBid1Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    title="Intensity"
                                    disabled={!assign}
                                    value={
                                        this.props.changeState.state[
                                            `valueBid1`
                                        ] || 0
                                    }
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                />

                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                        // bottom: '-9%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[`assignBid1Assign`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                {Object.keys(bid1SourceOptions).length > 0 ? (
                                    <select
                                        style={{
                                            height: '10vh',
                                            width: '100%',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            borderStyle: 'dotted',
                                            borderColor: '#e0dede',
                                            background: '#ebf6fa',
                                            fontSize: '18px',
                                        }}
                                        value={
                                            this.props.changeState.state[
                                                `valueBid1Assign`
                                            ]
                                        }
                                        onChange={(e) => {
                                            console.log(
                                                'changing',
                                                this.props.changeState
                                            )
                                            const { state, onChange } =
                                                this.props.changeState
                                            state[`valueBid1Assign`] =
                                                e.target.value
                                            onChange(state, 'hardware')
                                        }}
                                    >
                                        {Object.keys(bid1SourceOptions).map(
                                            (value, index) => (
                                                <option
                                                    key={index}
                                                    value={value}
                                                >
                                                    {bid1SourceOptions[value]}
                                                </option>
                                            )
                                        )}
                                    </select>
                                ) : (
                                    <div
                                        style={{
                                            height: '10vh',
                                            width: '100%',
                                            borderRadius: '10px',
                                            background: '#ebf6fa',
                                        }}
                                    ></div>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }
        if (name == 'BI Data 2') {
            let bid2SourceOptions = { ...sourceOptions }
            delete bid2SourceOptions.bid2
            if (Object.keys(bid2SourceOptions).length <= 2)
                delete bid2SourceOptions.default
            console.log('assign', bid2SourceOptions)
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.name}
                            activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign} `}
                        style={{
                            position: 'relative',
                            display: 'block',
                        }}
                    >
                        <div
                            className=""
                            style={{
                                width: '90%',
                                height: '28%',
                                position: 'absolute',
                                top: '20%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                borderRadius: '10px',
                                background: '#fff',
                                display: 'flex',
                            }}
                        >
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBid2Value`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBiData2Value}
                            >
                                <p1 style={{ marginTop: '0.9%' }}>Value</p1>
                            </button>
                            <button
                                className={`activeBuzzer${
                                    this.props.changeState.state[
                                        `assignBid2Assign`
                                    ]
                                }`}
                                style={{
                                    width: '50%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                    outline: 'inherit',
                                }}
                                disabled={!assign}
                                onClick={this.onhandleBiData2Assign}
                            >
                                <p1>Assign</p1>
                            </button>
                        </div>
                        {this.props.changeState.state[`assignBid2Value`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <Slider
                                    title="Intensity"
                                    disabled={!assign}
                                    value={
                                        this.props.changeState.state[
                                            `valueBid2`
                                        ] || 0
                                    }
                                    min={min}
                                    max={max}
                                    onChange={(value) =>
                                        onChange('value' + port, value)
                                    }
                                    renderIn="hardwarePropertyPanel"
                                />

                                <p
                                    style={{
                                        position: 'absolute',
                                        left: '33.5%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {min}
                                </p>
                                <p
                                    style={{
                                        position: 'absolute',
                                        right: '20%',
                                        marginTop: '2%',
                                    }}
                                >
                                    {max}
                                </p>
                            </div>
                        ) : this.props.changeState.state[`assignBid2Assign`] ? (
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    top: '70%',
                                    left: '50%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                {Object.keys(bid2SourceOptions).length > 0 ? (
                                    <select
                                        style={{
                                            height: '10vh',
                                            width: '100%',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            borderStyle: 'dotted',
                                            borderColor: '#e0dede',
                                            background: '#ebf6fa',
                                            fontSize: '18px',
                                        }}
                                        value={
                                            this.props.changeState.state[
                                                `valueBid2Assign`
                                            ]
                                        }
                                        onChange={(e) => {
                                            const { state, onChange } =
                                                this.props.changeState
                                            state[`valueBid2Assign`] =
                                                e.target.value
                                            onChange(state, 'hardware')
                                        }}
                                    >
                                        {Object.keys(bid2SourceOptions).map(
                                            (value, index) => (
                                                <option
                                                    key={index}
                                                    value={value}
                                                >
                                                    {bid2SourceOptions[value]}
                                                </option>
                                            )
                                        )}
                                    </select>
                                ) : (
                                    <div
                                        style={{
                                            height: '10vh',
                                            width: '100%',
                                            borderRadius: '10px',
                                            background: '#ebf6fa',
                                        }}
                                    ></div>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            )
        }

        if (/Assign [1-5]/g.exec(name)) {
            let n = parseInt(name.split(' ')[1])
            let sourceOptionsInput = sourceOptions
            sourceOptionsInput['default'] = 'Input'
            sourceOptionsOutput['default'] = 'Output'
            delete sourceOptionsInput.asgn1
            delete sourceOptionsInput.asgn2
            delete sourceOptionsInput.asgn3
            delete sourceOptionsInput.asgn4
            delete sourceOptionsInput.asgn5
            return (
                <div
                    className="hardwareInfo-section"
                    style={{ height: '180px' }}
                >
                    <div className="portDetails-hw" style={{ height: '100px' }}>
                        <Checkbox
                            checked={assign || false}
                            onChange={() => {
                                handlecheckbox()
                            }}
                            label={this.props.name}
                            activePort={port}
                        />
                    </div>
                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign} `}
                        style={{
                            position: 'relative',
                            display: 'block',
                        }}
                    >
                        <div
                            style={{
                                width: '45%',
                                position: 'relative',
                                top: '50%',
                                left: '25%',
                                transform: `translate(-50%,-50%)`,
                            }}
                        >
                            <select
                                style={{
                                    height: '10vh',
                                    width: '100%',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    borderStyle: 'dotted',
                                    borderColor: '#e0dede',
                                    background: '#ebf6fa',
                                    fontSize: '18px',
                                }}
                                value={
                                    this.props.changeState.state[
                                        `valueAsgn${n}Output`
                                    ]
                                }
                                onChange={(e) => {
                                    const { state, onChange } =
                                        this.props.changeState
                                    if (e.target.value === 'Output')
                                        state[`valueAsgn${n}Output`] = null
                                    else
                                        state[`valueAsgn${n}Output`] =
                                            e.target.value
                                    onChange(state, 'hardware')
                                }}
                            >
                                {Object.keys(sourceOptionsOutput).map(
                                    (value, index) => (
                                        <option key={index} value={value}>
                                            {sourceOptionsOutput[value]}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <div
                            style={{
                                transform: `translate(98.5%,-50%)scale(2)`,
                            }}
                        >
                            <p>=</p>
                        </div>
                        <div
                            style={{
                                width: '45%',
                                position: 'relative',
                                top: '-25%',
                                left: '75%',
                                transform: `translate(-50%,-50%)`,
                            }}
                        >
                            <select
                                style={{
                                    height: '10vh',
                                    width: '100%',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    borderStyle: 'dotted',
                                    borderColor: '#e0dede',
                                    background: '#ebf6fa',
                                    fontSize: '18px',
                                }}
                                value={
                                    this.props.changeState.state[
                                        `valueAsgn${n}Input`
                                    ]
                                }
                                onChange={(e) => {
                                    const { state, onChange } =
                                        this.props.changeState
                                    if (e.target.value == 'Input') {
                                        state[`valueAsgn${n}Input`] = null
                                    } else
                                        state[`valueAsgn${n}Input`] =
                                            e.target.value
                                    onChange(state, 'hardware')
                                }}
                            >
                                {Object.keys(sourceOptionsInput).map(
                                    (value, index) => (
                                        <option key={index} value={value}>
                                            {sourceOptionsInput[value]}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </div>
                </div>
            )
        }
        if (port == 'STPM' || name == 'STEPPER MOTOR') {
            if (port == 'STPM') {
                return (
                    <div>
                        {' '}
                        <tr className="hide" style={outputStyle}>
                            <td
                                style={{
                                    padding: '1%',
                                    textAlign: 'start',
                                    fontWeight: 'bold',
                                }}
                            >
                                <Checkbox
                                    checked={assign || false}
                                    onChange={() => {
                                        this.props.onChangeSTPM()
                                    }}
                                    label={name}
                                />
                            </td>
                        </tr>
                    </div>
                )
            } else if (name == 'STEPPER MOTOR') {
                return (
                    <div
                        style={{
                            height: '50px',
                            width: '100%',
                        }}
                    >
                        <div
                            style={
                                {
                                    // background: "green",
                                }
                            }
                        >
                            <LogicSwitchComp
                                ComponentName="STEPPER MOTOR"
                                title={port}
                                switchActionHandler={this.props.onChange}
                                checked={
                                    !this.props.isClickSTMP
                                        ? false
                                        : this.props.assign
                                }
                            />
                        </div>

                        {/* <div
              style={{
                backgroundColor: "red",
                width: "20%",
                display: "inline-block",
              }}
            >
              <h1>adasdassa</h1>
            </div> */}
                        {/* <div style={{ width: "100px" }}>
            </div> */}
                    </div>
                )
            }
        }

        if (device == '*SNI#') {
            if (port == 'B12') {
                defaultport = 'B'
            }

            if (port == 'C12') {
                defaultport = 'C'
            }
            if (port == 'G12') {
                defaultport = 'PB'
            } else if (port == 'F12') {
                defaultport = 'PD'
            } else if (port == 'AB') {
                defaultport = 'A2'
            } else if (port == 'AC') {
                defaultport = 'PC'
            } else {
                defaultport = port
            }
        } else {
            var connect = ['F', 'G', 'B12', 'B34', 'C12', 'C34']
            for (let i = 0; i < connect.length; i++) {
                if (PortConnections[connect[i]]) {
                    if (PortConnections[connect[i]].type == 'dual_splitter') {
                        if (port == 'A2') {
                            defaultport = 'A1'
                        } else if (port == 'A1') {
                            defaultport = 'A2'
                        } else if (port == 'A3') {
                            defaultport = 'A4'
                        } else if (port == 'A4') {
                            defaultport = 'A3'
                        } else if (port == 'F2') {
                            defaultport = 'F1'
                        } else if (port == 'F1') {
                            defaultport = 'F2'
                        } else if (port == 'G1') {
                            defaultport = 'G2'
                        } else if (port == 'G2') {
                            defaultport = 'G1'
                        } else if (port == 'B1') {
                            defaultport = 'B2'
                        } else if (port == 'B3') {
                            defaultport = 'B4'
                        } else {
                            defaultport = port
                        }
                    } else {
                        defaultport = port
                    }
                } else {
                    if (port == 'MOTOR1') {
                        defaultport = 'M1'
                    } else if (port == 'MOTOR2') {
                        defaultport = 'M2'
                    } else {
                        defaultport = port
                    }
                }
            }
        }
        //  else{
        //       defaultport = port;
        //       console.log("portportportport 111111",port);

        //       }

        // console.log('portportportport', defaultport)

        if (name == 'DUAL SPLITTER' || name == 'SERVO EXTENDER') {
            outputStyle = {
                verticalAlign: 'middle',
                color: '#FFF',
                // borderBottom: '2px solid grey',
                height: '72px',
                display: 'none',
            }
        } else {
            outputStyle = {
                verticalAlign: 'middle',
                color: '#FFF',
                // borderBottom: '2px solid grey',
                height: '72px',
                width: '100%',
            }
        }

        //get the each slider title
        const getSliderTitle = () => {
            if (port === 'SSTime') {
                return 'milliseconds'
            } else {
                if (name == 'MP3') {
                    return 'Value'
                } else {
                    return 'Intensity'
                }
            }
        }

        if (sliderType == 'motorSlider')
            return (
                <div className="hardwareInfo-section">
                    <div
                        className="portDetails-hw portDetails-hw_column"
                        style={{}}
                    >
                        <Checkbox
                            checked={assign || false}
                            onChange={async (value) => {
                                onChange('assign' + port1, value)
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 100)
                                )
                                onChange('assign' + port2, value)
                            }}
                            label={name}
                            activePort={defaultport}
                        />
                        {((connectedDevice == 'Ace' &&
                            deviceVersion?.startsWith('1')) ||
                            ['Roboki'].includes(connectedDevice)) && (
                            <LiveButton
                                isEnabled={assign || false}
                                liveBtnClick={(Btnvalue) =>
                                    this.liveBtnClick({
                                        Btnvalue,
                                        onChange,
                                        port1,
                                        port2,
                                        value1,
                                        value2,
                                        name,
                                    })
                                }
                                assign={assign}
                                isLiveBtnActive={isLiveBtnActive}
                            />
                        )}
                    </div>

                    <div
                        className={`portSlider-hw  isActivehardwareInfo${assign}`}
                        style={{ position: 'relative' }}
                    >
                        <Slider
                            disabled={!assign}
                            value={value1 || value2 || 0}
                            min={min}
                            max={max}
                            onChange={(value) => {
                                if (value < 0) {
                                    onChange('value' + port1, value)
                                    onChange('value' + port2, 0)
                                } else if (value > 0) {
                                    onChange('value' + port2, value)
                                    onChange('value' + port1, 0)
                                } else {
                                    onChange('value' + port1, 0)
                                    onChange('value' + port2, 0)
                                }
                            }}
                            onMouseUpHandel={(value) => {
                                this.onMouseUpHandel(value, port1, port2, name)
                            }}
                            renderIn="hardwarePropertyPanel"
                            title={
                                port === 'SSTime' ? 'milliseconds' : 'Intensity'
                            }
                        />
                        <p
                            style={{
                                position: 'absolute',
                                left: '34.5%',
                                // marginTop: '2%'
                                bottom: '-2%',
                            }}
                        >
                            {min}
                        </p>
                        <p
                            style={{
                                position: 'absolute',
                                right: '20%',
                                // marginTop: '2%'
                                bottom: '-2%',
                            }}
                        >
                            {max}
                        </p>
                    </div>
                </div>
            )

        return (
            <div className="hardwareInfo-section">
                <div
                    className={`portDetails-hw ${
                        this.isLiveBtnStyle(PortConnections, port)
                            ? 'portDetails-hw_column'
                            : ''
                    }`}
                >
                    <Checkbox
                        checked={assign || false}
                        onChange={(value) => onChange('assign' + port, value)}
                        label={name}
                        activePort={this.getLebelPort(defaultport)}
                        componentType={PortConnections?.[port]?.type}
                    />

                    {this.isLiveBtnStyle(PortConnections, port) &&
                        ((connectedDevice == 'Ace' &&
                            deviceVersion?.startsWith('1')) ||
                            ['Roboki'].includes(connectedDevice)) && (
                            <>
                                <LiveButton
                                    isEnabled={assign || false}
                                    liveBtnClick={async (Btnvalue) => {
                                        onChange('liveBtn' + port, Btnvalue)
                                        if (Btnvalue) {
                                            await new Promise((resolve) =>
                                                setTimeout(resolve, 200)
                                            )
                                            this.liveChange(port, value || 0)
                                            this.props.setDisableAfterSettingByt(
                                                false
                                            )
                                        }
                                    }}
                                    assign={assign}
                                    isLiveBtnActive={isLiveBtnActive}
                                />
                            </>
                        )}
                </div>

                <div
                    className={`portSlider-hw  isActivehardwareInfo${assign}`}
                    style={{ position: 'relative' }}
                >
                    <Slider
                        disabled={!assign}
                        value={value || 0}
                        min={min}
                        max={max}
                        onChange={(value) => {
                            onChange('value' + port, value)
                            this.liveChange(port, value)
                        }}
                        renderIn="hardwarePropertyPanel"
                        title={getSliderTitle()}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            left: '33.5%',
                            marginTop: '13%',
                        }}
                    >
                        {min}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            right: '22%',
                            marginTop: '13%',
                            // bottom: '-2%',
                        }}
                    >
                        {max}
                    </p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        PortConnections: (data) => {
            dispatch({ type: 'PORT_Connection', payload: data })
        },
        setDisableAfterSettingByt: (data) => {
            dispatch({ type: 'DISABLE_AFTER_SETTING_BYTE', payload: data })
        },
    }
}
SliderRow = connect(mapStateToProps, mapDispatchToProps)(SliderRow)
export default SliderRow

// export default SliderRow
