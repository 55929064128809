import React from 'react'
import DeviceSpeech from './tern+/speak'
import AceSpeech from './Ace/speak'

function Speech() {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    if (['Ace', 'Roboki'].includes(connectedDevice)) return <AceSpeech />
    return <DeviceSpeech />
}

export default Speech
