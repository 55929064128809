import React, { Component } from 'react'
import Slider, { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import '../../../index.css'

import renderPrgImage from '../../../source/programImg'

class Nouislider extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    //defaultProps this method is used to define default prop values
    static defaultProps = {
        onMouseUpHandel: () => {},
    }

    change = (e) => {
        this.props.onChange(e.target.value, e.target.name)
    }
    render() {
        const wrapperStyle = { width: 400, margin: 50 }
        var { range, disabled, step, value } = this.props

        const sliderStyle = {
            backgroundImage: `url("${
                this.props.sliderName == undefined
                    ? renderPrgImage('propertypanelSlider')
                    : this.props.sliderName.includes('R') &&
                      this.props.value > 0
                    ? renderPrgImage('propertypanelRedSlider')
                    : this.props.sliderName.includes('B') &&
                      this.props.value > 0
                    ? renderPrgImage('propertypanelBlueSlider')
                    : this.props.sliderName.includes('G') &&
                      this.props.value > 0
                    ? renderPrgImage('propertypanelGreenSlider')
                    : renderPrgImage('propertypanelInactiveSlider')
            }")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',

            // backgroundImage: `linear-gradient(to right, #B9B2D6, rgba(255,0,0,1))`,
        }

        return (
            <div>
                {/* OLD UI */}
                {/* <Slider
          className="slider-panel"
          min={range.min}
          max={range.max}
          steps={step}
          value={value}
          disabled={disabled}
          onChange={this.change}
        /> */}

                {/* NEW UI */}
                <input
                    className="slider-panel-input"
                    name={this.props.sliderName}
                    id="PanelsliderRange"
                    type="range"
                    value={value}
                    onChange={this.change}
                    onMouseUp={(e) =>
                        this.props.onMouseUpHandel(e.target.value)
                    }
                    min={range.min}
                    max={range.max}
                    disabled={disabled}
                    steps={step}
                    style={sliderStyle}
                />
            </div>
        )
    }
}

export default Nouislider
