import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Slider from './Slider'

import style from './SliderBox.module.css'
import IOComponents from '../../logic/IOComponents'
import LiveButton, {
    compName_character,
    klawLiveServoPortsNumber,
    livePortsNumber,
} from '../../logic/panels/helpers/LiveButton'
import { generateDeviceDataKey } from '../ByteTransfer/utils'
import { PORTDATA } from '../../Humanoid/defaultData'

function SliderBox(props) {
    const {
        id,
        servoId,
        btnText,
        sliderText,
        min,
        max,
        value,
        handleSliderChange,
        handleClick,
        liveServoFeedback,
        isEnabled,
        type,
        handelAssignButtonClick,
        dropDownValue,
        check,
        handelServoButtonClick,
        isLiveSActive,
        isDisabledAction,
        handleExternalDeviceLiveBtnClick = () => {},
        isLiveBtnActive = false,
        concept,
        setDisableAfterSettingByt = () => {},
    } = props
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    let deviceVersion = sessionStorage.getItem('deviceVersion')
    const [isLiveServoEnabled, setIsLiveServoEnabled] = useState(false)
    const [assignBid, setassignBid] = useState(false)
    const startState = props.logic.program[0].state
    let humanoidProgramData = {}
    let sessionPortData = {}

    try {
        humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )
    } catch (e) {
        console.log('ERROR', e)
    }
    try {
        sessionPortData = JSON.parse(sessionStorage.getItem('humanoidPortData'))
    } catch (e) {
        console.log('ERROR', e)
        let deviceKey = generateDeviceDataKey(connectedDevice, deviceVersion)
        if (
            [
                'HUMANOID0',
                'HUMANOID1',
                'HUMANOID2',
                'HEXAPOD1',
                'HEXAPOD2',
                'KLAW1',
            ].includes(deviceKey)
        ) {
            sessionPortData = structuredClone(PORTDATA[deviceKey])
        } else {
            sessionPortData = structuredClone(PORTDATA['HUMANOID0'])
        }
    }

    //handel bid button
    const onhandleBidValue = () => {
        if (isEnabled) {
            setassignBid(false)
            handelAssignButtonClick(id, assignBid)
        }
    }
    const onhandleBidAssign = () => {
        if (isEnabled) {
            setassignBid(true)
            handelAssignButtonClick(id, assignBid)
        }
    }

    //take input and output
    const { PortConnections } = props.assembly

    let componentProps = props.concept.componentProps,
        internalAccessories = props.concept.internalaccessories
    // let sstartState = props.logic.program[0].state
    let sourceOptionsOrder = [],
        sourceOptionsOrderOutput = [],
        sourceOptions = {},
        sourceOptionsOutput = {}
    sourceOptionsOrder.push('default')
    sourceOptionsOrderOutput.push('default')
    sourceOptions['default'] = 'default'
    sourceOptionsOutput['default'] = 'default'
    Object.keys(PortConnections).forEach((port) => {
        if (
            Object.keys(componentProps).length !== 0 &&
            componentProps !== 'undefined' &&
            port != 'undefined' &&
            PortConnections[port]
        ) {
            if (!PortConnections[port]) return
            var type = PortConnections[port].type
            if (type == '4_in_1_sensor') {
                //   var positionPorts = ["R", "G", "B", "D", "L", "M"];
                //   for (var i = 0; i < positionPorts.length; i++) {
                //     sourceOptionsOrder.push(positionPorts[i]);
                //     sourceOptions[positionPorts[i]] =
                //       componentProps[type].name + " \u2192 " + positionPorts[i];
                //   }
            } else {
                if (IOComponents[type].input && port.length != 1) {
                    sourceOptionsOrder.push(port)
                    sourceOptions[port] =
                        componentProps[type].name + ' \u2192 ' + port
                }
                if (IOComponents[type].output && port.length != 1) {
                    sourceOptionsOrderOutput.push(port)
                    sourceOptionsOutput[port] =
                        componentProps[type].name + ' \u2192 ' + port
                }
            }
        }
    })

    Object.keys(startState).forEach((port) => {
        if (
            startState[`${port}`] &&
            port != 'btRx' &&
            port != 'usbrx' &&
            port != 'btTx'
        ) {
            // console.log('gskk Selection', port)
            if (
                !(btnText == 'BiData1' && port == 'bid1') &&
                !(btnText == 'BiData2' && port == 'bid2')
            ) {
                sourceOptionsOrder.push(port)
                sourceOptions[`${port}`] = port
            }
        } else if (port == 'btTx') {
            for (let i = 1; i < 6; i++) {
                sourceOptionsOrder.push(`${port}${i}`)
                sourceOptions[`${port}${i}`] = `${port}${i}`
            }
        }
    })
    Object.keys(internalAccessories).forEach((port) => {
        var type = port
        if (type == 'Four_in_one_sensor') {
            var positionPorts = [
                'DistanceSensors',
                'GestureSensor',
                'LightSensor',
                'ColorSensor',
            ]
            for (var i = 0; i < positionPorts.length; i++) {
                if (
                    internalAccessories.Four_in_one_sensor[
                        `is${positionPorts[i]}`
                    ]
                ) {
                    if (positionPorts[i] == 'ColorSensor') {
                        sourceOptionsOrder.push('RED')
                        sourceOptions['RED'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'RED'
                        sourceOptionsOrder.push('GREEN')
                        sourceOptions['GREEN'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'GREEN'
                        sourceOptionsOrder.push('BLUE')
                        sourceOptions['BLUE'] =
                            '4_in_1_sensor' + ' \u2192 ' + 'BLUE'
                    } else {
                        sourceOptionsOrder.push(positionPorts[i])
                        sourceOptions[positionPorts[i]] =
                            '4_in_1_sensor' + ' \u2192 ' + positionPorts[i]
                    }
                }
            }
        } else if (
            type === 'isAccelerometer' &&
            internalAccessories[`${type}`]
        ) {
            sourceOptionsOrder.push('ACCELEROMETER_X')
            sourceOptions['isAccelerometerX'] = ' Accelerometer X'
            sourceOptionsOrder.push('ACCELEROMETER_Y')
            sourceOptions['isAccelerometerY'] = ' Accelerometer Y'
            sourceOptionsOrder.push('ACCELEROMETER_Z')
            sourceOptions['isAccelerometerZ'] = ' Accelerometer Z'
            sourceOptionsOrder.push('GYRO_X')
            sourceOptions['isGyroX'] = ' Gyro X'
            sourceOptionsOrder.push('GYRO_Y')
            sourceOptions['isGyroY'] = ' Gyro Y'
            sourceOptionsOrder.push('GYRO_Z')
            sourceOptions['isGyroZ'] = ' Gyro Z'
        } else if (type != 'isFour_in_one_sensor') {
            if (
                internalAccessories[`${type}`] &&
                ![
                    // 'isTouchZeroOutput',
                    // 'isTouchOneOutput',
                    // 'isTouchTwoOutput',
                    // 'isTouchThreeOutput',
                    // 'isbuzzer',
                    // 'isSmileOne',
                    // 'isSmileTwo',
                    // 'isSmileThree',
                    // 'isSmileFour',
                    'isActionGroup',
                    'isActionServo',
                    'isHeadServo',
                    'isZingmp3',
                    'isEYES',
                    'isEyeLeft',
                    'isEyeRight',
                    // 'isRightEye',
                    // 'isLeftEye',
                ].includes(type)
                // type != 'isTouchZeroOutput' &&
                // type != 'isTouchOneOutput' &&
                // type != 'isTouchTwoOutput' &&
                // type != 'isTouchThreeOutput' &&
                // type != 'isbuzzer' &&
                // type != 'isSmileOne' &&
                // type != 'isSmileTwo' &&
                // type != 'isSmileThree' &&
                // type != 'isSmileFour' &&
                // type != 'isEYES'
            ) {
                sourceOptionsOrder.push(port)
                sourceOptions[port] = type.slice(2)
            } else if (
                // type == 'isTouchZeroOutput' ||
                // type == 'isTouchOneOutput' ||
                // type == 'isTouchTwoOutput' ||
                // type == 'isTouchThreeOutput' ||
                (type == 'isbuzzer' ||
                    // type == 'isSmileOne' ||
                    // type == 'isSmileTwo' ||
                    // type == 'isSmileThree' ||
                    // type == 'isSmileFour' ||
                    type == 'isEYES') &&
                internalAccessories[`${type}`]
            ) {
                if (type == 'isEYES') {
                    for (let i = 1; i <= 12; i++) {
                        sourceOptionsOrderOutput.push(port + i)
                        sourceOptionsOutput[port + i] = 'Eye' + i
                    }
                } else {
                    sourceOptionsOrderOutput.push(port)
                    sourceOptionsOutput[port] = type.slice(2)
                }
            }
        }
    })

    //if typ===asign than take sourceOptionsInput
    let sourceOptionsInput = sourceOptions
    if (type === 'asign') {
        sourceOptionsInput['default'] = 'Input'
        sourceOptionsOutput['default'] = 'Output'
        delete sourceOptionsInput.asgn1
        delete sourceOptionsInput.asgn2
        delete sourceOptionsInput.asgn3
        delete sourceOptionsInput.asgn4
        delete sourceOptionsInput.asgn5
    }

    //for bttx assign input array
    let bttxassignArr = {}
    bttxassignArr['default'] = 'default'
    Object.keys(startState).forEach((port) => {
        if (
            startState[`${port}`] &&
            ![
                'btRx',
                'usbrx',
                'btTx',
                'bid1',
                'bid2',
                'asgn1',
                'asgn2',
                'asgn3',
                'asgn4',
                'asgn5',
                'btRte',
                'usbtx',
            ].includes(port)
        ) {
            bttxassignArr[`${port}`] = port
        }
    })

    useEffect(() => {
        if (type == 'bid' || type === 'bttx') {
            setassignBid(humanoidProgramData[check].state[id].value[3])
        }
    }, [id])

    //when the live button show or not condition find
    const isShowLiveBtn = (id) => {
        let assembly = props.assembly
        const portName = sessionPortData?.[id]?.name?.split(' ')?.[1]
        if (
            ['Klaw', 'Hexapod'].includes(connectedDevice) ||
            (connectedDevice == 'Humanoid' &&
                ['1', '2'].includes(deviceVersion?.[0]))
        ) {
            if (
                ['Klaw'].includes(connectedDevice) &&
                ['6', '7', '8', '9', '10'].includes(id)
            ) {
                return true
            }

            if (portName) {
                const externalDevice = assembly.PortConnections[portName]?.type
                return [
                    'led_1c',
                    'led',
                    'mini_geared_motor',
                    'dc_motor',
                    'dynamex_motor',
                    'servo_motor',
                    'servo_motor_270',
                    'servo_motor_360',
                ].includes(externalDevice)
            }
        } else {
            return false
        }
    }

    //live changes write the value
    const liveChangeByteSend = (value, portName, externalDevice) => {
        //handel the live change of external components
        if (
            ['Klaw'].includes(connectedDevice) &&
            ['6', '7', '8', '9', '10'].includes(id)
        ) {
            let newValue = Math.round(0.6 * value)

            let data = ['L'.charCodeAt(0), 'W'.charCodeAt(0)]
            data.push(klawLiveServoPortsNumber[id]) //compName_character[PortConnections[port].type]
            data.push('S'.charCodeAt(0)) //livePortsNumber[port]
            data.push(newValue)

            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        } else if (
            [
                'led_1c',
                'led',
                'mini_geared_motor',
                'dc_motor',
                'dynamex_motor',
                'servo_motor',
                'servo_motor_270',
                'servo_motor_360',
            ].includes(externalDevice)
        ) {
            if (PortConnections[portName].type == 'servo_motor_270') {
                value = Math.round(0.6667 * value)
            } else if (PortConnections[portName].type == 'servo_motor_360') {
                value = 100 + value
            }

            let data = ['L'.charCodeAt(0), 'W'.charCodeAt(0)]
            data.push(compName_character[externalDevice])
            data.push(livePortsNumber[portName])
            data.push(value)

            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        }
    }

    //handel the slider value change
    const onChangeSlider = (value, liveBtnValue) => {
        let assembly = props.assembly
        const portName = sessionPortData?.[id]?.name?.split(' ')?.[1]

        //handel the live change of each servo motor for humanoid and hexapod
        if (type == 'servo' && isLiveSActive) {
            liveServoFeedback(servoId, value)
        }

        if (portName) {
            const externalDevice = assembly.PortConnections?.[portName]?.type
            if (
                // ['A1', 'A2', 'B1', 'B2','C1','C2','D1', 'D2', 'M1', 'M2'].includes(portName) &&
                !['mini_geared_motor', 'dc_motor', 'dynamex_motor'].includes(
                    externalDevice
                ) &&
                (isLiveBtnActive || liveBtnValue)
            ) {
                liveChangeByteSend(value, portName, externalDevice)
            }
        }

        handleSliderChange(id, value)
    }

    //for dc motor onmouseup send the data
    const onMouseUpHandel = async (value, liveBtnValue) => {
        let assembly = props.assembly
        const portName = sessionPortData?.[id]?.name?.split(' ')?.[1]

        if (portName) {
            const externalDevice = assembly.PortConnections?.[portName]?.type
            if (
                // ['A1', 'A2', 'B1', 'B2','C1','C2','D1', 'D2', 'M1', 'M2'].includes(portName) &&
                ['mini_geared_motor', 'dc_motor', 'dynamex_motor'].includes(
                    externalDevice
                ) &&
                (isLiveBtnActive || liveBtnValue)
            ) {
                //for dynamex motor value map 0 to 80, for the hardwear issue

                let value1 = 0
                let value2 = 0
                if ('dynamex_motor' === externalDevice) {
                    if (value < 0) {
                        value2 = Math.round(Math.abs(value * 0.8))
                    } else if (value > 0) {
                        value1 = Math.round(Math.abs(value * 0.8))
                    }
                } else {
                    if (value < 0) {
                        value2 = Math.abs(value)
                    } else if (value > 0) {
                        value1 = Math.abs(value)
                    }
                }
                liveChangeByteSend(value1, portName, externalDevice)
                await new Promise((resolve) => setTimeout(resolve, 100))
                liveChangeByteSend(value2, portName[0] + '2', externalDevice)
            }
        }
    }

    return (
        <div className={style.container}>
            <div
                className={`${style.servoBtnGrp} ${
                    isShowLiveBtn(id) ? style.servoBtnGrp_1 : ''
                }`}
            >
                <button
                    className={isEnabled ? style.enabledBtn : style.disabledBtn}
                    onClick={() => {
                        if (concept?.disableAfterSettingByt) {
                            return
                        }
                        // if (servoId >= 1 && servoId <= 16) {
                        if (handelServoButtonClick) {
                            handelServoButtonClick(
                                servoId,
                                id,
                                'checkLiveServo'
                            )
                        }
                        // }
                        handleClick(id, assignBid)
                    }}
                    style={
                        isDisabledAction
                            ? {
                                  cursor: 'default',
                                  opacity: '0.5',
                                  height: isShowLiveBtn(id) && 'auto',
                                  minHeight: isShowLiveBtn(id) && '80px',
                              }
                            : {
                                  cursor: concept?.disableAfterSettingByt
                                      ? 'default'
                                      : 'pointer',
                                  opacity: concept?.disableAfterSettingByt
                                      ? '0.5'
                                      : '1',
                                  height: isShowLiveBtn(id) && 'auto',
                                  minHeight: isShowLiveBtn(id) && '80px',
                              }
                    }
                >
                    {btnText === 'M1' || btnText === 'M2' ? (
                        <>
                            Port {btnText} : <br /> Motor
                        </>
                    ) : (
                        btnText
                    )}
                </button>
                {isShowLiveBtn(id) && (
                    // <div>
                    <LiveButton
                        isEnabled={isEnabled || false}
                        assign={isEnabled}
                        disableAfterSettingByt={concept?.disableAfterSettingByt}
                        liveBtnClick={async (isLiveBtnActive) => {
                            handleExternalDeviceLiveBtnClick(
                                id,
                                isLiveBtnActive
                            )

                            //after sending the setting bytes wet for 2500 meli second. other waise it does not work for zing 2.0 and hexapod 2.0 does not work
                            if (isLiveBtnActive) {
                                // setDisableAfterSettingByt(true)
                                if (deviceVersion[0] === '2') {
                                    await new Promise((resolve) =>
                                        setTimeout(resolve, 2600)
                                    )
                                } else {
                                    await new Promise((resolve) =>
                                        setTimeout(resolve, 200)
                                    )
                                }
                                onChangeSlider(value, isLiveBtnActive)
                                onMouseUpHandel(value, isLiveBtnActive)
                                setDisableAfterSettingByt(false)
                            }
                        }}
                        isLiveBtnActive={isLiveBtnActive}
                        // style={{ border: '1px solid red' }}
                    />
                    // </div>
                )}
                {type == 'servo' ? (
                    <button
                        className={
                            isEnabled
                                ? isLiveSActive
                                    ? style.liveServoBtnOn
                                    : style.liveServoBtnOff
                                : style.disabledLiveServoBtn
                        }
                        onClick={() => {
                            liveServoFeedback(servoId, value)
                            handelServoButtonClick(servoId, id)
                            // setIsLiveServoEnabled(!isLiveServoEnabled)
                        }}
                        // onClick={() => liveServoFeedback(servoId, value)}
                    >
                        Live Servo
                    </button>
                ) : null}
            </div>

            <div
                style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                className={isEnabled ? style.enabledSlider : style.slider}
            >
                {(type === 'bid' || type === 'bttx') && isEnabled && (
                    <div
                        style={{
                            display: 'flex',
                            width: '80%',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                        }}
                    >
                        <button
                            className={
                                !assignBid ? style.bidBtnActive : style.bidBtn
                            }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                            }}
                            onClick={() => onhandleBidValue()}
                        >
                            Value
                        </button>
                        <button
                            className={
                                assignBid ? style.bidBtnActive : style.bidBtn
                            }
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                            }}
                            onClick={() => onhandleBidAssign()}
                        >
                            Assign
                        </button>
                    </div>
                )}
                {(type === 'bid' || type === 'bttx') && !isEnabled && (
                    <div
                        style={{
                            display: 'flex',
                            width: '80%',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                        }}
                    >
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                                color: 'rgb(0, 0, 0, 0.6)',
                            }}
                        >
                            Value
                        </button>
                        <button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: 'none',
                                width: '50%',
                                height: '100%',
                                borderRadius: '10px',
                                color: 'rgb(0, 0, 0, 0.6)',
                            }}
                        >
                            Assign
                        </button>
                    </div>
                )}

                <div className={style.slidercontainer}>
                    {(type === 'single' || type === 'servo') && (
                        <Slider
                            title={sliderText}
                            value={value}
                            min={min}
                            max={max}
                            renderIn="hardwarePropertyPanel"
                            onChange={(value) => {
                                onChangeSlider(value)
                            }}
                            onMouseUpHandel={onMouseUpHandel}
                            disabled={
                                !isEnabled ||
                                isDisabledAction ||
                                concept?.disableAfterSettingByt
                            }
                            // disabled={true}
                            sliderName={isEnabled ? null : ''}
                        />
                    )}
                    {(type === 'bid' || type === 'bttx') && !assignBid ? (
                        <div style={{ paddingTop: '30px' }}>
                            {
                                <Slider
                                    title={sliderText}
                                    value={
                                        humanoidProgramData[check].state[id]
                                            .value[2]
                                    }
                                    min={min}
                                    max={max}
                                    renderIn="hardwarePropertyPanel"
                                    onChange={(value) => {
                                        if (
                                            type == 'servo' &&
                                            isLiveServoEnabled
                                        )
                                            liveServoFeedback(servoId, value)
                                        handleSliderChange(id, value)
                                    }}
                                    disabled={!isEnabled}
                                    sliderName={isEnabled ? null : ''}
                                />
                            }
                        </div>
                    ) : (
                        (type === 'bid' || type === 'bttx') && (
                            <select
                                style={{
                                    height: '40px',
                                    width: '100%',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    borderStyle: 'dotted',
                                    borderColor: '#e0dede',
                                    background: '#ebf6fa',
                                    fontSize: '18px',
                                    marginTop: '30px',
                                }}
                                value={
                                    humanoidProgramData[check].state[id]
                                        .value[4]
                                }
                                onChange={(e) => {
                                    if (e.target.value == 'Input') {
                                        handleSliderChange(
                                            id,
                                            'default',
                                            'dropdown'
                                        )
                                    } else
                                        handleSliderChange(
                                            id,
                                            e.target.value,
                                            'dropdown'
                                        )
                                }}
                                disabled={!isEnabled}
                            >
                                {Object.keys(
                                    type === 'bid'
                                        ? sourceOptionsInput
                                        : bttxassignArr
                                ).map((value, index) => (
                                    <option key={index} value={value}>
                                        {sourceOptionsInput[value]}
                                    </option>
                                ))}
                            </select>
                        )
                    )}
                    {type === 'RGB' && (
                        <>
                            <Slider
                                title="Red"
                                value={value[0]}
                                min={min[0]}
                                max={max[0]}
                                renderIn="hardwarePropertyPanel"
                                onChange={(val) =>
                                    handleSliderChange(id, [
                                        val,
                                        value[1],
                                        value[2],
                                    ])
                                }
                                disabled={!isEnabled}
                                sliderName={isEnabled ? 'R' : ''}
                            />
                            <Slider
                                title="Green"
                                value={value[1]}
                                min={min[1]}
                                max={max[1]}
                                renderIn="hardwarePropertyPanel"
                                onChange={(val) =>
                                    handleSliderChange(id, [
                                        value[0],
                                        val,
                                        value[2],
                                    ])
                                }
                                disabled={!isEnabled}
                                sliderName={isEnabled ? 'G' : ''}
                            />
                            <Slider
                                title="Blue"
                                value={value[2]}
                                min={min[2]}
                                max={max[2]}
                                renderIn="hardwarePropertyPanel"
                                onChange={(val) =>
                                    handleSliderChange(id, [
                                        value[0],
                                        value[1],
                                        val,
                                    ])
                                }
                                disabled={!isEnabled}
                                sliderName={isEnabled ? 'B' : ''}
                            />
                        </>
                    )}
                </div>

                {type === 'asign' && (
                    <>
                        <div
                            style={{
                                position: 'relative',
                                display: 'block',
                                width: '100%',
                                height: '100px',
                            }}
                        >
                            <div
                                style={{
                                    width: '45%',
                                    position: 'relative',
                                    top: '40%',
                                    left: '25%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '40px',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={value[0]}
                                    // value={
                                    //     this.props.changeState.state[
                                    //         `valueAsgn${n}Output`
                                    //     ]
                                    // }
                                    onChange={(e) => {
                                        if (e.target.value === 'Output')
                                            handleSliderChange(
                                                id,
                                                'default',
                                                'output'
                                            )
                                        else
                                            handleSliderChange(
                                                id,
                                                e.target.value,
                                                'output'
                                            )
                                    }}
                                    disabled={!isEnabled}
                                >
                                    {Object.keys(sourceOptionsOutput).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsOutput[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                            <div
                                style={{
                                    transform: `translate(98.5%,-50%)scale(2)`,
                                }}
                            >
                                <p>=</p>
                            </div>
                            <div
                                style={{
                                    width: '45%',
                                    position: 'relative',
                                    top: '-40%',
                                    left: '77%',
                                    transform: `translate(-50%,-50%)`,
                                }}
                            >
                                <select
                                    style={{
                                        height: '40px',
                                        width: '100%',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        borderStyle: 'dotted',
                                        borderColor: '#e0dede',
                                        background: '#ebf6fa',
                                        fontSize: '18px',
                                    }}
                                    value={value[0]}
                                    // value={
                                    //     this.props.changeState.state[
                                    //         `valueAsgn${n}Input`
                                    //     ]
                                    // }
                                    onChange={(e) => {
                                        if (e.target.value == 'Input') {
                                            handleSliderChange(
                                                id,
                                                'default',
                                                'input'
                                            )
                                        } else
                                            handleSliderChange(
                                                id,
                                                e.target.value,
                                                'input'
                                            )
                                    }}
                                    disabled={!isEnabled}
                                >
                                    {Object.keys(sourceOptionsInput).map(
                                        (value, index) => (
                                            <option key={index} value={value}>
                                                {sourceOptionsInput[value]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        setDisableAfterSettingByt: (data) => {
            dispatch({ type: 'DISABLE_AFTER_SETTING_BYTE', payload: data })
        },
    }
}
// export default SliderBox
export default connect(mapStateToProps, mapDispatchToProps)(SliderBox)
