import React from 'react'

function OnlyNumberInput({ min, max, value, onChange }) {
    return (
        <input
            type="number"
            style={{
                border: 'none',
                borderRadius: '4rem',
                padding: '0.5rem',
                width: '4rem',
                textAlign: 'center',
            }}
            min={min}
            max={max}
            value={value}
            onChange={onChange}
        />
    )
}

export default OnlyNumberInput
