import closeBtn from '../../../../../../Assets/img/close.png'
import digitalBgimg from '../../../../../../Assets/img/digitalBgimg.png'

const HelpScreen = ({ ishelpButton, closeModal }) => {
    if (!ishelpButton) {
        return null
    }
    return (
        <>
            <div
                style={{
                    height: '100vh',
                    width: '100vw',
                    background: '#fff',
                    position: 'relative',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: '1000',
                    position: 'absolute',
                }}
            >
                <div
                    style={{
                        transform: 'translate(-50%,-50%)',
                        top: '47%',
                        left: '50%',
                        zIndex: '10',
                        position: 'absolute',
                        width: '65vw',
                    }}
                >
                    <img src={digitalBgimg} style={{ width: '100%' }} />
                    <div
                        style={{
                            // height: "90px",
                            width: '26%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '47%',
                            left: '28.9%',
                            background: '#fff',
                            fontSize: '22px',
                            borderRadius: '35px',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'white',
                                padding: '4px',
                                fontSize: '1.8vh',
                                textAlign: 'left',
                                color: '#707070',
                                fontFamily: 'Halcyon_Regular',
                                borderRadius: '12px',
                                marginBottom: '0px',
                            }}
                        >
                            Here you can enable whether the port should be
                            analog or digital in nature. You can enable the
                            ports as input or output- Analog or Digital
                        </p>
                    </div>
                </div>

                <div
                    onClick={closeModal}
                    style={{
                        borderRadius: '50%',
                        zIndex: '2000',
                        position: 'absolute',
                        top: '18%',
                        left: '81%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <img
                        src={closeBtn}
                        style={{ width: '7vh', height: 'auto' }}
                    />
                </div>
            </div>
        </>
    )
}
export default HelpScreen
