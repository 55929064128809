import React, { Component } from 'react'

import Checkbox from '../helpers/Checkbox'
import InputText from '../helpers/InputText'

class TextRow extends Component {
    constructor(props) {
        super(props)
        this.state = { count: 0 }
    }

    render() {
        const { assign, name, port, state, onChange, handlecheckbox } =
            this.props

        return (
            <div className={`hardwareInfo-section`}>
                <div className="portDetails-hw" style={{}}>
                    <Checkbox
                        checked={assign || false}
                        onChange={() => {
                            handlecheckbox()
                        }}
                        label={this.props.label}
                        activePort={port}
                    />
                </div>
                <div
                    className={`portSlider-hw  isActivehardwareInfo${assign}`}
                    style={{ position: 'relative' }}
                >
                    <input
                        type="text"
                        id={this.props.label}
                        autocomplete="off"
                        style={{
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            borderStyle: 'dotted',
                            borderColor: '#e0dede',
                            background: '#ebf6fa',
                            fontSize: '18px',
                        }}
                        placeholder="Enter Text Here (Upto 16 Characters)"
                        disabled={!assign}
                        maxlength="16"
                        value={this.props.textValue || ''}
                        onChange={(e) => {
                            this.props.handleTextChange(e)
                        }}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            right: '2%',
                            top: '5%',
                            fontSize: '10px',
                            color: 'grey',
                        }}
                    >
                        {this.props.textValue == undefined
                            ? 0
                            : this.props.textValue.length}
                        /16
                    </p>
                </div>
            </div>
        )
    }
}

// module.exports = TextRSow;
export default TextRow
