/**
 * @typedef WorkspaceComponentsData
 * @type object
 * @property {number} top   The top offset
 * @property {number} left   The left offset
 * @property {string} connectedTo   The port to which it is connected. It does not exist if not connected to any port
 */

/**
 * It has keys as component types for eg led
 * @example
 * {
 * 	 "led": [{top: 20, left: 80, connectedTo: 'A1'}, ...], ...
 * }
 * @typedef WorkspaceComponents
 * @type object
 * @property {WorkspaceComponentsData[]} ComponentType An array describing components of "ComponentType"
 */

/**
 * Describes a component in workspace. The last 3 properties may or may not be present.
 * (Different from {@link WorkspaceComponents})
 * @typedef AssemblyComponent
 * @type object
 * @property {string} type Type of the component
 * @property {?number} index Index of component in {@link WorkspaceComponents} if old component
 * @property {?number} left The left offset
 * @property {?number} top  The top offset
 * @property {?string} connectedTo Port connected to
 */

/**
 * This module is the workspace component of assembly tab and contains many functions for manipulation of the workspace object
 * This is a drop target.
 * @module components/assembly/Workspace
 */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ItemTypes from './ItemTypes'
import Modal from 'react-modal'

import { DropTarget } from 'react-dnd-old'
import DraggingInfo from './DraggingInfo'

// var cumulativeOffset  from './src/helpers/cumulativeOffset');

import Bibox from './Bibox'
import Component1 from './Component'
import renderPrgImage from '../../source/programImg'

var tutorialDesc = ''
//Custom Styles

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '28%',
        width: ' 30%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#9ecee8',
        zIndex: 1,
    },
}
var count = 0

const workspaceTarget = {
    drop(props, monitor, component) {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const { offset, scale } = props.workspace
        const { width, height } = props
        DraggingInfo.isDragging = false
        const type = monitor.getItemType()
        const item = monitor.getItem()
        var currentOffset
        if (
            monitor.getItemType() === ItemTypes.COMPONENT &&
            !DraggingInfo.draggingComponentOld
        ) {
            currentOffset = monitor.getClientOffset()
        } else currentOffset = monitor.getClientOffset()
        currentOffset.x -= document.body.clientWidth - width
        currentOffset.y -= document.body.clientHeight - height
        currentOffset.x = currentOffset.x / scale - offset.left
        currentOffset.y = currentOffset.y / scale - offset.top
        if (
            type === ItemTypes.COMPONENT &&
            !DraggingInfo.draggingComponentOld
        ) {
            if (document.getElementById('play_shield')) {
                //console.log("playsheild exists");
                return null
            }
            if (document.getElementById('arm_shield')) {
                //console.log("playsheild exists");
                return null
            }
            component.newComponent(item.type, currentOffset.x, currentOffset.y)
        } else {
            if (type === ItemTypes.BIBOX)
                component.moveBibox(currentOffset.x, currentOffset.y)
            else component.updateOldComponent(currentOffset.x, currentOffset.y)
        }
    },
}

let componentsDataSensor = JSON.parse(sessionStorage.getItem('concept')).counter

// var Workspace = React.createClass({
class Workspace extends Component {
    constructor(props) {
        super(props)
        this.workSpaceRef = React.createRef()
        this.boxRef = React.createRef()
        var tutorialPortArray = []
        var selectionType = localStorage.getItem('programMode')

        if (selectionType == 'learn') {
            tutorialDesc = JSON.parse(sessionStorage.getItem('tutorialPort'))
            // tutorialPortArray.push(JSON.parse(tutorialDesc).assembly1)
            // tutorialPortArray.push(JSON.parse(tutorialDesc).assembly2)
        }

        this.state = {
            tutorialDesc: tutorialDesc,
            usbOpen: false,
            detected: false,
            internalAccessoriesData: JSON.parse(
                sessionStorage.getItem('concept')
            ).internalaccessories,
        }
    }

    componentDidMount = () => {
        /*The followimg code is to position bibox at the centre of viepwoint on initial render if the user is
        starting an new project.This can be detected if the values of top and left are exactly the same as when the
        assembly object was created.

        If it is an existing project,then don't reposition
        */
        console.log('MOVETOMID' + sessionStorage.getItem('assembly'))
        const assembly = JSON.parse(sessionStorage.getItem('assembly'))
        console.log('MOVETOMID' + assembly)
        var leftbox = assembly.workspace.bibox.left
        var topbox = assembly.workspace.bibox.top
        console.log('MOVETOMID' + leftbox + topbox)
        if (leftbox == '328' && topbox == '208') {
            console.log('GROOT')
            this.moveBibox(
                this.workSpaceRef.current
                    ? this.workSpaceRef.current.getBoundingClientRect().width /
                          2 -
                          200
                    : 500,
                this.workSpaceRef.current
                    ? this.workSpaceRef.current.getBoundingClientRect().height /
                          2 -
                          180
                    : 800
            )
        } else {
            console.log('MOOT')
        }

        const { height, width } = this.props
        this.removeConnection = this.removeConnection.bind(this)
        var { PortConnections } = this.props.appState.assembly
        var { components, scale, offset } = this.props.workspace
        var selectedComponents = JSON.parse(
            sessionStorage.getItem('SelectedComp')
        )

        // var arr1 = [],
        // 	arr2 = [];

        // for (var k in components) {
        // 	arr1.push(k);
        // }
        // try {
        // 	for (var k = 0; k < selectedComponents.length; k++) {
        // 		arr2.push(selectedComponents[k].type);
        // 	}
        // } catch (e) {}

        // for (var i = 0; i < arr1.length; i++) {
        //   //console.log("workspacediffffff", difference[i]);
        //   if (arr1[i] != null || undefined) {
        //     console.log("workspace", components[arr1[i]]);
        //     delete components[arr1[i]];
        //   }
        // }
        // 	if (arr2.includes(arr1[i])) {
        // 		console.log("WorkspaceComponents present:", arr1[i]);
        // 	} else {
        // 		delete components[arr1[i]];
        // 	}
        // }

        if (components != null || undefined) {
            var { workspace } = this.props
            workspace.components = components
            this.props.update(workspace)
        }

        PortConnections = this.upDatePortConnectionsRedux()
        this.props.updatePort(PortConnections)
    }

    /**
     * Move the bibox to a new position
     * @param  {number} left The new left
     * @param  {number} top  The new top
     */
    moveBibox(left, top) {
        var { workspace } = this.props
        workspace.bibox.left = left
        workspace.bibox.top = top
        this.props.update(workspace)
    }

    upDatePortConnectionsRedux() {
        var { components } = this.props.workspace
        var { PortConnections } = this.props.appState.assembly
        Object.keys(PortConnections).map(
            (port) => (PortConnections[port] = null)
        )

        Object.keys(components).map((type) => {
            components[type].map((component, index) => {
                // let SensorObj = componentsDataSensor.find((o) => o.type === type);
                if (!component.connectedTo) return
                if (type == 'dual_splitter') {
                    if (component.connectedTo) {
                        if (
                            component.connectedTo == 'A' ||
                            component.connectedTo == 'B' ||
                            component.connectedTo == 'C' ||
                            component.connectedTo == 'D' ||
                            component.connectedTo == 'E' ||
                            component.connectedTo == 'F'
                        ) {
                            PortConnections[component.connectedTo] = {
                                type,
                                index,
                            }
                        }
                    }
                } else if (type == 'OLED') {
                    if (component.connectedTo) {
                        PortConnections['D'] = {
                            type,
                            index,
                        }
                        PortConnections['OLEDOne'] = {
                            type,
                            index,
                        }
                        PortConnections['OLEDTwo'] = {
                            type,
                            index,
                        }
                        PortConnections['OLEDThree'] = {
                            type,
                            index,
                        }
                    }
                } else if (
                    [
                        'dc_motor',
                        'mini_geared_motor',
                        'geared_motor',
                        'dynamex_motor',
                    ].includes(type)
                ) {
                    if (component.connectedTo) {
                        // armShield M1 (doesnt really mean M1) is actually M2 and M3.
                        const isArmSHield =
                            sessionStorage.getItem('armShield') == 'true' ||
                            false
                        const isPeeCeeATR =
                            sessionStorage.getItem('peeCeeATR') == 'true' ||
                            false

                        if (isPeeCeeATR) {
                            if (component.connectedTo == 'M1' && isPeeCeeATR) {
                                PortConnections['M1'] = {
                                    type,
                                    index,
                                }
                                PortConnections['M2'] = {
                                    type,
                                    index,
                                }
                            } else if (
                                component.connectedTo == 'M3' &&
                                isPeeCeeATR
                            ) {
                                PortConnections['M3'] = {
                                    type,
                                    index,
                                }
                                PortConnections['M4'] = {
                                    type,
                                    index,
                                }
                            } else if (
                                component.connectedTo == 'H' &&
                                isPeeCeeATR
                            ) {
                                if (
                                    sessionStorage
                                        .getItem('deviceVersion')
                                        ?.startsWith('1')
                                ) {
                                    PortConnections['H'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['F1'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['F2'] = {
                                        type,
                                        index,
                                    }
                                } else {
                                    PortConnections['H'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['A2'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['F2'] = {
                                        type,
                                        index,
                                    }
                                }
                            } else if (
                                component.connectedTo == 'G' &&
                                isPeeCeeATR
                            ) {
                                if (
                                    sessionStorage
                                        .getItem('deviceVersion')
                                        ?.startsWith('1')
                                ) {
                                    PortConnections['G'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['E1'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['E2'] = {
                                        type,
                                        index,
                                    }
                                } else {
                                    PortConnections['G'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['C2'] = {
                                        type,
                                        index,
                                    }
                                    PortConnections['F1'] = {
                                        type,
                                        index,
                                    }
                                }
                            } else {
                                PortConnections[
                                    component.connectedTo[0] + '1'
                                ] = {
                                    type,
                                    index,
                                }
                                PortConnections[
                                    component.connectedTo[0] + '2'
                                ] = {
                                    type,
                                    index,
                                }
                            }
                        } else if (
                            component.connectedTo == 'MOTOR1' &&
                            isArmSHield
                        ) {
                            PortConnections['MOTOR1'] = {
                                type,
                                index,
                            }
                            PortConnections['M2'] = {
                                type,
                                index,
                            }
                            PortConnections['M3'] = {
                                type,
                                index,
                            }
                        }
                        // M1 that appears on the playShield is actually M1 and M2
                        else if (component.connectedTo == 'M1') {
                            PortConnections['M1'] = {
                                type,
                                index,
                            }
                            PortConnections['M2'] = {
                                type,
                                index,
                            }
                        } else if (component.connectedTo == 'M3') {
                            PortConnections['M3'] = {
                                type,
                                index,
                            }
                            PortConnections['M4'] = {
                                type,
                                index,
                            }
                        } else if (
                            sessionStorage.getItem('connectedDevice') ===
                                'Klaw' &&
                            ['F1', 'F2'].includes(component.connectedTo)
                        ) {
                            PortConnections[component.connectedTo] = {
                                type,
                                index,
                            }
                        } else {
                            PortConnections[component.connectedTo[0] + '1'] = {
                                type,
                                index,
                            }
                            PortConnections[component.connectedTo[0] + '2'] = {
                                type,
                                index,
                            }
                        }
                    }
                } else if (
                    [
                        'servo_motor',
                        'servo_motor_270',
                        'servo_motor_360',
                    ].includes(type)
                ) {
                    PortConnections[component.connectedTo] = { type, index }
                    if (component.connectedTo == 'S1') {
                        if (
                            JSON.parse(
                                sessionStorage.getItem('peeCeeATR') || 'false'
                            ) &&
                            sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1')
                        ) {
                            PortConnections['S1'] = {
                                type,
                                index,
                            }
                        } else {
                            PortConnections['A1'] = {
                                type,
                                index,
                            }
                            PortConnections['S1'] = {
                                type,
                                index,
                            }
                        }
                    } else if (component.connectedTo == 'S2') {
                        if (
                            JSON.parse(
                                sessionStorage.getItem('peeCeeATR') || 'false'
                            ) &&
                            sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1')
                        ) {
                            PortConnections['S2'] = {
                                type,
                                index,
                            }
                        } else {
                            PortConnections['C1'] = {
                                type,
                                index,
                            }
                            PortConnections['S2'] = {
                                type,
                                index,
                            }
                        }
                    } else if (component.connectedTo == 'P0') {
                        PortConnections['A1'] = {
                            type,
                            index,
                        }
                        PortConnections['P0'] = {
                            type,
                            index,
                        }
                    } else if (component.connectedTo == 'P1') {
                        PortConnections['B1'] = {
                            type,
                            index,
                        }
                        PortConnections['P1'] = {
                            type,
                            index,
                        }
                    } else if (component.connectedTo == 'P2') {
                        PortConnections['C1'] = {
                            type,
                            index,
                        }
                        PortConnections['P2'] = {
                            type,
                            index,
                        }
                    }
                } else if (
                    type == 'led' ||
                    type == 'dip_switch' ||
                    type == 'dual_switch' ||
                    type == 'joystick' ||
                    type == 'tact_switch_2c'
                ) {
                    if (component.connectedTo) {
                        if (
                            component.connectedTo == 'A' ||
                            component.connectedTo == 'B' ||
                            component.connectedTo == 'C' ||
                            component.connectedTo == 'D' ||
                            component.connectedTo == 'E' ||
                            component.connectedTo == 'F'
                        ) {
                            PortConnections[`${component.connectedTo}1`] = {
                                type,
                                index,
                            }
                            PortConnections[`${component.connectedTo}2`] = {
                                type,
                                index,
                            }
                        }
                        PortConnections[component.connectedTo] = {
                            type,
                            index,
                        }
                    }
                } else {
                    if (
                        component.connectedTo == 'A' ||
                        component.connectedTo == 'B' ||
                        component.connectedTo == 'C' ||
                        component.connectedTo == 'D' ||
                        component.connectedTo == 'E' ||
                        component.connectedTo == 'F'
                    ) {
                        PortConnections[`${component.connectedTo}1`] = {
                            type,
                            index,
                        }
                    }
                    PortConnections[component.connectedTo] = {
                        type,
                        index,
                        // signalType: SensorObj.signalType,
                    }
                }
            })
        })
        return PortConnections
    }

    /**
     * Update the position of an old component
     * @param  {number} left The new left
     * @param  {number} top  The new top
     */
    updateOldComponent(left, top) {
        var { workspace } = this.props
        var item = DraggingInfo.draggingComponentOld

        workspace.components[item.type][item.index].top = top
        workspace.components[item.type][item.index].left = left

        if (DraggingInfo.newComponentPort != undefined) {
            workspace.components[item.type][item.index].connectedTo =
                DraggingInfo.newComponentPort
        }
        this.props.update(workspace)

        const PortConnections = this.upDatePortConnectionsRedux()

        this.props.updatePort(PortConnections)
    }
    /**
     * Add a new component to workspace. Also if DraggingInfo.newComponentPort is
     * defined connect the new component to that port.
     * @param  {string} type The type of the component
     * @param  {number} left The left offset
     * @param  {number} top  The top offset
     */
    newComponent(type, left, top) {
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        if (connectedDevice == 'Ace') {
            if (!sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
                this.props.handleReadBytes()
            } else {
                this.props.stopPA()
            }
        } else {
            this.props.stopPA()
        }

        let componentsDataSensor = JSON.parse(
            sessionStorage.getItem('concept')
        ).counter
        var { workspace } = this.props
        if (!workspace.components[type]) workspace.components[type] = []
        var component = { left: left, top: top } //IMP

        if (DraggingInfo.newComponentPort) {
            component.connectedTo = DraggingInfo.newComponentPort
            DraggingInfo.newComponentPort = null
        }
        workspace.components[type].push(component) //IMP
        // REDUX
        this.props.update(workspace)
        sessionStorage.setItem(
            'dualsplitter',
            JSON.stringify(workspace.components)
        )
        const PortConnections = this.upDatePortConnectionsRedux()
        this.props.updatePort(PortConnections)
    }
    /**
     * Connect a old component to a port in workspace (on drop)
     * @param  {AssemblyComponent} item
     * @param  {string} port The port
     */
    workspaceConnect(item, port) {
        var { workspace } = this.props
        workspace.components[item.type][item.index].connectedTo = port
        this.props.update(workspace)
    }
    /**
     * Remove connection of old component
     * @param  {AssemblyComponent} item
     */
    removeConnection(item) {
        var { workspace } = this.props
        if (workspace.components[item.type][item.index].connectedTo) {
            var obj = workspace.components[item.type][item.index]
            delete obj.connectedTo
        }
        this.props.update(workspace)
        let componentsDataSensor = JSON.parse(
            sessionStorage.getItem('concept')
        ).counter

        const PortConnections = this.upDatePortConnectionsRedux()
        this.props.updatePort(PortConnections)
    }

    closeUsb = () => {
        var c = document.getElementById('assemblyConnections')
        var d = document.getElementById('biboxClass')
        var e = document.getElementById('connectPort')

        c.style.zIndex = 2
        d.style.zIndex = 2
        e.style.zIndex = 2
        this.setState({ usbOpen: false })
    }
    render() {
        var count = 0
        if (this.state.detected == true) {
            var imageURL = 'images/Learn/ble_connection.png'
        } else {
            imageURL = 'images/Learn/ble_disconnection.png'
        }
        var usbDetectionModel = (
            <Modal isOpen={this.state.usbOpen} style={customStyles}>
                <img
                    onClick={this.closeUsb}
                    className="closeconceptModal"
                    src={renderPrgImage('close')}
                ></img>
                <div className="connectconceptMsg">
                    <p>Device not connected..</p>
                    <button>
                        {' '}
                        <Link
                            style={{ textDecoration: 'none', color: 'black' }}
                            to="/deviceSelection"
                        >
                            Reconnect
                        </Link>
                    </button>
                </div>
            </Modal>
        )
        const { connectDropTarget, removeFromWorkspace } = this.props
        const { bibox, components, offset, scale } = this.props.workspace
        var { PortConnections } = this.props.appState.assembly

        return connectDropTarget(
            <div
                id="workSpace"
                ref={this.workSpaceRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
            >
                {/* SHOWS DEVICE IMG ACE, HUMANOID, TEARN */}
                <Bibox
                    left={(bibox.left + offset.left) * scale}
                    top={(bibox.top + offset.top) * scale}
                    scale={scale}
                    workspaceConnect={this.workspaceConnect}
                    switch1={this.props.switch1}
                    switch2={this.props.switch2}
                    responceTp0={this.props.responceTp0}
                    responceTp1={this.props.responceTp1}
                    responceTp2={this.props.responceTp2}
                    mic={this.props.mic}
                    props={this.props}
                    removeFromWorkspace={removeFromWorkspace}
                    // Ports
                    rangeA1={this.props.rangeA1}
                    rangeA2={this.props.rangeA2}
                    tactswitch={this.props.tactswitch}
                    temp={this.props.temp}
                    gas={this.props.gas}
                    zero={this.props.zero}
                    one={this.props.one}
                    two={this.props.two}
                    shield={this.props.shield}
                    setShield={this.props.setShield}
                    armShield={this.props.armShield}
                    setArmShield={this.props.setArmShield}
                    peeCeeATR={this.props.peeCeeATR}
                    setPeeCeeATR={this.props.setPeeCeeATR}
                />

                {/* USB detection is remove and the Zindex is also change from componentDidmount */}
                {/* {usbDetectionModel} */}

                {Object.keys(components).map((key) => {
                    return (
                        <div key={key}>
                            {components[key].map((component, index) => {
                                const { left, top, connectedTo } = component
                                return (
                                    <Component1
                                        key={index}
                                        type={key}
                                        index={index}
                                        prop={this.props.prop}
                                        left={(offset.left + left) * scale}
                                        top={(offset.top + top) * scale}
                                        scale={scale}
                                        connectedTo={connectedTo}
                                        appState={this.props.appState}
                                        removeFromWorkspace={
                                            removeFromWorkspace
                                        }
                                        removeConnection={this.removeConnection}
                                        touch_pad={this.props.touch_pad}
                                        touch_pad2={this.props.touch_pad2}
                                        rangeA1={this.props.rangeA1}
                                        rangeA2={this.props.rangeA2}
                                        rangeB1={this.props.rangeB1}
                                        rangeB2={this.props.rangeB2}
                                        rangeC1={this.props.rangeC1}
                                        rangeC2={this.props.rangeC2}
                                        rangeD1={this.props.rangeD1}
                                        rangeD2={this.props.rangeD2}
                                        rangeE1={this.props.rangeE1}
                                        rangeE2={this.props.rangeE2}
                                        rangeF1={this.props.rangeF1}
                                        rangeF2={this.props.rangeF2}
                                        tactswitch={this.props.tactswitch}
                                        temp={this.props.temp}
                                        gas={this.props.gas}
                                        one={this.props.one}
                                        two={this.props.two}
                                        shield={this.props.shield}
                                        setShield={this.props.setShield}
                                        armShield={this.props.armShield}
                                        setArmShield={this.props.setArmShield}
                                        peeCeeATR={this.props.peeCeeATR}
                                        setPeeCeeATR={this.props.setPeeCeeATR}
                                    />
                                )
                            })}
                        </div>
                    )
                })}

                {localStorage.getItem('programMode') == 'learn' ? (
                    <div
                        style={{
                            height: '10%',
                            width: '65%',
                            border: '2px solid #bed5fa',
                            backgroundColor: 'white',
                            borderRadius: '20px',
                            bottom: '3%',
                            left: '10%',
                            position: 'inherit',
                        }}
                    >
                        <h3 style={{ marginLeft: '20px', marginTop: '2px' }}>
                            {Object.entries(this.state.tutorialDesc).map(
                                ([key, value]) => {
                                    if (PortConnections[key]) {
                                        if (
                                            PortConnections[key].type == value
                                        ) {
                                        }
                                    } else {
                                        if (count == 0) {
                                            count++
                                            this.props.nextVisbility('hidden')
                                            return (
                                                <div>
                                                    {' '}
                                                    Drag &amp; connect {
                                                        value
                                                    }{' '}
                                                    to {key} port
                                                </div>
                                            )
                                        }
                                    }
                                }
                            )}
                            {count == 0 ? (
                                <p>
                                    {this.props.nextVisbility('visible')}
                                    <div>Click on next</div>
                                </p>
                            ) : (
                                ''
                            )}
                        </h3>
                    </div>
                ) : (
                    <div> </div>
                )}
            </div>
        )
    }
}

export default DropTarget(
    [ItemTypes.BIBOX, ItemTypes.COMPONENT],
    workspaceTarget,
    (connect) => ({
        connectDropTarget: connect.dropTarget(),
    })
)(Workspace)
