const ObjectDetails = {
    1: {
        displayName: 'airplane',
    },
    2: {
        displayName: 'apple',
    },
    3: {
        displayName: 'backpack',
    },
    4: {
        displayName: 'banana',
    },
    5: {
        displayName: 'baseball bat',
    },
    6: {
        displayName: 'baseball glove',
    },
    7: {
        displayName: 'bear',
    },
    8: {
        displayName: 'bed',
    },
    9: {
        displayName: 'bench',
    },
    10: {
        displayName: 'bicycle',
    },
    11: {
        displayName: 'bird',
    },
    12: {
        displayName: 'boat',
    },
    13: {
        displayName: 'book',
    },
    14: {
        displayName: 'bottle',
    },
    15: {
        displayName: 'bowl',
    },
    16: {
        displayName: 'broccoli',
    },
    17: {
        displayName: 'bus',
    },
    18: {
        displayName: 'cake',
    },
    19: {
        displayName: 'car',
    },
    20: {
        displayName: 'carrot',
    },
    21: {
        displayName: 'cat',
    },
    22: {
        displayName: 'cell phone',
    },
    23: {
        displayName: 'chair',
    },
    24: {
        displayName: 'clock',
    },
    25: {
        displayName: 'couch',
    },
    26: {
        displayName: 'cow',
    },
    27: {
        displayName: 'cup',
    },
    28: {
        displayName: 'dining table',
    },
    29: {
        displayName: 'dog',
    },
    30: {
        displayName: 'donut',
    },
    31: {
        displayName: 'elephant',
    },
    32: {
        displayName: 'fire hydrant',
    },
    33: {
        displayName: 'fork',
    },
    34: {
        displayName: 'frisbee',
    },
    35: {
        displayName: 'giraffe',
    },
    36: {
        displayName: 'hair drier',
    },
    37: {
        displayName: 'handbag',
    },
    38: {
        displayName: 'horse',
    },
    39: {
        displayName: 'hot dog',
    },
    40: {
        displayName: 'keyboard',
    },
    41: {
        displayName: 'kite',
    },
    42: {
        displayName: 'knife',
    },
    43: {
        displayName: 'laptop',
    },
    44: {
        displayName: 'microwave',
    },
    45: {
        displayName: 'motorcycle',
    },
    46: {
        displayName: 'mouse',
    },
    47: {
        displayName: 'orange',
    },
    48: {
        displayName: 'oven',
    },
    49: {
        displayName: 'parking meter',
    },
    50: {
        displayName: 'person',
    },
    51: {
        displayName: 'pizza',
    },
    52: {
        displayName: 'potted plant',
    },
    53: {
        displayName: 'refrigerator',
    },
    54: {
        displayName: 'remote',
    },
    55: {
        displayName: 'sandwich',
    },
    56: {
        displayName: 'scissors',
    },
    57: {
        displayName: 'sheep',
    },
    58: {
        displayName: 'sink',
    },
    59: {
        displayName: 'skateboard',
    },
    60: {
        displayName: 'skis',
    },
    61: {
        displayName: 'snowboard',
    },
    62: {
        displayName: 'spoon',
    },
    63: {
        displayName: 'sports ball',
    },
    64: {
        displayName: 'stop sign',
    },
    65: {
        displayName: 'suitcase',
    },
    66: {
        displayName: 'surfboard',
    },
    67: {
        displayName: 'teddy bear',
    },
    68: {
        displayName: 'tennis racket',
    },
    69: {
        displayName: 'tie',
    },
    70: {
        displayName: 'toaster',
    },
    71: {
        displayName: 'toilet',
    },
    72: {
        displayName: 'toothbrush',
    },
    73: {
        displayName: 'traffic light',
    },
    74: {
        displayName: 'train',
    },
    75: {
        displayName: 'truck',
    },
    76: {
        displayName: 'tv',
    },
    77: {
        displayName: 'umbrella',
    },
    78: {
        displayName: 'vase',
    },
    79: {
        displayName: 'wine glass',
    },
    80: {
        displayName: 'zebra',
    },
}

export default ObjectDetails
