import { useState } from 'react'
import { useHistory } from 'react-router'
import close from '../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'

const ProgrammingPageFooter = (props) => {
    const {
        setSlideDirection,
        enable,
        count,
        setCount,
        children,
        setChildren,
        backY,
        buttonPlus,
        nextY,
    } = props
    const [popup, setPopup] = useState(false)
    const [popupText, setPopupText] = useState('')
    const history = useHistory()

    const Popup = (props) => {
        return props.trigger ? (
            <div
                style={{
                    position: 'absolute',
                    top: '0vh',
                    left: '0',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(255,255,255, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '90000',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        padding: '1rem 2rem',
                        width: '50%',
                        maxWidth: '400px',
                        minHeight: '150px',
                        border: '1px solid white',
                        borderRadius: '4rem',
                        boxShadow: '0.5rem 0.5rem 2rem #d9d5d4',
                        background: 'linear-gradient(to right, #f0eded, white)',
                    }}
                >
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            top: '-2vh',
                            right: '0vw',
                        }}
                        onClick={() => {
                            setPopup(false)
                        }}
                    />
                </div>
                <div
                    style={{
                        zIndex: '5',
                        width: '25%',
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <strong>{props.text}</strong>
                </div>
            </div>
        ) : (
            ''
        )
    }

    const nextPage = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        let allDetails = JSON.parse(sessionStorage.getItem('allDetails'))
        let arr = JSON.parse(sessionStorage.getItem('arr'))
        let noneOptionCheck = []
        let emptyEnterTextCheck = []
        let noneSelectedObjectCheck = []
        let emptyActionCheck = []
        let noneUsbSliderValueCheck = []

        const isnotNone = (currentValue) =>
            currentValue !== null &&
            currentValue !== 'none' &&
            currentValue !== ''

        if (
            allDetails !== null &&
            Object.keys(allDetails).length === arr.length
        ) {
            console.log(allDetails, 'emptyActionCheck', [...emptyActionCheck])
            Object.keys(allDetails).map((key) => {
                noneOptionCheck.push(allDetails[key].option)
                emptyEnterTextCheck.push(allDetails[key].speechText)
                noneSelectedObjectCheck.push(allDetails[key].selectedObject)
                noneUsbSliderValueCheck.push(allDetails[key].usbValue)
                emptyActionCheck.push(
                    isnotNone(allDetails[key].displayText) ||
                        isnotNone(allDetails[key].image) ||
                        (enable.s1.pc &&
                            isnotNone(allDetails[key].sliderOption))
                )
                return ''
            })
            if (noneOptionCheck.every(isnotNone)) {
                if (
                    emptyEnterTextCheck.filter(isnotNone).length +
                        noneSelectedObjectCheck.filter(isnotNone).length +
                        noneUsbSliderValueCheck.filter(isnotNone).length ===
                    arr.length
                ) {
                    if (emptyActionCheck.every((value) => value === true)) {
                        if (enable.s1.cam === true && enable.s1.mic === true) {
                            if (enable.s2.face === true) {
                                history.push('/FaceRecognition')
                            } else if (enable.s2.emotion === true) {
                                history.push('/EmotionDetection')
                            } else if (enable.s2.object === true) {
                                history.push('/ObjectDetection')
                            }
                        } else if (enable.s1.cam === true) {
                            if (enable.s2.face === true) {
                                history.push('/FaceRecognition')
                            } else if (enable.s2.emotion === true) {
                                history.push('/emotionDetection')
                            } else if (enable.s2.object === true) {
                                history.push('/objectDetection')
                            }
                        } else if (enable.s1.mic === true) {
                            history.push('/speechRecognition')
                        } else if (enable.s1.pc === true) {
                            history.push('/playComputer')
                        }
                    } else {
                        setPopup(true)
                        setPopupText('Please select atleast one action')
                    }
                } else {
                    setPopup(true)
                    setPopupText(
                        'Enter text or selected object cannot be empty or none'
                    )
                }
            } else {
                setPopup(true)
                setPopupText('Please select all conditions')
            }
        } else {
            setPopup('ggggg')
            setPopupText('Please select all conditions')
        }
    }

    const ButtonOnClick = () => {
        setCount(parseInt(count) + 1)
        setChildren((arr) => [...arr, parseInt(count) + 1])
    }

    return (
        <div>
            <img
                src={backY}
                draggable="false"
                alt="back"
                style={{
                    margin: 0,
                    position: 'fixed',
                    top: '92vh',
                    right: '92.7vw',
                    width: '3vw',
                    cursor: 'pointer',
                    transform: 'translate(-50%, -50%)',
                }}
                onClick={() => {
                    setSlideDirection(true)
                    sessionStorage.setItem('slideDirection', true)
                    if (
                        enable.s1.cam === true &&
                        enable.s1.mic === true &&
                        enable.s1.pc === true
                    ) {
                        if (enable.s2.face === true) {
                            history.push('/savedFaces')
                        } else if (enable.s2.emotion === true) {
                            history.push('/selectDetection')
                        } else if (enable.s2.object === true) {
                            history.push('/selectDetection')
                        }
                    } else if (
                        enable.s1.cam === true &&
                        enable.s1.mic === true
                    ) {
                        if (enable.s2.face === true) {
                            history.push('/savedFaces')
                        } else if (enable.s2.emotion === true) {
                            history.push('/selectDetection')
                        } else if (enable.s2.object === true) {
                            history.push('/selectDetection')
                        }
                    } else if (
                        enable.s1.cam === true &&
                        enable.s1.pc === true
                    ) {
                        if (enable.s2.face === true) {
                            history.push('/savedFaces')
                        } else if (enable.s2.emotion === true) {
                            history.push('/selectDetection')
                        } else if (enable.s2.object === true) {
                            history.push('/selectDetection')
                        }
                    } else if (
                        enable.s1.mic === true &&
                        enable.s1.pc === true
                    ) {
                        history.push('/chooseDevice')
                    } else if (enable.s1.cam === true) {
                        if (enable.s2.face === true) {
                            history.push('/savedFaces')
                        } else if (enable.s2.emotion === true) {
                            history.push('/selectDetection')
                        } else if (enable.s2.object === true) {
                            history.push('/selectDetection')
                        }
                    } else if (enable.s1.mic === true) {
                        history.push('/chooseDevice')
                    } else if (enable.s1.pc === true) {
                        history.push('/chooseDevice')
                    }
                }}
            />
            {/* Element to Move Dynamically */}
            <h2
                style={{
                    position: 'relative',
                    top: '74vh',
                    // font: "Halcyon Regular",
                    fontFamily: 'Halcyon_SemiBold',
                    fontSize: '16px',
                    color: 'grey',
                    textAlign: 'center',
                }}
            >
                Tap + button to keep coding
            </h2>
            <img
                src={buttonPlus}
                draggable="false"
                alt="add"
                style={{
                    margin: 0,
                    position: 'fixed',
                    top: '92vh',
                    left: '50vw',
                    width: '3vw',
                    cursor: 'pointer',
                    transform: 'translate(-50%, -50%)',
                }}
                onClick={() => ButtonOnClick()}
            />
            <img
                src={nextY}
                draggable="false"
                alt="next"
                style={{
                    margin: 0,
                    position: 'fixed',
                    marginBottom: 'auto',
                    top: '92vh',
                    left: '95.8vw',
                    width: '3vw',
                    cursor: 'pointer',
                    transform: 'translate(-50%, -50%)',
                }}
                onClick={nextPage}
            />
            {console.log('MMMMMMM888888', popup)}
            {popup ? <Popup trigger={popup} text={popupText} /> : ''}
        </div>
    )
}

export default ProgrammingPageFooter
