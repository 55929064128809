import style from './ViewActions.module.css'

function ActionLoader() {
    return (
        <div
            className={style.loader}
            style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                backgroundColor: 'rgb(255,255,255, 0.5)',
            }}
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default ActionLoader
