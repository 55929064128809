import React, { useEffect, useRef } from 'react'

const Select = (props) => {
    var style = {
        // border: '2.1px solid #3C413E',
        borderRadius: '15px',
        color: '#000',
        background: '#FFF',
        height: '100%',
        width: '100%',
        border: '1px solid white',
        borderRadius: '15px',
        outline: 'none',
        fontFamily: 'Halcyon_Medium',
        position: 'absolute',
        top: '50%',
        left: '0%',
        transform: 'translate(0%, -50%)',
        border: 'none',
        textAlign: 'center',
    }
    const selectRef = useRef()
    const startButtonState = JSON.parse(
        sessionStorage.getItem('startButtonState')
    )
    const flowchartBased =
        sessionStorage.getItem('programMode') === 'flowchartBased'

    let A1 = JSON.parse(sessionStorage.getItem('A1'))
    let A2 = JSON.parse(sessionStorage.getItem('A2'))
    let B1 = JSON.parse(sessionStorage.getItem('B1'))
    let B2 = JSON.parse(sessionStorage.getItem('B2'))
    let C1 = JSON.parse(sessionStorage.getItem('C1'))
    let C2 = JSON.parse(sessionStorage.getItem('C2'))
    let D1 = JSON.parse(sessionStorage.getItem('D1'))
    let D2 = JSON.parse(sessionStorage.getItem('D2'))
    let E1 = JSON.parse(sessionStorage.getItem('E1'))
    let E2 = JSON.parse(sessionStorage.getItem('E2'))
    let F1 = JSON.parse(sessionStorage.getItem('F1'))
    let F2 = JSON.parse(sessionStorage.getItem('F2'))
    let M1 = JSON.parse(sessionStorage.getItem('M1'))
    let M2 = JSON.parse(sessionStorage.getItem('M2'))
    let M3 = JSON.parse(sessionStorage.getItem('M3'))
    let M4 = JSON.parse(sessionStorage.getItem('M4'))

    let a1Checked = JSON.parse(sessionStorage.getItem('a1-I/O'))
    let a1Digi = JSON.parse(sessionStorage.getItem('A1DIGI'))
    let a2Checked = JSON.parse(sessionStorage.getItem('a2-I/O'))
    let a2Digi = JSON.parse(sessionStorage.getItem('A2DIGI'))
    let b1Checked = JSON.parse(sessionStorage.getItem('b1-I/O'))
    let b1Digi = JSON.parse(sessionStorage.getItem('B1DIGI'))
    let b2Checked = JSON.parse(sessionStorage.getItem('b2-I/O'))
    let b2Digi = JSON.parse(sessionStorage.getItem('B2DIGI'))
    let c1Checked = JSON.parse(sessionStorage.getItem('c1-I/O'))
    let c1Digi = JSON.parse(sessionStorage.getItem('C1DIGI'))
    let c2Checked = JSON.parse(sessionStorage.getItem('c2-I/O'))
    let c2Digi = JSON.parse(sessionStorage.getItem('C2DIGI'))
    let d1Checked = JSON.parse(sessionStorage.getItem('d1-I/O'))
    let d1Digi = JSON.parse(sessionStorage.getItem('D1DIGI'))
    let d2Checked = JSON.parse(sessionStorage.getItem('d2-I/O'))
    let d2Digi = JSON.parse(sessionStorage.getItem('D2DIGI'))
    let e1Checked = JSON.parse(sessionStorage.getItem('e1-I/O'))
    let e1Digi = JSON.parse(sessionStorage.getItem('E1DIGI'))
    let e2Checked = JSON.parse(sessionStorage.getItem('e2-I/O'))
    let e2Digi = JSON.parse(sessionStorage.getItem('E2DIGI'))
    let f1Checked = JSON.parse(sessionStorage.getItem('f1-I/O'))
    let f1Digi = JSON.parse(sessionStorage.getItem('F1DIGI'))
    let f2Checked = JSON.parse(sessionStorage.getItem('f2-I/O'))
    let f2Digi = JSON.parse(sessionStorage.getItem('F2DIGI'))
    let m1Checked = JSON.parse(sessionStorage.getItem('m1-I/O')) || false
    let m1Digi = JSON.parse(sessionStorage.getItem('M1DIGI'))
    let m2Checked = JSON.parse(sessionStorage.getItem('m2-I/O'))
    let m2Digi = JSON.parse(sessionStorage.getItem('M2DIGI'))
    let m3Checked = JSON.parse(sessionStorage.getItem('m3-I/O'))
    let m3Digi = JSON.parse(sessionStorage.getItem('M3DIGI'))
    let m4Checked = JSON.parse(sessionStorage.getItem('m4-I/O'))
    let m4Digi = JSON.parse(sessionStorage.getItem('M4DIGI'))
    let isMic = JSON.parse(sessionStorage.getItem('isMic'))
    let isDistanceSensors = JSON.parse(
        sessionStorage.getItem('isDistanceSensors')
    )
    let isBattery = JSON.parse(sessionStorage.getItem('isBattery'))
    let isAccelerometer = JSON.parse(sessionStorage.getItem('isAccelerometer'))
    let isGestureSensor = JSON.parse(sessionStorage.getItem('isGestureSensor'))
    let isLightSensor = JSON.parse(sessionStorage.getItem('isLightSensor'))
    let isColorSensor = JSON.parse(sessionStorage.getItem('isColorSensor'))
    let isTemperature = JSON.parse(sessionStorage.getItem('isTemperature'))
    let isTouchOne = JSON.parse(sessionStorage.getItem('isTouchOne'))
    let isTouchTwo = JSON.parse(sessionStorage.getItem('isTouchTwo'))
    let isTouchThree = JSON.parse(sessionStorage.getItem('isTouchThree'))
    let isTouchZeroOutput = JSON.parse(
        sessionStorage.getItem('isTouchZeroOutput')
    )
    let isTouchOneOutput = JSON.parse(
        sessionStorage.getItem('isTouchOneOutput')
    )
    let isTouchTwoOutput = JSON.parse(
        sessionStorage.getItem('isTouchTwoOutput')
    )
    let isTouchZero = JSON.parse(sessionStorage.getItem('isTouchZero'))
    let ultraA = JSON.parse(sessionStorage.getItem('AUltra'))
    let ultraC = JSON.parse(sessionStorage.getItem('CUltra'))

    useEffect(() => {
        A1 = JSON.parse(sessionStorage.getItem('A1'))
        A2 = JSON.parse(sessionStorage.getItem('A2'))
        a1Checked = JSON.parse(sessionStorage.getItem('a1-I/O'))
        a2Checked = JSON.parse(sessionStorage.getItem('a2-I/O'))
        B1 = JSON.parse(sessionStorage.getItem('B1'))
        B2 = JSON.parse(sessionStorage.getItem('B2'))
        b1Checked = JSON.parse(sessionStorage.getItem('b1-I/O'))
        b2Checked = JSON.parse(sessionStorage.getItem('b2-I/O'))
        C1 = JSON.parse(sessionStorage.getItem('C1'))
        C2 = JSON.parse(sessionStorage.getItem('C2'))
        c1Checked = JSON.parse(sessionStorage.getItem('c1-I/O'))
        c2Checked = JSON.parse(sessionStorage.getItem('c2-I/O'))
        D1 = JSON.parse(sessionStorage.getItem('D1'))
        D2 = JSON.parse(sessionStorage.getItem('D2'))
        d1Checked = JSON.parse(sessionStorage.getItem('d1-I/O'))
        d2Checked = JSON.parse(sessionStorage.getItem('d2-I/O'))
        E1 = JSON.parse(sessionStorage.getItem('E1'))
        E2 = JSON.parse(sessionStorage.getItem('E2'))
        F1 = JSON.parse(sessionStorage.getItem('F1'))
        F2 = JSON.parse(sessionStorage.getItem('F2'))
        M1 = JSON.parse(sessionStorage.getItem('M1'))
        M2 = JSON.parse(sessionStorage.getItem('M2'))
        M3 = JSON.parse(sessionStorage.getItem('M3'))
        M4 = JSON.parse(sessionStorage.getItem('M4'))
        e1Checked = JSON.parse(sessionStorage.getItem('e1-I/O'))
        e1Digi = JSON.parse(sessionStorage.getItem('E1DIGI'))
        e2Checked = JSON.parse(sessionStorage.getItem('e2-I/O'))
        e2Digi = JSON.parse(sessionStorage.getItem('E2DIGI'))
        f1Checked = JSON.parse(sessionStorage.getItem('f1-I/O'))
        f1Digi = JSON.parse(sessionStorage.getItem('F1DIGI'))
        f2Checked = JSON.parse(sessionStorage.getItem('f2-I/O'))
        f2Digi = JSON.parse(sessionStorage.getItem('F2DIGI'))
        m1Checked = JSON.parse(sessionStorage.getItem('m1-I/O')) || false
        m1Digi = JSON.parse(sessionStorage.getItem('M1DIGI'))
        m2Checked = JSON.parse(sessionStorage.getItem('m2-I/O'))
        m2Digi = JSON.parse(sessionStorage.getItem('M2DIGI'))
        m3Checked = JSON.parse(sessionStorage.getItem('m3-I/O'))
        m3Digi = JSON.parse(sessionStorage.getItem('M3DIGI'))
        m4Checked = JSON.parse(sessionStorage.getItem('m4-I/O'))
        m4Digi = JSON.parse(sessionStorage.getItem('M4DIGI'))
        isMic = JSON.parse(sessionStorage.getItem('isMic'))
        isBattery = JSON.parse(sessionStorage.getItem('isBattery'))
        isAccelerometer = JSON.parse(sessionStorage.getItem('isAccelerometer'))
        isDistanceSensors = JSON.parse(
            sessionStorage.getItem('isDistanceSensors')
        )
        isGestureSensor = JSON.parse(sessionStorage.getItem('isGestureSensor'))
        isLightSensor = JSON.parse(sessionStorage.getItem('isLightSensor'))
        isColorSensor = JSON.parse(sessionStorage.getItem('isColorSensor'))
        isTemperature = JSON.parse(sessionStorage.getItem('isTemperature'))
        isTouchOne = JSON.parse(sessionStorage.getItem('isTouchOne'))
        isTouchTwo = JSON.parse(sessionStorage.getItem('isTouchTwo'))
        isTouchZero = JSON.parse(sessionStorage.getItem('isTouchZero'))
        isTouchThree = JSON.parse(sessionStorage.getItem('isTouchThree'))
        isTouchZeroOutput = JSON.parse(
            sessionStorage.getItem('isTouchZeroOutput')
        )
        isTouchOneOutput = JSON.parse(
            sessionStorage.getItem('isTouchOneOutput')
        )
        isTouchTwoOutput = JSON.parse(
            sessionStorage.getItem('isTouchTwoOutput')
        )
        ultraA = JSON.parse(sessionStorage.getItem('AUltra'))
        ultraC = JSON.parse(sessionStorage.getItem('CUltra'))
        a1Digi = JSON.parse(sessionStorage.getItem('A1DIGI'))
        a2Digi = JSON.parse(sessionStorage.getItem('A2DIGI'))
        b1Digi = JSON.parse(sessionStorage.getItem('B1DIGI'))
        b2Digi = JSON.parse(sessionStorage.getItem('B2DIGI'))
        c1Digi = JSON.parse(sessionStorage.getItem('C1DIGI'))
        c2Digi = JSON.parse(sessionStorage.getItem('C2DIGI'))
        d1Digi = JSON.parse(sessionStorage.getItem('D1DIGI'))
        d2Digi = JSON.parse(sessionStorage.getItem('D2DIGI'))
        f1Digi = JSON.parse(sessionStorage.getItem('F1DIGI'))
        f2Digi = JSON.parse(sessionStorage.getItem('F2DIGI'))
    }, [])

    const onChange = (e) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        props.onChange(e.target.value)
    }
    const getOptions = () => {
        if (flowchartBased && props.update) {
            if (selectRef.current !== undefined) {
                const options = selectRef.current.options
                const optionLabel = []
                for (let i = 0; i < options.length; i++) {
                    if (
                        ![
                            'microphone',
                            'battery',
                            'distanceSensor',
                            'gestureSensor',
                            'lightSensor',
                            'colorSensorRed',
                            'colorSensorGreen',
                            'colorSensorBlue',
                            'temperature',
                            'touchZero',
                            'touchOne',
                            'touchTwo',
                            'touchThree',
                            'accelerometer_x',
                            'accelerometer_y',
                            'accelerometer_z',
                        ].includes(options[i].value) &&
                        !['Gyro -> X', 'Gyro -> Y', 'Gyro -> Z'].includes(
                            options[i].label
                        )
                    ) {
                        optionLabel.push(options[i].label)
                        sessionStorage.setItem('isSave', JSON.stringify(false))
                    }
                }
                sessionStorage.setItem(
                    'optionLabels',
                    JSON.stringify(optionLabel)
                )
                props.update(optionLabel.slice(1), props.flow)
            }
        }
    }

    return (
        <>
            <select
                onChange={onChange}
                style={style}
                value={props.selected}
                ref={selectRef}
            >
                <option value="null">Select Items</option>
                {startButtonState[0].isEnabled && (
                    <option value="Bicounter1">Bicounter1 </option>
                )}
                {startButtonState[1].isEnabled && (
                    <option value="Bicounter2">Bicounter2 </option>
                )}
                {startButtonState[3].isEnabled && (
                    <option value="Biflag1">Biflag1 </option>
                )}
                {startButtonState[4].isEnabled && (
                    <option value="Biflag2">Biflag2 </option>
                )}
                {startButtonState[5].isEnabled && (
                    <>
                        <option value="bt rx 1">BT RX 1 </option>
                        <option value="bt rx 2">BT RX 2</option>
                        <option value="bt rx 3">BT RX 3</option>
                        <option value="bt rx 4">BT RX 4</option>
                        <option value="bt rx 5">BT RX 5</option>
                    </>
                )}
                {startButtonState[6].isEnabled && (
                    <option value="bt rte">BT Remote </option>
                )}
                {startButtonState[7].isEnabled && (
                    <option value="usb rx">USB RX </option>
                )}

                {ultraA ? (
                    <>
                        <option value="ultra A1">ULTRASONIC A1 </option>
                    </>
                ) : (
                    <>
                        {!a1Checked && A1 && !a1Digi ? (
                            <>
                                <option value="port A1">
                                    DIGITAL INPUT -{'>'} A1
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {a1Digi && !a1Checked && A1 ? (
                            <>
                                <option value="port A1">
                                    ANALOG INPUT -{'>'} A1
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {!a2Checked && !a2Digi && A2 ? (
                            <>
                                <option value="port A2">
                                    DIGITAL INPUT -{'>'} A2
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {a2Digi && !a2Checked && A2 ? (
                            <>
                                <option value="port A2">
                                    ANALOG INPUT -{'>'} A2
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}

                {!b1Checked && B1 && !b1Digi ? (
                    <>
                        <option value="port B1">DIGITAL INPUT -{'>'} B1</option>
                    </>
                ) : (
                    <></>
                )}
                {b1Digi && B1 && !b1Checked ? (
                    <>
                        <option value="port B1">ANALOG INPUT -{'>'} B1</option>
                    </>
                ) : (
                    <></>
                )}
                {!b2Checked && B2 && !b2Digi ? (
                    <>
                        <option value="port B2">DIGITAL INPUT -{'>'} B2</option>
                    </>
                ) : (
                    <></>
                )}
                {b2Digi && !b2Checked && B2 ? (
                    <>
                        <option value="port B2">ANALOG INPUT -{'>'} B2</option>
                    </>
                ) : (
                    <></>
                )}
                {ultraC ? (
                    <>
                        <option value="ultra C1">ULTRASONIC C1 </option>
                    </>
                ) : (
                    <>
                        {!c1Checked && C1 && !c1Digi ? (
                            <>
                                <option value="port C1">
                                    DIGITAL INPUT -{'>'} C1
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {c1Digi && !c1Checked && C1 ? (
                            <>
                                <option value="port C1">
                                    ANALOG INPUT -{'>'} C1
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {!c2Checked && C2 && !c2Digi ? (
                            <>
                                <option value="port C2">
                                    DIGITAL INPUT -{'>'} C2
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {c2Digi && C2 && !c2Checked ? (
                            <>
                                <option value="port C2">
                                    ANALOG INPUT -{'>'} C2
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}
                {sessionStorage.getItem('deviceVersion')?.startsWith('1') ? (
                    <>
                        {!d1Checked && D1 && !d1Digi ? (
                            <>
                                <option value="port D1">
                                    DIGITAL INPUT -{'>'} D1
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {d1Digi && D1 && !d1Checked ? (
                            <>
                                <option value="port D1">
                                    ANALOG INPUT -{'>'} D1
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {!d2Checked && D2 && !d2Digi ? (
                            <>
                                <option value="port D2">
                                    DIGITAL INPUT -{'>'} D2
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                        {d2Digi && !d2Checked && D2 ? (
                            <>
                                <option value="port D2">
                                    ANALOG INPUT -{'>'} D2
                                </option>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {!e1Checked && E1 && !e1Digi ? (
                    <>
                        <option value="port E1">DIGITAL INPUT -{'>'} E1</option>
                    </>
                ) : (
                    <></>
                )}
                {e1Digi && !e1Checked && E1 ? (
                    <>
                        <option value="port E1">ANALOG INPUT -{'>'} E1</option>
                    </>
                ) : (
                    <></>
                )}
                {!e2Checked && E2 && !e2Digi ? (
                    <>
                        <option value="port E2">DIGITAL INPUT -{'>'} E2</option>
                    </>
                ) : (
                    <></>
                )}
                {e2Digi && !e2Checked && E2 ? (
                    <>
                        <option value="port E2">ANALOG INPUT -{'>'} E2</option>
                    </>
                ) : (
                    <></>
                )}

                {!f1Checked && F1 && !f1Digi ? (
                    <>
                        <option value="port F1">DIGITAL INPUT -{'>'} F1</option>
                    </>
                ) : (
                    <></>
                )}
                {f1Digi && !f1Checked && F1 ? (
                    <>
                        <option value="port F1">ANALOG INPUT -{'>'} F1</option>
                    </>
                ) : (
                    <></>
                )}
                {!f2Checked && F2 && !f2Digi ? (
                    <>
                        <option value="port F2">DIGITAL INPUT -{'>'} F2</option>
                    </>
                ) : (
                    <></>
                )}
                {f2Digi && !f2Checked && F2 ? (
                    <>
                        <option value="port F2">ANALOG INPUT -{'>'} F2</option>
                    </>
                ) : (
                    <></>
                )}
                {/* {!m1Checked && M1 ? (
        <>
          <option value="port M1">PORT M1</option>
        </>
      ) : (
        <></>
      )}
      {!m2Checked && M2 ? (
        <>
          <option value="port M2">PORT M2</option>
        </>
      ) : (
        <></>
      )}
      {!m3Checked && M3 ? (
        <>
          <option value="port M3">PORT M3</option>
        </>
      ) : (
        <></>
      )}
      {!m4Checked && M4 ? (
        <>
          <option value="port M4">PORT M4</option>
        </>
      ) : (
        <></>
      )} */}
                {isMic ? (
                    <>
                        <option value="microphone">Microphone</option>
                    </>
                ) : (
                    <></>
                )}
                {isBattery ? (
                    <>
                        <option value="battery">Battery</option>
                    </>
                ) : (
                    <></>
                )}
                {isAccelerometer ? (
                    <>
                        <option value="accelerometer_x">
                            Accelerometer -{'>'} X
                        </option>
                        <option value="accelerometer_y">
                            Accelerometer -{'>'} Y
                        </option>
                        <option value="accelerometer_z">
                            Accelerometer -{'>'} Z
                        </option>
                        <option value="gyro_x">Gyro -{'>'} X</option>
                        <option value="gyro_y">Gyro -{'>'} Y</option>
                        <option value="gyro_z">Gyro -{'>'} Z</option>
                    </>
                ) : (
                    <></>
                )}
                {isDistanceSensors ? (
                    <>
                        <option value="distanceSensor">Distance Sensor</option>
                    </>
                ) : (
                    <></>
                )}
                {isGestureSensor ? (
                    <>
                        <option value="gestureSensor">Gesture Sensor</option>
                    </>
                ) : (
                    <></>
                )}
                {isLightSensor ? (
                    <>
                        <option value="lightSensor">Light Sensor</option>
                    </>
                ) : (
                    <></>
                )}
                {isColorSensor ? (
                    <>
                        <option value="colorSensorRed">
                            PC Colour Sensor -{'>'} RED
                        </option>
                        <option value="colorSensorGreen">
                            PC Colour Sensor -{'>'} GREEN
                        </option>
                        <option value="colorSensorBlue">
                            PC Colour Sensor -{'>'} BLUE
                        </option>
                    </>
                ) : (
                    <></>
                )}
                {isTemperature ? (
                    <>
                        <option value="temperature">Temperature</option>
                    </>
                ) : (
                    <></>
                )}
                {isTouchZero ? (
                    <>
                        <option value="touchZero">Touchpad Zero </option>
                    </>
                ) : (
                    <></>
                )}
                {isTouchOne ? (
                    <>
                        <option value="touchOne">Touchpad One </option>
                    </>
                ) : (
                    <></>
                )}
                {isTouchTwo ? (
                    <>
                        <option value="touchTwo">Touchpad Two </option>
                    </>
                ) : (
                    <></>
                )}
                {isTouchThree ? (
                    <>
                        <option value="touchThree">Touchpad Three</option>
                    </>
                ) : (
                    <></>
                )}
                {flowchartBased ? getOptions() : null}
            </select>
        </>
    )
}

export default Select
