import React, { useState, useEffect } from 'react'
import MainHeader from '../ReusableComponents/Header/MainHeader'

import { useHistory } from 'react-router-dom'
import renderImage from '../../source/importImg'
import MainSlider from '../ReusableComponents/MainSlider/MainSlider'

import biboxSelectStyle from './biboxSelection.module.css'
import SelectDevice from './SelectDevice'
import cacheAssets from '../../utils/cacheAssets'

import packageJson from '../../../package.json'
import { compareVersion } from '../../utils/verionControl'

const getPlodeVersion = (connectedDevice) => {
    if (connectedDevice === 'Ace') return packageJson['version']
    // else if (connectedDevice === "Humanoid")
    else return packageJson['plode-versions']['plode-Beta']
}

function BiboxSelection() {
    let history = useHistory()
    window.history.forward()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    useEffect(() => {
        let assetsToBeCached = [
            renderImage('backBtn'),
            renderImage('helpBtnInActive'),
            renderImage('clos'),
            renderImage('Pc'),
            renderImage('Computer'),
            renderImage('reconnect'),
            renderImage('skip'),
            renderImage('AddInfo'),
            renderImage('PlyComp'),
            renderImage('rightArrow'),
            renderImage('leftArrow'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])
    

    return (
        <div className={biboxSelectStyle.Select_Device}>
            <MainHeader
                title="Select Your Device"
                titlePosition="center"
                help_btnPosition="right"
                helper={<MainSlider />}
                showBluetoothBtn={false}
                showUserAccount={true}
                goBack={() => {
                    setSlideDirection(true)
                    sessionStorage.setItem('slideDirection', true)
                    history.push('/')
                }}
                isGoBack={false}
            />
            <div
                className={`${slideDirection === 'true' ? 'slide-right' : ''}`}
                style={{ height: '100%' }}
            >
                {' '}
                <SelectDevice />
            </div>

            {/* Plode PC / Humanoid VERSION */}
            <div style={{ position: 'absolute', bottom: '2px', right: '5px' }}>
                <span
                    style={{
                        fontSize: '15px',
                        color: 'grey',
                        fontFamily: 'Halcyon_Regular',
                    }}
                >
                    version: {getPlodeVersion('Ace')}
                </span>
            </div>
        </div>
    )
}

export default BiboxSelection

/**
 * css back btn
 * help active
 *
 */
