import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { jwtVerify, jwtDecrypt, SignJWT } from 'jose'
export const generateBrowserFingerprint = async () => {
    try {
        const fpPromise = FingerprintJS.load()
        const fp = await fpPromise
        const result = await fp.get()
        const visitorId = result.visitorId
        return visitorId
    } catch (e) {
        return 'bibox'
    }
}
export async function encrypt(sub, name, email, image, visitorId) {
    const secret = new TextEncoder().encode(visitorId)
    const payload = {
        sub: sub,
        name: name,
        email: email,
        image: image,
    }
    const alg = 'HS256'
    const jwt = await new SignJWT(payload)
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setExpirationTime('15d')
        .sign(secret)
    return jwt
}
export async function decrypt(token, visitorId) {
    const secret = new TextEncoder().encode(visitorId)
    try {
        const { payload } = await jwtVerify(token, secret)

        return payload
    } catch (e) {
        return false
    }
    // const { payload } = JWT.verify(decryptedToken, visitorId)
}
export async function verifyJWT(token, visitorId) {
    const secret = new TextEncoder().encode(visitorId)
    const payload = await jwtVerify(token, secret)
    return payload
}
