import React from 'react'
import { Switch, Route } from 'react-router-dom'

import VisualProgram from '../ProgramContainer/VisualProgram/VisualProgram'
import CodeProject from './Project/Project'
import HumanoidActionsCreate from './CreateAction/ActionsCreate'
import ActionsSelect from './CreateAction/ActionsSelect'

import HumanoidSelectFeatures from '../Humanoid/HumanoidSelectFeatures'
import SaveAction from './CreateAction/SaveAction'
import YourActions from './YourActions/YourActions'
import ShowAction from './YourActions/ShowAction'
import UploadAction from './CreateAction/UploadAction'
import ActionsCreate from './CreateAction/ActionsCreate'

function index() {
    return (
        <Switch>
            <Route exact path="/code" component={VisualProgram} />
            <Route exact path="/code/project" component={CodeProject} />
            <Route
                exact
                path="/code/project/action/show"
                component={ShowAction}
            />
            <Route exact path="/code/project/actions" component={YourActions} />
            <Route
                exact
                path="/code/project/action/save"
                component={SaveAction}
            />
            <Route
                exact
                path="/code/project/action/upload"
                component={UploadAction}
            />
            <Route
                exact
                path="/code/project/action/create"
                component={ActionsCreate}
            />
            <Route
                exact
                path="/code/project/action/select"
                component={ActionsSelect}
            />

            <Route
                exact
                path="/code/InternalAccessories"
                component={HumanoidSelectFeatures}
            />
        </Switch>
    )
}

export default index
