console.log('SOURCE FILE', process.env.PUBLIC_URL)

export const BackgroundSrc =
    process.env.PUBLIC_URL + '/imagesplay/Background.png'

export const IllusLoginSrc =
    process.env.PUBLIC_URL + '/images/login/illus_ace.png'

export const humanoid_img =
    process.env.PUBLIC_URL + '/images/login/humanoid_img.png'

export const LoginBckSrc = process.env.PUBLIC_URL + '/images/login/login_bg.png'

// DONE
export const Login2xSrc =
    process.env.PUBLIC_URL + '/images/login/login_bg@2x.png'
// DONE
export const Web1920Src =
    process.env.PUBLIC_URL + '/images/program/Web 1920 – 26@2x.png'
// DONE
export const buttonNewProjectSrc =
    process.env.PUBLIC_URL + '/images/program/button_newproject.png'
export const saveProjectSrc =
    process.env.PUBLIC_URL + '/images/program/button_saveproject.png'
export const aceClosedSrc =
    process.env.PUBLIC_URL + '/imagesplay/illusa_ace_closed.png'
export const ternConnectedSrc =
    process.env.PUBLIC_URL + '/imagesplay/illus_tern_connected.png'
export const buttonHelpSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_help.png'
export const joystickUPSrc =
    process.env.PUBLIC_URL + '/imagesplay/joystick_up.png'
export const joystickDOWNSrc =
    process.env.PUBLIC_URL + '/imagesplay/joystick_down.png'
export const joystickLeftSrc =
    process.env.PUBLIC_URL + '/imagesplay/joystick_left.png'
export const joystickRightSrc =
    process.env.PUBLIC_URL + '/imagesplay/joystick_right.png'
export const joystickMidSrc =
    process.env.PUBLIC_URL + '/imagesplay/joystick_mid.png'
export const joystickBGSrc = process.env.PUBLIC_URL + '/imagesplay/jstickbg.png'
export const joystickSrc = process.env.PUBLIC_URL + '/imagesplay/jstick.png'
export const buttonProximitySrc =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_proximity.png'
export const buttonColorrecogSrc =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_colorrecog.png'
export const autoLightSrc =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_autolight.png'
export const buttonGestureSrc =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_gesture.png'
export const buttonAce2Src =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_2.png'
export const buttonAce1Src =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_1.png'

export const buttonAce3Src =
    process.env.PUBLIC_URL + '/imagesplay/Ace/button_3.png'
export const humanoidAttentionSrc =
    process.env.PUBLIC_URL + '/imagesplay/humanoid/Newattention.png'

export const humanoidPushup =
    process.env.PUBLIC_URL + '/imagesplay/humanoid/Newpushup.png'

export const humanoidSquat =
    process.env.PUBLIC_URL + '/imagesplay/humanoid/Newsquat.png'

export const humanoidDanceSrc =
    process.env.PUBLIC_URL + '/imagesplay/humanoid/Newdance.png'

export const remoteButtonSpinSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_button_spin.png'

export const humanoidSaluteSrc =
    process.env.PUBLIC_URL + '/imagesplay/humanoid/Newsalute.png'
export const remoteButtonLightSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_button_light.png'
export const humanoidBowSrc =
    process.env.PUBLIC_URL + '/imagesplay/humanoid/Newbow.png'
export const playRemoteHornSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_button_horn.png'
export const playRemoteFlowLineSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_followline.png'
export const playRemoteDiscomodeSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_discomode.png'
export const playRemoreSmartmodeSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_smartmode.png'
export const playRemoreFollowmeSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_remote_followme.png'
export const playSpeakIllusVoiceSrc =
    process.env.PUBLIC_URL + 'imagesplay/play_speak_illus_voice.png'
export const playSpeakButtonBgSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_speak_button_bg.png'

export const speakhumanoid =
    process.env.PUBLIC_URL + '/imagesplay/speakhumanoid.png'

export const playSpeakButtonTalkSrc =
    process.env.PUBLIC_URL + '/imagesplay/play_speak_button_talk.png'
export const IllusVoiceSrc =
    process.env.PUBLIC_URL + '/imagesplay/illus_voice.png'
export const tracebuttonDrawSrc =
    process.env.PUBLIC_URL + '/imagesplay/trace_button_draw.png'
export const tracebuttonEraseSrc =
    process.env.PUBLIC_URL + '/imagesplay/trace_button_erase.png'
export const tracebuttonPlaySrc =
    process.env.PUBLIC_URL + '/imagesplay/trace_button_play.png'
export const imagesPlaySendSrc = process.env.PUBLIC_URL + '/imagesplay/send.png'
export const buttonBackSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_back.png'
export const buttonRemoteSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_remote.png'
export const buttonSpeakSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_speak.png'
export const buttonMusicSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_music.png'
export const buttonCameraSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_camera.png'
export const buttonTracemeSrc =
    process.env.PUBLIC_URL + '/imagesplay/button_traceme.png'

// DONE
export const buttonSaveProjectSrc =
    process.env.PUBLIC_URL + '/images/program/button_saveproject.png'

export const buttonExitSrc =
    process.env.PUBLIC_URL + '/images/login/button_exit@2x.png'

export const toggleButtonBar =
    process.env.PUBLIC_URL + '/toggleButton/togglebar.png'
