import React from 'react'
import { useHistory } from 'react-router'

import MainHeader from '../../ReusableComponents/Header/MainHeader'
import style from './YourAction.module.css'

function YourActions() {
    const history = useHistory()

    const allActionGroups = JSON.parse(
        localStorage.getItem('customActionGroupData')
    )

    const handleCardClick = async (actionGroupName) => {
        sessionStorage.setItem(
            'standbyActionGroupToLoad',
            JSON.stringify(allActionGroups[actionGroupName])
        )
        history.push('/code/project/action/show')
    }

    const handleImport = async (evt) => {
        let file = evt.target.files[0]
        if (!file) return

        const fileContent = await new Response(file).text()
        const actionGroup = JSON.parse(
            fileContent
                .split(' ')
                .map((c) => String.fromCharCode(c))
                .join('')
        )

        if (!actionGroup.createActionState || !actionGroup.name) return

        console.log(actionGroup)

        const existingCustomActionGroupData = JSON.parse(
            localStorage.getItem('customActionGroupData')
        )

        let newActionGroupData = {}
        if (existingCustomActionGroupData)
            newActionGroupData = existingCustomActionGroupData

        newActionGroupData[actionGroup.name] = actionGroup

        localStorage.setItem(
            'customActionGroupData',
            JSON.stringify(newActionGroupData)
        )

        window.location.reload()
    }

    return (
        <>
            <MainHeader
                title="Your Actions"
                isGoBack={true}
                goBack={() => {
                    history.push('/code/project')
                }}
                showImport={true}
                handleImport={handleImport}
            />
            {allActionGroups && Object.entries(allActionGroups).length !== 0 && (
                <div className={style.container1}>
                    {Object.entries(allActionGroups).map((action) => (
                        <div
                            className={style.card}
                            onClick={() => handleCardClick(action[0])}
                        >
                            <div className={style.name}>{action[0]}</div>
                        </div>
                    ))}
                </div>
            )}
            {(!allActionGroups ||
                Object.entries(allActionGroups).length == 0) && (
                <div className={style.container2}>
                    {'No saved actions found ☹️'}
                </div>
            )}
        </>
    )
}

export default YourActions
