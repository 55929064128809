import React from 'react'
import renderImage from '../../../source/importImg'
import style from './DialogModal.module.css'

//
// const props = {show: boolean [turns the modal on and off],
//                text: string [text to show],
//                handleDialogModalClose: () => {},
//                optionsToSelect: [{text: "YES", do: () => {} }, {text: "NO", do: () => {}}]}
//
// For WARNING Dialogs: ignore the optionsToSelect prop
//

function DialogModal({
    show,
    text,
    connection,
    showCloseBtn,
    handleDialogModalClose,
    optionsToSelect,
    selectionShow,
    mismatchShow,
    showError,
}) {
    return (
        <>
            {show && (
                <div className={style.container}>
                    <div className={style.box}>
                        <img
                            className={style.closeBtn}
                            style={
                                !showCloseBtn ? { visibility: 'hidden' } : null
                            }
                            src={renderImage('clos')}
                            onClick={handleDialogModalClose}
                        />
                        <div className={style.text}>{text}</div>

                        <div className={style.btngroup}>
                            {optionsToSelect &&
                                optionsToSelect.map((option, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={style.btn}
                                        onClick={option.do}
                                    >
                                        {option.text}
                                    </button>
                                ))}
                        </div>
                    </div>
                </div>
            )}

            {selectionShow && (
                <div className={style.selectionContainer}>
                    <div className={style.selectionbox}>
                        <div className={style.selection_SubBox}>
                            <div>
                                {' '}
                                <img
                                    className={style.closeBtns}
                                    style={
                                        !showCloseBtn
                                            ? { visibility: 'hidden' }
                                            : null
                                    }
                                    src={renderImage('clos')}
                                    onClick={handleDialogModalClose}
                                />
                            </div>

                            <div>
                                <img
                                    src={renderImage('settingImg')}
                                    className={style.img}
                                />
                            </div>

                            <div
                                className={style.text}
                                style={{
                                    marginTop: '2vh',
                                    marginBottom: '0rem',
                                    fontSize: '2.7vh',
                                }}
                            >
                                {text}
                            </div>

                            <div
                                className={style.btngroup}
                                style={{
                                    alignItems: 'end',
                                }}
                            >
                                {optionsToSelect &&
                                    optionsToSelect.map((option, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className={
                                                option.disabled
                                                    ? `${style.btns_disabled}`
                                                    : `${style.btns}`
                                            }
                                            onClick={option.do}
                                            disabled={option.disabled}
                                        >
                                            {option.text}
                                        </button>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {mismatchShow && (
                <div className={style.mismatchContainer}>
                    <div className={style.mismatch_SubBox}>
                        <div>
                            {' '}
                            <img
                                className={style.closeBtns}
                                style={
                                    !showCloseBtn
                                        ? { visibility: 'hidden' }
                                        : null
                                }
                                src={renderImage('clos')}
                                onClick={handleDialogModalClose}
                            />
                        </div>

                        <div>
                            <img
                                src="https://cdn-icons-png.flaticon.com/128/9304/9304589.png"
                                className={style.img}
                            />
                        </div>

                        <div
                            className={style.text}
                            style={{
                                marginTop: '2vh',
                                marginBottom: '0rem',
                                fontSize: '2.7vh',
                            }}
                        >
                            {text}
                        </div>

                        <div
                            className={style.btngroup}
                            style={{
                                alignItems: 'end',
                            }}
                        >
                            {optionsToSelect &&
                                optionsToSelect.map((option, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={style.btns}
                                        onClick={option.do}
                                    >
                                        {option.text}
                                    </button>
                                ))}
                        </div>
                    </div>
                </div>
            )}

            {connection &&
                (!text.includes('unable') ? (
                    <div className={style.connect_container1}>
                        <div className={style.connect_box1}>
                            <div>
                                <div className={style.connectingSpinner}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div className={style.content1}>{text}</div>
                        </div>
                    </div>
                ) : (
                    <div className={style.connect_container}>
                        <div className={style.connect_box}>
                            <div className={style.connect_SubBox}>
                                <div className={style.text}>
                                    <div className={style.error}>
                                        <img
                                            src="https://cdn-icons-png.flaticon.com/128/1304/1304037.png"
                                            alt="error"
                                        />
                                    </div>
                                    <div className={style.content}>{text}</div>
                                </div>
                                <div
                                    className={style.btngroup}
                                    style={{
                                        alignItems: 'end',
                                    }}
                                >
                                    {optionsToSelect &&
                                        optionsToSelect.map((option, index) => (
                                            <button
                                                key={index}
                                                type="button"
                                                className={style.btns}
                                                onClick={option.do}
                                                disabled={option.disabled}
                                            >
                                                {option.text}
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            {showError && (
                <div className={style.mismatchContainer}>
                    <div className={style.mismatchbox}>
                        <div className={style.mismatch_SubBox}>
                            <div>
                                {' '}
                                <img
                                    className={style.closeBtns}
                                    style={
                                        !showCloseBtn
                                            ? { visibility: 'hidden' }
                                            : null
                                    }
                                    src={renderImage('clos')}
                                    onClick={handleDialogModalClose}
                                />
                            </div>

                            <div>
                                <img
                                    src="https://cdn-icons-png.flaticon.com/128/9304/9304589.png"
                                    className={style.img}
                                />
                            </div>

                            <div
                                className={style.text}
                                style={{
                                    marginTop: '2vh',
                                    marginBottom: '0rem',
                                    fontSize: '2.7vh',
                                }}
                            >
                                {text}
                            </div>

                            <div
                                className={style.btngroup}
                                style={{
                                    alignItems: 'end',
                                }}
                            >
                                {optionsToSelect &&
                                    optionsToSelect.map((option, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            className={style.btns}
                                            onClick={option.do}
                                        >
                                            {option.text}
                                        </button>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default DialogModal
