import { connect } from 'react-redux'

//for klaw each servo id corresponding port position maping
export let klawLiveServoPortsNumber = {
    6: 13,
    7: 14,
    8: 15,
    9: 16,
    10: 17,
}
export let livePortsNumber = {
    A1: 1,
    A2: 2,
    B1: 3,
    B2: 4,
    C1: 5,
    C2: 6,
    D1: 7,
    D2: 8,
    E1: 9,
    E2: 10,
    F1: 11,
    F2: 12,
    M1: 13,
    M2: 14,
    M3: 15,
    M4: 16,
    servo_motor1: 17,
    servo_motor2: 18,
}
export const compName_character = {
    led: 'P'.charCodeAt(0),
    led_1c: 'P'.charCodeAt(0),
    mini_geared_motor: 'P'.charCodeAt(0),
    dynamex_motor: 'P'.charCodeAt(0),
    dc_motor: 'P'.charCodeAt(0),

    servo_motor: 'S'.charCodeAt(0),
    servo_motor_270: 'S'.charCodeAt(0),
    servo_motor_360: 'R'.charCodeAt(0),
}

function LiveButton(props) {
    const {
        isEnabled,
        isLiveBtnActive,
        liveBtnClick,
        assign,
        disableAfterSettingByt,
    } = props
    const connectedDevice = sessionStorage.getItem('connectedDevice')

    const handelClick = async () => {
        if (disableAfterSettingByt || !props.webSerial.isConnected || !assign) {
            return
        }

        liveBtnClick(!isLiveBtnActive)
        let PortConnections = props.assembly.PortConnections
        if (!isLiveBtnActive) {
            let data = Array(20).fill(79)
            data[0] = 'L'.charCodeAt(0)
            data[1] = 'S'.charCodeAt(0)
            Object.keys(PortConnections).map((portKey) => {
                let componentType = PortConnections[portKey]?.type
                if (
                    [
                        'led_1c',
                        'led',
                        'mini_geared_motor',
                        'dc_motor',
                        'dynamex_motor',
                        'servo_motor',
                        'servo_motor_270',
                        'servo_motor_360',
                    ].includes(componentType)
                ) {
                    data[livePortsNumber[portKey] + 1] =
                        compName_character[componentType]
                }
            })

            //for klaw set bytes
            if (connectedDevice == 'Klaw') {
                let sessionPortData = {}

                try {
                    sessionPortData = JSON.parse(
                        sessionStorage.getItem('humanoidPortData')
                    )
                    if (sessionPortData?.['4b']?.isPortSelected) {
                        for (let i = 13; i <= 17; i++) {
                            data[i + 1] = 'S'.charCodeAt(0)
                        }
                    }
                } catch (e) {
                    console.log('ERROR', e)
                }
            }

            props.worker.postMessage({
                type: 'writeArray',
                value: [
                    'S'.charCodeAt(0),
                    'T'.charCodeAt(0),
                    'O'.charCodeAt(0),
                    'P'.charCodeAt(0),
                ],
            })

            await new Promise((resolve) => setTimeout(resolve, 100))

            //disable all byte sending, to the board
            props.setDisableAfterSettingByt(true)

            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        }
    }

    return (
        // <div>
        <button
            style={{}}
            className={`live_btn ${
                isEnabled
                    ? isLiveBtnActive
                        ? 'live_btn_active'
                        : ''
                    : 'live_btn_disabled'
            } ${disableAfterSettingByt ? 'live_btn_opacity' : ''}`}
            onClick={handelClick}
        >
            Live
        </button>
        // </div>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        setDisableAfterSettingByt: (data) => {
            dispatch({ type: 'DISABLE_AFTER_SETTING_BYTE', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveButton)
