import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import ActionCard from './component/actioncards/ActionCard.js'
import remoteStyle from './remote.module.css'
import Jyostick from './component/joystick/Joystick.js'
import zingimage from '../../../../../Assets/humanoid/ZingImage.png'
import hexapodImage from '../../../../../Assets/hexapod/HexapodMain@3x.png'
import unicodeToChar from '../../../../../utils/unicodeToChar.js'
import MainHeader from '../../../../ReusableComponents/Header/MainHeader.jsx'
import RemSlider from '../../../../ReusableComponents/RemSlider/RemSlider.js'

import { sendBytes } from '../../../../ReusableComponents/ByteTransfer/byteTransfer.js'
import {
    ZingByteCodes,
    HexapodByteCodes,
} from '../../../../ReusableComponents/ByteTransfer/byteCodes.js'
import { useRef } from 'react'
import cacheAssets from '../../../../../utils/cacheAssets.js'
import renderImage from '../../../../../source/importImg.js'
import { cond } from 'lodash'
import { includes } from '../../../../../redux/reducers/data.js'

const ACTIONS = {
    HUMANOID: [
        { name: 'Attention', action: 'Attention' },
        { name: 'Side Kick', action: 'SideKickLeft' },
        { name: 'Side Kick', action: 'SideKickRight' },
        { name: 'Salute', action: 'Salute' },
        { name: 'Dance', action: 'Dance' },
        { name: 'Wave', action: 'Wave' },
        { name: 'Front Kick', action: 'FrontKickLeft' },
        { name: 'Front Kick', action: 'FrontKickRight' },
        { name: 'Fight', action: 'Fight' },
        { name: 'PushUp', action: 'PushUp' },
    ],
    HEXAPOD: [
        { name: 'Attention', action: 'Attention' },
        { name: 'Slide Left', action: 'SlideLeft' },
        { name: 'Slide Right', action: 'SlideRight' },
        { name: 'Wave', action: 'Greeting' },
        { name: 'Turn around', action: 'TurnAround' },
        { name: 'Fight', action: 'Fight' },
        { name: 'Attack', action: 'Attack' },
        { name: 'Defend', action: 'Defence' },
        { name: 'Dance', action: 'Dance' },
    ],
}

const ORIGINAL_ACTION_STATE = {
    HUMANOID: {
        isAttention: false,
        isSideKickLeft: false,
        isSideKickRight: false,
        isSalute: false,
        isDance: false,
        isWave: false,
        isForwardKickLeft: false,
        isForwardKickRight: false,
        isFight: false,
        isPushUp: false,
    },
    HEXAPOD: {
        isAttack: false,
        isSlideLeft: false,
        isSlideRight: false,
        isDance: false,
        isDefence: false,
        isTurnAround: false,
        isGreeting: false,
        isFight: false,
        isAttention: false,
    },
}
const ByteCodes = {
    HUMANOID: ZingByteCodes,
    HEXAPOD: HexapodByteCodes,
}

const Remote = ({ webSerial, worker }) => {
    let history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const [actionState, setActionState] = useState(
        ORIGINAL_ACTION_STATE[`${connectedDevice.toUpperCase()}`]
    )
    const [isBtnEnabled, setIsBtnEnabled] = useState(true)
    const [continousAction, setContinousAction] = useState()
    const [intervalId, setIntervalId] = useState(false)
    const isInitialRender = useRef(true)

    const sendDataAsPacket = useRef(false)
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'

    // This function splits the data into packets of custom size
    // and also sends them at the given interval
    const timePromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true)
            }, ms)
        })
    }

    const checkDone = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendDataAsPacket.current) {
                    return resolve(true)
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    //for zing v2 write Data
    const writeData_v2 = async (data) => {
        worker.postMessage({
            type: 'writeArray',
            value: data,
        })

        let waittime = 12000
        if (data[1] === 116 || data[1] === 115) {
            waittime = 90500
        }

        sessionStorage.setItem('sendDataAsPacket', 'true')
        let replyOK = await Promise.race([checkDone(), timePromise(waittime)])
        if (replyOK) {
            console.log('OK recieved')
            setActionState({
                ...ORIGINAL_ACTION_STATE[`${connectedDevice.toUpperCase()}`],
            })
            setIsBtnEnabled(true)
        }
    }
    const writeData = async (data) => {
        worker.postMessage({
            type: 'writeArray',
            value: data,
        })

        let waittime = 12000
        if (data[1] === 116 || data[1] === 115 || data[1] === 105) {
            waittime = 90500
        }

        sendDataAsPacket.current = true

        let replyOK = await Promise.race([checkDone(), timePromise(waittime)])
        if (replyOK) {
            console.log('OK recieved')
            setActionState({
                ...ORIGINAL_ACTION_STATE[`${connectedDevice.toUpperCase()}`],
            })
            setIsBtnEnabled(true)
        }
    }

    // Handling long click actions
    // useEffect(async () => {
    //     const handleContinuousAction = async () => {
    //         if (isInitialRender.current) {
    //             isInitialRender.current = false
    //             return
    //         }

    //         if (webSerial.isConnected) {
    //             // console.log(continousAction)

    //             let newActionState = { ...actionState }
    //             newActionState[`is${continousAction}`] = true
    //             setActionState({ ...newActionState })
    //             // actionState.current = newActionState

    //             await mouseActions()

    //             if (
    //                 !continousAction &&
    //                 !JSON.parse(sessionStorage.getItem('isClicked'))
    //             ) {
    //                 setTimeout(async () => {
    //                     worker.postMessage({
    //                         type: 'writeArray',
    //                         value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
    //                     })

    //                     let replyOK = await Promise.race([
    //                         checkDone(),
    //                         timePromise(10000),
    //                     ])
    //                     if (replyOK) {
    //                         setActionState({
    //                             ...ORIGINAL_ACTION_STATE[
    //                                 `${connectedDevice.toUpperCase()}`
    //                             ],
    //                         })
    //                         // actionState.current = {
    //                         //     ...ORIGINAL_ACTION_STATE[
    //                         //         `${connectedDevice.toUpperCase()}`
    //                         //     ],
    //                         // }
    //                         setIsBtnEnabled(true)
    //                     }
    //                 }, 1500)
    //             }
    //         }
    //     }
    //     handleContinuousAction()
    // }, [continousAction])

    const handleContinuousAction = async (continousAction) => {
        if (webSerial.isConnected) {
            //------------------------------------------------------
            if (checkActionState(actionState)) {
                sendDataAsPacket.current = false

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })

                let newActionState = {
                    ...ORIGINAL_ACTION_STATE[
                        `${connectedDevice.toUpperCase()}`
                    ],
                }
                newActionState[`is${continousAction}`] = true
                setActionState({ ...newActionState })
                // actionState.current = newActionState

                worker.postMessage({
                    type: 'writeArray',
                    value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                })
                sessionStorage.setItem('sendDataAsPacket', 'true')
                await new Promise((resolve) => setTimeout(resolve, 1500))
            } else {
                let newActionState = {
                    ...ORIGINAL_ACTION_STATE[
                        `${connectedDevice.toUpperCase()}`
                    ],
                }
                newActionState[`is${continousAction}`] = true
                setActionState({ ...newActionState })
            }
            //------------------------------------------------------

            // let newActionState = { ...actionState }
            // newActionState[`is${continousAction}`] = true
            // setActionState({ ...newActionState })

            await mouseActions(continousAction)
        }
    }

    //for continous action mouse up send 'Ra'
    const sendRa = () => {
        if (!JSON.parse(sessionStorage.getItem('isClicked'))) {
            setTimeout(async () => {
                worker.postMessage({
                    type: 'writeArray',
                    value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                })

                let replyOK = await Promise.race([
                    checkDone(),
                    timePromise(10000),
                ])
                if (replyOK) {
                    setActionState({
                        ...ORIGINAL_ACTION_STATE[
                            `${connectedDevice.toUpperCase()}`
                        ],
                    })
                    setIsBtnEnabled(true)
                }
            }, 1500)
        }
    }

    const mouseActions = async (continousAction) => {
        console.log('callbackyYYYYYYYYYYYYYYYYYY')
        if (continousAction) {
            worker.postMessage({
                type: 'writeArray',
                value: ByteCodes[`${connectedDevice.toUpperCase()}`][
                    continousAction.toUpperCase()
                ],
            })
            sessionStorage.setItem('sendDataAsPacket', 'true')
            await new Promise((resolve) => setTimeout(resolve, 1500))
        }

        while (JSON.parse(sessionStorage.getItem('isClicked'))) {
            worker.postMessage({
                type: 'writeArray',
                value:
                    connectedDevice == 'Hexapod'
                        ? HexapodByteCodes[continousAction.toUpperCase()]
                        : ZingByteCodes[continousAction.toUpperCase()],
            })
            sessionStorage.setItem('sendDataAsPacket', 'true')
            if (!JSON.parse(sessionStorage.getItem('isClicked'))) {
                break
            }
            await new Promise((resolve) => setTimeout(resolve, 1500))
        }
    }

    // setting isClicked --> false initially
    useEffect(() => {
        sessionStorage.setItem('flags', false)
    }, [])

    //check any button enable
    const checkActionState = (actionObj) => {
        if (Object.values(actionObj).some((value) => value === true)) {
            return true
        } else return false
    }

    // Handling click events
    const handleAction = async (action) => {
        if (webSerial.isConnected) {
            //-----------------------------------------------------------------
            if (checkActionState(actionState)) {
                sendDataAsPacket.current = false

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })

                let newActionState = {
                    ...ORIGINAL_ACTION_STATE[
                        `${connectedDevice.toUpperCase()}`
                    ],
                }
                newActionState[`is${action}`] = true
                console.log('Humanoid action state', newActionState)
                setActionState({ ...newActionState })
                // actionState.current = newActionState

                worker.postMessage({
                    type: 'writeArray',
                    value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                })
                sessionStorage.setItem('sendDataAsPacket', 'true')
                await new Promise((resolve) => setTimeout(resolve, 1500))
            } else {
                let newActionState = {
                    ...ORIGINAL_ACTION_STATE[
                        `${connectedDevice.toUpperCase()}`
                    ],
                }
                newActionState[`is${action}`] = true
                console.log('Humanoid action state', newActionState)
                setActionState({ ...newActionState })
                // actionState.current = newActionState
            }
            //-----------------------------------------------------------------

            console.log(`${connectedDevice} is ${action}ing...`)

            // let newActionState = { ...ORIGINAL_ACTION_STATE }
            // newActionState[`is${action}`] = true
            // console.log('Humanoid action state', newActionState)
            // setActionState({ ...newActionState })

            writeData(
                ByteCodes[`${connectedDevice.toUpperCase()}`][
                    action.toUpperCase()
                ]
            )
        }
    }

    useEffect(() => {
        const handleBytesSending = async () => {
            let assetsToBeCached = [
                // helpScreen
                renderImage('remoteH'),
                renderImage('remoteH1'),
                renderImage('remoteH2'),
                renderImage('Remt1'),
                renderImage('Remt2'),
                renderImage('Remt3'),
                renderImage('Remt4'),
                renderImage('Remt5'),
                renderImage('Remt6'),
                renderImage('PlayH1'),
                renderImage('PlayH2'),
                renderImage('PlayH3'),
                renderImage('PlayH4'),
                renderImage('PlayH5'),
                renderImage('PlayH6'),
                renderImage('PlayH7'),
                renderImage('PlayH8'),
                renderImage('PlayH9'),
            ]
            cacheAssets(assetsToBeCached)
            if (webSerial.isConnected) {
                const PLAY = [
                    'P'.charCodeAt(),
                    'L'.charCodeAt(),
                    'A'.charCodeAt(),
                    'Y'.charCodeAt(),
                ]
                const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
                const Attention = ['R'.charCodeAt(), 'a'.charCodeAt()]
                if (
                    connectedDevice == 'Ace' &&
                    sessionStorage.getItem('deviceVersion')?.startsWith('1')
                )
                    return
                writePort(PLAY)

                if (connectedDevice == 'Hexapod') {
                    setTimeout(async () => {
                        writePort(Attention)
                    }, 200)
                    await new Promise((resolve, reject) =>
                        setTimeout(() => {
                            resolve()
                        }, 200)
                    )
                }
                setTimeout(() => {})
            }
        }
        handleBytesSending()
    }, [])
    async function writePort(data) {
        worker.postMessage({
            type: 'writeArray',
            value: data,
        })
        console.log('PlayBytes Sent')
    }

    //recived the data for zing v2
    useEffect(() => {
        const messageHandler = (e) => {
            if (e.data.type === 'read') {
                let combinedResult = e.data.value
                if (
                    combinedResult.includes('RDONE') ||
                    combinedResult.includes('Rdone')
                ) {
                    sendDataAsPacket.current = false
                }
            }
        }
        worker.addEventListener('message', messageHandler)

        // return worker.removeEventListener('message', messageHandler)
    }, [])

    return (
        <div className={remoteStyle['remote-container']}>
            <MainHeader
                title="Joystick"
                showBluetoothBtn={true}
                helper={connectedDevice == 'Humanoid' ? <RemSlider /> : ''}
                goBack={async () => {
                    writeData(ZingByteCodes['ATTENTION'])
                    // await new Promise((resolve) => setTimeout(resolve, 2000))

                    sessionStorage.setItem('slideDirection', true)
                    history.push('/introduction')
                }}
            />
            {connectedDevice === 'Humanoid' ? (
                <div style={{ height: '80%' }}>
                    <div className={remoteStyle['sub-container']}>
                        <div></div>
                        <div className={remoteStyle['joystick-container']}>
                            <Jyostick
                                actionState={actionState}
                                isEnabled={isBtnEnabled}
                                sendBytes={sendBytes}
                                setContinousAction={setContinousAction}
                                handleAction={handleAction}
                                handleContinuousAction={handleContinuousAction}
                                sendRa={sendRa}
                            />
                        </div>
                        <div className={remoteStyle['zing-container']}>
                            <img src={zingimage} />
                        </div>
                        {/* State Icon */}

                        <div className={remoteStyle['action-container']}>
                            <div className={remoteStyle['actionBar-item-1']}>
                                {ACTIONS[connectedDevice.toUpperCase()].map(
                                    (key) => (
                                        <ActionCard
                                            key={key.action}
                                            name={key.name}
                                            action={key.action}
                                            isOn={
                                                actionState[`is${key.action}`]
                                            }
                                            isEnabled={isBtnEnabled}
                                            handleAction={handleAction}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '5% 28% 34% 33%',
                        position: 'absolute',
                        top: '29%',
                    }}
                >
                    <div></div>
                    <div style={{ height: '55%', width: '100%' }}>
                        <Jyostick
                            actionState={actionState}
                            isEnabled={isBtnEnabled}
                            sendBytes={sendBytes}
                            setContinousAction={setContinousAction}
                            handleAction={handleAction}
                            continousAction={continousAction}
                            handleContinuousAction={handleContinuousAction}
                            sendRa={sendRa}
                        />
                    </div>
                    <div>
                        <img
                            style={{
                                position: 'absolute',
                                height: '45%',
                                width: '27%',
                                left: '35%',
                            }}
                            src={hexapodImage}
                        />
                    </div>
                    <div
                        style={{
                            height: '50%',
                            width: '89%',
                            display: 'grid',
                            gridTemplateColumns: 'auto auto auto',
                            alignItems: 'inherit',
                        }}
                    >
                        {ACTIONS[connectedDevice.toUpperCase()].map((key) => (
                            <ActionCard
                                key={key.action}
                                name={key.name}
                                action={key.action}
                                isOn={actionState[`is${key.action}`]}
                                isEnabled={isBtnEnabled}
                                handleAction={handleAction}
                            />
                        ))}
                    </div>
                    {/* <div></div> */}
                </div>
            )}
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(Remote)
