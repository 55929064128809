import { useDragLayer } from 'react-dnd-latest'
import { Handle } from 'react-flow-renderer'
import Background_if from '../../../../Assets/With name/Group 5545@2x.png'
import Background_loop from '../../../../Assets/flowchart/repeat.png'
import Background_wait from '../../../../Assets/flowchart/wait.png'
import Background_repeat from '../../../../Assets/flowchart/loop.png'
import Background_output from '../../../../Assets/With name/Group 5544@2x.png'

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 100,
    top: 10,
    width: '100%',
    height: '100%',
}
function getItemStyles(initialOffset, currentOffset, xOffset, yOffset, type) {
    let Background
    if (type == 'wait') Background = Background_wait
    else if (type == 'if') Background = Background_if
    else if (type == 'output') Background = Background_output
    else if (type == 'loop') Background = Background_loop
    else if (type == 'repeat') Background = Background_repeat
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }
    let zoom = JSON.parse(sessionStorage.getItem('planeOffset')) || 1

    if (zoom != 1) zoom = zoom.zoom
    let height = 52,
        width = 120

    let { x, y } = currentOffset
    x = x - xOffset
    y = y - yOffset

    const transform = `translate(${x}px, ${y}px) scale(${zoom})`
    return {
        backgroundImage: `url(${Background}) `,
        height: `${height}px`,
        width: `${width}px`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',

        transform,
        WebkitTransform: transform,
    }
}
export const CustomDragLayer = (props) => {
    const { itemType, isDragging, item, initialOffset, currentOffset, delta } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getClientOffset(),
            isDragging: monitor.isDragging(),
            delta: monitor.getDifferenceFromInitialOffset(),
        }))
    const myStyle = {
        color: 'white',
        backgroundColor: 'DodgerBlue',
        padding: '10px',
        fontFamily: 'Sans-Serif',
    }
    function renderItem() {
        switch (item.id) {
            case 'if':
                return (
                    <div
                        style={getItemStyles(
                            initialOffset,
                            currentOffset,
                            158,
                            35,
                            'if'
                        )}
                        id="dragLayerif"
                    >
                        <Handle
                            type="target"
                            id="top"
                            position="top"
                            style={{ left: 90, top: 3 }}
                        />
                        <Handle
                            position="left"
                            style={{ left: 140, top: 18 }}
                            id="rYes"
                        />
                        <Handle
                            type="source"
                            position="left"
                            style={{ left: 140, top: 36 }}
                            id="rNo"
                        />
                    </div>
                )
            case 'loop':
                return (
                    <div
                        id="dragLayerloop"
                        style={getItemStyles(
                            initialOffset,
                            currentOffset,
                            158,
                            35,
                            'loop'
                        )}
                    >
                        <Handle
                            type="target"
                            id="top"
                            position="top"
                            style={{ left: 90, top: 3 }}
                        />
                        <Handle
                            type="source"
                            position="right"
                            style={{ left: 140, top: 26 }}
                            id="r"
                        />
                    </div>
                )
            case 'wait':
                return (
                    <div
                        id="dragLayerwait"
                        style={getItemStyles(
                            initialOffset,
                            currentOffset,
                            158,
                            35,
                            'wait'
                        )}
                    >
                        <Handle
                            position="top"
                            id="top"
                            style={{ left: 90, top: 3 }}
                        />
                    </div>
                )
            case 'output':
                return (
                    <div
                        id="dragLayeroutput"
                        style={getItemStyles(
                            initialOffset,
                            currentOffset,
                            158,
                            35,
                            'output'
                        )}
                    >
                        <Handle
                            type="target"
                            position="top"
                            id="top"
                            style={{ left: 90, top: 3 }}
                        />
                    </div>
                )
            case 'end/repeat':
                return (
                    <div
                        id="dragLayerend/repeat"
                        style={getItemStyles(
                            initialOffset,
                            currentOffset,
                            158,
                            35,
                            'repeat'
                        )}
                    >
                        <Handle
                            type="target"
                            position="top"
                            id="top"
                            style={{ left: 90, top: 3 }}
                        />
                    </div>
                )
            default:
                return <h1>hello</h1>
        }
    }
    if (!isDragging) {
        return null
    }

    return (
        <div style={layerStyles}>
            <div>{renderItem()}</div>
        </div>
    )
}
