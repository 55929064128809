import React, { useState } from 'react'
import selectbarX from '../../../Assets/Bisoft_UI/AppMode/selectbar@2.png'
import { useHistory } from 'react-router'
import backY from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import saveButton from '../../../Assets/Bisoft_UI/AppMode/save.png'
import close from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import NavBar from '../NavBar'
import { useIndexedDB } from 'react-indexed-db'

const EnterName = () => {
    const history = useHistory()
    const { update, getAll } = useIndexedDB('SavedFaces')
    const [name, setName] = useState('')
    const [popup, setPopup] = useState(false)
    const [popupText, setPopupText] = useState('')
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )

    const nameChange = (event) => {
        setName(event.target.value)
    }

    const saveName = async () => {
        if (name !== '' && name.length <= 16) {
            let blobdata = await fetch(sessionStorage.getItem('img')).then(
                (r) => r.blob()
            )
            let tensors = sessionStorage.getItem('tensor')
            sessionStorage.removeItem('tensor')
            sessionStorage.removeItem('img')
            var reader = new FileReader()
            reader.readAsDataURL(blobdata)
            reader.onloadend = function () {
                var base64data = reader.result
                update({
                    id: name,
                    imageUrl: base64data,
                    imagetensors: tensors,
                })
            }
            getAll().then(() => {
                history.push('/savedFaces')
            })
        } else {
            setPopup(true)
            setPopupText('Entered name cannot be more than 16 characters')
        }
    }

    const Popup = (props) => {
        return props.trigger ? (
            <div
                style={{
                    position: 'absolute',
                    top: '10vh',
                    left: '0',
                    width: '100%',
                    height: '85vh',
                    backgroundColor: 'rgba(255,255,255, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '4',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        padding: '10vh 12vw',
                        width: '50%',
                        maxWidth: '10vw',
                        backgroundColor: 'white',
                        border: '0.8vh solid orange',
                        borderRadius: '2.5vh',
                    }}
                >
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            top: '-3vh',
                            right: '-2vw',
                        }}
                        onClick={() => {
                            setPopup(false)
                        }}
                    />
                </div>
                <div
                    style={{
                        zIndex: '5',
                        width: '25%',
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <strong>{props.text}</strong>
                </div>
            </div>
        ) : (
            ''
        )
    }

    return (
        <div>
            {/* NavBar */}
            <NavBar
                selectbar={selectbarX}
                selectbarwidth="45vw"
                headers={[
                    { name: 'Enable', color: 'white' },
                    { name: 'Train', color: 'white' },
                    { name: 'Code', color: 'black' },
                    { name: 'Run', color: 'black' },
                ]}
            />
            {/* Main */}
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
                style={{ position: 'relative' }}
            >
                {' '}
                <input
                    style={{
                        border: 'none',
                        outline: 'none',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        backgroundColor: ' #f2f2f2',
                        textAlign: 'center',
                        color: 'black',
                        position: 'absolute',
                        top: '40vh',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        height: '7vh',
                        width: '20vw',
                        borderRadius: '12px',
                    }}
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={nameChange}
                />
                <div>
                    <img
                        style={{
                            position: 'absolute',
                            top: '49vh',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            cursor: 'pointer',
                        }}
                        draggable="false"
                        src={saveButton}
                        alt="captureButton"
                        onClick={saveName}
                        height="36px"
                        width="116px"
                    />
                </div>
            </div>

            {/* Footer */}
            <div>
                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        //-ms-transform: "translate(-50%, -50%)",
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/UploadImage')
                    }}
                />
                {popup ? <Popup trigger={popup} text={popupText} /> : ''}
            </div>
        </div>
    )
}
export default EnterName
