import React, { Component } from 'react'
import { Nav } from 'react-bootstrap'
import './style.css'
class Header extends Component {
    /*   handleClick = (e) => {
    const [click] = React.useState(true);
    if (click) {
      e.preventDefault();
    }
  }; */
    render() {
        return (
            <div className="HeaderContainer">
                <div></div>
            </div>
        )
    }
}

export default Header
