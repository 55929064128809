//for all devices sensors data for the Select Sensors page in data mood
export const sensorsData = {
    // Ace: {
    ACE0: {
        mic: {
            sensors: 'Mic',
            activeImgURL: 'Activemic',
            inactiveImgURL: 'mic',
            reduxName: 'isMic',
        },
        temperature: {
            sensors: 'Temperature',
            activeImgURL: 'Activetemp',
            inactiveImgURL: 'temp',
            reduxName: 'isTemperature',
        },
        touch0: {
            sensors: 'Touch Zero',
            activeImgURL: 'ActivetouchZero',
            inactiveImgURL: 'touchZero',
            reduxName: 'isTouchZero',
        },
        touch1: {
            sensors: 'Touch One',
            activeImgURL: 'ActivetouchOne',
            inactiveImgURL: 'touchOne',
            reduxName: 'isTouchOne',
        },
        touch2: {
            sensors: 'Touch Two',
            activeImgURL: 'ActivetouchTwo',
            inactiveImgURL: 'touchTwo',
            reduxName: 'isTouchTwo',
        },
        distancesensor: {
            sensors: 'Distance Sensor',
            activeImgURL: 'Activedistance',
            inactiveImgURL: 'distance',
            reduxName: 'isDistanceSensors',
        },
        gesturesensor: {
            sensors: 'Gesture Sensor',
            activeImgURL: 'Activegesture',
            inactiveImgURL: 'gesture',
            reduxName: 'isGestureSensor',
        },
        colorsensor: {
            sensors: 'Color Sensor',
            activeImgURL: 'Activecolor',
            inactiveImgURL: 'color',
            reduxName: 'isColorSensor',
        },
        lightsensor: {
            sensors: 'Light Sensor',
            activeImgURL: 'Activelight',
            inactiveImgURL: 'light',
            reduxName: 'isLightSensor',
        },
    },
    ACE1: {
        mic: {
            sensors: 'Mic',
            activeImgURL: 'Activemic',
            inactiveImgURL: 'mic',
            reduxName: 'isMic',
        },
        temperature: {
            sensors: 'Temperature',
            activeImgURL: 'Activetemp',
            inactiveImgURL: 'temp',
            reduxName: 'isTemperature',
        },
        touch0: {
            sensors: 'Touch Zero',
            activeImgURL: 'ActivetouchZero',
            inactiveImgURL: 'touchZero',
            reduxName: 'isTouchZero',
        },
        touch1: {
            sensors: 'Touch One',
            activeImgURL: 'ActivetouchOne',
            inactiveImgURL: 'touchOne',
            reduxName: 'isTouchOne',
        },
        touch2: {
            sensors: 'Touch Two',
            activeImgURL: 'ActivetouchTwo',
            inactiveImgURL: 'touchTwo',
            reduxName: 'isTouchTwo',
        },
        touch3: {
            sensors: 'Touch Three',
            activeImgURL: 'ActivetouchThree',
            inactiveImgURL: 'touchThree',
            reduxName: 'isTouchThree',
        },
        distancesensor: {
            sensors: 'Distance Sensor',
            activeImgURL: 'Activedistance',
            inactiveImgURL: 'distance',
            reduxName: 'isDistanceSensors',
        },
        gesturesensor: {
            sensors: 'Gesture Sensor',
            activeImgURL: 'Activegesture',
            inactiveImgURL: 'gesture',
            reduxName: 'isGestureSensor',
        },
        colorsensor: {
            sensors: 'Color Sensor',
            activeImgURL: 'Activecolor',
            inactiveImgURL: 'color',
            reduxName: 'isColorSensor',
        },
        lightsensor: {
            sensors: 'Light Sensor',
            activeImgURL: 'Activelight',
            inactiveImgURL: 'light',
            reduxName: 'isLightSensor',
        },
        battery: {
            sensors: 'Battery',
            activeImgURL: 'Activebatt',
            inactiveImgURL: 'batt',
            reduxName: 'isBattery',
        },
        accelerometerX: {
            sensors: 'AccelerometerX',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isAccelerometerX',
        },
        accelerometerY: {
            sensors: 'AccelerometerY',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isAccelerometerY',
        },
        accelerometerZ: {
            sensors: 'AccelerometerZ',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isAccelerometerZ',
        },
        GyroX: {
            sensors: 'Gyro X',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isGyroX',
        },
        GyroY: {
            sensors: 'Gyro Y',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isGyroY',
        },
        GyroZ: {
            sensors: 'Gyro Z',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isGyroZ',
        },
    },
    // },
    // Humanoid: {
    HUMANOID0: {
        battery: {
            sensors: 'Battery',
            activeImgURL: 'Activebatt',
            inactiveImgURL: 'batt',
            reduxName: 'isBattery',
        },
        accelerometerX: {
            sensors: 'AccelerometerX',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isAccelerometerX',
        },
        accelerometerY: {
            sensors: 'AccelerometerY',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isAccelerometerY',
        },
        accelerometerZ: {
            sensors: 'AccelerometerZ',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isAccelerometerZ',
        },
        GyroX: {
            sensors: 'Gyro X',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isGyroX',
        },
        GyroY: {
            sensors: 'Gyro Y',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isGyroY',
        },
        GyroZ: {
            sensors: 'Gyro Z',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isGyroZ',
        },
    },
    HUMANOID1: {
        ultrasonic: {
            sensors: 'Ultrasonic',
            activeImgURL: 'ActiveHeadUltrasonice',
            inactiveImgURL: 'headUltrasonic',
            reduxName: 'isUltrasonic',
        },
        battery: {
            sensors: 'Battery',
            activeImgURL: 'Activebatt',
            inactiveImgURL: 'batt',
            reduxName: 'isBattery',
        },
        accelerometerX: {
            sensors: 'AccelerometerX',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isAccelerometerX',
        },
        accelerometerY: {
            sensors: 'AccelerometerY',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isAccelerometerY',
        },
        accelerometerZ: {
            sensors: 'AccelerometerZ',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isAccelerometerZ',
        },
        GyroX: {
            sensors: 'Gyro X',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isGyroX',
        },
        GyroY: {
            sensors: 'Gyro Y',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isGyroY',
        },
        GyroZ: {
            sensors: 'Gyro Z',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isGyroZ',
        },
    },
    HUMANOID2: {
        ultrasonic: {
            sensors: 'Ultrasonic',
            activeImgURL: 'ActiveHeadUltrasonice',
            inactiveImgURL: 'headUltrasonic',
            reduxName: 'isUltrasonic',
        },
        battery: {
            sensors: 'Battery',
            activeImgURL: 'Activebatt',
            inactiveImgURL: 'batt',
            reduxName: 'isBattery',
        },
        accelerometerX: {
            sensors: 'AccelerometerX',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isAccelerometerX',
        },
        accelerometerY: {
            sensors: 'AccelerometerY',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isAccelerometerY',
        },
        accelerometerZ: {
            sensors: 'AccelerometerZ',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isAccelerometerZ',
        },
        GyroX: {
            sensors: 'Gyro X',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isGyroX',
        },
        GyroY: {
            sensors: 'Gyro Y',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isGyroY',
        },
        GyroZ: {
            sensors: 'Gyro Z',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isGyroZ',
        },
    },
    // },
    // Hexapod: {
    HEXAPOD1: {
        ultrasonic: {
            sensors: 'Ultrasonic',
            activeImgURL: 'ActiveHeadUltrasonice',
            inactiveImgURL: 'headUltrasonic',
            reduxName: 'isUltrasonic',
        },
        battery: {
            sensors: 'Battery',
            activeImgURL: 'Activebatt',
            inactiveImgURL: 'batt',
            reduxName: 'isBattery',
        },
        accelerometerX: {
            sensors: 'AccelerometerX',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isAccelerometerX',
        },
        accelerometerY: {
            sensors: 'AccelerometerY',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isAccelerometerY',
        },
        accelerometerZ: {
            sensors: 'AccelerometerZ',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isAccelerometerZ',
        },
        GyroX: {
            sensors: 'Gyro X',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isGyroX',
        },
        GyroY: {
            sensors: 'Gyro Y',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isGyroY',
        },
        GyroZ: {
            sensors: 'Gyro Z',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isGyroZ',
        },
    },
    HEXAPOD2: {
        ultrasonic: {
            sensors: 'Ultrasonic',
            activeImgURL: 'ActiveHeadUltrasonice',
            inactiveImgURL: 'headUltrasonic',
            reduxName: 'isUltrasonic',
        },
        battery: {
            sensors: 'Battery',
            activeImgURL: 'Activebatt',
            inactiveImgURL: 'batt',
            reduxName: 'isBattery',
        },
        accelerometerX: {
            sensors: 'AccelerometerX',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isAccelerometerX',
        },
        accelerometerY: {
            sensors: 'AccelerometerY',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isAccelerometerY',
        },
        accelerometerZ: {
            sensors: 'AccelerometerZ',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isAccelerometerZ',
        },
        GyroX: {
            sensors: 'Gyro X',
            activeImgURL: 'ActiveacceX',
            inactiveImgURL: 'acceX',
            reduxName: 'isGyroX',
        },
        GyroY: {
            sensors: 'Gyro Y',
            activeImgURL: 'ActiveacceY',
            inactiveImgURL: 'acceY',
            reduxName: 'isGyroY',
        },
        GyroZ: {
            sensors: 'Gyro Z',
            activeImgURL: 'ActiveacceZ',
            inactiveImgURL: 'acceZ',
            reduxName: 'isGyroZ',
        },
    },
    // },
    // Roboki: {
    ROBOKI1: {
        switch1: {
            sensors: 'Switch One',
            activeImgURL: 'ActiveSwitch1',
            inactiveImgURL: 'switch1',
            reduxName: 'isSwitchOne',
        },
        switch2: {
            sensors: 'Switch Two',
            activeImgURL: 'ActiveSwitch2',
            inactiveImgURL: 'switch2',
            reduxName: 'isSwitchTwo',
        },
    },
    // },
}

//here {dataKey:value} value is the name of the state of sensors data store in the LinGraphChart components
//in line Graph and Bar Graph the sidebar data
export const graphSidebarData = {
    mic: {
        sensors: 'Microphone',
        bgColour: '#9F0344',
        reduxName: 'isMic',
        dataKey: 'mic',
    },
    temperature: {
        sensors: 'Temperature',
        bgColour: '#B47623',
        reduxName: 'isTemperature',
        dataKey: 'temp',
    },
    battery: {
        sensors: 'Battery',
        bgColour: '#D9B102',
        reduxName: 'isBattery',
        dataKey: 'batt',
    },
    ultrasonic: {
        sensors: 'Ultrasonic',
        bgColour: '#58D68D',
        reduxName: 'isUltrasonic',
        dataKey: 'ultrasonic',
    },
    touch0: {
        sensors: 'Touch 0',
        bgColour: '#006D70',
        reduxName: 'isTouchZero',
        dataKey: 'touch0',
    },
    touch1: {
        sensors: 'Touch 1',
        bgColour: '#260659',
        reduxName: 'isTouchOne',
        dataKey: 'touch1',
    },
    touch2: {
        sensors: 'Touch 2',
        bgColour: '#BC7C68',
        reduxName: 'isTouchTwo',
        dataKey: 'touch2',
    },
    touch3: {
        sensors: 'Touch 3',
        bgColour: '#ED5B22',
        reduxName: 'isTouchThree',
        dataKey: 'touch3',
    },
    accelerometerX: {
        sensors: 'AccelerometerX',
        bgColour: '#EC9EA4',
        reduxName: 'isAccelerometerX',
        dataKey: 'acceX',
    },
    accelerometerY: {
        sensors: 'AccelerometerY',
        bgColour: '#EDDD8A',
        reduxName: 'isAccelerometerY',
        dataKey: 'acceY',
    },
    accelerometerZ: {
        sensors: 'AccelerometerZ',
        bgColour: '#97CEEA',
        reduxName: 'isAccelerometerZ',
        dataKey: 'acceZ',
    },
    GyroX: {
        sensors: 'Gyro X',
        bgColour: '#B39485',
        reduxName: 'isGyroX',
        dataKey: 'gyroX',
    },
    GyroY: {
        sensors: 'Gyro Y',
        bgColour: '#FA8D5A',
        reduxName: 'isGyroY',
        dataKey: 'gyroY',
    },
    GyroZ: {
        sensors: 'Gyro Z',
        bgColour: '#541743',
        reduxName: 'isGyroZ',
        dataKey: 'gyroZ',
    },
    distancesensor: {
        sensors: 'Proximity Sensor',
        bgColour: '#B24E4E',
        reduxName: 'isDistanceSensors',
        dataKey: 'proximity',
    },
    lightsensor: {
        sensors: 'Light Sensor',
        bgColour: '#7E3733',
        reduxName: 'isLightSensor',
        dataKey: 'light',
    },
    colorsensorRed: {
        sensors: 'Color Sensor (Red)',
        bgColour: 'red',
        reduxName: 'isColorSensor',
        dataKey: 'red',
    },
    colorsensorBlue: {
        sensors: 'Color Sensor (Blue)',
        bgColour: 'blue',
        reduxName: 'isColorSensor',
        dataKey: 'blue',
    },
    colorsensorGreen: {
        sensors: 'Color Sensor (Green)',
        bgColour: 'green',
        reduxName: 'isColorSensor',
        dataKey: 'green',
    },
    gesturesensor: {
        sensors: 'Gesture Sensor',
        bgColour: '#382903',
        reduxName: 'isGestureSensor',
        dataKey: 'gesture',
    },
    switch1: {
        sensors: 'Switch One',
        bgColour: '#8BC9A6 ',
        reduxName: 'isSwitchOne',
        dataKey: 'switch1',
    },
    switch2: {
        sensors: 'Switch Two',
        bgColour: '#6774D2 ',
        reduxName: 'isSwitchTwo',
        dataKey: 'switch2',
    },
}
