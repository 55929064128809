
import inactiveWave from '../../../../../../../Assets/humanoid/wave.png'
import activeWave from '../../../../../../../Assets/humanoid/button talkback-2.png'
import activePick from '../../../../../../../Assets/humanoid/button talkback-2.png'
import inactivePick from '../../../../../../../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import activePick_left_drop_right from '../../../../../../../Assets/humanoid/button talkback-2.png'
import inactivePick_left_drop_right from '../../../../../../../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import activePick_right_drop_left from '../../../../../../../Assets/humanoid/button talkback-2.png'
import inactivePick_right_drop_left from '../../../../../../../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import activeCircle from '../../../../../../../Assets/humanoid/button talkback-2.png'
import inactiveCircle from '../../../../../../../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import activeScribble from '../../../../../../../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import inactiveScribble from '../../../../../../../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import actionCardStyle from './actionCard.module.css'

const IMAGES = {
   
    activeWave,
    inactiveWave,
    activePick,
    inactivePick,
    activePick_left_drop_right,
    inactivePick_left_drop_right,
    activePick_right_drop_left,
    inactivePick_right_drop_left,
    activeCircle,
    inactiveCircle,
    activeScribble,
    inactiveScribble
}

const disabledCard = {
    pointerEvents: 'None',
}

const ActionCard = ({ name, isOn, action, handleAction, isEnabled }) => {
    return (
        <div
            className={actionCardStyle['actionCard-container']}
            style={{ userSelect: 'none' }}
        >
            <img
                className={actionCardStyle['actionCard-img']}
                style={
                    isEnabled 
                        ? null
                        : disabledCard
                }
                src={
                    isOn
                        ? IMAGES[`active${action}`]
                        : IMAGES[`inactive${action}`]
                }
                onClick={() => handleAction(action)}
            />
            <p className={actionCardStyle['actionCard-title']}>{name}</p>
        </div>
    )
}
export default ActionCard
