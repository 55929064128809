import React, { useEffect, useLayoutEffect, useState } from 'react'
import './InternalAccessoriesScreen.css'
import { webSerialConnect } from '../../../redux/actions/index'
import { connect } from 'react-redux'
import io from 'socket.io-client'
import cacheAssets from '../../../utils/cacheAssets'
import renderCompImg from '../../../source/Comp_Img'

import PortConnections from '../../Assembly/PortConnections'
/* IMAGES  */
import Modal from 'react-modal'

import { internalaccessoriesObj } from './InternalAccessoriesStorage'
import { Link, useHistory } from 'react-router-dom'
import renderPrgImage from '../../../source/programImg'
import InternalScPrgm from '../../ReusableComponents/PrgmSlider/InternalPrgm/InternalScPrgm'
import { object } from 'prop-types'

import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import Footer from '../../ReusableComponents/FooterComponent'

//const socket = io.connect("http://localhost:3008");

// sessionStorage.setItem(
//   "interAccessoriesData",
//   JSON.stringify(internalaccessoriesObj)
// );

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        height: '23%',
        width: ' 25%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '5px solid rgb(255,140,25)',
        borderRadius: '20px',
        overflow: 'initial',
        // zIndex: 1,
    },
}

function InternalAccessoriesScreen(props) {
    console.log('Props', props)
    const history = useHistory()
    let oledPort = JSON.parse(sessionStorage.getItem('assembly'))

    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )

    const [isDistanceSensors, setDistanceSensors] = useState(false)
    const [isGestureSensor, setGestureSensor] = useState(false)
    const [isLightSensor, setLightSensor] = useState(false)
    const [isColorSensor, setColorSensor] = useState(false)

    const [isTemperature, setTemperature] = useState(false)
    const [isMic, setMic] = useState(false)

    // input
    const [isTouchZero, setTouchZero] = useState(false)
    const [isTouchOne, setTouchOne] = useState(false)
    const [isTouchTwo, setTouchTwo] = useState(false)

    // outPut
    const [isTouchZeroOutput, setTouchZeroOutput] = useState(false)
    const [isTouchOneOutput, setTouchOneOutput] = useState(false)
    const [isTouchTwoOutput, setTouchTwoOutput] = useState(false)

    const [isLeftEye, setEyeLeft] = useState(false)
    const [isRightEye, setEyeRight] = useState(false)
    const [isbuzzer, setbuzzer] = useState(false)

    const [isSmileOne, setSimleOne] = useState(false)
    const [isSmileTwo, setSimleTwo] = useState(false)
    const [isSmileThree, setSimleThree] = useState(false)
    const [isSmileFour, setSimleFour] = useState(false)

    const [erasedProgram, setErasedProgram] = useState(false)

    // const [isColorSensor, setColorSensor] = useState(false);

    const styleInput = {
        width: '31vw',
        height: '32vw',
        position: 'relative',
        backgroundImage: `url(${renderPrgImage('popupcardType')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 90%',

        // backgroundSize: "contain",
    }

    const styleoutput = {
        width: '31vw',
        height: '32vw',
        position: 'relative',
        backgroundImage: `url(${renderPrgImage('popupcardType')})`,
        backgroundRepeat: 'no-repeat',
        // backgroundSize: "contain",
        backgroundSize: '100% 90%',
    }

    const styleDevicePC = {
        width: '25vw',
        height: '22vw',

        backgroundImage: `url(${renderPrgImage('PCimg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '76% 96%',
        backgroundPosition: '50% 50%',
        zIndex: 110,
        top: '7%',
        position: 'relative',
        aspectRatio: '1 / 1',
    }

    useLayoutEffect(() => {
        setTouchZero(props.indexData.concept.internalaccessories.isTouchZero)
        setTouchOne(props.indexData.concept.internalaccessories.isTouchOne)
        setTouchTwo(props.indexData.concept.internalaccessories.isTouchTwo)

        setTouchZeroOutput(
            props.indexData.concept.internalaccessories.isTouchZeroOutput
        )
        setTouchOneOutput(
            props.indexData.concept.internalaccessories.isTouchOneOutput
        )
        setTouchTwoOutput(
            props.indexData.concept.internalaccessories.isTouchTwoOutput
        )

        setEyeLeft(props.indexData.concept.internalaccessories.isLeftEye)
        setEyeRight(props.indexData.concept.internalaccessories.isRightEye)
        setbuzzer(props.indexData.concept.internalaccessories.isbuzzer)

        setSimleOne(props.indexData.concept.internalaccessories.isSmileOne)
        setSimleTwo(props.indexData.concept.internalaccessories.isSmileTwo)
        setSimleThree(props.indexData.concept.internalaccessories.isSmileThree)
        setSimleFour(props.indexData.concept.internalaccessories.isSmileFour)

        setLightSensor(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isLightSensor
        )
        setColorSensor(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isColorSensor
        )
        setGestureSensor(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isGestureSensor
        )
        setDistanceSensors(
            props.indexData.concept.internalaccessories.Four_in_one_sensor
                .isDistanceSensors
        )
        setTemperature(
            props.indexData.concept.internalaccessories.isTemperature
        )
        setMic(props.indexData.concept.internalaccessories.isMic)
    })
    useEffect(() => {
        let hhh = JSON.parse(sessionStorage.getItem('SavedGDriveData'))
        let names = JSON.parse(sessionStorage.getItem('saveProps')) || null
        // console.log("Names", hhh[0].name);
        if (hhh != null && names != null) {
            for (let i = 0; i < hhh.length; i++) {
                if (names.name == hhh[i].name) {
                    sessionStorage.setItem('pip', true)
                    sessionStorage.setItem('name', names.name)
                    console.log(
                        'KK',
                        hhh[i].concept.internalaccessories.isTouchOne
                    )
                    Object.keys(hhh[i]).map((key, value) => {
                        console.log('KEYS', key, value)
                        switch (key) {
                            case 'concept': {
                                sessionStorage.setItem(
                                    'concept',
                                    JSON.stringify(hhh[i].concept)
                                )
                            }
                            case 'assembly': {
                                sessionStorage.setItem(
                                    'assembly',
                                    JSON.stringify(hhh[i].assembly)
                                )
                            }
                            case 'logic': {
                                sessionStorage.setItem(
                                    'logic',
                                    JSON.stringify(hhh[i].logic)
                                )
                            }
                            case 'programEnd': {
                                sessionStorage.setItem(
                                    'programEnd',
                                    JSON.stringify(hhh[i].programEnd)
                                )
                            }
                            case 'SelectedStatus': {
                                sessionStorage.setItem(
                                    'SelectedStatus',
                                    JSON.stringify(hhh[i].SelectedStatus)
                                )
                            }
                            // case "concept": {
                            //   sessionStorage.setItem("concept", history.concept);
                            // }
                            // case "concept": {
                            //   sessionStorage.setItem("concept", history.concept);
                            // }
                        }
                    })
                    if (
                        sessionStorage.getItem('internalAcessoriesReload') ==
                        'true'
                    ) {
                        sessionStorage.setItem(
                            'internalAcessoriesReload',
                            false
                        )
                        window.location.reload()
                    }
                }
            }
            // window.location.reload();
        }
    })
    const removeFromProgram = async (e, obj) => {
        if (obj.length == 0) return
        for (var key in obj) {
            if (
                obj[key].type == 'hardware' ||
                obj[key].type == 'output' ||
                obj[key].type == 'variable_output'
            ) {
                if (e.slice(0, 5) === 'value') {
                    obj[key].state[e] = 0
                } else {
                    obj[key].state[e] = false
                }
            }
            if (
                obj[key].type == 'condition' ||
                obj[key].type == 'if' ||
                obj[key].type == 'else' ||
                obj[key].type == 'sensor' ||
                obj[key].type == 'variable' ||
                obj[key].type == 'loop'
            ) {
                await removeFromProgram(e, obj[key].subprogram)
            }
        }
    }
    const handleFounInOneSensor = (e) => {
        const assembly = JSON.parse(sessionStorage.getItem('assembly'))
        // console.log("assembly", assembly.PortConnections.D.type);
        let portD
        try {
            portD = assembly.PortConnections.D.type
        } catch (error) {
            portD = ''
        }

        let isPortOccupied =
            assembly.PortConnections.D ||
            assembly.PortConnections.D1 ||
            assembly.PortConnections.D2 ||
            false

        console.log('ISTRUEEEE', oledPort, 'ISPORTOCCUPIED', isPortOccupied)

        if (isPortOccupied == 'true') {
            setFeedbackMsg('D port is occupied')
            isShowFeedback(true)
            return
        }

        switch (e.target.alt) {
            case 'distancesensors': {
                if (portD != 'OLED' && portD != '') {
                    var x = document.getElementById('snackbar16_D2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)

                    break
                }
                if (isDistanceSensors) {
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    props.selecteComponent({ isDistanceSensors: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            ['4-IN-1SENSOR→DISTANCE'].includes(
                                data.state.source.split(' ').join('')
                            )
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    setDistanceSensors(false)
                    var x = document.getElementById('snackbar16')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: true,
                        isColorSensor: false,
                        isLightSensor: false,
                        isGestureSensor: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = true
                    setDistanceSensors(true)
                    setColorSensor(false)
                    setGestureSensor(false)
                    setLightSensor(false)

                    var x = document.getElementById('snackbar16')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }

                break
            }
            case 'gesturesensor': {
                if (portD != 'OLED' && portD != '') {
                    var x = document.getElementById('snackbar17_D2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)

                    break
                }
                if (isGestureSensor) {
                    props.selecteComponent({ isGestureSensor: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            ['4-IN-1SENSOR→GESTURE'].includes(
                                data.state.source.split(' ').join('')
                            )
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    setGestureSensor(false)
                    var x = document.getElementById('snackbar17')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: false,
                        isColorSensor: false,
                        isLightSensor: false,
                        isGestureSensor: true,
                    })

                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = true
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    setDistanceSensors(false)
                    setColorSensor(false)
                    setGestureSensor(true)
                    setLightSensor(false)
                    var x = document.getElementById('snackbar17')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'lightsensor': {
                if (portD != 'OLED' && portD != '') {
                    var x = document.getElementById('snackbar18_D2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)

                    break
                }
                if (isLightSensor) {
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    props.selecteComponent({ isLightSensor: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            ['4-IN-1SENSOR→LIGHT'].includes(
                                data.state.source.split(' ').join('')
                            )
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)

                    setLightSensor(false)
                    var x = document.getElementById('snackbar18')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: false,
                        isColorSensor: false,
                        isLightSensor: true,
                        isGestureSensor: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = true
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    setDistanceSensors(false)
                    setColorSensor(false)
                    setGestureSensor(false)
                    setLightSensor(true)
                    var x = document.getElementById('snackbar18')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'colorsensor': {
                if (portD != 'OLED' && portD != '') {
                    var x = document.getElementById('snackbar19_D2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)

                    break
                }
                if (isColorSensor) {
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = false
                    props.selecteComponent({ isColorSensor: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            [
                                '4-IN-1SENSOR→RED',
                                '4-IN-1SENSOR→BLUE',
                                '4-IN-1SENSOR→GREEN',
                            ].includes(data.state.source.split(' ').join(''))
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    setColorSensor(false)
                    var x = document.getElementById('snackbar19')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({
                        isDistanceSensors: false,
                        isColorSensor: true,
                        isLightSensor: false,
                        isGestureSensor: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    props.indexData.logic.program.map((data) => {
                        if (data.type === 'sensor') {
                            if (data.state.source.includes('4-IN-1 SENSOR')) {
                                data.state.source = ''
                                data.state.value = 0
                                data.state.value2 = 0
                                data.state.condition = ''
                            }
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.Four_in_one_sensor.isColorSensor = true
                    internalaccessoriesObj.Four_in_one_sensor.isLightSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isGestureSensor = false
                    internalaccessoriesObj.Four_in_one_sensor.isDistanceSensors = false
                    setDistanceSensors(false)
                    setColorSensor(true)
                    setGestureSensor(false)
                    setLightSensor(false)
                    var x = document.getElementById('snackbar19')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
        }
    }

    const assembly = JSON.parse(sessionStorage.getItem('assembly'))

    //////////// BUGFIX PLAYC-23 & PLAYC-39 ////////////

    const [showFeedback, isShowFeedback] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState('')

    useEffect(() => {
        if (!showFeedback) return

        const timeout = setTimeout(() => {
            isShowFeedback(false)
        }, 1500)
        return () => {
            clearTimeout(timeout)
        }
    }, [showFeedback])

    const isPlayShield = sessionStorage.getItem('shield') === 'true' || false
    const isPeeCeeATR = sessionStorage.getItem('peeCeeATR') === 'true' || false
    const isarmShield = sessionStorage.getItem('armShield') === 'true' || false

    const isM1Occupied = assembly.PortConnections.M1 || false
    const isM2Occupied = assembly.PortConnections.M2 || false
    const isM3Occupied = assembly.PortConnections.M3 || false
    const isM4Occupied = assembly.PortConnections.M4 || false

    /////////////////////////////////////////
    // let clicked=JSON.parse(sessionStorage.getItem("clicked"))||[];
    const handleEventsClick = async (e) => {
        switch (e.target.alt) {
            case 'mic': {
                if (isMic) {
                    internalaccessoriesObj.isMic = false
                    props.selecteComponent({ isMic: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'MICROPHONE'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    setMic(false)
                    var x = document.getElementById('snackbar1')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isMic = true
                    props.selecteComponent({ isMic: true })

                    setMic(true)
                    var x = document.getElementById('snackbar1')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'temperature': {
                if (isTemperature) {
                    internalaccessoriesObj.isTemperature = false

                    props.selecteComponent({ isTemperature: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TEMPERATURE'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)

                    setTemperature(false)
                    var x = document.getElementById('snackbar2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    internalaccessoriesObj.isTemperature = true
                    props.selecteComponent({ isTemperature: true })

                    setTemperature(true)
                    var x = document.getElementById('snackbar2')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            // laxmi
            case 'touch0': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Play Shield is already connected on the touch pads'
                    )
                    isShowFeedback(true)
                    break
                }

                if (assembly.PortConnections.A1 != null) {
                    setFeedbackMsg('Remove External accessories from Port A1')
                    isShowFeedback(true)
                    break
                }
                if (isTouchZero) {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({ isTouchZero: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchZero'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchZero = false
                    setTouchZero(false)
                    var x = document.getElementById('snackbar3')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({
                        isTouchZero: true,
                        isTouchZeroOutput: false,
                        isSave: false,
                    })

                    internalaccessoriesObj.isTouchZero = true
                    internalaccessoriesObj.isTouchZeroOutput = false

                    setTouchZero(true)
                    setTouchZeroOutput(false)
                    var x = document.getElementById('snackbar3')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch1': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Play Shield is already connected on the touch pads'
                    )
                    isShowFeedback(true)
                    break
                }

                if (assembly.PortConnections.B1 != null) {
                    setFeedbackMsg('Remove External accessories from Port B1')
                    isShowFeedback(true)
                    break
                }
                if (isTouchOne) {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({ isTouchOne: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchOne'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchOne = false
                    setTouchOne(false)
                    var x = document.getElementById('snackbar4')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({
                        isTouchOne: true,
                        isTouchOneOutput: false,
                    })

                    internalaccessoriesObj.isTouchOne = true
                    internalaccessoriesObj.isTouchOneOutput = false

                    setTouchOne(true)
                    setTouchOneOutput(false)
                    var x = document.getElementById('snackbar4')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch2': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Play Shield is already connected on the touch pads'
                    )
                    isShowFeedback(true)
                    break
                }

                if (assembly.PortConnections.C1 != null) {
                    setFeedbackMsg('Remove External accessories from Port C1')
                    isShowFeedback(true)
                    break
                }
                if (isTouchTwo) {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({ isTouchTwo: false })
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source === 'TouchTwo'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchTwo = false
                    setTouchTwo(false)
                    var x = document.getElementById('snackbar5')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({
                        isTouchTwo: true,
                        isTouchTwoOutput: false,
                    })

                    internalaccessoriesObj.isTouchTwo = true
                    internalaccessoriesObj.isTouchTwoOutput = false

                    setTouchTwo(true)
                    setTouchTwoOutput(false)
                    var x = document.getElementById('snackbar5')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'touch0Output': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Play Shield is already connected on the touch pads'
                    )
                    isShowFeedback(true)
                    break
                }

                if (assembly.PortConnections.A1 != null) {
                    setFeedbackMsg('Remove External accessories from Port A1')
                    isShowFeedback(true)
                    break
                }
                console.log(props, 'select props')
                if (isTouchZeroOutput) {
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchZeroOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchZeroOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchZeroOutput: false })
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    //////////////////////////////////////////////////////////////////////////////
                    internalaccessoriesObj.isTouchZeroOutput = false
                    setTouchZeroOutput(false)
                    var x = document.getElementById('snackbar6')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({
                        isTouchZeroOutput: true,
                        isTouchZero: false,
                    })

                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchZero'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchZeroOutput = false
                        }
                    } //for sim

                    internalaccessoriesObj.isTouchZeroOutput = true
                    internalaccessoriesObj.isTouchZero = false

                    setTouchZeroOutput(true)
                    setTouchZero(false)
                    var x = document.getElementById('snackbar6')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'touch1Output': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Play Shield is already connected on the touch pads'
                    )
                    isShowFeedback(true)
                    break
                }

                if (assembly.PortConnections.B1 != null) {
                    setFeedbackMsg('Remove External accessories from Port B1')
                    isShowFeedback(true)
                    break
                }
                if (isTouchOneOutput) {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    internalaccessoriesObj.isTouchOneOutput = false
                    setTouchOneOutput(false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchOneOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchOneOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchOneOutput: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar7')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({
                        isTouchOneOutput: true,
                        isTouchOne: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchOne'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchOneOutput = true
                    internalaccessoriesObj.isTouchOne = false

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchOneOutput = false
                        }
                    } //for sim

                    setTouchOneOutput(true)
                    setTouchOne(false)
                    var x = document.getElementById('snackbar7')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }
            case 'touch2Output': {
                if (isPlayShield) {
                    setFeedbackMsg(
                        'Play Shield is already connected on the touch pads'
                    )
                    isShowFeedback(true)
                    break
                }

                if (assembly.PortConnections.C1 != null) {
                    setFeedbackMsg('Remove External accessories from Port C1')
                    isShowFeedback(true)
                    break
                }
                if (isTouchTwoOutput) {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    internalaccessoriesObj.isTouchTwoOutput = false
                    setTouchTwoOutput(false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignTouchTwoOutput',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueTouchTwoOutput',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isTouchTwoOutput: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar8')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    sessionStorage.setItem('isSave', JSON.stringify(false))
                    props.selecteComponent({
                        isTouchTwoOutput: true,
                        isTouchTwo: false,
                    })
                    //fix: playC1074 : state update on sensor change
                    //touchZero input -false
                    props.indexData.logic.program.map((data) => {
                        if (
                            data.type === 'sensor' &&
                            data.state.source == 'TouchTwo'
                        ) {
                            data.state.source = ''
                            data.state.value = 0
                            data.state.value2 = 0
                            data.state.condition = ''
                        }
                    })
                    props.logicComponent(props.indexData.logic)
                    internalaccessoriesObj.isTouchTwoOutput = true
                    internalaccessoriesObj.isTouchTwo = false

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignTouchTwoOutput = false
                        }
                    } //for sim

                    setTouchTwoOutput(true)
                    setTouchTwo(false)
                    var x = document.getElementById('snackbar8')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'leftEye': {
                if (isLeftEye) {
                    internalaccessoriesObj.isLeftEye = false
                    setEyeLeft(false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignLeftEye',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignLeftEyeR',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignLeftEyeG',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignLeftEyeB',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueLeftEyeR',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueLeftEyeG',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueLeftEyeB',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isLeftEye: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar9')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isLeftEye: true })

                    internalaccessoriesObj.isLeftEye = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        // console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEye = false
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEyeR = false
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEyeG = false
                            props.indexData.logic.program[
                                key
                            ].state.assignLeftEyeB = false
                        }
                    } //for sim

                    setEyeLeft(true)
                    var x = document.getElementById('snackbar9')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'rightEye': {
                if (isRightEye) {
                    internalaccessoriesObj.isRightEye = false
                    setEyeRight(false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignRightEye',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignRightEyeR',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignRightEyeG',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignRightEyeB',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueRightEyeR',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueRightEyeG',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueRightEyeB',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isRightEye: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar10')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isRightEye: true })

                    internalaccessoriesObj.isRightEye = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        // console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEye = false
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEyeR = false
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEyeG = false
                            props.indexData.logic.program[
                                key
                            ].state.assignRightEyeB = false
                        }
                    } //for sim

                    setEyeRight(true)
                    var x = document.getElementById('snackbar10')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'buzzer': {
                if (isbuzzer) {
                    internalaccessoriesObj.isbuzzer = false
                    setbuzzer(false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignBuzzer',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignBuzzerFrequency',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'assignBuzzerTone',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueBuzzerFrequency',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueBuzzerTone',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isbuzzer: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar11')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isbuzzer: true })

                    internalaccessoriesObj.isbuzzer = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignBuzzer = false
                        }
                    } //for sim

                    setbuzzer(true)
                    var x = document.getElementById('snackbar11')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            // Laxmi onclick

            case 'smile1': {
                if (isPeeCeeATR && isM1Occupied) {
                    setFeedbackMsg('Port M1 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isarmShield) {
                    setFeedbackMsg('Arm Shield is connected')
                    isShowFeedback(true)
                    break
                }
                if (isPlayShield && isM1Occupied) {
                    setFeedbackMsg('Port M1 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isSmileOne) {
                    internalaccessoriesObj.isSmileOne = false
                    setSimleOne(false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileOne',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileOne',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileOne: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar12')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isSmileOne: true })
                    internalaccessoriesObj.isSmileOne = true
                    setSimleOne(true)

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileOne = false
                        }
                    } //for sim
                    internalaccessoriesObj.isSmileOne = true

                    setSimleOne(true)

                    var x = document.getElementById('snackbar12')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'smile2': {
                if (isPeeCeeATR && isM1Occupied) {
                    setFeedbackMsg('Port M1 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isarmShield) {
                    setFeedbackMsg('Arm Shield is connected')
                    isShowFeedback(true)
                    break
                }
                if (isPlayShield && isM2Occupied) {
                    setFeedbackMsg('Port M2 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isSmileTwo) {
                    internalaccessoriesObj.isSmileTwo = false
                    setSimleTwo(false)
                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileTwo',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileTwo',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileTwo: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar13')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isSmileTwo: true })

                    internalaccessoriesObj.isSmileTwo = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileTwo = false
                        }
                    } //for sim

                    setSimleTwo(true)
                    var x = document.getElementById('snackbar13')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
                break
            }

            case 'smile3': {
                if (isPeeCeeATR && isM2Occupied) {
                    setFeedbackMsg('Port M2 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isarmShield) {
                    setFeedbackMsg('Arm Shield is connected')
                    isShowFeedback(true)
                    break
                }
                if (isPlayShield && isM3Occupied) {
                    setFeedbackMsg('Port M3 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isSmileThree) {
                    internalaccessoriesObj.isSmileThree = false
                    setSimleThree(false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileThree',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileThree',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileThree: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar14')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isSmileThree: true })

                    internalaccessoriesObj.isSmileThree = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileThree = false
                        }
                    } //for sim

                    setSimleThree(true)
                    var x = document.getElementById('snackbar14')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }

                break
            }

            case 'smile4': {
                if (isPeeCeeATR && isM2Occupied) {
                    setFeedbackMsg('Port M2 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isarmShield) {
                    setFeedbackMsg('Arm Shield is connected')
                    isShowFeedback(true)
                    break
                }
                if (isPlayShield && isM4Occupied) {
                    setFeedbackMsg('Port M4 is occupied')
                    isShowFeedback(true)
                    return
                }
                if (isSmileFour) {
                    internalaccessoriesObj.isSmileFour = false
                    setSimleFour(false)

                    //////PLAYC-405 Donot reorder the code because redux will not update //////////
                    await removeFromProgram(
                        'assignSmileFour',
                        props.indexData.logic.program
                    )
                    await removeFromProgram(
                        'valueSmileFour',
                        props.indexData.logic.program
                    )
                    props.selecteComponent({ isSmileFour: false })
                    //////////////////////////////////////////////////////////////////////////////
                    var x = document.getElementById('snackbar15')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    props.selecteComponent({ isSmileFour: true })

                    internalaccessoriesObj.isSmileFour = true

                    //to change props in logic.program for correct simulation
                    for (var key in props.indexData.logic.program) {
                        //console.log("halo key", props.indexData.logic.program[key]);
                        if (
                            props.indexData.logic.program[key].type ==
                            'hardware'
                        ) {
                            props.indexData.logic.program[
                                key
                            ].state.assignSmileFour = false
                        }
                    } //for sim

                    setSimleFour(true)
                    var x = document.getElementById('snackbar15')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                }
            }
        }
    }

    const backBtnAction = () => {
        console.log('baaaacccck')
        let saved = JSON.parse(sessionStorage.getItem('isSave'))
        console.log('saved', saved)

        if (saved) {
            props.history.push('/code/project')
            // window.location.reload(false)
            shouldErase('Yes')
        } else {
            setErasedProgram(true)
        }
        // setSlideDirection(true)
        // sessionStorage.setItem('slideDirection', true)

        // if (JSON.parse(sessionStorage.getItem("pip")) == true) {
        //   setErasedProgram(true);
        //   sessionStorage.setItem("pip", false);
        //   sessionStorage.setItem("name", "null");
        // } else {
        //   setErasedProgram(true);
        // }
    }

    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const shouldErase = (info) => {
        if (info == 'Yes') {
            let programMode = sessionStorage.programMode
            let connectedDevice = sessionStorage.connectedDevice
            let version = sessionStorage.deviceVersion
            sessionStorage.setItem('isSave', JSON.stringify(false))
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            if (
                localStorage.getItem('recoveryData') !== null &&
                programMode in recoveryData &&
                connectedDevice in recoveryData[programMode] &&
                version in recoveryData[programMode][connectedDevice]
            ) {
                if (Object.keys(recoveryData).length === 1) {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            localStorage.removeItem('recoveryData')
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                            localStorage.setItem(
                                'recoveryData',
                                JSON.stringify(recoveryData)
                            )
                        }
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                }
            }

            sessionStorage.removeItem('programEnd')
            sessionStorage.setItem('pip', false)
            sessionStorage.setItem('name', 'null')
            sessionStorage.removeItem('Index')
            sessionStorage.setItem('shield', 'false')
            sessionStorage.setItem('peeCeeATR', 'false')
            sessionStorage.setItem('arm_shield', 'false')
            sessionStorage.removeItem('PET')
            sessionStorage.removeItem('EndSwitch')
            props.indexData.concept.Index = []
            props.history.push('/code/project')
            // window.location.reload(false)
            props.DeselectedComponent({
                Four_in_one_sensor: {
                    isDistanceSensors: false,
                    isGestureSensor: false,
                    isLightSensor: false,
                    isColorSensor: false,
                },
                isFour_in_one_sensor: false,

                isMic: false,
                isTemperature: false,

                // input
                isTouchZero: false,
                isTouchOne: false,
                isTouchTwo: false,

                // output
                isTouchZeroOutput: false,
                isTouchOneOutput: false,
                isTouchTwoOutput: false,

                isLeftEye: false,
                isRightEye: false,
                isbuzzer: false,

                isSmileOne: false,
                isSmileTwo: false,
                isSmileThree: false,
                isSmileFour: false,
            })
        } else {
            setErasedProgram(false)
        }
    }
    const retriveRecoveryData = () => {
        if (localStorage.getItem('recoveryData')) {
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            sessionStorage.clear()
            for (let [key, value] of Object.entries(recoveryData)) {
                // console.log(key, value);
                sessionStorage.setItem(key, value)
            }
        }
        props.history.push('/code/project')
    }

    // var dataErased = (
    // 	<Modal
    // 		isOpen={erasedProgram}
    // 		style={customStyles}
    // 		contentLabel="Example Modal"
    // 	>
    // 		<div className="erasedConceptMsg" style={{ zIndex: "999" }}>
    // 			<p>All Unsaved program will be Erased, Continue ?</p>
    // 			<button
    // 				className="BtnPopup"
    // 				onClick={() => shouldErase("Yes")}
    // 				style={{ position: "relative", top: "-22px" }}
    // 			>
    // 				Yes
    // 			</button>
    // 			<button
    // 				className="BtnPopup"
    // 				onClick={() => shouldErase("No")}
    // 				style={{ position: "relative", top: "-22px", left: "10px" }}
    // 			>
    // 				No
    // 			</button>
    // 		</div>
    // 	</Modal>
    // );

    const next = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
        history.push('/selectScreen/ExternalAccessories')
    }

    sessionStorage.setItem('assemblyCheckbox', JSON.stringify(null))

    useEffect(() => {
        if (
            isDistanceSensors ||
            isGestureSensor ||
            isLightSensor ||
            isColorSensor
        ) {
            console.log('heeeeeeeeehehehehhhhhhhhhhhhhhhhhhhhhh, calling yes')
            internalaccessoriesObj.isFour_in_one_sensor = true
            props.selecteComponent({ isFour_in_one_sensor: true })
        } else {
            console.log('heeeeeeeeehehehehhhhhhhhhhhhhhhhhhhhhh, calling no')

            internalaccessoriesObj.isFour_in_one_sensor = false
            props.selecteComponent({ isFour_in_one_sensor: false })
        }
    }, [isDistanceSensors, isGestureSensor, isLightSensor, isColorSensor])

    // Caching External Component assets

    useEffect(() => {
        let assetsToBeCached = [
            renderCompImg('pot'),
            renderCompImg('play_shield'),
            renderCompImg('play_shield_v1_top'),
            renderCompImg('play_shield_top'),
            renderCompImg('dc_motor'),
            renderCompImg('dynamex_motor'),
            renderCompImg('geared_motor'),
            renderCompImg('mini_geared_motor'),
            renderCompImg('distance_sensor'),
            renderCompImg('dual_switch'),
            renderCompImg('joystick'),
            renderCompImg('laser'),
            renderCompImg('led'),
            renderCompImg('servo_motor'),
            renderCompImg('servo_motor_270'),
            renderCompImg('servo_motor_360'),
            renderCompImg('light_sensor'),
            renderCompImg('tact_switch'),
            renderCompImg('touch_sensor'),
            renderCompImg('ultrasonic_sensor'),
            renderCompImg('led_1c'),
            renderCompImg('tact_switch_2c'),
            renderCompImg('RGB'),
            renderCompImg('OLED'),
            renderCompImg('mp3'),
            renderCompImg('dip_switch'),
            renderCompImg('linear_pot'),
            renderCompImg('humidity'),
            renderCompImg('metal_detector'),
            renderCompImg('extender'),
            renderCompImg('temperature_sensor'),
            renderCompImg('gas'),
            renderCompImg('rain_sensor'),
            renderCompImg('pc_motor_driver'),
            renderCompImg('dual_splitter'),
            // helpScreen
            renderPrgImage('InternalPrgf1'),
            renderPrgImage('InternalPrgf2'),
            renderPrgImage('InternalPrgf3'),
            renderPrgImage('InternalPrgf4'),
            renderPrgImage('Pcv1SelectH1'),
            renderPrgImage('Pcv1SelectH2'),
            renderPrgImage('ExternalPrgf2'),
            renderPrgImage('ExternalPrgf3'),
            renderPrgImage('assemblyH'),
            renderPrgImage('Pcv1AssemblyH'),
            renderPrgImage('AssemblyPrgf1'),
            renderPrgImage('assemblyH2'),
            renderPrgImage('Pcv1AssemblyH2'),
            renderPrgImage('AssemblyPrgf2'),
            renderPrgImage('assemblyH3'),
            renderPrgImage('Pcv1AssemblyH3'),
            renderPrgImage('AssemblyPrgf3'),
            renderPrgImage('AssemblyPrgf4'),
            renderPrgImage('LogicPrgf1'),
            renderPrgImage('LogicPrgf2'),
            renderPrgImage('LogicPrgf3'),
            renderPrgImage('LogicPrgf4'),
            renderPrgImage('LogicPrgf5'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    // return <div className="InternalAccessoriesScreen_Main"></div>;
    return (
        <>
            <DialogModal
                show={erasedProgram}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldErase('Yes'),
                    },
                    {
                        text: 'NO',
                        do: () => shouldErase('No'),
                    },
                ]}
            />
            <div className="InternalAccessoriesScreen_Main">
                <div>
                    <ProgramHeader
                        data={{
                            options: ['Select', 'Assemble', 'Code', 'Simulate'],
                            selected: 0,
                        }}
                        showSave={true}
                        showHelp={true}
                        handleHelp={handleHelpBtn}
                    />
                    <div
                        className="navbar-Action"
                        style={{ alignItems: 'center' }}
                    ></div>
                </div>

                {/* {dataErased} */}

                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    }`}
                    style={{ height: '80vh' }}
                >
                    {' '}
                    <div className="Item-1">
                        <div className="InternalAccessoriesScreen-Item1">
                            {/* <img src={renderPrgImage devicePc} className="devicePc" /> */}

                            <div style={styleDevicePC} className="device">
                                <div className="deviceContainer">
                                    {props.indexData.concept.internalaccessories
                                        .isLeftEye ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalEYEActive'
                                            )}
                                            className="imgStyleEyeL"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalEYEInActive'
                                            )}
                                            className="imgStyleEyeL"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isRightEye ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalEYEActive'
                                            )}
                                            className="imgStyleEyeR"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalEYEInActive'
                                            )}
                                            className="imgStyleEyeR"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isSmileOne ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethActive'
                                            )}
                                            className="imgStyleTeeth1"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethInActive'
                                            )}
                                            className="imgStyleTeeth1"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isSmileTwo ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethActive'
                                            )}
                                            className="imgStyleTeeth2"
                                            // onClick={handleClicksForSave}
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethInActive'
                                            )}
                                            className="imgStyleTeeth2"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isSmileThree ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethActive'
                                            )}
                                            className="imgStyleTeeth3"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethInActive'
                                            )}
                                            className="imgStyleTeeth3"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isSmileFour ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethActive'
                                            )}
                                            className="imgStyleTeeth4"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTeethInActive'
                                            )}
                                            className="imgStyleTeeth4"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .Four_in_one_sensor.isDistanceSensors ||
                                    props.indexData.concept.internalaccessories
                                        .Four_in_one_sensor.isColorSensor ||
                                    props.indexData.concept.internalaccessories
                                        .Four_in_one_sensor.isGestureSensor ||
                                    props.indexData.concept.internalaccessories
                                        .Four_in_one_sensor.isLightSensor ? (
                                        <img
                                            src={renderPrgImage(
                                                'Pcinternal4in1Active'
                                            )}
                                            className="imgStyle4in1"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'Pcinternal4in1InActive'
                                            )}
                                            className="imgStyle4in1"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isMic ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalMicActive'
                                            )}
                                            className="imgStyleMic"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalMicInActive'
                                            )}
                                            className="imgStyleMic"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isbuzzer ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalBuzzerActive'
                                            )}
                                            className="imgStyleBuzzer"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalBuzzerInActive'
                                            )}
                                            className="imgStyleBuzzer"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isTouchZero ||
                                    props.indexData.concept.internalaccessories
                                        .isTouchZeroOutput ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTouchpadsActive'
                                            )}
                                            className="imgStyleTouchpads1"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTouchpadsInActive'
                                            )}
                                            className="imgStyleTouchpads1"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isTouchOne ||
                                    props.indexData.concept.internalaccessories
                                        .isTouchOneOutput ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTouchpadsActive'
                                            )}
                                            className="imgStyleTouchpads2"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTouchpadsInActive'
                                            )}
                                            className="imgStyleTouchpads2"
                                        />
                                    )}

                                    {props.indexData.concept.internalaccessories
                                        .isTouchTwo ||
                                    props.indexData.concept.internalaccessories
                                        .isTouchTwoOutput ? (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTouchpadsActive'
                                            )}
                                            className="imgStyleTouchpads3"
                                        />
                                    ) : (
                                        <img
                                            src={renderPrgImage(
                                                'PcinternalTouchpadsInActive'
                                            )}
                                            className="imgStyleTouchpads3"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="InternalAccessoriesScreen-Item2">
                            <div className="input input_one" style={styleInput}>
                                <p
                                    className="inputText"
                                    style={{ fontFamily: 'Halcyon_SemiBold' }}
                                >
                                    Inputs
                                </p>
                                <div className="inputContiner">
                                    <div className="container-row ">
                                        {props.indexData.concept
                                            .internalaccessories.isMic ? (
                                            <img
                                                src={renderPrgImage(
                                                    'micActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="mic"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'micInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="mic"
                                                onClick={handleEventsClick}
                                            />
                                        )}{' '}
                                        {props.indexData.concept
                                            .internalaccessories
                                            .isTemperature ? (
                                            <img
                                                src={renderPrgImage(
                                                    'tempActive'
                                                )}
                                                className="imgStyle"
                                                alt="temperature"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'tempInActive'
                                                )}
                                                className="imgStyle"
                                                alt="temperature"
                                                onClick={handleEventsClick}
                                            />
                                        )}{' '}
                                    </div>

                                    <div className="container-row">
                                        {props.indexData.concept
                                            .internalaccessories.isTouchZero ? (
                                            <img
                                                src={renderPrgImage(
                                                    'touchZeroActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch0"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'touchZeroInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch0"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isTouchOne ? (
                                            <img
                                                src={renderPrgImage(
                                                    'touchOneActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch1"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'touchOneInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch1"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isTouchTwo ? (
                                            <img
                                                src={renderPrgImage(
                                                    'touchTwoActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch2"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'touchTwoInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch2"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                    <div className="container-row">
                                        {props.indexData.concept
                                            .internalaccessories
                                            .Four_in_one_sensor
                                            .isDistanceSensors ? (
                                            <img
                                                src={renderPrgImage(
                                                    'distancesensorsActive'
                                                )}
                                                className="imgStyle"
                                                alt="distancesensors"
                                                onClick={handleFounInOneSensor}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'distanceSensorInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="distancesensors"
                                                onClick={handleFounInOneSensor}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories
                                            .Four_in_one_sensor
                                            .isGestureSensor ? (
                                            <img
                                                src={renderPrgImage(
                                                    'gestureSensorActiveBtn'
                                                )}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="gesturesensor"
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'gestureSensorInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="gesturesensor"
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories
                                            .Four_in_one_sensor
                                            .isLightSensor ? (
                                            <img
                                                src={renderPrgImage(
                                                    'lightSensorActiveBtn'
                                                )}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="lightsensor"
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'lightSensorInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="lightsensor"
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories
                                            .Four_in_one_sensor
                                            .isColorSensor ? (
                                            <img
                                                src={renderPrgImage(
                                                    'colorSensorActiveBtn'
                                                )}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="colorsensor"
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'colorSensorInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="colorsensor"
                                            />
                                        )}
                                    </div>

                                    {showFeedback && (
                                        <div id="snackbar1" className="show">
                                            {feedbackMsg}
                                        </div>
                                    )}

                                    {isMic ? (
                                        <div id="snackbar1">
                                            Microphone selected
                                        </div>
                                    ) : (
                                        <div id="snackbar1">
                                            Microphone deselected
                                        </div>
                                    )}
                                    {isTemperature ? (
                                        <div id="snackbar2">
                                            Temperature selected
                                        </div>
                                    ) : (
                                        <div id="snackbar2">
                                            Temperature deselected
                                        </div>
                                    )}

                                    {isTouchZero ? (
                                        <div id="snackbar3">
                                            Touch pad 0 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar3">
                                            Touch pad 0 deselected
                                        </div>
                                    )}

                                    {isTouchOne ? (
                                        <div id="snackbar4">
                                            Touch pad 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar4">
                                            Touch pad 1 deselected
                                        </div>
                                    )}

                                    {isTouchTwo ? (
                                        <div id="snackbar5">
                                            Touch pad 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar5">
                                            Touch pad 2 deselected
                                        </div>
                                    )}

                                    {isTouchZeroOutput ? (
                                        <div id="snackbar6">
                                            Touch pad 0 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar6">
                                            Touch pad 0 deselected
                                        </div>
                                    )}

                                    {isTouchOneOutput ? (
                                        <div id="snackbar7">
                                            Touch pad 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar7">
                                            Touch pad 1 deselected
                                        </div>
                                    )}

                                    {isTouchTwoOutput ? (
                                        <div id="snackbar8">
                                            Touch pad 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar8">
                                            Touch pad 2 deselected
                                        </div>
                                    )}

                                    {isLeftEye ? (
                                        <div id="snackbar9">
                                            Left Eye selected
                                        </div>
                                    ) : (
                                        <div id="snackbar9">
                                            Left Eye deselected
                                        </div>
                                    )}
                                    {isRightEye ? (
                                        <div id="snackbar10">
                                            Rigth Eye selected
                                        </div>
                                    ) : (
                                        <div id="snackbar10">
                                            Rigth Eye deselected
                                        </div>
                                    )}
                                    {isbuzzer ? (
                                        <div id="snackbar11">
                                            Buzzer selected
                                        </div>
                                    ) : (
                                        <div id="snackbar11">
                                            Buzzer deselected
                                        </div>
                                    )}

                                    {isSmileOne ? (
                                        <div id="snackbar12">
                                            Smile 1 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar12">
                                            Smile 1 deselected
                                        </div>
                                    )}
                                    {isSmileTwo ? (
                                        <div id="snackbar13">
                                            Smile 2 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar13">
                                            Smile 2 deselected
                                        </div>
                                    )}
                                    {isSmileThree ? (
                                        <div id="snackbar14">
                                            Smile 3 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar14">
                                            Smile 3 deselected
                                        </div>
                                    )}
                                    {isSmileFour ? (
                                        <div id="snackbar15">
                                            Smile 4 selected
                                        </div>
                                    ) : (
                                        <div id="snackbar15">
                                            Smile 4 deselected
                                        </div>
                                    )}
                                    {isDistanceSensors ? (
                                        <div id="snackbar16">
                                            Distance Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar16">
                                            Distance Sensor deselected
                                        </div>
                                    )}

                                    {isDistanceSensors ? null : (
                                        <div id="snackbar16_D2">
                                            {' '}
                                            Remove external accessories from
                                            Port D to enable Distance Sensors
                                        </div>
                                    )}
                                    {isGestureSensor ? (
                                        <div id="snackbar17">
                                            Gesture Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar17">
                                            Gesture Sensor deselected
                                        </div>
                                    )}
                                    {isGestureSensor ? null : (
                                        <div id="snackbar17_D2">
                                            {' '}
                                            Remove external accessories from
                                            Port D to enable Gesture Sensors
                                        </div>
                                    )}

                                    {isLightSensor ? (
                                        <div id="snackbar18">
                                            Light Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar18">
                                            Light Sensor deselected
                                        </div>
                                    )}
                                    {isLightSensor ? null : (
                                        <div id="snackbar18_D2">
                                            {' '}
                                            Remove external accessories from
                                            Port D to enable Light Sensors
                                        </div>
                                    )}

                                    {isColorSensor ? (
                                        <div id="snackbar19">
                                            Color Sensor selected
                                        </div>
                                    ) : (
                                        <div id="snackbar19">
                                            Color Sensor deselected
                                        </div>
                                    )}
                                    {isColorSensor ? null : (
                                        <div id="snackbar19_D2">
                                            {' '}
                                            Remove external accessories from
                                            Port D to enable Color Sensors
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="onput" style={styleoutput}>
                                <p className="txtTitle">Outputs</p>
                                <div className="outputContiner">
                                    <div className="container-row">
                                        {props.indexData.concept
                                            .internalaccessories
                                            .isTouchZeroOutput ? (
                                            <img
                                                src={renderPrgImage(
                                                    'touchZeroActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch0Output"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'touchZeroInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch0Output"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories
                                            .isTouchOneOutput ? (
                                            <img
                                                src={renderPrgImage(
                                                    'touchOneActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch1Output"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'touchOneInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch1Output"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories
                                            .isTouchTwoOutput ? (
                                            <img
                                                src={renderPrgImage(
                                                    'touchTwoActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch2Output"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'touchTwoInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="touch2Output"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                    <div className="container-row">
                                        {' '}
                                        {props.indexData.concept
                                            .internalaccessories.isLeftEye ? (
                                            <img
                                                src={renderPrgImage(
                                                    'lefteyeActive'
                                                )}
                                                className="imgStyle"
                                                alt="leftEye"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'lefteyeInActive'
                                                )}
                                                className="imgStyle"
                                                alt="leftEye"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {props.indexData.concept
                                            .internalaccessories.isRightEye ? (
                                            <img
                                                src={renderPrgImage(
                                                    'righteyeActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="rightEye"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'righteyeInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="rightEye"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {props.indexData.concept
                                            .internalaccessories.isbuzzer ? (
                                            <img
                                                src={renderPrgImage(
                                                    'buzzerActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="buzzer"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'buzzerInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="buzzer"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                    <div className="container-row">
                                        {props.indexData.concept
                                            .internalaccessories.isSmileOne ? (
                                            <img
                                                src={renderPrgImage(
                                                    'smileOneActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile1"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'smileOneInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile1"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isSmileTwo ? (
                                            <img
                                                src={renderPrgImage(
                                                    'smileTwoActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile2"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'smileTwoInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile2"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories
                                            .isSmileThree ? (
                                            <img
                                                src={renderPrgImage(
                                                    'smileThreeActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile3"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'smileThreeInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile3"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {props.indexData.concept
                                            .internalaccessories.isSmileFour ? (
                                            <img
                                                src={renderPrgImage(
                                                    'smileFourActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile4"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={renderPrgImage(
                                                    'smileFourInActiveBtn'
                                                )}
                                                className="imgStyle"
                                                alt="smile4"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* BOTTOM BACK,NEXT BTN and discription*/}
                <div>
                    <Footer
                        isGoBack={false}
                        goBack={backBtnAction}
                        title="Select the internal accessories of Play Computer "
                        isGoNext={true}
                        goNext={next}
                    />
                </div>

                {isHelp && (
                    <div
                        className="Ss_slide"
                        style={{
                            top: '49vh',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        {' '}
                        <InternalScPrgm />{' '}
                        <img
                            className="helpClo"
                            src={renderPrgImage('closBtn')}
                            onClick={handleHelpBtn}
                        ></img>
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalAccessoriesScreen)
