module.exports = {
    led: 'false',
    geared_motor: 'false',
    servo_motor: 'false',
    servo_motor_270: 'false',
    dc_motor: 'false',
    // dynamex_motor: 'false',
    stepper_motor: 'false',
    '7segment_display': 'false',
    '4_CH_relay': 'false',
    relay: 'false',
    electromagnet: 'false',
    led_strip: 'false',
    light_sensor: 'false',
    bend_sensor: 'false',
    gas_sensor: 'false',
    laser: 'false',
    ultrasonic_sensor: 'false',
    hall_sensor: 'false',
    dot_matrix: 'false',
    humidity_sensor: 'false',
    metal_detector: 'false',
    color_sensor: 'false',
    heartbeat_sensor: 'false',
    rfid: 'false',
    distance_sensor: 'false',
    sound_sensor: 'false',
    temperature_sensor: 'false',
    rain_sensor: 'false',
    tact_switch: 'false',
    dual_switch: 'false',
    rotational_sensor: 'false',
    accelerometer: 'false',
    solar_panel: 'false',
    battery: 'false',
    beeper: 'false',
    PWM: 'false',
}
