import renderImage from '../../source/importImg'
import { useHistory } from 'react-router'

function TermsAndConditions() {
    let history = useHistory()

    return (
        <>
            <div>
                <img
                    className={'Back_Btn'}
                    src={renderImage('backBtn')}
                    onClick={() => {
                        history.push('/')
                    }}
                    style={{ zIndex: '10' }}
                ></img>
            </div>
            <div
                style={{
                    position: 'relative',
                    overflowY: 'scroll',
                    height: '100vh',
                }}
            >
                <div className="container" style={{ left: '0%' }}>
                    <p></p>
                    <div className="row">
                        <div className="col-12">
                            <p></p>
                            <h1
                                data-aos="fade-up"
                                data-aos-easing="ease"
                                data-aos-delay="100"
                                className="aos-init aos-animate"
                            >
                                Terms and Conditions
                            </h1>
                            <div
                                className="aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease"
                                data-aos-delay="100"
                            >
                                <p>
                                    These terms and conditions are a legally
                                    binding agreement between Evobi Automations
                                    Pvt Ltd (BIBOX) / PlayComputer and the users
                                    (You) of PlayComputer Website, Products and
                                    services, as described below.
                                </p>

                                <p>
                                    This agreement explains all the necessary
                                    points pertaining to our terms and
                                    conditions and may be modified by Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    from time to time. This agreement (inclusive
                                    of all terms and conditions) applies to all
                                    types of visitors, users, others who access
                                    the website (“Users”) and buyers of
                                    PlayComputer products and
                                    services.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </p>

                                <p>
                                    Please ensure that you read through all the
                                    terms &amp; conditions and fully understand
                                    the contents of this agreement. If you have
                                    any doubts or questions about your rights
                                    and obligations which could result from your
                                    acceptance to our agreement, kindly get in
                                    touch and consult with us.
                                </p>

                                <p>
                                    By using or accessing our website, you
                                    signify that you have read through, fully
                                    understood, and agreed to abide by this
                                    agreement and have given your consent to us
                                    for the collection and use of your
                                    information as per the Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer privacy policy.
                                </p>

                                <p>
                                    Tangible or intangibles products, services,
                                    courses, educational content, tutorials,
                                    software or apps downloaded or bought from
                                    this website ( www.playcomputer.org), any
                                    sub-domains of PlayComputer, third party
                                    store/affiliations (for example Google
                                    PlayStore, Apple AppStore, Windows Store,
                                    online software or app listing portals,
                                    etc), any kind of media, or social media,
                                    are for self-consumption only, and the users
                                    or buyers are not authorized to
                                    resell/co-brand it with other products or
                                    services/demonstrate in any other offline or
                                    online channels/use it for business
                                    activities, unless formally approved by
                                    Evobi Automations Pvt Ltd (BIBOX) in written
                                    communication.
                                </p>

                                <h3>
                                    <strong>1. Our Company</strong>
                                </h3>

                                <p>
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer is a company registered in
                                    Beretena Agrahara, Next to Play Factory
                                    Hosur Main Road, Post, Electronic City,
                                    Bengaluru, Karnataka 560100, India. We
                                    operate the website “www.playcomputer.org”.
                                </p>

                                <h3>
                                    <strong>2. Trademarks</strong>
                                </h3>

                                <p>
                                    All page headers, graphics, logos, service
                                    names, etc. available/posted on our website
                                    are trademarks of Evobi Automations Pvt Ltd
                                    (BIBOX) / PlayComputer. We don’t give any
                                    rights to any party or user to use our
                                    trademarks – service &amp; product names,
                                    logos, graphics, other information posted on
                                    the website without a written permission of
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer.
                                </p>

                                <h3>
                                    <strong>3. Copyright &amp; Content</strong>
                                </h3>

                                <p>
                                    Any content like logos, graphics, pictures,
                                    textual, audios, videos and data made
                                    available on the website are properties of
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer and have been protected by
                                    copyright laws. The information, content (as
                                    mentioned above) made available through the
                                    website is the exclusive property of Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer,
                                    protected by the copyright laws. Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    reserves all rights of the content being
                                    posted on the website and the content not
                                    expressly granted on the website.
                                </p>

                                <h3>
                                    <strong>4. User Login Credentials</strong>
                                </h3>

                                <p>
                                    You are solely responsible for your login
                                    and password credentials. Please do NOT
                                    share your user login and password with
                                    anyone and ensure that your login
                                    credentials are NOT misused by anyone.
                                </p>

                                <h3>
                                    <strong>5. Guarantee/Warranty&nbsp;</strong>
                                </h3>

                                <p>
                                    All our parts are covered by different
                                    warranty periods which are shown on
                                    respective pages, indicating that products
                                    are free from any defects in materials and
                                    workmanship. For products like electronic
                                    parts, the warranty is covered only when the
                                    full batch of an item is defective. If
                                    defects arise during this period, we will at
                                    our option, repair or replace the goods
                                    purchased or refund your money. Our
                                    liability under this warranty is subject to
                                    us being satisfied that the material is
                                    faulty due to poor materials or workmanship.
                                    We will not be responsible for any damage
                                    caused by incorrectly connecting or improper
                                    use of the material. Furthermore, we will
                                    not be liable for any damage to third party
                                    or attached equipment to which these
                                    products are used.
                                </p>

                                <p>
                                    All decisions regarding the warranty
                                    acceptance will be taken by Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    and shall be accepted by customers. Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    may or may not charge for repairs and return
                                    shipping if item’s warranty is void due to
                                    any reasons.
                                </p>

                                <h3>
                                    <strong>
                                        6. Terms and Condition of Sales
                                    </strong>
                                </h3>

                                <p>
                                    &nbsp;Unless it is explicitly agreed in
                                    writing, the conditions as set out below
                                    apply to all transactions for the sales of
                                    goods and materials, design services by
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer to the buyers of goods and/or
                                    Services.
                                </p>

                                <p>
                                    These conditions apply to all orders placed
                                    with Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer by the buyers. The acceptance
                                    by Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer of any order is conditional
                                    upon the acceptance of these conditions by
                                    the buyers, which shall override all other
                                    terms and conditions inconsistent herewith –
                                    whether expressed, implied or including
                                    terms, conditions or stipulations contained
                                    in the buyer’s purchase order or other form
                                    of writing or otherwise stipulated by a
                                    buyer with variances or additional to these
                                    Conditions. The same shall not be binding
                                    upon Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer unless specifically accepted
                                    and signed by Evobi Automations Pvt Ltd
                                    (BIBOX) / PlayComputer.
                                </p>

                                <p>
                                    The acceptance of a purchase order by Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    cannot be construed or implied to create an
                                    obligation on Evobi Automations Pvt Ltd
                                    (BIBOX) / PlayComputer’s part to accept,
                                    subsequent purchase orders for any of the
                                    goods and/or services.
                                </p>

                                <h3>
                                    <strong>7. Prices</strong>
                                </h3>

                                <p>
                                    The prices of the Goods or Services are the
                                    prices contained by Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer non-binding
                                    quotation or is the current list of prices
                                    as displayed on the website. The Tax is not
                                    included in prices. However, these prices
                                    are exclusive of delivery, packaging,
                                    carriage, insurance, customs duties which
                                    may be charged.
                                </p>

                                <p>
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer reserves all the rights to
                                    adjust the prices of any products/services
                                    at any point in time.
                                </p>

                                <p>
                                    For all international users of Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer,
                                    pricing should be strictly in USD/EURO/GBP.
                                    If an order is checked out in INR, the order
                                    is invalid.
                                </p>

                                <h3>
                                    <strong>
                                        8. Payment of Goods/Services
                                    </strong>
                                </h3>

                                <p>
                                    Unless a credit account has been set up
                                    prior to sale, a payment is due prior to
                                    shipment of the Goods or performance of
                                    services. In the case of an approved credit
                                    account, the payment is required to be made
                                    not later than 30 days after the date of the
                                    invoice.
                                </p>

                                <h3>
                                    <strong>9. Delivery of Goods</strong>
                                </h3>

                                <p>
                                    Delivery charges are applicable to all
                                    orders unless goods are picked up directly
                                    from one of our premises. A delivery occurs
                                    when the Goods are handed over to the buyer,
                                    a courier company or a delivery
                                    representative. Delivery dates quoted are
                                    approximate only and Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer will not be
                                    liable for any delay in the delivery of the
                                    Goods.
                                </p>

                                <p>
                                    If the buyer fails to take delivery of the
                                    Goods or fails to give adequate delivery
                                    instructions &amp; it returns to Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer,
                                    then Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer reserves all the rights to
                                    decide whether to resend the parcel or not.
                                </p>

                                <p>
                                    Any claim by the buyers or users for non or
                                    incorrect delivery must be advised to Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    within 10 days of a goods receipt.
                                </p>

                                <h3>
                                    <strong>10. Risk and Property</strong>
                                </h3>

                                <p>
                                    &nbsp;A risk of damage to or a loss of the
                                    goods passes on delivery or –&nbsp; if a
                                    buyer fails to take delivery without a good
                                    cause at the time when the delivery is
                                    tendered.
                                </p>

                                <p>
                                    The title to and the property in the Goods
                                    does not pass to a buyer until Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    has received a full payment of the price and
                                    all other sums due to Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer from the buyer.
                                </p>

                                <h3>
                                    <strong>11. Limitation of Liability</strong>
                                </h3>

                                <p>
                                    All buyers shall accept the sole
                                    responsibility for and Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer shall not be
                                    liable for any use of the goods by the
                                    buyers.&nbsp; Evobi Automations Pvt Ltd
                                    (BIBOX) / PlayComputer’s agents and
                                    employees and buyers shall hold Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer
                                    harmless and fully indemnify against any
                                    claims, costs, damages, loss, and
                                    liabilities arising out of such use.
                                </p>

                                <p>
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer is not liable to any of the
                                    buyers because of any representation, or any
                                    warranty (expressed or implied), conditions
                                    or other terms, or any duty in common law,
                                    or under the expressed terms of the
                                    Contract, for : (a) any loss of profit,
                                    business contracts, opportunity, goodwill,
                                    revenue, anticipated savings, expenses,
                                    costs or similar loss; and/or (b) any
                                    indirect, special or consequential loss or
                                    damage; and in the case of either paragraph
                                    (a) or (b) above whether caused by
                                    negligence, breach of contract, tort, or
                                    breach or statutory duty of Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer,
                                    arising out of or in connect with the
                                    contract.
                                </p>

                                <p>
                                    Any other liability of Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer to the buyers in
                                    contract, tort, negligence, breach of
                                    statutory duty or otherwise arising out of
                                    or in connection with the contract is
                                    limited to the price.
                                </p>

                                <h3>
                                    <strong>
                                        12. Confidentiality Agreement
                                    </strong>
                                </h3>

                                <p>
                                    &nbsp;All users/buyers must always keep the
                                    confidential information acquired in
                                    consequence of the contract, including
                                    business correspondence, technical
                                    literature, except for information which is
                                    bound to be disclosed by law or to its
                                    professional advisers where it is necessary
                                    for the performance of their professional
                                    services.
                                </p>

                                <p>
                                    The users agree not to disclose any
                                    confidential information of Evobi
                                    Automations Pvt Ltd (BIBOX) / PlayComputer)
                                    to anyone, unless and until it is required
                                    to do so by law.&nbsp;&nbsp;&nbsp;
                                </p>

                                <h3>
                                    <strong>13. User-Generated Content</strong>
                                </h3>

                                <p>
                                    User-Generate Content includes any comments,
                                    projects, forum posts or links directed to
                                    third party websites that users submit to
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer.
                                </p>

                                <p>
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer encourages every user to foster
                                    and heave up creativity by sharing art,
                                    works, and projects on Evobi Automations Pvt
                                    Ltd (BIBOX) / PlayComputer. We also
                                    understand the needs to protect the rights
                                    of users for their content – hence, users
                                    are responsible for protecting their rights
                                    of sharing content on the Evobi Automations
                                    Pvt Ltd (BIBOX) / PlayComputer website.
                                </p>

                                <p>
                                    If you notice a violation of any of terms
                                    and policies with respect this agreement, we
                                    request you to please let us know/bring to
                                    our attention immediately, so that necessary
                                    steps can be actioned accordingly.
                                </p>

                                <h3>
                                    <strong>
                                        14. Termination and cancellation of User
                                        Rights/Account
                                    </strong>
                                </h3>

                                <p>
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer reserves all rights to
                                    terminate and cancel user rights and account
                                    of anyone without giving a prior notice if a
                                    user doesn’t comply with the mentioned terms
                                    and conditions.
                                </p>

                                <h3>
                                    <strong>15. General</strong>
                                </h3>

                                <p>
                                    These conditions and the documents referred
                                    to in them, set out the entire agreement
                                    between the Parties and supersede any prior
                                    written or oral agreement between them
                                    relating to the subject matter of the
                                    Contract and the buyers acknowledge that in
                                    entering into the contract or agreement, it
                                    has not relied on any representation, not
                                    expressly set out in the contract.
                                </p>

                                <p>
                                    If any provision of these conditions is held
                                    to be illegal, void or unenforceable, the
                                    legality, validity and enforceability of the
                                    remainder of the Contract is not to be
                                    affected.
                                </p>

                                <p>
                                    Nothing in this contract excludes liability
                                    for fraud.
                                </p>

                                <p>
                                    No person (either legal or natural) who is
                                    not a party has a right to enforce any term
                                    or condition of the Contract.
                                </p>

                                <p>
                                    All suspected fraud causes are to be handed
                                    directly to the relevant authorities without
                                    exception.
                                </p>

                                <p>
                                    Evobi Automations Pvt Ltd (BIBOX) /
                                    PlayComputer reserves all rights to change
                                    pricing even upon successful checkout after
                                    communicating with customers.
                                </p>

                                <p>
                                    Subject to Karnataka Jurisdiction
                                    only.&nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TermsAndConditions
