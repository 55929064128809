import styles from './home.module.css'
import MainImage from '../../Assets/Bisoft_UI/Main/PNG/homePage_asset@2x.png'
import Logo from '../../Assets/images/plodelogo.svg'
import box from '../../Assets/Bisoft_UI/Buttons/Active with shadow/Homepage/container@2x.png'
import android from '../../Assets/Bisoft_UI/Buttons/Active with shadow/Homepage/android@2x.png'
import ios from '../../Assets/Bisoft_UI/Buttons/Active with shadow/Homepage/ios@2x.png'
import plodeBtn from '../../Assets/Bisoft_UI/Buttons/Active with shadow/Homepage/plodeWeb@2x.png'
import { useHistory } from 'react-router'
import { browserName, browserVersion, isMobile } from 'react-device-detect'
import App from '../../App'

function HomePage(params) {
    let history = useHistory()
    var supportedBrowser = false
    if (
        browserName == 'Chrome' ||
        browserName == 'Edge' ||
        browserName == 'Opera'
    ) {
        if (parseInt(browserVersion) > 89) {
            console.log(parseInt(browserVersion), 'versionbrowser')
            supportedBrowser = true
        }
    }
    return (
        <>
            {/* grid 2 rows */}
            <div className={styles.mainContainer}>
                {/* ROW1===grid 2 cols */}
                <div className={styles.mainBody}>
                    {/* col1 */}
                    <div className={styles.mainImageContainer}>
                        <img src={MainImage} className={styles.mainImage}></img>
                    </div>
                    {/* col 2 */}
                    <div className={styles.mainContentContainer}>
                        <div className={styles.logoContainer}>
                            <img
                                src={Logo}
                                className={styles.mainContentLogo}
                            ></img>
                        </div>
                        <div className={styles.paragraphContainer}>
                            <div className={styles.paragraph}>
                                PLODE is an all in one coding & tinkering
                                environment which provides effortless and par
                                excellence features to your kid with which they
                                can create the machines of future. PLODE is not
                                only an App but it is an integrated development
                                environment over which sits the fun remote
                                control front-end, unique hexagonal coding
                                blocks, flowchart based coding, block based
                                coding & matchless python and C scripters. While
                                peripheral modes such as Play, Learn, and App
                                mode, integrate complementarity into the
                                ecosystem, with products such as Play Computer
                                which provides a playful approach to
                                innovations. PLODE Application can connect with
                                the Play Computer and our vast collection of
                                add-ons, accessories and sensors that makes Play
                                Computer even smarter.
                            </div>
                        </div>
                        <div className={styles.buttonsContainer}>
                            {/* <button>Open web app</button>
              <div>
                {" "}
                *Plode web app is still in beta version, kindly use the android
                or ios app for a better experience.
              </div> */}
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div className={styles.box}>
                                    <img
                                        src={plodeBtn}
                                        className={styles.boxImage}
                                        onClick={() => {
                                            history.push('/login')
                                            window.location.reload(true)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    ></img>
                                </div>
                                <div className={styles.box}>
                                    <img
                                        src={box}
                                        className={styles.boxImage}
                                    ></img>
                                    <p className={styles.boxItem}>Try Now</p>
                                    <img
                                        src={android}
                                        className={styles.boxItem}
                                        style={{
                                            left: '45%',
                                            top: '25%',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            window.location.href =
                                                'https://play.google.com/store/apps/details?id=com.bibox.bisoftsuite'
                                        }}
                                    ></img>
                                    <img
                                        src={ios}
                                        className={styles.boxItem}
                                        onClick={() => {
                                            window.location.href =
                                                'https://apps.apple.com/in/app/plode/id1580203387'
                                        }}
                                        style={{
                                            left: '62%',
                                            top: '25%',
                                            cursor: 'pointer',
                                        }}
                                    ></img>
                                </div>
                            </div>

                            <div
                                style={{
                                    fontSize: '0.7vw',
                                    marginLeft: '20px',
                                    color: 'grey',
                                    width: '27vw',
                                }}
                            >
                                {isMobile ? (
                                    <div className={styles.msg}>
                                        If you would like to connect the
                                        hardware kindly use this website on a
                                        Desktop/Laptop or download the Plode App
                                        from the PlayStore/AppStore
                                    </div>
                                ) : supportedBrowser == false ? (
                                    <div className={styles.msg}>
                                        Kindly use the latest version of Google
                                        Chrome or Microsoft Edge to view this
                                        website
                                    </div>
                                ) : null}
                                {/* // {supportedBrowser == false ? (<div className={styles.msg}>Kindly use the latest version of Google Chrome
                //  or Microsoft Edge to view this website</div>) : null } */}
                                *Plode Web App is still in beta, kindly use the
                                android or ios app for a better experience.
                            </div>
                        </div>

                        <div className={styles.bottomTextDiv}>
                            <p className={styles.copyRightText}>
                                <span style={{ fontSize: '1.1rem' }}>
                                    &copy;
                                </span>
                                {'  '}
                                PLAYFULLY CRAFTED BY YUDU ROBOTICS
                            </p>
                        </div>
                    </div>
                </div>
                {/* ROW2 */}
                <div className={styles.footer}>
                    <div
                        className={styles.privacyPolicy}
                        onClick={() => {
                            history.push('/TermsAndConditions')
                        }}
                    >
                        Terms & Conditions
                    </div>
                    <div
                        className={styles.privacyPolicy}
                        onClick={() => {
                            history.push('/privacyPolicy')
                        }}
                    >
                        Privacy Policy
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage
