import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import nextY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/next.png'
import backY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import cameraY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/camera.png'
import micY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/mic.png'
import pcY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/pc.png'
import remoteY from '../../Assets/Bisoft_UI/AppMode/remote.png'
import cameraYActive from '../../Assets/Bisoft_UI/Buttons/Active without shadow/cameraActive.png'
import micYActive from '../../Assets/Bisoft_UI/Buttons/Active without shadow/micActive.png'
import pcYActive from '../../Assets/Bisoft_UI/Buttons/Active without shadow/pcActive.png'
import remoteYActive from '../../Assets/Bisoft_UI/AppMode/remoteActive.png'
import selectbarY from '../../Assets/Bisoft_UI/AppMode/selectbar.png'
import { useHistory } from 'react-router'
import { initDB, useIndexedDB } from 'react-indexed-db'
import { DBConfig } from './DBConfig'
import close from '../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import cacheAssets from '../../utils/cacheAssets'
import AppMode_Img from '../../source/AppMode_Img'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import closeBtn from '../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../source/programImg'
import renderImage from '../../source/importImg'

initDB(DBConfig)

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

const DeviceSelection = () => {
    let enable
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const { getAll, clear } = useIndexedDB('ProgramData')
    const arr = sessionStorage.getItem('arr') || []
    const history = useHistory()

    useEffect(() => {
        getAll().then((result) => {
            if (arr.length === 0 && result.length !== 0) {
                clear()
            }
        })
    }, [getAll, clear])

    let s = { cam: null, mic: null, pc: null }
    let session = JSON.parse(sessionStorage.getItem('enable'))
    if (session === null || Object.keys(session.s1).length === 0) {
        s = {
            cam: false,
            mic: false,
            pc: false,
            remote: false,
        }
    } else {
        s = {
            cam: session.s1.cam,
            mic: session.s1.mic,
            pc: session.s1.pc,
            remote: session.s1.remote,
        }
    }

    //   const navigate = useNavigate();
    const [cam, setCam] = useState(s.cam)
    const [mic, setMic] = useState(s.mic)
    const [pc, setPc] = useState(s.pc)
    const [remote, setRemote] = useState(s.remote)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    if (session === null || session.s2 === undefined) {
        enable = {
            s1: { cam: cam, mic: mic, pc: pc },
        }
    } else {
        enable = {
            s1: { cam: cam, mic: mic, pc: pc },
            s2: session.s2,
        }
    }

    let deviceName = 'PlayComputer'
    switch (connectedDevice) {
        case 'Ace':
            deviceName = 'PC'
            break
        case 'Humanoid':
            deviceName = 'Zing'
            break
        case 'Hexapod':
            deviceName = 'Crawl-e'
            break
        case 'Klaw':
            deviceName = 'Klaw-b'
            break
        default:
            deviceName = connectedDevice
    }

    useEffect(() => {
        var allDetails =
            JSON.parse(sessionStorage.getItem('allDetails')) || undefined
        if (allDetails === undefined) return
        if (mic === false) {
            Object.keys(allDetails).map((key) => {
                if (allDetails[key].option === 'Speech Recognized')
                    allDetails[key].option = null
            })
        }
        if (pc === false) {
            Object.keys(allDetails).map((key) => {
                if (allDetails[key].option === 'USB')
                    allDetails[key].option = null
            })
        }
        if (cam === false) {
            Object.keys(allDetails).map((key) => {
                if (
                    allDetails[key].option === 'Emotion Detected' ||
                    allDetails[key].option === 'Face Detected' ||
                    allDetails[key].option === 'Object Detected'
                )
                    allDetails[key].option = null
            })
        }
        sessionStorage.setItem('allDetails', JSON.stringify(allDetails))
    }, [cam, mic, pc])
    sessionStorage.setItem('enable', JSON.stringify(enable))
    const clickHandler = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        if (cam === true) {
            history.push('/selectDetection')
        } else if (mic === true || pc === true) {
            history.push('/programmingPage')
        } else if (remote === true) {
            history.push('appMode/remote')
        } else {
            setPopup(true)
            setPopupText('Please select aleast one mode')
        }
    }
    const shouldEraseYes = () => {
        const keys = [
            'enable',
            'count',
            'arr',
            'appDetails',
            'img',
            'tensor',
            'allDetails',
            'currentSavedSessionId',
        ]
        Object.keys(sessionStorage).map((key) => {
            if (keys.includes(key)) {
                sessionStorage.removeItem(key)
            }
        })
        history.push('/selection')
    }
    const shouldErasedNo = () => {
        setErasePopup(false)
    }

    const [popup, setPopup] = useState(false)
    const [erasePopup, setErasePopup] = useState(false)

    const [popupText, setPopupText] = useState('')
    const [erasedProgram, setErasedProgram] = useState(false)

    const Popup = (props) => {
        return props.trigger ? (
            <div
                style={{
                    position: 'absolute',
                    top: '-1vh',
                    left: '0',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(255,255,255, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '90000',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        padding: '1rem 2rem',
                        width: '50%',
                        maxWidth: '400px',
                        minHeight: '150px',
                        border: '1px solid white',
                        borderRadius: '4rem',
                        boxShadow: '0.5rem 0.5rem 2rem #d9d5d4',
                        background: 'linear-gradient(to right, #f0eded, white)',
                    }}
                >
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            top: '-2vh',
                            right: '0vw',
                        }}
                        onClick={() => {
                            setPopup(false)
                        }}
                    />
                </div>
                <div
                    style={{
                        zIndex: '5',
                        width: '25%',
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <strong>{props.text}</strong>
                </div>
            </div>
        ) : (
            ''
        )
    }

    const clickBackHandler = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)

        setErasePopup(true)
    }
    const [ishelpButton, sethelpButton] = useState(false)
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    useEffect(() => {
        let assetsToBeCached = [
            AppMode_Img('selectbarX'),
            AppMode_Img('ellipse'),
            AppMode_Img('buttonPlus'),
            AppMode_Img('popupCard'),
            AppMode_Img('pause'),
            AppMode_Img('play'),
            AppMode_Img('cameraP5'),
            AppMode_Img('selectBarfive'),
            AppMode_Img('popupCardfive'),
            AppMode_Img('popupCardone'),
            AppMode_Img('projectbig'),
            AppMode_Img('musicIcon'),
            AppMode_Img('RecognizeText'),
            AppMode_Img('faceY'),
            AppMode_Img('faceXActive'),
            AppMode_Img('objectY'),
            AppMode_Img('objectXActive'),
            AppMode_Img('emotionY'),
            AppMode_Img('emotionXActive'),
            AppMode_Img('skip'),
            AppMode_Img('captureButton'),
            AppMode_Img('startCamera'),
            AppMode_Img('PopupCard'),
            AppMode_Img('cameraYActive'),
            AppMode_Img('micYActive'),
            AppMode_Img('pcYActive'),
            AppMode_Img('cameraY'),
            AppMode_Img('micY'),
            AppMode_Img('pcY'),
            // helpScreen
            renderPrgImage('AppMicH'),
            renderPrgImage('AppPcH'),
            renderPrgImage('AppCameraH'),
            renderPrgImage('AppRemoteH'),
            renderPrgImage('PrgmH'),
            renderPrgImage('PrgmH1'),
            renderPrgImage('PrgmH2'),
            renderPrgImage('PrgmH3'),
            renderPrgImage('PrgmH4'),
            renderPrgImage('faceH'),
            renderPrgImage('emotionH'),
            renderPrgImage('objectH'),
            renderPrgImage('appModeRemoteH'),
            renderPrgImage('emotionDecH'),
            renderPrgImage('emotionDecH1'),
            renderPrgImage('emotionDecH2'),
            renderPrgImage('emotionDecH3'),
            renderPrgImage('emotionDecH4'),
            renderPrgImage('emotionDecH5'),

            // selectionScreen
            renderImage('backBtn'),
            renderImage('helpBtnInActive'),
            renderImage('Ply'),
            renderImage('Prog'),
            renderImage('activeAdvanceSetting'),
            renderPrgImage('dataButton'),
            renderPrgImage('appButton'),
            renderPrgImage('playButton'),
            renderPrgImage('learnButton'),
            renderPrgImage('codeButton'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    // let connectedDevice = sessionStorage.getItem('connectedDevice')

    return (
        <div>
            <DialogModal
                show={erasePopup}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldEraseYes(),
                    },
                    {
                        text: 'NO',
                        do: () => shouldErasedNo(),
                    },
                ]}
            />
            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="20%"
                    headers={[
                        { name: 'Enable', color: 'white' },
                        { name: 'Control', color: 'black' },
                        { name: 'Run', color: 'black' },
                    ]}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
            </div>
            {/* Main */}

            <div
                className={`${slideDirection === 'true' ? 'slide-right' : ''}`}
            >
                <div style={{ position: 'relative' }}>
                    <img
                        src={cam ? cameraYActive : cameraY}
                        alt={cam ? 'cameraActive' : 'camera'}
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '41vh',
                            left: '20vw',
                            width: '10vw',
                            cursor: 'pointer',
                            transform: 'translate(-50%, -50%)',
                        }}
                        draggable="false"
                        onClick={() => {
                            setCam(!cam)
                            setRemote(false)
                        }}
                    />
                    <img
                        src={mic ? micYActive : micY}
                        alt={mic ? 'micActive' : 'mic'}
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '41vh',
                            left: '40vw',
                            width: '10vw',
                            cursor: 'pointer',
                            transform: 'translate(-50%, -50%)',
                        }}
                        draggable="false"
                        onClick={() => {
                            setMic(!mic)
                            setRemote(false)
                        }}
                    />{' '}
                    <img
                        src={pc ? pcYActive : pcY}
                        alt={pc ? 'pcYActive' : 'pc'}
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '41vh',
                            left: '60vw',
                            width: '10vw',
                            cursor: 'pointer',
                            transform: 'translate(-50%, -50%)',
                        }}
                        draggable="false"
                        onClick={() => {
                            setPc(!pc)
                            setRemote(false)
                        }}
                    />
                    <img
                        src={remote ? remoteYActive : remoteY}
                        alt={remote ? 'remoteYActive' : 'remote'}
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '41vh',
                            left: '80vw',
                            width: '10vw',
                            cursor: 'pointer',
                            transform: 'translate(-50%, -50%)',
                        }}
                        draggable="false"
                        onClick={() => {
                            setRemote(!remote)
                            setCam(false)
                            setMic(false)
                            setPc(false)
                        }}
                    />
                    {/* Element to Move Dynamically */}
                    <h2
                        style={{
                            position: 'absolute',
                            top: '52vh',
                            transform: 'translate(-50%,-50%)',
                            left: '20%',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '16px',
                        }}
                    >
                        <strong>Camera</strong>
                    </h2>
                    {/* Element to Move Dynamically */}
                    <h2
                        style={{
                            position: 'absolute',
                            top: '52vh',
                            transform: 'translate(-50%,-50%)',
                            left: '40%',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '16px',
                        }}
                    >
                        <strong>Mic</strong>
                    </h2>
                    {/* Element to Move Dynamically */}
                    <h2
                        style={{
                            position: 'absolute',
                            top: '52vh',
                            transform: 'translate(-50%,-50%)',
                            left: '60%',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '16px',
                            // opacity: `${device}` === `Humanoid` ? '0.5' : '',
                        }}
                    >
                        <strong>{deviceName}</strong>
                    </h2>
                    {/* Element to Move Dynamically */}
                    <h2
                        style={{
                            position: 'absolute',
                            top: '52vh',
                            transform: 'translate(-50%,-50%)',
                            left: '80%',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '16px',
                        }}
                    >
                        <strong>Remote</strong>
                    </h2>
                </div>
            </div>

            {/* Footer */}
            <div>
                <img
                    src={nextY}
                    alt="next"
                    draggable="false"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        left: '95.8vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    // laxmi
                    onClick={clickHandler}
                />
                {popup ? <Popup trigger={popup} text={popupText} /> : ''}
                <p
                    style={{
                        textAlign: 'center',
                        marginTop: '75vh',
                        // font: "Halcyon Regular",
                        // fontFamily: "Halcyon Regular",
                        fontSize: '16px',
                        color: 'grey',
                        position: 'absolute',
                        top: '16%',
                        left: '50%',
                        transform: 'translate(-50%,0)',
                    }}
                >
                    Tap on the icons to select the smart features you want to
                    use
                </p>
                <img
                    src={backY}
                    alt="back"
                    draggable="false"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={clickBackHandler}
                />
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('AppCameraH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "90px",
                                    width: '3%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '25.5%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Enable this feature use camera as the AI
                                    based programming. You can internally use
                                    Face recognition, object dectection &
                                    emotion detection
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('AppMicH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "90px",
                                    width: '3%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '36.8%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Enable this feature to use Mic as the AI
                                    based programming. Here, speech based word
                                    detection will be enabled
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('AppPcH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "90px",
                                    width: '3%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '43.5%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Enable this feature use selected device in
                                    sync with App Mode to perform certain
                                    actions or take input
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('AppRemoteH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "90px",
                                    width: '3%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '54.7%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Select this button to enable Remote control
                                    mode with the {deviceName}.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}
export default DeviceSelection
