// Device Section Images
import nextY from '../Assets/Bisoft_UI/Buttons/Active without shadow/next.png'
import backY from '../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import cameraY from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/camera.png'
import micY from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/mic.png'
import pcY from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/pc.png'
import cameraYActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/cameraActive.png'
import micYActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/micActive.png'
import pcYActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/pcActive.png'
import selectbarY from '../Assets/Bisoft_UI/AppMode/selectbar.png'
import close from '../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'

// Programme
import selectbarX from '../Assets/Bisoft_UI/AppMode/selectbar@2.png'
import ellipse from '../Assets/Bisoft_UI/AppMode/ellipse.png'
import buttonPlus from '../Assets/Bisoft_UI/Buttons/Active without shadow/add.png'
import popupCard from '../Assets/Bisoft_UI/AppMode/appModepopupCard.png'

// SpeechRecognition
import pause from '../Assets/Bisoft_UI/Buttons/Active with shadow/pause_btn@3x.png'
import play from '../Assets/Bisoft_UI/Buttons/Active with shadow/play-run.png'
import cameraP5 from '../Assets/Bisoft_UI/AppMode/camera(speech).png'
import selectBarfive from '../Assets/Bisoft_UI/AppMode/selectBarfive.png'
import popupCardfive from '../Assets/Bisoft_UI/AppMode/popupCardfive.png'
import popupCardone from '../Assets/Bisoft_UI/AppMode/popupCardone.png'
import projectbig from '../Assets/Bisoft_UI/AppMode/projectBig.png'
import musicIcon from '../Assets/Bisoft_UI/AppMode/musicIcon.png'

// SelectDevice
import RecognizeText from '../Assets/Bisoft_UI/AppMode/RecognizeText.png'
import faceY from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/face.png'
import faceXActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/faceActive.png'
import objectY from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/object.png'
import objectXActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/objectActive.png'
import emotionY from '../Assets/Bisoft_UI/Buttons/Inactive - without shadow/emotion.png'
import emotionXActive from '../Assets/Bisoft_UI/Buttons/Active without shadow/emotionActive.png'

// UploadImage
import skip from '../Assets/Bisoft_UI/Buttons/Active without shadow/skip@2x.png'
import captureButton from '../Assets/Bisoft_UI/AppMode/capture.png'
import startCamera from '../Assets/Bisoft_UI/AppMode/startCamera.png'
import PopupCard from '../Assets/Bisoft_UI/AppMode/popupCard@5.png'

function AppMode_Img(imageName) {
    switch (imageName) {
        case 'nextY':
            return nextY
        case 'backY':
            return backY
        case 'cameraY':
            return cameraY
        case 'micY':
            return micY
        case 'pcY':
            return pcY
        case 'cameraYActive':
            return cameraYActive
        case 'micYActive':
            return micYActive
        case 'pcYActive':
            return pcYActive
        case 'selectbarY':
            return selectbarY
        case 'close':
            return close
        case 'selectbarX':
            return selectbarX
        case 'ellipse':
            return ellipse
        case 'buttonPlus':
            return buttonPlus
        case 'popupCard':
            return popupCard
        case 'pause':
            return pause
        case 'play':
            return play
        case 'cameraP5':
            return cameraP5
        case 'selectBarfive':
            return selectBarfive
        case 'popupCardfive':
            return popupCardfive
        case 'popupCardone':
            return popupCardone
        case 'projectbig':
            return projectbig
        case 'musicIcon':
            return musicIcon
        case 'RecognizeText':
            return RecognizeText
        case 'faceY':
            return faceY
        case 'faceXActive':
            return faceXActive
        case 'objectY':
            return objectY
        case 'objectXActive':
            return objectXActive
        case 'emotionY':
            return emotionY
        case 'emotionXActive':
            return emotionXActive
        case 'skip':
            return skip
        case 'captureButton':
            return captureButton
        case 'startCamera':
            return startCamera
        case 'PopupCard':
            return PopupCard
    }
}
export default AppMode_Img
