import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import Panel1 from './FlowchartConnections/logic/pannel'

import Bottom from './Bottom'
import selectImg from '../../Assets/img/select bar@2x.png'
import secondaryImg from '../../Assets/Bisoft_UI/Buttons/Inactive with shadow/save - secondary@2x.png'
import strokeImg from '../../Assets/Bisoft_UI/Buttons/Inactive with shadow/help@2x.png'
import connectionImg from '../../Assets/usb - off@2x.png'
import './InternalAccessories.css'
import './style.css'

import { connect } from 'react-redux'
import { webSerialConnect } from '../../redux/actions'

import popupcardImg from '../../Assets/internalAccessories/popupcard@2x.png'
import pcImg from '../../Assets/internalAccessories/PC_image@3x.png'

import PcinternalEYEActive from '../../Assets/internalAccessories/eye - active.svg'
import PcinternalEYEInActive from '../../Assets/internalAccessories/eye - inactive.svg'
import PcinternalTeethInActive from '../../Assets/internalAccessories/teeth - inactive.svg'
import PcinternalTeethActive from '../../Assets/internalAccessories/teeth - active.svg'

import Pcinternal4in1Active from '../../Assets/internalAccessories/4 in 1 - active.svg'
import Pcinternal4in1InActive from '../../Assets/internalAccessories/4 in 1 - inactive.svg'
import PcinternalMicActive from '../../Assets/internalAccessories/internal mic - inactive.svg'
import PcinternalMicInActive from '../../Assets/internalAccessories/internal mic - active.svg'
import PcinternalBuzzerInActive from '../../Assets/internalAccessories/buzzer - inactive.svg'
import PcinternalBuzzerActive from '../../Assets/internalAccessories/buzzer - active.svg'
import PcinternalTouchpadsInActive from '../../Assets/internalAccessories/touch pads - inactive.svg'
import PcinternalTouchpadsActive from '../../Assets/internalAccessories/touch pads - active.svg'

import mic from '../../Assets/internalAccessories/inputsandoutputs/mic.png'
import micEnabled from '../../Assets/internalAccessories/inputsandoutputs/mic-enabled.png'
import temperature from '../../Assets/internalAccessories/inputsandoutputs/temperature.png'
import temperatureEnabled from '../../Assets/internalAccessories/inputsandoutputs/temperature-enabled.png'
import touchzero from '../../Assets/internalAccessories/inputsandoutputs/touchpad0.png'
import touchzeroEnabled from '../../Assets/internalAccessories/inputsandoutputs/touchpad0-enabled.png'
import touchone from '../../Assets/internalAccessories/inputsandoutputs/touchpad1.png'
import touchoneEnabled from '../../Assets/internalAccessories/inputsandoutputs/touchpad1-enabled.png'
import touchtwo from '../../Assets/internalAccessories/inputsandoutputs/touchpad2.png'
import touchtwoEnabled from '../../Assets/internalAccessories/inputsandoutputs/touchpad2-enabled.png'
import distancesensor from '../../Assets/internalAccessories/inputsandoutputs/distancesensor.png'
import distancesensorEnabled from '../../Assets/internalAccessories/inputsandoutputs/distancesensor-enabled.png'
import gesturesensor from '../../Assets/internalAccessories/inputsandoutputs/gesture.png'
import gesturesensorEnabled from '../../Assets/internalAccessories/inputsandoutputs/gesture-enabled.png'
import lightsensor from '../../Assets/internalAccessories/inputsandoutputs/lightsensor.png'
import lightsensorEnabled from '../../Assets/internalAccessories/inputsandoutputs/lightsensor-enabled.png'
import colorsensor from '../../Assets/internalAccessories/inputsandoutputs/colorsensor.png'
import colorsensorEnabled from '../../Assets/internalAccessories/inputsandoutputs/colorsensor-enabled.png'
import lefteye from '../../Assets/internalAccessories/inputsandoutputs/lefteye.png'
import lefteyeEnabled from '../../Assets/internalAccessories/inputsandoutputs/lefteye-enabled.png'
import righteye from '../../Assets/internalAccessories/inputsandoutputs/righteye.png'
import righteyeEnabled from '../../Assets/internalAccessories/inputsandoutputs/righteye-enabled.png'
import buzzer from '../../Assets/internalAccessories/inputsandoutputs/buzzer.png'
import buzzerEnabled from '../../Assets/internalAccessories/inputsandoutputs/buzzer-enabled.png'
import smileone from '../../Assets/internalAccessories/inputsandoutputs/smile1.png'
import smileoneEnabled from '../../Assets/internalAccessories/inputsandoutputs/smile1-enabled.png'
import smiletwo from '../../Assets/internalAccessories/inputsandoutputs/smile2.png'
import smiletwoEnabled from '../../Assets/internalAccessories/inputsandoutputs/smile2-enabled.png'
import smilethree from '../../Assets/internalAccessories/inputsandoutputs/smile3.png'
import smilethreeEnabled from '../../Assets/internalAccessories/inputsandoutputs/smile3-enabled.png'
import smilefour from '../../Assets/internalAccessories/inputsandoutputs/smile4.png'
import smilefourEnabled from '../../Assets/internalAccessories/inputsandoutputs/smile4-enabled.png'
import { Link, useHistory } from 'react-router-dom'
import { useLocalStorage } from '../LocalStorage/LocalStorage'
import renderPrgImage from '../../source/programImg'

// import bgImg from "../../Assets/img/inputInactiveURAT.png";
import internalBgimg from '../../Assets/Bisoft_UI/Help screen/Program/Group 3212@2x.png'
import closeBtn from '../../Assets/img/close.png'
import robotBgimg from '../../Assets/Bisoft_UI/Help screen/Program/Group 3215@2x.png'
import inputBgimg from '../../Assets/Bisoft_UI/Help screen/Program/Group 3213@2x.png'
import outputBgimg from '../../Assets/Bisoft_UI/Help screen/Program/Group 3214@2x.png'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import ProgramHeader from '../ReusableComponents/Header/ProgramHeader'
import cacheAssets from '../../utils/cacheAssets'

var Panel = Panel1('')

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

const InternalAccessories = (props) => {
    const history = useHistory()
    const mode = sessionStorage.getItem('programMode')
    function findIndex(array, string) {
        var index = []
        for (var i = 0; i < array.length; i++) {
            if (array[i].indexOf(string) > -1) {
                index.push(i)
            }
        }
        return index
    }
    const [ishelpButton, sethelpButton] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }
    const [isUsb, setUsb] = useState(false)
    const [p1, setP1] = useState({
        selected: false,
        port: {},
    })

    useEffect(() => {
        console.log('PROPS', props.location.data)

        // if(props.location.data)
        let hhh = JSON.parse(localStorage.getItem('SavedFlowData'))
        // console.log("Names", hhh[0].name);
        if (hhh != null) {
            for (let i = 0; i < hhh.length; i++) {
                if (props.location.data == hhh[i].name) {
                    sessionStorage.setItem('pip', true)
                    sessionStorage.setItem('name', props.location.data)
                    // console.log("KK", hhh[i].concept.internalaccessories.isTouchOne);
                    Object.keys(hhh[i]).map((key, value) => {
                        console.log('KEYS', key, value)
                        switch (key) {
                            case 'isSmileOne': {
                                sessionStorage.setItem(
                                    'isSmileOne',
                                    JSON.stringify(hhh[i].isSmileOne)
                                )
                            }
                            case 'isSmileTwo': {
                                sessionStorage.setItem(
                                    'isSmileTwo',
                                    JSON.stringify(hhh[i].isSmileTwo)
                                )
                            }
                            case 'isSmileThree': {
                                sessionStorage.setItem(
                                    'isSmileThree',
                                    JSON.stringify(hhh[i].isSmileThree)
                                )
                            }
                            case 'isSmileFour': {
                                sessionStorage.setItem(
                                    'isSmileFour',
                                    JSON.stringify(hhh[i].isSmileFour)
                                )
                            }
                            case 'isColorSensor': {
                                sessionStorage.setItem(
                                    'isColorSensor',
                                    JSON.stringify(hhh[i].isColorSensor)
                                )
                            }
                            case 'isGestureSensor': {
                                sessionStorage.setItem(
                                    'isGestureSensor',
                                    JSON.stringify(hhh[i].isGestureSensor)
                                )
                            }
                            case 'isDistanceSensors': {
                                sessionStorage.setItem(
                                    'isDistanceSensors',
                                    JSON.stringify(hhh[i].isDistanceSensors)
                                )
                            }
                            case 'isLightSensor': {
                                sessionStorage.setItem(
                                    'isLightSensor',
                                    JSON.stringify(hhh[i].isLightSensor)
                                )
                            }
                            case 'A1': {
                                sessionStorage.setItem(
                                    'A1',
                                    JSON.stringify(hhh[i].A1)
                                )
                            }
                            case 'A2': {
                                sessionStorage.setItem(
                                    'A2',
                                    JSON.stringify(hhh[i].A2)
                                )
                            }
                            case 'A1DIGI': {
                                sessionStorage.setItem(
                                    'A1DIGI',
                                    JSON.stringify(hhh[i].A1DIGI)
                                )
                            }
                            case 'A2DIGI': {
                                sessionStorage.setItem(
                                    'A2DIGI',
                                    JSON.stringify(hhh[i].A2DIGI)
                                )
                            }

                            case 'B1': {
                                sessionStorage.setItem(
                                    'B1',
                                    JSON.stringify(hhh[i].B1)
                                )
                            }
                            case 'B2': {
                                sessionStorage.setItem(
                                    'B2',
                                    JSON.stringify(hhh[i].B2)
                                )
                            }

                            case 'B1DIGI': {
                                sessionStorage.setItem(
                                    'B1DIGI',
                                    JSON.stringify(hhh[i].B1DIGI)
                                )
                            }
                            case 'B2DIGI': {
                                sessionStorage.setItem(
                                    'B2DIGI',
                                    JSON.stringify(hhh[i].B2DIGI)
                                )
                            }

                            case 'C1': {
                                sessionStorage.setItem(
                                    'C1',
                                    JSON.stringify(hhh[i].C1)
                                )
                            }
                            case 'C2': {
                                sessionStorage.setItem(
                                    'C2',
                                    JSON.stringify(hhh[i].C2)
                                )
                            }
                            case 'C1DIGI': {
                                sessionStorage.setItem(
                                    'C1DIGI',
                                    JSON.stringify(hhh[i].C1DIGI)
                                )
                            }
                            case 'C2DIGI': {
                                sessionStorage.setItem(
                                    'C2DIGI',
                                    JSON.stringify(hhh[i].C2DIGI)
                                )
                            }
                            case 'isTemperature': {
                                sessionStorage.setItem(
                                    'isTemperature',
                                    JSON.stringify(hhh[i].isTemperature)
                                )
                            }
                            case 'isEyeLeft': {
                                sessionStorage.setItem(
                                    'isEyeLeft',
                                    JSON.stringify(hhh[i].isEyeLeft)
                                )
                            }
                            case 'isEyeRight': {
                                sessionStorage.setItem(
                                    'isEyeRight',
                                    JSON.stringify(hhh[i].isEyeRight)
                                )
                            }
                            case 'isMic': {
                                sessionStorage.setItem(
                                    'isMic',
                                    JSON.stringify(hhh[i].isMic)
                                )
                            }
                            case 'isTouchZeroOutput': {
                                sessionStorage.setItem(
                                    'isTouchZeroOutput',
                                    JSON.stringify(hhh[i].isTouchZeroOutput)
                                )
                            }
                            case 'isTouchOneOutput': {
                                sessionStorage.setItem(
                                    'isTouchOneOutput',
                                    JSON.stringify(hhh[i].isTouchOneOutput)
                                )
                            }
                            case 'isTouchTwoOutput': {
                                sessionStorage.setItem(
                                    'isTouchTwoOutput',
                                    JSON.stringify(hhh[i].isTouchTwoOutput)
                                )
                            }
                            case 'isTouchZero': {
                                sessionStorage.setItem(
                                    'isTouchZero',
                                    JSON.stringify(hhh[i].isTouchZero)
                                )
                            }
                            case 'isTouchOne': {
                                sessionStorage.setItem(
                                    'isTouchOne',
                                    JSON.stringify(hhh[i].isTouchOne)
                                )
                            }
                            case 'isTouchTwo': {
                                sessionStorage.setItem(
                                    'isTouchTwo',
                                    JSON.stringify(hhh[i].isTouchTwo)
                                )
                            }

                            case 'a1IO': {
                                sessionStorage.setItem(
                                    'a1-I/O',
                                    JSON.stringify(hhh[i].a1IO)
                                )
                            }
                            case 'a2IO': {
                                sessionStorage.setItem(
                                    'a2-I/O',
                                    JSON.stringify(hhh[i].a2IO)
                                )
                            }
                            case 'b1IO': {
                                sessionStorage.setItem(
                                    'b1-I/O',
                                    JSON.stringify(hhh[i].b1IO)
                                )
                            }
                            case 'b2IO': {
                                sessionStorage.setItem(
                                    'b2-I/O',
                                    JSON.stringify(hhh[i].b2IO)
                                )
                            }
                            case 'c1IO': {
                                sessionStorage.setItem(
                                    'c1-I/O',
                                    JSON.stringify(hhh[i].c1IO)
                                )
                            }
                            case 'c2IO': {
                                sessionStorage.setItem(
                                    'c1-I/O',
                                    JSON.stringify(hhh[i].c2IO)
                                )
                            }
                            case 'm1IO': {
                                sessionStorage.setItem(
                                    'm1-I/O',
                                    JSON.stringify(hhh[i].m1IO)
                                )
                            }
                            case 'm2IO': {
                                sessionStorage.setItem(
                                    'm2-I/O',
                                    JSON.stringify(hhh[i].m2IO)
                                )
                            }
                            case 'm3IO': {
                                sessionStorage.setItem(
                                    'm3-I/O',
                                    JSON.stringify(hhh[i].m3IO)
                                )
                            }
                            case 'm4IO': {
                                sessionStorage.setItem(
                                    'm4-I/O',
                                    JSON.stringify(hhh[i].m4IO)
                                )
                            }

                            case 'M1DIGI': {
                                sessionStorage.setItem(
                                    'M1DIGI',
                                    JSON.stringify(hhh[i].M1DIGI)
                                )
                            }
                            case 'M2DIGI': {
                                sessionStorage.setItem(
                                    'M2DIGI',
                                    JSON.stringify(hhh[i].M2DIGI)
                                )
                            }
                            case 'M3DIGI': {
                                sessionStorage.setItem(
                                    'M3DIGI',
                                    JSON.stringify(hhh[i].M3DIGI)
                                )
                            }
                            case 'M4DIGI': {
                                sessionStorage.setItem(
                                    'M4DIGI',
                                    JSON.stringify(hhh[i].M4DIGI)
                                )
                            }
                            case 'flowchartelements': {
                                sessionStorage.setItem(
                                    'flowchart-elements',
                                    JSON.stringify(hhh[i].flowchartelements)
                                )
                            }
                        }
                        if (key.includes('ifValue')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('ifSelect')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('gt')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('lt')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('ne')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('eq')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('bw')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }

                        if (key.includes('a1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('a2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('b1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('b2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('c1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('c2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('d1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('d2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('e1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('e2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('f1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('f2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('m1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('m2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('m3')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('m4')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }

                        if (key.includes('t0')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('t1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('t2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }

                        if (key.includes('le')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('re')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('buzz')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }

                        if (key.includes('s1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('s2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('s3')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('s4')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }

                        if (key.includes('mp3')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('oled')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('countRGB')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('rgb')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }

                        if (key.includes('valRGB1')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB2')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB3')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB4')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB5')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB6')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB7')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB8')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB9')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                        if (key.includes('valRGB10')) {
                            sessionStorage.setItem(key, hhh[i][key])
                        }
                    })
                    window.location.reload()
                }
            }
        }
    })

    const [isDistanceSensors, setDistanceSensors] = useLocalStorage(
        'isDistanceSensors',
        false
    )

    const [isGestureSensor, setGestureSensor] = useLocalStorage(
        'isGestureSensor',
        false
    )

    const [isLightSensor, setLightSensor] = useLocalStorage(
        'isLightSensor',
        false
    )

    const [isColorSensor, setColorSensor] = useLocalStorage(
        'isColorSensor',
        false
    )

    const [isTemperature, setTemperature] = useLocalStorage(
        'isTemperature',
        false
    )

    const [isMic, setMic] = useLocalStorage('isMic', false)
    // input
    const [isTouchZero, setTouchZero] = useLocalStorage('isTouchZero', false)
    const [isTouchOne, setTouchOne] = useLocalStorage('isTouchOne', false)
    const [isTouchTwo, setTouchTwo] = useLocalStorage('isTouchTwo', false)

    // outPut
    const [isTouchZeroOutput, setTouchZeroOutput] = useLocalStorage(
        'isTouchZeroOutput',
        false
    )
    const [isTouchOneOutput, setTouchOneOutput] = useLocalStorage(
        'isTouchOneOutput',
        false
    )
    const [isTouchTwoOutput, setTouchTwoOutput] = useLocalStorage(
        'isTouchTwoOutput',
        false
    )

    const [isEyeLeft, setEyeLeft] = useLocalStorage('isEyeLeft', false)
    const [isEyeRight, setEyeRight] = useLocalStorage('isEyeRight', false)
    const [isbuzzer, setbuzzer] = useLocalStorage('isBuzzer', false)

    const [isSimeleOne, setSimleOne] = useLocalStorage('isSmileOne', false)
    const [isSimeleTwo, setSimleTwo] = useLocalStorage('isSmileTwo', false)
    const [isSimeleThree, setSimleThree] = useLocalStorage(
        'isSmileThree',
        false
    )
    const [isSimeleFour, setSimleFour] = useLocalStorage('isSmileFour', false)

    const [erasedProgram, setErasedProgram] = useState(false)

    //gsk 28/2/2022 back button logic
    const [a1, setA1] = useLocalStorage(
        'A1',
        JSON.parse(sessionStorage.getItem('A1'))
    )
    const [a1Checked, setA1Checked] = useLocalStorage(
        'a1-I/O',
        JSON.parse(sessionStorage.getItem('a1-I/O'))
    )
    const [a1Digi, setA1Digi] = useLocalStorage(
        'A1DIGI',
        JSON.parse(sessionStorage.getItem('A1DIGI'))
    )
    const [a2, setA2] = useLocalStorage(
        'A2',
        JSON.parse(sessionStorage.getItem('A2'))
    )
    const [a2Checked, setA2Checked] = useLocalStorage(
        'a2-I/O',
        JSON.parse(sessionStorage.getItem('a2-I/O'))
    )
    const [a2Digi, setA2Digi] = useLocalStorage(
        'A2DIGI',
        JSON.parse(sessionStorage.getItem('A2DIGI'))
    )
    const [b1, setB1] = useLocalStorage(
        'B1',
        JSON.parse(sessionStorage.getItem('B1'))
    )
    const [b1Checked, setB1Checked] = useLocalStorage(
        'b1-I/O',
        JSON.parse(sessionStorage.getItem('b1-I/O'))
    )
    const [b1Digi, setB1Digi] = useLocalStorage(
        'B1DIGI',
        JSON.parse(sessionStorage.getItem('B1DIGI'))
    )
    const [b2, setB2] = useLocalStorage(
        'B2',
        JSON.parse(sessionStorage.getItem('B2'))
    )
    const [b2Checked, setB2Checked] = useLocalStorage(
        'b2-I/O',
        JSON.parse(sessionStorage.getItem('b2-I/O'))
    )
    const [b2Digi, setB2Digi] = useLocalStorage(
        'B2DIGI',
        JSON.parse(sessionStorage.getItem('B2DIGI'))
    )
    const [c1, setC1] = useLocalStorage(
        'C1',
        JSON.parse(sessionStorage.getItem('C1'))
    )
    const [c1Checked, setC1Checked] = useLocalStorage(
        'c1-I/O',
        JSON.parse(sessionStorage.getItem('c1-I/O'))
    )
    const [c1Digi, setC1Digi] = useLocalStorage(
        'C1DIGI',
        JSON.parse(sessionStorage.getItem('C1DIGI'))
    )
    const [c2, setC2] = useLocalStorage(
        'C2',
        JSON.parse(sessionStorage.getItem('C2'))
    )
    const [c2Checked, setC2Checked] = useLocalStorage(
        'c2-I/O',
        JSON.parse(sessionStorage.getItem('c2-I/O'))
    )
    const [c2Digi, setC2Digi] = useLocalStorage(
        'C2DIGI',
        JSON.parse(sessionStorage.getItem('C2DIGI'))
    )
    const [d1, setD1] = useLocalStorage(
        'D1',
        JSON.parse(sessionStorage.getItem('D1'))
    )
    const [d1Checked, setD1Checked] = useLocalStorage(
        'd1-I/O',
        JSON.parse(sessionStorage.getItem('d1-I/O'))
    )
    const [d1Digi, setD1Digi] = useLocalStorage(
        'D1DIGI',
        JSON.parse(sessionStorage.getItem('D1DIGI'))
    )
    const [d2, setD2] = useLocalStorage(
        'D2',
        JSON.parse(sessionStorage.getItem('D2'))
    )
    const [d2Checked, setD2Checked] = useLocalStorage(
        'd2-I/O',
        JSON.parse(sessionStorage.getItem('d2-I/O'))
    )
    const [d2Digi, setD2Digi] = useLocalStorage(
        'D2DIGI',
        JSON.parse(sessionStorage.getItem('D2DIGI'))
    )
    const [e1, setE1] = useLocalStorage(
        'E1',
        JSON.parse(sessionStorage.getItem('E1'))
    )
    const [e1Checked, setE1Checked] = useLocalStorage(
        'e1-I/O',
        JSON.parse(sessionStorage.getItem('e1-I/O'))
    )
    const [e1Digi, setE1Digi] = useLocalStorage(
        'E1DIGI',
        JSON.parse(sessionStorage.getItem('E1DIGI'))
    )
    const [e2, setE2] = useLocalStorage(
        'E2',
        JSON.parse(sessionStorage.getItem('E2'))
    )
    const [e2Checked, setE2Checked] = useLocalStorage(
        'e2-I/O',
        JSON.parse(sessionStorage.getItem('e2-I/O'))
    )
    const [e2Digi, setE2Digi] = useLocalStorage(
        'E2DIGI',
        JSON.parse(sessionStorage.getItem('E2DIGI'))
    )
    const [f1, setF1] = useLocalStorage(
        'F1',
        JSON.parse(sessionStorage.getItem('F1'))
    )
    const [f1Checked, setF1Checked] = useLocalStorage(
        'f1-I/O',
        JSON.parse(sessionStorage.getItem('f1-I/O'))
    )
    const [f1Digi, setF1Digi] = useLocalStorage(
        'F1DIGI',
        JSON.parse(sessionStorage.getItem('F1DIGI'))
    )
    const [f2, setF2] = useLocalStorage(
        'F2',
        JSON.parse(sessionStorage.getItem('F2'))
    )
    const [f2Checked, setF2Checked] = useLocalStorage(
        'f2-I/O',
        JSON.parse(sessionStorage.getItem('f2-I/O'))
    )
    const [f2Digi, setF2Digi] = useLocalStorage(
        'F2DIGI',
        JSON.parse(sessionStorage.getItem('F2DIGI'))
    )
    const [m1, setM1] = useLocalStorage(
        'M1',
        JSON.parse(sessionStorage.getItem('M1'))
    )
    const [m1Checked, setM1Checked] = useLocalStorage(
        'm1-I/O',
        JSON.parse(sessionStorage.getItem('m1-I/O'))
    )
    const [m1Digi, setM1Digi] = useLocalStorage(
        'M1DIGI',
        JSON.parse(sessionStorage.getItem('M1DIGI'))
    )
    const [m2, setM2] = useLocalStorage(
        'M2',
        JSON.parse(sessionStorage.getItem('M2'))
    )
    const [m2Checked, setM2Checked] = useLocalStorage(
        'm2-I/O',
        JSON.parse(sessionStorage.getItem('m2-I/O'))
    )
    const [m2Digi, setM2Digi] = useLocalStorage(
        'M2DIGI',
        JSON.parse(sessionStorage.getItem('M2DIGI'))
    )
    const [m3, setM3] = useLocalStorage(
        'M3',
        JSON.parse(sessionStorage.getItem('M3'))
    )
    const [m3Checked, setM3Checked] = useLocalStorage(
        'm3-I/O',
        JSON.parse(sessionStorage.getItem('m3-I/O'))
    )
    const [m3Digi, setM3Digi] = useLocalStorage(
        'M3DIGI',
        JSON.parse(sessionStorage.getItem('M3DIGI'))
    )
    const [m4, setM4] = useLocalStorage(
        'M4',
        JSON.parse(sessionStorage.getItem('M4'))
    )
    const [m4Checked, setM4Checked] = useLocalStorage(
        'm4-I/O',
        JSON.parse(sessionStorage.getItem('m4-I/O'))
    )
    const [m4Digi, setM4Digi] = useLocalStorage(
        'M4DIGI',
        JSON.parse(sessionStorage.getItem('M4DIGI'))
    )
    function findIndex_new(array, string) {
        var index = []
        for (var i = 0; i < array.length; i++) {
            if (
                array[i].indexOf(string) > -1 &&
                array[i].indexOf('countRGB') == -1
            ) {
                index.push(i)
            }
        }
        return index
    }
    const clearFlowChartIfPannelData = (flowData, sensor) => {
        if (
            mode === 'flowchartBased' &&
            ![undefined, null].includes(flowData)
        ) {
            Object.values(JSON.parse(flowData)).map((value) => {
                if (
                    value.data !== undefined &&
                    value.data.specificElType === 'if'
                ) {
                    var id = value.id
                    if (
                        sensor.includes(sessionStorage.getItem(`ifSelect${id}`))
                    ) {
                        sessionStorage.setItem(`ifValue${id}`, 0)
                        sessionStorage.setItem(`ifValue2${id}`, 0)
                        sessionStorage.setItem(`gt${id}`, false)
                        sessionStorage.setItem(`lt${id}`, false)
                        sessionStorage.setItem(`bw${id}`, false)
                        sessionStorage.setItem(`eq${id}`, false)
                        sessionStorage.setItem(`ne${id}`, false)
                    }
                }
            })
        }
    }
    const handleEventsClick = (e) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        switch (e.target.alt) {
            case 'mic': {
                var x = document.getElementById('snackbar1')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isMic) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['microphone']
                    )
                    setMic(false)
                } else {
                    setMic(true)
                }
                break
            }
            case 'temperature': {
                var x = document.getElementById('snackbar2')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTemperature) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['temperature']
                    )
                    setTemperature(false)
                } else {
                    setTemperature(true)
                }
                break
            }

            case 'touch0': {
                if (JSON.parse(sessionStorage.getItem('A1'))) {
                    let x = document.getElementById('snackbar3_A1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar3')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTouchZero) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchZero']
                    )
                    setTouchZero(false)
                } else {
                    setTouchZero(true)
                    setTouchZeroOutput(false)
                }
                break
            }

            case 'touch1': {
                if (JSON.parse(sessionStorage.getItem('B1'))) {
                    let x = document.getElementById('snackbar4_B1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar4')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTouchOne) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchOne']
                    )
                    setTouchOne(false)
                } else {
                    setTouchOne(true)
                    setTouchOneOutput(false)
                }
                break
            }
            case 'touch2': {
                if (JSON.parse(sessionStorage.getItem('C1'))) {
                    let x = document.getElementById('snackbar5_C1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar5')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTouchTwo) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['touchTwo']
                    )
                    setTouchTwo(false)
                } else {
                    setTouchTwo(true)
                    setTouchTwoOutput(false)
                }
                break
            }

            case 'touch0Output': {
                if (JSON.parse(sessionStorage.getItem('A1'))) {
                    let x = document.getElementById('snackbar6_A1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar6')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTouchZeroOutput) {
                    setTouchZeroOutput(false)
                } else {
                    setTouchZeroOutput(true)
                    setTouchZero(false)
                }
                let a = findIndex(Object.keys(sessionStorage), 't0')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }
            case 'touch1Output': {
                if (JSON.parse(sessionStorage.getItem('B1'))) {
                    let x = document.getElementById('snackbar7_B1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar7')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTouchOneOutput) {
                    setTouchOneOutput(false)
                } else {
                    setTouchOneOutput(true)
                    setTouchOne(false)
                }
                let a = findIndex(Object.keys(sessionStorage), 't1')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'touch2Output': {
                if (JSON.parse(sessionStorage.getItem('C1'))) {
                    let x = document.getElementById('snackbar8_C1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar8')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isTouchTwoOutput) {
                    setTouchTwoOutput(false)
                } else {
                    setTouchTwoOutput(true)
                    setTouchTwo(false)
                }
                let a = findIndex(Object.keys(sessionStorage), 't2')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'leftEye': {
                var x = document.getElementById('snackbar9')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isEyeLeft) {
                    setEyeLeft(false)
                } else {
                    setEyeLeft(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 'eyel')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'rightEye': {
                var x = document.getElementById('snackbar10')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isEyeRight) {
                    setEyeRight(false)
                } else {
                    setEyeRight(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 're')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'buzzer': {
                var x = document.getElementById('snackbar11')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isbuzzer) {
                    setbuzzer(false)
                } else {
                    setbuzzer(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 'buzz')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'smile1': {
                if (JSON.parse(sessionStorage.getItem('M1'))) {
                    let x = document.getElementById('snackbar12_M1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }
                var x = document.getElementById('snackbar12')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isSimeleOne) {
                    setSimleOne(false)
                } else {
                    setSimleOne(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 's1')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'smile2': {
                if (JSON.parse(sessionStorage.getItem('M2'))) {
                    let x = document.getElementById('snackbar13_M2')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                var x = document.getElementById('snackbar13')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isSimeleTwo) {
                    setSimleTwo(false)
                } else {
                    setSimleTwo(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 's2')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'smile3': {
                if (JSON.parse(sessionStorage.getItem('M3'))) {
                    let x = document.getElementById('snackbar14_M3')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                var x = document.getElementById('snackbar14')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isSimeleThree) {
                    setSimleThree(false)
                } else {
                    setSimleThree(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 's3')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }

            case 'smile4': {
                if (JSON.parse(sessionStorage.getItem('M4'))) {
                    let x = document.getElementById('snackbar15_M4')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                var x = document.getElementById('snackbar15')
                x.className = 'show'
                setTimeout(function () {
                    x.className = x.className.replace('show', '')
                }, 1000)
                if (isSimeleFour) {
                    setSimleFour(false)
                } else {
                    setSimleFour(true)
                }
                let a = findIndex(Object.keys(sessionStorage), 's4')
                console.log('gsk', a)
                for (let i in a) {
                    sessionStorage.setItem(Object.keys(sessionStorage)[a[i]], 0)
                }
                break
            }
        }
    }
    const handleFounInOneSensor = (e) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const DOLED = JSON.parse(sessionStorage.getItem('DOLED'))
        switch (e.target.alt) {
            case 'distancesensors': {
                if (
                    JSON.parse(sessionStorage.getItem('D1')) &&
                    !DOLED &&
                    !isDistanceSensors
                ) {
                    let x = document.getElementById('snackbar16_D1')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isDistanceSensors) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['distanceSensor']
                    )
                    setDistanceSensors(false)
                    var x = document.getElementById('snackbar16')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    setDistanceSensors(true)
                    setColorSensor(false)
                    setGestureSensor(false)
                    setLightSensor(false)
                }

                break
            }
            case 'gesturesensor': {
                if (
                    JSON.parse(sessionStorage.getItem('D1')) &&
                    !DOLED &&
                    !isGestureSensor
                ) {
                    let x = document.getElementById('snackbar17_D1')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isGestureSensor) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['gestureSensor']
                    )
                    setGestureSensor(false)
                    var x = document.getElementById('snackbar17')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    setDistanceSensors(false)
                    setColorSensor(false)
                    setGestureSensor(true)
                    setLightSensor(false)
                }
                break
            }
            case 'lightsensor': {
                if (
                    JSON.parse(sessionStorage.getItem('D1')) &&
                    !DOLED &&
                    !isLightSensor
                ) {
                    let x = document.getElementById('snackbar18_D1')

                    x.className = 'show'
                    console.log(x, 'hey')
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isLightSensor) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        ['lightSensor']
                    )
                    setLightSensor(false)
                    var x = document.getElementById('snackbar18')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    setDistanceSensors(false)
                    setColorSensor(false)
                    setGestureSensor(false)
                    setLightSensor(true)
                }
                break
            }
            case 'colorsensor': {
                if (
                    JSON.parse(sessionStorage.getItem('D1')) &&
                    !DOLED &&
                    !isColorSensor
                ) {
                    let x = document.getElementById('snackbar19_D1')

                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                    return
                }

                if (isColorSensor) {
                    clearFlowChartIfPannelData(
                        sessionStorage.getItem('flowchart-elements'),
                        [
                            'colorSensorRed',
                            'colorSensorGreen',
                            'colorSensorBlue',
                        ]
                    )
                    setColorSensor(false)
                    var x = document.getElementById('snackbar19')
                    x.className = 'show'
                    setTimeout(function () {
                        x.className = x.className.replace('show', '')
                    }, 1000)
                } else {
                    setDistanceSensors(false)
                    setColorSensor(true)
                    setGestureSensor(false)
                    setLightSensor(false)
                }
                break
            }
        }
        // setD1(false);
        // setD2(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height: '23%',
            width: ' 25%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: '5px solid rgb(255,140,25)',
            borderRadius: '20px',
            overflow: 'initial',
            // zIndex: 1,
        },
    }

    

    const backBtnAction = () => {
        let saved = JSON.parse(sessionStorage.getItem('isSave'))  

        if (saved) {
            props.history.push('/code/project')
            // window.location.reload(false)
            shouldErase('Yes')
        } else {
            setErasedProgram(true)
        }
        // setSlideDirection(true)
        // sessionStorage.setItem('slideDirection', true)
        // // localStorage.setItem('recoveryData', JSON.stringify(sessionStorage))
        // setErasedProgram(true)
    }
    const shouldErase = (info) => {
        if (info == 'Yes') {
            let programMode = sessionStorage.programMode
            let connectedDevice = sessionStorage.connectedDevice
            let version = sessionStorage.deviceVersion
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            if (
                localStorage.getItem('recoveryData') !== null &&
                programMode in recoveryData &&
                connectedDevice in recoveryData[programMode] &&
                version in recoveryData[programMode][connectedDevice]
            ) {
                if (Object.keys(recoveryData).length === 1) {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            localStorage.removeItem('recoveryData')
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                            localStorage.setItem(
                                'recoveryData',
                                JSON.stringify(recoveryData)
                            )
                        }
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                }
            }
            var arr = Object.keys(sessionStorage)
            for (const i of arr) {
                if (
                    i != 'connectedDevice' &&
                    i != 'Hardware' &&
                    i != 'userData' &&
                    i != 'concept' &&
                    i != 'webSerialPortList' &&
                    i != 'user' &&
                    i != 'deviceVersion' &&
                    i != 'programMode' &&
                    i != 'isWindowOnFocus' &&
                    i != 'slideDirection'
                ) {
                    //arr.push(i);
                    sessionStorage.removeItem(i)
                    // console.log(i, "sess");
                }
            }
            //sessionStorage.setItem("testarr", JSON.stringify(arr));

            // sessionStorage.removeItem("Index");
            // props.indexData.concept.Index = [];
            // props.history.push("/programSelection");
            // setDistanceSensors(false);
            // setGestureSensor(false);
            // setLightSensor(false);
            // setColorSensor(false);
            // setTemperature(false);
            // setMic(false);
            // setTouchZero(false);
            // setTouchOne(false);
            // setTouchTwo(false);
            // setTouchZeroOutput(false);
            // setTouchOneOutput(false);
            // setTouchTwoOutput(false);
            // setEyeLeft(false);
            // setEyeRight(false);
            // setbuzzer(false);
            // setSimleOne(false);
            // setSimleTwo(false);
            // setSimleThree(false);
            // setSimleFour(false);
            // setA1(false);
            // setA1Checked(false);
            // setA1Digi(false);
            // setA2(false);
            // setA2Checked(false);
            // setA2Digi(false);
            // setB1(false);
            // setB1Checked(false);
            // setB1Digi(false);
            // setB2(false);
            // setB2Checked(false);
            // setB2Digi(false);
            // setC1(false);
            // setC1Checked(false);
            // setC1Digi(false);
            // setC2(false);
            // setC2Checked(false);
            // setC2Digi(false);
            // setD1(false);
            // setD1Checked(false);
            // setD1Digi(false);
            // setD2(false);
            // setD2Checked(false);
            // setD2Digi(false);
            // setE1(false);
            // setE1Checked(false);
            // setE1Digi(false);
            // setE2(false);
            // setE2Checked(false);
            // setE2Digi(false);
            // setF1(false);
            // setF1Checked(false);
            // setF1Digi(false);
            // setF2(false);
            // setF2Checked(false);
            // setF2Digi(false);
            // setM1(false);
            // setM1Checked(false);
            // setM1Digi(false);
            // setM2(false);
            // setM2Checked(false);
            // setM2Digi(false);
            // setM3(false);
            // setM3Checked(false);
            // setM3Digi(false);
            // setM4(false);
            // setM4Checked(false);
            // setM4Digi(false);
            // sessionStorage.setItem("flowchart-elements", null);
            // sessionStorage.setItem("flowchart-elements-id", null);
            history.push('/flow')
            // window.location.reload()
        } else {
            setErasedProgram(false)
        }
    }

    // var dataErased = (
    //   <Modal
    //     isOpen={erasedProgram}
    //     style={customStyles}
    //     contentLabel="Example Modal"
    //   >
    //     <div className="erasedConceptMsg" style={{ zIndex: "999" }}>
    //       <p>All Unsaved program will be Erased, Continue ?</p>
    //       <button
    //         className="BtnPopup"
    //         onClick={() => shouldErase("Yes")}
    //         style={{
    //           position: "relative",
    //           top: "-22px",
    //         }}
    //       >
    //         Yes
    //       </button>
    //       <button
    //         className="BtnPopup"
    //         onClick={() => shouldErase("No")}
    //         style={{ position: "relative", top: "-22px", left: "10px" }}
    //       >
    //         No
    //       </button>
    //     </div>
    //   </Modal>
    // );

    const next = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
        history.push('/flow/selectports')
    }

    useEffect(() => {
        let assetsToBeCached = [internalBgimg, inputBgimg, outputBgimg]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <>
            <DialogModal
                show={erasedProgram}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldErase('Yes'),
                    },
                    {
                        text: 'NO',
                        do: () => shouldErase('No'),
                    },
                ]}
            />
            <div className="flowchart-InternalAccessoriesScreen_Main">
                <ProgramHeader
                    data={{
                        options: [
                            'Select Ports',
                            'Input/Output',
                            'Digital/Analog',
                            'Flowchart',
                        ],
                        selected: 0,
                    }}
                    showSave={true}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : ''
                    }`}
                >
                    {' '}
                    <div className="flowchart-Item-1">
                        <div className="flowchart-InternalAccessoriesScreen-Item1">
                            <div
                                className="flowchart-device"
                                style={{
                                    width: '25vw',
                                    height: '22vw',
                                    backgroundImage: `url(${pcImg})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '76% 96%',
                                    backgroundPosition: '50% 50%',
                                    zIndex: '110',
                                    top: '7%',
                                    position: 'relative',
                                    aspectRatio: '1 / 1',
                                }}
                            >
                                <div className="deviceContainer">
                                    {isEyeLeft ? (
                                        <img
                                            src={PcinternalEYEActive}
                                            className="imgStyleEyeL"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalEYEInActive}
                                            className="imgStyleEyeL"
                                        />
                                    )}
                                    {isEyeRight ? (
                                        <img
                                            src={PcinternalEYEActive}
                                            className="imgStyleEyeR"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalEYEInActive}
                                            className="imgStyleEyeR"
                                        />
                                    )}
                                    {isSimeleOne ? (
                                        <img
                                            src={PcinternalTeethActive}
                                            className="imgStyleTeeth1"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTeethInActive}
                                            className="imgStyleTeeth1"
                                        />
                                    )}

                                    {isSimeleTwo ? (
                                        <img
                                            src={PcinternalTeethActive}
                                            className="imgStyleTeeth2"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTeethInActive}
                                            className="imgStyleTeeth2"
                                        />
                                    )}

                                    {isSimeleThree ? (
                                        <img
                                            src={PcinternalTeethActive}
                                            className="imgStyleTeeth3"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTeethInActive}
                                            className="imgStyleTeeth3"
                                        />
                                    )}

                                    {isSimeleFour ? (
                                        <img
                                            src={PcinternalTeethActive}
                                            className="imgStyleTeeth4"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTeethInActive}
                                            className="imgStyleTeeth4"
                                        />
                                    )}

                                    {isDistanceSensors ||
                                    isColorSensor ||
                                    isGestureSensor ||
                                    isLightSensor ? (
                                        <img
                                            src={Pcinternal4in1Active}
                                            className="imgStyle4in1"
                                        />
                                    ) : (
                                        <img
                                            src={Pcinternal4in1InActive}
                                            className="imgStyle4in1"
                                        />
                                    )}

                                    {isMic ? (
                                        <img
                                            src={PcinternalMicInActive}
                                            className="imgStyleMic"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalMicActive}
                                            className="imgStyleMic"
                                        />
                                    )}
                                    {isbuzzer ? (
                                        <img
                                            src={PcinternalBuzzerActive}
                                            className="imgStyleBuzzer"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalBuzzerInActive}
                                            className="imgStyleBuzzer"
                                        />
                                    )}

                                    {isTouchZero ? (
                                        <img
                                            src={PcinternalTouchpadsActive}
                                            className="imgStyleTouchpads1"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTouchpadsInActive}
                                            className="imgStyleTouchpads1"
                                        />
                                    )}
                                    {isTouchOne ? (
                                        <img
                                            src={PcinternalTouchpadsActive}
                                            className="imgStyleTouchpads2"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTouchpadsInActive}
                                            className="imgStyleTouchpads2"
                                        />
                                    )}

                                    {isTouchTwo ? (
                                        <img
                                            src={PcinternalTouchpadsActive}
                                            className="imgStyleTouchpads3"
                                        />
                                    ) : (
                                        <img
                                            src={PcinternalTouchpadsInActive}
                                            className="imgStyleTouchpads3"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flowchart-InternalAccessoriesScreen-Item2">
                            <div
                                className="input"
                                style={{
                                    width: '31vw',
                                    height: '32vw',
                                    position: 'relative',
                                    backgroundImage: `url(${popupcardImg})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100% 90%',
                                    marginTop: '37px',
                                }}
                            >
                                <p
                                    className="flowchart-inputText"
                                    style={{ fontFamily: 'Halcyon_SemiBold' }}
                                >
                                    Inputs
                                </p>

                                <div className="inputContiner">
                                    <div className="container-row ">
                                        {isMic ? (
                                            <img
                                                src={micEnabled}
                                                className="imgStyle"
                                                alt="mic"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={mic}
                                                className="imgStyle"
                                                alt="mic"
                                                onClick={handleEventsClick}
                                            />
                                        )}{' '}
                                        {isTemperature ? (
                                            <img
                                                src={temperatureEnabled}
                                                className="imgStyle"
                                                alt="temperature"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={temperature}
                                                className="imgStyle"
                                                alt="temperature"
                                                onClick={handleEventsClick}
                                            />
                                        )}{' '}
                                    </div>
                                    <div className="container-row">
                                        {isTouchZero ? (
                                            <img
                                                src={touchzeroEnabled}
                                                className="imgStyle"
                                                alt="touch0"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={touchzero}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="touch0"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {isTouchOne ? (
                                            <img
                                                id="touch1"
                                                src={touchoneEnabled}
                                                className="imgStyle"
                                                alt="touch1"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                id="touch1"
                                                src={touchone}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="touch1"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {isTouchTwo ? (
                                            <img
                                                // id="touch2"
                                                src={touchtwoEnabled}
                                                className="imgStyle"
                                                alt="touch2"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={touchtwo}
                                                // id="touch2"
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'C1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="touch2"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                    <div className="container-row">
                                        {isDistanceSensors ? (
                                            <img
                                                src={distancesensorEnabled}
                                                className="imgStyle"
                                                alt="distancesensors"
                                                onClick={handleFounInOneSensor}
                                            />
                                        ) : (
                                            <img
                                                src={distancesensor}
                                                // className="imgStyle"
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'D1'
                                                        )
                                                    ) === true &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'DOLED'
                                                        )
                                                    ) === false
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="distancesensors"
                                                onClick={handleFounInOneSensor}
                                            />
                                        )}
                                        {isGestureSensor ? (
                                            <img
                                                src={gesturesensorEnabled}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="gesturesensor"
                                            />
                                        ) : (
                                            <img
                                                src={gesturesensor}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'D1'
                                                        )
                                                    ) == true &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'DOLED'
                                                        )
                                                    ) === false
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                onClick={handleFounInOneSensor}
                                                alt="gesturesensor"
                                            />
                                        )}

                                        {isLightSensor ? (
                                            <img
                                                src={lightsensorEnabled}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="lightsensor"
                                            />
                                        ) : (
                                            <img
                                                src={lightsensor}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'D1'
                                                        )
                                                    ) == true &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'DOLED'
                                                        )
                                                    ) === false
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                onClick={handleFounInOneSensor}
                                                alt="lightsensor"
                                            />
                                        )}

                                        {isColorSensor ? (
                                            <img
                                                src={colorsensorEnabled}
                                                className="imgStyle"
                                                onClick={handleFounInOneSensor}
                                                alt="colorsensor"
                                            />
                                        ) : (
                                            <img
                                                src={colorsensor}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'D1'
                                                        )
                                                    ) == true &&
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'DOLED'
                                                        )
                                                    ) === false
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                onClick={handleFounInOneSensor}
                                                alt="colorsensor"
                                            />
                                        )}
                                    </div>

                                    {isMic ? (
                                        <div id="snackbar1">
                                            Microphone Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar1">
                                            Microphone Disabled
                                        </div>
                                    )}
                                    {isTemperature ? (
                                        <div id="snackbar2">
                                            Temperature Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar2">
                                            Temperature Disabled
                                        </div>
                                    )}

                                    {isTouchZero ? (
                                        <div id="snackbar3">
                                            Touch pad 0 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar3">
                                            Touch pad 0 Disabled
                                        </div>
                                    )}

                                    {/* TOAST-MSG */}
                                    {isTouchZero ? null : (
                                        <div id="snackbar3_A1">
                                            {' '}
                                            Disable A1 to Enable Touch Pad 0
                                        </div>
                                    )}

                                    {isTouchOne ? (
                                        <div id="snackbar4">
                                            Touch pad 1 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar4">
                                            Touch pad 1 Disabled
                                        </div>
                                    )}
                                    {isTouchOne ? null : (
                                        <div id="snackbar4_B1">
                                            {' '}
                                            Disable B1 to Enable Touch Pad 1
                                        </div>
                                    )}

                                    {isTouchTwo ? (
                                        <div id="snackbar5">
                                            Touch pad 2 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar5">
                                            Touch pad 2 Disabled
                                        </div>
                                    )}
                                    {isTouchTwo ? null : (
                                        <div id="snackbar5_C1">
                                            {' '}
                                            Disable C1 to Enable Touch Pad 2
                                        </div>
                                    )}

                                    {isTouchZeroOutput ? (
                                        <div id="snackbar6">
                                            Touch pad 0 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar6">
                                            Touch pad 0 Disabled
                                        </div>
                                    )}
                                    {isTouchZeroOutput ? null : (
                                        <div id="snackbar6_A1">
                                            {' '}
                                            Disable A1 to Enable Touch Pad 0
                                        </div>
                                    )}
                                    {isTouchOneOutput ? (
                                        <div id="snackbar7">
                                            Touch pad 1 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar7">
                                            Touch pad 1 Disabled
                                        </div>
                                    )}
                                    {isTouchOneOutput ? null : (
                                        <div id="snackbar7_B1">
                                            {' '}
                                            Disable B1 to Enable Touch Pad 1
                                        </div>
                                    )}
                                    {isTouchTwoOutput ? (
                                        <div id="snackbar8">
                                            Touch pad 2 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar8">
                                            Touch pad 2 Disabled
                                        </div>
                                    )}
                                    {isTouchTwoOutput ? null : (
                                        <div id="snackbar8_C1">
                                            {' '}
                                            Disable C1 to Enable Touch Pad 2
                                        </div>
                                    )}

                                    {isEyeLeft ? (
                                        <div id="snackbar9">
                                            Left Eye Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar9">
                                            Left Eye Disabled
                                        </div>
                                    )}
                                    {isEyeRight ? (
                                        <div id="snackbar10">
                                            Rigth Eye Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar10">
                                            Rigth Eye Disabled
                                        </div>
                                    )}
                                    {isbuzzer ? (
                                        <div id="snackbar11">
                                            Buzzer Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar11">
                                            Buzzer Disabled
                                        </div>
                                    )}

                                    {isSimeleOne ? (
                                        <div id="snackbar12">
                                            Smile 1 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar12">
                                            Smile 1 Disabled
                                        </div>
                                    )}

                                    {isSimeleOne ? null : (
                                        <div id="snackbar12_M1">
                                            {' '}
                                            Disable L1 to Enable Smile 1
                                        </div>
                                    )}

                                    {isSimeleTwo ? (
                                        <div id="snackbar13">
                                            Smile 2 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar13">
                                            Smile 2 Disabled
                                        </div>
                                    )}
                                    {isSimeleTwo ? null : (
                                        <div id="snackbar13_M2">
                                            {' '}
                                            Disable L2 to Enable Smile 2
                                        </div>
                                    )}

                                    {isSimeleThree ? (
                                        <div id="snackbar14">
                                            Smile 3 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar14">
                                            Smile 3 Disabled
                                        </div>
                                    )}
                                    {isSimeleThree ? null : (
                                        <div id="snackbar14_M3">
                                            {' '}
                                            Disable L3 to Enable Smile 3
                                        </div>
                                    )}
                                    {isSimeleFour ? (
                                        <div id="snackbar15">
                                            Smile 4 Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar15">
                                            Smile 4 Disabled
                                        </div>
                                    )}
                                    {isSimeleFour ? null : (
                                        <div id="snackbar15_M4">
                                            {' '}
                                            Disable L4 to Enable Smile 4
                                        </div>
                                    )}
                                    {isDistanceSensors ? (
                                        <div id="snackbar16">
                                            Distance Sensor Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar16">
                                            Distance Sensor Disabled
                                        </div>
                                    )}
                                    {isDistanceSensors ? null : (
                                        <div id="snackbar16_D1">
                                            {' '}
                                            Disable D1 to Enable Distance Sensor
                                        </div>
                                    )}
                                    {isGestureSensor ? (
                                        <div id="snackbar17">
                                            Gesture Sensor Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar17">
                                            Gesture Sensor Disabled
                                        </div>
                                    )}
                                    {isGestureSensor ? null : (
                                        <div id="snackbar17_D1">
                                            {' '}
                                            Disable D1 to Enable Gesture Sensor
                                        </div>
                                    )}
                                    {isLightSensor ? (
                                        <div id="snackbar18">
                                            Light Sensor Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar18">
                                            Light Sensor Disabled
                                        </div>
                                    )}
                                    {isLightSensor ? null : (
                                        <div id="snackbar18_D1">
                                            {' '}
                                            Disable D1 to Enable Light Sensor
                                        </div>
                                    )}
                                    {isColorSensor ? (
                                        <div id="snackbar19">
                                            Color Sensor Enabled
                                        </div>
                                    ) : (
                                        <div id="snackbar19">
                                            Color Sensor Disabled
                                        </div>
                                    )}
                                    {isColorSensor ? null : (
                                        <div id="snackbar19_D1">
                                            {' '}
                                            Disable D1 to Enable Color Sensor
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="output"
                                style={{
                                    width: ' 31vw',
                                    height: ' 32vw',
                                    position: 'relative',
                                    backgroundImage: `url(${popupcardImg})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100% 90%',
                                    marginTop: '37px',
                                }}
                            >
                                <p
                                    className="flowchart-txtTitle"
                                    style={{ fontFamily: 'Halcyon_SemiBold' }}
                                >
                                    Outputs
                                </p>
                                <div className="outputContiner">
                                    <div className="container-row ">
                                        {isTouchZeroOutput ? (
                                            <img
                                                src={touchzeroEnabled}
                                                className="imgStyle"
                                                alt="touch0Output"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={touchzero}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="touch0Output"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {isTouchOneOutput ? (
                                            <img
                                                src={touchoneEnabled}
                                                className="imgStyle"
                                                alt="touch1Output"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={touchone}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="touch1Output"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {isTouchTwoOutput ? (
                                            <img
                                                src={touchtwoEnabled}
                                                className="imgStyle"
                                                alt="touch2Output"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={touchtwo}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'C1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="touch2Output"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                    <div className="container-row">
                                        {isEyeLeft ? (
                                            <img
                                                src={lefteyeEnabled}
                                                className="imgStyle"
                                                alt="leftEye"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={lefteye}
                                                className="imgStyle"
                                                alt="leftEye"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {isEyeRight ? (
                                            <img
                                                src={righteyeEnabled}
                                                className="imgStyle"
                                                alt="rightEye"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={righteye}
                                                className="imgStyle"
                                                alt="rightEye"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                        {isbuzzer ? (
                                            <img
                                                src={buzzerEnabled}
                                                className="imgStyle"
                                                alt="buzzer"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={buzzer}
                                                className="imgStyle"
                                                alt="buzzer"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                    <div className="container-row">
                                        {isSimeleOne ? (
                                            <img
                                                src={smileoneEnabled}
                                                className="imgStyle"
                                                alt="smile1"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={smileone}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'M1'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="smile1"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {isSimeleTwo ? (
                                            <img
                                                src={smiletwoEnabled}
                                                className="imgStyle"
                                                alt="smile2"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={smiletwo}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'M2'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="smile2"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {isSimeleThree ? (
                                            <img
                                                src={smilethreeEnabled}
                                                className="imgStyle"
                                                alt="smile3"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={smilethree}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'M3'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="smile3"
                                                onClick={handleEventsClick}
                                            />
                                        )}

                                        {isSimeleFour ? (
                                            <img
                                                src={smilefourEnabled}
                                                className="imgStyle"
                                                alt="smile4"
                                                onClick={handleEventsClick}
                                            />
                                        ) : (
                                            <img
                                                src={smilefour}
                                                className={`imgStyle ${
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'M4'
                                                        )
                                                    ) == true
                                                        ? 'isDisableBtn'
                                                        : ''
                                                }`}
                                                alt="smile4"
                                                onClick={handleEventsClick}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {dataErased} */}

                <div className="SelectScreenBottom">
                    <div className="bottom-child">
                        {/* <Link to="/programSelection"> */}
                        <img
                            className="iconBtnSize imgBackBtn"
                            src={renderPrgImage('backBtn')}
                            onClick={backBtnAction}
                        />
                        {/* </Link> */}
                        <div
                            className="decription"
                            style={{
                                fontFamily: 'Halcyon_SemiBold',
                                zIndex: '-10',
                            }}
                        >
                            <p>
                                Select the internal accessories of Play Computer
                            </p>
                        </div>

                        <img
                            className="iconBtnSize imgNextBtn"
                            src={renderPrgImage('nextBtn')}
                            onClick={next}
                        />
                    </div>
                </div>
                {/* <Bottom
        to="/flow/selectports"
        prev="/visualProgram"
        description="Tap on the icon to select the in built Play Computer feature"
      /> */}
                {ishelpButton == true ? (
                    <div
                        style={{
                            height: '100vh',
                            width: '100vw',
                            background: '#fff',
                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            zIndex: '1000',
                            position: 'absolute',
                        }}
                    >
                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '15%',
                                right: '18%',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                        <Slider
                            {...settings}
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '46%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                            }}
                        >
                            {/* first  */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={internalBgimg}
                                    style={{ width: '100%' }}
                                ></img>

                                <div
                                    style={{
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '24%',
                                        left: '25.3%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        transform: 'translate(-50%,-50%)',
                                        height: '6%',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '3px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        Your saved project will show here with
                                        an image and name, to open it, click to
                                        select
                                    </p>
                                </div>
                                <div
                                    style={{
                                        // height: "90px",
                                        width: '2%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '56%',
                                        left: '26.5%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        transform: 'translate(-50%,-50%)',
                                        height: '6%',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        Bluetooth connection status;
                                        red:disconnected, green:connected
                                    </p>
                                </div>
                                <div
                                    style={{
                                        height: '6%',
                                        width: '2%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '77%',
                                        left: '26.8%',
                                        transform: 'translate(-50%,-50%)',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        To go to external accessories screen
                                    </p>
                                </div>
                                <div
                                    style={{
                                        height: '6%',
                                        width: '2%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '77%',
                                        left: '23.3%',
                                        transform: 'translate(-50%,-50%)',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',

                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        To go the new project / your project
                                        screen
                                    </p>
                                </div>
                            </div>
                            {/* first end */}

                            {/* second start */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={robotBgimg}
                                    style={{ width: '100%' }}
                                />
                                <div
                                    style={{
                                        height: '6%',
                                        width: '2%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '44%',
                                        left: '34.5%',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        background: '#fff',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        All internal accessories input or output
                                        that are enabled are highlighted on the
                                        playcomputer at the respective position
                                    </p>
                                </div>
                            </div>
                            {/* second end */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={inputBgimg}
                                    style={{ width: '100%' }}
                                />
                                <div
                                    style={{
                                        height: '6%',
                                        width: '2%',
                                        position: 'absolute',
                                        top: '45%',
                                        left: '42.2%',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        // transform: "translate(-50%,-50%)",

                                        background: '#fff',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        The list of all the internal
                                        accessories-input type, that can be
                                        enabled or Disabled.
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={outputBgimg}
                                    style={{ width: '100%' }}
                                />
                                <div
                                    style={{
                                        height: '6%',
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '47.5%',
                                        left: '53%',
                                        fontSize: '22px',
                                        borderRadius: '35px',
                                        // transform: "translate(-50%,-50%)",

                                        background: '#fff',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        The list of all the internal
                                        accessories-output type, that can be
                                        enabled or Disabled.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                ) : null}
            </div>
        </>
    )
}

// export default InternalAccessories;

const mapStateToProps = (state) => {
    console.log('mapStateToProps', state)

    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialConnect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalAccessories)
