import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import renderImage from '../../../source/importImg'
import style from './ViewActions.module.css'
import unicodeToChar from '../../../utils/unicodeToChar'
import humanoid from '../../../Assets/images/ZingImage.png'
import { readBytes, sendBytes } from '../../ReusableComponents/ByteTransfer'
import {
    MSBLSBToDecimal,
    actionConverter,
} from '../../ReusableComponents/ByteTransfer/utils'
import ActionLoader from './ActionLoader'
import { Loader } from 'three'

const INITIAL_FEEDBACK = {
    isOn: false,
    msg: '',
}

const trashIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        className="bi bi-trash"
        viewBox="0 0 16 16"
    >
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path
            fillRule="evenodd"
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
    </svg>
)
const ViewActions = (props) => {
    const history = useHistory()
    const [feedback, setFeedback] = useState(INITIAL_FEEDBACK)
    const [isHovered, setIsHovered] = useState(null)
    const [savedActions, setSavedActions] = useState([])
    const [saveActionList, setSaveActionList] = useState([])
    const [deleteFname, seteDeleteFname] = useState('')
    const [frLoader, setFrLoader] = useState(false)
    const [loader, setLoader] = useState(false)
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!JSON.parse(sessionStorage.getItem('sendDataAsPacket'))) {
                    return resolve()
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    useEffect(async () => {
        if (props.viewPopUp && props.webSerial.isConnected) {
            if (['1', '2'].includes(deviceVersion[0])) {
                setFrLoader(true)
                props.worker.postMessage({
                    type: 'fetchFileWriteArray',
                    value: ['F'.charCodeAt(0), 'R'.charCodeAt(0)],
                })
                sessionStorage.setItem('sendDataAsPacket', 'true')
                try {
                    let replyOK = await Promise.race([
                        checkOk(),
                        timeoutPromise(10000), //
                    ])
                } catch (e) {
                    setFrLoader(false)
                }
            } else {
                fetchActionSlotData()
            }
        }
    }, [props.viewPopUp])

    useEffect(() => {
        const id = setTimeout(() => {
            setFeedback({ isOn: false })
        }, 3000)
        return () => clearTimeout(id)
    }, [feedback])

    const handleMouseEnter = (id) => {
        setIsHovered(id)
    }

    const handleMouseLeave = () => {
        setIsHovered(null)
    }

    const fetchActionSlotData = async () => {
        if (props.webSerial.port) {
            await sendBytes(
                ['F'.charCodeAt(0), 'R'.charCodeAt(0)],
                props.webSerial.port
            )
            let reader = props.webSerial.port.readable.getReader()
            let actionsList = ''
            while (true) {
                const { value } = await Promise.race([
                    reader.read(),
                    new Promise((_, reject) =>
                        setTimeout(reject, 2000, new Error('timeout'))
                    ),
                ])
                const result = unicodeToChar(value).trim()
                actionsList += result
                if (actionsList.slice(-3) === 'END') {
                    actionsList = actionsList.slice(0, -3)
                    reader.releaseLock()
                    break
                }
            }
            actionsList = actionsList
                .replace(/.BIN/g, ',')
                .split(',')
                .map((item) => item.trim())
            setSavedActions(actionsList.slice(0, -1))
        }
    }

    const deleteAction = async (name) => {
        let delAc = ['D'.charCodeAt(0), 'F'.charCodeAt(0), name.length]
        let fNameAsci = `${name}`.split('').map((item) => item.charCodeAt(0))

        seteDeleteFname(name)
        delAc = [...delAc, ...fNameAsci]
        if (['1', '2'].includes(deviceVersion[0])) {
            setLoader(true)
            props.worker.postMessage({
                type: 'writeArray',
                value: delAc,
            })
            sessionStorage.setItem('sendDataAsPacket', 'true')
            try {
                let replyOK = await Promise.race([
                    checkOk(),
                    timeoutPromise(1000), //
                ])
            } catch (e) {
                setLoader(false)
                setFeedback({
                    isOn: true,
                    msg: `'Action group not deleted try again .....'`,
                })
            }
        } else {
            await sendBytes(delAc, props.webSerial.port)
            if (props.webSerial.port) {
                if (!props.webSerial.port.readable.locked) {
                    let reader = props.webSerial.port.readable.getReader()
                    const { value } = await Promise.race([
                        reader.read(),
                        new Promise((_, reject) =>
                            setTimeout(reject, 500, new Error('timeout'))
                        ),
                    ])
                    const result = unicodeToChar(value).trim()
                    if (result.includes('OK')) {
                        await sendBytes(
                            ['D'.charCodeAt(0), 'F'.charCodeAt(0)],
                            props.webSerial.port
                        )
                        const { value } = await Promise.race([
                            reader.read(),
                            new Promise((_, reject) =>
                                setTimeout(reject, 500, new Error('timeout'))
                            ),
                        ])
                        const result = unicodeToChar(value).trim()
                        console.log(result)
                        reader.releaseLock()
                        if (result === 'DEL') {
                            setFeedback({
                                isOn: true,
                                msg: `Action deleted successfully`,
                            })
                            setTimeout(() => {
                                fetchActionSlotData()
                            }, 100)
                        } else {
                            setFeedback({
                                isOn: true,
                                msg: 'Action group not deleted try again .....',
                            })
                        }
                    }
                }
            }
        }
    }

    // open the action from zing board
    const openAction = async (name) => {
        console.log(name)
        name = name.trim()
        if (props.webSerial.isConnected) {
            let fOpenBytes = ['F'.charCodeAt(0), 'A'.charCodeAt(0), name.length]
            let fNameAsci = `${name}`
                .split('')
                .map((item) => item.charCodeAt(0))

            fOpenBytes = [...fOpenBytes, ...fNameAsci]
            if (['1', '2'].includes(deviceVersion[0])) {
                setLoader(true)
                props.worker.postMessage({
                    type: 'fetchFileWriteArray', // writeArray
                    value: fOpenBytes,
                })
                sessionStorage.setItem('sendDataAsPacket', 'true')
                try {
                    let replyOK = await Promise.race([
                        checkOk(),
                        timeoutPromise(10000), //
                    ])
                } catch (e) {
                    setLoader(false)
                    setFeedback({
                        isOn: true,
                        msg: `'Action group can not open try again .....'`,
                    })
                }
            } else {
                // fetchActionSlotData()
            }
        }
    }
    //set the n

    //read data from zing S3 board
    useEffect(() => {
        let fsLength = 0

        const messageHandler = (e) => {
            let val = e.data.value
            if (e.data.type === 'read') {
                //check "DEL" came back from board
                if (
                    e.data.value.includes('DFOK')
                    // !e.data.value.includes('.BIN')
                    // fsLength == 0
                ) {
                    sessionStorage.setItem('sendDataAsPacket', 'false')
                    let newSavedActions = [...savedActions]
                    let indexDeleteFile = newSavedActions.indexOf(deleteFname)
                    if (indexDeleteFile !== -1) {
                        newSavedActions.splice(indexDeleteFile, 1)
                        setSavedActions(newSavedActions)
                    }
                    setLoader(false)
                    setFeedback({
                        isOn: true,
                        msg: `Action  deleted successfully`,
                    })
                    //check "ERR" came back from board if file not deleted
                } else if (
                    e.data.value.includes('ERR') &&
                    !e.data.value.includes('FAERR')
                    //!e.data.value.includes('.BIN') &&
                    // fsLength == 0 &&
                ) {
                    sessionStorage.setItem('sendDataAsPacket', 'false')
                    setLoader(false)
                    setFeedback({
                        isOn: true,
                        msg: `'Action group not deleted try again .....'`,
                    })

                    //check "ERR" came back from board if file datas not fetch
                } else if (
                    e.data.value.includes('FAERR')
                    // !e.data.value.includes('.BIN')
                    // fsLength == 0
                ) {
                    sessionStorage.setItem('sendDataAsPacket', 'false')
                    setLoader(false)
                    setFeedback({
                        isOn: true,
                        msg: `'Action group can not open try again .....'`,
                    })
                }
            }

            //here we take arrayData, and stringData both together from serial worker
            if (e.data.type === 'actionRead') {
                let { arrayData, stringData } = e.data.value

                //extract the data after "FR" and before "END"
                let regexEqu = /13,10,70,82,([^]*?),10,69,78,68/
                let match = arrayData.join(',').match(regexEqu)
                if (match) {
                    const matchArr = match[1].split(',')
                    fsLength = matchArr[0]
                    const extractStringData = unicodeToChar(matchArr)
                    let fileNameArr = extractStringData.split(/[\n]+/)
                    //in the fileNameArr remove the length of actions
                    fileNameArr.shift()
                    setSavedActions(fileNameArr)
                }

                //extract the data after "FA" and before "END"
                let regexEquFA = /13,10,70,65,([^]*?),10,69,78,68/
                let matchFA = arrayData.join(',').match(regexEquFA)
                //set the data of each file fetch from board   stringData.includes('FA') && !stringData.includes('FAER')
                if (matchFA) {
                    sessionStorage.setItem('sendDataAsPacket', 'false')
                    setLoader(false)
                    const matchArr = matchFA[1].split(',')
                    let createActionState = actionConverter(matchArr)
                    if (props.setSelectedAction) {
                        props.setSelectedAction(0)
                        props.setCreateActionState(createActionState)
                    } else {
                        sessionStorage.setItem(
                            'createActionState',
                            JSON.stringify(createActionState)
                        )
                        history.push('/code/project/action/select')
                    }
                    props.setViewPopUp(false)
                }
            }
        }
        props.worker.addEventListener('message', messageHandler)

        return () => {
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [deleteFname])

    return (
        <>
            {props.viewPopUp && (
                <>
                    <div className={style.container}>
                        <img
                            className={style.closeBtn}
                            src={renderImage('clos')}
                            onClick={() => {
                                props.setViewPopUp(false)
                                setSavedActions([])
                            }}
                        />
                        <div
                            className={style.box}
                            style={{
                                justifyContent:
                                    savedActions.length === 0 ? 'center' : null,
                                alignItems:
                                    savedActions.length === 0 ? 'center' : null,
                            }}
                        >
                            {loader && <ActionLoader />}{' '}
                            {savedActions.length !== 0 ? (
                                savedActions.map((value, index) => {
                                    return (
                                        <div
                                            className={style.containerImage}
                                            key={index}
                                        >
                                            <img
                                                className={style.circularImage}
                                                src={humanoid}
                                                onClick={() =>
                                                    openAction(value)
                                                }
                                            />
                                            <div
                                                className={style.textContainer}
                                            >
                                                <div
                                                    className={
                                                        style.centeredText
                                                    }
                                                    onMouseEnter={() =>
                                                        handleMouseEnter(index)
                                                    }
                                                    onMouseLeave={
                                                        handleMouseLeave
                                                    }
                                                    onClick={() =>
                                                        openAction(value)
                                                    }
                                                >
                                                    {value}
                                                    {isHovered === index ? (
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                deleteAction(
                                                                    value
                                                                )
                                                            }}
                                                        >
                                                            {trashIcon}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <>
                                    {!props.webSerial.isConnected ? (
                                        <p>Please connect the Device</p>
                                    ) : frLoader ? (
                                        <div className={style.loader}>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    ) : (
                                        <p>
                                            We can not fetch files, please try
                                            again
                                        </p>
                                    )}
                                </>
                            )}
                        </div>
                        {feedback.isOn ? (
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '8vh',
                                    zIndex: 95001,
                                }}
                            >
                                {feedback.msg}
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
        </>
    )
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(ViewActions)
