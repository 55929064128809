var webSerialInitialState = {
    port: undefined,
    isConnected: false,
}

// webSerial.port - Instance of port object
// webSerial.isConnected - true when port is connected and false when not connected

const webSerialPortList_Reducer = (state = webSerialInitialState, action) => {
    switch (action.type) {
        case 'WEB_SERIAL_PORT_CONNECT': {
            if(!sessionStorage.getItem('isDeviceConnected')) return webSerialInitialState
            const data = {
                ...state,
                port: action.payload.port,
                isConnected: true,
            }
            sessionStorage.setItem('webSerialPortList', JSON.stringify(data))
            return data
        }

        case 'WEB_SERIAL_PORT_DISCONNECT': {
            return webSerialInitialState
        }

        default:
            return state
    }
}

export default webSerialPortList_Reducer
