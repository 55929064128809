const RightToggleSection = ({
    bttnColor,
    bttnColor2,
    C1DIGI,
    C2DIGI,
    D1DIGI,
    D2DIGI,
    F1DIGI,
    F2DIGI,
    M3DIGI,
    M4DIGI,
    c1Digi,
    c2Digi,
    d1Digi,
    d2Digi,
    f1Digi,
    f2Digi,
    m3Digi,
    m4Digi,
    c1Servo,
    c2Servo,
    d1Servo,
    d2Servo,
    f1Servo,
    f2Servo,
    c1Servo360,
    c2Servo360,
    d1Servo360,
    d2Servo360,
    f1Servo360,
    f2Servo360,
    c1Servo270,
    c2Servo270,
    d1Servo270,
    d2Servo270,
    f1Servo270,
    f2Servo270,
    Mp3M3,
    m3Mp3,
    Servo360C1,
    Servo360C2,
    Servo360D1,
    Servo360D2,
    Servo360F1,
    Servo360F2,
    Servo270C1,
    Servo270C2,
    Servo270D1,
    Servo270D2,
    Servo270F1,
    Servo270F2,
    ServoC1,
    ServoC2,
    ServoD1,
    ServoD2,
    ServoF1,
    ServoF2,
    toggleC1,
    toggleC2,
    toggleD1,
    toggleD2,
    toggleF1,
    toggleF2,
    toggleM3,
    toggleM4,
    isSmileFour,
    isSmileThree,
    isPCv1,
}) => {
    return (
        <>
            <div className="digital-ButtonRightDivInput">
                {/* PORT C */}
                <div
                    className="digital-flow-left-upper"
                    style={{ height: '235pt', backgroundSize: '260pt 300pt' }}
                >
                    <div className="digital-flow-left-upper-grp">
                        <label className={'input upper-label-input'}>
                            <span
                                className={(C1DIGI || false) + '-span textsp'}
                            >
                                C1
                            </span>
                            <div
                                className={
                                    'switch-button-' +
                                    ((C1DIGI &&
                                        !c1Servo &&
                                        !c1Servo360 &&
                                        !c1Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id={'s5'}
                                style={{ color: bttnColor[4] }}
                            >
                                <input
                                    active={c1Digi}
                                    // disabled={
                                    //   !C1DIGI ||
                                    //   c1Servo ||
                                    //   JSON.parse(sessionStorage.getItem("CUltra")) ||
                                    //   false
                                    // }
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    onChange={toggleC1}
                                    checked={c1Digi}
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in5"
                                        style={{
                                            color: bttnColor2[4],
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-button-servo-' +
                                    ((c1Servo &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-button-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('C1')
                                        ) ||
                                        !JSON.parse(
                                            sessionStorage.getItem('c1-I/O')
                                        ) ||
                                        JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )
                                    }
                                    checked={c1Servo}
                                    onChange={ServoC1}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 270 */}
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-buttons-servo270-' +
                                    ((c1Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('C1')
                                        ) ||
                                        !JSON.parse(
                                            sessionStorage.getItem('c1-I/O')
                                        ) ||
                                        JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )
                                    }
                                    checked={c1Servo270}
                                    onChange={Servo270C1}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 360 */}
                        <label
                            className={'upper-label-input-servo360'}
                            style={{ display: 'Block' }}
                        >
                            <div
                                className={
                                    'switch-buttons-servo-' +
                                    ((c1Servo360 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('C1')
                                        ) ||
                                        !JSON.parse(
                                            sessionStorage.getItem('c1-I/O')
                                        ) ||
                                        JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )
                                    }
                                    checked={c1Servo360}
                                    onChange={Servo360C1}
                                ></input>
                            </div>
                        </label>
                        <br />
                        <label className={'input upper-label-input'}>
                            <span
                                className={(C2DIGI || false) + '-span textsp'}
                            >
                                C2
                            </span>
                            <div
                                className={
                                    'switch-button-' +
                                    ((C2DIGI &&
                                        !c2Servo &&
                                        !c2Servo270 &&
                                        !c2Servo360 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id={'s6'}
                                style={{ color: bttnColor[5] }}
                            >
                                <input
                                    active={c2Digi}
                                    // disabled={
                                    //   !C2DIGI ||
                                    //   c2Servo ||
                                    //   JSON.parse(sessionStorage.getItem("CUltra")) ||
                                    //   false
                                    // }
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    onChange={toggleC2}
                                    checked={c2Digi}
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in6"
                                        style={{
                                            color: bttnColor2[5],
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-button-servo-' +
                                    ((c2Servo &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-button-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('C2')
                                        ) ||
                                        !JSON.parse(
                                            sessionStorage.getItem('c2-I/O')
                                        ) ||
                                        JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )
                                    }
                                    checked={c2Servo}
                                    onChange={ServoC2}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 270 */}
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-buttons-servo270-' +
                                    ((c2Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('C2')
                                        ) ||
                                        !JSON.parse(
                                            sessionStorage.getItem('c2-I/O')
                                        ) ||
                                        JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )
                                    }
                                    checked={c2Servo270}
                                    onChange={Servo270C2}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 360 */}
                        <label
                            className={'upper-label-input-servo360'}
                            style={{ display: 'Block' }}
                        >
                            <div
                                className={
                                    'switch-buttons-servo-' +
                                    ((c2Servo360 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('C2')
                                        ) ||
                                        !JSON.parse(
                                            sessionStorage.getItem('c2-I/O')
                                        ) ||
                                        JSON.parse(
                                            sessionStorage.getItem('CUltra')
                                        )
                                    }
                                    checked={c2Servo360}
                                    onChange={Servo360C2}
                                ></input>
                            </div>
                        </label>
                    </div>
                </div>
                {/* PORT D */}
                <div
                    className="digital-flow-left-upper"
                    style={{ height: '235pt', backgroundSize: '260pt 300pt' }}
                >
                    <div className="digital-flow-left-upper-grp">
                        {/* Port D1 */}
                        <label className={'input upper-label-input'}>
                            <span
                                className={(D1DIGI || false) + '-span textsp'}
                            >
                                D1
                            </span>
                            <div
                                className={
                                    'switch-button-' +
                                    ((D1DIGI &&
                                        !d1Servo &&
                                        !d1Servo360 &&
                                        !d1Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        ) &&
                                        !(
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isColorSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isGestureSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isDistanceSensors'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isLightSensor'
                                                )
                                            )
                                        )) ||
                                        false)
                                }
                                id="s7"
                                style={{ color: bttnColor[6] }}
                            >
                                <input
                                    onChange={toggleD1}
                                    active={d1Digi}
                                    // disabled={
                                    //   !JSON.parse(sessionStorage.getItem("D1")) ||
                                    //   !JSON.parse(sessionStorage.getItem("d1-I/O")) ||
                                    //   JSON.parse(sessionStorage.getItem("DOLED"))
                                    // }
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    checked={d1Digi}
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in7"
                                        style={{
                                            color: bttnColor2[6],
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-button-servo-' +
                                    ((d1Servo &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        ) &&
                                        !(
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isColorSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isGestureSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isDistanceSensors'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isLightSensor'
                                                )
                                            )
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-button-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        isPCv1
                                            ? !JSON.parse(
                                                  sessionStorage.getItem('D1')
                                              ) ||
                                              !JSON.parse(
                                                  sessionStorage.getItem(
                                                      'd1-I/O'
                                                  )
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                            : !JSON.parse(
                                                  sessionStorage.getItem('D1')
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                    }
                                    checked={d1Servo}
                                    onChange={ServoD1}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 270 */}
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-buttons-servo270-' +
                                    ((d1Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        ) &&
                                        !(
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isColorSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isGestureSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isDistanceSensors'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isLightSensor'
                                                )
                                            )
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        isPCv1
                                            ? !JSON.parse(
                                                  sessionStorage.getItem('D1')
                                              ) ||
                                              !JSON.parse(
                                                  sessionStorage.getItem(
                                                      'd1-I/O'
                                                  )
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                            : !JSON.parse(
                                                  sessionStorage.getItem('D1')
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                    }
                                    checked={d1Servo270}
                                    onChange={Servo270D1}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 360 */}
                        <label
                            className={'upper-label-input-servo360'}
                            style={{ display: 'Block' }}
                        >
                            <div
                                className={
                                    'switch-buttons-servo-' +
                                    ((d1Servo360 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        ) &&
                                        !(
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isColorSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isGestureSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isDistanceSensors'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isLightSensor'
                                                )
                                            )
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        isPCv1
                                            ? !JSON.parse(
                                                  sessionStorage.getItem('D1')
                                              ) ||
                                              !JSON.parse(
                                                  sessionStorage.getItem(
                                                      'd1-I/O'
                                                  )
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                            : !JSON.parse(
                                                  sessionStorage.getItem('D1')
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                    }
                                    checked={d1Servo360}
                                    onChange={Servo360D1}
                                ></input>
                            </div>
                        </label>
                        <br />
                        {/* PORT D2 */}
                        <label className={'input upper-label-input'}>
                            <span
                                className={(D2DIGI || false) + '-span textsp'}
                            >
                                D2
                            </span>
                            <div
                                className={
                                    'switch-button-' +
                                    ((D2DIGI &&
                                        !d2Servo &&
                                        !d2Servo360 &&
                                        !d2Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        ) &&
                                        !(
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isColorSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isGestureSensor'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isDistanceSensors'
                                                )
                                            ) ||
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    'isLightSensor'
                                                )
                                            )
                                        )) ||
                                        false)
                                }
                                id="s8"
                                style={{ color: bttnColor[7] }}
                            >
                                <input
                                    onClick={toggleD2}
                                    active={d2Digi}
                                    // disabled={
                                    //     !JSON.parse(
                                    //         sessionStorage.getItem('D2')
                                    //     ) ||
                                    //     !JSON.parse(
                                    //         sessionStorage.getItem(
                                    //             'd2-I/O'
                                    //         )
                                    //     ) ||
                                    //     JSON.parse(
                                    //         sessionStorage.getItem(
                                    //             'DOLED'
                                    //         )
                                    //     )
                                    // }
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    checked={d2Digi}
                                ></input>
                                <label className="switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in8"
                                        style={{
                                            color: bttnColor2[7],
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-button-servo-' +
                                    ((d2Servo &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-button-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        isPCv1
                                            ? !JSON.parse(
                                                  sessionStorage.getItem('D2')
                                              ) ||
                                              !JSON.parse(
                                                  sessionStorage.getItem(
                                                      'd2-I/O'
                                                  )
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                            : !JSON.parse(
                                                  sessionStorage.getItem('D2')
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                    }
                                    checked={d2Servo}
                                    onChange={ServoD2}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 270 */}
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-buttons-servo270-' +
                                    ((d2Servo270 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        isPCv1
                                            ? !JSON.parse(
                                                  sessionStorage.getItem('D2')
                                              ) ||
                                              !JSON.parse(
                                                  sessionStorage.getItem(
                                                      'd2-I/O'
                                                  )
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                            : !JSON.parse(
                                                  sessionStorage.getItem('D2')
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                    }
                                    checked={d2Servo270}
                                    onChange={Servo270D2}
                                ></input>
                            </div>
                        </label>
                        {/* Servo 360 */}
                        <label
                            className={'upper-label-input-servo360'}
                            style={{ display: 'Block' }}
                        >
                            <div
                                className={
                                    'switch-buttons-servo-' +
                                    ((d2Servo360 &&
                                        !JSON.parse(
                                            sessionStorage.getItem('DOLED')
                                        )) ||
                                        false)
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-buttons-checkbox-servo"
                                    type="checkbox"
                                    disabled={
                                        isPCv1
                                            ? !JSON.parse(
                                                  sessionStorage.getItem('D2')
                                              ) ||
                                              !JSON.parse(
                                                  sessionStorage.getItem(
                                                      'd2-I/O'
                                                  )
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                            : !JSON.parse(
                                                  sessionStorage.getItem('D2')
                                              ) ||
                                              JSON.parse(
                                                  sessionStorage.getItem(
                                                      'DOLED'
                                                  )
                                              )
                                    }
                                    checked={d2Servo360}
                                    onChange={Servo360D2}
                                ></input>
                            </div>
                        </label>
                    </div>
                </div>
                {/* PORT F */}
                {isPCv1 ? (
                    <>
                        <div
                            className="digital-flow-left-upper"
                            style={{
                                height: '235pt',
                                backgroundSize: '260pt 300pt',
                            }}
                        >
                            <div className="digital-flow-left-upper-grp">
                                <label className={'input upper-label-input'}>
                                    <span
                                        className={
                                            (F1DIGI || false) + '-span textsp'
                                        }
                                    >
                                        F1
                                    </span>
                                    <div
                                        className={
                                            'switch-button-' +
                                            ((F1DIGI &&
                                                !f1Servo &&
                                                !f1Servo270 &&
                                                !f1Servo360) ||
                                                false)
                                        }
                                        id={'s9'}
                                        style={{ color: '#717171' }}
                                    >
                                        <input
                                            active={f1Digi}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                            onChange={toggleF1}
                                            checked={f1Digi}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in9"
                                                style={{
                                                    color: '#fcfcfc',
                                                }}
                                            >
                                                Digital
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        'input upper-label-input upper-label-input-servo'
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-servo-' + f1Servo
                                        }
                                        id="s9"
                                    >
                                        <input
                                            className="switch-button-checkbox-servo"
                                            type="checkbox"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('F1')
                                                ) ||
                                                !JSON.parse(
                                                    sessionStorage.getItem(
                                                        'f1-I/O'
                                                    )
                                                )
                                            }
                                            checked={f1Servo}
                                            onChange={ServoF1}
                                        ></input>
                                    </div>
                                </label>
                                {/* Servo 270 */}
                                <label
                                    className={
                                        'input upper-label-input upper-label-input-servo'
                                    }
                                >
                                    <div
                                        className={
                                            'switch-buttons-servo270-' +
                                            (f1Servo270 || false)
                                        }
                                        id="s9"
                                    >
                                        <input
                                            className="switch-buttons-checkbox-servo"
                                            type="checkbox"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('F1')
                                                ) ||
                                                !JSON.parse(
                                                    sessionStorage.getItem(
                                                        'f1-I/O'
                                                    )
                                                )
                                            }
                                            checked={f1Servo270}
                                            onChange={Servo270F1}
                                        ></input>
                                    </div>
                                </label>
                                {/* Servo 360 */}
                                <label
                                    className={'upper-label-input-servo360'}
                                    style={{ display: 'Block' }}
                                >
                                    <div
                                        className={
                                            'switch-buttons-servo-' +
                                            (f1Servo360 || false)
                                        }
                                        id="s9"
                                    >
                                        <input
                                            className="switch-buttons-checkbox-servo"
                                            type="checkbox"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('F1')
                                                ) ||
                                                !JSON.parse(
                                                    sessionStorage.getItem(
                                                        'f1-I/O'
                                                    )
                                                )
                                            }
                                            checked={f1Servo360}
                                            onChange={Servo360F1}
                                        ></input>
                                    </div>
                                </label>
                                <br />
                                <label className={'input upper-label-input'}>
                                    <span
                                        className={
                                            (F2DIGI || false) + '-span textsp'
                                        }
                                    >
                                        F2
                                    </span>
                                    <div
                                        className={
                                            'switch-button-' +
                                            ((F2DIGI &&
                                                !f2Servo &&
                                                !f2Servo270 &&
                                                !f2Servo360) ||
                                                false)
                                        }
                                        id={'s10'}
                                        style={{ color: '#717171' }}
                                    >
                                        <input
                                            active={f2Digi}
                                            className="switch-button-checkbox"
                                            type="checkbox"
                                            onChange={toggleF2}
                                            checked={f2Digi}
                                        ></input>
                                        <label
                                            className="switch-button-label"
                                            for=""
                                        >
                                            <span
                                                className="switch-button-label-span"
                                                id="in10"
                                                style={{
                                                    color: '#fcfcfc',
                                                }}
                                            >
                                                Digital
                                            </span>
                                        </label>
                                    </div>
                                </label>
                                <br />
                                <label
                                    className={
                                        'input upper-label-input upper-label-input-servo'
                                    }
                                >
                                    <div
                                        className={
                                            'switch-button-servo-' +
                                            (f2Servo || false)
                                        }
                                        id="s10"
                                    >
                                        <input
                                            className="switch-button-checkbox-servo"
                                            type="checkbox"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('F2')
                                                ) ||
                                                !JSON.parse(
                                                    sessionStorage.getItem(
                                                        'f2-I/O'
                                                    )
                                                )
                                            }
                                            checked={f2Servo}
                                            onChange={ServoF2}
                                        ></input>
                                    </div>
                                </label>
                                {/* Servo 270 */}
                                <label
                                    className={
                                        'input upper-label-input upper-label-input-servo'
                                    }
                                >
                                    <div
                                        className={
                                            'switch-buttons-servo270-' +
                                            (f2Servo270 || false)
                                        }
                                        id="s10"
                                    >
                                        <input
                                            className="switch-buttons-checkbox-servo"
                                            type="checkbox"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('F2')
                                                ) ||
                                                !JSON.parse(
                                                    sessionStorage.getItem(
                                                        'f2-I/O'
                                                    )
                                                )
                                            }
                                            checked={f2Servo270}
                                            onChange={Servo270F2}
                                        ></input>
                                    </div>
                                </label>
                                {/* Servo 360 */}
                                <label
                                    className={'upper-label-input-servo360'}
                                    style={{ display: 'Block' }}
                                >
                                    <div
                                        className={
                                            'switch-buttons-servo-' +
                                            (f2Servo360 || false)
                                        }
                                        id="s10"
                                    >
                                        <input
                                            className="switch-buttons-checkbox-servo"
                                            type="checkbox"
                                            disabled={
                                                !JSON.parse(
                                                    sessionStorage.getItem('F2')
                                                ) ||
                                                !JSON.parse(
                                                    sessionStorage.getItem(
                                                        'f2-I/O'
                                                    )
                                                )
                                            }
                                            checked={f2Servo360}
                                            onChange={Servo360F2}
                                        ></input>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div
                            className="Digital-flow-left-upper"
                            style={{ visibility: 'hidden' }}
                        ></div>
                    </>
                ) : (
                    <div className="Digital-flow-left-upper">
                        <div className="Digital-flow-left-upper-grp">
                            <label className={'input upper-label-input'}>
                                <span
                                    className={
                                        (F1DIGI || false) + '-span textsp'
                                    }
                                >
                                    F1
                                </span>
                                <div
                                    className={
                                        'switch-button-' + (F1DIGI || false)
                                    }
                                    id="s9"
                                    style={{ color: '#717171' }}
                                >
                                    <input
                                        active={f1Digi}
                                        // disabled={!F1DIGI || false}
                                        className="switch-button-checkbox"
                                        type="checkbox"
                                        onChange={toggleF1}
                                        checked={f1Digi}
                                    ></input>
                                    <label
                                        className=" switch-button-label"
                                        for=""
                                    >
                                        <span
                                            className="switch-button-label-span"
                                            id="in9"
                                            style={{
                                                color: '#fcfcfc',
                                            }}
                                        >
                                            Digital
                                        </span>
                                    </label>
                                </div>
                            </label>
                        </div>
                        <div className="Digital-flow-left-upper-grp">
                            <label className={'input upper-label-input'}>
                                <span
                                    className={
                                        (F2DIGI || false) + '-span textsp'
                                    }
                                >
                                    F2
                                </span>
                                <div
                                    className={
                                        'switch-button-' + (F2DIGI || false)
                                    }
                                    id="s10"
                                    style={{ color: '#717171' }}
                                >
                                    <input
                                        active={f2Digi}
                                        // disabled={!F2DIGI || false}
                                        className="switch-button-checkbox"
                                        type="checkbox"
                                        onChange={toggleF2}
                                        checked={f2Digi}
                                    ></input>

                                    <label
                                        className=" switch-button-label"
                                        for=""
                                    >
                                        <span
                                            className="switch-button-label-span"
                                            id="in10"
                                            style={{
                                                color: '#fcfcfc',
                                            }}
                                        >
                                            Digital
                                        </span>
                                    </label>
                                </div>
                            </label>
                        </div>
                    </div>
                )}

                {/* <div
                            className="Digital-flow-left-upper"
                            style={{ visibility: 'hidden' }}
                        ></div> */}

                {/* PORT M3 & M4 */}
                <div
                    className="digital-flow-left-upper"
                    style={
                        isPCv1
                            ? {
                                  position: 'relative',
                                  transform: 'translateY(-72%)',
                              }
                            : { top: '-1vh' }
                    }
                >
                    <div className="digital-flow-left-upper-grp">
                        <label className={'input upper-label-input'}>
                            <span
                                className={
                                    (M3DIGI || isSmileThree || false) +
                                    '-span textsp'
                                }
                            >
                                L3
                            </span>
                            <div
                                className={
                                    'switch-button-' +
                                    (M3DIGI || isSmileThree || false)
                                }
                                id="s15"
                                style={{ color: bttnColor[14] }}
                            >
                                <input
                                    active={m3Digi}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    onChange={toggleM3}
                                    checked={m3Digi}
                                ></input>
                                <label className=" switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in15"
                                        style={{
                                            color: bttnColor2[14],
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                    </div>

                    <div
                        className="digital-flow-left-upper-grp"
                        style={{ top: '10%' }}
                    >
                        <label className={'input upper-label-input'}>
                            <span
                                className={
                                    (M4DIGI || isSmileFour || false) +
                                    '-span textsp'
                                }
                            >
                                L4
                            </span>
                            <div
                                className={
                                    'switch-button-' +
                                    (((M4DIGI || isSmileFour) && !m3Mp3) ||
                                        false)
                                }
                                id="s16"
                                style={{ color: bttnColor[15] }}
                            >
                                <input
                                    active={m4Digi}
                                    className="switch-button-checkbox"
                                    type="checkbox"
                                    onChange={toggleM4}
                                    checked={m4Digi}
                                ></input>
                                <label className=" switch-button-label" for="">
                                    <span
                                        className="switch-button-label-span"
                                        id="in16"
                                        style={{
                                            color: bttnColor2[15],
                                        }}
                                    >
                                        Digital
                                    </span>
                                </label>
                            </div>
                        </label>
                        <br />
                        <label
                            className={
                                'input upper-label-input upper-label-input-servo'
                            }
                        >
                            <div
                                className={
                                    'switch-button-mp3-' +
                                    (m3Mp3 &&
                                        JSON.parse(
                                            sessionStorage.getItem('M4')
                                        ))
                                }
                                id="s2"
                            >
                                <input
                                    className="switch-button-checkbox-mp3"
                                    disabled={
                                        !JSON.parse(
                                            sessionStorage.getItem('M4')
                                        )
                                    }
                                    type="checkbox"
                                    checked={m3Mp3}
                                    onChange={Mp3M3}
                                ></input>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RightToggleSection
