import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import MainHeader from '../../ReusableComponents/Header/MainHeader'
import renderPrgImage from '../../../source/programImg'
import style from './SaveAction.module.css'

const DUMMYACTIONDATA = {
    name: 'new action group',
    description: '',
    slot: 1,
    createActionState: {},
}

const INITIALFEEDBACK = {
    isOn: false,
    msg: '',
    code: 'success',
}

function SaveAction({ webSerial }) {
    const history = useHistory()

    const [actionGroupData, setActionGroupData] = useState(DUMMYACTIONDATA)
    const [feedback, setFeedback] = useState(INITIALFEEDBACK)

    const handleChange = (e, key) => {
        setActionGroupData({ ...actionGroupData, [key]: e.target.value })
    }

    const validateInput = () => {
        if (!/[a-zA-Z0-9]+/.test(actionGroupData.name)) {
            setFeedback({
                isOn: true,
                msg: 'Invalid Name',
                code: 'error',
            })
            return false
        }
        return true
    }

    useEffect(() => {
        const createActionState = JSON.parse(
            sessionStorage.getItem('createActionState')
        )
        if (createActionState._meta) {
            setActionGroupData(createActionState._meta)
        }
    }, [])

    useEffect(() => {
        const createActionState = JSON.parse(
            sessionStorage.getItem('createActionState')
        )
        createActionState._meta = actionGroupData
        sessionStorage.setItem(
            'createActionState',
            JSON.stringify(createActionState)
        )
    }, [actionGroupData])

    useEffect(() => {
        const id = setTimeout(() => {
            setFeedback({ isOn: false })
        }, 3000)
        return () => clearTimeout(id)
    }, [feedback])

    const handleSave = () => {
        if (!validateInput()) return

        const existingCustomActionGroupData = JSON.parse(
            localStorage.getItem('customActionGroupData')
        )

        let newActionGroupData = {}
        if (existingCustomActionGroupData)
            newActionGroupData = existingCustomActionGroupData

        if (newActionGroupData[actionGroupData.name]) {
            setFeedback({
                isOn: true,
                msg: 'Action group name already exists',
                code: 'error',
            })
            const confirm = window.confirm(
                'This action group will replace an already saved one. Continue ?'
            )
            if (!confirm) return
        }

        newActionGroupData[actionGroupData.name] = actionGroupData

        newActionGroupData[actionGroupData.name]['createActionState'] =
            JSON.parse(sessionStorage.getItem('createActionState'))

        localStorage.setItem(
            'customActionGroupData',
            JSON.stringify(newActionGroupData)
        )

        setFeedback({
            isOn: true,
            msg: 'Action group has been successfully saved!',
            code: 'success',
        })
    }

    const handleNavigateNextPage = () => {
        history.push('/code/project/action/upload')
    }

    return (
        <div>
            <MainHeader
                title="Save Action Group"
                isGoBack={true}
                goBack={() => history.push('/code/project/action/create')}
            />
            <div className={style.leftCol}>
                <div className={style.inputCover}>
                    <span className={style.caption}>Name</span>
                    <input
                        className={style.input}
                        value={actionGroupData.name}
                        onChange={(e) => handleChange(e, 'name')}
                    />
                </div>

                <div className={style.inputCover}>
                    <span className={style.caption}>Description</span>
                    <textarea
                        className={style.textarea}
                        value={actionGroupData.description}
                        onChange={(e) => handleChange(e, 'description')}
                    />
                </div>

                {feedback.isOn && (
                    <div className={style.feedback}>
                        <span className={style[feedback.code]}>
                            {feedback.msg}
                        </span>
                    </div>
                )}
            </div>

            <div className={style.rightCol}>
                <div className={style.btnGrp}>
                    <img
                        style={{
                            width: '4rem',
                            height: '4rem',
                            cursor: 'pointer',
                        }}
                        src={renderPrgImage('saveBtn')}
                        onClick={handleSave}
                    />

                    <img
                        style={{
                            width: '4rem',
                            height: '4rem',
                            cursor: 'pointer',
                            scale: '1.2',
                            marginTop: '0.8rem',
                        }}
                        src={renderPrgImage('nextBtn')}
                        onClick={handleNavigateNextPage}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(SaveAction)
