// import React, { useState, useEffect, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import stylee from './Graph.module.css'
import renderPrgImage from '../../../source/programImg'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import { useHistory } from 'react-router'
import { useState } from 'react'
import closeBtn from '../../../Assets/img/close.png'
// import { GraphPlotStorage } from './GraphPlotStorage'

function GraphTime(props) {
    console.log('props gsk ', props)
    let history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [milliSec, setMiliSec] = useState(100)
    const [sec, setSec] = useState(0)
    const [min, setMin] = useState(0)
    const [hr, setHr] = useState(0)
    const [ishelpButton, sethelpButton] = useState(false)
    const backBtn = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        history.push('/graphPorts')
    }
    const nextBtn = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        history.push('/graphPlotter')
    }
    const onInput = (e, len) => {
        //restricts typing more than max value digits
        let newValue = e.target.value.slice(0, len)
        e.target.value = newValue
        newValue = parseInt(newValue)
    }
    //PlayC 1178
    //limits the value to its maximum
    const handleBlur = (e,max,type) => {
        let inputVal = parseInt(e.target.value, 10)
        if (inputVal > max) inputVal = Math.min(Math.max(inputVal, 0), max)
        e.target.value = inputVal.toString()
        switch (type) {
            case 'ms':
                props.graphPlotStorage({
                    time: {
                        h: props.indexData.graphPlotStorage.time.h,
                        m: props.indexData.graphPlotStorage.time.m,
                        s: props.indexData.graphPlotStorage.time.s,
                        ms: inputVal,
                    },
                })
                break
            case 's':
                props.graphPlotStorage({
                    time: {
                        h: props.indexData.graphPlotStorage.time.h,
                        m: props.indexData.graphPlotStorage.time.m,
                        s: inputVal,
                        ms: props.indexData.graphPlotStorage.time.ms,
                    },
                })
                break
            case 'm':
                props.graphPlotStorage({
                    time: {
                        h: props.indexData.graphPlotStorage.time.h,
                        m: inputVal,
                        s: props.indexData.graphPlotStorage.time.s,
                        ms: props.indexData.graphPlotStorage.time.ms,
                    },
                })
                break
            case 'h':
                props.graphPlotStorage({
                    time: {
                        h: inputVal,
                        m: props.indexData.graphPlotStorage.time.m,
                        s: props.indexData.graphPlotStorage.time.s,
                        ms: props.indexData.graphPlotStorage.time.ms,
                    },
                })
                break
        }
    }
    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }
    return (
        <>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <div style={{ height: '20%' }}>
                    <ProgramHeader
                        data={{
                            options: [
                                'Select Sensors',
                                'Select Ports',
                                'Set Time',
                                'Set Graph',
                                'Plot',
                            ],
                            selected: 2,
                        }}
                        showSave={false}
                        showHelp={true}
                        handleHelp={handleClickhelpbtn}
                    />
                </div>

                <div className={stylee.Sensor_Body} style={{ height: '80%' }}>
                    <div style={{ width: '20%', position: 'relative' }}>
                        <img
                            style={{
                                position: 'absolute',
                                left: '49%',
                                top: '90%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('backBtn')}
                            onClick={backBtn}
                        />
                    </div>

                    <div
                        style={{
                            backgroundImage: `url(${renderPrgImage(
                                'timepopUp'
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            // border: '1px solid red',
                            position: 'relative',
                        }}
                        // className={stylee.Sensors_Container}
                        className={`${
                            slideDirection === 'true'
                                ? 'slide-right'
                                : 'slide-left'
                        } ${stylee.Sensors_Container}`}
                    >
                        <div className={stylee.time_headers}>
                            <p>Hours</p>
                            <p>Min</p>
                            <p>Sec</p>
                            <p>Millisec</p>
                        </div>
                        <div>
                            <input
                                type="number"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '17%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '14%',
                                    height: '37%',
                                    padding: '0.5rem',
                                    fontSize: '9vh',
                                    borderRadius: '26%',
                                    textAlign: 'center',
                                    borderWidth: '0',
                                }}
                                onInputCapture={(e) => {
                                    onInput(e, 2)
                                }}
                                max="23"
                                min="0"
                                maxLength="2"
                                defaultValue={
                                    props.indexData.graphPlotStorage.time.h
                                }
                                placeholder="00"
                                onBlur={(e) => handleBlur(e, 23,'h')}
                            />
                        </div>

                        <div>
                            <img
                                src={renderPrgImage('Colon')}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '28%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '6vh',
                                }}
                            />
                        </div>

                        <div>
                            <input
                                type="number"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '40%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '14%',
                                    height: '37%',
                                    padding: '0.5rem',
                                    fontSize: '9vh',
                                    borderRadius: '26%',
                                    textAlign: 'center',
                                    borderWidth: '0',
                                }}
                                onInput={(e) => {
                                    onInput(e, 2)
                                }}
                                max={59}
                                min={0}
                                defaultValue={
                                    props.indexData.graphPlotStorage.time.m
                                }
                                placeholder="00"
                                onBlur={(e) => handleBlur(e,59,'m')}
                            />
                        </div>

                        <div>
                            <img
                                src={renderPrgImage('Colon')}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '51%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '6vh',
                                }}
                            />
                        </div>

                        <div>
                            <input
                                type="number"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '63%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '14%',
                                    height: '37%',
                                    padding: '0.5rem',
                                    fontSize: '9vh',
                                    borderRadius: '26%',
                                    textAlign: 'center',
                                    borderWidth: '0',
                                }}
                                onInput={(e) => {
                                    onInput(e, 2)
                                }}
                                max={59}
                                min={0}
                                defaultValue={
                                    props.indexData.graphPlotStorage.time.s
                                }
                                placeholder="00"
                                onBlur={(e) => handleBlur(e,59,'s')}
                            />
                        </div>

                        <div>
                            <img
                                src={renderPrgImage('Colon')}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '74%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '6vh',
                                }}
                            />
                        </div>

                        <div>
                            <input
                                type="number"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '85%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '14%',
                                    height: '37%',
                                    padding: '0.5rem',
                                    fontSize: '9vh',
                                    borderRadius: '26%',
                                    textAlign: 'center',
                                    borderWidth: '0',
                                }}
                                onInput={(e) => {
                                    onInput(e, 3)
                                }}
                                max={999}
                                min={0}
                                defaultValue={
                                    props.indexData.graphPlotStorage.time.ms
                                }
                                placeholder="000"
                                onBlur={(e) => handleBlur(e,999,'ms')}
                            />
                        </div>
                    </div>

                    <div style={{ width: '20%', position: 'relative' }}>
                        <img
                            style={{
                                position: 'absolute',
                                right: '21%',
                                top: '90%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('nextBtn')}
                            onClick={nextBtn}
                        />
                    </div>
                </div>
                {ishelpButton == true ? (
                    <div className={stylee.helpScreen}>
                        <div
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '47%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                                width: '65vw',
                            }}
                        >
                            <img
                                src={renderPrgImage('graphTimeH')}
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div
                            className={stylee.help}
                            style={{
                                width: '17%',
                                top: '65%',
                                left: '50%',
                            }}
                        >
                            <p className={stylee.help_text}>
                                Time delay for updating the Sensor value in
                                displaying at the plotter screens
                            </p>
                        </div>

                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '18%',
                                left: '81%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        indexData: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        graphPlotStorage: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GraphTime)
