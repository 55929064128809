function cacheAssets(assetList) {
    const promises = assetList.map((src) => {
        return new Promise(function (resolve, reject) {
            const img = new Image()
            // console.log(src);
            img.src = src
            img.onload = resolve()
            img.onerror = reject()
        })
    })
    Promise.all(promises)
}

export default cacheAssets
