var isConnecting= false


const Connection_Reducer = (state = isConnecting, action) => {
    console.log("connectcdccc",state,action)
    if (action.type == 'CONNECTING') {
        return action.payload
    } else {
        return state
    }
}
export default Connection_Reducer
