import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import renderImage from '../../source/importImg'
import AdvanceSettingStyle from './AdvanceSetting.module.css'
import cacheAssets from '../../utils/cacheAssets'
import AdvanceSlider from '../ReusableComponents/AdvanceSetSlider/AdvanceSlider'
function AdvanceSetting(props) {
    let history = useHistory()
    const version = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const gobackUrl = () => {
        history.push('/Selection')
    }
    const [isHelp, setHelp] = useState(false)
    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }
    // const firmwareUpdate = () => {
    //     console.log('firmwareUpdate')
    //     if (connectedDevice == 'Ace' && version == '0.1.13') {
    //         window.location.href =
    //             'http://downloads.plode.org/firmwareUpdateTool.exe/'
    //     } else {
    //         window.location.href = 'https://zing.plode.org/'
    //     }
    // }
    const firmwareUpdate = () => {
        window.open('http://firmware.plode.org/')
    }
    const AccountSetting = () => {
        history.push('/accountSetting')
    }

    useEffect(() => {
        let assetsToBeCached = [
            renderImage('backBtn'),
            renderImage('firmwareUpdate'),
            renderImage('AccountSetting'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])
    return (
        <>
            <div className={AdvanceSettingStyle.Main_Select}>
                <div className={AdvanceSettingStyle.Select_Header}>
                    <div>
                        <img
                            className={AdvanceSettingStyle.Back_Btn}
                            src={renderImage('backBtn')}
                            onClick={gobackUrl}
                        ></img>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <p className={AdvanceSettingStyle.text}>
                            Advance Setting
                        </p>
                    </div>
                    <div id={AdvanceSettingStyle.Help_Bttn}>
                        {isHelp == false ? (
                            <div className="header_button">
                                <img
                                    className={
                                        AdvanceSettingStyle.Help_Bttn_Img
                                    }
                                    src={renderImage('helpBtnInActive')}
                                    onClick={handleHelpBtn}
                                ></img>
                                <span
                                    className="tooltiptext"
                                    style={{ left: '90.3%' }}
                                >
                                    {' '}
                                    <p>Help</p>
                                </span>
                            </div>
                        ) : (
                            <div style={{ position: 'relative' }}>
                                {' '}
                                <div className={AdvanceSettingStyle.S_slide}>
                                    <AdvanceSlider />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '23vh',
                                        left: '40vw',
                                        transform: 'translate(-50%,-50%)',
                                        zIndex: '100000',
                                    }}
                                >
                                    <img
                                        className={
                                            AdvanceSettingStyle.helpClose
                                        }
                                        src={renderImage('clos')}
                                        onClick={handleHelpBtn}
                                    ></img>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={AdvanceSettingStyle.setting_Body}>
                    <div onClick={AccountSetting}>
                        <img
                            className={AdvanceSettingStyle.setting_BodyDiv}
                            src={renderImage('AccountSetting')}
                        ></img>
                        <div className={AdvanceSettingStyle.Body_Text}>
                            <p className={AdvanceSettingStyle.P_Text}>
                                Account <br /> Setting
                            </p>
                        </div>
                    </div>

                    <div onClick={firmwareUpdate}>
                        <img
                            className={AdvanceSettingStyle.setting_BodyDiv1}
                            src={renderImage('firmwareUpdate')}
                        ></img>
                        <div className={AdvanceSettingStyle.Body_Text1}>
                            <p className={AdvanceSettingStyle.P_Text1}>
                                Firmware <br /> Update
                            </p>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </>
    )
}
export default AdvanceSetting
