import selectedCompo_Reducer from './componentSelection'
import assemblySelec_Reducer from './assemblySelection'
import logicSelection from './logicSection'
import flowlogicSelection from './flowLogic'
import packageJson from '../../../package.json'
import webSerialPortList_Reducer from './WebSerial'
import { combineReducers } from 'redux'
import popUpModalReducer from './popUpModalReducer'
import graphPlotStorage_Reducer from './graphPlotStorage'
import workerReducer from './worker'
import deviceReducer from './device'
import Connection_Reducer from './Connection'
if (!packageJson['dev']) {
    console.log = function () {}
    console.warn = function () {}
}
const allReducer = combineReducers({
    webSerial: webSerialPortList_Reducer,
    concept: selectedCompo_Reducer,
    assembly: assemblySelec_Reducer,
    logic: logicSelection,
    flowlogic: flowlogicSelection,
    popUpModal: popUpModalReducer,
    graphPlotStorage: graphPlotStorage_Reducer,
    worker: workerReducer,
    device:deviceReducer,
    isConnecting:Connection_Reducer
})

export default allReducer
