import React, { Component } from "react";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "../../../index.css";

import renderPrgImage from "../../../source/programImg";
class Nouislider extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	change = (e) => {
		this.props.onChange(e.target.value, e.target.name);
	};
	render() {
		const wrapperStyle = { width: 400, margin: 50 };
		var { range, disabled, step, value } = this.props;

		const sliderStyle = {
			backgroundImage: `url("${
				this.props.sliderName == undefined
					? renderPrgImage("propertypanelSlider")
					: this.props.sliderName.includes("R") && this.props.value > 0
					? renderPrgImage("propertypanelRedSlider")
					: this.props.sliderName.includes("B") && this.props.value > 0
					? renderPrgImage("propertypanelBlueSlider")
					: this.props.sliderName.includes("G") && this.props.value > 0
					? renderPrgImage("propertypanelGreenSlider")
					: renderPrgImage("propertypanelInactiveSlider")
			}")`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundPosition: "center",
		};

		return (
			<div>
				<input
					className="slider-panel-input"
					name={this.props.sliderName}
					id="PanelsliderRange"
					type="range"
					value={value}
					onChange={this.change}
					min={range.min}
					max={range.max}
					disabled={disabled}
					steps={step}
					style={sliderStyle}
				/>
			</div>
		);
	}
}

export default Nouislider;
