import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import Bottom from '../Bottom'
import Digitalbutton from '../Digitalbutton'
import { connect } from 'react-redux'
import { webSerialAction } from '../../../redux/actions'

import { Nav, ButtonGroup, ToggleButton } from 'react-bootstrap'
import SwitchButton from '../SwitchButton/SwitchButtonDigital'
import useLocalStorage from '../../LocalStorage/LocalStorage'
import pcImg from '../../../Assets/internalAccessories/PC_image@3x.png'
import { Link, useHistory } from 'react-router-dom'
import renderPrgImage from '../../../source/programImg'

import digitalImg from '../../../Assets/img/code bar@2x.png'
import secondaryImg from '../../../Assets/img/save - secondary.png'
import strokeImg from '../../../Assets/img/button 52x52 - stroke.png'
import connectionImg from '../../../Assets/usb - off@2x.png'

import '../pcimage.css'
import '../Navbar.css'
import '../style.css'
import '../buttonDig.scss'

import digitalBgimg from '../../../Assets/img/digitalBgimg.png'
import closeBtn from '../../../Assets/img/close.png'
import renderImage from '../../../source/importImg'

import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import selectStyle from '../../Humanoid/HumanoidSelectFeatures.module.css' //'./HumanoidSelectFeatures.module.css'

let bttnColor = []
let bttnColor2 = []
let bttType = [
    'A1DIGI',
    'A2DIGI',
    'B1DIGI',
    'B2DIGI',
    'C1DIGI',
    'C2DIGI',
    'D1DIGI',
    'D2DIGI',
    'F1DIGI',
    'F2DIGI',
    'E1DIGI',
    'E2DIGI',
    'M1DIGI',
    'M2DIGI',
    'M3DIGI',
    'M4DIGI',
]
for (let i = 0; i < 16; i++) {
    bttnColor[i] = '#717171'
    bttnColor2[i] = '#fcfcfc'
    if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
        bttnColor[i] = '#fcfcfc'
        bttnColor2[i] = '#717171'
    }
}
function HumanoidDA(props) {
    const history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    // const ZingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const [ishelpButton, sethelpButton] = useState(false)
    const [isAnimating, setIsAnimating] = useState(false)
    const humanoidPortData = JSON.parse(
        sessionStorage.getItem('humanoidPortData')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const next = () => {
        saveDataToLocalStorage()

        history.push('/flow/flowchart')
    }
    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')

    useLayoutEffect(() => {
        return () => {
            for (let i = 0; i < 16; i++) {
                if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
                    bttnColor[i] = '#fcfcfc'
                    bttnColor2[i] = '#717171'
                } else {
                    bttnColor[i] = '#717171'
                    bttnColor2[i] = '#fcfcfc'
                }
            }
        }
    })

    const backBtnAction = () => {
        saveDataToLocalStorage()

        history.push('/flow/input-output')
    }
    var humanoidProgramData =
        JSON.parse(sessionStorage.getItem('humanoidProgramData')) || null
    const A1DIGI = JSON.parse(sessionStorage.getItem('A1'))
    const A2DIGI = JSON.parse(sessionStorage.getItem('A2'))
    const B1DIGI = JSON.parse(sessionStorage.getItem('B1'))
    const B2DIGI = JSON.parse(sessionStorage.getItem('B2'))
    const C1DIGI = JSON.parse(sessionStorage.getItem('C1'))
    const C2DIGI = JSON.parse(sessionStorage.getItem('C2'))
    const D1DIGI = JSON.parse(sessionStorage.getItem('D1'))
    const D2DIGI = JSON.parse(sessionStorage.getItem('D2'))
    const F1DIGI = JSON.parse(sessionStorage.getItem('F1'))
    const F2DIGI = JSON.parse(sessionStorage.getItem('F2'))
    const E1DIGI = JSON.parse(sessionStorage.getItem('E1'))
    const E2DIGI = JSON.parse(sessionStorage.getItem('E2'))
    const M1DIGI = JSON.parse(sessionStorage.getItem('M1'))
    const M2DIGI = JSON.parse(sessionStorage.getItem('M2'))
    const M3DIGI = JSON.parse(sessionStorage.getItem('M3'))
    const M4DIGI = JSON.parse(sessionStorage.getItem('M4'))
    const SPI = JSON.parse(sessionStorage.getItem('spi'))
    const I2C = JSON.parse(sessionStorage.getItem('i2c'))
    const UART = JSON.parse(sessionStorage.getItem('uart'))

    const [a1Digi, setA1Digi] = useLocalStorage('A1DIGI', false)
    const [b1Digi, setB1Digi] = useLocalStorage('B1DIGI', false)
    const [c1Digi, setC1Digi] = useLocalStorage('C1DIGI', false)
    const [d1Digi, setD1Digi] = useLocalStorage('D1DIGI', false)
    const [a2Digi, setA2Digi] = useLocalStorage('A2DIGI', false)
    const [b2Digi, setB2Digi] = useLocalStorage('B2DIGI', false)
    const [c2Digi, setC2Digi] = useLocalStorage('C2DIGI', false)
    const [d2Digi, setD2Digi] = useLocalStorage('D2DIGI', false)
    const [f1Digi, setF1Digi] = useLocalStorage('F1DIGI', false)
    const [f2Digi, setF2Digi] = useLocalStorage('F2DIGI', false)
    const [e1Digi, setE1Digi] = useLocalStorage('E1DIGI', false)
    const [e2Digi, setE2Digi] = useLocalStorage('E2DIGI', false)
    const [m1Digi, setM1Digi] = useLocalStorage('M1DIGI', false)
    const [m2Digi, setM2Digi] = useLocalStorage('M2DIGI', false)
    const [m3Digi, setM3Digi] = useLocalStorage('M3DIGI', false)
    const [m4Digi, setM4Digi] = useLocalStorage('M4DIGI', false)

    const [a1Servo, setA1Servo] = useLocalStorage('A1Servo', false)
    const [a1Servo270, setA1Servo270] = useLocalStorage('A1Servo270', false)
    const [a1Servo360, setA1Servo360] = useLocalStorage('A1Servo360', false)
    const [a2Servo, setA2Servo] = useLocalStorage('A2Servo', false)
    const [a2Servo270, setA2Servo270] = useLocalStorage('A2Servo270', false)
    const [a2Servo360, setA2Servo360] = useLocalStorage('A2Servo360', false)
    const [b1Servo, setB1Servo] = useLocalStorage('B1Servo', false)
    const [b1Servo270, setB1Servo270] = useLocalStorage('B1Servo270', false)
    const [b1Servo360, setB1Servo360] = useLocalStorage('B1Servo360', false)
    const [b2Servo, setB2Servo] = useLocalStorage('B2Servo', false)
    const [b2Servo270, setB2Servo270] = useLocalStorage('B1Servo270', false)
    const [b2Servo360, setB2Servo360] = useLocalStorage('B2Servo360', false)
    const [c1Servo, setC1Servo] = useLocalStorage('C1Servo', false)
    const [c1Servo270, setC1Servo270] = useLocalStorage('C1Servo270', false)
    const [c1Servo360, setC1Servo360] = useLocalStorage('C1Servo360', false)
    const [c2Servo, setC2Servo] = useLocalStorage('C2Servo', false)
    const [c2Servo270, setC2Servo270] = useLocalStorage('C2Servo270', false)
    const [c2Servo360, setC2Servo360] = useLocalStorage('C2Servo360', false)

    const [d1Servo, setD1Servo] = useLocalStorage('D1Servo', false)
    const [d1Servo360, setD1Servo360] = useLocalStorage('D1Servo360', false)
    const [d2Servo, setD2Servo] = useLocalStorage('D2Servo', false)
    const [d2Servo360, setD2Servo360] = useLocalStorage('D2Servo360', false)

    const [pwmA1, setPwmA1] = useLocalStorage(
        'PWMA1',
        JSON.parse(sessionStorage.getItem('a1-I/O')) &&
            JSON.parse(sessionStorage.getItem('A1'))
    )
    const [pwmD1, setPwmD1] = useLocalStorage(
        'PWMD1',
        JSON.parse(sessionStorage.getItem('d1-I/O')) &&
            JSON.parse(sessionStorage.getItem('D1'))
    )
    const [pwmD2, setPwmD2] = useLocalStorage(
        'PWMD2',
        JSON.parse(sessionStorage.getItem('d2-I/O')) &&
            JSON.parse(sessionStorage.getItem('D2'))
    )
    const toggleA1 = async () => {
        if (
            !A1DIGI ||
            a1Servo ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        ) {
            console.log('hii')
            setToastMsg('Enable A1 To Enable A1 Digital or Analog ')
            setToastActive(true)
            disableToast()
        }
        if (JSON.parse(sessionStorage.getItem('AUltra'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (a1Servo || a1Servo270 || a1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !A1DIGI ||
            a1Servo ||
            a1Servo360 ||
            a1Servo270 ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        )
            return
        if (JSON.parse(sessionStorage.getItem('a1-I/O')) === true) {
            setPwmA1(!pwmA1)
        }
        setA1Digi(!a1Digi)
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                console.log(value)
                if (value[1].type === 'output') {
                    value[1].state[31].isActionEnabled = false
                    value[1].state[31].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('A1DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('A1DIGI')))) {
            document.getElementById('in1').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s1').style.cssText = 'color: #717171;'
            humanoidPortData[31].type2 = 'digital'
        } else {
            document.getElementById('in1').style.cssText = 'color: #717171; '
            document.getElementById('s1').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[31].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const toggleA2 = async () => {
        if (
            !A2DIGI ||
            a2Servo ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        ) {
            console.log('hii')
            setToastMsg('Enable A2 To Enable A2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('AUltra'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (a2Servo || a2Servo270 || a2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !A2DIGI ||
            a2Servo ||
            a2Servo270 ||
            a2Servo360 ||
            JSON.parse(sessionStorage.getItem('AUltra')) ||
            false
        )
            return
        setA2Digi(!a2Digi)
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[32].isActionEnabled = false
                    value[1].state[32].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('A2DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('A2DIGI')))) {
            document.getElementById('in2').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s2').style.cssText = 'color: #717171;'
            humanoidPortData[32].type2 = 'digital'
        } else {
            document.getElementById('in2').style.cssText = 'color: #717171; '
            document.getElementById('s2').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[32].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const toggleB1 = async () => {
        if (
            !B1DIGI ||
            b1Servo ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        ) {
            console.log('hii')
            setToastMsg('Enable B1 To Enable B1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3'))
        ) {
            console.log('WORKING 1')
            setToastMsg('Disable RGB AND MP3')
            setToastActive(true)
            disableToast()
        }
        if (b1Servo || b1Servo270 || b1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !B1DIGI ||
            b1Servo ||
            b1Servo270 ||
            b1Servo360 ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        )
            return
        setB1Digi(!b1Digi)
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[33].isActionEnabled = false
                    value[1].state[33].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('B1DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('B1DIGI')))) {
            document.getElementById('in3').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s3').style.cssText = 'color: #717171;'
            humanoidPortData[33].type2 = 'digital'
        } else {
            document.getElementById('in3').style.cssText = 'color: #717171; '
            document.getElementById('s3').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[33].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const toggleB2 = async () => {
        if (
            !B2DIGI ||
            b2Servo ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        ) {
            console.log('hii')
            setToastMsg('Enable B2 To Enable B2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3'))
        ) {
            console.log('WORKING 1')
            setToastMsg('Disable RGB AND MP3')
            setToastActive(true)
            disableToast()
        }
        if (b2Servo || b2Servo270 || b2Servo270) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !B2DIGI ||
            b2Servo ||
            b2Servo270 ||
            b2Servo360 ||
            JSON.parse(sessionStorage.getItem('BRGB')) ||
            JSON.parse(sessionStorage.getItem('BMP3')) ||
            false
        )
            return
        setB2Digi(!b2Digi)
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[34].isActionEnabled = false
                    value[1].state[34].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('B2DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('B2DIGI')))) {
            document.getElementById('in4').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s4').style.cssText = 'color: #717171;'
            humanoidPortData[34].type2 = 'digital'
        } else {
            document.getElementById('in4').style.cssText = 'color: #717171; '
            document.getElementById('s4').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[34].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const toggleC1 = async () => {
        if (
            !C1DIGI ||
            c1Servo ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        ) {
            console.log('hii')
            setToastMsg('Enable C1 To Enable C1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }
        if (JSON.parse(sessionStorage.getItem('CUltra'))) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (c1Servo || c1Servo270 || c1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !C1DIGI ||
            c1Servo ||
            c1Servo360 ||
            c1Servo270 ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        )
            return
        setC1Digi(!c1Digi)
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[35].isActionEnabled = false
                    value[1].state[35].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('C1DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('C1DIGI')))) {
            document.getElementById('in5').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s5').style.cssText = 'color: #717171;'
            humanoidPortData[35].type2 = 'digital'
        } else {
            document.getElementById('in5').style.cssText = 'color: #717171; '
            document.getElementById('s5').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[35].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const toggleC2 = async () => {
        if (
            !C2DIGI ||
            c2Servo ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        ) {
            console.log('hii')
            setToastMsg('Enable C2 To Enable C2 Digital or Analog')
            setToastActive(true)
            disableToast()
        }
        if (JSON.parse(sessionStorage.getItem('CUltra'))) {
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (c2Servo || c2Servo270 || c2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !C2DIGI ||
            c2Servo ||
            c2Servo270 ||
            c2Servo360 ||
            JSON.parse(sessionStorage.getItem('CUltra')) ||
            false
        )
            return
        setC2Digi(!c2Digi)
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[36].isActionEnabled = false
                    value[1].state[36].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('C2DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('C2DIGI')))) {
            document.getElementById('in6').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s6').style.cssText = 'color: #717171;'
            humanoidPortData[36].type2 = 'digital'
        } else {
            document.getElementById('in6').style.cssText = 'color: #717171; '
            document.getElementById('s6').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[36].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const toggleD1 = async () => {
        if (
            !JSON.parse(sessionStorage.getItem('D1')) ||
            // !JSON.parse(sessionStorage.getItem("d1-I/O")) ||
            d1Servo ||
            JSON.parse(sessionStorage.getItem('DOLED'))
        ) {
            setToastMsg('Enable D1 To Enable D1 Digital or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('DOLED'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  OLED')
            setToastActive(true)
            disableToast()
        }
        if (JSON.parse(sessionStorage.getItem('DUltra'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (d1Servo || d1Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !JSON.parse(sessionStorage.getItem('D1')) ||
            d1Servo ||
            d1Servo360 ||
            JSON.parse(sessionStorage.getItem('DUltra')) ||
            false
        )
            return
        // if (JSON.parse(sessionStorage.getItem("d1-I/O")) === true) {
        //   setPwmD1(!pwmD1);
        // }
        setD1Digi(!d1Digi)
        if (
            sessionStorage.getItem('d1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[37].isActionEnabled = false
                    value[1].state[37].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('D1DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('D1DIGI')))) {
            document.getElementById('in7').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s7').style.cssText = 'color: #717171;'
            humanoidPortData[37].type2 = 'digital'
        } else {
            document.getElementById('in7').style.cssText = 'color: #717171; '
            document.getElementById('s7').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[37].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }
    const toggleD2 = async () => {
        if (
            !JSON.parse(sessionStorage.getItem('D2')) ||
            // !JSON.parse(sessionStorage.getItem("d2-I/O")) ||
            d2Servo ||
            JSON.parse(sessionStorage.getItem('DOLED'))
        ) {
            console.log('hii')
            setToastMsg('Enable D2 To Enable D2 Digital  or Analog')
            setToastActive(true)
            disableToast()
        }

        if (JSON.parse(sessionStorage.getItem('DOLED'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  OLED')
            setToastActive(true)
            disableToast()
        }
        if (JSON.parse(sessionStorage.getItem('DUltra'))) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (d2Servo || d2Servo360) {
            setToastMsg('Disable  Servo')
            setToastActive(true)
            disableToast()
        }

        if (
            !JSON.parse(sessionStorage.getItem('D2')) ||
            d2Servo360 ||
            d2Servo ||
            JSON.parse(sessionStorage.getItem('DUltra')) ||
            false
        )
            return

        setD2Digi(!d2Digi)
        if (
            sessionStorage.getItem('d2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[38].isActionEnabled = false
                    value[1].state[38].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
        await JSON.parse(sessionStorage.getItem('D2DIGI'))
        const humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        if (!(await JSON.parse(sessionStorage.getItem('D2DIGI')))) {
            document.getElementById('in8').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s8').style.cssText = 'color: #717171;'
            humanoidPortData[38].type2 = 'digital'
        } else {
            document.getElementById('in8').style.cssText = 'color: #717171; '
            document.getElementById('s8').style.cssText = '  color: #fcfcfc;'
            humanoidPortData[38].type2 = 'analog'
        }
        sessionStorage.setItem(
            'humanoidPortData',
            JSON.stringify(humanoidPortData)
        )
    }

    const ServoA1 = () => {
        setA1Servo(!a1Servo)
        if (!a1Servo) {
            setA1Servo(true)
            setA1Servo270(false)
            setA1Servo360(false)
        }
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[31].isActionEnabled = false
                    value[1].state[31].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo270A1 = () => {
        setA1Servo270(!a1Servo270)
        if (!a1Servo270) {
            setA1Servo(false)
            setA1Servo270(true)
            setA1Servo360(false)
        }
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[31].isActionEnabled = false
                    value[1].state[31].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360A1 = () => {
        setA1Servo360(!a1Servo360)
        if (!a1Servo360) {
            setA1Servo(false)
            setA1Servo270(false)
            setA1Servo360(true)
        }
        if (
            sessionStorage.getItem('a1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[31].isActionEnabled = false
                    value[1].state[31].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoA2 = () => {
        setA2Servo(!a2Servo)
        if (!a2Servo) {
            setA2Servo(true)
            setA2Servo270(false)
            setA2Servo360(false)
        }
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[32].isActionEnabled = false
                    value[1].state[32].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo270A2 = () => {
        setA2Servo270(!a2Servo270)
        if (!a2Servo270) {
            setA2Servo(false)
            setA2Servo270(true)
            setA2Servo360(false)
        }
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[32].isActionEnabled = false
                    value[1].state[32].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360A2 = () => {
        setA2Servo360(!a2Servo360)
        if (!a2Servo360) {
            setA2Servo(false)
            setA2Servo270(false)
            setA2Servo360(true)
        }
        if (
            sessionStorage.getItem('a2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[32].isActionEnabled = false
                    value[1].state[32].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoB1 = () => {
        setB1Servo(!b1Servo)
        if (!b1Servo) {
            setB1Servo(true)
            setB1Servo270(false)
            setB1Servo360(false)
        }
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[33].isActionEnabled = false
                    value[1].state[33].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo270B1 = () => {
        setB1Servo360(!b1Servo270)
        if (!b1Servo270) {
            setB1Servo(false)
            setB1Servo270(true)
            setB1Servo360(false)
        }
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[33].isActionEnabled = false
                    value[1].state[33].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360B1 = () => {
        setB1Servo360(!b1Servo360)
        if (!b1Servo360) {
            setB1Servo(false)
            setB1Servo270(false)
            setB1Servo360(true)
        }
        if (
            sessionStorage.getItem('b1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[33].isActionEnabled = false
                    value[1].state[33].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoB2 = () => {
        setB2Servo(!b2Servo)
        if (!b2Servo) {
            setB2Servo(true)
            setB2Servo270(false)
            setB2Servo360(false)
        }
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[34].isActionEnabled = false
                    value[1].state[34].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo270B2 = () => {
        setB2Servo270(!b2Servo270)
        if (!b2Servo270) {
            setB2Servo(false)
            setB2Servo270(true)
            setB2Servo360(false)
        }
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[34].isActionEnabled = false
                    value[1].state[34].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360B2 = () => {
        setB2Servo360(!b2Servo360)
        if (!b2Servo360) {
            setB2Servo(false)
            setB2Servo270(false)
            setB2Servo360(true)
        }
        if (
            sessionStorage.getItem('b2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[34].isActionEnabled = false
                    value[1].state[34].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoC1 = () => {
        setC1Servo(!c1Servo)
        if (!c1Servo) {
            setC1Servo(true)
            setC1Servo270(false)
            setC1Servo360(false)
        }
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[35].isActionEnabled = false
                    value[1].state[35].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo270C1 = () => {
        setC1Servo270(!c1Servo270)
        if (!c1Servo270) {
            setC1Servo(false)
            setC1Servo270(true)
            setC1Servo360(false)
        }
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[35].isActionEnabled = false
                    value[1].state[35].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360C1 = () => {
        setC1Servo360(!c1Servo360)
        if (!c1Servo360) {
            setC1Servo(false)
            setC1Servo270(false)
            setC1Servo360(true)
        }
        if (
            sessionStorage.getItem('c1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[35].isActionEnabled = false
                    value[1].state[35].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoC2 = () => {
        setC2Servo(!c2Servo)
        if (!c2Servo) {
            setC2Servo(true)
            setC2Servo270(false)
            setC2Servo360(false)
        }
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[36].isActionEnabled = false
                    value[1].state[36].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo270C2 = () => {
        setC2Servo270(!c2Servo270)
        if (!c2Servo270) {
            setC2Servo(false)
            setC2Servo270(true)
            setC2Servo360(false)
        }
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[36].isActionEnabled = false
                    value[1].state[36].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360C2 = () => {
        setC2Servo360(!c2Servo360)
        if (!c2Servo360) {
            setC2Servo(false)
            setC2Servo270(false)
            setC2Servo360(true)
        }
        if (
            sessionStorage.getItem('c2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[36].isActionEnabled = false
                    value[1].state[36].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoD1 = () => {
        setD1Servo(!d1Servo)
        if (!d1Servo) {
            setD1Servo(true)
            setD1Servo360(false)
        }
        if (
            sessionStorage.getItem('d1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[37].isActionEnabled = false
                    value[1].state[37].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360D1 = () => {
        setD1Servo360(!d1Servo360)
        if (!d1Servo360) {
            setD1Servo(false)
            setD1Servo360(true)
        }
        if (
            sessionStorage.getItem('d1-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[37].isActionEnabled = false
                    value[1].state[37].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const ServoD2 = () => {
        setD2Servo(!d2Servo)
        if (!d2Servo) {
            setD2Servo(true)
            setD2Servo360(false)
        }
        if (
            sessionStorage.getItem('d2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[38].isActionEnabled = false
                    value[1].state[38].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }
    const Servo360D2 = () => {
        setD2Servo360(!d2Servo360)
        if (!d2Servo360) {
            setD2Servo(false)
            setD2Servo360(true)
        }
        if (
            sessionStorage.getItem('d2-I/O') === 'true' &&
            humanoidProgramData !== null
        ) {
            Object.entries(humanoidProgramData).map((value, index) => {
                if (value[1].type === 'output') {
                    value[1].state[38].isActionEnabled = false
                    value[1].state[38].value = 0
                }
            })
            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(humanoidProgramData)
            )
        }
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }
    return (
        <div className="Digital">
            <ProgramHeader
                data={{
                    options: [
                        'Select Ports',
                        'Input/Output',
                        'Digital/Analog',
                        'FlowChart',
                    ],
                    selected: 2,
                }}
                showSave={true}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />

            <div className="MainContainerInput">
                {/* <div className="CenterImgs">
                    <img
                        src={renderImage('Zing')}
                        style={{
                            position: 'absolute',
                            left: '80%',
                            top: '-6vh',
                            transform: 'translate(-50%,-50%)',
                        }}
                    />
                    <img
                        // className={selectStyle['select-buzzer']}
                        style={{
                            position: 'absolute',
                            left: '80%',
                            top: '-29vh',
                            transform: 'translate(-50%,-50%)',
                            width: '80%',
                        }}
                        src={
                            humanoidPortData[2].isPortSelected
                                ? renderImage('activeBuzzer')
                                : renderImage('inactiveBuzzer')
                        }
                    />
                </div> */}
                <div
                    className={selectStyle['select-humanoid']}
                    style={{
                        backgroundImage: `url(${renderImage('humanoid')})`,
                        top: '26%',
                        left: '15%',
                        backgroundSize: '100% 80%',
                    }}
                >
                    {/* <img className={selectStyle["select-buzzer"]} src={isBuzzer.img} /> */}
                    <img
                        className={selectStyle['select-leftEye']}
                        src={
                            humanoidPortData[0].isPortSelected
                                ? renderImage('activeEye')
                                : renderImage('inactiveEye')
                        }
                        style={{ top: '15.8%', left: '46%' }}
                    />
                    <img
                        className={selectStyle['select-rightEye']}
                        src={
                            humanoidPortData[1].isPortSelected
                                ? renderImage('activeEye')
                                : renderImage('inactiveEye')
                        }
                        style={{ top: '15.8%', left: '54.1%' }}
                    />
                    <img
                        className={selectStyle['select-buzzer']}
                        src={
                            humanoidPortData[2].isPortSelected
                                ? renderImage('activeBuzzer')
                                : renderImage('inactiveBuzzer')
                        }
                        style={{ top: '17.3%', width: '100%' }}
                    />
                </div>

                <div
                    className={`page2-content ${
                        isAnimating ? 'slide-out2' : ''
                    }`}
                    style={{ width: '100vw' }}
                >
                    {' '}
                    <div className="Digital-ports-Container">
                        <div className="digital-ButtonDivInput">
                            <div
                                className="digital-flow-left-upper"
                                style={{
                                    height: '235pt',
                                    backgroundSize: '260pt 300pt',
                                }}
                            >
                                <div className="digital-flow-left-upper-grp">
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">A1</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((A1DIGI &&
                                                    !a1Servo &&
                                                    !a1Servo270 &&
                                                    !a1Servo360 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id={'s1'}
                                            style={{ color: bttnColor[0] }}
                                        >
                                            <input
                                                active={a1Digi}
                                                // disabled={
                                                //   !A1DIGI ||
                                                //   a1Servo ||
                                                //   JSON.parse(sessionStorage.getItem("AUltra")) ||
                                                //   false
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleA1}
                                                checked={a1Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in1"
                                                    style={{
                                                        color: bttnColor2[0],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    {/* servo 180 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-button-servo-' +
                                                ((a1Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-button-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'a1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )
                                                }
                                                checked={a1Servo}
                                                onChange={ServoA1}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* servo 270 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo270-' +
                                                ((a1Servo270 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'a1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )
                                                }
                                                checked={a1Servo270}
                                                onChange={Servo270A1}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* servo 360 */}
                                    <label
                                        className={'upper-label-input-servo360'}
                                        style={{ display: 'Block' }}
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo-' +
                                                ((a1Servo360 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'a1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )
                                                }
                                                checked={a1Servo360}
                                                onChange={Servo360A1}
                                            ></input>
                                        </div>
                                    </label>
                                    <br />

                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">A2</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((A2DIGI &&
                                                    !a2Servo360 &&
                                                    !a2Servo270 &&
                                                    !a2Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id={'s2'}
                                            style={{ color: bttnColor[1] }}
                                        >
                                            <input
                                                active={a2Digi}
                                                // disabled={
                                                //   !A2DIGI ||
                                                //   a2Servo ||
                                                //   JSON.parse(sessionStorage.getItem("AUltra")) ||
                                                //   false
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleA2}
                                                checked={a2Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in2"
                                                    style={{
                                                        color: bttnColor2[1],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    {/* {(deviceVersion === '0.1.10' ||
                                        deviceVersion[0] == '2') &&
                                    ['Humanoid', 'Hexapod'].includes(
                                        connectedDevice
                                    ) ? ( */}

                                    {/* servo 180 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-button-servo-' +
                                                ((a2Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-button-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A2'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'a2-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )
                                                }
                                                checked={a2Servo}
                                                onChange={ServoA2}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* servo 270 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo270-' +
                                                ((a2Servo270 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'A2'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'a2-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'AUltra'
                                                        )
                                                    )
                                                }
                                                checked={a2Servo270}
                                                onChange={Servo270A2}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* ) : (
                                        ''
                                    )} */}
                                    {/* Servo 360 */}
                                    {/* {(deviceVersion === '0.1.10' ||
                                        deviceVersion[0] == '2') &&
                                    ['Humanoid', 'Hexapod'].includes(
                                        connectedDevice
                                    ) ? ( */}
                                    <>
                                        {' '}
                                        <label
                                            className={
                                                'upper-label-input-servo360'
                                            }
                                            style={{ display: 'Block' }}
                                        >
                                            <div
                                                className={
                                                    'switch-buttons-servo-' +
                                                    ((a2Servo360 &&
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'AUltra'
                                                            )
                                                        )) ||
                                                        false)
                                                }
                                                id="s2"
                                            >
                                                <input
                                                    className="switch-buttons-checkbox-servo"
                                                    type="checkbox"
                                                    disabled={
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'A2'
                                                            )
                                                        ) ||
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'a2-I/O'
                                                            )
                                                        ) ||
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'AUltra'
                                                            )
                                                        )
                                                    }
                                                    checked={a2Servo360}
                                                    onChange={Servo360A2}
                                                ></input>
                                            </div>
                                        </label>
                                    </>
                                    {/* ) : (
                                        <></>
                                    )} */}
                                </div>
                            </div>
                            <div
                                className="digital-flow-left-upper"
                                style={{
                                    height: '235pt',
                                    backgroundSize: '260pt 300pt',
                                }}
                            >
                                <div className="digital-flow-left-upper-grp">
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">B1</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((B1DIGI &&
                                                    !b1Servo360 &&
                                                    !b1Servo270 &&
                                                    !b1Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id={'s3'}
                                            style={{ color: bttnColor[2] }}
                                        >
                                            <input
                                                active={b1Digi}
                                                // disabled={
                                                //   !B1DIGI ||
                                                //   b1Servo ||
                                                //   JSON.parse(sessionStorage.getItem("BRGB")) ||
                                                //   JSON.parse(sessionStorage.getItem("BMP3")) ||
                                                //   false
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleB1}
                                                checked={b1Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in3"
                                                    style={{
                                                        color: bttnColor2[2],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-button-servo-' +
                                                ((b1Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-button-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'b1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )
                                                }
                                                checked={b1Servo}
                                                onChange={ServoB1}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* Servo 270 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo270-' +
                                                ((b1Servo270 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'b1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )
                                                }
                                                checked={b1Servo270}
                                                onChange={Servo270B1}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* Servo 360 */}
                                    <label
                                        className={'upper-label-input-servo360'}
                                        style={{ display: 'Block' }}
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo-' +
                                                ((b1Servo360 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'b1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )
                                                }
                                                checked={b1Servo360}
                                                onChange={Servo360B1}
                                            ></input>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">B2</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((B2DIGI &&
                                                    !b2Servo360 &&
                                                    !b2Servo270 &&
                                                    !b2Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id={'s4'}
                                            style={{ color: bttnColor[3] }}
                                        >
                                            <input
                                                active={b2Digi}
                                                // disabled={
                                                //   !B2DIGI ||
                                                //   b2Servo ||
                                                //   JSON.parse(sessionStorage.getItem("BRGB")) ||
                                                //   JSON.parse(sessionStorage.getItem("BMP3")) ||
                                                //   false
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleB2}
                                                checked={b2Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in4"
                                                    style={{
                                                        color: bttnColor2[3],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-button-servo-' +
                                                ((b2Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-button-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B2'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'b2-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )
                                                }
                                                checked={b2Servo}
                                                onChange={ServoB2}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* Servo 270 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo270-' +
                                                ((b2Servo270 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B2'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'b2-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )
                                                }
                                                checked={b2Servo270}
                                                onChange={Servo270B2}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* Servo 360 */}
                                    <label
                                        className={'upper-label-input-servo360'}
                                        style={{ display: 'Block' }}
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo-' +
                                                ((b2Servo360 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'B2'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'b2-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BRGB'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'BMP3'
                                                        )
                                                    )
                                                }
                                                checked={b2Servo360}
                                                onChange={Servo360B2}
                                            ></input>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div
                                style={{ width: '280pt', height: '60px' }}
                            ></div>
                        </div>

                        <div
                            className="digital-ButtonRightDivInput"
                            // style={{ marginTop: '50px' }}
                        >
                            <div
                                className="digital-flow-left-upper"
                                style={{
                                    height: '235pt',
                                    backgroundSize: '260pt 300pt',
                                }}
                            >
                                <div className="digital-flow-left-upper-grp">
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">C1</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((C1DIGI &&
                                                    !c1Servo360 &&
                                                    !c1Servo270 &&
                                                    !c1Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id={'s5'}
                                            style={{ color: bttnColor[4] }}
                                        >
                                            <input
                                                active={c1Digi}
                                                // disabled={
                                                //   !C1DIGI ||
                                                //   c1Servo ||
                                                //   JSON.parse(sessionStorage.getItem("CUltra")) ||
                                                //   false
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleC1}
                                                checked={c1Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in5"
                                                    style={{
                                                        color: bttnColor2[4],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-button-servo-' +
                                                ((c1Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-button-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'C1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'c1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )
                                                }
                                                checked={c1Servo}
                                                onChange={ServoC1}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* servo270 */}
                                    <label
                                        className={
                                            'input upper-label-input upper-label-input-servo'
                                        }
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo270-' +
                                                ((c1Servo270 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'C1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'c1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )
                                                }
                                                checked={c1Servo270}
                                                onChange={Servo270C1}
                                            ></input>
                                        </div>
                                    </label>
                                    {/* servo360 */}
                                    <label
                                        className={'upper-label-input-servo360'}
                                        style={{ display: 'Block' }}
                                    >
                                        <div
                                            className={
                                                'switch-buttons-servo-' +
                                                ((c1Servo360 &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s2"
                                        >
                                            <input
                                                className="switch-buttons-checkbox-servo"
                                                type="checkbox"
                                                disabled={
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'C1'
                                                        )
                                                    ) ||
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'c1-I/O'
                                                        )
                                                    ) ||
                                                    JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )
                                                }
                                                checked={c1Servo360}
                                                onChange={Servo360C1}
                                            ></input>
                                        </div>
                                    </label>
                                    <br />
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">C2</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((C2DIGI &&
                                                    !c2Servo360 &&
                                                    !c2Servo270 &&
                                                    !c2Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'CUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id={'s6'}
                                            style={{ color: bttnColor[5] }}
                                        >
                                            <input
                                                active={c2Digi}
                                                // disabled={
                                                //   !C2DIGI ||
                                                //   c2Servo ||
                                                //   JSON.parse(sessionStorage.getItem("CUltra")) ||
                                                //   false
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                onChange={toggleC2}
                                                checked={c2Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in6"
                                                    style={{
                                                        color: bttnColor2[5],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    {/* {(deviceVersion === '0.1.10' ||
                                        deviceVersion[0] == '2') &&
                                    ['Humanoid', 'Hexapod'].includes(
                                        connectedDevice
                                    ) ? ( */}
                                    <>
                                        <label
                                            className={
                                                'input upper-label-input upper-label-input-servo'
                                            }
                                        >
                                            <div
                                                className={
                                                    'switch-button-servo-' +
                                                    ((c2Servo &&
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'CUltra'
                                                            )
                                                        )) ||
                                                        false)
                                                }
                                                id="s2"
                                            >
                                                <input
                                                    className="switch-button-checkbox-servo"
                                                    type="checkbox"
                                                    disabled={
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'C2'
                                                            )
                                                        ) ||
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'c2-I/O'
                                                            )
                                                        ) ||
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'CUltra'
                                                            )
                                                        )
                                                    }
                                                    checked={c2Servo}
                                                    onChange={ServoC2}
                                                ></input>
                                            </div>
                                        </label>
                                        <label
                                            className={
                                                'input upper-label-input upper-label-input-servo'
                                            }
                                        >
                                            <div
                                                className={
                                                    'switch-buttons-servo270-' +
                                                    ((c2Servo270 &&
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'CUltra'
                                                            )
                                                        )) ||
                                                        false)
                                                }
                                                id="s2"
                                            >
                                                <input
                                                    className="switch-buttons-checkbox-servo"
                                                    type="checkbox"
                                                    disabled={
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'C2'
                                                            )
                                                        ) ||
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'c2-I/O'
                                                            )
                                                        ) ||
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'CUltra'
                                                            )
                                                        )
                                                    }
                                                    checked={c2Servo270}
                                                    onChange={Servo270C2}
                                                ></input>
                                            </div>
                                        </label>
                                        <label
                                            className={
                                                'upper-label-input-servo360'
                                            }
                                            style={{ display: 'Block' }}
                                        >
                                            <div
                                                className={
                                                    'switch-buttons-servo-' +
                                                    ((c2Servo360 &&
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'CUltra'
                                                            )
                                                        )) ||
                                                        false)
                                                }
                                                id="s2"
                                            >
                                                <input
                                                    className="switch-buttons-checkbox-servo"
                                                    type="checkbox"
                                                    disabled={
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'C2'
                                                            )
                                                        ) ||
                                                        !JSON.parse(
                                                            sessionStorage.getItem(
                                                                'c2-I/O'
                                                            )
                                                        ) ||
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                'CUltra'
                                                            )
                                                        )
                                                    }
                                                    checked={c2Servo360}
                                                    onChange={Servo360C2}
                                                ></input>
                                            </div>
                                        </label>
                                    </>
                                    {/* ) : (
                                        <></>
                                    )} */}
                                </div>
                            </div>
                            <div
                                className={
                                    deviceVersion === '0.1.10' &&
                                    ['Humanoid'].includes(connectedDevice)
                                        ? 'digital-flow-left-upper'
                                        : 'Digital-flow-left-upper'
                                }
                            >
                                <div
                                    className={
                                        deviceVersion === '0.1.10' &&
                                        ['Humanoid'].includes(connectedDevice)
                                            ? 'digital-flow-left-upper-grp'
                                            : 'Digital-flow-left-upper-grp'
                                    }
                                >
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">D1</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((D1DIGI &&
                                                    !d1Servo360 &&
                                                    !d1Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'DUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s7"
                                            style={{ color: bttnColor[6] }}
                                        >
                                            <input
                                                onChange={toggleD1}
                                                active={d1Digi}
                                                // disabled={
                                                //   !JSON.parse(sessionStorage.getItem("D1")) ||
                                                //   !JSON.parse(sessionStorage.getItem("d1-I/O")) ||
                                                //   JSON.parse(sessionStorage.getItem("DOLED"))
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                checked={d1Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in7"
                                                    style={{
                                                        color: bttnColor2[6],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>

                                    {deviceVersion.includes('0.1.1') &&
                                    ['Humanoid'].includes(connectedDevice) ? (
                                        <>
                                            <br></br>{' '}
                                            <label
                                                className={
                                                    'input upper-label-input upper-label-input-servo'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-button-servo-' +
                                                        ((d1Servo &&
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )) ||
                                                            false)
                                                    }
                                                    id="s2"
                                                >
                                                    <input
                                                        className="switch-button-checkbox-servo"
                                                        type="checkbox"
                                                        disabled={
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'D1'
                                                                )
                                                            ) ||
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )
                                                        }
                                                        checked={d1Servo}
                                                        onChange={ServoD1}
                                                    ></input>
                                                </div>
                                            </label>
                                            {/* Servo 360 */}
                                            <label
                                                className={
                                                    'input upper-label-input upper-label-input-servo'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-buttons-servo-' +
                                                        ((d1Servo360 &&
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )) ||
                                                            false)
                                                    }
                                                    id="s2"
                                                >
                                                    <input
                                                        className="switch-buttons-checkbox-servo"
                                                        type="checkbox"
                                                        disabled={
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'D1'
                                                                )
                                                            ) ||
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )
                                                        }
                                                        checked={d1Servo360}
                                                        onChange={Servo360D1}
                                                    ></input>
                                                </div>
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <br />
                                    <label
                                        className={'input upper-label-input'}
                                    >
                                        <span className="textsp">D2</span>
                                        <div
                                            className={
                                                'switch-button-' +
                                                ((D2DIGI &&
                                                    !d2Servo360 &&
                                                    !d2Servo &&
                                                    !JSON.parse(
                                                        sessionStorage.getItem(
                                                            'DUltra'
                                                        )
                                                    )) ||
                                                    false)
                                            }
                                            id="s8"
                                            style={{ color: bttnColor[7] }}
                                        >
                                            <input
                                                onClick={toggleD2}
                                                active={d2Digi}
                                                // disabled={
                                                //   !JSON.parse(sessionStorage.getItem("D2")) ||
                                                //   !JSON.parse(sessionStorage.getItem("d2-I/O")) ||
                                                //   JSON.parse(sessionStorage.getItem("DOLED"))
                                                // }
                                                className="switch-button-checkbox"
                                                type="checkbox"
                                                checked={d2Digi}
                                            ></input>
                                            <label
                                                className="switch-button-label"
                                                for=""
                                            >
                                                <span
                                                    className="switch-button-label-span"
                                                    id="in8"
                                                    style={{
                                                        color: bttnColor2[7],
                                                    }}
                                                >
                                                    Digital
                                                </span>
                                            </label>
                                        </div>
                                    </label>
                                    <br />
                                    {deviceVersion === '0.1.10' &&
                                    ['Humanoid'].includes(connectedDevice) ? (
                                        <>
                                            <label
                                                className={
                                                    'input upper-label-input upper-label-input-servo'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-button-servo-' +
                                                        ((d2Servo &&
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )) ||
                                                            false)
                                                    }
                                                    id="s2"
                                                >
                                                    <input
                                                        className="switch-button-checkbox-servo"
                                                        type="checkbox"
                                                        disabled={
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'D2'
                                                                )
                                                            ) ||
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )
                                                        }
                                                        checked={d2Servo}
                                                        onChange={ServoD2}
                                                    ></input>
                                                </div>
                                            </label>
                                            {/* Servo 360 */}
                                            <label
                                                className={
                                                    'input upper-label-input upper-label-input-servo'
                                                }
                                            >
                                                <div
                                                    className={
                                                        'switch-buttons-servo-' +
                                                        ((d2Servo360 &&
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )) ||
                                                            false)
                                                    }
                                                    id="s2"
                                                >
                                                    <input
                                                        className="switch-buttons-checkbox-servo"
                                                        type="checkbox"
                                                        disabled={
                                                            !JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'D2'
                                                                )
                                                            ) ||
                                                            JSON.parse(
                                                                sessionStorage.getItem(
                                                                    'DUltra'
                                                                )
                                                            )
                                                        }
                                                        checked={d2Servo360}
                                                        onChange={Servo360D2}
                                                    ></input>
                                                </div>
                                            </label>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            <div style={{ width: '280pt' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SelectScreenBottom">
                <div className="bottom-child">
                    {/* <Link to="/programSelection"> */}
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    {/* </Link> */}

                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('nextBtn')}
                        onClick={next}
                    />
                </div>
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '1000',
                        position: 'absolute',
                    }}
                >
                    <div
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '47%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                            width: '65vw',
                        }}
                    >
                        <img
                            src={renderPrgImage('digitalH')}
                            style={{ width: '100%' }}
                        />
                        <div
                            style={{
                                // height: "90px",
                                width: '26%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '47%',
                                left: '28.9%',
                                background: '#fff',
                                fontSize: '22px',
                                borderRadius: '35px',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <p
                                style={{
                                    backgroundColor: 'white',
                                    padding: '4px',
                                    fontSize: '1.8vh',
                                    textAlign: 'left',
                                    color: '#707070',
                                    fontFamily: 'Halcyon_Regular',
                                    borderRadius: '12px',
                                    marginBottom: '0px',
                                }}
                            >
                                Here you can enable whether the port should be
                                analog or digital in nature. You can enable the
                                ports as input or output- Analog or Digital
                            </p>
                        </div>
                    </div>

                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '18%',
                            left: '81%',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                </div>
            ) : null}

            <div
                className={`toast-msg 
        ${isToastActive ? 'toast-msg-active' : 'toast-msg-inactive'}`}
                ref={toastRef}
            >
                {toastMsg}
            </div>
        </div>
    )
}

// export default Digital;
const mapStateToProps = (state) => {
    console.log('mapStateToProps', state)

    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialAction: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HumanoidDA)
