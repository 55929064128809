import React, { useState } from 'react'
import { useEffect } from 'react'

import { connect } from 'react-redux'
import renderPrgImage from '../../source/programImg'

import {
    continousPABytesReadToggle,
    HUMANOID_PA_SEND_BYTES,
    HEXAPOD_PA_SEND_BYTES,
} from '../ReusableComponents/ByteTransfer'
import {
    humanoidPABytesToSensorData,
    hexapodPABytesToSensorData,
    generateDeviceDataKey,
    klawPABytesToSensorData,
} from '../ReusableComponents/ByteTransfer/utils'
import { EXTERNALDEVICES, PORTDATA } from './defaultData'

import Select from './helpers/Select'
import Slider from './helpers/Slider'

import './DecisionPanel.css'
import { HUMANOID_V1_PA_SEND_BYTES } from '../ReusableComponents/ByteTransfer/byteCodes'
var selectId = 0
const decisionOptions = [
    {
        name: 'Greater Than',
        activeImg: renderPrgImage('greaterthanActive'),
        inactiveImg: renderPrgImage('greaterthanInActive'),
        value: 'gt',
        id: 'hexcontent_div1',
        ids: 'hexsensors-txt1',
    },
    {
        name: 'Less Than',
        activeImg: renderPrgImage('lessthanActive'),
        inactiveImg: renderPrgImage('lessthanInActive'),
        value: 'lt',
        id: 'hexcontent_div2',
        ids: 'hexsensors-txt2',
    },
    {
        name: 'In Between',
        activeImg: renderPrgImage('inbetweenActive'),
        inactiveImg: renderPrgImage('inbetweenInActive'),
        value: 'bw',
        id: 'hexcontent_div3',
        ids: 'hexsensors-txt3',
    },
    {
        name: 'Equals To',
        activeImg: renderPrgImage('equaltoActive'),
        inactiveImg: renderPrgImage('equaltoInActive'),
        value: 'eq',
        id: 'hexcontent_div4',
        ids: 'hexsensors-txt4',
    },
    {
        name: 'Not Equals To',
        activeImg: renderPrgImage('notequaltoActive'),
        inactiveImg: renderPrgImage('notequaltoInActive'),
        value: 'ne',
        id: 'hexcontent_div5',
        ids: 'hexsensors-txt5',
    },
]

const DecisionPanel = (props) => {
    const connectedDevice = sessionStorage.getItem('connectedDevice') || '0.0.0'
    let deviceVersion = sessionStorage.getItem('deviceVersion') || ''
    const [programData, setProgramData] = useState(
        JSON.parse(sessionStorage.getItem('humanoidProgramData'))
    )
    const [isReading, setIsReading] = useState(false)
    const [readResponse, setReadResponse] = useState(false)
    const [readDevice, setReadDevice] = useState()

    const [minMax, setMinMax] = useState([0, 0])
    const startState = props.logic.program[0].state
    const [disabled, setDisabled] = useState(false)
    const [sliderValue, setSliderValue] = useState(0)
    const PORTDATAKey = generateDeviceDataKey(connectedDevice, deviceVersion)
    const EXTERNALDEVICESData =
        ['0', '1'].includes(deviceVersion[0]) &&
        ['Hexapod', 'Humanoid'].includes(connectedDevice)
            ? EXTERNALDEVICES['v1']
            : EXTERNALDEVICES['v2']
    useEffect(() => {
        sessionStorage.removeItem('readSetIntervalId')
    }, [])

    ///// PA bytes read function for Humanoid
    const handlecontinousPARead = async () => {
        props.worker.onmessage = (e) => {
            if (e.data.type == 'PAreadZH') setReadResponse(e.data.value)
        }
        if (
            !readDevice ||
            // readDevice == 'battery' ||
            !props.webSerial.isConnected
        )
            return
        sessionStorage.setItem('isReadingZingPA', !isReading)

        setIsReading(!isReading)
        if (!isReading === false) {
            return
        }
    }

    // //for zing 2.0 handel PA recived PA bytes
    // const recievedBytes_ZgV2=()=>{

    // }

    //for humanoid 2.0 send PA bytes
    const setPABytes_V2 = () => {
        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let sendBytes = Array(13).fill('0')

        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[11] = 'E'
        sendBytes[12] = 'R'
        let internalAccessories = props.concept.internalaccessories
        let portConnections = props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                port !== 'C' &&
                // port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                        case 'C1':
                            return 6
                        case 'C2':
                            return 7
                        // case 'D1':
                        //     return 8
                        // case 'D2':
                        //     return 9
                        // case 'E1':
                        //     return 10
                        // case 'E2':
                        //     return 11
                        // case 'F1':
                        //     return 12
                        // case 'F2':
                        //     return 13
                    }
                }

                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'touch_sensor' ||
                    Type == 'dip_switch' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })
        if (connectedDevice == 'Hexapod') {
            sendBytes[8] = humanoidPortData['24'].isPortSelected ? 'U' : '0'
            let isAMPUEnable = false
            for (let i = 0; i < 6; i++) {
                if (humanoidPortData[25 + i].isPortSelected) {
                    isAMPUEnable = true
                    break
                }
            }
            sendBytes[9] = isAMPUEnable ? 'A' : 'O'
            sendBytes[10] = humanoidPortData['23'].isPortSelected ? 'B' : '0'
        } else {
            sendBytes[8] = humanoidPortData['22'].isPortSelected ? 'U' : '0'
            // sendBytes[9] = humanoidPortData['4b'].isPortSelected
            //     ? 'S'.charCodeAt(0)
            //     : 'O'.charCodeAt(0)
            let isAMPUEnable = false
            for (let i = 0; i < 6; i++) {
                if (humanoidPortData[23 + i].isPortSelected) {
                    isAMPUEnable = true
                    break
                }
            }
            sendBytes[9] = isAMPUEnable ? 'A' : 'O'
            // sendBytes[9] = 'O'.charCodeAt(0)
            sendBytes[10] = humanoidPortData['21'].isPortSelected ? 'B' : '0'
        }

        sendBytes = sendBytes.toString().replaceAll(',', '')
        return sendBytes
    }

    //hexapod
    const setPABytes_Hexapod = () => {
        let sendBytes = [...HEXAPOD_PA_SEND_BYTES]

        const checkPortStatus = (port) => {
            if (sessionStorage.getItem('programMode') === 'flowchartBased')
                if (sessionStorage.getItem(port)) {
                    if (['A', 'C', 'D'].includes(port.slice(0, 1))) {
                        if (sessionStorage.getItem(`${port.slice(0, 1)}Ultra`))
                            return 'U'.charCodeAt(0)
                    }
                    if (!sessionStorage.getItem(`${port.toLowerCase()}-I/O`)) {
                        if (
                            [null, false].includes(
                                sessionStorage.getItem(`${port}DIGI`)
                            )
                        ) {
                            return 'I'.charCodeAt(0)
                        } else {
                            return 'A'.charCodeAt(0)
                        }
                    } else {
                        return 79
                    }
                } else {
                    return 79
                }
            else {
                var comp = props.assembly.PortConnections[port]
                if (comp === null) return 79
                if (
                    ['A1', 'A2', 'C1', 'C2', 'D1', 'D2'].includes(port) &&
                    props.assembly.PortConnections[port.slice(0, 1)].type ==
                        'ultrasonic_sensor'
                ) {
                    return 'U'.charCodeAt()
                } else if (
                    [
                        'tact_switch',
                        'touch_sensor',
                        'dual_switch',
                        'dip_switch',
                        'metal_detector',
                    ].includes(comp.type)
                ) {
                    return 'I'.charCodeAt()
                } else if (
                    [
                        'distance_sensor',
                        'temperature_sensor',
                        'gas',
                        'light_sensor',
                        'linear_pot',
                        'pot',
                        'rain_sensor',
                        'humidity',
                    ].includes(comp.type)
                ) {
                    return 'A'.charCodeAt()
                } else if (
                    ['rotatory', 'joystick', 'extender'].includes(comp.type)
                ) {
                    return 'A'.charCodeAt()
                } else {
                    return 'O'.charCodeAt(0)
                }
            }
        }

        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )

        sendBytes[2] = checkPortStatus('A1')
        sendBytes[3] = checkPortStatus('A2')
        sendBytes[4] = checkPortStatus('B1')
        sendBytes[5] = checkPortStatus('B2')
        sendBytes[6] = checkPortStatus('C1')
        sendBytes[7] = checkPortStatus('C2')

        sendBytes[8] = humanoidPortData['24'].isPortSelected
            ? 'U'.charCodeAt(0)
            : 'O'.charCodeAt(0)
        sendBytes[10] = humanoidPortData['23'].isPortSelected
            ? 'B'.charCodeAt(0)
            : 'O'.charCodeAt(0)

        let isAMPUEnable = false
        for (let i = 0; i < 6; i++) {
            if (humanoidPortData[25 + i].isPortSelected) {
                isAMPUEnable = true
                break
            }
        }
        sendBytes[9] = isAMPUEnable ? 'A'.charCodeAt(0) : 'O'.charCodeAt(0)
        return sendBytes
    }

    //humanoid 0.1 and 1.0
    const setPABytes_Humanoid = () => {
        const checkPortStatus = (port) => {
            if (sessionStorage.getItem('programMode') === 'flowchartBased')
                if (sessionStorage.getItem(port)) {
                    if (['A', 'C', 'D'].includes(port.slice(0, 1))) {
                        if (sessionStorage.getItem(`${port.slice(0, 1)}Ultra`))
                            return 'U'.charCodeAt(0)
                    }
                    if (!sessionStorage.getItem(`${port.toLowerCase()}-I/O`)) {
                        if (
                            [null, false].includes(
                                sessionStorage.getItem(`${port}DIGI`)
                            )
                        ) {
                            return 'I'.charCodeAt(0)
                        } else {
                            return 'A'.charCodeAt(0)
                        }
                    } else {
                        return 79
                    }
                } else {
                    return 79
                }
            else {
                var comp = props.assembly.PortConnections[port]
                if (comp === null) return 79
                if (
                    ['A1', 'A2', 'C1', 'C2', 'D1', 'D2'].includes(port) &&
                    props.assembly.PortConnections[port.slice(0, 1)].type ==
                        'ultrasonic_sensor'
                ) {
                    return 'U'.charCodeAt()
                } else if (
                    [
                        'tact_switch',
                        'touch_sensor',
                        'dual_switch',
                        'dip_switch',
                        'metal_detector',
                    ].includes(comp.type)
                ) {
                    return 'I'.charCodeAt()
                } else if (
                    [
                        'distance_sensor',
                        'temperature_sensor',
                        'gas',
                        'light_sensor',
                        'linear_pot',
                        'pot',
                        'rain_sensor',
                        'humidity',
                    ].includes(comp.type)
                ) {
                    return 'A'.charCodeAt()
                } else if (
                    ['rotatory', 'joystick', 'extender'].includes(comp.type)
                ) {
                    return 'A'.charCodeAt()
                } else {
                    return 'O'.charCodeAt(0)
                }
            }
        }
        if (deviceVersion[0] == '1') {
            let sendBytes = [...HUMANOID_V1_PA_SEND_BYTES]

            //for 1.0 zing PaByets

            let humanoidPortData = JSON.parse(
                sessionStorage.getItem('humanoidPortData')
            )
            console.log(checkPortStatus('C1'))
            sendBytes[2] = checkPortStatus('A1')
            sendBytes[3] = checkPortStatus('A2')
            sendBytes[4] = checkPortStatus('B1')
            sendBytes[5] = checkPortStatus('B2')
            sendBytes[6] = checkPortStatus('C1')
            sendBytes[7] = checkPortStatus('C2')

            sendBytes[8] = humanoidPortData['22'].isPortSelected
                ? 'U'.charCodeAt(0)
                : 'O'.charCodeAt(0)
            sendBytes[10] = humanoidPortData['21'].isPortSelected
                ? 'B'.charCodeAt(0)
                : 'O'.charCodeAt(0)

            let isAMPUEnable = false
            for (let i = 0; i < 6; i++) {
                if (humanoidPortData[23 + i].isPortSelected) {
                    isAMPUEnable = true
                    break
                }
            }
            sendBytes[9] = isAMPUEnable ? 'A'.charCodeAt(0) : 'O'.charCodeAt(0)

            console.log(sendBytes)
            return sendBytes
        } else {
            HUMANOID_PA_SEND_BYTES[2] = checkPortStatus('A1')
            HUMANOID_PA_SEND_BYTES[3] = checkPortStatus('A2')
            HUMANOID_PA_SEND_BYTES[4] = checkPortStatus('B1')
            HUMANOID_PA_SEND_BYTES[5] = checkPortStatus('B2')
            HUMANOID_PA_SEND_BYTES[6] = checkPortStatus('C1')
            HUMANOID_PA_SEND_BYTES[7] = checkPortStatus('C2')
            HUMANOID_PA_SEND_BYTES[8] = checkPortStatus('D1')
            HUMANOID_PA_SEND_BYTES[9] = checkPortStatus('D2')
            HUMANOID_PA_SEND_BYTES[10] = 'B'.charCodeAt(0)
            HUMANOID_PA_SEND_BYTES[11] = 'A'.charCodeAt(0)
            HUMANOID_PA_SEND_BYTES[12] = 'G'.charCodeAt(0)

            return HUMANOID_PA_SEND_BYTES
        }
    }

    //set PA bytes for Klaw
    const setPABytes_Klaw = () => {
        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let sendBytes = Array(15).fill('O')
        sendBytes[0] = 'P'
        sendBytes[1] = 'A'
        sendBytes[13] = 'E'
        sendBytes[14] = 'R'
        // let internalAccessories = this.props.concept.internalaccessories
        let portConnections = props.assembly.PortConnections
        Object.keys(portConnections).map((port) => {
            if (
                port !== 'A' &&
                port !== 'B' &&
                // port !== 'C' &&
                // port !== 'D' &&
                portConnections[port] !== null
            ) {
                const getIndex = (port) => {
                    switch (port) {
                        case 'A1':
                            return 2
                        case 'A2':
                            return 3
                        case 'B1':
                            return 4
                        case 'B2':
                            return 5
                    }
                }
                let arrIndex = getIndex(port)
                let Type = portConnections[port].type
                if (Type == 'ultrasonic_sensor') {
                    sendBytes[arrIndex] = 'U'
                } else if (
                    Type == 'tact_switch' ||
                    Type == 'dual_switch' ||
                    Type == 'metal_detector' ||
                    Type == 'touch_sensor' ||
                    Type == 'dip_switch' ||
                    Type == 'tact_switch_2c'
                ) {
                    sendBytes[arrIndex] = 'I'
                    sendBytes[arrIndex] = 'I'
                } else if (
                    Type == 'distance_sensor' ||
                    Type == 'temperature_sensor' ||
                    Type == 'gas' ||
                    Type == 'light_sensor' ||
                    Type == 'linear_pot' ||
                    Type == 'pot' ||
                    Type == 'rain_sensor' ||
                    Type == 'humidity'
                ) {
                    sendBytes[arrIndex] = 'A'
                } else if (
                    Type == 'rotatory' ||
                    Type == 'joystick' ||
                    Type == 'extender'
                ) {
                    sendBytes[arrIndex] = 'A'
                    sendBytes[arrIndex] = 'A'
                }
            }
        })

        //joystick1
        sendBytes[6] = humanoidPortData['0'].isPortSelected ? 'A' : 'O'
        sendBytes[7] = humanoidPortData['1'].isPortSelected ? 'A' : 'O'
        //joystick1
        sendBytes[8] = humanoidPortData['2'].isPortSelected ? 'A' : 'O'
        sendBytes[9] = humanoidPortData['3'].isPortSelected ? 'A' : 'O'
        //joystick1
        sendBytes[10] = humanoidPortData['4'].isPortSelected ? 'A' : 'O'
        sendBytes[11] = humanoidPortData['5'].isPortSelected ? 'A' : 'O'
        sendBytes[12] = 'O'
        //switch
        // sendBytes[12] = humanoidPortData['3'].isPortSelected ? 'I' : 'O'
        // sendBytes[13] = humanoidPortData['4'].isPortSelected ? 'I' : 'O'
        // sendBytes[14] = humanoidPortData['5'].isPortSelected ? 'I' : 'O'

        sendBytes = sendBytes.toString().replaceAll(',', '')
        let asciSendBytes = [...sendBytes].map((char) => char.charCodeAt(0))
        return asciSendBytes
    }

    //for 2.0 handel PA read
    const handleReadByte = async () => {
        props.worker.onmessage = (e) => {
            if (['PAreadZH', 'PAreadKlaw'].includes(e.data.type))
                setReadResponse(e.data.value)
        }
        // const M8 = new Uint8Array(['M'.charCodeAt(0), '8'.charCodeAt(0)])
        // this.setState({ isRead_v1: !this.state.isRead_v1 })
        // sessionStorage.setItem('isRead', !isReading)
        sessionStorage.setItem('isReadingZingPA', !isReading)
        setIsReading(!isReading)
        if (
            !readDevice ||
            readDevice == 'battery' ||
            !props.webSerial.isConnected
        )
            return
        // sessionStorage.setItem('isReadingZingPA', !isReading)

        if (!isReading === false) {
            return
        }
        while (true) {
            if (
                !JSON.parse(sessionStorage.getItem('isReadingZingPA')) ||
                !props.webSerial.isConnected
            ) {
                props.worker.postMessage({
                    type: 'write',
                    value: 'M8',
                })
                // sessionStorage.removeItem('isReadingZingPA')
                return
            } else {
                if (
                    connectedDevice == 'Hexapod' ||
                    deviceVersion[0] == '1'
                    //deviceVersion[0] == '2'
                ) {
                    let send_bytes
                    if (connectedDevice == 'Klaw') {
                        send_bytes = setPABytes_Klaw()
                    } else if (connectedDevice == 'Hexapod') {
                        send_bytes = setPABytes_Hexapod()
                    } else {
                        send_bytes = setPABytes_Humanoid()
                    }
                    props.worker.postMessage({
                        type: 'writeArray',
                        value: send_bytes,
                    })
                } else {
                    props.worker.postMessage({
                        type: 'write',
                        value: setPABytes_V2(),
                    })
                }
                await new Promise((resolve) => setTimeout(resolve, 100))
            }
        }
    }

    const handleDecision = (val, type) => {
        const newProgramData = JSON.parse(JSON.stringify(programData))

        /*Logic block to reset the Slider when the Port is changed in Decision popup
            Additional check added to ensure the Slider is not reset when there are more than one decision blocks
            - Code Start*/
        if (type === 'port') {
            if (selectId === 0) {
                selectId = props.check
            } else if (
                selectId === props.check &&
                (newProgramData[props.check].state.value !== 0 ||
                    newProgramData[props.check].state.value2 !== 0) &&
                newProgramData[props.check].state[type] != val
            ) {
                newProgramData[props.check].state.value = 0
                newProgramData[props.check].state.value2 = 0
            } else {
                selectId = props.check
            }
        }
        //Code End - PLAYC-689 Bug Fix

        newProgramData[props.check].state[type] = val

        if (type === 'port') {
            // internal ports
            if (programData[props.check].state['port'] != val) {
                newProgramData[props.check].state['condition'] = 'gt'
                updateReduxLogic(['condition'], ['gt'])
            }
            if (val === 'battery') {
                updateReduxLogic(['source'], [val])
                setMinMax([0, 0])
            } else if (parseInt(val) > 51 && parseInt(val) < 65) {
                setMinMax([
                    PORTDATA[PORTDATAKey][val].min,
                    PORTDATA[PORTDATAKey][val].max,
                ])
                updateReduxLogic(['source'], [PORTDATA[PORTDATAKey][val].name])
            } else if (parseInt(val) >= 0 && parseInt(val) <= 30) {
                console.log('coming hrerrewrew')
                setMinMax([
                    PORTDATA[PORTDATAKey][val].min,
                    PORTDATA[PORTDATAKey][val].max,
                ])
                updateReduxLogic(
                    ['source'],
                    [
                        PORTDATA[PORTDATAKey][val].name
                            .replaceAll(' ', '_')
                            .toLowerCase(),
                    ]
                )

                newProgramData[props.check].state['value'] = Math.min(
                    newProgramData[props.check].state['value'],
                    PORTDATA[PORTDATAKey][val].max
                )

                // flowchart external ports[
            } else {
                const curPort =
                    props.assembly.PortConnections[
                        PORTDATA[PORTDATAKey][val].name.split(' ')[1]
                    ]

                if (curPort == null) {
                    const portToVarMap = {
                        31: 'A1',
                        32: 'A2',
                        33: 'B1',
                        34: 'B2',
                        35: 'C1',
                        36: 'C2',
                        37: 'D1',
                        38: 'D2',
                    }
                    var inputType = JSON.parse(
                        sessionStorage.getItem(
                            `${portToVarMap[val].slice(0, 1)}Ultra`
                        )
                    )
                        ? 'Ultra'
                        : [null, false].includes(
                              JSON.parse(
                                  sessionStorage.getItem(
                                      `${portToVarMap[val]}DIGI`
                                  )
                              )
                          )
                        ? 'Digital'
                        : 'Analog'
                    if (inputType === 'Digital') {
                        setMinMax([0, 1])
                    } else if (inputType === 'Ultra') {
                        setMinMax([0, 400])
                    } else {
                        setMinMax([0, 1024])
                    }

                    updateReduxLogic(
                        ['source'],
                        [
                            [
                                PORTDATA[PORTDATAKey][val].name
                                    .replaceAll(' ', '_')
                                    .toLowerCase(),
                            ],
                        ]
                    )
                } else {
                    const externalDevice = curPort.type
                    setMinMax([
                        EXTERNALDEVICESData[externalDevice].min,
                        EXTERNALDEVICESData[externalDevice].max,
                    ])
                    if (
                        deviceVersion[0] == '2' &&
                        EXTERNALDEVICESData[externalDevice].type == 'input' &&
                        EXTERNALDEVICESData[externalDevice].max == 1024
                    ) {
                        setMinMax([
                            EXTERNALDEVICESData[externalDevice].min,
                            4095,
                        ])
                    }
                    updateReduxLogic(
                        ['source'],
                        [PORTDATA[PORTDATAKey][val].name.split(' ')[1]]
                    )

                    newProgramData[props.check].state['value'] = Math.min(
                        newProgramData[props.check].state['value'],
                        EXTERNALDEVICESData[externalDevice].max
                    )
                }
            }
        } else if (type === 'condition') {
            updateReduxLogic([type], [val])
        } else {
            updateReduxLogic([type], [val])
            setSliderValue(val)
        }

        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    const findNodeAndUpdate = (program, id, keys, values) => {
        for (let node = 0; node < program.length; node++) {
            if (program[node].nodeId === id) {
                for (let i = 0; i < keys.length; i++) {
                    program[node].state[keys[i]] = values[i]
                }
                break
            }
            if (
                program[node].type === 'condition' ||
                program[node].type === 'sensor' ||
                program[node].type === 'loop'
            ) {
                findNodeAndUpdate(program[node].subprogram, id, keys, values)
            }
        }
    }

    const updateReduxLogic = (keys, values) => {
        let newLogic = JSON.parse(JSON.stringify(props.logic))
        findNodeAndUpdate(newLogic.program, props.check, keys, values)
        props.updateLogic(newLogic)
    }

    let sourceOptionsOrder = []
    Object.keys(startState).forEach((name) => {
        if (name == 'usbrx') {
            if (startState[name]) {
                var positionPorts = ['USB RX']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        }
        if (name == 'btRx') {
            if (startState[name]) {
                var positionPorts = ['BT RX1']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
            if (startState[name]) {
                var positionPorts = ['BT RX2']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
            if (startState[name]) {
                var positionPorts = ['BT RX3']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
            if (startState[name]) {
                var positionPorts = ['BT RX4']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
            if (startState[name]) {
                var positionPorts = ['BT RX5']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        }

        if (name === 'btRte') {
            if (startState[name]) {
                var positionPorts = ['BT Remote']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        } else if (name == 'bic1') {
            if (startState[name]) {
                var positionPorts = ['Bicounter1']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }

            // sourceOptionsOrder.push("Bicounter1");
        } else if (name == 'bic2') {
            if (startState[name]) {
                var positionPorts = ['Bicounter2']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        } else if (name == 'bif1') {
            if (startState[name]) {
                var positionPorts = ['Biflag1']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        } else if (name == 'bif2') {
            if (startState[name]) {
                var positionPorts = ['Biflag2']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        } else if (name == 'bid1') {
            if (startState[name]) {
                var positionPorts = ['BiData1']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        } else if (name == 'bid2') {
            if (startState[name]) {
                var positionPorts = ['BiData2']
                for (var i = 0; i < positionPorts.length; i++) {
                    sourceOptionsOrder.push(positionPorts[i])
                }
            }
        }
    })

    return (
        <div
            className={
                props.showImage == 'false'
                    ? 'outertabDiv-Condition-nobackground'
                    : 'outertabDiv-Conditions'
            }
        >
            <div className="outertabDiv-subConditions">
                {/* First */}
                <div
                    style={{ position: 'relative' }}
                    className="hexsub_Container1"
                >
                    <div
                        id="hexcontent1_text1"
                        style={{
                            color: 'black',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '7%',
                                transform: 'translate(0%, -50%)',
                            }}
                        >
                            If the value of
                        </p>
                    </div>
                    <div
                        id="hexcontent1_text2"
                        style={{
                            color: 'black',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '0%',
                                transform: 'translate(0%, -50%)',
                                width: '100%',
                                height: '40%',
                                borderRadius: '15px',
                                fontFamily: 'Halcyon_Medium',
                            }}
                        >
                            <Select
                                onChange={(event) => {
                                    handleDecision(event.target.value, 'port')
                                }}
                                componetName="flowchart"
                                selected={programData[props.check].state.port}
                                check={props.check}
                                assembly={props.assembly}
                                sourceOptionsOrder={sourceOptionsOrder}
                            />
                        </div>
                    </div>
                    <div
                        id="hexcontent1_text3"
                        style={{
                            color: 'black',
                        }}
                    >
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '7%',
                                transform: 'translate(0, -50%)',
                            }}
                        >
                            is
                        </p>
                    </div>
                </div>

                {/* Second */}
                <div className="hexsub_Container2">
                    {decisionOptions.map((decision) => (
                        <>
                            <div key={decision.value} id={decision.id}>
                                <img
                                    src={
                                        programData[props.check].state
                                            .condition == decision.value
                                            ? decision.activeImg
                                            : decision.inactiveImg
                                    }
                                    onClick={() =>
                                        handleDecision(
                                            decision.value,
                                            'condition'
                                        )
                                    }
                                />
                            </div>
                            <div id={decision.ids}>
                                <p>{decision.name}</p>
                            </div>
                        </>
                    ))}
                </div>

                {/* Third */}
                <div className="hexsub_Container3">
                    <div>
                        <div
                            style={
                                programData[props.check].state.condition ===
                                'bw'
                                    ? {
                                          top: '48%',
                                          width: '100%',
                                          position: 'absolute',
                                          transform: 'translate(0, -50%)',
                                      }
                                    : {
                                          top: '55%',
                                          width: '100%',
                                          position: 'absolute',
                                          transform: 'translate(0, -50%)',
                                      }
                            }
                        >
                            {' '}
                            <Slider
                                value={
                                    programData[props.check].state.value
                                    // sliderValue
                                }
                                onChange={(value) =>
                                    handleDecision(value, 'value')
                                }
                                min={minMax[0]}
                                max={minMax[1]}
                                renderIn="conditionPropertyPanel"
                                disabled={disabled}
                            />
                        </div>

                        <div
                            style={
                                programData[props.check].state.condition ===
                                'bw'
                                    ? {
                                          position: 'absolute',
                                          top: '55.5%',
                                          left: '21%',
                                          transform: 'translate(-50%,-50%)',
                                      }
                                    : {
                                          position: 'absolute',
                                          top: '62.5%',
                                          left: '21%',
                                          transform: 'translate(-50%,-50%)',
                                      }
                            }
                        >
                            <p
                                style={{
                                    position: 'absolute',
                                    color: 'black',
                                }}
                            >
                                {minMax[0]}
                            </p>
                        </div>

                        <div
                            style={
                                programData[props.check].state.condition ===
                                'bw'
                                    ? {
                                          position: 'absolute',
                                          top: '55.5%',
                                          left: '83%',
                                          transform: 'translate(-50%,-50%)',
                                      }
                                    : {
                                          position: 'absolute',
                                          top: '62.5%',
                                          left: '83%',
                                          transform: 'translate(-50%,-50%)',
                                      }
                            }
                        >
                            <p
                                style={{
                                    position: 'absolute',
                                    color: 'black',
                                }}
                            >
                                {minMax[1]}
                            </p>
                        </div>
                    </div>

                    {programData[props.check].state.condition === 'bw' && (
                        <div className="hexsub_Container3">
                            <div
                                style={{
                                    top: '63%',
                                    width: '100%',
                                    position: 'absolute',
                                    transform: 'translate(0, -50%)',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        position: 'absolute',
                                        transform: 'translate(54%, -106%)',
                                    }}
                                >
                                    and
                                </div>{' '}
                                <Slider
                                    value={
                                        programData[props.check].state.value2 !=
                                            null ||
                                        programData[props.check].state.value2 !=
                                            undefined
                                            ? programData[props.check].state
                                                  .value2
                                            : 0
                                    }
                                    onChange={(value) =>
                                        handleDecision(value, 'value2')
                                    }
                                    min={minMax[0]}
                                    max={minMax[1]}
                                    renderIn="conditionPropertyPanel"
                                />
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '71.3%',
                                    left: '21%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        position: 'absolute',
                                        color: 'black',
                                    }}
                                >
                                    {minMax[0]}
                                </p>
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '71.3%',
                                    left: '83%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        position: 'absolute',
                                        color: 'black',
                                    }}
                                >
                                    {minMax[1]}
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Fourth */}
                <div className="hexsub_Container4">
                    <div id="hexcontent5_text1">
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '7%',
                                transform: 'translate(0%, -50%)',
                            }}
                        >
                            Read the
                        </p>
                    </div>
                    <div id="hexcontent5_text2">
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '0%',
                                transform: 'translate(0%, -50%)',
                                width: '100%',
                                height: '55%',
                                borderRadius: '15px',
                            }}
                        >
                            <Select
                                onChange={(event) => {
                                    return setReadDevice(event.target.value)
                                }}
                                componetName="flowchart"
                                selected={readDevice}
                                check={props.check}
                                assembly={props.assembly}
                                sourceOptionsOrder={sourceOptionsOrder}
                            />
                        </div>
                    </div>

                    <div id="hexcontent5_text3">
                        <div
                            className="hexsub1_content5"
                            style={{
                                background: `${
                                    isReading ? '#fafafa' : '#30A8CE'
                                }`,
                                color: `${isReading ? '#000' : '#fff'}`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                handleReadByte(e)
                            }}
                        >
                            {isReading ? (
                                <p style={{ marginTop: '18%' }}>
                                    {sessionStorage.getItem(
                                        'connectedDevice'
                                    ) == 'Humanoid'
                                        ? humanoidPABytesToSensorData(
                                              readResponse,
                                              readDevice
                                          )
                                        : sessionStorage.getItem(
                                              'connectedDevice'
                                          ) == 'Hexapod'
                                        ? hexapodPABytesToSensorData(
                                              readResponse,
                                              readDevice
                                          )
                                        : klawPABytesToSensorData(
                                              readResponse,
                                              readDevice
                                          )}
                                </p>
                            ) : (
                                'Read'
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLogic: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DecisionPanel)
