import React, { useEffect, useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import NavBar from '../NavBar'
import { useHistory } from 'react-router'
import * as tf from '@tensorflow/tfjs'
import selectBarfive from '../../../Assets/Bisoft_UI/AppMode/selectBarfive.png'
import pause from '../../../Assets/Bisoft_UI/Buttons/Active with shadow/pause_btn@3x.png'
import backY from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import play from '../../../Assets/Bisoft_UI/Buttons/Active with shadow/play-run@2x.png'
import cameraP5 from '../../../Assets/Bisoft_UI/AppMode/camera(speech).png'
import popupCardfive from '../../../Assets/Bisoft_UI/AppMode/popupCardfive.png'
import popupCardone from '../../../Assets/Bisoft_UI/AppMode/popupCardone.png'
import projectbig from '../../../Assets/Bisoft_UI/AppMode/projectBig.png'
import musicIcon from '../../../Assets/Bisoft_UI/AppMode/musicIcon.png'
import close from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import styles from '../Appmode.module.css'
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition'
import {
    readBytesAppMode,
    sendBytes,
} from '../../ReusableComponents/ByteTransfer/byteTransfer'
import closeBtn from '../../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../../source/programImg'
import { connect } from 'react-redux'
let mobilenet = undefined
let send = false
let predict = false
let CLASS_NAMES = []
let classColor = []
let trainingDataInputs = []
let trainingDataOutputs = []
let model

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

const TeachableMachine = (props) => {
    const allDetails = JSON.parse(sessionStorage.getItem('allDetails'))
    const enable = JSON.parse(sessionStorage.getItem('enable'))
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice') || ''
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const [isPlay, setPlay] = useState(false)
    const [stateload, setStateload] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [popup, setPopup] = useState(false)
    const [display, setDisplay] = useState(true)
    const [image, setImage] = useState(popupCardfive)
    const [detected, setDetected] = useState()
    const [text, setText] = useState('value')
    const [size, setSize] = useState(0)
    const [imageDetails, setImageDetails] = useState('')
    const [videoLoaded, setvideoLoaded] = useState(false)
    const [faceDetected, setFaceDetected] = useState('Unknown')
    const [results, setResults] = useState()
    const [tensors, setTensors] = useState(false)
    const [select, setSelect] = useState('None')
    const [detectionsPopup, setDetectionsPopup] = useState(false)
    const [prediction, setPrediction] = useState([])
    const { getAll } = useIndexedDB('SavedFaces')
    const { getByID } = useIndexedDB('ProgramData')
    const history = useHistory()
    const MOBILE_NET_INPUT_WIDTH = 224
    const MOBILE_NET_INPUT_HEIGHT = 224
    const videoRef = React.useRef()
    const interval = React.useRef()
    const readBytesInterval = React.useRef()
    const { interimTranscript } = useSpeechRecognition()
    const [isFaceDetected, setIsFaceDetected] = useState(false)
    const startListening = () =>
        SpeechRecognition.startListening({ continuous: true })

    const [ishelpButton, sethelpButton] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    const loadmodels = async () => {
        const URL =
            'https://tfhub.dev/google/tfjs-model/imagenet/mobilenet_v3_small_100_224/feature_vector/5/default/1'
        mobilenet = await tf
            .loadGraphModel(URL, { fromTFHub: true })
            .then(setStateload(true))
    }

    useEffect(() => {
        if (stateload && dataLoaded && videoLoaded) {
            interval.current = window.setInterval(() => {
                predictLoop()
            }, 1000)
        }
    }, [stateload, videoLoaded, dataLoaded])
    useEffect(() => {
        if (isPlay === true) {
            getAll().then((result) => {
                setImageDetails(result)
                setDataLoaded(true)
            })
        }
        if (
            isPlay === true &&
            enable.s1.pc === true &&
            ((deviceVersion?.startsWith('1') &&
                ['Ace'].includes(connectedDevice)) ||
                deviceVersion?.startsWith('2') ||
                connectedDevice == 'Roboki')
        ) {
            props.worker.postMessage({
                type: 'writeArray',
                value: [85, 83, 0],
            })
            send = true
        }
        if (
            isPlay === true &&
            enable.s1.pc === true &&
            !(deviceVersion?.startsWith('1') && connectedDevice === 'Ace') &&
            !(
                ['Humanoid', 'Hexapod'].includes(connectedDevice) &&
                deviceVersion?.startsWith('2')
            ) &&
            connectedDevice !== 'Roboki'
        ) {
            sendBytes([85, 83, 0], props.webSerial.port)
            send = true
            readBytesInterval.current = setInterval(async () => {
                let bytes
                if (send === true) {
                    bytes = await readBytesAppMode(props.webSerial.port, '')
                    send = false
                    if (bytes !== undefined && bytes.split('\n').length >= 2) {
                        let length = bytes.split('\n').length
                        bytes = bytes.split('\n')[length - 1]
                    }
                    if (
                        bytes !== undefined &&
                        2 < bytes.length <= 5 &&
                        bytes.slice(0, 2) === 'US'
                    ) {
                        Object.keys(allDetails).map((value) => {
                            if (allDetails[value].usbValue === bytes.slice(2)) {
                                if (allDetails[value].displayText !== null) {
                                    setText(allDetails[value].displayText)
                                }
                                if (allDetails[value].image !== null) {
                                    getByID(allDetails[value].id).then(
                                        (event) => {
                                            setImage(event.imageURL)
                                        }
                                    )
                                }
                                if (allDetails[value].sliderOption === 'TX1') {
                                    sendBytes(
                                        [
                                            85,
                                            83,
                                            Number(
                                                allDetails[value].sliderValue
                                            ),
                                        ],
                                        props.webSerial.port
                                    )
                                    send = true
                                }
                                setSize(allDetails[value].selectedSize)
                            }
                        })
                    }
                }
            }, 10000)
        }

        return () => {
            props.worker.onmessage = (e) => {
                if (e.data.type === 'Appread') {
                    if (isPlay) return
                    console.log('gsk value', e.data.value)
                    let bytes = e.data.value
                    Object.keys(allDetails).map((value) => {
                        if (allDetails[value].usbValue == bytes[3]) {
                            if (allDetails[value].displayText !== null) {
                                setText(allDetails[value].displayText)
                            }
                            if (allDetails[value].image !== null) {
                                getByID(allDetails[value].id).then((event) => {
                                    setImage(event.imageURL)
                                })
                            }
                            if (allDetails[value].sliderOption === 'TX1') {
                                props.worker.postMessage({
                                    type: 'writeArray',
                                    value: [
                                        85,
                                        83,
                                        Number(allDetails[value].sliderValue),
                                    ],
                                })
                                send = true
                            }
                            setSize(allDetails[value].selectedSize)
                        }
                    })
                }
            }
        }
    }, [isPlay])

    useEffect(() => {
        if (enable.s1.mic === true && isPlay === true) {
            setTimeout(() => {
                setResults('listening...')
            }, 1000)
        }
    }, [results, isPlay])
    let face = false
    useEffect(() => {
        if (dataLoaded === true) {
            CLASS_NAMES = []
            trainingDataInputs = []
            trainingDataOutputs = []
            let count = 0
            Object.keys(allDetails).map((value) => {
                if (allDetails[value].option === 'Face Detected') {
                    setIsFaceDetected(true)
                    face = true
                    imageDetails.map((image) => {
                        if (image.id === allDetails[value].selectedObject) {
                            CLASS_NAMES.push(image.id)
                            classColor.push(
                                '#' +
                                    Math.floor(
                                        Math.random() * 16777215
                                    ).toString(16)
                            )
                            let tensors = JSON.parse(image.imagetensors)
                            tensors.map((tensor) => {
                                trainingDataInputs.push(tf.tensor(tensor))
                                trainingDataOutputs.push(count)
                            })
                            count += 1
                            console.log(
                                CLASS_NAMES,
                                trainingDataInputs,
                                trainingDataOutputs
                            )
                        }
                    })
                }
            })
            if (!face) {
                return
            }
            if (CLASS_NAMES.length === 1) {
                CLASS_NAMES.push('UnKnown')
                classColor.push(
                    '#' + Math.floor(Math.random() * 16777215).toString(16)
                )
                setTensors(true)
            } else {
                setTensors(true)
            }
            model = tf.sequential()
            model.add(
                tf.layers.dense({
                    inputShape: [1024],
                    units: 128,
                    activation: 'relu',
                })
            )
            model.add(
                tf.layers.dense({
                    units: CLASS_NAMES.length,
                    activation: 'softmax',
                })
            )

            model.summary()

            // Compile the model with the defined optimizer and specify a loss function to use.
            model.compile({
                // Adam changes the learning rate over time which is useful.
                optimizer: 'adam',
                // Use the correct loss function. If 2 classes of data, must use binaryCrossentropy.
                // Else categoricalCrossentropy is used if more than 2 classes.
                loss:
                    CLASS_NAMES.length === CLASS_NAMES.length
                        ? 'binaryCrossentropy'
                        : 'categoricalCrossentropy',
                // As this is a classification problem you can record accuracy in the logs too!
                metrics: ['accuracy'],
            })
        }
    }, [dataLoaded])

    useEffect(() => {
        if (!isFaceDetected) {
            return
        }
        if (videoLoaded === true && dataLoaded === true) {
            setTimeout(() => {
                trainAndPredict(trainingDataInputs, trainingDataOutputs)
            }, 2500)
        }
    }, [videoLoaded, dataLoaded, isFaceDetected])

    useEffect(() => {
        if (interimTranscript !== '') {
            setResults(interimTranscript)
            Object.entries(allDetails).map((i) => {
                if (i[1].speechText !== null && i[1].speechText !== '') {
                    if (
                        i[1].speechText.toLowerCase() ===
                        interimTranscript.trim().toLowerCase()
                    ) {
                        if (allDetails[i[1].id].displayText !== null) {
                            setText(allDetails[i[1].id].displayText)
                        }
                        if (allDetails[i[1].id].image !== null) {
                            getByID(allDetails[i[1].id].id).then((event) => {
                                setImage(event.imageURL)
                            })
                        }
                        if (
                            enable.s1.pc === true &&
                            allDetails[i[1].id].sliderOption === 'TX1'
                        ) {
                            if (
                                deviceVersion?.startsWith('2') ||
                                (deviceVersion?.startsWith('1') &&
                                    ['Ace'].includes(connectedDevice)) ||
                                deviceVersion == 'Roboki'
                            ) {
                                props.worker.postMessage({
                                    type: 'writeArray',
                                    value: [
                                        85,
                                        83,
                                        Number(allDetails[i[1].id].sliderValue),
                                    ],
                                })
                            } else {
                                sendBytes(
                                    [
                                        85,
                                        83,
                                        Number(allDetails[i[1].id].sliderValue),
                                    ],
                                    props.webSerial.port
                                )
                            }
                            send = true
                        }
                        setSize(allDetails[i[1].id].selectedSize)
                    }
                }
                return null
            })
        }
        // else {
        //     setImage(popupCardfive)
        //     setText('value')
        // }
    }, [interimTranscript])

    const calculateFeaturesOnCurrentFrame = () => {
        return tf.tidy(function () {
            // Grab pixels from current VIDEO frame.
            // if (videoRef.current !== null && videoLoaded) {
            let videoFrameAsTensor = tf.browser.fromPixels(videoRef.current)

            // Resize video frame tensor to be 224 x 224 pixels which is needed by MobileNet for input.
            let resizedTensorFrame = tf.image.resizeBilinear(
                videoFrameAsTensor,
                [MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH],
                true
            )

            let normalizedTensorFrame = resizedTensorFrame.div(255)
            if (mobilenet == undefined) return
            return mobilenet
                .predict(normalizedTensorFrame.expandDims())
                .squeeze()
            // }
        })
    }

    const trainAndPredict = async (trainingDataInputs, outputs) => {
        console.log(CLASS_NAMES.length, trainingDataInputs, trainingDataOutputs)
        tf.util.shuffleCombo(trainingDataInputs, outputs)

        let outputsAsTensor = tf.tensor1d(outputs, 'int32')
        let oneHotOutputs = tf.oneHot(outputsAsTensor, CLASS_NAMES.length)
        let inputsAsTensor = tf.stack(trainingDataInputs)

        await model.fit(inputsAsTensor, oneHotOutputs, {
            shuffle: true,
            batchSize: 5,
            epochs: 10,
            callbacks: { onEpochEnd: logProgress },
        })

        outputsAsTensor.dispose()
        oneHotOutputs.dispose()
        inputsAsTensor.dispose()

        predict = true
        // setPredict(true);
        predictLoop()
    }

    const logProgress = (epoch, logs) => {
        console.log('Data for epoch ' + epoch, logs)
    }

    const predictLoop = () => {
        if (predict && videoRef.current !== null) {
            tf.tidy(function () {
                let imageFeatures = calculateFeaturesOnCurrentFrame()
                if (imageFeatures == undefined) return
                let prediction = model
                    .predict(imageFeatures.expandDims())
                    .squeeze()
                let highestIndex = prediction.argMax().arraySync()
                let predictionArray = prediction.arraySync()
                setPrediction(predictionArray)
                console.log(
                    'Prediction: ' +
                        CLASS_NAMES[highestIndex] +
                        ' with ' +
                        Math.floor(predictionArray[highestIndex] * 100) +
                        '% confidence'
                )
                if (Math.floor(predictionArray[highestIndex] * 100) >= 95) {
                    if (CLASS_NAMES[highestIndex] !== 'Unknown') {
                        Object.keys(allDetails).map((key) => {
                            if (
                                allDetails[key].selectedObject ===
                                CLASS_NAMES[highestIndex]
                            ) {
                                setDetected(
                                    CLASS_NAMES[highestIndex] + ' is detected'
                                )
                                if (allDetails[key].displayText !== null) {
                                    setText(allDetails[key].displayText)
                                }
                                if (allDetails[key].image !== null) {
                                    getByID(allDetails[key].id).then(
                                        (event) => {
                                            setImage(event.imageURL)
                                        }
                                    )
                                }
                                if (
                                    enable.s1.pc === true &&
                                    allDetails[key].sliderOption === 'TX1'
                                ) {
                                    if (
                                        deviceVersion?.startsWith('2') ||
                                        (deviceVersion?.startsWith('1') &&
                                            ['Ace'].includes(
                                                connectedDevice
                                            )) ||
                                        connectedDevice == 'Roboki'
                                    ) {
                                        props.worker.postMessage({
                                            type: 'writeArray',
                                            value: [
                                                85,
                                                83,
                                                Number(
                                                    allDetails[key].sliderValue
                                                ),
                                            ],
                                        })
                                    } else {
                                        sendBytes(
                                            [
                                                85,
                                                83,
                                                Number(
                                                    allDetails[key].sliderValue
                                                ),
                                            ],
                                            props.webSerial.port
                                        )
                                    }
                                    send = true
                                }
                                setSize(allDetails[key].selectedSize)
                            }
                            return ''
                        })
                    }
                } else {
                    if (CLASS_NAMES[highestIndex] !== 'Unknown') {
                        Object.keys(allDetails).map((key) => {
                            if (
                                allDetails[key].selectedObject ===
                                CLASS_NAMES[highestIndex]
                            ) {
                                setDetected(
                                    CLASS_NAMES[highestIndex] + ' is detected'
                                )
                                if (allDetails[key].displayText !== null) {
                                    setText(allDetails[key].displayText)
                                }
                                if (allDetails[key].image !== null) {
                                    getByID(allDetails[key].id).then(
                                        (event) => {
                                            setImage(event.imageURL)
                                        }
                                    )
                                }
                                if (
                                    enable.s1.pc === true &&
                                    allDetails[key].sliderOption === 'TX1'
                                ) {
                                    if (
                                        deviceVersion?.startsWith('2') ||
                                        (deviceVersion?.startsWith('1') &&
                                            ['Ace'].includes(
                                                connectedDevice
                                            )) ||
                                        connectedDevice == 'Roboki'
                                    ) {
                                        props.worker.postMessage({
                                            type: 'writeArray',
                                            value: [85, 83, 0],
                                        })
                                    } else {
                                        sendBytes(
                                            [85, 83, 0],
                                            props.webSerial.port
                                        )
                                    }
                                    send = true
                                }
                                setSize(allDetails[key].selectedSize)
                            }
                            return ''
                        })
                    }
                }
                // else {
                //     setDetected('Unkown is detected')
                //     setImage(popupCardfive)
                //     setText('value')
                // }
            })
        }
    }

    const reset = () => {
        predict = false
        for (let i = 0; i < trainingDataInputs.length; i++) {
            trainingDataInputs[i].dispose()
        }
        trainingDataInputs.splice(0)
        trainingDataOutputs.splice(0)
        console.log('Tensors in memory: ' + tf.memory().numTensors)
    }

    const enableCam = () => {
        navigator.mediaDevices
            .getUserMedia({ audio: false, video: { width: 300 } })
            .then((stream) => {
                let video = videoRef.current
                video.srcObject = stream
                setvideoLoaded(true)
                video.play()
                if (enable.s1.mic === true) {
                    startListening()
                }
            })
            .catch((err) => {
                console.error('error:', err)
            })
    }

    const disableCam = () => {
        setvideoLoaded(false)
        videoRef.current.pause()
        videoRef.current.srcObject.getTracks()[0].stop()
        if (enable.s1.mic === true) {
            SpeechRecognition.stopListening()
        }
        setTimeout(() => {
            setText('value')
            setDetected('')
            setImage(popupCardfive)
        }, 1500)
    }

    const optionSelected = (event) => {
        setSelect(event.target.value)
        if (event.target.value === 'detected') {
            setDetectionsPopup(true)
        }
    }

    const Popup = (props) => {
        return props.trigger ? (
            <div className={styles.popup}>
                <div className={styles.popupInner}>
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            bottom: '70vh',
                            right: '-1.2vw',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setPopup(false)
                            props.set(true)
                        }}
                    />
                </div>
                {props.displayImage !== popupCardfive ? (
                    <div style={{ zIndex: '3' }}>
                        <img
                            style={{ width: '100%', maxWidth: '60vw' }}
                            src={props.displayImage}
                            height="350vh"
                            width="350vw"
                            alt="displayImage"
                            draggable="false"
                        />
                        <p
                            style={{
                                fontSize: `${2 * Number(props.font)}vh`,
                                zIndex: '4',
                                textAlign: 'center',
                            }}
                        >
                            {props.displayText}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </div>
        ) : (
            ''
        )
    }

    const DetectionsPopup = (props) => {
        const ProgressBar = (props) => {
            const containerStyles = {
                height: 20,
                width: '70%',
                backgroundColor: '#e0e0de',
                borderRadius: 50,
                marginLeft: '2vh',
            }

            const fillerStyles = {
                height: '100%',
                width: `${props.prediction}%`,
                backgroundColor: props.bgColor,
                borderRadius: 'inherit',
                textAlign: 'right',
                transition: 'width 1s ease-out',
            }

            const labelStyles = {
                color: `${props.prediction}` === `0` ? 'black' : 'white',
                fontWeight: 'bold',
                marginBottom: '5%',
            }

            return (
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '40% 60%',
                        padding: '10px',
                        justifyItems: 'center',
                    }}
                >
                    <div style={{ marginBottom: '5px' }}>{props.classname}</div>
                    <div style={containerStyles}>
                        <div style={fillerStyles}>
                            <div style={labelStyles}>{props.prediction}%</div>
                        </div>
                    </div>
                    <br></br>
                </div>
            )
        }

        return props.trigger ? (
            <div className={styles.detectionPopup}>
                <div className={styles.detectionPopupInner}>
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            position: 'absolute',
                            width: '3vw',
                            bottom: '64vh',
                            right: '-1vw',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setDetectionsPopup(false)
                            setSelect('None')
                        }}
                    />
                </div>
                <div
                    className={styles.scrollable}
                    style={{
                        top: '19vh',
                        height: '62vh',
                        maxWidth: '33vw',
                        left: '33.5vw',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                    }}
                >
                    {props.classnames.map((value, key) => (
                        <ProgressBar
                            key={key}
                            classname={value}
                            bgColor={classColor[key]}
                            prediction={
                                Math.floor(prediction[key] * 100)
                                    ? Math.floor(prediction[key] * 100)
                                    : 0
                            }
                        />
                    ))}
                </div>
            </div>
        ) : (
            ''
        )
    }

    const onStart = () => {
        if (enable.s1.pc !== true) {
            setPlay(true)
            enableCam()
        } else {
            if (props.webSerial.port !== undefined) {
                setPlay(true)
                enableCam()
            } else {
                window.alert('Please connect your device')
            }
        }
    }

    return (
        <div>
            {/* NavBar */}
            <NavBar
                selectbar={selectBarfive}
                selectbarwidth="90%"
                headers={[
                    { name: 'Enable', color: 'white' },
                    { name: 'Control', color: 'white' },
                    { name: 'Run', color: 'white' },
                ]}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />
            {/* Main */}
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
                style={{ position: 'relative' }}
            >
                {' '}
                <div>
                    <img
                        src={popupCardfive}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '40vh',
                            left: '18vw',
                            width: '23.5vw',
                            height: '62vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={cameraP5}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '40vh',
                            left: '18vw',
                            width: '6vw',
                            height: '12vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={popupCardfive}
                        draggable="false"
                        alt="popupCardfive"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '50.5vh',
                            left: '51.2vw',
                            width: '26.8vw',
                            height: '39vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={popupCardone}
                        draggable="false"
                        alt="popupCardone"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '23vh',
                            left: '50.3vw',
                            width: '34vw',
                            height: '33vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={musicIcon}
                        draggable="false"
                        alt="popupCardone"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '23vh',
                            left: '45.3vw',
                            width: '7vw',
                            height: '10vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={projectbig}
                        draggable="false"
                        alt="projectbig"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '55.8vh',
                            left: '51.4vw',
                            width: '24vw',
                            height: '23vh',
                            borderWidth: 2,
                            borderColor: 'black',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '22vh',
                            right: '25vw',
                        }}
                    >
                        <label>
                            <select
                                className={styles.selectBox}
                                style={{
                                    position: 'absolute',
                                    right: '14vw',
                                    top: '16vh',
                                    width: '19.4vw',
                                    height: '6.2vh',
                                    font: 'Halcyon Regular',
                                    fontFamily: 'Halcyon Regular',
                                    fontSize: '15px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                                onChange={optionSelected}
                                value={select}
                            >
                                <option defaultValue={'None'}>
                                    Select option
                                </option>
                                <option value="text">Text </option>
                                <option value="detected">
                                    See all detections
                                </option>
                            </select>
                        </label>
                    </div>
                    {select === 'None' ? (
                        <></>
                    ) : (
                        <>
                            {select === 'text' ? (
                                <div>
                                    {/* Element to Move Dynamically */}
                                    <p
                                        style={{
                                            position: 'relative',
                                            top: '53vh',
                                            left: '41.5vw',
                                            font: 'Halcyon Regular',
                                            fontFamily: 'Halcyon Regular',
                                            fontSize: '15px',
                                            textAlign: 'center',
                                            maxWidth: '20vw',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        <strong>{text}</strong>
                                    </p>
                                </div>
                            ) : (
                                <DetectionsPopup
                                    trigger={detectionsPopup}
                                    classnames={CLASS_NAMES}
                                    prediction={prediction}
                                />
                            )}
                        </>
                    )}
                    {display === true && image !== null ? (
                        <img
                            src={image}
                            draggable="false"
                            alt="displayimage"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '40vh',
                                left: '83vw',
                                width: '19vw',
                                height: '62vh',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setDisplay(false)
                                setPopup(true)
                            }}
                        />
                    ) : (
                        <img
                            src={popupCardfive}
                            draggable="false"
                            alt="displayimage"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '40vh',
                                left: '83vw',
                                width: '19vw',
                                height: '62vh',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                    <Popup
                        trigger={popup}
                        displayImage={image}
                        displayText={text}
                        font={size}
                        set={setDisplay}
                    />
                    {isPlay === true ? (
                        <video
                            id="video"
                            style={{
                                position: 'absolute',
                                zIndex: '2',
                                // marginLeft: '8%',
                                // marginTop: '24vh',
                                borderRadius: '10px',
                                top: '40vh',
                                left: '18%',
                                transform: 'translate(-50%,-50%)',
                            }}
                            ref={videoRef}
                            width="20%"
                        />
                    ) : (
                        <p
                            style={{
                                position: 'absolute',
                                left: '18%',
                                top: '47vh',
                                transform: 'translate(-50%,0%)',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                            }}
                        >
                            Camera Inactive
                        </p>
                    )}
                    <p
                        style={{
                            position: 'relative',
                            bottom: '-68vh',
                            right: '-17.5vw',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '15px',
                            textAlign: 'center',
                            maxWidth: '400px',
                            wordWrap: 'break-word',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <strong>{detected}</strong>
                    </p>
                    {/* <canvas
          ref={canvasRef}
          style={{
            zIndex: "2",
            position: "absolute",
            marginLeft: "4vw",
            marginTop: "18vh",
            borderRadius: "10px",
          }}
        /> */}
                </div>
            </div>

            {/* Footer */}
            <div>
                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        if (isPlay) {
                            setPlay(false)
                            reset()
                            disableCam()
                            clearInterval(interval.current)
                            if (enable.s1.pc === true) {
                                clearInterval(readBytesInterval.current)
                            }
                            history.push('/programmingPage')
                        } else {
                            history.push('/programmingPage')
                        }
                    }}
                />
                {stateload === true ? (
                    isPlay === false ? (
                        <img
                            id="image"
                            src={play}
                            draggable="false"
                            alt="play"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '93vh',
                                left: '95.8vw',
                                width: '4vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                onStart()
                            }}
                        />
                    ) : (
                        <>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '10%',
                                    font: 'Halcyon Regular',
                                    fontFamily: 'Halcyon Regular',
                                    fontSize: '18px',
                                    left: '52%',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                {results}
                            </p>
                            <img
                                src={pause}
                                draggable="false"
                                alt="pause"
                                style={{
                                    margin: 0,
                                    position: 'absolute',
                                    top: '93vh',
                                    left: '95.8vw',
                                    width: '4vw',
                                    cursor: 'pointer',
                                    transform: 'translate(-50%, -50%)',
                                }}
                                onClick={() => {
                                    setPlay(false)
                                    setStateload(false)
                                    reset()
                                    disableCam()
                                    setDataLoaded(false)
                                    clearInterval(interval.current)
                                    if (enable.s1.pc === true) {
                                        clearInterval(readBytesInterval.current)
                                    }
                                }}
                            />
                        </>
                    )
                ) : (
                    <p
                        style={{
                            position: 'absolute',
                            bottom: '8%',
                            marginLeft: '38.5%',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '18px',
                            textAlign: 'center',
                        }}
                    >
                        Please wait while we are loading the assets...
                        {loadmodels()}
                    </p>
                )}
            </div>
            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '25.4%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Camera scene is the output window of what
                                    the web camera will see & detect
                                </p>
                            </div>
                        </div>

                        {/* <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH1')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '41.5%',
                                    left: '23.5%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Message & values display screen show the
                                    message or values as an output for the
                                    program that is running
                                </p>
                            </div>
                        </div> */}

                        {/* <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH2')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '60.5%',
                                    left: '30.7%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '1.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Message & values display screen show the
                                    message or values as an output for the
                                    program that is running
                                </p>
                            </div>
                        </div> */}

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH3')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '55%',
                                    left: '34.8%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    The image/video output space, shows the
                                    images if any of the if condition is true
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH4')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '78%',
                                    left: '46.6%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to run the app mode.
                                    The respective action will be executed, when
                                    the if condition is true
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionDecH5')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '78%',
                                    left: '56.6%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Click on this button to stop the app mode
                                    code execution
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    // console.log("mapStateToProps", state);

    return state
}
export default connect(mapStateToProps)(TeachableMachine)
