import React from 'react'
import styles from './Appmode.module.css'

const Slider = (props) => {
    const handleSliderChange = (event) => {
        let value = parseInt(event.target.value, 10);
        if (props.usbRx && value < 1) {
            value = 1;
        }
        props.setslider(value);
        props.save(event);
    };

    const handleNumberChange = (event) => {
        let value = parseInt(event.target.value, 10);
        if (props.usbRx && value < 1) {
            value = 1;
        } else if (value > 255) {
            value = 255;
        }
        props.setslider(value);
        props.save(event);
    };

    return (
        <div>
            <div
                className={styles.Container}
                style={{
                    position: 'absolute',
                    right: props.right,
                    top: props.top,
                }}
            >
                <input
                    type="range"
                    min={props.usbRx ? '1' : '0'}
                    max="255"
                    value={props.slider ? props.slider : props.usbRx ? 1 : 0}
                    className={styles.slider}
                    id="myRange"
                    onChange={handleSliderChange}
                />
                <input
                    style={{
                        position: 'absolute',
                        left: '18vw',
                        top: '0.8vh',
                        backgroundColor: 'white',
                        border: 'none',
                        borderRadius: '2vh',
                        cursor: 'pointer',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '16px',
                        textAlign: 'center',
                    }}
                    type="number"
                    min={props.usbRx ? '1' : '0'}
                    max="255"
                    value={props.slider ? props.slider : props.usbRx ? 1 : 0}
                    onChange={handleNumberChange}
                />
            </div>
            <div
                style={{
                    width: '20vw',
                    height: '10vh',
                    position: 'absolute',
                    top: '25vh',
                    left: '20vw',
                }}
            ></div>
        </div>
    );
};

export default Slider;
