import React from 'react'
import style from './PanelModal.module.css'

function PanelModal(props) {
    return (
        <div className={style.bg}>
            <div className={style.panel}>
                <button
                    className={style.closeButton}
                    onClick={props.handleClose}
                ></button>
                <div>{props.children}</div>
            </div>
        </div>
    )
}

export default PanelModal
