import { connect } from 'react-redux'

function DeviceMissMatchPopupCom(props) {
    let expectedDeviceName =
        props?.device?.connectedDevice ||
        sessionStorage.getItem('connectedDevice') ||
        ''
    let expectedDeviceVersion =
        props?.device?.deviceVersion ||
        sessionStorage.getItem('deviceVersion') ||
        ''
    if (expectedDeviceVersion == '0.0.0') {
        expectedDeviceVersion = sessionStorage.getItem('deviceVersion') || ''
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <p
                style={{
                    transform: 'translateY(20%)',
                    fontSize: '2.5vh',
                }}
            >
                A device mismatch found!
            </p>
            <p
                style={{
                    transform: 'translateY(20%)',
                    fontSize: '2vh',
                }}
            >
                Would you like to continue with connected device?
            </p>

            <div className="mismatch_deviceName_div">
                <div
                    style={{
                        textAlign: 'left',
                    }}
                >
                    <p>Expected Device: {expectedDeviceName}</p>
                    <p>Expected Version: {expectedDeviceVersion}</p>
                </div>

                <div
                    style={{
                        textAlign: 'left',
                        marginLeft: '50px',
                    }}
                >
                    <p>
                        Connected Device:{' '}
                        {props?.device?.deviceMismatched?.name}
                    </p>
                    <p>
                        Connected Version:{' '}
                        {props?.device?.deviceMismatched?.version}
                    </p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(DeviceMissMatchPopupCom)
