import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import renderPrgImage from '../../../../source/programImg'
import renderImage from '../../../../source/importImg'
import Slider from '../../../../Components/ReusableComponents/Slider/Slider'

import './JoyStick.css'
import { resolve } from 'path'
import { includes } from '../../../../redux/reducers/data'

let initalRender = true

let endpointOut_ = 2
let count = 0

let value = false

function JoyStick(props) {
    let history = useHistory()
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]

    const [isBuzzer, setBuzzer] = useState(0)
    const [isSmile1, setSmile1] = useState(false)
    const [isSmile2, setSmile2] = useState(false)
    const [isSmile3, setSmile3] = useState(false)
    const [isSmile4, setSmile4] = useState(false)

    const [isLevel, setLevel] = useState(false)
    const [isDisco, setDisco] = useState(false)
    const [isGesture, setGesture] = useState(false)
    const [isDice, setDice] = useState(false)
    const [isGuitar, setGuitar] = useState(false)
    const [isSimon, setSimon] = useState(false)

    const [isLeftGreenSlider, setLeftGreenSlider] = useState(0)
    const [isLeftBlueSlider, setLeftBlueSlider] = useState(0)
    const [isLeftRedSlider, setLeftRedSlider] = useState(0)

    const [isRightGreenSlider, setRightGreenSlider] = useState(0)
    const [isRightBlueSlider, setRightBlueSlider] = useState(0)
    const [isRightRedSlider, setRightRedSlider] = useState(0)

    const [isL_Red, setL_Red] = useState(0)
    const [isL_Green, setL_Green] = useState(0)
    const [isL_Blue, setL_Blue] = useState(0)

    const [isR_Red, setR_Red] = useState(0)
    const [isR_Green, setR_Green] = useState(0)
    const [isR_Blue, setR_Blue] = useState(0)

    const [isFreq, setFreq] = useState(0)
    const [disable, setDisable] = useState(false)
    const [disableDisco, setDisableDisco] = useState(false)
    const [toastMessage, setToastMessage] = useState(false)

    //get image url
    const getImgUrl = () => {
        if (deviceVersion?.startsWith('1') && connectedDevice === 'Ace') {
            return `url(${renderImage('PCv1_3x')})`
        } else if (connectedDevice === 'Roboki') {
            return `url(${renderImage('Roboki')})`
        } else {
            return `url(${renderImage('PCv1_3x')})`
        }
    }

    //peecee image style
    const RstyleDevicePC = {
        width: '25vw',
        height: '20.5vw',

        backgroundImage: getImgUrl(),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '76% 96%',
        backgroundPosition: '50% 50%',
        zIndex: 110,
        top: connectedDevice === 'Roboki' ? '38%' : '-8%',
        left: '9%',

        position: 'relative',
    }
    const [afterOffDisable, setAfterOffDisable] = useState(false)

    const resetBytes = [
        'R'.charCodeAt(),
        'L'.charCodeAt(),
        0,
        0,
        0,

        'R'.charCodeAt(),
        0,
        0,
        0,

        'B'.charCodeAt(),
        0,
        0,
        0,
        0,
        0,
    ]
    const resetBytesRoboki = [
        'R'.charCodeAt(),
        'L'.charCodeAt(),
        0,
        0,
        0,

        'R'.charCodeAt(),
        0,
        0,
        0,
    ]
    //level
    const level = [
        'G'.charCodeAt(),
        'L'.charCodeAt(),
        'M'.charCodeAt(),
        isLevel ? 49 : 48,
    ]
    //gesture
    const gesture = ['R'.charCodeAt(), 'g'.charCodeAt(), isGesture ? 49 : 48]
    //disco
    const disco = ['R'.charCodeAt(), 'd'.charCodeAt(), isDisco ? 49 : 48]
    //dice
    const dice = [
        'G'.charCodeAt(),
        'L'.charCodeAt(),
        'D'.charCodeAt(),
        isDice ? 49 : 48,
    ]
    //guitar
    const guitar = [
        'G'.charCodeAt(),
        'A'.charCodeAt(),
        'G'.charCodeAt(),
        isGuitar ? 49 : 48,
    ]
    //simon
    const simon = [
        'G'.charCodeAt(),
        'R'.charCodeAt(),
        'G'.charCodeAt(),
        isSimon ? 49 : 48,
    ]
    useEffect(async () => {
        // if (sessionStorage.getItem('deviceVersion')?.includes('1.0.0')) return
        if (props.webSerial.isConnected && count === 0) {
            sendPlayBytes()
            count = count + 1
        }
        if (props.webSerial.isConnected && count > 0) {
            if (!JSON.parse(sessionStorage.getItem('isPlay'))) {
                sendPlayBytes()
            }
        }
        if (!props.webSerial.isConnected) {
            count = 0
        }
    }, [sessionStorage.getItem('isDeviceConnected')])

    // useEffect(async () => {
    //     if (props.webSerial.isConnected) {
    //         console.log("adsadsad",!JSON.parse(sessionStorage.getItem('isPlay')),count)
    //         if (!JSON.parse(sessionStorage.getItem('isPlay')) && count==1) {
    //             props.worker.postMessage({
    //                 type: 'write',
    //                 value: 'PLAY',
    //             })
    //             sessionStorage.setItem('isPlay', true)
    //             await new Promise((resolve) => setTimeout(resolve, 100))
    //         }
    //         props.worker.postMessage({
    //             type: 'write',
    //             value: 'GAG0',
    //         })
    //         waiting for 300ms to get the response back, donot operate before that - a bug to be fixed later
    //         await new Promise((resolve) => setTimeout(resolve, 300))
    //         props.worker.postMessage({
    //             type: 'write',
    //             value: 'GLM0',
    //         })
    //         await new Promise((resolve) => setTimeout(resolve, 100))
    //         props.worker.postMessage({
    //             type: 'write',
    //             value: 'GLD0',
    //         })
    //         await new Promise((resolve) => setTimeout(resolve, 100))
    //         props.worker.postMessage({
    //             type: 'write',
    //             value: 'Rg0',
    //         })
    //         await new Promise((resolve) => setTimeout(resolve, 100))
    //         props.worker.postMessage({
    //             type: 'write',
    //             value: 'Rd0',
    //         })
    //         props.worker.postMessage({
    //             type: 'write',
    //             value: 'M8',
    //         })
    //         await new Promise((resolve) => setTimeout(resolve, 100))
    //         RL000R000B00000
    //          if (connectedDevice === 'Roboki') {
    //    writePort(resetBytesRoboki)
    // } else {
    //   writePort(resetBytes)
    // }
    //     }
    //     // }else{
    //     //     sessionStorage.setItem('isPlay',false)
    //     // }
    // }, [props.webSerial])
    useEffect(() => {
        if (initalRender) {
            initalRender = false
        } else {
            // RgbEyeData()
        }
    }, [isSmile1, isSmile2, isSmile3, isSmile4])
    const arrayConvert = (buffer) => {
        const view = new Uint8Array(buffer)
        const array = Array.from(view)
        return array
    }
    const endpointIn_ = 2

    const airGuitarRead = async () => {
        props.worker.onmessage = async (e) => {
            if (e.data.type == 'read' && e.data.value.length === 4) {
                let play = e.data.value[1] + '' + e.data.value[2]

                if (play != '00') {
                    try {
                        var audio = new Audio(`${renderImage(`${play}`)}`)
                        await new Promise((resolve) => setTimeout(resolve, 500))
                        audio.play()
                    } catch (e) {
                        console.log('guitar error', e)
                    }
                }
            }
        }
    }
    const sendPlayBytes = async () => {
        sessionStorage.setItem('isPlay', true)
        const PLAY = [
            'P'.charCodeAt(),
            'L'.charCodeAt(),
            'A'.charCodeAt(),
            'Y'.charCodeAt(),
        ]
        const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]

        // props.worker.postMessage({
        //     type: 'write',
        //     value: 'GAG0',
        // })
        // // waiting for 300ms to get the response back, donot operate before that - a bug to be fixed later
        // await new Promise((resolve) => setTimeout(resolve, 300))
        if (connectedDevice !== 'Roboki') {
            // props.worker.postMessage({
            //     type: 'write',
            //     value: 'GAG0',
            // })
            props.worker.postMessage({
                type: 'write',
                value: 'GLM0',
            })
            await new Promise((resolve) => setTimeout(resolve, 100))
            props.worker.postMessage({
                type: 'write',
                value: 'GLD0',
            })
            await new Promise((resolve) => setTimeout(resolve, 100))
            props.worker.postMessage({
                type: 'write',
                value: 'Rg0',
            })
        }
        const playBytes = setTimeout(() => {
            writePort(PLAY)
            clearTimeout(playBytes)
        }, 100)

        // const clearPin = setTimeout(() => {
        //     writePort(M8)
        //     clearTimeout(clearPin)
        // }, 350)
    }

    const gobackUrl = () => {
        sessionStorage.setItem('slideDirection', true)

        if (isLevel) {
            writePort([
                'G'.charCodeAt(),
                'L'.charCodeAt(),
                'M'.charCodeAt(),
                48,
            ])
        } else if (isDisco) {
            writePort(['R'.charCodeAt(), 'd'.charCodeAt(), 48])
        } else if (isGesture) {
            writePort(['R'.charCodeAt(), 'g'.charCodeAt(), 48])
        } else if (isDice) {
            writePort([
                'G'.charCodeAt(),
                'L'.charCodeAt(),
                'D'.charCodeAt(),
                48,
            ])
        } else if (isGuitar) {
            ////////////////// this is to break the air guitar loop ////////////
            guitarClick()
        } else if (isSimon) {
            writePort([
                'G'.charCodeAt(),
                'R'.charCodeAt(),
                'G'.charCodeAt(),
                48,
            ])
        } else {
            let data =
                connectedDevice === 'Roboki' ? resetBytesRoboki : resetBytes
            writePort(data)
        }
        setTimeout(() => {
            history.goBack()
        }, 500)
    }

    const handleLevel = (e) => {
        if (isLevel) {
            setLevel(false)
            setDisable(false)
        } else {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setGesture(false)
            setDisco(false)
            setDice(false)
            setGuitar(false)
            setSimon(false)
            setLevel(true)
        }
        levelClick()
    }

    const handleDisco = async (e) => {
        if (disableDisco) return
        setDisableDisco(true)
        setTimeout(() => {
            setDisableDisco(false)
        }, 1000)

        if (isDisco) {
            setDisco(false)
            setDisable(false)
        } else {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setLevel(false)
            setGesture(false)
            setGuitar(false)
            setSimon(false)
            setDice(false)
            setDisco(true)
        }

        await discoClick()
    }

    const handleGesture = (e) => {
        if (isGesture) {
            setGesture(false)
            setDisable(false)
        } else {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setDice(false)
            setGuitar(false)
            setSimon(false)
            setLevel(false)
            setDisco(false)
            setGesture(true)
        }
        gestureClick()
    }

    const handleDice = (e) => {
        if (isDice) {
            setDice(false)
            setDisable(false)
        } else {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setGesture(false)
            setDisco(false)
            setGuitar(false)
            setSimon(false)
            setLevel(false)
            setDice(true)
        }
        diceClick()
    }

    const handleGuitar = (e) => {
        if (isGuitar) {
            setGuitar(false)
            setDisable(false)
        } else {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setGesture(false)
            setDisco(false)
            setDice(false)
            setSimon(false)
            setLevel(false)
            setGuitar(true)
        }
        guitarClick()
    }

    const handleSimon = (e) => {
        if (isSimon) {
            setSimon(false)
            setDisable(false)
        } else {
            setDisable(true)
            setBuzzer(false)
            setLeftGreenSlider(0)
            setL_Blue(0)
            setLeftRedSlider(0)
            setLeftBlueSlider(0)
            setRightGreenSlider(0)
            setRightRedSlider(0)
            setRightBlueSlider(0)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setGesture(false)
            setDisco(false)
            setDice(false)
            setGuitar(false)
            setLevel(false)
            setSimon(true)
        }
        simonClick()
    }

    /*
     */
    const funSetTimeOut = (bytes) => {
        setTimeout(() => {
            writePort(bytes)
        }, 1000)
    }
    const levelClick = () => {
        if (isLevel) {
            let d = [71, 76, 77, 48]
            setTimeout(() => {
                writePort(d)
            }, 600)
        } else if (disco[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)
            funSetTimeOut([71, 76, 77, 49])
        } else if (gesture[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)
            funSetTimeOut([71, 76, 77, 49])
        } else if (dice[3] == 49) {
            let d = [71, 76, 68, 48]
            writePort(d)
            funSetTimeOut([71, 76, 77, 49])
        } else if (guitar[3] == 49) {
            let d = [71, 65, 71, 48]
            writePort(d)
            funSetTimeOut([71, 76, 77, 49])
        } else if (simon[3] == 49) {
            let d = [71, 82, 71, 48]
            writePort(d)
            funSetTimeOut([71, 76, 77, 49])
        } else {
            writePort(M8)
            setTimeout(() => {
                writePort(resetBytes)
            }, 300)
            setTimeout(() => {
                let d2 = [71, 76, 77, 49]
                writePort(d2)
            }, 500)
        }
    }

    const gestureClick = () => {
        if (isGesture) {
            let d = [82, 103, 48]
            writePort(d)
        } else if (level[3] == 49) {
            let d = [71, 76, 77, 48]
            writePort(d)
            funSetTimeOut([82, 103, 49])
        } else if (disco[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)
            funSetTimeOut([82, 103, 49])
        } else if (dice[3] == 49) {
            let d = [71, 76, 68, 48]
            writePort(d)
            funSetTimeOut([82, 103, 49])
        } else if (guitar[3] == 49) {
            let d = [71, 65, 71, 48]
            writePort(d)
            funSetTimeOut([82, 103, 49])
        } else if (simon[3] == 49) {
            let d = [71, 82, 71, 48]
            writePort(d)
            funSetTimeOut([82, 103, 49])
        } else {
            writePort(M8)
            setTimeout(() => {
                writePort(resetBytes)
            }, 300)
            setTimeout(() => {
                let d2 = [82, 103, 49]
                writePort(d2)
            }, 700)
        }
    }

    const discoClick = () => {
        if (isDisco) {
            setAfterOffDisable(true)
            let d = [82, 100, 48]
            setTimeout(() => {
                writePort(d)
            }, 100)
            setTimeout(() => setAfterOffDisable(false), 800)
        } else if (level[3] == 49) {
            let d = [71, 76, 77, 48]
            writePort(d)
            funSetTimeOut([82, 100, 49])
        } else if (gesture[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)
            funSetTimeOut([82, 100, 49])
        } else if (dice[3] == 49) {
            let d = [71, 76, 68, 48]
            writePort(d)
            funSetTimeOut([82, 100, 49])
        } else if (guitar[3] == 49) {
            let d = [71, 65, 71, 48]
            writePort(d)
            funSetTimeOut([82, 100, 49])
        } else if (simon[3] == 49) {
            let d = [71, 82, 71, 48]
            writePort(d)
            funSetTimeOut([82, 100, 49])
        } else {
            writePort(M8)
            setTimeout(() => {
                writePort(resetBytes)
            }, 300)
            setTimeout(() => {
                let d2 = [82, 100, 49]
                writePort(d2)
            }, 500)
        }
    }

    const diceClick = () => {
        if (isDice) {
            let d = [71, 76, 68, 48]
            setAfterOffDisable(true)
            setTimeout(() => {
                writePort(d)
            }, 100)
            setTimeout(() => setAfterOffDisable(false), 800)
        } else if (level[3] == 49) {
            let d = [71, 76, 77, 48]
            writePort(d)
            funSetTimeOut([71, 76, 68, 49])
        } else if (disco[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)
            funSetTimeOut([71, 76, 68, 49])
        } else if (gesture[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)
            funSetTimeOut([71, 76, 68, 49])
        } else if (guitar[3] == 49) {
            let d = [71, 65, 71, 48]
            writePort(d)
            funSetTimeOut([71, 76, 68, 49])
        } else if (simon[3] == 49) {
            let d = [71, 82, 71, 48]
            writePort(d)
            funSetTimeOut([71, 76, 68, 49])
        } else {
            writePort(M8)
            setTimeout(() => {
                writePort(resetBytes)
            }, 300)
            setTimeout(() => {
                let d2 = [71, 76, 68, 49]
                writePort(d2)
            }, 500)
        }
    }

    const guitarClick = () => {
        if (isGuitar) {
            let d = [71, 65, 71, 48]
            setTimeout(() => {
                writePort(d)
            }, 600)
        } else if (level[3] == 49) {
            let d = [71, 76, 77, 48]
            writePort(d)
            funSetTimeOut([71, 65, 71, 49])
        } else if (disco[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)
            funSetTimeOut([71, 65, 71, 49])
        } else if (gesture[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)
            funSetTimeOut([71, 65, 71, 49])
        } else if (dice[3] == 49) {
            let d = [71, 76, 68, 48]
            writePort(d)
            funSetTimeOut([71, 65, 71, 49])
        } else if (simon[3] == 49) {
            let d = [71, 82, 71, 48]
            writePort(d)
            funSetTimeOut([71, 65, 71, 49])
        } else {
            writePort(M8)
            setTimeout(() => {
                writePort(resetBytes)
            }, 300)
            setTimeout(() => {
                let d2 = [71, 65, 71, 49]
                writePort(d2)
                airGuitarRead(props.webSerial.port)
            }, 500)
        }
    }

    const simonClick = () => {
        if (isSimon) {
            let d = [71, 82, 71, 48]
            setTimeout(() => {
                writePort(d)
            }, 600)
        } else if (level[3] == 49) {
            let d = [71, 76, 77, 48]
            writePort(d)
            funSetTimeOut([71, 82, 71, 49])
        } else if (disco[2] == 49) {
            let d = [82, 100, 48]
            writePort(d)
            funSetTimeOut([71, 82, 71, 49])
        } else if (gesture[2] == 49) {
            let d = [82, 103, 48]
            writePort(d)
            funSetTimeOut([71, 82, 71, 49])
        } else if (dice[3] == 49) {
            let d = [71, 76, 68, 48]
            writePort(d)
            funSetTimeOut([71, 82, 71, 49])
        } else if (guitar[3] == 49) {
            let d = [71, 65, 71, 48]
            writePort(d)
            funSetTimeOut([71, 82, 71, 49])
        } else {
            writePort(M8)
            setTimeout(() => {
                writePort(resetBytes)
            }, 300)
            setTimeout(() => {
                let d2 = [71, 82, 71, 49]
                writePort(d2)
            }, 500)
        }
    }

    const Sliderhandler = (value, name) => {
        if (name == 'Right_green_slider') {
            setRightGreenSlider(value)
        } else if (name == 'Right_blue_slider') {
            setRightBlueSlider(value)
        } else if (name == 'Right_red_slider') {
            setRightRedSlider(value)
        }

        switch (name) {
            case 'Left_green_slider': {
                setLeftGreenSlider(value)
                break
            }
            case 'Left_blue_slider': {
                setLeftBlueSlider(value)
                break
            }
            case 'Left_red_slider': {
                setLeftRedSlider(value)
                break
            }
            case 'buzzer_slider': {
                setBuzzer(value)
                break
            }
        }
    }

    const handleSimle1 = (e) => {
        setSmile1(!isSmile1)
    }
    const handleSimle2 = (e) => {
        setSmile2(!isSmile2)
    }

    const handleSimle3 = (e) => {
        setSmile3(!isSmile3)
    }

    const handleSimle4 = (e) => {
        setSmile4(!isSmile4)
    }

    const RgbEyeData = async (smile) => {
        let data
        if (connectedDevice === 'Roboki') {
            data = [
                'R'.charCodeAt(),
                'L'.charCodeAt(),
                isLeftRedSlider ? JSON.parse(isL_Red) : 0,
                isLeftGreenSlider ? JSON.parse(isL_Green) : 0,
                isLeftBlueSlider ? JSON.parse(isL_Blue) : 0,

                'R'.charCodeAt(),
                isRightRedSlider ? JSON.parse(isR_Red) : 0,
                isRightGreenSlider ? JSON.parse(isR_Green) : 0,
                isRightBlueSlider ? JSON.parse(isR_Blue) : 0,
            ]
        } else {
            data = [
                'R'.charCodeAt(),
                'L'.charCodeAt(),
                isLeftRedSlider ? JSON.parse(isL_Red) : 0,
                isLeftGreenSlider ? JSON.parse(isL_Green) : 0,
                isLeftBlueSlider ? JSON.parse(isL_Blue) : 0,

                'R'.charCodeAt(),
                isRightRedSlider ? JSON.parse(isR_Red) : 0,
                isRightGreenSlider ? JSON.parse(isR_Green) : 0,
                isRightBlueSlider ? JSON.parse(isR_Blue) : 0,

                'B'.charCodeAt(),
                JSON.parse(isFreq),
                isSmile1 ? 49 : 48,
                isSmile2 ? 49 : 48,
                isSmile3 ? 49 : 48,
                isSmile4 ? 49 : 48,
            ]
            switch (smile) {
                case 'smile1':
                    if (isSmile1) {
                        setSmile1(false)
                        data[11] = 48
                    } else {
                        setSmile1(true)
                        data[11] = 49
                    }
                    break
                case 'smile2':
                    if (isSmile2) {
                        setSmile2(false)
                        data[12] = 48
                    } else {
                        setSmile2(true)
                        data[12] = 49
                    }
                    break
                case 'smile3':
                    if (isSmile3) {
                        setSmile3(false)
                        data[13] = 48
                    } else {
                        setSmile3(true)
                        data[13] = 49
                    }
                    break
                case 'smile4':
                    if (isSmile4) {
                        setSmile4(false)
                        data[14] = 48
                    } else {
                        setSmile4(true)
                        data[14] = 49
                    }
                    break
                default:
                    break
            }
        }
        await writePort(data)
    }

    async function writePort(data) {
        if (props.webSerial.isConnected && props.webSerial.port !== undefined) {
            console.log('Play Mode', data)
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        } else {
            if (
                data.length === 15 &&
                (data[2] !== 0 ||
                    data[3] !== 0 ||
                    data[4] !== 0 ||
                    data[6] !== 0 ||
                    data[7] !== 0 ||
                    data[8] !== 0 ||
                    data[14] === 49 ||
                    data[13] === 49 ||
                    data[12] === 49 ||
                    data[11] === 49 ||
                    data[10] !== 0)
            ) {
                setToastMessage(true)
                const timer = setTimeout(() => {
                    setToastMessage(false)
                    clearTimeout(timer)
                }, 500)
            } else if (data.length === 3 && data[2] === 49) {
                setToastMessage(true)
                const timer = setTimeout(() => {
                    setToastMessage(false)
                    clearTimeout(timer)
                }, 500)
            } else if (data.length === 4 && data[3] === 49) {
                setToastMessage(true)
                const timer = setTimeout(() => {
                    setToastMessage(false)
                    clearTimeout(timer)
                }, 500)
            }
        }
    }

    useEffect(() => {
        if (!props.webSerial.isConnected) {
            setLevel(false)
            setDisco(false)
            setGesture(false)
            setDice(false)
            setGuitar(false)
            setSimon(false)
            setSmile1(false)
            setSmile2(false)
            setSmile3(false)
            setSmile4(false)
            setLeftGreenSlider(0)
            setLeftBlueSlider(0)
            setLeftRedSlider(0)
            setRightGreenSlider(0)
            setRightBlueSlider(0)
            setRightRedSlider(0)
            setL_Red(0)
            setL_Green(0)
            setL_Blue(0)
            setBuzzer(0)
            setDisable(false)
            setFreq(0)
        }
    }, [props.webSerial.isConnected])

    //teeth disable style
    const teethStyle = afterOffDisable
        ? { cursor: 'default', opacity: '0.5' }
        : { cursor: 'pointer', opacity: '1' }

    return (
        <div>
            {' '}
            <img
                className="Back_BTN"
                src={renderImage('backBtn')}
                onClick={gobackUrl}
            />
            <div className="Remote_Body">
                {/* Left  */}
                <div>
                    {deviceVersion?.startsWith('1') &&
                        connectedDevice === 'Ace' && (
                            <div>
                                <img
                                    className="Level_IA"
                                    src={renderImage(
                                        isLevel == false
                                            ? 'level_IA'
                                            : 'level_AC'
                                    )}
                                    onClick={(e) => {
                                        handleLevel()
                                    }}
                                    alt="levelInactive"
                                    draggable="false"
                                ></img>

                                <p className="Level_txt">Level</p>

                                <img
                                    className="Disco_IA_v1"
                                    src={renderImage(
                                        isDisco == false
                                            ? 'disco_IA'
                                            : 'disco_Ac'
                                    )}
                                    onClick={(e) => {
                                        handleDisco()
                                    }}
                                    // onClick={handleDisco}
                                    alt="DiscoInactive"
                                    draggable="false"
                                ></img>

                                <p className="Disco_txt_v1">Disco</p>

                                <img
                                    className="Gesture_IA_v1"
                                    src={renderImage(
                                        isGesture == false
                                            ? 'gesture_IA'
                                            : 'gesture_Ac'
                                    )}
                                    onClick={(e) => {
                                        handleGesture()
                                    }}
                                    // onClick={handleGesture}
                                    alt="GestureInactive"
                                    draggable="false"
                                ></img>

                                <p className="Gesture_txt_v1">Gesture</p>
                            </div>
                        )}
                    <div>
                        <div>
                            <img
                                className="Left_Eye_Backdround_v1"
                                src={renderImage('Eye_bg_Svg')}
                                draggable="false"
                                style={{
                                    top:
                                        connectedDevice === 'Roboki'
                                            ? '52%'
                                            : '68%',
                                }}
                            ></img>
                        </div>
                        <div
                            className="Slider_Div1_v1"
                            style={{
                                top:
                                    connectedDevice === 'Roboki'
                                        ? '39.5%'
                                        : '55.5%',
                            }}
                        >
                            <div>
                                {isLeftRedSlider > 0 ? (
                                    <Slider
                                        rangImgName="red_slider"
                                        title="Left_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_red"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setL_Red={setL_Red}
                                        isL_Red={isL_Red}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Left_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_red"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setL_Red={setL_Red}
                                        isL_Red={isL_Red}
                                        disable={disable}
                                    />
                                )}
                            </div>
                            <div>
                                {isLeftGreenSlider > 0 ? (
                                    <Slider
                                        rangImgName="green_slider"
                                        title="Left_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_green"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setL_Green={setL_Green}
                                        isL_Green={isL_Green}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Left_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_green"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setL_Green={setL_Green}
                                        isL_Green={isL_Green}
                                        disable={disable}
                                    />
                                )}
                            </div>
                            <div>
                                {isLeftBlueSlider > 0 ? (
                                    <Slider
                                        rangImgName="blue_slider"
                                        title="Left_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_blue"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setL_Blue={setL_Blue}
                                        isL_Blue={isL_Blue}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Left_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="L_blue"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setL_Blue={setL_Blue}
                                        isL_Blue={isL_Blue}
                                        disable={disable}
                                    />
                                )}
                            </div>
                        </div>

                        <p
                            className="Left_Eye_txt_v1"
                            style={{
                                bottom:
                                    connectedDevice === 'Roboki' ? '20%' : '5%',
                            }}
                        >
                            Left Eye
                        </p>
                    </div>
                </div>

                {/* Main  */}
                <div>
                    <div className="Ace_Teeth">
                        {deviceVersion?.startsWith('1') &&
                            connectedDevice === 'Ace' && (
                                <div style={RstyleDevicePC} className="ace">
                                    <img
                                        className="Buzzer_Inactive"
                                        src={renderImage(
                                            isBuzzer > 0
                                                ? 'buzzerAc_Svg'
                                                : 'buzzerIA_Svg'
                                        )}
                                        draggable="false"
                                    ></img>

                                    <div>
                                        {[37, 42, 47.4, 52.8, 57.6, 63.4].map(
                                            (value, index) => {
                                                return (
                                                    <div key={index}>
                                                        <img
                                                            src={
                                                                ((isLeftGreenSlider >
                                                                    0 ||
                                                                    isLeftRedSlider >
                                                                        0 ||
                                                                    isLeftBlueSlider >
                                                                        0) &&
                                                                    (value ===
                                                                        37 ||
                                                                        value ===
                                                                            42)) ||
                                                                ((isRightGreenSlider >
                                                                    0 ||
                                                                    isRightRedSlider >
                                                                        0 ||
                                                                    isRightBlueSlider >
                                                                        0) &&
                                                                    (value ===
                                                                        63.4 ||
                                                                        value ===
                                                                            57.6))
                                                                    ? renderPrgImage(
                                                                          'PcinternalEYEActive_v1'
                                                                      )
                                                                    : renderPrgImage(
                                                                          'PcinternalEYEInActive_v1'
                                                                      )
                                                            }
                                                            className="Rgb_Eyes"
                                                            style={{
                                                                left: `${value}%`,
                                                                top: '41%',
                                                            }}
                                                            draggable="false"
                                                        />
                                                        <img
                                                            src={
                                                                ((isLeftGreenSlider >
                                                                    0 ||
                                                                    isLeftRedSlider >
                                                                        0 ||
                                                                    isLeftBlueSlider >
                                                                        0) &&
                                                                    (value ===
                                                                        37 ||
                                                                        value ===
                                                                            42)) ||
                                                                ((isRightGreenSlider >
                                                                    0 ||
                                                                    isRightRedSlider >
                                                                        0 ||
                                                                    isRightBlueSlider >
                                                                        0) &&
                                                                    (value ===
                                                                        63.4 ||
                                                                        value ===
                                                                            57.6))
                                                                    ? renderPrgImage(
                                                                          'PcinternalEYEActive_v1'
                                                                      )
                                                                    : renderPrgImage(
                                                                          'PcinternalEYEInActive_v1'
                                                                      )
                                                            }
                                                            className="Rgb_Eyes"
                                                            style={{
                                                                left: `${value}%`,
                                                                top: '45%',
                                                            }}
                                                            draggable="false"
                                                        />
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>

                                    <img
                                        className="Ace_Teeth1_v1"
                                        src={renderImage(
                                            isSmile1 == false
                                                ? 'teethIA_Svg'
                                                : 'teethAc_Svg'
                                        )}
                                        draggable="false"
                                    ></img>

                                    <img
                                        className="Ace_Teeth2_v1"
                                        src={renderImage(
                                            isSmile2 == false
                                                ? 'teethIA_Svg'
                                                : 'teethAc_Svg'
                                        )}
                                        draggable="false"
                                    />

                                    <img
                                        className="Ace_Teeth3_v1"
                                        src={renderImage(
                                            isSmile3 == false
                                                ? 'teethIA_Svg'
                                                : 'teethAc_Svg'
                                        )}
                                        draggable="false"
                                    ></img>

                                    <img
                                        className="Ace_Teeth4_v1"
                                        src={renderImage(
                                            isSmile4 == false
                                                ? 'teethIA_Svg'
                                                : 'teethAc_Svg'
                                        )}
                                        draggable="false"
                                    ></img>

                                    <img
                                        className="Ace_4Teeth_v1"
                                        src={renderImage(
                                            isGesture == false
                                                ? 'FourteethIA_Svg'
                                                : 'FourteethAc_Svg'
                                        )}
                                        draggable="false"
                                    />
                                </div>
                            )}
                        {connectedDevice === 'Roboki' && (
                            <div style={RstyleDevicePC} className="ace">
                                <img
                                    className="Roboki_Rgb_Eye"
                                    src={renderPrgImage(
                                        isLeftGreenSlider > 0 ||
                                            isLeftRedSlider > 0 ||
                                            isLeftBlueSlider > 0
                                            ? 'PcinternalEYEActive_v1'
                                            : 'PcinternalEYEInActive_v1'
                                    )}
                                    draggable="false"
                                    style={{ left: '45.8%', top: '39.5%' }}
                                ></img>
                                <img
                                    className="Roboki_Rgb_Eye"
                                    src={renderPrgImage(
                                        isRightGreenSlider > 0 ||
                                            isRightRedSlider > 0 ||
                                            isRightBlueSlider > 0
                                            ? 'PcinternalEYEActive_v1'
                                            : 'PcinternalEYEInActive_v1'
                                    )}
                                    draggable="false"
                                    style={{ left: '53.5%', top: '39.5%' }}
                                ></img>
                            </div>
                        )}
                    </div>

                    {deviceVersion?.startsWith('1') &&
                        connectedDevice === 'Ace' && (
                            <div className="Buzzer_Rgbeye">
                                <div>
                                    <img
                                        className="Buzzer_Bg_v1"
                                        src={renderImage('buzzer_bg_Svg')}
                                        draggable="false"
                                    ></img>
                                    <h2 className="Buzzer_txt_v1">Buzzer</h2>
                                </div>

                                <div className="Slider_Div3_v1">
                                    {isBuzzer > 0 ? (
                                        <Slider
                                            rangImgName="frequency_slider"
                                            title="buzzer_slider"
                                            onChangehandler={Sliderhandler}
                                            componentName="freq"
                                            leftEyeData={RgbEyeData}
                                            max={100}
                                            min={0}
                                            setFreq={setFreq}
                                            isFreq={isFreq}
                                            disable={disable}
                                        />
                                    ) : (
                                        <Slider
                                            rangImgName="inactiveslider"
                                            title="buzzer_slider"
                                            onChangehandler={Sliderhandler}
                                            componentName="freq"
                                            leftEyeData={RgbEyeData}
                                            max={100}
                                            min={0}
                                            setFreq={setFreq}
                                            isFreq={isFreq}
                                            disable={disable}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    {deviceVersion?.startsWith('1') &&
                        connectedDevice === 'Ace' && (
                            <div>
                                <img
                                    className="Teeth_Bg_v1"
                                    src={renderImage('teeth_bg_Svg')}
                                    draggable="false"
                                ></img>

                                <img
                                    className="Teeth_Smile1_v1"
                                    src={renderImage(
                                        isSmile1 ? 'smile1_Active' : 'smile1'
                                    )}
                                    style={teethStyle}
                                    onClick={() => {
                                        if (disable || afterOffDisable) return
                                        // handleSimle1()
                                        RgbEyeData('smile1')
                                    }}
                                    alt="smileOneInactive"
                                    draggable="false"
                                ></img>

                                <img
                                    className="Teeth_Smile2_v1"
                                    src={renderImage(
                                        isSmile2 ? 'smile2_Active' : 'smile2'
                                    )}
                                    onClick={() => {
                                        if (disable || afterOffDisable) return
                                        // handleSimle2()
                                        RgbEyeData('smile2')
                                    }}
                                    style={teethStyle}
                                    alt="smileTwoInactive"
                                    draggable="false"
                                ></img>

                                <img
                                    className="Teeth_Smile3_v1"
                                    src={renderImage(
                                        isSmile3 ? 'smile3_Active' : 'smile3'
                                    )}
                                    style={teethStyle}
                                    onClick={() => {
                                        if (disable || afterOffDisable) return
                                        // handleSimle3()
                                        RgbEyeData('smile3')
                                    }}
                                    alt="smileThreeInactive"
                                    draggable="false"
                                ></img>

                                <img
                                    className="Teeth_Smile4_v1"
                                    src={renderImage(
                                        isSmile4 ? 'smile4_Active' : 'smile4'
                                    )}
                                    style={teethStyle}
                                    onClick={() => {
                                        if (disable || afterOffDisable) return
                                        // handleSimle4()
                                        RgbEyeData('smile4')
                                    }}
                                    alt="smileFourInactive"
                                    draggable="false"
                                ></img>

                                <p className="Teeth_text">Teeth</p>
                            </div>
                        )}
                </div>

                {/* Right  */}
                <div>
                    {deviceVersion?.startsWith('1') &&
                        connectedDevice === 'Ace' && (
                            <div>
                                <img
                                    className="Dice_IA"
                                    src={
                                        isDice == false
                                            ? renderImage('dice_IA')
                                            : renderImage('dice_AC')
                                    }
                                    onClick={(e) => {
                                        handleDice()
                                    }}
                                    alt="DiceInactive"
                                    draggable="false"
                                ></img>

                                <p className="Dice_txt">Dice</p>

                                <img
                                    className="Guitar_IA"
                                    src={renderImage(
                                        isGuitar == false
                                            ? 'guitar_IA'
                                            : 'guitar_AC'
                                    )}
                                    onClick={(e) => {
                                        handleGuitar()
                                    }}
                                    alt="GuitarInactive"
                                    draggable="false"
                                ></img>

                                <p className="Guitar_txt">Guitar</p>

                                <img
                                    className="Simon_IA"
                                    src={renderImage(
                                        isSimon == false
                                            ? 'simon_IA'
                                            : 'simon_AC'
                                    )}
                                    onClick={(e) => {
                                        handleSimon()
                                    }}
                                    // onClick={handleGesture}
                                    alt="SimonInactive"
                                    draggable="false"
                                ></img>

                                <p className="Simon_txt">Simon Says</p>
                            </div>
                        )}
                    <div>
                        <div>
                            <img
                                className="Right_Eye_Backdround_v1"
                                src={renderImage('Eye_bg_Svg')}
                                draggable="false"
                                style={{
                                    top:
                                        connectedDevice === 'Roboki'
                                            ? '52%'
                                            : '68%',
                                }}
                            ></img>
                        </div>
                        <div
                            className="Slider_Div2_v1"
                            style={{
                                top:
                                    connectedDevice === 'Roboki'
                                        ? '39.5%'
                                        : '55.5%',
                            }}
                        >
                            <div>
                                {isRightRedSlider > 0 ? (
                                    <Slider
                                        rangImgName="red_slider"
                                        title="Right_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_red"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setR_Red={setR_Red}
                                        isR_Red={isR_Red}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Right_red_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_red"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setR_Red={setR_Red}
                                        isR_Red={isR_Red}
                                        disable={disable}
                                    />
                                )}
                            </div>
                            <div>
                                {isRightGreenSlider > 0 ? (
                                    <Slider
                                        rangImgName="green_slider"
                                        title="Right_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_green"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setR_Green={setR_Green}
                                        isR_Green={isR_Green}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Right_green_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_green"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setR_Green={setR_Green}
                                        isR_Green={isR_Green}
                                        disable={disable}
                                    />
                                )}
                            </div>
                            <div>
                                {isRightBlueSlider > 0 ? (
                                    <Slider
                                        rangImgName="blue_slider"
                                        title="Right_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_blue"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setR_Blue={setR_Blue}
                                        isR_Blue={isR_Blue}
                                        disable={disable}
                                    />
                                ) : (
                                    <Slider
                                        rangImgName="inactiveslider"
                                        title="Right_blue_slider"
                                        onChangehandler={Sliderhandler}
                                        componentName="R_blue"
                                        leftEyeData={RgbEyeData}
                                        max={100}
                                        setR_Blue={setR_Blue}
                                        isR_Blue={isR_Blue}
                                        disable={disable}
                                    />
                                )}
                            </div>
                        </div>

                        <p
                            className="Right_Eye_txt_v1"
                            style={{
                                bottom:
                                    connectedDevice === 'Roboki' ? '20%' : '5%',
                            }}
                        >
                            Right Eye
                        </p>
                    </div>
                </div>

                {toastMessage ? (
                    <div id="toastMessage">Please connect the device</div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}
// export default RemoteSection;

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(JoyStick)
