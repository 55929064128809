/**
 * This module decribes the ItemTypes inn the workspace
 * @module components/assembly/ItemTypes
 */

let ItemTypes = {
    BIBOX: 'bibox',
    COMPONENT: 'component',
    PORT_CIRCLE: 'port_circle',
}
export default ItemTypes
