import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import AssemblyStyle from './AssemblyPrgm.module.css'
import renderPrgImage from '../../../../source/programImg'
import renderImage from '../../../../source/importImg'
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    zIndex: '1000',
                    // right: '-17%',
                    left: '101%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={renderImage('rightArrow')}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={renderImage('leftArrow')}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

const connectedDevice =
    sessionStorage.getItem('connectedDevice') === 'Humanoid' ? true : false

let isPcv = sessionStorage.getItem('deviceVersion')
export class AssemblyPrgm extends Component {
    render() {
        let deviceName = 'PlayComputer'
        switch (sessionStorage.getItem('connectedDevice')) {
            case 'Ace':
                deviceName = 'PlayComputer'
                break
            case 'Humanoid':
                deviceName = 'Zing'
                break
            case 'Hexapod':
                deviceName = 'Crawl-e'
                break
            case 'Klaw':
                deviceName = 'Klaw-b'
                break
            default:
                deviceName = sessionStorage.getItem('connectedDevice')
        }

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        }
        return (
            <div>
                <div className={AssemblyStyle.container}></div>
                <Slider
                    {...settings}
                    style={{
                        transform: 'translate(-50%,-50%)',
                        top: '47%',
                        left: '50%',
                        position: 'absolute',
                    }}
                >
                    <div className={AssemblyStyle.wdt}>
                        <img
                            className={AssemblyStyle.img}
                            src={
                                ['Humanoid', 'Hexapod', 'Klaw'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                )
                                    ? renderPrgImage('assemblyH')
                                    : isPcv?.startsWith('1')
                                    ? renderPrgImage('Pcv1AssemblyH')
                                    : renderPrgImage('AssemblyPrgf1')
                            }
                        />

                        {/* {!connectedDevice && (
                            <div className={AssemblyStyle.Hel}>
                                <p className={AssemblyStyle.Hel_P}>
                                    {' '}
                                    You can use this button to read the live
                                    sensor values of the internal & external
                                    accessories connected to {deviceName}. Only
                                    accessories enabled at the select screen
                                    will work.
                                </p>
                            </div>
                        )} */}

                        <div className={AssemblyStyle.Cam1}>
                            <p className={AssemblyStyle.Cam1_P}>
                                Help Button will explain all the features and
                                functionality of the entire app for each screen{' '}
                            </p>
                        </div>
                        <div className={AssemblyStyle.Cam2}>
                            <p className={AssemblyStyle.Cam2_P}>
                                To save the new project or overwrite the saved &
                                loaded project.
                            </p>
                        </div>
                        <div className={AssemblyStyle.Cam3}>
                            <p className={AssemblyStyle.Cam3_P}>
                                USB connection status; red: disconnected, green:
                                connected.
                            </p>
                        </div>
                    </div>

                    <div className={AssemblyStyle.wdt}>
                        <img
                            className={AssemblyStyle.img}
                            src={
                                ['Humanoid', 'Hexapod', 'Klaw'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                )
                                    ? renderPrgImage('assemblyH2')
                                    : isPcv?.startsWith('1')
                                    ? renderPrgImage('Pcv1AssemblyH2')
                                    : renderPrgImage('AssemblyPrgf2')
                            }
                        />
                        <div className={AssemblyStyle.con}>
                            <p className={AssemblyStyle.con_P}>
                                All external accessories selected at the select
                                screen are shown here. These can be dragged &
                                dropped at the respective ports of {deviceName}.
                            </p>
                        </div>
                    </div>

                    <div className={AssemblyStyle.wdt}>
                        <img
                            className={AssemblyStyle.img}
                            src={
                                ['Humanoid', 'Hexapod', 'Klaw'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                )
                                    ? renderPrgImage('assemblyH3')
                                    : isPcv?.startsWith('1')
                                    ? renderPrgImage('Pcv1AssemblyH3')
                                    : renderPrgImage('AssemblyPrgf3')
                            }
                        />
                        <div
                            className={AssemblyStyle.Mus}
                            style={
                                ['Humanoid', 'Hexapod', 'Klaw'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                )
                                    ? { left: '26%' }
                                    : null
                            }
                        >
                            <p className={AssemblyStyle.Mus_P}>
                                1. While dragging the external accessories near{' '}
                                {deviceName}, a red-dot appears at the ports,
                                where it can be connected. Once connnected, a
                                wire diagram forms between them.
                                <br /> 2. to remove a connection of the external
                                accessories, you can drag them towards the left
                                panel of the screen. The connection will
                                automatically disconnect.
                            </p>
                        </div>
                    </div>

                    <div className={AssemblyStyle.wdt}>
                        <img
                            className={AssemblyStyle.img}
                            // src={
                            //     sessionStorage.getItem('connectedDevice') ===
                            //     'Humanoid'
                            //         ? renderPrgImage('assemblyH1')
                            //         : isPcv === '1.0.03'
                            //         ? renderPrgImage('Pcv1AssemblyH1')
                            //         : renderPrgImage('AssemblyPrgf4')
                            // }

                            src={
                                ['Humanoid', 'Hexapod', 'Klaw'].includes(
                                    sessionStorage.getItem('connectedDevice')
                                )
                                    ? renderPrgImage('assemblyH1')
                                    : renderPrgImage('AssemblyPrgf4')
                            }
                        />
                        <div className={AssemblyStyle.Cam}>
                            <p className={AssemblyStyle.Cam_P}>
                                You can use this button to read the live sensor
                                values of the internal & external accessories
                                connected to {deviceName}. Only accessories
                                enabled at the select screen will work.
                            </p>
                        </div>
                        <div className={AssemblyStyle.Ca}>
                            <p className={AssemblyStyle.Ca_P}>
                                To go back to the internal accessories screen.
                            </p>
                        </div>
                        <div className={AssemblyStyle.Camr}>
                            <p className={AssemblyStyle.Camr_P}>
                                To go to the code screen
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
        )
    }
}

export default AssemblyPrgm
