import renderImage from '../../source/importImg'
import { useHistory } from 'react-router'

function PrivacyPolicy() {
    let history = useHistory()

    return (
        <>
            <div>
                <img
                    className={'Back_Btn'}
                    src={renderImage('backBtn')}
                    onClick={() => {
                        history.push('/')
                    }}
                    style={{ zIndex: '10' }}
                ></img>
            </div>
            <div
                style={{
                    position: 'relative',
                    overflowY: 'scroll',
                    height: '100vh',
                }}
            >
                <div className="container" style={{ left: '0%' }}>
                    <p></p>

                    <div className="row">
                        <div className="col-12">
                            <p></p>
                            <h1
                                data-aos="fade-up"
                                data-aos-easing="ease"
                                data-aos-delay="100"
                                className="aos-init aos-animate"
                            >
                                Privacy Policy
                            </h1>
                            <div
                                className="aos-init aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease"
                                data-aos-delay="100"
                            >
                                <p>
                                    <strong>
                                        This privacy policy explains how we
                                        collect, use and disclose your
                                        information/data including personal
                                        information when
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        You visit our website{' '}
                                        <strong>www.playcomputer.org</strong>
                                    </li>
                                    <li>
                                        Our programming software{' '}
                                        <strong>Plode at www.plode.org</strong>
                                    </li>
                                </ol>

                                <p>
                                    This privacy policy is very important to us,
                                    and we are committed to safeguarding it for
                                    them.
                                </p>

                                <p>
                                    When this privacy policy mentions “Evobi
                                    Automations Pvt Ltd (BIBOX).”, PlayComputer
                                    (playcomputer.org), “we”, “our” or “us”, it
                                    refers to Evobi Automations Pvt Ltd., the
                                    company that is responsible for your
                                    information under this privacy policy.
                                </p>

                                <p>
                                    In simplified form (greater detail is
                                    below), our policy can be summarized as
                                    follows:&nbsp;
                                </p>

                                <ul>
                                    <li>
                                        We may collect some information about
                                        you, as described in this Policy, but
                                        you have some choices about how much you
                                        share about yourself.
                                    </li>
                                    <li>
                                        We do not knowingly collect information
                                        from children under 13 years of age on
                                        the website or the Plode web app.&nbsp;
                                    </li>
                                    <li>
                                        We will not sell your personal
                                        information but may share such
                                        information with our vendors and our
                                        Clients in connection with providing the
                                        Services.
                                    </li>
                                    <li>
                                        We may collect, use and share
                                        aggregated, anonymous information about
                                        our users.
                                    </li>
                                </ul>

                                <p>
                                    Please read the entire privacy policy
                                    carefully on this page. Whenever you access
                                    or use our website, it signifies that you
                                    have given your consent to us for the
                                    collection and use of your information.
                                </p>

                                <p>
                                    &nbsp;If you do NOT comply or agree with
                                    this Privacy Policy, do NOT access our
                                    website, use our services or interact with
                                    any other aspect of our business.
                                </p>

                                <p>
                                    <strong>
                                        Please note this privacy policy is
                                        subject to our terms &amp; and
                                        conditions and can be changed or
                                        modified by us at any time without any
                                        prior notification.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        For the purpose of this Privacy Policy,
                                        “Personal Information” means any
                                        information relating to an identified or
                                        identifiable individual.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        Information you provide while using our
                                        services on playcomputer.org
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        Information that is necessary for the
                                        use of the Services.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        We ask for and collect the following
                                        personal information about you when you
                                        use our Services:
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>
                                            Google Account Information: When you
                                            sign in to www.plode.org to use the
                                            plode web app, we require certain
                                            information such as your first name,
                                            last name and email address to
                                            provide you with access to our
                                            software securely. We will also
                                            request permision to acess your
                                            google drive, strictly for only
                                            saving your project data on a folder
                                            named Plode App Data.
                                        </strong>
                                    </li>
                                    <p></p>
                                    <li>
                                        <strong>
                                            The information you provide through
                                            live chats as support: The Services
                                            also include live chat support,
                                            customer support, where you may send
                                            any questions to us regarding the
                                            Services. When you speak to one of
                                            our representatives regarding any
                                            question, issue or problem you may
                                            have, you will be asked to provide
                                            your contact information, a summary
                                            of the problem and any other
                                            information that would be helpful to
                                            us in resolving the issue.
                                        </strong>
                                    </li>
                                </ol>

                                <p>
                                    <strong>
                                        The collection of this information by us
                                        is necessary for the adequate
                                        performance of the contract between you
                                        and us and to allow us to comply with
                                        our legal obligations. If the requested
                                        information is not provided, we may not
                                        be able or in a position to provide you
                                        with all the requested services or
                                        resolve your issues.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        We retain your account information with
                                        us until you delete your PlayComputer
                                        Account. We also retain some of your
                                        information which is necessary to comply
                                        with our legal obligations in resolving
                                        disputes, enforcing our agreements,
                                        supporting business operations and
                                        continuing to develop and improve the
                                        Services.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        When you buy something from our website,
                                        as part of the buying and selling
                                        process, we collect the personal
                                        information you give us such as your
                                        name, address and email address.
                                    </strong>
                                </h3>

                                <p>
                                    IP Address: When you browse our website, we
                                    also automatically collect your computer’s
                                    internet protocol (IP) address to provide us
                                    with the information that helps us learn
                                    about your browser and operating system and
                                    serve you a better site interfacing
                                    experience.
                                </p>

                                <p>
                                    Email marketing (if applicable): With your
                                    permission, we may send you emails about our
                                    store, new products, and other updates.
                                </p>

                                <p>
                                    Note: For security reasons, some of our
                                    services require a provision of contact
                                    information such as email address or phone
                                    number from you. This information is
                                    required to provide you with these Services.
                                    Please note that you are responsible for
                                    receiving consent if you use these Services
                                    on behalf of another person.
                                </p>

                                <h3>
                                    <strong>Comments</strong>
                                </h3>

                                <p>
                                    <strong>
                                        When visitors leave comments on the site
                                        we collect the data shown in the
                                        comments form, and also the visitor’s IP
                                        address and browser user agent string to
                                        help spam detection.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Media</strong>
                                </h3>

                                <p>
                                    <strong>
                                        If you upload images to the website, you
                                        should avoid uploading images with
                                        embedded location data (EXIF GPS)
                                        included. Visitors to the website can
                                        download and extract any location data
                                        from images on the website.
                                    </strong>
                                </p>

                                <p>
                                    <strong>Your Consent obtainment</strong>
                                </p>

                                <h3>
                                    <strong>
                                        The automatically collected information
                                        from your use of our website, app, and
                                        software services
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        When you use our services, we
                                        automatically collect information,
                                        including personal information, about
                                        the services you use and how you use
                                        them. This information collection is
                                        necessary for the adequate performance
                                        of the contract between you and us,
                                        which enables us to comply with legal
                                        obligations.
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>
                                            Machine and Connection Information:
                                            We collect information about your
                                            computer or other devices you use to
                                            access our Services. This machine
                                            information includes your connection
                                            type and settings when you install,
                                            access, update or use our services.
                                            We also collect these types of your
                                            device information – IP address,
                                            your approximate location, URLs of
                                            referring, etc. to provide you with
                                            better service accessibility and
                                            experience.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Cookies: We use cookies to track
                                            your navigation on our website.
                                        </strong>
                                    </li>
                                </ol>

                                <h3>
                                    <strong>
                                        Information collection upon transactions
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        Payment Transaction Related Information:
                                        PlayComputer may collect information
                                        related to your payment transactions
                                        through different Services we offer,
                                        including the product name, type of
                                        payment instrument used, date and time,
                                        payment amount, tax amount, credit card
                                        number, credit card expiration date,
                                        cardholder name (if provided), basic
                                        information like first name, last name,
                                        phone, address, country, state, city,
                                        postal code, and other details connected
                                        with transactions.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Marketing Reason</strong>
                                </h3>

                                <p>
                                    <strong>
                                        If we ask for your personal information
                                        for a secondary reason, like marketing,
                                        we will either ask you directly for your
                                        expressed consent or provide you with an
                                        opportunity to say “no” to it.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        Plode App – What personal information
                                        does PlayComputer collect about me?
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        Where applicable, we indicate whether
                                        and why you must provide us with your
                                        Personal Information, as well as the
                                        consequences of failing to do so. If you
                                        do not provide Personal Information when
                                        requested, you may not be able to
                                        benefit from our App, if that
                                        information is necessary to provide you
                                        with the service or if we are legally
                                        required to collect the information.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Account Information</strong>
                                </h3>

                                <p>
                                    <strong>
                                        In order to access the in-app courses,
                                        you need to make an account. During
                                        account creation, if the user is under
                                        16 years old, we ask you for
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>Guardian’s email</strong>
                                    </li>
                                    <li>
                                        <strong>Profile Picture</strong>
                                    </li>
                                    <li>
                                        <strong>Username</strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Age (Not the Date of Birth)
                                        </strong>
                                    </li>
                                </ol>

                                <p>
                                    <strong>
                                        If the user is above 16 years old, we
                                        ask you for
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>User email id</strong>
                                    </li>
                                    <li>
                                        <strong>Username</strong>
                                    </li>
                                    <li>
                                        <strong>Profile Picture</strong>
                                    </li>
                                    <li>
                                        <strong>Country</strong>
                                    </li>
                                    <li>
                                        <strong>Date of Birth</strong>
                                    </li>
                                    <li>
                                        <strong>Gender</strong>
                                    </li>
                                </ol>

                                <p>
                                    <strong>
                                        We ask that you select a username that
                                        does not disclose your real name or
                                        other information that could identify
                                        you. Other users can see your username,
                                        but not your age, gender, or email
                                        address.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>User-generated Content</strong>
                                </h3>

                                <p>
                                    <strong>
                                        We collect any information that you
                                        provide to us when you do the in-app
                                        courses and create projects.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        Personal Information We Collect
                                        Automatically From Your Use of the App
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        When you use Plode App, we and our
                                        third-party service providers collect
                                        information about you and your device
                                        through automated means, such as cookies
                                        and web server logs. By using Plode App,
                                        you consent to the placement of cookies
                                        and similar technologies in your browser
                                        in accordance with this Privacy Policy.
                                        The information collected in this manner
                                        includes your IP address, network
                                        location, the device you are using,
                                        device IDs and characteristics,
                                        operating system version, language
                                        preferences, and information about the
                                        usage of our site. We use this
                                        information, for example, to ensure that
                                        the app functions properly, to determine
                                        how many users have used our app, or to
                                        prevent fraud. We use IP address
                                        information to derive your approximate
                                        location. We also work with analytics
                                        providers, such as Google Analytics,
                                        which use cookies and similar
                                        technologies to collect and analyze
                                        information about the use of the app and
                                        report on activities and trends.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        You can learn more about Google’s
                                        practices by going to{' '}
                                    </strong>
                                    <a href="https://www.google.com/policies/privacy/partners/">
                                        <strong>
                                            https://www.google.com/policies/privacy/partners/
                                        </strong>
                                    </a>
                                    <strong>.</strong>
                                </p>

                                <h3>
                                    <strong>
                                        How Does Plode App (BiBox) Share my
                                        Personal Information?
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        We disclose information that we collect
                                        through the App to third parties in the
                                        following circumstances:
                                    </strong>
                                </p>

                                <ul>
                                    <li>
                                        <strong>
                                            To third-party service providers who
                                            provide services such as website
                                            hosting, data analysis, information
                                            technology, and related
                                            infrastructure provisions, customer
                                            service, email delivery, and other
                                            services.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            We may also disclose your Personal
                                            Information with your permission. We
                                            may seek your permission in various
                                            ways. For example, we may present
                                            you with an “opt-in” prompt when you
                                            register to use the App or access
                                            certain content.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            To a potential or actual acquirer,
                                            successor, or assignee as part of
                                            any reorganization, merger, sale,
                                            joint venture, assignment, transfer,
                                            or other disposition of all or any
                                            portion of our organization or
                                            assets. You will have the
                                            opportunity to opt out of any such
                                            transfer if the new entity’s planned
                                            processing of your information
                                            differs materially from that set
                                            forth in this Privacy Policy.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            If required to do so by law or in
                                            the good faith belief that such
                                            action is appropriate: (a) under
                                            applicable law, including laws
                                            outside your country of residence;
                                            (b) to comply with legal process;
                                            (c) to respond to requests from
                                            public and government authorities,
                                            such as school, school districts,
                                            and law enforcement, including
                                            public and government authorities
                                            outside your country of residence;
                                            (d) to enforce our terms and
                                            conditions; (e) to protect our
                                            operations or those of any of our
                                            affiliates; (f) to protect our
                                            rights, privacy, safety, or
                                            property, and/or that of our
                                            affiliates, you, or others; and (g)
                                            to allow us to pursue available
                                            remedies or limit the damages that
                                            we may sustain.
                                        </strong>
                                    </li>
                                </ul>

                                <h3>
                                    <strong>Sharing &amp; Disclosure</strong>
                                </h3>

                                <p>
                                    <strong>
                                        To comply with the law, we may disclose
                                        your personal information, if we are
                                        required by law to do so or if you
                                        violate our Terms of Service.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        Agilo Research may disclose your
                                        information, including personal
                                        information, to courts, law enforcement
                                        or governmental authorities, or
                                        authorized third parties, if and to the
                                        extent we are required or permitted to
                                        do so by law or if such disclosure is
                                        reasonably necessary.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Payment</strong>
                                </h3>

                                <p>
                                    <strong>
                                        We use Razorpay, and Stripe for
                                        processing payments. We/Razorpay/Stripe
                                        do not store your card data on their
                                        servers. The data is encrypted by the
                                        respective payment gateways when
                                        processing payment. Your purchase
                                        transaction data is only used, as long
                                        as it is necessary to complete your
                                        purchase transaction. After that is
                                        completed, your purchase transaction
                                        information or card details are not
                                        saved by the payment gateways.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        For more information, you may also want
                                        to read terms and conditions on{' '}
                                    </strong>
                                    <a href="https://razorpay.com">
                                        <strong>https://razorpay.com</strong>
                                    </a>
                                    <strong> or </strong>
                                    <a href="https://stripe.com/en-in">
                                        <strong>
                                            https://stripe.com/en-in
                                        </strong>
                                    </a>
                                </p>

                                <h3>
                                    <strong>Third Party Services</strong>
                                </h3>

                                <p>
                                    <strong>
                                        Our third-party service providers
                                        collect, use and disclose your
                                        information required and necessary for
                                        them to perform and maintain their
                                        services provided to us.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        However, please take a note that certain
                                        third-party service providers, such as
                                        payment gateways and other payment
                                        transaction processors, have their own
                                        privacy policies listed with respect to
                                        the information we need to provide to
                                        them for your purchase-related
                                        transactions.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        For these providers, we request and
                                        advise you to read their privacy
                                        policies, so that you can understand how
                                        your personal information will be
                                        handled by these providers.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        The important point to remember:&nbsp;
                                        third-party service providers may be in
                                        or have facilities that are governed
                                        affected by a different jurisdiction
                                        than either you or us. So, if you choose
                                        to proceed with a transaction that
                                        involves the services of a third-party
                                        service provider, then your information
                                        may become subject to the laws of the
                                        jurisdiction(s) in which that service
                                        provider or its facilities are located.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        Once you leave our website or are
                                        redirected to a third-party website or
                                        application, you are no longer governed
                                        by this Privacy Policy or our website’s
                                        Terms of Service.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Links:</strong>
                                </h3>

                                <p>
                                    <strong>
                                        When you click on any links, they may
                                        direct you away from our site. In such
                                        case or scenarios, we are not
                                        responsible for the privacy practices of
                                        other sites and encourage you to read
                                        their privacy statements to understand
                                        how they use the information of users.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        How we use the information we collect
                                        about you
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        We use, store and process the
                                        information we collect about you, to
                                        provide, maintain and develop our
                                        services. This enables us to create and
                                        maintain a safer and trusted environment
                                        for both the parties – You and Us and
                                        comply with our legal obligations.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        We process this information, given our
                                        legitimate interest in improving our
                                        services, and where it is necessary and
                                        required for the adequate performance of
                                        the contract with you.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        To provide, improve and develop our
                                        services
                                    </strong>
                                </h3>

                                <ol>
                                    <li>
                                        <strong>
                                            Operate, protect and optimize
                                            services and experience
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Enable us to access and use services
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Provide customer services: to
                                            resolve technical issues, responding
                                            to questions or queries, improve
                                            services.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            If you provide us with your contact
                                            information, we may process the
                                            information for fraud detection and
                                            prevention and to serve any purpose
                                            you authorize at the time of
                                            information collection.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            We may contact you about your
                                            account or feedback. We might also
                                            contact you about this Policy or our
                                            app Terms of Service.
                                        </strong>
                                    </li>
                                </ol>

                                <h3>
                                    <strong>
                                        To create and maintain a safer and
                                        trusted environment for both the parties
                                    </strong>
                                </h3>

                                <ol>
                                    <li>
                                        <strong>
                                            Conduct security investigations and
                                            risk assessments
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Detect and prevent any harmful
                                            activities such as spam, abuse,
                                            security incidents, etc.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Enforce our terms and conditions –
                                            Agreement.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Comply with our legal obligations.
                                        </strong>
                                    </li>
                                </ol>

                                <h3>
                                    <strong>
                                        To provide, personalize, and improve our
                                        advertising and marketing.
                                    </strong>
                                </h3>

                                <ol>
                                    <li>
                                        <strong>
                                            Send you marketing, advertising,
                                            promotional messages, and other
                                            information based on your interest
                                            and preferences and social media
                                            advertising through social media
                                            platforms such as Facebook, Google,
                                            etc.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            Personalize and improve our
                                            advertising.
                                        </strong>
                                    </li>
                                </ol>

                                <p>
                                    <strong>
                                        We use and process your personal
                                        information for the purposes listed in
                                        this section, given our legitimate
                                        interest in undertaking marketing
                                        activities to offer you products or
                                        services that may be of your interest.
                                        You can opt-out of receiving marketing
                                        communications from us by following the
                                        unsubscribe instructions included in our
                                        marketing communications.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Plode App</strong>
                                </h3>

                                <ol>
                                    <li>
                                        Internal and Service-Related Usage: We
                                        use Personal Information for internal
                                        and App-related purposes, including to
                                        operate, provide, and maintain the App.
                                    </li>
                                    <li>
                                        Analytics and Improving the App: We and
                                        our service providers use Personal
                                        Information that we collect on the App,
                                        such as your location and your
                                        activities on the App, to monitor and
                                        analyze usage of the App and to improve
                                        and enhance the App.
                                    </li>
                                    <li>
                                        Communications: We may send emails to an
                                        email address you provide to us for
                                        customer-service or technical-support
                                        purposes, to send you information about
                                        topics or content that we think may
                                        interest you, or updates about the
                                        latest developments or features on the
                                        App. Parents and guardians who register
                                        their under-16-year-olds for Plode may
                                        also receive additional updates from
                                        PlayComputer and BiBox.
                                    </li>
                                    <li>
                                        Storage: We may use your google drive to
                                        store any programs or data that you
                                        create and require for the proper
                                        working and function of the Plode App.
                                        Denying the plode app acess to you
                                        google drive may result in the inability
                                        to use the multiple features of the
                                        Plode App and may cause it to not
                                        function as intended.
                                    </li>
                                </ol>

                                <h3>
                                    <strong>Security</strong>
                                </h3>

                                <p>
                                    <strong>
                                        To help protect your information against
                                        unauthorized access, loss, destruction,
                                        or alteration, we are continuously
                                        implementing security measures. We
                                        follow the industry best practice to
                                        ensure your information is not misused
                                        or accessed by any other party.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Cookies</strong>
                                </h3>

                                <p>
                                    <strong>
                                        If you leave a comment on our site you
                                        may opt-in to saving your name, email
                                        address and website in cookies. These
                                        are for your convenience so that you do
                                        not have to fill in your details again
                                        when you leave another comment. These
                                        cookies will last for one year.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        If you visit our login page, we will set
                                        a temporary cookie to determine if your
                                        browser accepts cookies. This cookie
                                        contains no personal data and is
                                        discarded when you close your browser.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        When you log in, we will also set up
                                        several cookies to save your login
                                        information and your screen display
                                        choices. Login cookies last for two
                                        days, and screen options cookies last
                                        for a year. If you select “Remember Me”,
                                        your login will persist for two weeks.
                                        If you log out of your account, the
                                        login cookies will be removed.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        If you edit or publish an article, an
                                        additional cookie will be saved in your
                                        browser. This cookie includes no
                                        personal data and simply indicates the
                                        post ID of the article you just edited.
                                        It expires after 1 day.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        How we use your Google Account
                                        Information and Services
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        We may use the information from your
                                        google account and acess your google
                                        drive data in the following ways.
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>
                                            To track you as you navigate through
                                            our web app.
                                        </strong>
                                    </li>
                                    <p></p>
                                    <li>
                                        <strong>
                                            To identify you from your First
                                            Name, Last Name, Username & Email as
                                            you use our web app.
                                        </strong>
                                    </li>
                                    <p></p>
                                    <li>
                                        <strong>
                                            To save your user data to your own
                                            google drive which will consist of
                                            your projects or any other data you
                                            create on our web app.
                                        </strong>
                                    </li>
                                    <p></p>
                                    <li>
                                        <strong>
                                            To load previously saved user data
                                            for your use and modification on our
                                            web app.
                                        </strong>
                                    </li>
                                    <p></p>
                                </ol>
                                <h3>
                                    <strong>How we use cookies</strong>
                                </h3>

                                <p>
                                    <strong>
                                        We may use the information from your use
                                        of our services for the below
                                        objectives: To recognize your computer
                                        when you visit our website.
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>
                                            To track you as you navigate through
                                            our website.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            To analyze the use of the website.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            To improve website usability.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            To help administer the website.
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            To personalize the website for you,
                                            including targeting advertisements
                                            and promotional marketing which may
                                            be of your interest.
                                        </strong>
                                    </li>
                                </ol>

                                <h3>
                                    <strong>
                                        European users Data Protection Laws
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        If you are a resident of the European
                                        Union (“EU”) or Switzerland, you are
                                        entitled to certain protections under
                                        the EU’s General Data Protection
                                        Regulation (“GDPR”) and/or other
                                        applicable laws (collectively, the “Data
                                        Protection Laws”), and this section
                                        applies to your use of the Services. As
                                        used in this section, the terms
                                        “processing,” “processor,” “controller”
                                        and “personal data” have the meaning
                                        given to them in the Data Protection
                                        Laws.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        Period for which personal data will be
                                        stored
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        We will store your personal data as long
                                        as necessary to provide the
                                        functionality of the Services. For
                                        example, we may store your personal data
                                        as long as your personal data is posted
                                        on or connected with any Events that you
                                        have attended, and/or as long as the
                                        Client is using such data for their own
                                        business purposes. We may also store
                                        your personal data as long as required
                                        by law.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        Withdrawal of consent and restriction of
                                        processing
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        Where you have provided your consent to
                                        the processing of your personal
                                        information by PlayComputer, you may
                                        withdraw your consent at any time by
                                        sending us notifications specifying
                                        which consent you are withdrawing.
                                        Please note that the withdrawal of your
                                        consent does not affect the lawfulness
                                        of any processing activities based on
                                        such consent before its withdrawal.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        Where your personal information is used
                                        and processed for direct marketing and
                                        advertising purposes, at any time, you
                                        may ask us to stop processing your data
                                        for these direct marketing objectives by
                                        sending an e-mail to .
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        Changes to this privacy policy
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        We reserve all rights to change or
                                        modify our privacy policy at any time
                                        without prior notice and hence, we
                                        advise you to please review it
                                        frequently. Any privacy policy related
                                        changes or modifications will be posted
                                        here for your reference, those changes
                                        will be effective immediately.
                                    </strong>
                                </p>

                                <p>
                                    <strong>
                                        If our store is acquired by or merged
                                        with another company, your information
                                        and data may be transferred to the new
                                        owners, so that we may continue selling
                                        products/services to you.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>Your rights</strong>
                                </h3>

                                <p>
                                    <strong>
                                        Finally, you may have certain rights
                                        under the Data Protection Laws with
                                        respect to your personal data. Such
                                        rights may include, but are not limited
                                        to, the right to request access to your
                                        personal data, that your personal data
                                        be corrected or deleted, to restrict the
                                        processing of your personal data or to
                                        object to the processing of your
                                        personal data. To exercise such rights,
                                        you may contact us at{' '}
                                    </strong>
                                    <strong>support@playcomputer.org </strong>
                                    <strong>
                                        Specifically, and without limiting your
                                        rights under the Data Protection Laws,
                                        you may do any of the following:
                                    </strong>
                                </p>

                                <ol>
                                    <li>
                                        <strong>
                                            request access to your personal data
                                            to be able to correct, amend, or
                                            delete such personal data where it
                                            is inaccurate or has been processed
                                            in violation of the Privacy Shield
                                            Principles,
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            request that your personal data not
                                            be used for a purpose that is
                                            materially different from the
                                            purposes for which it was originally
                                            collected or for purposes
                                            subsequently authorized by you; or
                                        </strong>
                                    </li>
                                    <li>
                                        <strong>
                                            request that we do not disclose your
                                            personal data to third parties
                                            (other than those that are acting as
                                            our agent to perform tasks on our
                                            behalf, such as payment and data
                                            processors).
                                        </strong>
                                    </li>
                                </ol>

                                <h3>
                                    <strong>Government requests</strong>
                                </h3>

                                <p>
                                    <strong>
                                        As described in this Policy, we may
                                        share personal data with third parties
                                        and may be required to disclose personal
                                        data in response to lawful requests by
                                        public authorities, including to meet
                                        national security or law enforcement
                                        requirements.
                                    </strong>
                                </p>

                                <h3>
                                    <strong>
                                        Questions and contact information
                                    </strong>
                                </h3>

                                <p>
                                    <strong>
                                        If you would like to access, correct,
                                        amend or delete any personal information
                                        we have about you, register a complaint,
                                        or simply want more information, contact
                                        our Privacy Compliance Officer at{' '}
                                    </strong>
                                    <strong>support@playcomputer.org</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
